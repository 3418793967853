import { Directive, ElementRef } from '@angular/core';

import { CanvasDomService } from '../canvas-dom.service';
import { SelectionChangedData } from '../canvas.service';
import { GroupContourElementHolder } from '../contour-element-builder.service';
import {
    ContourChangeData,
    ContourCollisionVizData,
    ContourItemsRemoved,
    CuttableContourDrawData,
    MarginContourCollisionVizData
} from '../contour/contour-items-interfaces';
import { LayerElementStoreService } from '../layer-element-store.service';

import { CanvasContourLayer } from './canvas-contour-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';
import { CanvasLayerWithSubscriptionMixin } from './destroyable-layer-mixin';
import { CollisionClipPath } from './fixed-collision-layer.directive';

declare var Snap: any;

export const OUTER_PATH_TO_CLIP_ID = 'outer-contour-collision-';
export const INNER_PATH_TO_CLIP_ID = 'inner-contour-collision-';

export const OUTER_CLIP_CONTOUR_ID = 'outer-clip-contour-';
export const INNER_CLIP_CONTOUR_ID = 'inner-clip-contour-';

export const SVG_NAMESPACE_URI = 'http://www.w3.org/2000/svg';
const OUTER_CLASS_CLIP_PATHS_ELEM = 'outer-clip-contour-elem-';
const INNER_CLASS_CLIP_PATHS_ELEM = 'inner-clip-contour-elem-';
const ID_CLIP_LAYOUT_PATH_ELEM = 'clip-layout-elem';
/**
 * handles "dynamic" collisions
 *
 * in this context dynamic means that there is a object selected or in movement
 */
@Directive({ selector: '[appCollisionLayer]' })
export class CollisionLayerDirective extends CanvasLayerMixin(CanvasLayerWithSubscriptionMixin())
    implements CanvasContourLayer<Snap.Paper> {
    svgElement: Snap.Paper;
    element: Snap.Paper;

    private readonly clipPaths: { [id: string]: CollisionClipPath } = {};

    constructor(
        private readonly elementRef: ElementRef,
        private readonly workspaceDOMService: CanvasDomService,
        private readonly layerElementStore: LayerElementStoreService
    ) {
        super();

        this.svgElement = this.element = Snap(elementRef.nativeElement);
    }

    addContourItem(contourData: CuttableContourDrawData) {}

    changeContourItem(contourData: ContourChangeData) {
        if (contourData.children) {
            contourData.children.forEach(x => this.updateCollisionPaths(x));
        } else {
            this.updateCollisionPaths(contourData);
        }
    }

    selectContourItems(data: SelectionChangedData) {
        const { itemsToSelect, itemsToDeselect } = data;

        if (itemsToSelect) {
            itemsToSelect.forEach(itemData => {
                if (itemData.collision) {
                    itemData.collision.data.forEach(contourCollisionData => {
                        let clipPath = this.clipPaths[
                            contourCollisionData.selectedContour.contourId
                        ];
                        if (!clipPath) {
                            clipPath = this.createPathsToClip2(
                                contourCollisionData.selectedContour
                            );
                            this.createLayoutClipPaths2(itemData.collision.foam, clipPath);
                            this.createLayoutClipPaths2(
                                itemData.collision.foamInnerMargin,
                                clipPath,
                                true
                            );
                        }

                        if (itemData.isCollidable) {
                            this.createClipPaths2(contourCollisionData.otherContours, clipPath);
                        }
                    });
                }
            });
        }

        if (itemsToDeselect) {
            itemsToDeselect.forEach(itemData => this.removeContourClipPath(itemData.contourId));
        }
    }

    private removeContourClipPath(contourId: string) {
        const elementHolder = this.layerElementStore.getContourElements2(contourId);
        if (elementHolder instanceof GroupContourElementHolder) {
            Object.keys(elementHolder.elementHolders).forEach(childContourId =>
                this.removeClipPaths(childContourId)
            );
        } else {
            this.removeClipPaths(contourId);
        }
    }

    /**
     *
     * @param contourData
     */
    removeContourItem(contourData: ContourItemsRemoved) {
        if (!contourData.contourIds) {
            return;
        }

        contourData.contourIds.forEach(contourId => {
            this.removeClipPaths(contourId);
        });
    }

    private updateCollisionPaths(contourData: ContourChangeData) {
        const _clipPaths = this.clipPaths[contourData.contourId];

        // path to clipped is not set if the contour was not selected yet (by calling
        // selectContourItems)
        if (!_clipPaths) {
            return;
        }
        const outerPathToBeClipped = _clipPaths.selectedOuterPathGroup.children()[0];
        if (outerPathToBeClipped) {
            this.updatePathToClipped(outerPathToBeClipped.node, contourData);
        }
        const innerPathToBeClipped = _clipPaths.selectedInnerPathGroup.children()[0];
        if (innerPathToBeClipped) {
            this.updatePathToClipped(innerPathToBeClipped.node, contourData, true);
        }

        const selectedOuterClipPathClass = OUTER_CLASS_CLIP_PATHS_ELEM + contourData.contourId;
        const selectedInnerClipPathClass = INNER_CLASS_CLIP_PATHS_ELEM + contourData.contourId;

        // update all cli-paths of this item used by other items
        for (const clipPathId of Object.keys(this.clipPaths)) {
            if (contourData.contourId !== clipPathId) {
                const outerClipPath = Object.values(
                    this.clipPaths[clipPathId].outerClipPathContent
                ).find((p: SVGPathElement) => {
                    return p.classList.contains(selectedOuterClipPathClass);
                });

                const innerClipPath = Object.values(
                    this.clipPaths[clipPathId].innerClipPathContent
                ).find((p: SVGPathElement) => {
                    return p.classList.contains(selectedInnerClipPathClass);
                });

                if (outerClipPath) {
                    this.updatePathToClipped(outerClipPath, contourData);
                }
                if (innerClipPath) {
                    this.updatePathToClipped(innerClipPath, contourData, true);
                }
            }
        }
    }

    private updatePathToClipped(
        clipPathElem: HTMLElement | SVGPathElement,
        contourData: ContourChangeData,
        inner = false
    ) {
        if (clipPathElem) {
            const dAttr = inner ? contourData.svgPathDefinition : contourData.marginPathString;
            if (dAttr) {
                clipPathElem.setAttribute('d', dAttr);
            }

            if (contourData.matrix) {
                clipPathElem.setAttribute('transform', contourData.matrix.toString());
            }

            if (contourData.visibility !== undefined) {
                clipPathElem.style.visibility = contourData.visibility ? 'visible' : 'hidden';
            }
        }
    }

    private removeClipPaths(contourId: string) {
        const clipPathItems = this.clipPaths[contourId];
        if (clipPathItems) {
            clipPathItems.outerClipPathElement.remove();
            clipPathItems.innerClipPathElement.remove();
            clipPathItems.selectedOuterPathGroup.remove();
            clipPathItems.selectedInnerPathGroup.remove();
            delete this.clipPaths[contourId];
        }
    }

    private createPathsToClip2(collisionData: MarginContourCollisionVizData): CollisionClipPath {
        if (!collisionData) {
            console.warn('Failed to create path clip');
            return undefined;
        }

        const outerCollisionGroup: Snap.Paper = this.svgElement.g();
        outerCollisionGroup.attr({
            'clip-path': `url(#${OUTER_CLIP_CONTOUR_ID}${collisionData.contourId})`
        });

        const pathId = OUTER_PATH_TO_CLIP_ID + collisionData.contourId;
        const outerCollisionPath = outerCollisionGroup.path(collisionData.pathStringToBeClipped);
        outerCollisionPath.attr({ id: pathId });
        outerCollisionPath.node.setAttribute(
            'transform',
            collisionData.localMarginPathMatrix.toString()
        );

        const outerClipPathElem: SVGClipPathElement = document.createElementNS(
            SVG_NAMESPACE_URI,
            'clipPath'
        );
        outerClipPathElem.id = OUTER_CLIP_CONTOUR_ID + collisionData.contourId;
        outerClipPathElem.setAttribute('clip-rule', 'evenodd');
        this.svgElement.node.appendChild(outerClipPathElem);

        // Add inner collision clip

        const innerCollisionGroup: Snap.Paper = this.svgElement.g();
        innerCollisionGroup.attr({
            'clip-path': `url(#${INNER_CLIP_CONTOUR_ID}${collisionData.contourId})`
        });

        const innerCollisionPath = innerCollisionGroup.path(collisionData.clipPathString);
        innerCollisionPath.attr({
            id: INNER_PATH_TO_CLIP_ID + collisionData.contourId
        });
        innerCollisionPath.node.setAttribute(
            'transform',
            collisionData.localContourPathMatrix.toString()
        );

        const innerClipPathElem: SVGClipPathElement = document.createElementNS(
            SVG_NAMESPACE_URI,
            'clipPath'
        );
        innerClipPathElem.id = INNER_CLIP_CONTOUR_ID + collisionData.contourId;
        innerClipPathElem.setAttribute('clip-rule', 'evenodd');
        this.svgElement.node.appendChild(innerClipPathElem);

        return (this.clipPaths[collisionData.contourId] = {
            contourId: collisionData.contourId,
            selectedOuterPathGroup: outerCollisionGroup,
            selectedInnerPathGroup: innerCollisionGroup,
            outerClipPathElement: outerClipPathElem,
            innerClipPathElement: innerClipPathElem,
            outerClipPathContent: {},
            innerClipPathContent: {}
        });
    }

    private createLayoutClipPaths2(
        foamData: ContourCollisionVizData,
        clipPath: CollisionClipPath,
        inner = false
    ) {
        if (!foamData || !clipPath) {
            console.warn('Failed to create layout clip path');
            return;
        }

        const clipPathEl = inner ? clipPath.innerClipPathElement : clipPath.outerClipPathElement;

        // create layout clip-path
        const layoutClip = document.createElementNS(SVG_NAMESPACE_URI, 'path');
        clipPathEl.appendChild(layoutClip);
        layoutClip.id = ID_CLIP_LAYOUT_PATH_ELEM;
        layoutClip.setAttribute('d', foamData.clipPathString);
        layoutClip.setAttribute('transform', foamData.localContourPathMatrix.toString());

        clipPathEl[layoutClip.id] = layoutClip;
    }

    private createClipPaths2(
        collisionData: MarginContourCollisionVizData[],
        clipPath: CollisionClipPath
    ) {
        for (const otherItem of Object.values(collisionData)) {
            // Clip path already exists
            if (!clipPath.outerClipPathContent[otherItem.contourId]) {
                const className = OUTER_CLASS_CLIP_PATHS_ELEM + otherItem.contourId;
                const clipPathChild = document.createElementNS(SVG_NAMESPACE_URI, 'path');
                clipPathChild.classList.add(className);
                clipPath.outerClipPathElement.appendChild(clipPathChild);
                clipPathChild.setAttribute('d', otherItem.clipPathString);
                clipPathChild.setAttribute(
                    'transform',
                    otherItem.localContourPathMatrix.toString()
                );

                clipPath.outerClipPathContent[otherItem.contourId] = clipPathChild;
            }

            if (clipPath.innerClipPathContent[otherItem.contourId]) {
                const className = INNER_CLASS_CLIP_PATHS_ELEM + otherItem.contourId;
                const clipPathChild = document.createElementNS(SVG_NAMESPACE_URI, 'path');
                clipPathChild.classList.add(className);
                clipPath.innerClipPathElement.appendChild(clipPathChild);
                clipPathChild.setAttribute('d', otherItem.pathStringToBeClipped);
                clipPathChild.setAttribute('transform', otherItem.localMarginPathMatrix.toString());

                clipPath.innerClipPathContent[otherItem.contourId] = clipPathChild;
            }

            //  add clip path for inner
        }
    }
}
