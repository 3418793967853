export const COLOR_BG_ITEM_PATH = '#1e72c7';
export const COLOR_BG_MARGIN_PATH = '#546268';
export const OPACITY_BG_MARGIN_PATH = 0.4;

/**
 * Ids and class names prefixes
 */

// TODO remove ending minus
export const CLASS_NAME_CONTOUR_GROUP = 'contour-group-';
export const CLASS_NAME_TEXT_CONTOUR_GROUP = 'text-contour-group-';
export const CLASS_NAME_MARGIN_GROUP = 'safety-margin-group-';
export const CLASS_NAME_CONTOUR_SELECTED = 'item-selected';

export const CLASS_NAME_CONTOUR_PATH = 'contour-item-';
export const CLASS_NAME_MARGIN_PATH = 'contour-margin-';
export const CLASS_NAME_CONTOUR_IMAGE = 'contour-image-';
