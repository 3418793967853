import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogRefs } from '../dialog-refs';

@Component({
    selector: 'app-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'modal-card-head'
    }
})
export class DialogHeaderComponent<T> implements OnInit {
    @Input('closeable')
    closable: boolean = true;

    dialogRefs: DialogRefs<T>;

    constructor(dialogRefs: DialogRefs<T>) {
        this.dialogRefs = dialogRefs;
    }

    ngOnInit() {}

    @HostListener('body:keyup.escape')
    close() {
        this.dialogRefs.close();
    }
}
