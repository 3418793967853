import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { InlSVGTextInputElement } from './inl-svg-text-input-element';

import { CanvasElementChangedEvent } from './canvas.component';
import {
    ContourElementHolder,
    FoamContourElementHolder,
    GroupContourElementHolder,
    TextContourElementHolder
} from './contour-element-builder.service';
import { ContourElementRefs } from './contour-element-refs';
import { GroupContourElementRefs } from './group-contour-element-refs';

@Injectable({
    providedIn: 'root'
})
export class LayerElementStoreService {
    /**
     * @deprecated
     */
    contourElementsRefs: {
        [key: string]: ContourElementRefs | GroupContourElementRefs;
    } = {};

    htmlTextInputContainer: ElementRef;
    /**
     * @deprecated
     */
    private svgTextInputs: { [contourId: string]: InlSVGTextInputElement } = {};
    private foamContourChanged: Subject<CanvasElementChangedEvent> = new Subject();
    private foamInnerMarginContourChanged: Subject<CanvasElementChangedEvent> = new Subject();

    foamElementHolder: FoamContourElementHolder;
    foamInnerMarginElementHolder: FoamContourElementHolder;

    elementChanges: CanvasElementChangedEvent[] = [];
    readonly elementChanged = new Subject<CanvasElementChangedEvent[]>();

    /* contourElementHolders: (
        |ContourElementHolder|TextContourElementHolder|GroupContourElementHolder)[] = []; */

    contourElementHolders: ElementHolderArray = {};

    /**
     * @deprecated
     * @param contourId
     */
    getContourElements(
        contourId: string
    ): ContourElementRefs | GroupContourElementRefs | undefined {
        return this.contourElementsRefs[contourId];
    }

    addGroupContourElements(contourId: string): GroupContourElementRefs {
        const groupContourRefs =
            (this.getContourElements(contourId) as GroupContourElementRefs) ||
            new GroupContourElementRefs(contourId, this);

        this.contourElementsRefs[contourId] = groupContourRefs;
        return groupContourRefs;
    }

    setContourElements(
        contourId: string,
        elementHolder: ContourElementHolder | TextContourElementHolder | GroupContourElementHolder
    ) {
        this.contourElementHolders[contourId] = elementHolder;
    }

    getContourElements2(
        contourId: string,
        withGroupChildren?: boolean
    ): ContourElementHolder | TextContourElementHolder | GroupContourElementHolder | undefined {
        const result = this.contourElementHolders[contourId];

        if (!result && withGroupChildren) {
            for (const holder of Object.values(this.contourElementHolders)) {
                if (holder instanceof GroupContourElementHolder) {
                    const soughtHolder = holder.elementHolders[contourId];
                    if (soughtHolder) {
                        return soughtHolder;
                    }
                }
            }
        }

        return result;
    }

    /**
     * @param contourId
     */
    removeContourElements(contourId: string) {
        delete this.contourElementHolders[contourId];
    }

    setFoamContour(elementHolder: FoamContourElementHolder) {
        this.foamElementHolder = elementHolder;
    }

    setFoamInnerMarginContour(elementHolder: FoamContourElementHolder) {
        this.foamInnerMarginElementHolder = elementHolder;
    }

    notifyOnContourChanges() {
        if (this.elementChanges.length > 0) {
            this.elementChanged.next(Array.from(this.elementChanges));
            this.elementChanges = [];
        }
    }

    addContourElementChanges(change: CanvasElementChangedEvent) {
        this.elementChanges.push(change);
    }

    notifyFoamContourChange(changedEvent: CanvasElementChangedEvent) {
        this.foamContourChanged.next(changedEvent);
    }

    notifyFoamInnerMarginContourChange(changedEvent: CanvasElementChangedEvent) {
        this.foamInnerMarginContourChanged.next(changedEvent);
    }

    getFoamContourChange(): Observable<CanvasElementChangedEvent> {
        return this.foamContourChanged.asObservable();
    }

    getFoamInnerMarginContourChange(): Observable<CanvasElementChangedEvent> {
        return this.foamInnerMarginContourChanged.asObservable();
    }

    getSVGTextInput(contourId: string): InlSVGTextInputElement {
        return this.svgTextInputs[contourId];
    }

    /**
     * @deprecated
     * @param contourId
     */
    removeSVGTextInput(contourId: string) {
        const inlInput: InlSVGTextInputElement = this.svgTextInputs[contourId];
        if (inlInput) {
            inlInput.destroy();
            delete this.svgTextInputs[contourId];
        }
    }
}

type ElementHolderArray = {
    [index: string]: ContourElementHolder | TextContourElementHolder | GroupContourElementHolder;
};
