import { UndoableEditaAction } from './undo-manager.service';
import { CanvasContour } from '../canvas/contour/contour-items-interfaces';

export class CreationEditAction implements UndoableEditaAction {
    readonly presentationName: string;
    private initialMatrix: Snap.Matrix;

    constructor(private readonly createdContour: CanvasContour, presentationName?: string) {
        if (!createdContour) {
            throw Error('contours cannot be undefined');
        }

        this.presentationName = presentationName || 'Add contour';
        this.initialMatrix = this.createdContour.localContourPathMatrix.clone();
    }

    redo(): void {
        const canvasService = this.createdContour.getCanvas();
        const added = canvasService.addContourItem(this.createdContour);
        if (added) {
            this.createdContour.transform(this.initialMatrix);
            canvasService.clearSelection();
            canvasService.addToSelection([this.createdContour]);
        }
    }

    undo(): void {
        this.createdContour.getCanvas().removeContours([this.createdContour]);
    }
}
