/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["tabindex", "0"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], [[2, "text-before-icon", null], [2, "text-after-icon", null], [2, "text-before-after-icon", null]], null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 4243456, null, 0, i2.ButtonComponent, [i1.ElementRef], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isBefore(); var currVal_1 = i1.ɵnov(_v, 1).isAfter(); var currVal_2 = i1.ɵnov(_v, 1).isBeforeAndAfter(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var ButtonComponentNgFactory = i1.ɵccf("app-button, app-button[app-icon-button]", i2.ButtonComponent, View_ButtonComponent_Host_0, { textPosition: "textPosition", color: "color", iconAlign: "iconAlign", buttonSize: "buttonSize", disabled: "disabled" }, {}, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
