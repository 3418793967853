import { UndoableEditaAction } from './undo-manager.service';
import { TextContour } from '../canvas/contour/text-contour';

export class TextUndoableEditAction implements UndoableEditaAction {
    readonly presentationName: string;

    constructor(
        private readonly textContour: TextContour,
        private readonly newTextContent: string,
        private readonly oldTextContent: string,
        presentationName?: string
    ) {
        this.presentationName = presentationName || 'Set Text';
    }

    undo(): void {
        this.textContour.setText(this.oldTextContent);
    }

    redo(): void {
        this.textContour.setText(this.newTextContent);
    }
}
