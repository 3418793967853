import { Action, ActionEvent } from './action';
import { CanvasService } from '../canvas/canvas.service';
import { FoamEditorService } from '../foam-editor.service';
import { Rectangle2D } from '../shared/geom/rectangle2D';
import { CircleContour } from '../canvas/contour/circle-contour';
import { CanvasContour } from '../canvas/contour/contour-items-interfaces';
import { ResizeableContour } from '../canvas/contour/resizeable-contour';
import { ResizeEditAction } from '../undo/resize-edit-action';
import { DepthEditAction } from '../undo/depth-edit-action';

export class ResizeContourAction implements Action {
    private readonly canvasService: CanvasService;

    constructor(private readonly foamEditor: FoamEditorService) {
        this.canvasService = this.foamEditor.getCanvasService();
    }

    execute(event: ResizeContourActionEvent) {
        const selectedItems = this.canvasService.getSelectedContourItems();
        if (selectedItems.length !== 1 || !selectedItems[0].isResizable) {
            return;
        }

        const itemToResize = selectedItems[0];

        if (!(itemToResize instanceof ResizeableContour)) {
            return;
        }

        if (event.depth) {
            // set old values
            const oldDepth = itemToResize.depth;
            const isOldDepthUserDefined = itemToResize.userDefinedDepth;

            itemToResize.userDefinedDepth = true;

            if (event.depth !== itemToResize.depth) {
                itemToResize.resizeDepth(event.depth);
                this.canvasService
                    .getUndoManagerService()
                    .addEditAction(
                        new DepthEditAction(
                            itemToResize,
                            event.depth,
                            oldDepth,
                            isOldDepthUserDefined,
                            itemToResize.userDefinedDepth
                        )
                    );
            } else if (!isOldDepthUserDefined) {
                // user has change the depth value (for the first time) but
                // to the value has not change because it equals the current auto-computed depth
                // Still we must mark the contour depth as user defined and make this action undoable
                this.canvasService
                    .getUndoManagerService()
                    .addEditAction(
                        new DepthEditAction(
                            itemToResize,
                            event.depth,
                            oldDepth,
                            isOldDepthUserDefined,
                            itemToResize.userDefinedDepth,
                            'Use user-defined depth'
                        )
                    );
            }
        }

        if (event.dimension) {
            const new2DBounds = this.getDimensionBounds(event.dimension, itemToResize);
            if (new2DBounds) {
                const oldBounds = itemToResize.localContourPathBBox.clone();
                itemToResize.resizeDimension(new2DBounds, oldBounds);
                this.canvasService
                    .getUndoManagerService()
                    .addEditAction(new ResizeEditAction(itemToResize, new2DBounds, oldBounds));
            }
        }
    }

    getDimensionBounds(
        dimension: { width?: number; height?: number },
        itemToResize: CanvasContour
    ): Rectangle2D | undefined {
        let newWidth: number;
        let newHeight: number;
        const isCircle = itemToResize instanceof CircleContour;
        if (dimension.width) {
            newWidth = dimension.width;
            newHeight = isCircle ? dimension.width : itemToResize.height;
        } else if (dimension.height) {
            newWidth = isCircle ? dimension.height : itemToResize.width;
            newHeight = dimension.height;
        } else {
            return undefined;
        }

        const contourBounds = itemToResize.localContourPathBBox.clone();
        return new Rectangle2D(contourBounds.x, contourBounds.y, newWidth, newHeight);
    }
}

export interface ResizeContourActionEvent extends ActionEvent {
    readonly dimension?: {
        readonly width?: number;
        readonly height?: number;
    };
    readonly depth?: number;
}
