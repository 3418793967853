import { DroppableEvent } from './droppable-handler.service';
import {
    ALIGN_MODE_CENTER,
    ALIGN_MODE_CURSOR_OFFSET,
    DroppableMirrorComponent
} from './droppable-mirror/droppable-mirror.component';
import { getScrollX, getScrollY } from '../utils/dom-utils';
import { CanvasImageContourCreateActionEvent } from '../foam-editor/actions/contour-create.action';

export class DefaultSVGDndHandler implements DndHandler {
    private cacheDraggableItemBBox: ClientRect | DOMRect;
    private mirrorComponent: DroppableMirrorComponent;

    setMirrorComponent(mirror: DroppableMirrorComponent) {
        this.mirrorComponent = mirror;
    }

    onDragStart(evt: DroppableEvent) {
        if (!this.mirrorComponent) {
            throw new ReferenceError();
        }

        this.mirrorComponent.top = evt.y + getScrollY();
        this.mirrorComponent.left = evt.x + getScrollX();
        this.mirrorComponent.cursorOffsetX = evt.mirrorOffsetX;
        this.mirrorComponent.cursorOffsetY = evt.mirrorOffsetY;
        this.mirrorComponent.dropState = 'outside';
        this.mirrorComponent.alignMode = ALIGN_MODE_CURSOR_OFFSET;

        this.cacheDraggableItemBBox = evt.draggableTarget.htmlElement.getBoundingClientRect();
        this.mirrorComponent.width = this.cacheDraggableItemBBox.width;
        this.mirrorComponent.height = this.cacheDraggableItemBBox.height;

        // Note: the mirror element should be displayed after the position is set to hide the erratic
        // (initial) position change from the last drag-n-drop operation to the current one
        this.mirrorComponent.isMirroredNodeVisible = true;
    }

    onDragCancel(evt: DroppableEvent) {
        this.mirrorComponent.isMirroredNodeVisible = false;
        this.mirrorComponent.isPreviewNodeVisible = false;
        this.mirrorComponent.mirrorDraggedItem = null;
        this.mirrorComponent.dropState = 'stop';

        this.mirrorComponent.svgPreviewCanvas.clear();
    }

    onDragEnter(evt: DroppableEvent) {
        this.mirrorComponent.isPreviewNodeVisible = true;
        this.mirrorComponent.isMirroredNodeVisible = false;
        this.mirrorComponent.alignMode = ALIGN_MODE_CENTER;
        this.mirrorComponent.dropState = 'enter';
    }

    onDragLeave(evt: DroppableEvent) {
        this.mirrorComponent.alignMode = ALIGN_MODE_CURSOR_OFFSET;
        this.mirrorComponent.isPreviewNodeVisible = false;
        this.mirrorComponent.isMirroredNodeVisible = true;
        this.mirrorComponent.dropState = 'outside';

        this.mirrorComponent.width = this.cacheDraggableItemBBox.width;
        this.mirrorComponent.height = this.cacheDraggableItemBBox.height;
    }

    onDragMove(evt: DroppableEvent) {
        this.mirrorComponent.left = evt.x + getScrollX();
        this.mirrorComponent.top = evt.y + getScrollY();
        this.mirrorComponent.cursorOffsetX = evt.mirrorOffsetX;
        this.mirrorComponent.cursorOffsetY = evt.mirrorOffsetY;
    }

    onDragReEnterPreviewArea(evt: DroppableEvent) {
        this.mirrorComponent.alignMode = ALIGN_MODE_CURSOR_OFFSET;
        this.mirrorComponent.isPreviewNodeVisible = true;
        this.mirrorComponent.isMirroredNodeVisible = false;
        this.mirrorComponent.dropState = 'outside';

        this.mirrorComponent.width = this.cacheDraggableItemBBox.width;
        this.mirrorComponent.height = this.cacheDraggableItemBBox.height;
    }

    onDragDrop(evt: DroppableEvent) {
        this.mirrorComponent.mirrorDraggedItem = null;
        this.mirrorComponent.svgPreviewCanvas.clear();
        this.mirrorComponent.svgPreviewCanvas.attr({ width: 0, height: 0 });
        this.mirrorComponent.isPreviewNodeVisible = false;
        this.mirrorComponent.dropState = 'stop';
    }
}

export interface DndHandler {
    setMirrorComponent(mirror: DroppableMirrorComponent);
    onDragStart(evt: DroppableEvent);
    onDragMove(evt: DroppableEvent);
    onDragEnter(evt: DroppableEvent);
    onDragReEnterPreviewArea(evt: DroppableEvent);
    onDragDrop(evt: DroppableEvent);
    onDragLeave(evt: DroppableEvent);
    onDragCancel(evt: DroppableEvent);
}

export interface DndHostComponent<T> {
    getData(): T[];
    getScaleFactor(): number;
    onItemDropped(event: CanvasImageContourCreateActionEvent);
}
