import { ImageContourModel } from './image-contour.model';
import { FoamContourModel } from './foam-contour.model';
import { ModelMapper } from './model.mapper';
import { ModelDecoder } from './model.decoder';

export class FoamConfigurationModel {
    public id: string;
    public configurationName: string;
    public readonly foamContour: FoamContourModel;
    public readonly contours: ImageContourModel[];

    constructor(obj: FoamConfigurationModel) {
        this.id = obj.id ? ModelDecoder.string(obj.id) : undefined;
        this.configurationName = obj.configurationName
            ? ModelDecoder.string(obj.configurationName)
            : undefined;
        this.foamContour = ModelDecoder.object(obj.foamContour);
        if (!obj.contours) {
            this.contours = [];
        } else if (Array.isArray(obj.contours)) {
            this.contours = obj.contours;
        } else {
            throw new Error(
                `Invalid value type; expected Array, received ${JSON.stringify(obj.contours)}.`
            );
        }
    }
}
