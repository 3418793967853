import { FoamConfigurationModel } from './foam-configuration.model';
import { ModelDecoder } from './model.decoder';

export class StartParameterModel {
    inlayUserId: string;
    languageKey: string;
    price: string;
    vat: string;
    foamConfig: FoamConfigurationModel;
    languageContent: object;

    constructor(obj: StartParameterModel) {
        // FIXME: check inlayUserId again !!!!!
        this.inlayUserId = obj.inlayUserId;
        this.languageKey = ModelDecoder.string(obj.languageKey);
        this.price = ModelDecoder.string(obj.price);
        this.vat = ModelDecoder.string(obj.vat);
        this.foamConfig = new FoamConfigurationModel(obj.foamConfig);
        this.languageContent = ModelDecoder.object(obj.languageContent);
    }
}
