import { Tool } from './tool';
import { Observable } from 'rxjs';
import { FoamEditorService } from '../../foam-editor.service';
import { CanvasService } from '../../canvas/canvas.service';
import { SimpleDragEvent } from '../event-helpers';
import { Handle } from '../handle/handle';
import { Point2D } from '../geom/point2D';

export class HandleTracker extends Tool {
    private readonly canvasService: CanvasService;
    private handle: Handle;
    private startPoint: Point2D;

    constructor(foamEditorService: FoamEditorService) {
        super(foamEditorService);
        this.canvasService = this.foamEditorService.getCanvasService();
    }

    getToolStarted(): Observable<any> {
        return undefined;
    }

    handleMouseDown(event: MouseEvent): void {
        // event.preventDefault();
        this.startPoint = this.canvasService.clientXYToFoamLayer(event.x, event.y);
        this.handle.trackStart(this.startPoint);
    }

    handleMouseDrag(event: SimpleDragEvent): void {
        event.originalEvent.preventDefault();
        if (!this.handle || !this.handle.owner) {
            return;
        }

        const canvasCursorPt = this.canvasService.clientXYToFoamLayer(event.x, event.y);
        this.handle.trackStep(this.startPoint, canvasCursorPt);
    }

    handleMouseMove(event: MouseEvent): void {}

    handleMouseUp(event: MouseEvent): void {
        event.preventDefault();
        const canvasCursorPt = this.canvasService.clientXYToFoamLayer(event.x, event.y);
        this.handle.trackEnd(this.startPoint, canvasCursorPt);
        this.handle = undefined;
        this.toolDoneSubject.next();
    }

    setHandle(newHandle: Handle) {
        this.handle = newHandle;
    }

    handleDbClick(event: MouseEvent): void {}
}
