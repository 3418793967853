import { UndoableEditaAction } from './undo-manager.service';
import { Rectangle2D } from '../shared/geom/rectangle2D';
import { ResizeableContour } from '../canvas/contour/resizeable-contour';

export class ResizeEditAction implements UndoableEditaAction {
    readonly presentationName: string;

    constructor(
        private readonly contour: ResizeableContour,
        private readonly newBounds: Rectangle2D,
        private readonly oldBounds: Rectangle2D,
        presentationName?: string
    ) {
        if (!contour) {
            throw Error('contour cannot be undefined');
        }
        this.presentationName = presentationName || 'Resize contour';
    }

    redo(): void {
        this.contour.resizeDimension(this.newBounds);
    }

    undo(): void {
        this.contour.resizeDimension(this.oldBounds);
    }
}
