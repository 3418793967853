/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../dialog/dialog-header/dialog-header.component";
import * as i4 from "../../../dialog/dialog-refs";
import * as i5 from "./upload-dialog.component";
import * as i6 from "./upload-dialog.service";
import * as i7 from "@angular/common/http";
import * as i8 from "../../../configurator-store.service";
import * as i9 from "../../../backend/backend.service";
import * as i10 from "@ngx-translate/core";
var styles_UploadDialogComponent = [i0.styles];
var RenderType_UploadDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadDialogComponent, data: {} });
export { RenderType_UploadDialogComponent as RenderType_UploadDialogComponent };
function View_UploadDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_UploadDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { uploadDialogContentSlot: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-dialog-header", [["class", "modal-card-head"]], null, [["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.DialogHeaderComponent, [i4.DialogRefs], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "h1", [["class", "app-dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDialogComponent_1)), i1.ɵdid(6, 16384, [[1, 4]], 0, i5.UploadDialogContentDirective, [i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogTitle; _ck(_v, 4, 0, currVal_0); }); }
export function View_UploadDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "app-upload-dialog", [], null, null, null, View_UploadDialogComponent_0, RenderType_UploadDialogComponent)), i1.ɵprd(131584, null, i6.UploadDialogService, i6.UploadDialogService, [i7.HttpClient, i4.DialogRefs, i8.ConfiguratorStoreService, i9.BackendService]), i1.ɵdid(2, 4440064, null, 0, i5.UploadDialogComponent, [i4.DialogRefs, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector, i6.UploadDialogService, i1.NgZone, i10.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var UploadDialogComponentNgFactory = i1.ɵccf("app-upload-dialog", i5.UploadDialogComponent, View_UploadDialogComponent_Host_0, {}, {}, []);
export { UploadDialogComponentNgFactory as UploadDialogComponentNgFactory };
