<app-dialog-header>
    <h1 class="app-dialog-title">{{ 'EDIT_CONTOUR_DIALOG.TITLE' | translate }}</h1>
</app-dialog-header>
<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <form [formGroup]="form">
                    <div class="form-field" id="form-field__contour-title">
                        <label class="form-field__label" for="input-contour-title">{{
                            'EDIT_CONTOUR_DIALOG.NAME' | translate
                        }}</label>
                        <input
                            type="text"
                            formControlName="title"
                            id="input-contour-title"
                            class="input-element"
                            placeholder="z.B. Werkzeug 1"
                            maxlength="20"
                            required
                        />
                        <div class="form-field__hint">
                            {{ 'EDIT_CONTOUR_DIALOG.NAME_INFO_TEXT' | translate }}
                        </div>
                    </div>
                    <app-error-messages [group]="form" controlName="title"> </app-error-messages>
                    <div class="form-field" id="form-field__contour-desc">
                        <label class="form-field__label" for="input-contour-desc">{{
                            'EDIT_CONTOUR_DIALOG.DESCRIPTION' | translate
                        }}</label>
                        <textarea
                            id="input-contour-desc"
                            class="input-element"
                            formControlName="description"
                        >
                        </textarea>
                        <div class="form-field__hint">
                            {{ 'EDIT_CONTOUR_DIALOG.DESCRIPTION_INFO_TEXT' | translate }}
                        </div>
                    </div>
                    <div class="form-field" id="form-field__contour-depth">
                        <label class="form-field__label" for="input-contour-depth">{{
                            'EDIT_CONTOUR_DIALOG.HEIGHT' | translate
                        }}</label>
                        <input
                            id="input-contour-depth"
                            type="text"
                            class="input-element"
                            required
                            formControlName="depth"
                        />
                        <div class="form-field__hint">
                            {{ 'EDIT_CONTOUR_DIALOG.HEIGHT_INFO_TEXT' | translate }}
                        </div>
                    </div>
                    <app-error-messages [group]="form" controlName="depth"> </app-error-messages>
                </form>

                <div class="contour-image-container">
                    <img *ngIf="imageContour.image != null" [attr.src]="imageContour.image" />
                </div>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'secondary'"
            iconAlign="horizontal"
            textPosition="after"
            buttonSize="normal"
            (click)="cancel()"
        >
            <app-icon svgWidth="24" svgHeight="24" shape="icon-close" iconSize="small"> </app-icon>
            {{ buttonCancelText }}
        </app-button>
        <app-button
            app-icon-button
            [disabled]="form.pristine || form.invalid"
            [color]="'primary'"
            buttonSize="normal"
            textPosition="before"
            (click)="save()"
        >
            {{ 'EDIT_CONTOUR_DIALOG.SAVE_BUTTON' | translate }}
            <app-icon svgWidth="24" svgHeight="24" shape="arrow_right" iconSize="small"> </app-icon>
        </app-button>
    </div>
</app-dialog-footer>
