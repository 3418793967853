import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Injector,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { InlComponentPortal, PortalSlot } from '../../../shared/portal/portal';
import { DialogRefs } from '../../../dialog/dialog-refs';

import { HowToUploadComponent } from './contents/how-to/how-to-upload.component';
import { ImageUploadComponent } from './contents/image-upload/image-upload.component';
import { MatChoiceComponent } from './contents/mat-choice/mat-choice.component';
import { DetectionResultComponent } from './contents/detection-result/detection-result.component';
import { UploadDialogService } from './upload-dialog.service';
import { DEFAULT_DIALOG_SIZE, DialogSize } from '../../../dialog/dialog-container.component';
import { ContourConfigurationComponent } from './contents/contour-configuration/contour-configuration.component';

@Directive({
    selector: '[appUploadDialogContent]'
})
export class UploadDialogContentDirective {
    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
    selector: 'app-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss'],
    providers: [UploadDialogService]
})
export class UploadDialogComponent extends PortalSlot implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(UploadDialogContentDirective, { static: true })
    readonly uploadDialogContentSlot: UploadDialogContentDirective;

    private attachedComponentRef: ComponentRef<any>;

    forwardButtonText: string;
    backwardButtonText: string;
    backwardButtonIcon: string = 'icon-close';
    private buttonBackText: string;
    private buttonNextText: string;
    private buttonFinishText: string;
    private buttonCancelText: string;

    private isDetached: Subject<void> = new Subject();

    dialogTitle: string;
    isDetectionResultView = false;

    constructor(
        public dialogRefs: DialogRefs<UploadDialogComponent>,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private injector: Injector,
        private uploadDialogService: UploadDialogService,
        private ngZone: NgZone,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        const howToPortal = new InlComponentPortal(HowToUploadComponent, this.injector);
        const underlayPortal = new InlComponentPortal(MatChoiceComponent, this.injector);
        const imageUploadPortal = new InlComponentPortal(ImageUploadComponent, this.injector);
        const detectionResultPortal = new InlComponentPortal(
            DetectionResultComponent,
            this.injector
        );
        const contourConfigPortal = new InlComponentPortal(
            ContourConfigurationComponent,
            this.injector
        );

        this.uploadDialogService.setDialogContents([
            new UploadDialogContent(
                this.translateService.instant('TOOL_CAPTURE_INSTRUCTION_DIALOG.TITLE'),
                howToPortal
            ),
            new UploadDialogContent(
                this.translateService.instant('MAT_CHOICE_DIALOG.TITLE'),
                underlayPortal
            ),
            new UploadDialogContent(
                this.translateService.instant('IMAGE_UPLOAD_DIALOG.TITLE'),
                imageUploadPortal
            ),
            new UploadDialogContent(
                this.translateService.instant('DETECTION_RESULT_DIALOG.TITLE'),
                detectionResultPortal,
                'large'
            ),
            new UploadDialogContent(
                this.translateService.instant('CONTOURS_CONFIG_UPLOAD_DIALOG.TITLE'),
                contourConfigPortal
            )
        ]);

        // Note: we cannot use AfterViewInit to set the first content as the content will be set
        // after the change detection. This might cause ExpressionChangedAfterItHasBeenCheckedError
        // when updating the dialog-title and poses other delay-detection issues in testing
        this.uploadDialogService
            .getContentChanged()
            .pipe(filter(content => content !== null))
            .subscribe((content: UploadDialogContent<any>) => {
                this.loadContent(content);
            });
        this.uploadDialogService.loadNextContent();
    }

    ngAfterViewInit() {}

    /** Executes a function when the zone is stable. */
    private executeOnStable(fn: () => any): void {
        if (this.ngZone.isStable) {
            fn();
        } else {
            this.ngZone.onStable
                .asObservable()
                .pipe(take(1))
                .subscribe(fn);
        }
    }

    private updateDialogSize(content: UploadDialogContent<any>) {
        this.dialogRefs.dialogContainer.dialogSize = content.dialogSize
            ? content.dialogSize
            : DEFAULT_DIALOG_SIZE;
    }

    private loadContent<T extends UploadDialogContentComponent>(content: UploadDialogContent<T>) {
        // this.updateNavButtons();
        this.detach();
        // it is save to update the title now
        // also it allows us to detect the title in tests without calling fixture.detectChanges() twice
        this.dialogTitle = content.title;

        // Use promise to delay the attachment of the new content in order to ensure that
        // the previous content was removed from the DOM
        Promise.resolve().then(() => {
            // Updating the dialog size before attaching the content is important
            // for computing the max-width/max-height of the detection-result canvas
            this.updateDialogSize(content);
            this.attach(content.componentPortal);

            const compInstance = <UploadDialogContentComponent>(
                content.componentPortal.componentRef.instance
            );
            // reset validity
            this.uploadDialogService.setContentValidity(false);
            compInstance.onAttached();
            compInstance
                .isValid()
                .pipe(takeUntil(this.isDetached))
                .subscribe(isValid => {
                    this.uploadDialogService.setContentValidity(isValid);
                    // this.isNextButtonDisabled = !isValid;
                });
        });
    }

    private updateNavButtons() {
        if (this.uploadDialogService.hasPreviousContent()) {
            this.backwardButtonIcon = 'arrow_left';
            this.backwardButtonText = this.buttonBackText;
        } else {
            this.backwardButtonIcon = 'icon-close';
            this.backwardButtonText = this.buttonCancelText;
        }

        this.forwardButtonText = this.uploadDialogService.hasNextContent()
            ? this.buttonNextText
            : this.buttonFinishText;
    }

    attach<T>(portal: InlComponentPortal<T>, cachedComponentInstance?: T): ComponentRef<T> {
        super.checkPortalArgs(portal);
        return this.attachComponentPortal(portal);
    }

    private attachComponentPortal<T>(portal: InlComponentPortal<T>): ComponentRef<T> {
        portal.attach(this, true);
        const viewContainerRef = this.uploadDialogContentSlot.viewContainerRef;

        if (portal.componentRef) {
            viewContainerRef.detach(0);
            viewContainerRef.insert(portal.componentRef.hostView);
            this.attachedComponentRef = portal.componentRef;
        } else {
            const resolver = portal.componentFactoryResolver || this.componentFactoryResolver;
            const componentFactory = resolver.resolveComponentFactory(portal.component);
            if (viewContainerRef.length > 0) {
                viewContainerRef.detach(0);
            }
            this.attachedComponentRef = viewContainerRef.createComponent(
                componentFactory,
                viewContainerRef.length,
                portal.injector || viewContainerRef.injector
            );
            portal.componentRef = this.attachedComponentRef;
            // needed to make sure that the ngOnInit is called immediately otherwise the
            // onAttached() method will overtake
            this.attachedComponentRef.changeDetectorRef.detectChanges();
        }

        this.attachedPortal = portal;
        return this.attachedComponentRef;
    }

    /**
     * Detach the attached portal (dialog-content) without clearing it out as we want to cache them
     * *
     */
    detach(): any {
        super.detach();
        this.uploadDialogContentSlot.viewContainerRef.detach(0);
        // this.disposeAttachedComponents();
    }

    hasAttached(): boolean {
        return !!this.attachedPortal;
    }

    ngOnDestroy() {
        this.dispose();
    }

    dispose(): void {
        super.dispose();
        this.disposeAttachedComponents();
        this.attachedPortal = null;
        this.attachedComponentRef = null;
    }

    private disposeAttachedComponents() {
        const contents = this.uploadDialogService.getDialogContents();
        if (contents) {
            contents.forEach(content => {
                if (content.componentPortal.componentRef) {
                    content.componentPortal.componentRef.destroy();
                }
            });
        }
    }
}

export class UploadDialogContent<T extends UploadDialogContentComponent> {
    title: string;
    componentPortal: InlComponentPortal<T>;
    dialogSize: DialogSize;

    constructor(title: string, componentPortal: InlComponentPortal<T>, dialogSize?: DialogSize) {
        this.title = title;
        this.componentPortal = componentPortal;
        this.dialogSize = dialogSize;
    }
}

export interface UploadDialogContentComponent {
    isValid(): Observable<boolean>;

    onAttached(): void;

    onLoadNextContent(): void;

    onLoadPrevContent(): void;
}
