<app-dialog-header>
    <h1 class="app-dialog-title">{{ 'SAVE_CONFIG_DIALOG.TITLE' | translate }}</h1>
</app-dialog-header>
<app-dialog-content>
    <div class="app-dialog-body">
        <p class="app-dialog-body-text">{{ 'SAVE_CONFIG_DIALOG.TEXT' | translate }}</p>
    </div>
</app-dialog-content>
<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'secondary'"
            iconAlign="horizontal"
            textPosition="after"
            buttonSize="normal"
            (click)="cancel()"
        >
            <app-icon svgWidth="24" svgHeight="24" shape="icon-close" iconSize="small"> </app-icon>
            {{ 'NAVIGATION_BUTTON.CANCEL' | translate }}
        </app-button>
        <app-button app-icon-button [color]="'primary'" buttonSize="normal" (click)="exit()">
            {{ 'NAVIGATION_BUTTON.SAVE' | translate }}
        </app-button>
    </div>
</app-dialog-footer>
