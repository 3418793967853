export function getOffset(elem: HTMLElement): { x: number; y: number } {
    const box = elem.getBoundingClientRect();
    const doc = elem.ownerDocument;
    const body = doc.body;
    const docElem = doc.documentElement;

    // HACK: IE < 8 has a 2px border by default
    // We remove this border by subtracting clientTop and clientLeft
    // TODO remove it since we don't support IE 8
    /*  const clientTop = docElem.clientTop || body.clientTop || 0,
        clientLeft = docElem.clientLeft || body.clientLeft || 0; */

    const top = box.top + (window.pageYOffset || docElem.scrollTop || body.scrollTop);
    const left = box.left + (window.pageXOffset || docElem.scrollLeft || body.scrollLeft);

    return {
        y: top,
        x: left
    };
}

export function getScrollX(): number {
    return (
        window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0
    );
}

export function getScrollY(): number {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
}

export function isEqualsBounds(bound1: DOMRect, bound2: DOMRect) {
    if (bound1 === bound2) {
        return true;
    }
    type DOMRectKeys = keyof DOMRect;
    const rectKeys: DOMRectKeys[] = ['width', 'height', 'x', 'y', 'top', 'bottom', 'left', 'right'];
    const result = rectKeys.find(x => bound1[x] !== bound2[x]);
    return result === undefined;
}

// TODO replace with HTMLElement.insertBefore
export function sendToFront(parent: HTMLElement, node: Node) {
    const currentFront = parent.lastChild;
    if (currentFront !== node) {
        parent.replaceChild(node, currentFront);
        parent.insertBefore(currentFront, node);
    }
}

export function sendToBack(parent: HTMLElement, node: Node) {
    const currentBack = parent.firstChild;
    if (currentBack !== node) {
        // parent.replaceChild(node, currentBack);
        parent.insertBefore(node, currentBack);
    }
}

export function createGroup(layer: Snap.Paper, className: string, attr?): Snap.Paper {
    const group = layer.g();
    group.node.classList.add(className);
    if (attr) {
        group.attr(attr);
    }
    return group;
}

export function createPath(pathString, group, attr?, className?): Snap.Element {
    const path = group.path(pathString);
    path.attr(attr);
    if (className) {
        path.addClass(className);
    }
    return path;
}
