import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { fromEvent, ReplaySubject } from 'rxjs';
import {
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    share,
    take,
    takeUntil
} from 'rxjs/operators';

import { CanvasDroppableHandler } from './canvas-droppable-handler.service';
import { DroppableContainerDirective } from '../../droppable/droppable-container.directive';
import { EditorInputEvents } from '../foam-editor.service';
import { EventHelpers } from '../shared/event-helpers';
import { Dimension2D } from '../shared/geom/dimension2d';
import { Point2D } from '../shared/geom/point2D';
import { Rectangle2D } from '../shared/geom/rectangle2D';
import { CollisionHandlerService } from './collision/collision-handler.service';
import { CanvasDomService } from './canvas-dom.service';
import { coerceBooleanProperty } from '../../utils/coerce-boolean';

import {
    CanvasBoundsChange,
    CanvasBoundsManager,
    ZoomAndScrollableCanvas
} from './shared/canvas-bounds.manager';
import { CanvasScrollViewportDirective } from './shared/canvas-scroll-viewport.directive';
import { CanvasProperties, CanvasService, SelectionChangedData } from './canvas.service';
import { ReadOnlyContourTransformData } from './contour-element-builder.service';
import {
    ContourChangeData,
    ContourDrawData,
    ContourItemsRemoved,
    FoamDrawData,
    RubberBandDrawData
} from './contour/contour-items-interfaces';
import { LayerElementStoreService } from './layer-element-store.service';
import { CanvasLayer } from './layers/canvas-layer';
import { CanvasOverlayLayerDirective } from './layers/canvas-overlay-layer.directive';
import { CollisionLayerDirective } from './layers/collision-layer.directive';
import { ContourItemsLayerDirective } from './layers/contour-items-layer.directive';
import { FixedCollisionLayerDirective } from './layers/fixed-collision-layer.directive';
import { FoamLayerDirective } from './layers/foam-layer.directive';
import { ItemsLayerDirective } from './layers/items-layer.directive';
import { SafetyMarginLayerDirective } from './layers/safety-margin-layer.directive';
import { SelectionLayerDirective } from './layers/selection-layer.directive';
import { RubberBandDrawer } from './rubber-band-drawer';
import { ScaleFactorState } from './shared/scalefactor-state';

declare var Snap: any;

@Component({
    selector: 'app-canvas',
    templateUrl: './canvas.component.html',
    styleUrls: ['./canvas.component.scss'],
    providers: [CanvasOverlayLayerDirective],
    host: {
        '[attr.tabindex]': 'this.disabled ? -1 : 0'
    }
})
export class CanvasComponent implements ZoomAndScrollableCanvas, OnInit, AfterViewInit, OnDestroy {
    private eventHandlers: EditorInputEvents;

    @ViewChild('textContourInput', { static: false })
    readonly textContourInputElement: ElementRef<HTMLElement>;

    private _enabled: boolean = true;

    dropZoneId = 'workspace-dropzone';
    canvasProperties: CanvasProperties;

    @ViewChild(CanvasScrollViewportDirective, { static: false })
    readonly scrollViewport: CanvasScrollViewportDirective;

    @ViewChild('canvasContent', { static: false })
    readonly canvasContent: ElementRef<SVGSVGElement>;

    readonly backgroundCenterPadding: [number, number, number, number];

    @ViewChild(ItemsLayerDirective, { static: false })
    readonly itemsLayer: ItemsLayerDirective;

    @ViewChild(ContourItemsLayerDirective, { static: false })
    readonly contourLayer: ContourItemsLayerDirective;

    @ViewChild(SafetyMarginLayerDirective, { static: false })
    readonly marginLayer: SafetyMarginLayerDirective;

    @ViewChild(FoamLayerDirective, { static: false })
    readonly foamLayer: FoamLayerDirective;

    @ViewChild(SelectionLayerDirective, { static: false })
    readonly selectionLayer: SelectionLayerDirective;

    @ViewChild(CollisionLayerDirective, { static: false })
    readonly collisionLayer: CollisionLayerDirective;

    @ViewChild(FixedCollisionLayerDirective, { static: false })
    readonly fixedCollisionLayer: FixedCollisionLayerDirective;

    @ViewChild(CanvasOverlayLayerDirective, { static: false })
    readonly canvasOverlayLayer: CanvasOverlayLayerDirective;

    @ViewChild(DroppableContainerDirective, { static: false })
    readonly droppableContainer: DroppableContainerDirective;

    svgSnapElement: Snap.Paper;

    private rubberBand: RubberBandDrawer;
    showScrollBars: boolean = true;
    private canvasLayers: CanvasLayer[];
    private canvasBoundsManager: CanvasBoundsManager;

    @Output()
    readonly scaleFactorChanged = new EventEmitter<ScaleFactorState>();

    /** Emits once during the component's ngOnInit */
    readonly initialized = new ReplaySubject<boolean>(1);

    private _disabled: boolean = false;

    constructor(
        public readonly elementRef: ElementRef<HTMLElement>,
        private readonly canvasService: CanvasService,
        private readonly collisionHandler: CollisionHandlerService,
        private readonly layerElementStore: LayerElementStoreService,
        readonly canvasDroppableHandler: CanvasDroppableHandler,
        private readonly workspaceDomService: CanvasDomService,
        private _ngZone: NgZone
    ) {
        this.backgroundCenterPadding = [10, 10, 10, 10];
    }

    ngOnInit() {
        this.canvasService
            .getProperties()
            .pipe(distinctUntilChanged())
            .subscribe(props => {
                // TODO to be removed
                this.canvasProperties = props;
            });

        this.initialized.next(true);
    }

    getCanvasBackgroundLocalBounds(): Rectangle2D | undefined {
        if (this.foamLayer) {
            // const bbox = this.foamLayer.getBBox();
            return new Rectangle2D(0, 0, this.foamLayer.foamWidth, this.foamLayer.foamHeight);
        }
        return undefined;
    }

    ngAfterViewInit() {
        this.svgSnapElement = Snap(this.canvasContent.nativeElement);

        this.workspaceDomService.svgCanvas = this.canvasContent;

        this.itemsLayer.overlayLayer = this.canvasOverlayLayer;

        this.workspaceDomService.foamLayer = this.foamLayer;
        this.workspaceDomService.fixedCollisionLayer = this.fixedCollisionLayer;
        this.workspaceDomService.selectionLayer = this.selectionLayer;
        this.workspaceDomService.canvasOverlayLayer = this.canvasOverlayLayer;
        this.workspaceDomService.collisionLayer = this.collisionLayer;
        this.workspaceDomService.contourItemsLayer = this.contourLayer;
        this.workspaceDomService.droppableContainer = this.droppableContainer;
        this.workspaceDomService.svgCanvas = this.canvasContent;
        this.workspaceDomService.textContourInputElement = this.textContourInputElement;
        this.workspaceDomService.contourItemsLayer.marginLayer = this.marginLayer;

        this.getInputEvents();
        this.canvasService.setFocusRequestHandler(() => this.requestFocus());
        this.canvasService.setContentOffsetHandler(() => this.getFoamLayerOffset());
        this.contourLayer.parentLayer = this.itemsLayer;
        this.marginLayer.parentLayer = this.itemsLayer;

        this.canvasBoundsManager = new CanvasBoundsManager(this, this._ngZone);
        this.canvasLayers = [
            this.itemsLayer,
            this.foamLayer,
            this.contourLayer,
            this.marginLayer,
            this.selectionLayer,
            this.fixedCollisionLayer,
            this.collisionLayer,
            this.canvasOverlayLayer
        ];

        // the order matter

        this.scrollViewport.onScrolled.subscribe(scrollPos => {
            return (this.canvasService.viewportScrollPosition = scrollPos);
        });

        this.canvasService
            .getOnScaleFactorChanged()
            .subscribe(scale => this.canvasBoundsManager.setScaleFactor(scale));

        this.canvasBoundsManager.canvasBoundsChange.subscribe(change =>
            this.handleCanvasBoundsChange(change)
        );

        this.canvasService
            .getOnScaleFactorChanged()
            .subscribe(scale => this.canvasBoundsManager.setScaleFactor(scale));

        this.canvasBoundsManager.canvasBoundsChange.subscribe(change =>
            this.handleCanvasBoundsChange(change)
        );

        this.canvasService.getOnFoamChanged().subscribe(drawData => this.setFoamItem(drawData));

        this.canvasService.getOnContourAdded().subscribe(drawData => this.addContourItem(drawData));
        this.canvasService.getOnContourChanged().subscribe(data => this.changeContourItem(data));

        this.canvasService.getOnSelectionChanged().subscribe(data => this.handleSelection(data));
        this.canvasService.getOnRubberBandChanged().subscribe(rubberBandData => {
            this.drawRubberBand(rubberBandData);
        });

        this.canvasService.getContourItemRemoved().subscribe(data => this.removeContourItems(data));

        this.layerElementStore.getFoamContourChange().subscribe(foamChangeData => {
            this.canvasService.onFoamContourDrawn(foamChangeData);
            // also check update innerFoam here to have the correct matrix
            this.canvasService.onFoamInnerMarginContourDrawn(foamChangeData);
        });

        this.layerElementStore
            .getFoamInnerMarginContourChange()
            .subscribe(foamInnerMarginChangeData => {
                this.canvasService.onFoamInnerMarginContourDrawn(foamInnerMarginChangeData);
            });

        this.layerElementStore.elementChanged.subscribe(event => {
            event.forEach(contourData =>
                this.canvasService.onContourDrawn(contourData, contourData.isNewContour)
            );
        });

        this.layerElementStore.htmlTextInputContainer = this.textContourInputElement;
    }

    private handleCanvasBoundsChange(change: CanvasBoundsChange) {
        if (change.canvasViewportBounds) {
            this.canvasService.setCanvasViewportBounds(change.canvasViewportBounds);
        }
        if (change.canvasContentBounds) {
            this.canvasService.setCanvasContentBounds(change.canvasContentBounds);
            const contentBounds = this.canvasBoundsManager.getCanvasContentBounds();
            this.scrollViewport.scrollContentToCenter(
                new Dimension2D(contentBounds.width, contentBounds.height)
            );
        }

        // update canvas layers (child components( first
        if (change.scaleFactor) {
            this.canvasLayers.forEach((l: CanvasLayer) =>
                l.scaleLayers(change.scaleFactor, change.centerXY)
            );
            this.showScrollBars =
                this.canvasBoundsManager.scaleFactorToFitViewport < change.scaleFactor;
        }

        if (change.scaleFactor || change.canvasViewportBounds) {
            const scaleFactor = this.canvasBoundsManager.getScaleFactor();
            const viewportBounds = this.canvasBoundsManager.getCanvasViewportBounds();
            if (scaleFactor != null && viewportBounds != null) {
                this.canvasService.updateFoamCollisionClipPath(scaleFactor, viewportBounds);
            }
        }

        // update canvasService and parent components
        if (change.scaleFactor) {
            this.canvasService.setScaleFactor(change.scaleFactor);
            this.scaleFactorChanged.emit({
                scaleFactor: change.scaleFactor,
                scaleFactorToFitViewport: this.canvasBoundsManager.scaleFactorToFitViewport
            });
        }
    }

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(disabled: any) {
        this._disabled = coerceBooleanProperty(disabled);
    }

    setCanvasViewport(
        newViewport: Rectangle2D,
        scaleToViewPort: boolean = false,
        scrollToCenter: boolean = false
    ) {
        this.canvasBoundsManager.setViewport(newViewport, scaleToViewPort, scrollToCenter);
    }

    private createInputEvents() {
        const evtTarget = this.elementRef.nativeElement;

        const mouseDowns = fromEvent<MouseEvent>(evtTarget, 'mousedown', {
            capture: true
        }).pipe(filter(() => this.enabled));

        const mouseMoves = fromEvent<MouseEvent>(window, 'mousemove', {
            capture: false
        }).pipe(filter(() => this.enabled));

        /*
        const mouseUps = <Observable<MouseEvent>>(
            fromEvent(window, 'mouseup', { capture: false }).pipe(filter(() => this.enabled))
        ); */

        const windowMouseUps = fromEvent<MouseEvent>(window, 'mouseup', {
            capture: false
        });
        const mouseUps = mouseDowns.pipe(
            concatMap(() =>
                windowMouseUps.pipe(
                    // emit only the first event and unsubscribe the inner observable.
                    // This makes sure that the ony the first mouseup event after each
                    // mouseDown (from the source) is emitted
                    take(1)
                )
            ),
            share()
        );

        const dblClicks = fromEvent<MouseEvent>(window, 'dblclick', {
            capture: false
        }).pipe(filter(() => this.enabled));

        const windowBlur = fromEvent<MouseEvent>(window, 'blur', {
            capture: false
        }).pipe(filter(() => this.enabled));

        fromEvent<MouseEvent>(document, 'mousedown', { capture: true })
            .pipe(
                filter((evt: MouseEvent) => {
                    const targetNode = evt.target as Node;
                    if (targetNode == null) {
                        return false;
                    } else if (targetNode.nodeName && targetNode.nodeName === 'INPUT') {
                        return false;
                    } else {
                        const firstChild = targetNode.firstChild;
                        return (
                            evt.detail > 1 &&
                            (!firstChild || firstChild.nodeType !== Node.TEXT_NODE)
                        );
                    }
                })
            )
            .subscribe(e => {
                e.preventDefault();
            });

        // fromEvent(document.body, 'focus', { capture: true }).subscribe(x => console.log(x));

        const mouseDrags = mouseDowns.pipe(
            concatMap(mouseDownEvent => {
                return mouseMoves.pipe(
                    // When the window loses focus, the mouseUps may not be captured and thus
                    // the drag gesture cannot be stopped afterwards. Therefore we use the blur
                    // event to stop the drag gesture whenever the window loses focus
                    takeUntil(windowBlur),
                    takeUntil(mouseUps),
                    pairwise(),
                    map((x: MouseEvent[]) => {
                        return EventHelpers.toSelectionDragEvent(x, mouseDownEvent);
                    })
                );
            })
        );

        const keyDown = fromEvent<KeyboardEvent>(window, 'keydown', {
            capture: false
        }).pipe(
            filter(e => {
                return this.enabled && (e.target as Node).nodeName !== 'INPUT';
            })
        );

        const keyUp = fromEvent<KeyboardEvent>(window, 'keyup', {
            capture: true
        }).pipe(filter(() => this.enabled));

        this.eventHandlers = {
            mouseDowns: mouseDowns,
            mouseMoves: mouseMoves,
            mouseUps: mouseUps,
            mouseDrag: mouseDrags,
            dblClicks: dblClicks,
            keyDown: keyDown,
            keyUp: keyUp
        };
    }

    getInputEvents(): EditorInputEvents {
        if (!this.eventHandlers) {
            this.createInputEvents();
        }
        return this.eventHandlers;
    }

    requestFocus() {
        this.elementRef.nativeElement.focus();
    }

    /**
     * Returns x,y location of the canvas draw area ({@link ItemsLayerDirective}) relative to the
     * document
     */
    getFoamLayerOffset(): Point2D {
        // TODO cache this
        const canvasViewportElem = this.scrollViewport.getElement().nativeElement;
        const contentOffset = this.itemsLayer.layerOffset;
        return new Point2D(
            contentOffset.x - canvasViewportElem.scrollLeft,
            contentOffset.y - canvasViewportElem.scrollTop
        );
    }

    private setFoamItem(contourData: FoamDrawData) {
        this.foamLayer.setFoamItem(contourData);
    }

    /**
     * Adds the parts of the contour item that are specific to the layer.
     * This method should be called by the {@link CanvasService} when a contour item has been added.
     *
     * @param contourData
     */
    private addContourItem(contourData: ContourDrawData) {
        // const drawnDataArr: CanvasElementChangedEvent[] = [];
        this.requestFocus();
        this.itemsLayer.addContourItem(contourData);

        // The order matter
        /*  [this.contourLayer, this.marginLayer, this.selectionLayer].forEach(d => {
              const drawnData = (d as CanvasContourLayer).addContourItem(contourData);
              if (drawnData) {
                  drawnDataArr.push(drawnData);
              }
          });

        const elementHolder = this.layerElementStore.getContourElements2(contourData.contourId); */
    }

    /**
     * Removes the parts of the given contour item.
     * This method is invoked when a contour item has been removed.
     * @param data
     */
    private removeContourItems(data: ContourItemsRemoved) {
        this.itemsLayer.removeContourItem(data);
    }

    changeContourItem(contourData: ContourChangeData) {
        this.requestFocus();
        this.itemsLayer.changeContourItem(contourData);
    }

    private handleSelection(data: SelectionChangedData) {
        this.itemsLayer.selectContourItems(data);
    }

    drawRubberBand(rubberBandData: RubberBandDrawData) {
        if (!this.rubberBand) {
            this.rubberBand = new RubberBandDrawer(this.svgSnapElement);
        }
        this.rubberBand.draw(rubberBandData);
    }

    @Input()
    set enabled(newValue: boolean) {
        if (newValue !== this._enabled) {
            this._enabled = newValue;
            this.canvasService.setEnabled(newValue);
        }
    }

    get enabled(): boolean {
        return this._enabled;
    }

    ngOnDestroy() {
        if (this.canvasBoundsManager) {
            this.canvasBoundsManager.destroy();
        }
    }
}

/**
 * Describes the change (e.g., transformation) applied to a DOM element of a {@link
 * CanvasContourLayer}
 */
export interface CanvasElementChangedEvent {
    readonly contourId: string;
    readonly isNewContour: boolean;
    margin?: { marginPathString?: string } & ReadOnlyContourTransformData;
    contour?: { svgPathDefinition?: string } & ReadOnlyContourTransformData;
    children?: { [contourId: string]: CanvasElementChangedEvent };
    readonly textContent?: string;
}
