import { NgModule } from '@angular/core';
import { UploadDialogComponent, UploadDialogContentDirective } from './upload-dialog.component';
import { UploadDialogFooterComponent } from './upload-dialog-footer/upload-dialog-footer.component';
import { HowToUploadComponent } from './contents/how-to/how-to-upload.component';
import { ImageUploadComponent } from './contents/image-upload/image-upload.component';
import { MatChoiceComponent } from './contents/mat-choice/mat-choice.component';
import { DetectionResultComponent } from './contents/detection-result/detection-result.component';
import { ContourConfigurationComponent } from './contents/contour-configuration/contour-configuration.component';
import { DialogModule } from '../../../dialog/dialog.module';
import { SharedModule } from '../../../shared/shared.module';
import { DetectionResultCanvasComponent } from './contents/detection-result/detection-result-canvas/detection-result-canvas.component';
import { TranslationSharedModule } from '../../../shared/translation-shared.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollViewportModule } from '../../scroll-viewport/scroll-viewport.module';
import { CanvasModule } from '../../canvas/canvas.module';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        TranslationSharedModule,
        DialogModule,
        ScrollViewportModule,
        CanvasModule
    ],
    declarations: [
        UploadDialogComponent,
        UploadDialogFooterComponent,
        UploadDialogContentDirective,
        HowToUploadComponent,
        MatChoiceComponent,
        ImageUploadComponent,
        DetectionResultComponent,
        ContourConfigurationComponent,
        DetectionResultCanvasComponent
    ],
    exports: [
        DialogModule,
        UploadDialogComponent,
        UploadDialogFooterComponent,
        UploadDialogContentDirective,
        HowToUploadComponent,
        ImageUploadComponent,
        MatChoiceComponent,
        DetectionResultComponent,
        ContourConfigurationComponent,
        DetectionResultCanvasComponent
    ],
    entryComponents: [
        UploadDialogComponent,
        HowToUploadComponent,
        MatChoiceComponent,
        ImageUploadComponent,
        DetectionResultComponent,
        ContourConfigurationComponent
    ]
})
export class UploadContourDialogModule {}
