import { PaintSelectionModel } from './paint-selection-model';
import { Point2D } from '../../../../../shared/geom/point2D';
import { ContourSegmentationResult } from '../detection-result-canvas/detection-result-canvas.component';
import {
    competingFill,
    freeCachedRgbColors,
    getRGBColor,
    ImageInfo,
    optimizeFunctionOnNextCall
} from './edge-respecting-selection';
import { create4NeighborPixels, getPixelCoordinate, getPixelIndex, PixelInfo } from './pixel-math';
import { Bounds, ZERO_BOUNDS } from '../../../../../shared/geom/geometry';

export enum SelectionMode {
    ADD,
    REMOVE
}

export const SELECTION_ADD_FILL_COLOR = 1;
export const SELECTION_REMOVE_FILL_COLOR = 0;

export class PaintSelection {
    selectionMode: SelectionMode = SelectionMode.ADD;

    private readonly paintSelectionModel: PaintSelectionModel;
    private imageInfo: ImageInfo;
    private hasContours: boolean = false;
    private cachedBorderIndices: number[];
    /**
     * All selected pixels from the startSelection to the last updateSelection call.
     */
    private readonly allSelectedPixelIndices: Set<number>;
    private hasImage: boolean;

    constructor() {
        this.allSelectedPixelIndices = new Set<number>();
        this.paintSelectionModel = new PaintSelectionModel();
    }

    public setImageData(
        imageData: Uint8ClampedArray,
        width: number,
        height: number,
        channels: number
    ) {
        if (imageData == null || !(imageData instanceof Uint8ClampedArray) || channels == null) {
            console.warn('Invalid background image data');
            return;
        }
        freeCachedRgbColors();

        const pixelMap: PixelInfo[] = new Array<PixelInfo>(width * height);
        for (let i = 0, len = pixelMap.length; i < len; i++) {
            const rgb = getRGBColor(i, imageData, channels);
            pixelMap[i] = {
                color: rgb,
                summedColor: rgb[0] + rgb[1] + rgb[2],
                coordinate: getPixelCoordinate(i, width)
            };
        }

        this.imageInfo = {
            data: imageData,
            width: width,
            height: height,
            channels: channels,
            pixelMap: pixelMap
        };

        this.hasImage = true;
    }

    /**
     * Sets and updates data needed to perform paint selection
     * @param segmentationData
     * @param width the width of the paper image
     * @param height the height of the paper image
     * @param imageData
     * @param channels
     */
    public setContours(
        segmentationData: ContourSegmentationResult,
        width: number,
        height: number
    ): void {
        this.paintSelectionModel.init(segmentationData, width, height);

        // If the segmentation was refined, then the contour mask was already
        // filled during the mouse move
        // fill imageMask
        fillRegionsByMask(
            this.paintSelectionModel.contoursMask,
            this.paintSelectionModel.compoundSelectionMask,
            this.paintSelectionModel.width,
            this.paintSelectionModel.contoursBounds
        );

        this.hasContours = true;
    }

    /**
     * Start the paint selection
     *
     * Note: At the selection start, the paintIndices newPaintIndices are equal.
     * The borderIndices includes the borders of all contours and of the new selection
     *
     * @param mousePt
     * @param selectionMode
     * @param radius
     */
    public startSelection(
        mousePt: Point2D,
        selectionMode: SelectionMode,
        radius: number = 4
    ): PaintSelectionResult {
        if (!this.checkContours()) {
            return null;
        }

        const selectedPixelIndices = competingFill(this.imageInfo, mousePt, radius);

        selectedPixelIndices.forEach(pixel => this.allSelectedPixelIndices.add(pixel));

        const model = this.paintSelectionModel;
        model.allSelectedRegions.clear();
        model.currentSelectedRegions.clear();
        model.lastMaskFillIndices.clear();
        const { borderIndices, selectionBounds } = this.drawSelectionBorder(
            selectedPixelIndices,
            selectionMode,
            false
        );

        this.cachedBorderIndices = borderIndices;

        return {
            borderIndices: borderIndices,
            paintIndices: selectedPixelIndices,
            newPaintIndices: selectedPixelIndices,
            bounds: selectionBounds
        };
    }

    /**
     * Updates the selection
     *
     * Note: Unlike at the selection start, borderIndices includes only the new borders created by
     * the new selection (based on the pixels)
     *
     * @param mousePt
     * @param selectionMode
     * @param radius
     */
    public updateSelection(
        mousePt: Point2D,
        selectionMode: SelectionMode,
        radius: number = 4
    ): PaintSelectionResult {
        if (!this.checkContours()) {
            return null;
        }
        const selectedPixelIndices = competingFill(this.imageInfo, mousePt, radius);
        const newPaintIndices = selectedPixelIndices.filter(
            pixel => !this.allSelectedPixelIndices.has(pixel)
        );
        selectedPixelIndices.forEach(pixel => this.allSelectedPixelIndices.add(pixel));

        const { borderIndices, selectionBounds } = this.drawSelectionBorder(
            selectedPixelIndices,
            selectionMode
        );

        this.cachedBorderIndices = borderIndices;
        return {
            borderIndices: borderIndices,
            paintIndices: selectedPixelIndices,
            newPaintIndices: newPaintIndices,
            bounds: selectionBounds
        };
    }

    public endSelection(
        selectionMode: SelectionMode
    ): { allSelectedRegions: number[]; compoundBounds: Bounds } {
        if (!this.checkContours()) {
            return null;
        }

        // clear newSelectionMask selections (see newSelectionOnly fillRegions's parameter)
        const selectedRegions = Array.from(this.paintSelectionModel.allSelectedRegions);
        const borderBounds = getBorderBounds(
            selectedRegions,
            this.paintSelectionModel.borderIndices[this.paintSelectionModel.currentLevel],
            this.paintSelectionModel.width,
            this.paintSelectionModel.height
        );
        fillRegions(
            selectedRegions,
            borderBounds,
            SELECTION_REMOVE_FILL_COLOR,
            this.paintSelectionModel,
            true
        );

        // Clean mask
        if (selectionMode === SelectionMode.ADD) {
            // Remove all regions added to the mask (since the last mouse down)
            // This also ensures that the server generated mask is the single source of truth after
            // releasing the mouse
            fillSelectionMask(
                this.paintSelectionModel.lastMaskFillIndices,
                this.paintSelectionModel.compoundSelectionMask,
                0
            );
        } else if (selectionMode === SelectionMode.REMOVE) {
            // Avoid regions that are too small to be still visible when editing the segmentation
            // This typically happens when removing regions from a contour such that the edited contour
            // is divided into several regions and some of these regions are smaller than the minimal
            // contour size.
            fillRegionsByMask(
                this.paintSelectionModel.contoursMask,
                this.paintSelectionModel.compoundSelectionMask,
                this.paintSelectionModel.width,
                this.paintSelectionModel.contoursBounds,
                0
            );
        }

        // clear mask
        const allSelectionBorderBounds = getBorderBounds(
            Array.from(this.paintSelectionModel.allSelectedRegions),
            this.paintSelectionModel.borderIndices[this.paintSelectionModel.currentLevel],
            this.paintSelectionModel.width,
            this.paintSelectionModel.height
        );
        const compoundBounds = getCompoundBounds(
            this.paintSelectionModel.contoursBounds,
            allSelectionBorderBounds
        );

        this.allSelectedPixelIndices.clear();

        return {
            allSelectedRegions: Array.from(this.paintSelectionModel.allSelectedRegions),
            compoundBounds: compoundBounds
        };
    }

    private checkContours(): boolean {
        if (!this.hasContours && !this.hasImage) {
            console.warn('Cannot start selection because no contour is found.');
            return false;
        }
        return true;
    }

    /**
     * Updates selection borders according to the given selected or deselected pixels.
     *
     * @param pixelIndices The selected or pixels for which the selection borders should be updated
     * @param selectionMode Determines whether the specified pixelIndices are selected or deselected pixels.
     * @param newSelectionOnly Determines whether only the new selection or all existing selections
     * are considered for computing the borderIndices and selectionBounds.
     */
    private drawSelectionBorder(
        pixelIndices: number[],
        selectionMode: SelectionMode,
        newSelectionOnly: boolean = true
    ): SelectedBorders {
        const model = this.paintSelectionModel;
        model.currentSelectedRegions.clear();

        selectRegionByPixels(pixelIndices, model);
        const selectedRegions = Array.from(model.currentSelectedRegions);
        const { newSelectionBounds, allSelectionsBounds } = this.drawRegionBorder(
            selectedRegions,
            selectionMode,
            model
        );

        const borderBounds = newSelectionOnly
            ? newSelectionBounds
            : getCompoundBounds(model.contoursBounds, allSelectionsBounds);

        const borderIndices: number[] = getBorderIndices(
            model,
            model.compoundSelectionMask,
            borderBounds
        );

        return {
            borderIndices: borderIndices,
            selectionBounds: borderBounds
        };
    }

    /**
     * Returns BorderIndices of the new selection only
     * @param selectedRegions
     * @param selectionMode
     * @param model
     */
    private drawRegionBorder(
        selectedRegions: number[],
        selectionMode: SelectionMode,
        model: PaintSelectionModel
    ): BorderBounds {
        if (!model || !model.borderIndices || !selectedRegions || selectedRegions.length === 0) {
            return undefined;
        }

        const width = model.width;
        const height = model.height;

        // update mask
        let pixelValue;
        if (selectionMode === SelectionMode.ADD) {
            pixelValue = SELECTION_ADD_FILL_COLOR;
        } else if (selectionMode === SelectionMode.REMOVE) {
            pixelValue = SELECTION_REMOVE_FILL_COLOR;
        }

        // get border bounds for the selected Regions only based on the segmentation borderIndices
        let selectionBorderBounds = getBorderBounds(
            selectedRegions,
            model.borderIndices[model.currentLevel],
            width,
            height
        );

        // fill only new selected regions, i.e selectedRegions == lastSelectedRegions
        const fillIndices = fillRegions(selectedRegions, selectionBorderBounds, pixelValue, model);
        fillIndices.forEach(x => model.lastMaskFillIndices.add(x));

        const allSelectionBorderBounds = getBorderBounds(
            Array.from(model.allSelectedRegions),
            model.borderIndices[model.currentLevel],
            width,
            height
        );

        const innerFillResult = fillInnerContours(model, allSelectionBorderBounds);
        innerFillResult.pixels.forEach(x => model.lastMaskFillIndices.add(x));
        // update selectionBorderBounds
        if (innerFillResult.regions.length > 0) {
            selectionBorderBounds = getBorderBounds(
                [...innerFillResult.regions, ...selectedRegions],
                model.borderIndices[model.currentLevel],
                width,
                height
            );
        }

        return {
            allSelectionsBounds: allSelectionBorderBounds,
            newSelectionBounds: selectionBorderBounds
        };
    }
}

function fillInnerContours(
    model: PaintSelectionModel,
    allSelectionBorderBounds: Bounds
): { pixels: number[]; regions: number[] } {
    const contours = traceContours(
        model.width,
        model.compoundSelectionMask,
        allSelectionBorderBounds
    );
    // const innerContours: { x: number; y: number }[][] = Object.values(contours.inners);
    const innerPixels: number[] = [];
    const innerRegions: number[] = [];
    const level = model.currentLevel;

    for (let h = 0, len = contours.length; h < len; h++) {
        const contour = contours[h];

        // check if is inner contour
        if (!contour[0]) {
            continue;
        }

        const { fillIndices } = fillContour(
            contour[2],
            model.width,
            model.height,
            model.newSelectionMask
        );
        // Mark inner contours as selected regions using newSelectionMask updated above
        for (let i = 0, len2 = fillIndices.length; i < len2; i++) {
            const k = fillIndices[i];
            const regionId = model.regionPixels[level][k];
            if (regionId) {
                model.allSelectedRegions.add(regionId);
                innerRegions.push(regionId);
                // update compound selection mask
                model.compoundSelectionMask[k] = 1;
                innerPixels.push(k);
            }
        }
    }
    return { pixels: innerPixels, regions: innerRegions };
}

type BorderBounds = { allSelectionsBounds: Bounds; newSelectionBounds: Bounds };
type SelectedBorders = { borderIndices: number[]; selectionBounds: Bounds };

export interface PaintSelectionResult {
    readonly borderIndices: number[];
    readonly paintIndices: number[];
    readonly newPaintIndices: number[];
    readonly bounds: Bounds;
}

/**
 * Creates a selection border that combines the border of the selected region at xy-position with
 * the contours' borders.
 * The contours
 * @deprecated
 * @param x
 * @param y
 * @param selectionMode
 * @param model
 */
export function createNewSelectionBorder(
    x: number,
    y: number,
    selectionMode: SelectionMode,
    model: PaintSelectionModel
): number[] {
    model.allSelectedRegions.clear();
    model.lastMaskFillIndices.clear();
    return createRegionBorders(x, y, selectionMode, model);
}

export function selectRegionByPixels(pixelIndices: number[], model: PaintSelectionModel) {
    const level = model.currentLevel;
    // const regionIds = [];
    for (let i = 0; i < pixelIndices.length; i++) {
        const regionId = model.regionPixels[level][pixelIndices[i]];
        if (regionId) {
            model.allSelectedRegions.add(regionId);
            model.currentSelectedRegions.add(regionId);
        }
    }
}

/**
 * @deprecated
 * @param ptX
 * @param ptY
 * @param selectionMode
 * @param model
 */
function createRegionBorders(
    ptX: number,
    ptY: number,
    selectionMode: SelectionMode,
    model: PaintSelectionModel
): number[] | undefined {
    if (!model.borderIndices) return undefined;
    if (ptX < 0 || ptY < 0) {
        return undefined;
    }

    //  getRegionIndices(x: number, y: number, allRegionPixels: number[], width: number, withNeighbors?: boolean)
    const regionIds = getRegionIndices(ptX, ptY, model, true);
    if (regionIds == null || regionIds.length === 0) {
        // get borderIndices based on the current mask as no region has been selected
        return getBorderIndices(model, model.compoundSelectionMask, model.contoursBounds);
    }

    const regionIdsToAdd = regionIds.filter(
        regId1 => !model.noSupportedRegionIds.some(regId2 => regId1 === regId2)
    );
    return drawRegionBorderById(regionIdsToAdd, selectionMode, model);
}

function drawRegionBorderById(
    regionIds: number[],
    selectionMode: SelectionMode,
    model: PaintSelectionModel
): number[] {
    if (!model || !model.borderIndices || !regionIds || regionIds.length === 0) {
        return undefined;
    }

    const width = model.width;
    const height = model.height;

    // update mask
    let pixelValue;
    if (selectionMode === SelectionMode.ADD) {
        pixelValue = SELECTION_ADD_FILL_COLOR;
    } else if (selectionMode === SelectionMode.REMOVE) {
        pixelValue = SELECTION_REMOVE_FILL_COLOR;
    }

    regionIds.forEach(x => model.allSelectedRegions.add(x));

    const selectedRegionsArr = Array.from(model.allSelectedRegions);
    const borderBounds = getBorderBounds(
        selectedRegionsArr,
        model.borderIndices[model.currentLevel],
        width,
        height
    );

    const fillIndices = fillRegions(selectedRegionsArr, borderBounds, pixelValue, model);
    fillIndices.forEach(x => model.lastMaskFillIndices.add(x));

    const compoundBounds = getCompoundBounds(model.contoursBounds, borderBounds);
    return getBorderIndices(model, model.compoundSelectionMask, compoundBounds);
}

function getCompoundBounds(bounds1: Bounds, bounds2: Bounds): Bounds {
    if (bounds1.width === 0 || (bounds1.height === 0 && bounds2.width > 0 && bounds2.height > 0)) {
        return bounds2;
    }
    if (bounds2.width === 0 || (bounds2.height === 0 && bounds1.width > 0 && bounds1.height > 0)) {
        return bounds1;
    }

    const minX = Math.min(bounds1.minX, bounds2.minX);
    const minY = Math.min(bounds1.minY, bounds2.minY);
    const maxX = Math.max(bounds1.maxX, bounds2.maxX);
    const maxY = Math.max(bounds1.maxY, bounds2.maxY);
    return {
        minX: minX,
        minY: minY,
        maxX: maxX,
        maxY: maxY,
        width: maxX - minX,
        height: maxY - minY
    };
}

function fillContour(
    // contour: { x: number; y: number }[],
    contour: number[],
    width: number,
    height: number,
    mask: Uint8Array
): { bounds: Bounds; fillIndices: number[] } {
    // NOSONAR
    let maxX = -1;
    let minX = width + 1;
    let maxY = -1;
    let minY = height + 1;

    const len = contour.length;
    for (let j = 0; j < len; j++) {
        const x = contour[j] % width;
        const y = (contour[j] - x) / width;
        // const y = contour[j].y;

        // check minmax for X
        if (x < minX) minX = x;
        if (x > maxX) maxX = x;

        if (y < minY) minY = y;
        if (y > maxY) maxY = y;
    }

    const fillIndices: number[] = [];
    let xx, yy;
    for (yy = minY; yy < maxY + 1; yy++) {
        for (xx = minX; xx < maxX + 1; xx++) {
            const k = yy * width + xx;
            mask[k] = 1;
            fillIndices.push(k);
        }
    }
    return {
        bounds: {
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY,
            width: maxX === minX ? 1 : maxX - minX,
            height: maxY === minY ? 1 : maxY - minY
        },
        fillIndices: fillIndices
    };
}

function getBorderBounds(
    regionIds: number[],
    borderIndices: number[][],
    width: number,
    height: number
): Bounds {
    const regLen = regionIds.length;
    if (regLen === 0) {
        return ZERO_BOUNDS;
    }

    // NOSONAR
    let maxX = -1;
    let minX = width + 1;
    let maxY = -1;
    let minY = height + 1;

    for (let i = 0; i < regLen; i++) {
        const borders = borderIndices[regionIds[i]];
        if (borders === undefined) {
            console.error(`Could not found border indices for the region ${regionIds[i]}`);
            continue;
        }

        const borderLen = borders.length;
        for (let j = 0; j < borderLen; j++) {
            const pixelIndex = borders[j];
            const x = pixelIndex % width; // calc x by index
            const y = (pixelIndex - x) / width; // calc y by index

            // check minmax for X
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;

            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
        }
    }

    return {
        minX: minX,
        minY: minY,
        maxX: maxX,
        maxY: maxY,
        width: maxX === minX ? 1 : maxX - minX + 1,
        height: maxY === minY ? 1 : maxY - minY + 1
    };
}

/**
 * Get regions' indices of the given coordinates.
 *
 * Regions marked as -1 will not be take into account
 *
 * @param x
 * @param y
 * @param model
 * @param withNeighbors
 */
function getRegionIndices(
    x: number,
    y: number,
    model: PaintSelectionModel,
    withNeighbors?: boolean
) {
    // NOSONAR
    // p = pixel index
    const p = getPixelIndex(x, y, model.width);
    if (p == null) {
        return null;
    }

    const level = model.currentLevel;
    const regionIds = [];
    const firstSelectedRegionId = model.regionPixels[level][p];

    if (firstSelectedRegionId == null || firstSelectedRegionId === -1) {
        return null;
    }

    regionIds.push(firstSelectedRegionId);

    const selectedRegionBounds = getBorderBounds(
        [firstSelectedRegionId],
        model.borderIndices[model.currentLevel],
        model.width,
        model.height
    );

    if (
        withNeighbors &&
        (selectedRegionBounds.width < model.maxSelectedRegionWidth ||
            selectedRegionBounds.height < model.maxSelectedRegionHeight)
    ) {
        // 4-pixel-neighbors
        const neighbors = create4NeighborPixels(p, model.width, model.height);
        const len = neighbors.length;
        let i = 0;
        while (i < len) {
            const regionId = model.regionPixels[level][neighbors[i]];

            if (
                regionId != null &&
                regionId !== firstSelectedRegionId &&
                !regionIds.some(v => v === regionId)
            ) {
                const neighbourBounds = getBorderBounds(
                    [regionId],
                    model.borderIndices[model.currentLevel],
                    model.width,
                    model.height
                );
                if (neighbourBounds.width < 12 && neighbourBounds.height < 5) {
                    regionIds.push(regionId);
                }
            }
            i++;
        }
    }

    return regionIds.length > 0 ? regionIds : null;
}

export function fillRegionsByMask(
    contoursMask: number[],
    maskToFill: Uint8Array,
    width: number,
    bounds: Bounds,
    value: number = 1
) {
    if (!contoursMask || !Array.isArray(contoursMask)) {
        console.error('contoursMask must be an array');
        return;
    }

    if (!maskToFill || contoursMask.length !== maskToFill.length) {
        console.error('contoursMask length does not match with the imageMask length');
        return;
    }

    const w = width;
    const minX = bounds.minX;
    const maxX = bounds.maxX;
    const minY = bounds.minY;
    const maxY = bounds.maxY;

    let x, y, k;
    for (y = minY; y < maxY + 1; y++) {
        for (x = minX; x < maxX + 1; x++) {
            k = y * w + x;
            if (contoursMask[k] === 255 || contoursMask[k] > 60) {
                maskToFill[k] = value;
            } else {
                maskToFill[k] = value === 1 ? 0 : 1;
            }
        }
    }
}

/**
 * Fill regions of the image mask and returns the pixel indices
 * of each filled region.
 *
 * @param regionIds
 * @param bounds The bounds of the ROI whose pixels should be filled
 * @param pixelValue value to fill the pixel of the given regions
 * @param model
 */
function fillRegions(
    regionIds: number[],
    bounds: Bounds,
    pixelValue: number,
    model: PaintSelectionModel,
    // TODO to complex
    newSelectionOnly?: boolean
): number[] {
    /// const mask = new Uint8Array(width * height);
    const w = model.width;
    const data = model.compoundSelectionMask;
    const minX = bounds.minX;
    const maxX = bounds.maxX;
    const minY = bounds.minY;
    const maxY = bounds.maxY;

    let x, y, k;
    const filledIndices = [];
    for (y = minY; y < maxY + 1; y++) {
        // visited region border
        // const visited: { [regionId: number]: number } = {};
        for (x = minX; x < maxX + 1; x++) {
            k = y * w + x;
            const regId = model.regionPixels[model.currentLevel][k];
            for (let l = 0; l < regionIds.length; l++) {
                if (regionIds[l] === regId) {
                    model.newSelectionMask[k] = pixelValue;
                    if (!newSelectionOnly) {
                        data[k] = pixelValue;
                    }
                    filledIndices.push(k);
                    // visited[regId] = visited[regId] ? visited[regId] + 1 : 0;
                }
            }
        }
    }

    return filledIndices;
}

export function fillSelectionMask(
    pixelsIndices: Set<number>,
    maskToFIll: Uint8Array,
    fillValue: number
) {
    if (pixelsIndices) {
        pixelsIndices.forEach(i => {
            if (maskToFIll[i] !== undefined) {
                maskToFIll[i] = fillValue;
            }
        });
    }
}

/**
 * Create a border index array of boundary points of the mask
 */
export function getBorderIndices(
    model: PaintSelectionModel,
    mask: Uint8Array,
    bounds: Bounds
): number[] {
    // NOSONAR
    let x, y, k, k1, k2;
    const w = model.width;
    const h = model.height;
    const data = mask; // model.compoundSelectionMask; // TODO use selectionMask
    const border = []; // only border points
    const minX = bounds.minX;
    const maxX = bounds.maxX;
    const minY = bounds.minY;
    const maxY = bounds.maxY;

    // walk through inner values except points on the boundary of the image
    for (y = minY; y < maxY + 1; y++) {
        for (x = minX; x < maxX + 1; x++) {
            k = y * w + x;
            // "white" point isn't the border
            if (data[k] === 0) {
                continue;
            }
            k1 = k + w; // y + 1
            k2 = k - w; // y - 1
            // check if any neighbor with a "black" border color
            if (
                data[k + 1] === 0 ||
                data[k - 1] === 0 ||
                data[k1] === 0 ||
                data[k1 + 1] === 0 ||
                data[k1 - 1] === 0 ||
                data[k2] === 0 ||
                data[k2 + 1] === 0 ||
                data[k2 - 1] === 0
            ) {
                // if (data[k + 1] + data[k - 1] +
                //    data[k1] + data[k1 + 1] + data[k1 - 1] +
                //    data[k2] + data[k2 + 1] + data[k2 - 1] == 8) continue;
                border.push(k);
            }
        }
    }
    // walk through points on the boundary of the image if necessary
    // if the "black" point is adjacent to the boundary of the image, it is a border point
    for (y = 0; y < h; y++) {
        if (data[y * w] === 1) {
            // NOSONAR
            border.push(y * w);
        }
    }

    for (x = 0; x < w; x++) {
        if (data[x] === 1) {
            border.push(x);
        }
    }
    k = w - 1;
    for (y = 0; y < h; y++) {
        if (data[y * w + k] === 1) {
            border.push(y * w + k);
        }
    }

    k = (h - 1) * w;
    for (x = 0; x < w; x++) {
        if (data[k + x] === 1) {
            border.push(k + x);
        }
    }

    return border;
}

/**
 *
 * @param borders
 * @param removedBorders
 * @param width
 * @param height
 * @param ctx
 * @param hatchLength
 * @param hatchOffset
 */

export function drawBorder(
    borders: number[],
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D,
    hatchLength: number = 4,
    hatchOffset: number = 0
) {
    // taken from http://jsfiddle.net/zmdga17w/1/ (https://github.com/Tamersoul/magic-wand-js)
    const len = borders.length;
    for (let j = 0; j < len; j++) {
        const i = borders[j];
        const x = i % width; // calc x by index
        const y = (i - x) / width; // calc y by index
        if ((x + y + hatchOffset) % (hatchLength * 2) < hatchLength) {
            ctx.fillStyle = 'rgba(0,0,0,1)';
            ctx.fillRect(x, y, 1, 1);
        } else {
            ctx.fillStyle = 'rgba(255,255,255,1)';
            ctx.fillRect(x, y, 1, 1);
        }
    }
}

// type TraceContours = { inner: boolean; label: number; points: number[] }[];
type TraceContours = [boolean, number, []][];
// [[false, 0, []]];
// type TraceContours = { [label: number]: number[] };

/** Create a contour array for the binary mask
 * Algorithm: http://www.sciencedirect.com/science/article/pii/S1077314203001401
 * @return {Array} [inner, label, {Array} points]
 */
export function traceContours(width: number, mask: Uint8Array, bounds: Bounds): TraceContours {
    const compressedMask = prepareMask(width, mask, bounds);

    const contours = [];
    let label = 0;
    const w = compressedMask.width;
    const w2 = w * 2;
    const h = compressedMask.height;
    const src = compressedMask.data;
    const dx = compressedMask.offset.x;
    const dy = compressedMask.offset.y;
    const dest = new Uint8Array(src); // label matrix

    let i;
    let j;
    let x;
    let y;
    let k;
    let k1;
    let inner;
    let dir;
    let first;
    let second;
    let current;
    let previous;
    let next;
    let d;

    // all [dx,dy] pairs (array index is the direction)
    // 5 6 7
    // 4 X 0
    // 3 2 1
    const directions = [
        [1, 0],
        [1, 1],
        [0, 1],
        [-1, 1],
        [-1, 0],
        [-1, -1],
        [0, -1],
        [1, -1]
    ];

    // const inners: { [label: number]: { x: number; y: number }[] } = {};
    // const inners: { [label: number]: number[] } = {};

    for (y = 1; y < h - 1; y++) {
        for (x = 1; x < w - 1; x++) {
            k = y * w + x;
            // if the  pixel above is black, it must be an external contour
            if (src[k - w] === 0 && dest[k - w] === 0) {
            }
            // if the pixel below is black, it must be an internal contour
            if (src[k + w] === 0 && dest[k + w] === 0) {
            }
        }
    }

    for (y = 1; y < h - 1; y++) {
        for (x = 1; x < w - 1; x++) {
            k = y * w + x;
            if (src[k] === 1) {
                for (i = -w; i < w2; i += w2) {
                    // k - w: outer tracing (y - 1), k + w: inner tracing (y + 1)
                    if (src[k + i] === 0 && dest[k + i] === 0) {
                        // need contour tracing
                        inner = i === w; // is inner contour tracing ?
                        label++; // label for the next contour

                        const c: number[] = [];
                        dir = inner ? 2 : 6; // start direction
                        current = previous = first = { x: x, y: y };
                        second = null;
                        while (true) {
                            // current := starting point
                            dest[current.y * w + current.x] = label; // mark label for the current point

                            // for the current point find among P's 8-neighbors the points following P
                            // bypass all the neighbors around the current point in a clockwise
                            for (j = 0; j < 8; j++) {
                                // d := starting/next pixel point for the clockwise search
                                dir = (dir + 1) % 8;
                                // get the next point by new direction
                                d = directions[dir]; // index as direction
                                // next := starting/next pixel point for the clockwise search
                                next = { x: current.x + d[0], y: current.y + d[1] };
                                // find the first black boundary pixel
                                k1 = next.y * w + next.x;
                                if (src[k1] === 1) {
                                    /* if (inner) {
                                        if (!inners[label]) {
                                            inners[label] = [];
                                        }
                                        inners[label].push(
                                            getPixelIndex(current.x + dx, current.y + dy, width)
                                        );
                                           inners[label].push({
                                                x: current.x + dx,
                                                y: current.y + dy
                                            });
                                    } */
                                    dest[k1] = label; // mark a label
                                    break;
                                }

                                // mark a white pixel with negative integer
                                dest[k1] = -1;
                                next = null;
                            }
                            // no pixel has been found among all neighbors, hence next is isolated
                            if (next === null) break;

                            current = next;
                            if (second) {
                                if (
                                    previous.x === first.x &&
                                    previous.y === first.y &&
                                    current.x === second.x &&
                                    current.y === second.y
                                ) {
                                    break; // creating the contour completed when returned to original position
                                }
                            } else {
                                second = next;
                            }
                            c.push(getPixelIndex(previous.x + dx, previous.y + dy, width));
                            // c.push(getPoint2D(previous.x + dx, previous.y + dy));
                            // c.push({ x: previous.x + dx, y: previous.y + dy });
                            previous = current;
                            dir = (dir + 4) % 8; // next dir (symmetrically to the current direction)
                        }

                        if (next != null) {
                            c.push(getPixelIndex(first.x + dx, first.y + dy, width)); // close the contour
                            // c.push(first.y + dy * width + first.x + dx); // close the contour
                            // c.push(getPoint2D(first.x + dx, first.y + dy)); // close the contour
                            // c.push({ x: first.x + dx, y: first.y + dy }); // close the contour
                            // contours.push({ inner: inner, label: label, points: c }); // add contour to the list
                            contours.push([inner, label, c]); // add contour to the list
                        }
                    }
                }
            }
        }
    }
    // return { inners: inners, contours: contours };
    return contours;
}

/** Create a compressed mask with a "white" border (1px border with zero values) for the contour tracing
 * @param {Object} mask: {Uint8Array} data, {int} width, {int} height, {Object} bounds
 * @return {Object} border mask: {Uint8Array} data, {int} width, {int} height, {Object} offset
 */
function prepareMask(width: number, mask: Uint8Array, bounds: Bounds) {
    let x;
    let y;
    const w = width;
    const data = mask;
    const minX = bounds.minX;
    const maxX = bounds.maxX;
    const minY = bounds.minY;
    const maxY = bounds.maxY;
    const rw = maxX - minX + 3; // bounds size +1 px on each side (a "white" border)
    const rh = maxY - minY + 3;
    const result = new Uint8Array(rw * rh); // reduced mask (bounds size)

    // walk through inner values and copy only "black" points to the result mask
    for (y = minY; y < maxY + 1; y++) {
        for (x = minX; x < maxX + 1; x++) {
            if (data[y * w + x] === 1) result[(y - minY + 1) * rw + (x - minX + 1)] = 1;
        }
    }

    return {
        data: result,
        width: rw,
        height: rh,
        offset: { x: minX - 1, y: minY - 1 }
    };
}

type Point2DType = { x: number; y: number };

const point2DPool: Point2DType[][] = [];
const activePoint2DPool: Point2DType[] = [];
const pointPoolWidth = 900;
const pointPoolHeight = 600;
createPoint2DPool(pointPoolWidth, pointPoolHeight);

function createPoint2DPool(width: number, height: number): void {
    for (let x = 0; x < width; x++) {
        const pts = [];
        for (let y = 0; y < height; y++) {
            pts.push({
                x: x,
                y: y
            });
        }
        point2DPool.push(pts);
    }
}

function expandPaintPool(w: number, h: number) {
    for (let x = 0; x < w; x++) {}
}

function getPoint2D(x: number, y: number): Point2DType {
    let newPoint: Point2DType;
    // check to see if there is a spare one
    if (x < pointPoolWidth || y < pointPoolHeight) {
        newPoint = point2DPool[x][y];
    } else {
        const width = Math.min(0, x - pointPoolWidth);
        const height = Math.min(0, y - pointPoolHeight);
        expandPaintPool(width, height);
        // newPoint = { x: number; y: number };
    }
    return newPoint;
}
