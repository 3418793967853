/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./price-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./price-display.component";
var styles_PriceDisplayComponent = [i0.styles];
var RenderType_PriceDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PriceDisplayComponent, data: {} });
export { RenderType_PriceDisplayComponent as RenderType_PriceDisplayComponent };
export function View_PriceDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "price-display"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "price-display__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "price-display__title-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["height", "75"], ["width", "20"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:polygon", [["points", "0,0 0,75 20,36.5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "price-display__price"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "price-display__price-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "price-display__price-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("EDITOR.PRICE_DISPLAY")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.price; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.vat; _ck(_v, 11, 0, currVal_2); }); }
export function View_PriceDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-price-display", [], null, null, null, View_PriceDisplayComponent_0, RenderType_PriceDisplayComponent)), i1.ɵdid(1, 49152, null, 0, i3.PriceDisplayComponent, [], null, null)], null, null); }
var PriceDisplayComponentNgFactory = i1.ɵccf("app-price-display", i3.PriceDisplayComponent, View_PriceDisplayComponent_Host_0, { price: "price", vat: "vat" }, {}, []);
export { PriceDisplayComponentNgFactory as PriceDisplayComponentNgFactory };
