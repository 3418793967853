import { CuttableContour, CuttableContourArgs } from './cuttable-contour';
import { Rectangle2D } from '../../shared/geom/rectangle2D';

export abstract class ResizeableContour extends CuttableContour {
    userDefinedDepth: boolean = false;

    protected constructor(obj: ResizeableContourArgs) {
        super(obj);
        this.userDefinedDepth = obj.userDefinedDepth;
    }

    abstract resizeDimension(newBounds: Rectangle2D, oldBounds?: Rectangle2D): void;

    isValidResizeStep(newBounds: Rectangle2D, oldBounds: Rectangle2D): boolean {
        return true;
    }

    constraintResizeStep(newBounds: Rectangle2D, oldBounds?: Rectangle2D): Rectangle2D {
        return newBounds;
    }

    abstract resizeDepth(depth: number): void;
}
export interface ResizeableContourArgs extends CuttableContourArgs {
    userDefinedDepth?: boolean;
}
