import { CuttableContour } from './cuttable-contour';
import {
    CircleEastHandle,
    CircleNorthHandle,
    CircleSouthHandle,
    CircleWestHandle,
    ResizeHandle
} from '../../shared/handle/resize-handle';
import {
    ContourChangeData,
    ContourHandlesData,
    CuttableContourDrawData,
    ItemProperties,
    MarginContourCollisionVizData,
    ResizeHandleListData
} from './contour-items-interfaces';
import { BBox } from 'snapsvg';
import { ContourElementType } from './contour-element-type';
import { Handle } from '../../shared/handle/handle';
import { Tool } from '../../shared/tool/tool';
import { CanvasElementChangedEvent } from '../canvas.component';
import {
    calculateMargin,
    getCircularShapeSegments,
    pathArrayToString,
    pathString2Polygon,
    transformBoundingBox
} from './contour-helper';
import { COLOR_BG_ITEM_PATH, COLOR_BG_MARGIN_PATH, OPACITY_BG_MARGIN_PATH } from './constants';
import { FoamEditorService } from '../../foam-editor.service';
import { ID_RESIZE_HANDLES_GROUP } from '../handles/resize-handles-drawer';
import { ResizeableContour } from './resizeable-contour';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { MarginUtils } from '../shared/margin-utils';

export class CircleContour extends ResizeableContour {
    private resizeHandles: ResizeHandle[];

    isRemovable: boolean;
    isSelectable: boolean = true;
    isRotatable: boolean = false;
    isCollidable: boolean = true;
    isResizable: boolean = true;
    radius: number;
    private showResizeHandles: boolean = true;

    constructor(obj: CircleGripContourArgs) {
        super(obj);

        const setterFn = (value: any, def: any) => (value == null ? def : value);
        this.radius = setterFn(obj.radius, 40);
        this.width = setterFn(obj.radius * 2, 80);
        this.height = this.width;
        this.depth = setterFn(obj.depth, 13);
        this.svgPathDefinition = this.createPathString();

        this.itemProps.minRadius = 5;
        this.itemProps.contourFill = COLOR_BG_ITEM_PATH;
        this.itemProps.marginFill = COLOR_BG_MARGIN_PATH;
        this.itemProps.marginOpacity = OPACITY_BG_MARGIN_PATH;

        this.marginSize = this.depth ? calculateMargin(this.depth) : undefined;
        this.marginPathString = setterFn(
            obj.marginPathString,
            MarginUtils.createMarginPathString(this.svgPathDefinition, this.marginSize)
        );
    }

    private createPathString(): string {
        const pathSegments = getCircularShapeSegments(this.radius, true, this.radius, this.radius);
        return pathArrayToString(pathSegments);
    }

    isValidResizeStep(newBounds: Rectangle2D, oldBounds: Rectangle2D): boolean {
        if (newBounds.width < oldBounds.width && newBounds.width / 2 <= this.itemProps.minRadius) {
            return false;
        }
        return true;
    }

    constraintResizeStep(newBounds: Rectangle2D): Rectangle2D {
        newBounds.width = Math.max(newBounds.width, this.itemProps.minRadius * 2);
        newBounds.height = newBounds.width;
        newBounds.recomputeCenter();
        return newBounds;
    }

    constraintResizeDepth(newDepth: number): number {
        let newValue = Math.max(newDepth, this.canvasService.getPropertiesValue().minContourDepth);
        newValue = Math.min(newValue, this.canvasService.getPropertiesValue().maxContourDepth);
        return newValue;
    }

    resizeDepth(depth: number) {
        if (depth && this.depth !== depth) {
            const newDepth = this.constraintResizeDepth(depth);
            this.depth = newDepth;
            this.marginSize = calculateMargin(newDepth);

            this.svgPathDefinition = this.createPathString();
            this.marginPathString = MarginUtils.createMarginPathString(
                this.svgPathDefinition,
                this.marginSize
            );

            const contourChangeData = new ContourChangeData({
                contourId: this.contourId,
                marginPathString: this.marginPathString,
                isDepthChanged: true
            });
            this.contourChanged.next(contourChangeData);
        }
    }

    resizeDimension(newBounds: Rectangle2D, oldBounds?: Rectangle2D) {
        newBounds = this.constraintResizeStep(newBounds);
        if (!oldBounds) {
            oldBounds = this.localContourPathBBox.clone();
        }

        this.radius = newBounds.width / 2;
        this.width = newBounds.width;
        this.height = this.width;
        this.svgPathDefinition = this.createPathString();
        this.marginPathString = MarginUtils.createMarginPathString(
            this.svgPathDefinition,
            this.marginSize
        );

        const matrix = this.localContourPathMatrix.clone();
        // east
        // matrix.translate(0, -dRadius);
        // west
        // matrix.ranslate(-dRadius * 2, -dRadius);
        // north
        // matrix.translate(-dRadius, -dRadius * 2);
        // south
        // matrix.translate(-dRadius, 0);

        matrix.translate(newBounds.x, newBounds.y);

        const oldMarginBounds = this.localMarginPathBBox;

        const dWidth = newBounds.width - oldBounds.width;
        const dHeight = newBounds.height - oldBounds.height;

        const newMarginBounds = new Rectangle2D(
            oldMarginBounds.x,
            oldMarginBounds.y,
            oldMarginBounds.width + dWidth,
            oldMarginBounds.height + dHeight
        );

        const oldContourBounds = this.localContourPathBBox;
        const newContourBounds = new Rectangle2D(
            oldContourBounds.x,
            oldContourBounds.y,
            newBounds.width,
            newBounds.height
        );

        const contourChangeData = new ContourChangeData({
            contourId: this.contourId,
            svgPathDefinition: this.svgPathDefinition,
            marginPathString: this.marginPathString,
            matrix: matrix,
            width: this.width,
            height: this.height,
            handles: new ContourHandlesData({
                contourId: this.contourId,
                transformBounds: {
                    localContourBounds: newContourBounds,
                    localMarginBounds: newMarginBounds,
                    selectionBoundsMatrix: matrix
                }
            })
        });
        this.contourChanged.next(contourChangeData);
    }

    contains(px: number, py: number): boolean {
        return false;
    }

    containsTarget(target: Element): boolean {
        return target.id === this.itemProps.itemPathId || target.id === this.itemProps.marginPathId;
    }

    createHandles(): Handle[] {
        if (!this.resizeHandles) {
            this.resizeHandles = [
                new CircleSouthHandle(this),
                new CircleNorthHandle(this),
                new CircleEastHandle(this),
                new CircleWestHandle(this)
            ];
        }

        return [...this.resizeHandles];
    }

    getCollisionData(): MarginContourCollisionVizData {
        return new MarginContourCollisionVizData({
            contourId: this.contourId,
            clipPathString: this.svgPathDefinition,
            pathString: this.svgPathDefinition,
            pathStringToBeClipped: this.marginPathString,
            transformString: this.localMarginPathMatrix
                ? this.localMarginPathMatrix.toTransformString()
                : undefined,
            transformString2: this.localMarginPathMatrix
                ? this.localMarginPathMatrix.toString()
                : undefined,

            innerCollisionPolygonLines: this.polygonLines,
            innerCollisionBBox: this.globalContourPathBBox,
            outerCollisionPolygonLines: this.marginPolygonLines,
            outerCollisionBBox: this.globalMarginPathBBox,

            globalContourPathBBox: this.globalContourPathBBox,
            localContourPathBBox: this.localContourPathBBox,

            globalMarginPathMatrix: this.globalMarginPathMatrix,
            localMarginPathMatrix: this.localMarginPathMatrix,
            globalContourPathMatrix: this.globalContourPathMatrix,
            localContourPathMatrix: this.localContourPathMatrix,
            isCollidable: this.isCollidable
        });
    }

    getDrawData(): CuttableContourDrawData {
        return new CuttableContourDrawData({
            contourId: this.contourId,
            svgPathDefinition: this.svgPathDefinition,
            marginPathString: this.marginPathString,
            marginSize: this.marginSize,
            itemProps: this.itemProps,
            isCollidable: this.isCollidable,
            isSelectable: this.isSelectable,
            matrix: this.localContourPathMatrix
        });
    }

    getHandlesDrawData(): ContourHandlesData {
        return new ContourHandlesData({
            containerId: 'handle-container-' + this.contourId,
            contourId: this.contourId,
            transformBounds: {
                localContourBounds: this.localContourPathBBox,
                localMarginBounds: this.localMarginPathBBox,
                selectionBoundsMatrix: this.localContourPathMatrix
            },
            resizeHandles: new ResizeHandleListData({
                contourId: this.contourId,
                containerId: ID_RESIZE_HANDLES_GROUP + this.contourId,
                handles: this.resizeHandles.map(x => x.getDrawData()),
                visible: this.showResizeHandles
            })
        });
    }

    getTool(target: Element, editor?: FoamEditorService): Tool | null {
        return undefined;
    }

    onContourElementDrawn(drawnData: CanvasElementChangedEvent): void {
        if (!drawnData) {
            return;
        }
        if (drawnData.contour) {
            this.polygonLines = pathString2Polygon(
                this.svgPathDefinition,
                drawnData.contour.globalMatrix
            );

            this.globalContourPathBBox = drawnData.contour.globalBBox;
            this.localContourPathBBox = drawnData.contour.localBBox;
            this.globalContourPathMatrix = drawnData.contour.globalMatrix;
            this.localContourPathMatrix = drawnData.contour.localMatrix;

            this.contourBoundsInParent = Rectangle2D.fromPointsObject(
                transformBoundingBox(drawnData.contour.localBBox, this.localContourPathMatrix)
            );
        }

        if (drawnData.margin) {
            this.globalMarginPathMatrix = drawnData.margin.globalMatrix;
            this.localMarginPathMatrix = drawnData.margin.localMatrix;
            this.globalMarginPathBBox = drawnData.margin.globalBBox;
            this.localMarginPathBBox = drawnData.margin.localBBox;
            this.marginPolygonLines = pathString2Polygon(
                this.marginPathString,
                drawnData.margin.globalMatrix
            );
        }
    }
}

export interface CircleGripContourArgs {
    contourId?: string;
    radius: number;
    itemProps?: ItemProperties;
    marginPathString?: string;
    marginSize?: number;
    isSelectable?: boolean;
    isCollidable?: boolean;
    userDefinedDepth?: boolean;
    pathGlobalBBox?: BBox;
    pathLocalBBox?: BBox;
    marginPathLocalBBox?: BBox;
    marginPathGlobalBBox?: BBox;
    depth: number;
    visible?: {
        [ContourElementType.ITEM_PATH]: boolean;
        [ContourElementType.MARGIN_PATH]: boolean;
    };
}
