import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { FoamEditorService } from '../../foam-editor.service';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { Handle } from '../../shared/handle/handle';
import { Tool } from '../../shared/tool/tool';
import { PolygonSegment } from '../collision/collision-util';
import { pathString2Polygon, transformBoundingBox } from './contour-helper';
import { nextUniqueId } from '../../../utils/unique-id';
import { CanvasElementChangedEvent } from '../canvas.component';
import { CanvasService } from '../canvas.service';

import {
    ContourChangeData,
    ContourCollisionVizData,
    ContourHandlesData,
    ContourStateData,
    ContourType,
    FoamDrawData,
    ItemProperties,
    CanvasContour
} from './contour-items-interfaces';
import { BBox } from 'snapsvg';
import { MarginUtils } from '../shared/margin-utils';
import { FoamInnerMarginContour } from './foam-inner-margin-contour';

declare var Snap: any;
export interface FoamContourArgs {
    contourId: string;
    svgPathDefinition: string;
    svgClipPathDefinition?: string;
    width: number;
    height: number;
    depth: number;
    marginPathString?: string;
    marginSize?: number;
    visible?: boolean;
    innerMargin: number;
    globalContourPathBBox?: BBox;
    localContourPathBBox?: BBox;
    globalContourPathMatrix?: Snap.Matrix;
    localContourPathMatrix?: Snap.Matrix;
}

export class FoamContour implements CanvasContour {
    boundingBox: Snap.BBox | undefined;
    contourId: string;
    contourType: ContourType = ContourType.FOAM;
    private canvasService: CanvasService; /* TODO: why is this here? */
    image: string;
    isCollidable: boolean;
    isRemovable: boolean;
    isSelectable: boolean;
    isResizable: boolean;
    isTextElement = false;
    itemElement: Snap.Element | undefined;
    itemTransformation: Snap.TransformationDescriptor | undefined;
    marginBoundingBox: Snap.BBox | undefined;
    marginPath: Snap.Element | undefined;
    marginPathString: string | undefined;
    marginPolygonLines: Array<PolygonSegment> | undefined;
    pathSegments: Array<any> | undefined;
    svgPathDefinition: string;
    polygonLines: Array<PolygonSegment> | undefined;
    totalDegree: number | undefined;

    svgClipPathDefinition: string;

    readonly itemProps: ItemProperties;
    readonly width: number;
    readonly height: number;
    readonly depth: number;

    localContourPathBBox: Rectangle2D;
    globalContourPathBBox: Rectangle2D;
    contourBoundsInParent: Rectangle2D;

    globalContourPathMatrix: Snap.Matrix;
    localContourPathMatrix: Snap.Matrix;

    private contourChanged: Subject<ContourStateData> = new Subject();
    isRotatable = false;
    visible: boolean;
    innerMargin = 0;
    foamInnerMarginContour: FoamInnerMarginContour;

    constructor(obj?: FoamContourArgs) {
        const setterFn = (value: any, def: any) => (value == null ? def : value);
        const setterOrErrorFn = (value: any) => {
            if (value == null) {
                throw new ReferenceError();
            }
            return value;
        };
        this.contourId = obj.contourId || nextUniqueId();
        this.svgPathDefinition = setterOrErrorFn(obj.svgPathDefinition);
        this.width = setterOrErrorFn(obj.width);
        this.height = setterOrErrorFn(obj.height);
        this.depth = setterOrErrorFn(obj.depth);

        this.innerMargin = setterFn(obj.innerMargin, 0);
        if (this.innerMargin && this.innerMargin > 0) {
            const foamInnerMarginPath = MarginUtils.createInnerMarginFromSvgPath(
                this.svgPathDefinition,
                this.innerMargin
            );

            this.foamInnerMarginContour = new FoamInnerMarginContour({
                contourId: 'foam-limiter-' + this.contourId.toString(),
                svgPathDefinition: foamInnerMarginPath,
                foamSvgPathDefinition: this.svgPathDefinition,
                // TODO should be derived from svgPathDefinition ??
                width: this.width - this.innerMargin * 2,
                height: this.height - this.innerMargin * 2
            });
        }

        this.svgClipPathDefinition = obj.svgClipPathDefinition || this.createClipPath(200, 200);

        this.visible = setterFn(obj.visible, true);
        this.globalContourPathBBox = setterFn(obj.globalContourPathBBox, undefined);
        this.localContourPathBBox = setterFn(obj.localContourPathBBox, undefined);
        this.globalContourPathMatrix = setterFn(obj.globalContourPathMatrix, undefined);
        this.localContourPathMatrix = setterFn(obj.localContourPathMatrix, undefined);
    }

    static create(foamContourItem: any): FoamContour {
        const contourItem = Object.assign({} as FoamContour, _.cloneDeep(foamContourItem));
        return new FoamContour(contourItem);
    }

    updateClipPath(minScaleFactor: number, maxWidth: number, maxHeight: number) {
        const minFoamWidth = this.width * minScaleFactor;
        const minFoamHeight = this.height * minScaleFactor;

        const offsetX = (maxWidth - minFoamWidth) / 2 + 50;
        const offsetY = (maxHeight - minFoamHeight) / 2 + 50;
        this.svgClipPathDefinition = this.createClipPath(offsetX, offsetY);
    }

    private createClipPath(offsetX: number, offsetY: number) {
        const x2 = this.width + offsetX;
        const y2 = this.height + offsetY;
        return `M-${offsetX},-${offsetY} L-${offsetX},${y2} L${x2},${y2} L${x2},-${offsetY} L-${offsetX},-${offsetY} ${this.svgPathDefinition}`;
    }

    contains(px: number, py: number): boolean {
        return false;
    }

    // TODO should not contains this method
    containsTarget(target: Element): boolean {
        return false;
    }

    createHandles(): Handle[] {
        return undefined;
    }

    getHandlesDrawData(): ContourHandlesData {
        return undefined;
    }

    getOnChanged(): Observable<ContourStateData> {
        return this.contourChanged.pipe(share());
    }

    getOnRemoved(): Observable<void> {
        return undefined;
    }

    getTool(target: Element, editor?: FoamEditorService): Tool | null {
        return undefined;
    }

    remove() {}

    onContourElementDrawn(drawnData: CanvasElementChangedEvent) {
        if (drawnData && drawnData.contour) {
            this.polygonLines = pathString2Polygon(
                this.svgPathDefinition,
                drawnData.contour.globalMatrix
            );
            // leave it undefined?
            // this.marginPolygonLines = this.polygonLines;

            this.globalContourPathBBox = drawnData.contour.globalBBox;
            this.localContourPathBBox = drawnData.contour.localBBox;
            this.globalContourPathMatrix = drawnData.contour.globalMatrix;
            this.localContourPathMatrix = drawnData.contour.localMatrix;

            this.contourBoundsInParent = Rectangle2D.fromPointsObject(
                transformBoundingBox(drawnData.contour.localBBox, this.localContourPathMatrix)
            );
        }
    }

    setCanvas(canvas: CanvasService) {
        this.canvasService = canvas;
    }

    transform(matrix: Snap.Matrix): ContourChangeData {
        return undefined;
    }

    getDrawData(): FoamDrawData {
        return new FoamDrawData({
            contourId: this.contourId,
            svgPathDefinition: this.svgPathDefinition,
            svgClipPathDefinition: this.svgClipPathDefinition,
            innerMarginDrawData: this.foamInnerMarginContour
                ? this.foamInnerMarginContour.getDrawData()
                : undefined
        });
    }

    clone() {}

    getCollisionData(): ContourCollisionVizData {
        return new ContourCollisionVizData({
            contourId: this.contourId,
            pathString: this.svgPathDefinition,
            clipPathString: this.svgClipPathDefinition,
            // pathStringToBeClipped: this.clipPathString,
            transformString: this.localContourPathMatrix.toTransformString(),
            transformString2: this.localContourPathMatrix.toString(),
            innerCollisionPolygonLines: this.polygonLines,
            innerCollisionBBox: this.globalContourPathBBox,
            globalContourPathMatrix: this.globalContourPathMatrix,
            localContourPathMatrix: this.localContourPathMatrix,
            isCollidable: this.isCollidable
        });
    }

    getSelectionData(): undefined {
        return undefined;
    }

    getVisibility(): boolean {
        return true;
    }

    seVisibility(newValue: boolean) {}

    getCanvas(): CanvasService {
        return this.canvasService;
    }
}
