/**
 * A 2D rectangle used to describe the bounds of an object.
 *
 */
export class Rectangle2D {
    /**
     * The x coordinate of the upper-left corner of the {@code Rectangle2D}
     */
    x: number;
    /**
     * The y coordinate of the upper-left corner of the {@code Rectangle2D}
     */
    y: number;
    /**
     * The width of the {@code Rectangle2D}
     */
    width: number;
    /**
     * The height of the {@code Rectangle2D}
     */
    height: number;
    /**
     * The x coordinate of the lower-right corner of this {@code Rectangle2D}.
     */
    x2: number;
    /**
     *  The y coordinate of the lower-right corner of this {@code Rectangle2D}.
     */
    y2: number;

    /**
     * The x coordinate of the rectangle's center
     */
    cx: number;
    /**
     * The y coordinate of the rectangle's center
     */
    cy: number;

    /**
     *
     * @param minX The x coordinate of the upper-left corner of the {@code Rectangle2D}
     * @param minY The y coordinate of the upper-left corner of the {@code Rectangle2D}
     * @param width The width of the {@code Rectangle2D}
     * @param height The height of the {@code Rectangle2D}
     */
    constructor(minX: number, minY: number, width: number, height: number) {
        this.x = minX;
        this.y = minY;
        this.width = width;
        this.height = height;
        this.x2 = minX + width;
        this.y2 = minY + height;
        this.cx = minX + this.width / 2;
        this.cy = minY + this.height / 2;
    }

    static fromObject(obj: { x: number; y: number; width: number; height: number }): Rectangle2D {
        return new Rectangle2D(obj.x, obj.y, obj.width, obj.height);
    }

    static fromPointsObject(obj: { x: number; y: number; x2: number; y2: number }): Rectangle2D {
        return new Rectangle2D(obj.x, obj.y, Math.abs(obj.x2 - obj.x), Math.abs(obj.y2 - obj.y));
    }

    recomputeCenter() {
        this.cx = this.x + this.width / 2;
        this.cy = this.y + this.height / 2;
    }

    isEmpty(): boolean {
        return this.width <= 0 || this.height <= 0;
    }

    /**
     * Tests if the given coordinate ({@code x}, {@code y)} is inside the rectangle
     * @param x
     * @param y
     */
    contains(x: number, y: number): boolean {
        return x >= this.x && x <= this.x2 && y >= this.y && y <= this.y2;
    }

    /**
     * Tests if the given rectangle is in the Rectangle
     *
     * @param rect
     */
    containsRect(rect: Rectangle2D): boolean {
        return rect.x >= this.x && rect.y >= this.y && rect.x2 <= this.x2 && rect.y2 <= this.y2;
    }

    clone(): Rectangle2D {
        return new Rectangle2D(this.x, this.y, this.width, this.height);
    }

    equals(rect: Rectangle2D): boolean {
        return rect.x === this.x && rect.y === this.y && rect.x2 === this.x2 && rect.y2 === this.y2;
    }
}
