/**
 * Mapper from domain object to canvas contour object.
 **/
import {
    CircleContourModel,
    ContourModel,
    ImageContourModel,
    ImageContourType,
    ProducerImageContourModel,
    RecessedGripContourModel,
    RectangleContourModel,
    TextContourModel
} from './image-contour.model';
import { CircleContour } from '../foam-editor/canvas/contour/circle-contour';
import { TextContour } from '../foam-editor/canvas/contour/text-contour';
import { RecessedGripContour } from '../foam-editor/canvas/contour/recessed-grip-contour';
import { RectangleContour } from '../foam-editor/canvas/contour/rectangle-contour';
import { ImageContour } from '../foam-editor/canvas/contour/image-contour';
import { ContourStartDimensions } from '../foam-editor/foam-editor.service';
import { CanvasContour } from '../foam-editor/canvas/contour/contour-items-interfaces';
import { PositionData } from './position-data.model';
import { FoamContourModel } from './foam-contour.model';
import { StartParameterModel } from './start-parameter.model';
import { FoamConfigurationModel } from './foam-configuration.model';

export class ModelMapper {
    static toCanvasContour(
        contourObj: ContourModel,
        startDimensions?: ContourStartDimensions
    ): CanvasContour | undefined {
        if (
            contourObj instanceof ImageContourModel ||
            contourObj instanceof ProducerImageContourModel
        ) {
            return ImageContour.create(contourObj, contourObj.id, contourObj.imageContourType);
        } else if (contourObj instanceof CircleContourModel) {
            return new CircleContour({
                radius: contourObj.radius,
                depth: contourObj.depth
            });
        } else if (contourObj instanceof RectangleContourModel) {
            return new RectangleContour({
                width: contourObj.width,
                height: contourObj.height,
                cornerRadiusXY: startDimensions.RECTANGLE.cornerRadius,
                depth: contourObj.depth
            });
        } else if (contourObj instanceof RecessedGripContourModel) {
            return new RecessedGripContour({
                width: contourObj.width,
                height: contourObj.height,
                cornerRadiusXY: startDimensions.RECESSED_GRIP.cornerRadius,
                depth: contourObj.depth,
                userDefinedDepth: contourObj.userDefinedDepth
            });
        } else if (contourObj instanceof TextContourModel) {
            return new TextContour({
                textContent: contourObj.textContent
            });
        }

        return undefined;
    }

    static imageContourToJSON(
        canvasObj: ImageContour,
        producerModelObj?: ProducerImageContourModel
    ): ImageContourJSON | ProducerImageContourJSON {
        const result: ImageContourModel = {
            id: canvasObj.dataStoreId,
            width: canvasObj.width,
            height: canvasObj.height,
            depth: canvasObj.depth,
            svgPathDefinition: canvasObj.svgPathDefinition,
            positionData: new PositionData({
                localMatrix: canvasObj.localContourPathMatrix
            }),
            imageContourType: canvasObj.type,
            title: canvasObj.title,
            description: canvasObj.description,
            previewImage: canvasObj.previewImage,
            image: canvasObj.image
        };

        if (
            producerModelObj &&
            producerModelObj.imageContourType === ImageContourType.PRODUCER_TOOL
        ) {
            (result as ProducerImageContourModel).producerName = producerModelObj.producerName;
            (result as ProducerImageContourModel).toolTypeName = producerModelObj.toolTypeName;
            return { ProducerImageContour: result };
        }

        return ModelMapper.imageContourModelToJSON(result);
    }

    static imageContourModelToJSON(obj: ImageContourModel): ImageContourJSON {
        return { ImageContour: obj };
    }

    static rectangularContourToJSON(
        canvasObj: RectangleContour | RecessedGripContour
    ): RectangleContourJSON | RecessedGripContourJSON {
        const result: ImageContourModel | RecessedGripContourModel = {
            positionData: new PositionData({
                localMatrix: canvasObj.localContourPathMatrix
            }),
            svgPathDefinition: canvasObj.svgPathDefinition,
            width: canvasObj.width,
            height: canvasObj.height,
            depth: canvasObj.depth
        };

        if (canvasObj instanceof RectangleContour) {
            return { RectangleContour: result };
        } else if (canvasObj instanceof RecessedGripContour) {
            result.userDefinedDepth = canvasObj.userDefinedDepth;
            return { RecessedGripContour: result };
        } else {
            throw new Error(
                `Invalid canvas contour type. Expected RectangleContour or RecessedGripContour. Received ${JSON.stringify(
                    canvasObj
                )}.`
            );
        }
    }

    static circleContourToJSON(canvasObj: CircleContour): CircleContourJSON {
        return {
            CircleContour: {
                positionData: new PositionData({
                    localMatrix: canvasObj.localContourPathMatrix
                }),
                svgPathDefinition: canvasObj.svgPathDefinition,
                width: canvasObj.width,
                height: canvasObj.height,
                depth: canvasObj.depth,
                radius: canvasObj.radius
            }
        };
    }

    static textContourToJSON(canvasObj: TextContour): TextContourJSON {
        return {
            TextContour: {
                positionData: new PositionData({
                    localMatrix: canvasObj.localContourPathMatrix
                }),
                svgPathDefinition: canvasObj.svgPathDefinition,
                width: canvasObj.width,
                height: canvasObj.height,
                depth: canvasObj.depth,
                textContent: canvasObj.textContent,
                fontFamily: canvasObj.fontFamily,
                fontSize: canvasObj.fontSize
            }
        };
    }

    static foamConfigurationToJSON(
        id: string,
        configurationName: string,
        foamContour: FoamContourModel,
        contours: ContourJSON[]
    ): FoamConfigurationJSON {
        return {
            id,
            configurationName,
            foamContour: foamContour,
            allContour: contours
        };
    }

    static jsonToStartParameterModel(jsonObj: any): StartParameterModel {
        const foamConfig = new FoamConfigurationModel({
            id: jsonObj.foamConfig.id,
            configurationName: jsonObj.foamConfig.configurationName,
            foamContour: new FoamContourModel(jsonObj.foamConfig.foamContour),
            contours: jsonObj.foamConfig.allContour.map(item => {
                return ModelMapper.jsonToContourModel(item);
            })
        });

        return new StartParameterModel({
            inlayUserId: jsonObj.inlayUserId,
            languageKey: jsonObj.languageKey,
            price: jsonObj.price,
            vat: jsonObj.vat,
            foamConfig: foamConfig,
            languageContent: jsonObj.languageContent
        });
    }

    static jsonToContourModel(jsonObj: any): ContourModelTypes {
        if (jsonObj.ProducerImageContour) {
            jsonObj = jsonObj.ProducerImageContour;
            return new ProducerImageContourModel({
                id: jsonObj.id,
                imageContourType: jsonObj.imageContourType,
                title: jsonObj.title,
                width: jsonObj.width,
                height: jsonObj.height,
                depth: jsonObj.depth,
                description: jsonObj.description,
                previewImage: jsonObj.previewImage,
                image: jsonObj.image,
                toolTypeName: jsonObj.toolTypeName,
                producerName: jsonObj.producerName,
                svgPathDefinition: jsonObj.svgPathDefinition,
                positionData: new PositionData(jsonObj.positionData)
            });
        } else if (jsonObj.ImageContour) {
            jsonObj = jsonObj.ImageContour;
            return new ImageContourModel({
                id: jsonObj.id,
                imageContourType: jsonObj.imageContourType,
                title: jsonObj.title,
                width: jsonObj.width,
                height: jsonObj.height,
                depth: jsonObj.depth,
                description: jsonObj.description,
                previewImage: jsonObj.previewImage,
                image: jsonObj.image,
                svgPathDefinition: jsonObj.svgPathDefinition,
                positionData: new PositionData(jsonObj.positionData)
            });
        } else if (jsonObj.CircleContour) {
            jsonObj = jsonObj.CircleContour;
            return new CircleContourModel(jsonObj);
        } else if (jsonObj.RectangleContour) {
            jsonObj = jsonObj.RectangleContour;
            return new RectangleContourModel(jsonObj);
        } else if (jsonObj.RecessedGripContour) {
            jsonObj = jsonObj.RecessedGripContour;
            return new RecessedGripContourModel(jsonObj);
        } else if (jsonObj.TextContour) {
            jsonObj = jsonObj.TextContour;
            return new TextContourModel(jsonObj);
        } else {
            throw new ReferenceError('unknown type!');
        }
    }
}

export interface ImageContourJSON {
    ImageContour: ImageContourModel;
}

export interface ProducerImageContourJSON {
    ProducerImageContour: ProducerImageContourModel;
}

export interface RectangleContourJSON {
    RectangleContour: RectangleContourModel;
}

export interface CircleContourJSON {
    CircleContour: CircleContourModel;
}

export interface RecessedGripContourJSON {
    RecessedGripContour: RecessedGripContourModel;
}

export interface TextContourJSON {
    TextContour: TextContourModel;
}

export interface FoamConfigurationJSON {
    id: string;
    configurationName: string;
    foamContour: FoamContourModel;
    // TODO: fix typo
    allContour: ContourJSON[];
}

export type ContourModelTypes =
    | ImageContourModel
    | ProducerImageContourModel
    | RectangleContourModel
    | RecessedGripContourModel
    | CircleContourModel;

export type ContourJSON =
    | ProducerImageContourJSON
    | ImageContourJSON
    | TextContourJSON
    | RecessedGripContourJSON
    | RectangleContourJSON
    | CircleContourJSON;
