<div class="editor-wrapper">
    <app-ribbon (panelChanged)="onRibbonPanelChanged($event)"></app-ribbon>

    <div #mainWindow class="main-window">
        <app-project-property
            [hasCollision]="collisionChange | async"
            [boxName]="boxName | async"
            [configurationName]="configurationName"
            (configurationNameChange)="onConfigNameChange($event)"
        >
        </app-project-property>
        <div id="workspace">
            <app-edit-actions-toolbar *ngIf="showEditActions"></app-edit-actions-toolbar>
            <div
                [ngClass]="{
                    'workspace-col': showEditActions,
                    'workspace-col--no-edit': !showEditActions
                }"
            >
                <app-canvas (scaleFactorChanged)="scaleFactorChanged($event)"></app-canvas>
                <div
                    [ngClass]="{
                        'workspace-info-wrapper': !showCompletionButtons,
                        'workspace-info-wrapper--overview': showCompletionButtons
                    }"
                >
                    <div *ngIf="showCompletionButtons" class="completion-buttons">
                        <span
                            appTooltip
                            [appToolTipContent]="saveToolTipContent"
                            [appTooltipPlacement]="'top'"
                            [appTooltipDisabled]="canBeSaved() | async"
                        >
                            <app-button
                                app-icon-button
                                iconAlign="horizontal"
                                textPosition="after"
                                buttonSize="normal"
                                (click)="openSaveDialog()"
                                [disabled]="!(canBeSaved() | async)"
                            >
                                <app-icon
                                    svgWidth="24"
                                    svgHeight="24"
                                    cssWidth="21px"
                                    cssHeight="19px"
                                    shape="save"
                                    iconSize="small"
                                ></app-icon>
                                {{ 'OVERVIEW_RIBBON.BUTTON_SAVE' | translate }}
                            </app-button>
                        </span>
                        <span
                            appTooltip
                            [appToolTipContent]="saveBasketToolTipContent"
                            [appTooltipPlacement]="'top'"
                            [appTooltipDisabled]="canBeSaved() | async"
                        >
                            <app-button
                                app-icon-button
                                iconAlign="horizontal"
                                textPosition="after"
                                buttonSize="normal"
                                (click)="openSaveToBasketDialog()"
                                [disabled]="!(canBeSaved() | async)"
                            >
                                <app-icon
                                    svgWidth="24"
                                    svgHeight="24"
                                    cssWidth="21px"
                                    cssHeight="19px"
                                    shape="icon-shopping-cart"
                                    iconSize="small"
                                ></app-icon>
                                {{ 'OVERVIEW_RIBBON.BUTTON_FINISH' | translate }}
                            </app-button>
                        </span>
                    </div>

                    <div *ngIf="!showCompletionButtons" class="contour-properties-container">
                        <app-contour-properties
                            *ngIf="showContourProperties"
                            [contourProperties]="contourProperties"
                        >
                        </app-contour-properties>
                        <app-display-options-panel
                            *ngIf="displayFunctions"
                        ></app-display-options-panel>
                        <app-zoom-panel
                            *ngIf="showZoomPanel"
                            [zoomValue]="zoomValue"
                            [zoomStep]="zoomStep"
                            [minValue]="100"
                            (zoomedIn)="zoomIn($event)"
                            (zoomedOut)="zoomOut($event)"
                            [titleZoomOut]="'MOUSEOVER.ZOOM_OUT' | translate"
                            [titleZoomIn]="'MOUSEOVER.ZOOM_IN' | translate"
                        >
                        </app-zoom-panel>
                    </div>
                    <app-price-display [price]="price" [vat]="vat"></app-price-display>
                </div>
            </div>
            <app-droppable-mirror></app-droppable-mirror>
        </div>
    </div>
</div>

<div *ngIf="isSavingFoamConfiguration | async" class="editor-wrapper transfer-overlay">
    <div>
        <p>{{ 'LOADING_MESSAGES.CONFIGURATOR_PROCESSING_ORDER' | translate }}</p>
        <app-spinner type="threeBounce" color="blue"></app-spinner>
    </div>
</div>
