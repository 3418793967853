import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, share, takeUntil } from 'rxjs/operators';

import { Point2D } from '../shared/geom/point2D';
import { Rectangle2D } from '../shared/geom/rectangle2D';
import { Handle } from '../shared/handle/handle';
import { CollisionHandlerService } from './collision/collision-handler.service';
import { RecessedGripCollisionService } from './recessed-grip-collision-service';
import { getScrollX, getScrollY } from '../../utils/dom-utils';
import { UndoManagerService } from '../undo/undo-manager.service';

import { ScrollViewportBounds } from './shared/canvas-scroll-viewport.directive';
import { CanvasElementChangedEvent } from './canvas.component';
import {
    CanvasCollisionVizData,
    ContourChangeData,
    ContourCollisionVizData,
    ContourDrawData,
    ContourItemsRemoved,
    ContourSelectionData,
    ContourStateData,
    FoamDrawData,
    MarginContourCollisionVizData,
    RubberBandDrawData,
    CanvasContour
} from './contour/contour-items-interfaces';
import { CuttableContour } from './contour/cuttable-contour';
import { FoamContour } from './contour/foam-contour';
import { GroupCuttableContour } from './contour/group-cuttable-contour';
import { RecessedGripContour } from './contour/recessed-grip-contour';
import { ResizeableContour } from './contour/resizeable-contour';
import { TextContour } from './contour/text-contour';
import {
    CollisionChangedEvent,
    CollisionEventNotifier,
    StaticCollisionChangedEvent
} from './collision/collision-event-notifier';

declare var Snap: any;
const MINIMUM_CONTOUR_DEPTH = 5;
const MINIMUM_GROUND_MARGIN = 5;
const MINIMUM_RECESSED_GRIP_DEPTH = 10;
const MAXIMUM_CUTTING_DEPTH = 77;

@Injectable()
export class CanvasService implements OnDestroy {
    private selectedItems: Set<CanvasContour> = new Set();

    private selectionChangedSource = new Subject<SelectionChangedData>();

    private foamContourItem: FoamContour;
    private scaleFactor: number = 1.0;
    private maxContourDepth: number;
    private readonly onScaleFactorChanged = new Subject<number>();
    private readonly onCanvasViewportChanged = new Subject<ScrollViewportBounds>();

    private canvasViewportBounds: ScrollViewportBounds;
    private canvasContentBounds: Rectangle2D = new Rectangle2D(0, 0, 1, 1);

    private readonly contourItemAdded = new Subject<ContourDrawData>();
    private readonly foamContourChanged = new BehaviorSubject<FoamDrawData>(null);

    private readonly contourItemChanged: Subject<ContourStateData>;
    private readonly contourItemRemoved: Subject<ContourItemsRemoved>;
    private focusRequester: () => void;
    private readonly onRubberBandChanged: Subject<RubberBandDrawData>;
    contentOffsetRequester: () => Point2D;
    private readonly handles: Map<string, Handle[]> = new Map();

    isEnabled: boolean;
    private propertyChangedSubject: BehaviorSubject<CanvasProperties>;
    viewportScrollPosition: Point2D = new Point2D(0, 0);
    addedItems: { [contourId: string]: CanvasContour } = {};

    constructor(
        private readonly collisionHandler: CollisionHandlerService,
        private collisionEventNotifier: CollisionEventNotifier,
        private readonly undoManagerService: UndoManagerService,
        private readonly recessedGripCollisionService: RecessedGripCollisionService
    ) {
        this.contourItemChanged = new Subject();
        this.contourItemRemoved = new Subject();
        this.onRubberBandChanged = new Subject();

        this.propertyChangedSubject = new BehaviorSubject<CanvasProperties>({
            showContourImage: true,
            showContourSafeMargin: true,
            scaleFactor: 1.0,
            maxContourDepth: 0
        });
        this.recessedGripCollisionService
            .getRecessedGripCollisionChange()
            .subscribe(x => this.updateRecessedGripsDepth(x));
    }

    findHandle(target: Element): Handle | undefined {
        let result: Handle;
        for (const values of this.handles.values()) {
            result = values.find(handle => handle.contains(target));
            if (result) {
                return result;
            }
        }

        return result;
    }

    setFoamContourItem(foamContourItem: FoamContour) {
        this.foamContourItem = foamContourItem;
        foamContourItem.setCanvas(this);
        const foamDepth = foamContourItem.depth ? foamContourItem.depth : 0;
        this.setProperties({
            maxCuttingDepth: MAXIMUM_CUTTING_DEPTH,
            maxContourDepth: foamDepth - MINIMUM_GROUND_MARGIN,
            minContourDepth: MINIMUM_CONTOUR_DEPTH,
            minRecessedGripDepth: MINIMUM_RECESSED_GRIP_DEPTH
        });
        this.foamContourChanged.next(foamContourItem.getDrawData());
    }

    updateFoamCollisionClipPath(minScaleFactor: number, viewportBounds: ScrollViewportBounds) {
        if (this.foamContourItem) {
            this.foamContourItem.updateClipPath(
                minScaleFactor,
                viewportBounds.outerBounds.width,
                viewportBounds.outerBounds.height
            );
        }
    }

    getFoamContourItem() {
        return this.foamContourItem;
    }

    /**
     * Checks if the contour is valid with respect to the depth constraints
     * @param depth
     */
    isContourDepthValid(depth: number): boolean {
        const props = this.propertyChangedSubject.getValue();
        const maxDepth = this.getPropertiesValue().isPartialCuttingEnable
            ? props.maxCuttingDepth
            : props.maxContourDepth;
        return depth >= props.minContourDepth && depth <= maxDepth;
    }

    /**
     * Adds the contour to canvas.
     * A group contour with less than two children will not be added.
     *
     * This method returns {@code false} if the contour could not have been added,
     * either because it already exists or is invalid
     *
     * @param contourItem to be added to the canvas
     * @return true, if the contour has been added, otherwise false
     */
    addContourItem(contourItem: CanvasContour): boolean {
        if (contourItem instanceof GroupCuttableContour && contourItem.children.length < 2) {
            console.warn('Cannot add group with only one contour');
            return false;
        }

        if (contourItem.contourId == null) {
            throw Error('Item has no contourId');
        }

        if (this.addedItems[contourItem.contourId] != null) {
            console.warn('Cannot add already existing contour');
            return false;
        }

        // text contours have no depth
        if (!(contourItem instanceof TextContour || contourItem instanceof GroupCuttableContour)) {
            if (!this.isContourDepthValid(contourItem.depth)) {
                return false;
            }
        }

        this.addedItems[contourItem.contourId] = contourItem;
        contourItem.setCanvas(this);
        const drawData = contourItem.getDrawData();

        if (drawData) {
            this.contourItemAdded.next(contourItem.getDrawData());
        } else {
            console.warn('drawData not found');
        }

        contourItem
            .getOnChanged()
            .pipe(takeUntil(contourItem.getOnRemoved()))
            .subscribe(changeData => {
                if (
                    changeData instanceof ContourChangeData &&
                    changeData.isDepthChanged &&
                    contourItem instanceof CuttableContour
                ) {
                    this.updateRecessedGripsDepthOf(contourItem);
                }

                this.contourItemChanged.next(changeData);
            });

        return true;
    }

    removeContours(contours: CanvasContour[]) {
        const contourIds: string[] = [];
        const toRemoveIds: string[] = [];
        contours.forEach(contour => {
            if (contour instanceof FoamContour) {
                console.error('Cannot remove the foam');
                return;
            }

            if (!this.addedItems[contour.contourId]) {
                console.warn('Failed to remove contour with ID: ' + contour.contourId);
                return;
            }

            delete this.addedItems[contour.contourId];

            if (contour instanceof GroupCuttableContour) {
                toRemoveIds.push(...contour.children.map(x => x.contourId));
                contourIds.push(...contour.children.map(x => x.contourId));
            } else {
                toRemoveIds.push(contour.contourId);
            }

            this.selectedItems.delete(contour);
            contour.remove();
            contourIds.push(contour.contourId);
        });

        if (contourIds.length > 0) {
            this.contourItemRemoved.next(new ContourItemsRemoved(contourIds));
        }

        // the order matter for stability reasons: first update the view and the collision handlers
        if (toRemoveIds.length > 0) {
            this.collisionHandler.removeNodes(toRemoveIds);
            toRemoveIds.forEach(x => this.recessedGripCollisionService.removeContour(x));
            // this.recessedGripCollisionService.removeContour(toRemoveIds);
        }
    }

    getContourItemRemoved(): Observable<ContourItemsRemoved> {
        return this.contourItemRemoved.asObservable();
    }

    /**
     * Informs the contour that its shape has changed and performs operations specific to shape
     * changes. These operations involve:
     *  - updating the collision state of the contour if it is {@link CanvasContour.isCollidable}
     *  and {@link CanvasContour.isSelectable}
     *  - computing the auto-depth of recessed-grips if the contour is a recessed-grip or
     *  a {@link CuttableContour} that intersects with one or more recessed-grips
     *
     * This method is called after the contour has been (re-)drawn by one ore more components.
     *
     * @param contourId - the contourId of the contour whose drawing state
     * @param drawnData - the shape changes of the contour
     * @param skipCollisionDetection
     */
    // TODO check if we can remove the contourId
    onContourDrawn(drawnData: CanvasElementChangedEvent, skipCollisionDetection?: boolean) {
        const contourItem = this.addedItems[drawnData.contourId];
        if (!contourItem) {
            return;
        }
        contourItem.onContourElementDrawn(drawnData);

        // isSelectable to exclude foam contour
        if (!skipCollisionDetection && contourItem.isSelectable) {
            this.updateCollisions(contourItem);
        }

        if (contourItem instanceof CuttableContour) {
            this.detectRecessedGripCollision(contourItem);
        } else if (contourItem instanceof GroupCuttableContour) {
            contourItem.children.forEach(item => this.detectRecessedGripCollision(item));
        }
    }

    onFoamContourDrawn(drawnData: CanvasElementChangedEvent) {
        if (!this.foamContourItem) {
            throw new Error('Failed to update foam');
        }
        this.foamContourItem.onContourElementDrawn(drawnData);
    }

    onFoamInnerMarginContourDrawn(drawnData: CanvasElementChangedEvent) {
        if (this.foamContourItem && this.foamContourItem.foamInnerMarginContour) {
            this.foamContourItem.foamInnerMarginContour.onContourElementDrawn(drawnData);
        }
    }

    private updateCollisions(contourItem: CanvasContour) {
        const collisionData = this.createCollisionVizData(contourItem);
        if (collisionData) {
            this.collisionHandler.detect(collisionData, true);
        } else {
            console.error('Failed to create collision detection data');
        }
    }

    private detectRecessedGripCollision(selectedContour: CuttableContour) {
        let otherContours: CuttableContour[] = [];

        const contours = this.getAllContourItems();
        for (let i = 0; i < contours.length; i++) {
            const contour = contours[i];
            if (contour !== selectedContour) {
                if (contour instanceof GroupCuttableContour) {
                    otherContours = otherContours.concat(
                        contour.children.filter(x => x instanceof CuttableContour)
                    );
                } else if (contour instanceof CuttableContour) {
                    otherContours.push(contour as CuttableContour);
                }
            }
        }

        if (!selectedContour) {
            return;
        }

        this.recessedGripCollisionService.detect(selectedContour, otherContours);
    }

    private updateRecessedGripsDepthOf(contour: CuttableContour) {
        const gripCollisions = this.recessedGripCollisionService.getRecessedGripOf(contour);
        if (gripCollisions.size > 0) {
            this.updateRecessedGripsDepth(gripCollisions);
        }
    }

    private updateRecessedGripsDepth(gripCollisions: Map<string, Set<string>>) {
        if (!gripCollisions) {
            return;
        }

        for (const [gripId, collidingContours] of gripCollisions.entries()) {
            // contour might be inside a group
            const gripToUpdate = this.getContourItem(gripId, true) as ResizeableContour;
            if (!gripToUpdate) {
                return;
            }

            if (!this.checkGripCollisionUpdateCondition(gripToUpdate)) {
                return;
            }

            // if the has no colliding contours then it should retain its last depth even
            // if it no longer collides with any contours.
            if (collidingContours.size > 0) {
                const newDepth = this.getRecessedGripMaxDepth(collidingContours);
                gripToUpdate.resizeDepth(newDepth);
            }
        }
    }

    /**
     * Checks if the grip collision detections should be updated.
     * @param contour
     */
    private checkGripCollisionUpdateCondition(contour: CuttableContour): boolean {
        return contour instanceof RecessedGripContour && !contour.userDefinedDepth;
    }

    private getRecessedGripMaxDepth(collidingContours: Set<string>) {
        let newDepth = 0;
        for (const contourId of collidingContours) {
            const contour = this.getContourItem(contourId, true);
            if (!contour) {
                throw ReferenceError();
            }
            newDepth = Math.max(newDepth, contour.depth);
        }
        return Math.min(newDepth, this.getPropertiesValue().maxContourDepth);
    }

    getOnContourAdded(): Observable<ContourDrawData> {
        return this.contourItemAdded.pipe(share());
    }

    getOnFoamChanged(): Observable<FoamDrawData> {
        return this.foamContourChanged.pipe(
            filter(data => data !== null),
            share()
        );
    }

    getOnContourChanged(): Observable<ContourStateData> {
        return this.contourItemChanged.asObservable();
    }

    getContourItem(contourId: string, withGroupChildren?: boolean): CanvasContour | null {
        const soughtContour = this.addedItems[contourId];
        if (!soughtContour && withGroupChildren) {
            for (const contour of Object.values(this.addedItems)) {
                if (contour instanceof GroupCuttableContour) {
                    const result = contour.getChildContour(contourId);
                    if (result) {
                        return result;
                    }
                }
            }
        }
        return soughtContour || null;
    }

    getAllContourItems(flatGroupChildren?: boolean): CanvasContour[] {
        if (flatGroupChildren) {
            const flattArray: CanvasContour[] = [];

            for (const contour of Object.values(this.addedItems)) {
                if (contour instanceof GroupCuttableContour) {
                    flattArray.push(...contour.children);
                } else {
                    flattArray.push(contour);
                }
            }
            return flattArray;
        } else {
            return Object.values(this.addedItems);
        }
    }

    requestFocus() {
        if (this.focusRequester) {
            this.focusRequester();
        }
    }

    /**
     * Sets the callback function that request the focus for the canvas component
     *
     * This is used to avoid any coupling with the canvas component
     * @param callbackFn
     */
    setFocusRequestHandler(callbackFn: () => void) {
        this.focusRequester = callbackFn;
    }

    /**
     * Sets the cursor of the Canvas
     */
    setCursor(cursorName: string) {}

    setCanvasViewportBounds(newViewportBounds: ScrollViewportBounds) {
        if (!this.canvasViewportBounds || !this.canvasViewportBounds.equals(newViewportBounds)) {
            this.canvasViewportBounds = newViewportBounds;
            // FIXME remove me. It should not be possible to change the canvasViewport via the
            // this.onCanvasViewportChanged.next(newViewportBounds);
        }
    }

    /**
     * Returns the bounds of the svg canvas view relative to the document viewport
     *
     */
    getCanvasViewportBounds(): ScrollViewportBounds {
        return this.canvasViewportBounds.clone();
    }

    getOnCanvasViewportChanged(): Observable<ScrollViewportBounds> {
        return this.onCanvasViewportChanged.pipe(
            distinctUntilChanged((bounds1, bounds2) => bounds1.equals(bounds2)),
            share()
        );
    }

    setCanvasContentBounds(newCanvasDrawBounds: Rectangle2D) {
        if (newCanvasDrawBounds) {
            this.canvasContentBounds = newCanvasDrawBounds.clone();
        }
    }

    getCanvasDrawBounds(): Rectangle2D | null {
        return this.canvasContentBounds ? this.canvasContentBounds.clone() : null;
    }

    /**
     * Checks whether the mouse target is an element of the
     *
     * @param evt
     */
    findContourItem(evt: MouseEvent): CanvasContour {
        const selectedBBoxes: CanvasContour[] = [];
        for (const contour of Object.values(this.addedItems)) {
            const targetPoint = this.clientXYToCanvas(evt.clientX, evt.clientY);
            if (contour.containsTarget(evt.target as Element)) {
                return contour;
            }

            // store selected bounds that might be used if no target is found
            if (
                contour instanceof GroupCuttableContour &&
                contour.contains(targetPoint.x, targetPoint.y)
            ) {
                selectedBBoxes.push(contour);
            }
        }

        // for now, if no target is found we pick the last added selected grouped contour based on
        // its bound box
        if (selectedBBoxes.length > 0) {
            return selectedBBoxes.pop();
        }

        return null;
    }

    findContourWithin(bounds: Rectangle2D): CanvasContour[] {
        const contained: Array<CanvasContour> = [];

        for (const woItem of Object.values(this.addedItems)) {
            const isIntersecting = Snap.path.isBBoxIntersect(woItem.globalMarginPathBBox, bounds);
            if (isIntersecting) {
                contained.push(woItem);
            }
        }

        return contained;
    }

    isContourItemSelected(contourItem: CanvasContour): boolean {
        return this.selectedItems.has(contourItem);
    }

    /**
     * Returns the selected contour items.
     *
     */
    getSelectedContourItems(): CanvasContour[] {
        return Array.from(this.selectedItems);
    }

    private getCollisionVizDataExpect(ignoreContourId?: string): ContourCollisionVizData[] {
        const data: ContourCollisionVizData[] = [];

        const otherContours = Object.values(this.addedItems).filter(
            x => !(x instanceof FoamContour)
        );

        for (const otherItem of otherContours) {
            if (otherItem.contourId !== ignoreContourId && otherItem.isCollidable) {
                if (otherItem instanceof GroupCuttableContour) {
                    otherItem.getCollisionData().forEach(x => {
                        if (x.contourId !== ignoreContourId) {
                            data.push(x);
                        }
                    });
                } else {
                    data.push(otherItem.getCollisionData() as ContourCollisionVizData);
                }
            }
        }

        return data;
    }

    private createCollisionVizData(woItem: CanvasContour): CanvasCollisionVizData | undefined {
        if (woItem instanceof FoamContour) {
            return undefined;
        }
        const collisionData = woItem.getCollisionData();
        const foamInnerMarginCollisionData = this.foamContourItem.foamInnerMarginContour
            ? this.foamContourItem.foamInnerMarginContour.getCollisionData()
            : undefined;

        if (collisionData instanceof Array) {
            const children = collisionData.map(childCollisionData => {
                return {
                    contourId: childCollisionData.contourId,
                    selectedContour: childCollisionData as MarginContourCollisionVizData,
                    otherContours: this.getCollisionVizDataExpect(
                        childCollisionData.contourId
                    ) as MarginContourCollisionVizData[]
                };
            });

            return {
                data: children,
                foam: this.foamContourItem.getCollisionData(),
                foamInnerMargin: foamInnerMarginCollisionData
            };
        } else {
            return {
                data: [
                    {
                        contourId: woItem.contourId,
                        selectedContour: woItem.getCollisionData() as MarginContourCollisionVizData,
                        otherContours: this.getCollisionVizDataExpect(
                            woItem.contourId
                        ) as MarginContourCollisionVizData[]
                    }
                ],
                foam: this.foamContourItem.getCollisionData(),
                foamInnerMargin: foamInnerMarginCollisionData
            };
        }
    }

    clearSelection() {
        const oldSelection = new Set(this.selectedItems);
        this.selectedItems.clear();
        const selectedIds: string[] = [];

        const selectionData: Set<ContourSelectionData> = new Set();
        oldSelection.forEach(woItem => {
            selectionData.add(
                new ContourSelectionData({
                    contourId: woItem.contourId,
                    itemProps: woItem.itemProps
                })
            );

            if (woItem instanceof GroupCuttableContour) {
                selectedIds.push(...woItem.children.map(x => x.contourId));
            } else {
                selectedIds.push(woItem.contourId);
            }
        });

        if (selectionData.size > 0) {
            this.selectionChangedSource.next({
                itemsToSelect: null,
                itemsToDeselect: selectionData
            });
        }

        if (selectedIds.length > 0) {
            this.collisionHandler.addNodeToStaticGraph(selectedIds);
        }
    }

    toggleSelection(contourItem: CanvasContour) {
        if (this.isContourItemSelected(contourItem)) {
            this.removeFromSelection([contourItem]);
        } else {
            this.addToSelection([contourItem]);
        }
    }

    /**
     * Adds the given contours to the current selection.
     *
     * @param contours
     */
    addToSelection(contours: CanvasContour[]) {
        const newSelection: Set<ContourSelectionData> = new Set();
        const selectedIds: string[] = [];

        for (let i = 0; i < contours.length; ++i) {
            const woItem = contours[i];

            if (
                !woItem.isSelectable ||
                this.selectedItems.has(woItem) ||
                !this.getContourItem(woItem.contourId)
            ) {
                continue;
            }

            this.selectedItems.add(woItem);
            // add handles
            this.handles.set(woItem.contourId, woItem.createHandles());

            const collision = this.createCollisionVizData(woItem);
            newSelection.add(
                new ContourSelectionData({
                    contourId: woItem.contourId,
                    itemProps: woItem.itemProps,
                    isCollidable: woItem.isCollidable,
                    handles: woItem.getHandlesDrawData(),
                    collision: collision,
                    visibility: woItem.getVisibility(),
                    selectionData: woItem.getSelectionData(),
                    sendTo: woItem instanceof RecessedGripContour ? 'back' : undefined
                })
            );

            if (woItem instanceof GroupCuttableContour) {
                selectedIds.push(...woItem.children.map(x => x.contourId));
            } else {
                selectedIds.push(woItem.contourId);
            }
        }

        // fire selection changed
        if (newSelection.size > 0) {
            this.selectionChangedSource.next({
                itemsToSelect: newSelection,
                itemsToDeselect: null
            });
        }
        if (selectedIds.length > 0) {
            this.collisionHandler.selectNode(selectedIds);
        }
    }

    /**
     * Removes the given contours from the current selection.
     *
     * @param contours
     */
    removeFromSelection(contours: CanvasContour[]) {
        const selectionData: Set<ContourSelectionData> = new Set();
        const removedIds: string[] = [];

        for (let i = 0; i < contours.length; ++i) {
            const woContour = contours[i];
            const deleted = this.selectedItems.delete(woContour);
            if (deleted) {
                this.handles.delete(woContour.contourId);
                selectionData.add(
                    new ContourSelectionData({
                        contourId: woContour.contourId,
                        itemProps: woContour.itemProps
                    })
                );

                if (woContour instanceof GroupCuttableContour) {
                    removedIds.push(...woContour.children.map(x => x.contourId));
                } else {
                    removedIds.push(woContour.contourId);
                }
            }
        }

        if (selectionData.size > 0) {
            this.selectionChangedSource.next({
                itemsToSelect: null,
                itemsToDeselect: selectionData
            });

            const selectedIds = Array.from(this.selectedItems).map(x => x.contourId);
            this.collisionHandler.addNodeToStaticGraph(removedIds, selectedIds);
        }
    }

    getOnSelectionChanged(): Observable<SelectionChangedData> {
        return this.selectionChangedSource.pipe(share());
    }

    /**
     * Converts x,y points of the client/page coordinate to the canvas coordinate.
     *
     * The client coordinate is relative to the top-left corner of the browser content area
     * Used to draw elements on the svg root or any unscaled (canvas) layers
     *
     */
    clientToCanvas(point: Point2D): Point2D {
        point.x =
            point.x -
            this.canvasViewportBounds.outerBounds.x +
            getScrollX() +
            this.viewportScrollPosition.x;
        point.y =
            point.y -
            this.canvasViewportBounds.outerBounds.y +
            getScrollY() +
            this.viewportScrollPosition.y;

        return point;
    }

    /**
     * Converts x,y points of the client/page coordinate to the canvas coordinate.
     *
     * Used to draw elements on the svg root or any unscaled (canvas) layers
     * @param ptX
     * @param ptY
     */
    clientXYToCanvas(ptX: number, ptY: number): Point2D {
        ptX =
            ptX -
            this.canvasViewportBounds.outerBounds.x +
            getScrollX() +
            this.viewportScrollPosition.x;
        ptY =
            ptY -
            this.canvasViewportBounds.outerBounds.y +
            getScrollY() +
            this.viewportScrollPosition.y;

        return new Point2D(ptX, ptY);
    }

    clientXYToCanvasViewport(ptX: number, ptY: number): Point2D {
        const newPtX = ptX - this.canvasViewportBounds.outerBounds.x + getScrollX();
        const newPtY = ptY - this.canvasViewportBounds.outerBounds.y + getScrollY();
        return new Point2D(newPtX, newPtY);
    }

    /**
     * Converts x,y points of the client/page coordinate to the canvas content layer coordinate.
     *
     * The canvas content layer is the top-layer that contains all items drawn on the canvas.
     *
     *
     * @param ptX
     * @param ptY
     */
    clientXYToFoamLayer(ptX: number, ptY: number): Point2D {
        const scaleFactor = this.getScaleFactor();
        // TODO not needed anymore
        if (!this.contentOffsetRequester) {
            throw new ReferenceError('contentOffsetRequester cannot be null');
        }

        const foamLayerOffset = this.contentOffsetRequester();
        ptX =
            (ptX - this.canvasViewportBounds.outerBounds.x - foamLayerOffset.x + getScrollX()) /
            scaleFactor;
        ptY =
            (ptY - this.canvasViewportBounds.outerBounds.y - foamLayerOffset.y + getScrollY()) /
            scaleFactor;

        return new Point2D(ptX, ptY);
    }

    /**
     * Gets the absolute offset from the mouse cursor to the top-left-corner of the given
     * {@link CanvasContour}
     *
     * @param cursorX x-coordinate of the cursor
     * @param cursorY y-coordinate of the cursor
     * @param contourItem
     */
    getCursorOffsetFromContour(
        cursorX: number,
        cursorY: number,
        contourItem: CanvasContour
    ): Point2D {
        const canvasCursorPt = this.clientXYToFoamLayer(cursorX, cursorY);
        return new Point2D(
            canvasCursorPt.x - contourItem.globalContourPathBBox.x,
            canvasCursorPt.y - contourItem.globalContourPathBBox.y
        );
    }

    getScaleFactor(): number {
        return this.scaleFactor;
    }

    setScaleFactor(newScaleFactor: number) {
        if (newScaleFactor <= 0.0 || Number.isNaN(newScaleFactor)) {
            return;
        }

        if (newScaleFactor !== this.scaleFactor) {
            this.scaleFactor = newScaleFactor;
            this.onScaleFactorChanged.next(newScaleFactor);
        }
    }

    getOnScaleFactorChanged(): Observable<number> {
        return this.onScaleFactorChanged.pipe(distinctUntilChanged(), share());
    }

    ngOnDestroy(): void {
        this.contourItemChanged.complete();
        this.contourItemRemoved.complete();
        this.onRubberBandChanged.complete();
    }

    getOnRubberBandChanged(): Observable<RubberBandDrawData> {
        return this.onRubberBandChanged.asObservable().pipe(share());
    }

    drawRubberBand(drawData: RubberBandDrawData) {
        this.onRubberBandChanged.next(drawData);
    }

    /**
     * Sets callback function used to by the CanvasService to compute the canvas content offset
     *
     * This is used to avoid any coupling with the canvas component
     * @param callbackFn
     */
    setContentOffsetHandler(callbackFn: () => Point2D) {
        this.contentOffsetRequester = callbackFn;
    }

    setEnabled(newValue: boolean) {
        this.isEnabled = newValue;
    }

    setProperties(property: CanvasProperties) {
        this.propertyChangedSubject.next({
            ...this.propertyChangedSubject.getValue(),
            ...property
        });
    }

    getProperties(): Observable<CanvasProperties> {
        return this.propertyChangedSubject.asObservable();
    }

    getPropertiesValue(): CanvasProperties {
        return this.propertyChangedSubject.getValue();
    }

    getUndoManagerService(): UndoManagerService {
        return this.undoManagerService;
    }

    onStaticCollisionChanged(): Observable<StaticCollisionChangedEvent> {
        return this.collisionEventNotifier.onStaticCollisionChanged;
    }

    onDynamicCollisionChanged(): Observable<CollisionChangedEvent> {
        return this.collisionEventNotifier.onDynamicCollisionChanged;
    }
}

export interface SelectionChangedData {
    itemsToSelect: Set<ContourSelectionData>;
    itemsToDeselect: Set<ContourSelectionData>;
}

export interface CanvasProperties {
    showContourImage?: boolean;
    showContourSafeMargin?: boolean;
    isPartialCuttingEnable?: boolean;
    scaleFactor?: number;
    maxContourDepth?: number;
    maxCuttingDepth?: number;
    minContourDepth?: number;
    minRecessedGripDepth?: number;
}
