import { Observable, Subject } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';

import { FoamEditorService } from '../../foam-editor.service';
import { transformPoint } from '../../shared/geom/matrix';
import { Point2D } from '../../shared/geom/point2D';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { Handle } from '../../shared/handle/handle';
import { RotationHandle } from '../../shared/handle/rotation-handle';
import { PolygonSegment } from '../collision/collision-util';
import { transformBoundingBox } from './contour-helper';
import { nextUniqueId } from '../../../utils/unique-id';
import { CanvasElementChangedEvent } from '../canvas.component';
import { CanvasService } from '../canvas.service';

import {
    ContourChangeData,
    ContourHandlesData,
    ContourStateData,
    ContourType,
    GroupContourDrawData,
    ItemProperties,
    MarginContourCollisionVizData,
    CanvasContour
} from './contour-items-interfaces';
import { CuttableContour } from './cuttable-contour';

declare var Snap: any;

/**
 * Group DefaultContourDraw
 * Note: only one level of grouping is supported
 */
export class GroupCuttableContour implements CanvasContour {
    boundingBox: Snap.BBox | undefined;
    contourId: string;
    contourType: ContourType;
    globalContourPathBBox: Rectangle2D;
    globalMarginPathBBox: Rectangle2D;
    image: string;
    isCollidable: boolean = true;
    isRemovable: boolean = true;
    isSelectable: boolean = true;
    isResizable: boolean = false;
    itemElement: Snap.Element | undefined;
    readonly itemProps: ItemProperties;
    itemTransformation: Snap.TransformationDescriptor | undefined;
    localContourPathBBox: Rectangle2D;

    /**
     * The local bounding box at the time of the group creation. It is specified as (y=min,y=minX,
     * width, height), where width = largest x position of all child's corners and height = largest
     * y position of all child's corners
     */
    localMarginPathBBox: Rectangle2D;
    marginBoundingBox: Snap.BBox | undefined;
    marginPath: Snap.Element | undefined;
    marginPathString: string | undefined;
    marginPolygonLines: Array<PolygonSegment> | undefined;
    pathSegments: Array<any> | undefined;
    svgPathDefinition: string;
    polygonLines: Array<PolygonSegment> | undefined;
    totalDegree: number | undefined;
    children: CuttableContour[] = [];

    private canvasService: CanvasService;

    private contourChanged: Subject<ContourStateData> = new Subject();
    private contourRemoved: Subject<void> = new Subject();

    globalContourPathMatrix: Snap.Matrix;
    globalMarginPathMatrix: Snap.Matrix;
    localContourPathMatrix: Snap.Matrix;
    localMarginPathMatrix: Snap.Matrix;

    contourBoundsInParent: Rectangle2D;

    private rotationHandle: RotationHandle<GroupCuttableContour>;
    private lastAnchor: Point2D = new Point2D(Number.MIN_VALUE, Number.MIN_VALUE);
    private itemAnchors: Map<string, Point2D> = new Map();
    private initialGlobalContourPathBBox: Rectangle2D;
    private initialLocalContourPathBBox: Rectangle2D;
    private initialBoundsInvalid: boolean = true;

    protected visible: boolean = true;
    isRotatable: boolean = true;

    depth: number;
    height: number;
    width: number;

    constructor(children?: CuttableContour[]) {
        this.contourId = nextUniqueId();
        if (children && children.length > 0) {
            this.children = Array.from(children);
            this.children.forEach(childContour => {
                childContour.parentContour = this;
                this.listenToChildChanges(childContour);
            });
        }
        // Contour
    }

    getChildContour(contourId: string): CuttableContour | undefined {
        return this.children.find(x => x.contourId === contourId);
    }

    private listenToChildChanges(childContour: CuttableContour) {
        childContour
            .getOnChanged()
            .pipe(takeUntil(this.getOnRemoved()))
            .subscribe(changeData => {
                this.contourChanged.next(
                    new ContourChangeData({
                        contourId: this.contourId,
                        children: [changeData]
                    })
                );
            });
    }

    /**
     * Adds a child contour item to the group contour.
     *
     * @param contourItem
     */
    add(contourItem: CuttableContour) {
        this.children.push(contourItem);
        contourItem.parentContour = this;
        this.listenToChildChanges(contourItem);
    }

    contains(x: number, y: number): boolean {
        return this.globalContourPathBBox.contains(x, y);
    }

    containsTarget(target: Element): boolean {
        if (!this.children) {
            return false;
        }

        return !!this.children.find(x => x.containsTarget(target));
    }

    createHandles(): Handle[] {
        if (!this.rotationHandle) {
            this.rotationHandle = new RotationHandle<GroupCuttableContour>(this);
        }
        return [this.rotationHandle];
    }

    getHandlesDrawData(): ContourHandlesData {
        return new ContourHandlesData({
            containerId: 'handle-container-' + this.contourId,
            contourId: this.contourId,
            transformBounds: {
                localContourBounds: this.localContourPathBBox,
                localMarginBounds: this.localMarginPathBBox,
                selectionBoundsMatrix: this.localContourPathMatrix
            },
            rotationHandles: this.rotationHandle.getDrawData()
        });
    }

    getCollisionData(): MarginContourCollisionVizData[] {
        return this.children.filter(x => x.isCollidable).map(x => x.getCollisionData());
    }

    getDrawData(): GroupContourDrawData {
        return new GroupContourDrawData({
            contourId: this.contourId,
            children: this.children.map(x => x.getDrawData())
        });
    }

    getOnChanged(): Observable<ContourStateData> {
        return this.contourChanged.pipe(share());
    }

    getOnRemoved(): Observable<void> {
        return this.contourRemoved.pipe(share());
    }

    getTool(target: Element, editor?: FoamEditorService): null {
        return null;
    }

    onContourElementDrawn(drawnData: CanvasElementChangedEvent): void {
        if (!drawnData) {
            return;
        }

        if (drawnData.contour) {
            this.globalContourPathBBox = drawnData.contour.globalBBox;
            this.localContourPathBBox = drawnData.contour.localBBox;
            this.globalContourPathMatrix = drawnData.contour.globalMatrix;

            // margin == contour
            this.globalMarginPathMatrix = drawnData.contour.globalMatrix;
            this.globalMarginPathBBox = drawnData.contour.globalBBox;
            this.localMarginPathBBox = drawnData.contour.localBBox;

            // Since the group bounds change after each transformation, we must cache the initial
            // bounds for transforming the handles properly
            if (this.initialBoundsInvalid) {
                this.localContourPathMatrix = drawnData.contour.localMatrix;
                this.localMarginPathMatrix = drawnData.contour.localMatrix;

                this.initialGlobalContourPathBBox = drawnData.contour.globalBBox;
                this.initialLocalContourPathBBox = drawnData.contour.localBBox;
                this.initialBoundsInvalid = false;
            }

            this.contourBoundsInParent = Rectangle2D.fromPointsObject(
                transformBoundingBox(drawnData.contour.localBBox, this.localContourPathMatrix)
            );
        }

        if (drawnData.children) {
            this.children.forEach(child => {
                const childDrawData = drawnData.children[child.contourId];
                if (childDrawData) {
                    child.onContourElementDrawn(childDrawData);
                }
            });
        }
    }

    remove() {
        this.children.forEach(childContour => {
            childContour.parentContour = undefined;
            childContour.remove();
        });
        this.children = [];
        this.children = undefined;
        this.contourRemoved.next();
    }

    setCanvas(canvas: CanvasService) {
        this.canvasService = canvas;
    }

    getCanvas(): CanvasService {
        return this.canvasService;
    }

    private childAnchor(contour: CuttableContour, anchorX: number, anchorY: number) {
        let itemAnchor = this.itemAnchors.get(contour.contourId);
        // Since the rotation pivot is given relative to the local bounds of the contour
        // to be rotated, we must convert the group rotation pivot relative to each contour
        if (!itemAnchor /* || (this.lastAnchor.x !== anchorX || this.lastAnchor.y !== anchorY) */) {
            this.lastAnchor.x = anchorX;
            this.lastAnchor.y = anchorY;

            const anchorPos = transformPoint(anchorX, anchorY, this.localContourPathMatrix);
            const itemTopLeft = transformPoint(
                contour.localContourPathBBox.x,
                contour.localContourPathBBox.y,
                contour.localContourPathMatrix
            );

            itemAnchor = new Point2D(anchorPos.x - itemTopLeft.x, anchorPos.y - itemTopLeft.y);
            this.itemAnchors.set(contour.contourId, itemAnchor);
        }

        return itemAnchor;
    }

    rotate(angle: number, anchorX: number, anchorY: number): ContourChangeData {
        if (this.children) {
            const contourChangeData = this.performChangeOp(() => {
                return this.children.map(contour => {
                    const itemAnchor = this.childAnchor(contour, anchorX, anchorY);
                    return contour.rotate(angle, itemAnchor.x, itemAnchor.y, true);
                });
            });

            if (this.localContourPathMatrix) {
                this.localContourPathMatrix.rotate(angle, anchorX, anchorY);
            } else {
                this.localContourPathMatrix = Snap.matrix().rotate(angle, anchorX, anchorY);
            }

            this.localMarginPathMatrix = this.localContourPathMatrix;

            contourChangeData.transformString = this.localContourPathMatrix.toTransformString();
            contourChangeData.matrix = this.localContourPathMatrix;
            contourChangeData.x = (this.localContourPathMatrix as any).e;
            contourChangeData.y = (this.localContourPathMatrix as any).f;
            contourChangeData.rotate = {
                deltaAngle: angle,
                anchorX: anchorX,
                anchorY: anchorY
            };

            contourChangeData.handles = new ContourHandlesData({
                contourId: this.contourId,
                transformBounds: {
                    localContourBounds: this.initialLocalContourPathBBox,
                    localMarginBounds: this.initialLocalContourPathBBox,
                    selectionBoundsMatrix: this.localContourPathMatrix
                }
            });

            this.contourChanged.next(contourChangeData);
            return contourChangeData;
        }
        return undefined;
    }

    transform(matrix: Snap.Matrix): ContourChangeData {
        if (this.children) {
            const contourChangeData = this.performChangeOp(() => {
                return this.children.map(contour => contour.transform(matrix, true));
            });

            this.contourChanged.next(contourChangeData);
            return contourChangeData;
        }
        return undefined;
    }

    translate(dx: number, dy: number): ContourChangeData {
        if (this.children) {
            const contourChangeData = this.performChangeOp(() => {
                return this.children.map(contour => contour.translate(dx, dy, true));
            });

            if (this.localContourPathMatrix) {
                // prepend the translation before the rotation
                this.localContourPathMatrix = Snap.matrix()
                    .translate(dx, dy)
                    .add(this.localContourPathMatrix.clone());
            } else {
                this.localContourPathMatrix = Snap.matrix().translate(dx, dy);
            }

            this.localMarginPathMatrix = this.localContourPathMatrix;

            contourChangeData.transformString = this.localContourPathMatrix.toTransformString();
            contourChangeData.matrix = this.localContourPathMatrix;
            contourChangeData.x = (this.localContourPathMatrix as any).e;
            contourChangeData.y = (this.localContourPathMatrix as any).f;
            contourChangeData.translate = {
                dx: dx,
                dy: dy
            };

            contourChangeData.handles = new ContourHandlesData({
                contourId: this.contourId,
                transformBounds: {
                    localContourBounds: this.initialLocalContourPathBBox,
                    localMarginBounds: this.initialLocalContourPathBBox,
                    selectionBoundsMatrix: this.localContourPathMatrix
                }
            });

            this.contourChanged.next(contourChangeData);
            return contourChangeData;
        }

        return undefined;
    }

    performChangeOp(callback: () => ContourChangeData[]): ContourChangeData {
        // One level iteration
        const childChangeData: ContourChangeData[] = callback();

        return new ContourChangeData({
            contourId: this.contourId,
            children: childChangeData
        });
    }

    getSelectionData(): undefined {
        return undefined;
    }

    seVisibility(newValue: boolean) {
        if (this.visible !== newValue) {
            this.visible = newValue;

            this.contourChanged.next(
                new ContourChangeData({
                    contourId: this.contourId,
                    visibility: this.visible
                })
            );
        }
    }

    getVisibility(): boolean {
        return this.visible;
    }

    clone() {}

    size(): number {
        return !this.children ? 0 : this.children.length;
    }
}
