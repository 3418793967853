/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expansion-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./expansion-panel.component";
import * as i3 from "./accordion.directive";
import * as i4 from "../../../shared/collections/unique-selection-dispatcher";
var styles_ExpansionPanelComponent = [i0.styles];
var RenderType_ExpansionPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ExpansionPanelComponent, data: { "animation": [{ type: 7, name: "bodyExpansion", definitions: [{ type: 0, name: "collapsed", styles: { type: 6, styles: { height: "0px", visibility: "hidden" }, offset: null }, options: undefined }, { type: 0, name: "expanded", styles: { type: 6, styles: { height: "*", visibility: "visible" }, offset: null }, options: undefined }, { type: 1, expr: "expanded <=> collapsed", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(0.4,0.0,0.2,1)" }, options: null }], options: {} }] } });
export { RenderType_ExpansionPanelComponent as RenderType_ExpansionPanelComponent };
export function View_ExpansionPanelComponent_0(_l) { return i1.ɵvid(2, [i1.ɵncd(null, 0), (_l()(), i1.ɵeld(1, 0, [["body", 1]], null, 2, "div", [["class", "app-expansion-panel-content"]], [[24, "@bodyExpansion", 0], [8, "id", 0]], [[null, "@bodyExpansion.done"], [null, "@bodyExpansion.start"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@bodyExpansion.done" === en)) {
        var pd_0 = (_co._bodyAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("@bodyExpansion.start" === en)) {
        var pd_1 = (_co._bodyAnimation($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "app-expansion-panel-body"]], null, null, null, null, null)), i1.ɵncd(null, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._getExpandedState(); var currVal_1 = _co.id; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ExpansionPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expansion-panel", [["class", "app-expansion-panel"]], [[2, "app-expanded", null]], null, null, View_ExpansionPanelComponent_0, RenderType_ExpansionPanelComponent)), i1.ɵdid(1, 180224, null, 0, i2.ExpansionPanelComponent, [[2, i3.AccordionDirective], i1.ChangeDetectorRef, i4.UniqueSelectionDispatcher], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).expanded; _ck(_v, 0, 0, currVal_0); }); }
var ExpansionPanelComponentNgFactory = i1.ɵccf("app-expansion-panel", i2.ExpansionPanelComponent, View_ExpansionPanelComponent_Host_0, { expanded: "expanded", disabled: "disabled", hideToggle: "hideToggle" }, { closed: "closed", opened: "opened", expandedChange: "expandedChange" }, ["app-expansion-panel-header", "*"]);
export { ExpansionPanelComponentNgFactory as ExpansionPanelComponentNgFactory };
