<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <div class="contour-image">
                    <img
                        *ngIf="selectedContour"
                        src="data:image/png;base64,{{ selectedContour.image }}"
                        alt=""
                    />
                </div>
            </div>
            <div class="contours-browser__container">
                <div class="dialog-content__inner-container">
                    <div class="contours-browser__title">
                        {{ 'CONTOURS_CONFIG_UPLOAD_DIALOG.CAPTURED_TOOLS' | translate }}
                    </div>
                    <div class="contours-browser">
                        <div class="contours-browser__scroll-button-left">
                            <app-icon
                                [disabled]="!canScrollContoursLeft"
                                [shape]="'keyboard_arrow_left'"
                                [cssHeight]="'40px'"
                                [cssWidth]="'18px'"
                                (click)="scrollContoursUp($event)"
                            ></app-icon>
                        </div>

                        <app-scroll-viewport
                            #contourScrollViewport
                            orientation="horizontal"
                            itemSize="52"
                            itemSpace="10"
                            [itemCount]="contours ? contours.length : 0"
                            class="upload-contours-scroll-viewport"
                        >
                            <div class="uploaded-contours-list">
                                <div
                                    *ngFor="let contour of contours; let i = index"
                                    class="contour-browser-item__image"
                                    [class.contour-browser-item__image--selected]="
                                        selectedContourIndex == i
                                    "
                                >
                                    <app-icon
                                        *ngIf="contour.isValid"
                                        class="uploaded-contour-valid-icon"
                                        shape="check-circle"
                                        iconSize="small"
                                        cssWidth="20px"
                                        cssHeight="20px"
                                    >
                                    </app-icon>
                                    <a (click)="selectContour(i)">
                                        <img src="data:image/png;base64,{{ contour.image }}" />
                                    </a>
                                </div>
                            </div>
                        </app-scroll-viewport>

                        <div class="contours-browser__scroll-button-right">
                            <a (click)="scrollContoursDown($event)">
                                <app-icon
                                    [disabled]="!canScrollContoursRight"
                                    [shape]="'keyboard_arrow_right'"
                                    [cssHeight]="'40px'"
                                    [cssWidth]="'18px'"
                                ></app-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-content__inner-container">
                <div class="dialog-content__title connector-container">
                    {{ 'CONTOURS_CONFIG_UPLOAD_DIALOG.DETAILS' | translate }}
                    <svg
                        *ngIf="contourConnectorPath != null"
                        id="border-contour-connector"
                        width="100%"
                        height="100%"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <path [attr.d]="contourConnectorPath" />
                    </svg>
                </div>
                <div
                    *ngIf="currentContourFormGroup"
                    class="upload-form-group-{{ selectedContourIndex }}"
                >
                    <form [formGroup]="currentContourFormGroup">
                        <div class="form-field" id="form-field__contour-title">
                            <label class="form-field__label" for="input-contour-title">{{
                                'CONTOURS_CONFIG_UPLOAD_DIALOG.NAME' | translate
                            }}</label>
                            <input
                                type="text"
                                formControlName="title"
                                id="input-contour-title"
                                class="input-element"
                                placeholder="{{
                                    'CONTOURS_CONFIG_UPLOAD_DIALOG.NAME_PLACEHOLDER' | translate
                                }}"
                                maxlength="20"
                                required
                            />
                            <div class="form-field__hint">
                                {{ 'CONTOURS_CONFIG_UPLOAD_DIALOG.NAME_INFO_TEXT' | translate }}
                            </div>
                        </div>

                        <div class="form-field" id="form-field__contour-desc">
                            <label class="form-field__label" for="input-contour-desc">{{
                                'CONTOURS_CONFIG_UPLOAD_DIALOG.DESCRIPTION' | translate
                            }}</label>
                            <input
                                id="input-contour-desc"
                                type="text"
                                class="input-element"
                                formControlName="description"
                            />
                            <div class="form-field__hint">
                                {{
                                    'CONTOURS_CONFIG_UPLOAD_DIALOG.DESCRIPTION_INFO_TEXT'
                                        | translate
                                }}
                            </div>
                        </div>
                        <div class="form-field" id="form-field__contour-depth">
                            <label class="form-field__label" for="input-contour-depth">{{
                                'CONTOURS_CONFIG_UPLOAD_DIALOG.HEIGHT' | translate
                            }}</label>
                            <input
                                id="input-contour-depth"
                                type="text"
                                class="input-element"
                                required
                                formControlName="depth"
                            />
                            <div class="form-field__hint">
                                {{ 'CONTOURS_CONFIG_UPLOAD_DIALOG.HEIGHT_INFO_TEXT' | translate }}
                            </div>
                        </div>
                    </form>
                    <div class="delete-button">
                        <app-link-text
                            app-icon-link-text
                            textPosition="after"
                            (click)="deleteContour(selectedContourIndex)"
                        >
                            <app-icon
                                shape="bin"
                                iconSize="small"
                                cssWidth="20"
                                cssHeight="20"
                            ></app-icon>
                            {{ 'CONTOURS_CONFIG_UPLOAD_DIALOG.DELETE_CONTOUR' | translate }}
                        </app-link-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-upload-dialog-footer></app-upload-dialog-footer>
