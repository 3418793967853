/**
 * This directive is used to scale and center the foam and its contour items
 */
import { AfterViewInit, ContentChild, Directive, ElementRef, Input, OnInit } from '@angular/core';

import { InlTextChangedEvent } from '../inl-svg-text-input-element';
import { DroppableContainerDirective } from '../../../droppable/droppable-container.directive';
import { DroppableItemDirective } from '../../../droppable/droppable-item.directive';
import { Point2D } from '../../shared/geom/point2D';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { sendToBack } from '../../../utils/dom-utils';
import { CanvasElementChangedEvent } from '../canvas.component';
import { CanvasService, SelectionChangedData } from '../canvas.service';
import {
    ContourElementBuilder,
    ContourElementHolder,
    GroupContourElementHolder,
    TextContourElementHolder
} from '../contour-element-builder.service';
import {
    ContourChangeData,
    ContourDrawData,
    ContourHandlesData,
    ContourItemsRemoved,
    CuttableContourDrawData,
    GroupContourDrawData,
    TextCuttableContourDrawData
} from '../contour/contour-items-interfaces';
import { TextContour } from '../contour/text-contour';
import { LayerElementStoreService } from '../layer-element-store.service';

import { CanvasLayer } from './canvas-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';
import { CanvasOverlayLayerDirective } from './canvas-overlay-layer.directive';
import { CollisionLayerDirective } from './collision-layer.directive';
import { ContourItemsLayerDirective } from './contour-items-layer.directive';
import { FixedCollisionLayerDirective } from './fixed-collision-layer.directive';
import { FoamLayerDirective } from './foam-layer.directive';
import { SafetyMarginLayerDirective } from './safety-margin-layer.directive';
import { SelectionLayerDirective } from './selection-layer.directive';

declare var Snap: any;

@Directive({ selector: '[appItemLayer]', providers: [ContourElementBuilder] })
export class ItemsLayerDirective extends CanvasLayerMixin()
    implements CanvasLayer<Snap.Paper>, OnInit, AfterViewInit {
    @ContentChild(FoamLayerDirective, { static: false })
    foamLayer: FoamLayerDirective;

    /**
     * @deprecated use element instead
     */
    private svgElement: Snap.Paper;
    element: Snap.Paper;
    private localMatrix: Snap.Matrix;
    layerOffset: Point2D = new Point2D(0, 0);
    private canvasViewport: Element;

    @ContentChild(SafetyMarginLayerDirective, { static: false })
    marginLayer: SafetyMarginLayerDirective;

    @ContentChild(ContourItemsLayerDirective, { static: false })
    contourItemsLayer: ContourItemsLayerDirective;

    @ContentChild(CollisionLayerDirective, { static: false })
    private readonly collisionLayer: CollisionLayerDirective;

    @ContentChild(FixedCollisionLayerDirective, { static: false })
    private readonly fixedCollisionLayer: FixedCollisionLayerDirective;

    @ContentChild(SelectionLayerDirective, { static: false })
    private readonly selectionLayer: SelectionLayerDirective;

    @ContentChild(DroppableContainerDirective, { static: false })
    readonly droppableContainer: DroppableContainerDirective;

    overlayLayer: CanvasOverlayLayerDirective;

    private svgRoot: SVGSVGElement;

    constructor(
        private readonly elementRef: ElementRef,
        private canvasService: CanvasService,
        private layerElementStore: LayerElementStoreService,
        private contourBuilder: ContourElementBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.svgElement = this.element = Snap(this.elementRef.nativeElement);
        this.canvasViewport = document.querySelector('.canvas-viewport');
        this.svgRoot = document.querySelector('.svg-frame') as SVGSVGElement;
    }

    ngAfterViewInit(): void {
        this.localMatrix = this.element.transform().localMatrix;
    }

    scaleLayers(scaleFactor: number, centerXY?: Point2D) {
        const tx = Snap.matrix();
        tx.a *= scaleFactor;
        tx.d *= scaleFactor;

        // Add translation to the y,x location for centering the item layer
        if (centerXY) {
            tx.translate(centerXY.x / scaleFactor, centerXY.y / scaleFactor);
        }
        this.element.node.setAttribute('transform', tx.toString());
        this.layerOffset.x = tx.e;
        this.layerOffset.y = tx.f;

        if (this.layerElementStore.foamElementHolder) {
            const foamChangedEvent = this.layerElementStore.foamElementHolder.getDrawData();
            this.layerElementStore.notifyFoamContourChange(foamChangedEvent);
        }

        this.scaleFactor = scaleFactor;
        // update the badges if the scale factor has changed
        this.scaleContourElements();
    }

    private scaleContourElements() {
        const elementHolders = Object.values(this.layerElementStore.contourElementHolders);
        elementHolders.forEach(holder => {
            if (
                holder instanceof ContourElementHolder ||
                holder instanceof TextContourElementHolder
            ) {
                const changedEvent = holder.getDrawData();
                this.overlayLayer.updateBadgePosition(
                    holder.contourId,
                    changedEvent.contour.globalBBox
                );
                this.layerElementStore.addContourElementChanges(changedEvent);
            } else if (holder instanceof GroupContourElementHolder) {
                const changedEvent = holder.getDrawData(this.scaleFactor, this.layerOffset);
                this.layerElementStore.addContourElementChanges(changedEvent);
            }
        });

        this.layerElementStore.notifyOnContourChanges();
    }

    private _showContourImage: boolean = true;

    @Input()
    get showContourImage(): boolean {
        return this._showContourImage;
    }

    set showContourImage(newValue: boolean) {
        if (this._showContourImage !== newValue) {
            this._showContourImage = newValue;
            const displayValue = newValue ? 'inline' : 'none';

            Object.values(this.layerElementStore.contourElementHolders).forEach(holder => {
                if (holder instanceof ContourElementHolder && holder.contourElements.imageElement) {
                    holder.contourElements.imageElement.node.style.display = displayValue;
                } else if (holder instanceof GroupContourElementHolder) {
                    Object.values(holder.elementHolders).forEach(holderChild => {
                        if (
                            holderChild instanceof ContourElementHolder &&
                            holderChild.contourElements.imageElement
                        ) {
                            holderChild.contourElements.imageElement.node.style.display = displayValue;
                        }
                    });
                }
            });
        }
    }

    private _showContourSafeMargin: boolean = true;
    @Input()
    get showContourSafeMargin(): boolean {
        return this._showContourSafeMargin;
    }

    set showContourSafeMargin(newValue: boolean) {
        Object.values(this.layerElementStore.contourElementHolders).forEach(holder => {
            if (holder instanceof ContourElementHolder && holder.marginElements) {
                holder.update({
                    contourId: holder.contourId,
                    marginVisibility: newValue
                });
            } else if (holder instanceof GroupContourElementHolder) {
                Object.values(holder.elementHolders).forEach(holderChild => {
                    holderChild.update({
                        contourId: holder.contourId,
                        marginVisibility: newValue
                    });
                });
            }
        });
    }

    addContourItem(contourData: ContourDrawData) {
        if (contourData instanceof TextCuttableContourDrawData) {
            this.addTextContourItem(contourData);
            this.layerElementStore.notifyOnContourChanges();
        } else if (contourData instanceof CuttableContourDrawData) {
            this.addSingleContourItem(contourData);
            this.layerElementStore.notifyOnContourChanges();
        } else if (contourData instanceof GroupContourDrawData) {
            this.addGroupContourItem(contourData);
            this.layerElementStore.notifyOnContourChanges();
        }
    }

    private createContourElements(contourData: CuttableContourDrawData) {
        const contourLayerElement = this.contourItemsLayer.element;
        const contourElementHolder = this.contourBuilder
            .createContour(contourData.contourId)
            .setContourElements(contourLayerElement, contourData)
            .setMarginElements(this.marginLayer.element, contourData);

        if (contourElementHolder.contourElements.imageElement && !this.showContourImage) {
            contourElementHolder.contourElements.imageElement.node.style.display = 'none';
        }

        return contourElementHolder;
    }

    private createTextContourElements(
        contourData: TextCuttableContourDrawData
    ): TextContourElementHolder {
        const onChangeCallback = (textChanged: InlTextChangedEvent) => {
            const holder = this.layerElementStore.getContourElements2(textChanged.contourId);
            if (holder instanceof TextContourElementHolder) {
                holder.update({
                    contourId: textChanged.contourId,
                    textContent: textChanged.textContent
                });
                const changedEvent = holder.getDrawData();

                // update selection handles
                this.selectionLayer.changeContourItem(
                    new ContourChangeData({
                        contourId: changedEvent.contourId,
                        handles: new ContourHandlesData({
                            contourId: changedEvent.contourId,
                            transformBounds: {
                                localContourBounds: changedEvent.contour.localBBox,
                                localMarginBounds: changedEvent.margin.localBBox,
                                selectionBoundsMatrix: changedEvent.contour.localMatrix
                            }
                        })
                    })
                );

                this.collisionLayer.changeContourItem(
                    new ContourChangeData({
                        contourId: changedEvent.contourId,
                        marginPathString: holder.marginElements.marginPathString,
                        matrix: holder.marginElements.transformData.localMatrix
                    })
                );

                this.overlayLayer.updateBadgePosition(
                    changedEvent.contourId,
                    changedEvent.contour.globalBBox
                );

                this.layerElementStore.addContourElementChanges(changedEvent);
                this.layerElementStore.notifyOnContourChanges();
            }
        };

        const textElementHolder = this.contourBuilder
            .createTextContour(contourData.contourId)
            .setTextContourElements(this.contourItemsLayer.element, contourData, {
                svgRoot: this.svgRoot,
                htmlTextInputElement: this.layerElementStore.htmlTextInputContainer.nativeElement,
                onTextChanged: onChangeCallback
            });

        // Update bbox to encounter tspan-based line breaks for itemPath and marginPath
        // const textBBox = Rectangle2D.fromObject((textElem.node as any).getBBox());
        const textBBox = Rectangle2D.fromObject(textElementHolder.textInput.getTextBBox(true));
        const marginPathString = TextContour.createTextMarginPathString(
            textBBox,
            contourData.marginSize
        );

        textElementHolder.setMarginElements(this.marginLayer.element, contourData, {
            marginPathString: marginPathString
        });

        // TODO is this working?
        if (!this.marginLayer.showContourSafetyMargin) {
            textElementHolder.marginElements.pathElement.node.style.display = 'none';
        }

        return textElementHolder;
    }

    private addSingleContourItem(contourData: CuttableContourDrawData) {
        const elementHolder = this.createContourElements(contourData);

        if (contourData.sendTo === 'back') {
            const groupEl = elementHolder.contourElements.groupElement;
            sendToBack(groupEl.parent().node, groupEl.node);
        }

        // TODO is this working?
        if (!this.marginLayer.showContourSafetyMargin) {
            elementHolder.marginElements.pathElement.node.style.display = 'none';
        }

        this.layerElementStore.setContourElements(contourData.contourId, elementHolder);

        this.makeContourDroppable(
            elementHolder.contourElements.pathElement,
            elementHolder.marginElements.pathElement
        );

        const changedEvent = elementHolder.getDrawData(true);
        this.layerElementStore.addContourElementChanges(changedEvent);
    }

    private makeContourDroppable(itemPath: Snap.Element, marginPath: Snap.Element) {
        if (itemPath) {
            // create the droppable directive dynamically for the item
            const droppableItem = new DroppableItemDirective(this.droppableContainer, null);

            droppableItem.dropZone = 'thrash-basket-dropzone';
            droppableItem.nativeElement = itemPath.node.parentElement;
            if (marginPath) {
                droppableItem.relatedNativeElements = [marginPath.node];
            }
            droppableItem.ngOnInit();
        }
    }

    private addTextContourItem(contourData: TextCuttableContourDrawData) {
        const elementHolder = this.createTextContourElements(contourData);
        this.layerElementStore.setContourElements(contourData.contourId, elementHolder);

        this.makeContourDroppable(
            elementHolder.contourElements.textElement,
            elementHolder.marginElements.pathElement
        );

        const changedEvent = elementHolder.getDrawData(true);
        this.layerElementStore.addContourElementChanges(changedEvent);
    }

    private addGroupContourItem(groupContourData: GroupContourDrawData) {
        const groupContourHolder = this.contourBuilder.createGroupContour(
            groupContourData.contourId
        );
        groupContourData.children.forEach(contourData => {
            if (contourData instanceof TextCuttableContourDrawData) {
                groupContourHolder.setContourHolder(this.createTextContourElements(contourData));
            } else if (contourData instanceof CuttableContourDrawData) {
                groupContourHolder.setContourHolder(this.createContourElements(contourData));
            }
        });

        this.layerElementStore.setContourElements(groupContourData.contourId, groupContourHolder);
        const changedEvents = groupContourHolder.getDrawData(this.scaleFactor, this.layerOffset);
        this.layerElementStore.addContourElementChanges(changedEvents);
        // return this.createGroupChangedEvent(groupContourData.contourId, groupContourRefs);
    }

    changeContourItem(contourData: ContourChangeData) {
        const elementHolder = this.layerElementStore.getContourElements2(contourData.contourId);
        if (!elementHolder) {
            return;
        }
        let contourShapeChanged = false;
        let changedEvent: CanvasElementChangedEvent;

        if (elementHolder instanceof TextContourElementHolder) {
            contourShapeChanged = elementHolder.update(contourData);
        } else if (elementHolder instanceof ContourElementHolder) {
            contourShapeChanged = elementHolder.update(contourData);
        } else if (elementHolder instanceof GroupContourElementHolder) {
            contourShapeChanged = elementHolder.update(contourData);
        }

        // update other layers
        this.selectionLayer.changeContourItem(contourData);
        this.collisionLayer.changeContourItem(contourData);

        // we could move the visibility update to the changeContourItem. However, by leaving it here
        // we have only the check once if the the visibility is not undefined or null
        if (contourData.visibility !== undefined && contourData.visibility !== null) {
            this.overlayLayer.setBadgeVisibility(elementHolder, contourData.visibility);
        }

        if (contourShapeChanged) {
            if (elementHolder instanceof GroupContourElementHolder) {
                changedEvent = elementHolder.getDrawData(this.scaleFactor, this.layerOffset);
                Object.values(elementHolder.elementHolders).forEach(h => {
                    this.overlayLayer.updateBadgePosition(
                        h.contourId,
                        h.contourElements.transformData.globalBBox
                    );
                });
            } else {
                changedEvent = elementHolder.getDrawData(false);
                this.overlayLayer.updateBadgePosition(
                    changedEvent.contourId,
                    changedEvent.contour.globalBBox
                );
            }

            this.layerElementStore.addContourElementChanges(changedEvent);
            this.layerElementStore.notifyOnContourChanges();
        }
    }

    removeContourItem(contourData: ContourItemsRemoved) {
        if (contourData.contourIds) {
            contourData.contourIds.forEach(contourId => {
                const elementHolder = this.layerElementStore.getContourElements2(contourId);
                if (!elementHolder) {
                    return;
                }

                // delete via dragNDrop is not supported for groups yet
                // TODO creation and deletion of DroppableItem should be located in the same
                // file/class
                if (elementHolder instanceof ContourElementHolder) {
                    this.droppableContainer.removeItem(
                        elementHolder.contourElements.groupElement.node
                    );
                }
                this.layerElementStore.removeContourElements(contourId);
                elementHolder.remove();
            });
        }

        this.collisionLayer.removeContourItem(contourData);
        this.fixedCollisionLayer.removeContourItem(contourData);
        this.overlayLayer.removeContourItem(contourData);
        this.selectionLayer.removeContourItem(contourData);
    }

    selectContourItems(selectionData: SelectionChangedData) {
        if (selectionData.itemsToSelect) {
            selectionData.itemsToSelect.forEach(itemSelData => {
                const elementHolder = this.layerElementStore.getContourElements2(
                    itemSelData.contourId
                );
                if (elementHolder) {
                    elementHolder.select(itemSelData);
                }
            });
        }

        if (selectionData.itemsToDeselect) {
            selectionData.itemsToDeselect.forEach(itemSelData => {
                const elementHolder = this.layerElementStore.getContourElements2(
                    itemSelData.contourId
                );
                if (elementHolder) {
                    elementHolder.deselect(itemSelData);
                }
            });
        }

        this.selectionLayer.selectContourItems(selectionData);
        this.collisionLayer.selectContourItems(selectionData);
        this.fixedCollisionLayer.selectContourItems(selectionData);
    }
}

interface ContourElements {
    itemPath: Snap.Element;
    itemImage: Snap.Element;
    group: Snap.Paper;
}
