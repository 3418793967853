import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DroppableStateStore } from './droppable-state-store.service';

@Directive({
    selector: '[appDropZone]'
})
export class DropZoneDirective implements OnInit, OnDestroy {
    @Input()
    dropZoneId: string;
    /* Specifies the element the mouse must enter to start emitting drag events.
    It is used for moveable items (e.g. contour items) because the droppable-container of these
    items cannot be used to trigger drag-n-drop start event*/
    @Input()
    dragPreviewAreaElement: Element;

    constructor(public elementRef: ElementRef, private droppableStateStore: DroppableStateStore) {}

    ngOnInit(): void {
        if (null === this.dropZoneId) {
            throw new Error('Attribute "dropZoneId" is required');
        }
        this.droppableStateStore.addDropZone({ dropZoneId: this.dropZoneId, componentRef: this });
    }

    ngOnDestroy(): void {
        this.droppableStateStore.removeDropZone({
            dropZoneId: this.dropZoneId,
            componentRef: this
        });
    }
}
