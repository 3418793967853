import { ImageContour } from '../foam-editor/canvas/contour/image-contour';
import { catchError, concatMap, map, retryWhen } from 'rxjs/operators';
import { ImageContourModel, ProducerImageContourModel } from './../models/image-contour.model';
import { forkJoin, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ModelMapper } from '../models/model.mapper';
import { ApiErrorCode, instanceOfApiError } from './api-error';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const BASE64_DATA_PREFIX = 'data:image';
const HTTP_TEXT_OPTIONS = {
    headers: new HttpHeaders({ 'Content-Type': 'text/plain' }),
    responseType: 'text',
    withCredentials: true
};
const HTTP_JSON_OPTIONS = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};
const HTTP_IMAGE_OPTIONS = {
    responseType: 'arraybuffer',
    withCredentials: true
};
export class BackendService {
    constructor(http) {
        this.http = http;
        this.TOOL_SUFFIX = '/Tool';
        this.TOOL_OPTIONS_SUFFIX = '/ToolOptions';
        this.SAVE_FOAM_CONFIG_URL = '/Config';
        this.STARTPARAMTER_URL = '/Config/startParameters';
    }
    static deleteImageAttributes(contour) {
        // FIXME: move to server
        if (!(contour.previewImage && contour.previewImage.startsWith(BASE64_DATA_PREFIX))) {
            delete contour.previewImage;
        }
        if (!(contour.image && contour.image.startsWith(BASE64_DATA_PREFIX))) {
            delete contour.image;
        }
        return contour;
    }
    static isAsyncRequestTimeoutError(error) {
        return (error &&
            instanceOfApiError(error) &&
            error.errorCode === ApiErrorCode.ASYNC_REQUEST_TIMEOUT);
    }
    get backendUrl() {
        return this._backendUrl;
    }
    set backendUrl(url) {
        this._backendUrl = url;
    }
    get apiUrl() {
        if (!this.backendUrl) {
            return environment.apiUrl;
        }
        return 'https://' + this.backendUrl;
    }
    saveFoamConfig(bodyData) {
        return this.http
            .put(this.apiUrl + this.SAVE_FOAM_CONFIG_URL, bodyData, HTTP_JSON_OPTIONS)
            .pipe(catchError(err => this.handleError(err)), map(o => {
            // return (o as any).configId;
            const configId = o.configId;
            console.log('bodyData Saved, id: ' + configId);
            return configId;
        }));
    }
    /**
     * Performs HTTP long polling for the given {@code requestStatusUrl},
     *  by responding to the ASYNC_REQUEST_TIMEOUT message from the server.
     *
     * @param requestStatusUrl
     * @param maxRetries - The number of replies when receiving a ASYNC_REQUEST_TIMEOUT message
     */
    longPollingRequest(requestStatusUrl, maxRetries = 70) {
        const saveStatus = this.http.get(this.apiUrl + '/' + requestStatusUrl, HTTP_JSON_OPTIONS);
        let retryCnt = 0;
        return saveStatus.pipe(retryWhen(errors => errors.pipe(concatMap(error => {
            if (error && BackendService.isAsyncRequestTimeoutError(error.error)) {
                retryCnt++;
                if (retryCnt > maxRetries) {
                    return throwError(`Failed to complete long polling after ${maxRetries} attempts`);
                }
                return of(error);
            }
            return throwError(error);
        }))));
    }
    handleError(err) {
        console.log('handing error: ' + err);
        if (err && err.error && BackendService.isAsyncRequestTimeoutError(err.error)) {
            const data = err.error.data;
            if (data && data.requestStatusUrl) {
                return this.longPollingRequest(data.requestStatusUrl);
            }
            else {
                return throwError('Failed to check status of the async request');
            }
        }
        return throwError(err);
    }
    getStartParameters(id) {
        this.initializeServerUrl();
        let options = HTTP_JSON_OPTIONS;
        if (id) {
            options = Object.assign({ params: { id: id } }, options);
        }
        return this.http
            .get(this.apiUrl + this.STARTPARAMTER_URL, options)
            .pipe(map((data) => ModelMapper.jsonToStartParameterModel(data)));
    }
    getAllToolFilter() {
        return this.http.get(this.apiUrl + this.TOOL_OPTIONS_SUFFIX, HTTP_JSON_OPTIONS);
    }
    getAllTool() {
        const TOOL_CLASS_NAME = 'ImageContour';
        const PRODUCER_TYPE_CLASS_NAME = 'ProducerImageContour';
        return this.http.get(this.apiUrl + this.TOOL_SUFFIX, HTTP_JSON_OPTIONS).pipe(map((responseArray) => {
            return responseArray.map(data => {
                if (data[TOOL_CLASS_NAME]) {
                    return new ImageContourModel(data[TOOL_CLASS_NAME]);
                }
                else if (data[PRODUCER_TYPE_CLASS_NAME]) {
                    return new ProducerImageContourModel(data[PRODUCER_TYPE_CLASS_NAME]);
                }
                throw Error('ImageContourModel data structure invalid');
            });
        }));
    }
    getTool(id) {
        return this.http.get(this.apiUrl + this.TOOL_SUFFIX + '/' + id, HTTP_JSON_OPTIONS).pipe(map((data) => {
            // FIXME: use domain model
            return new ImageContour(data);
        }));
    }
    getToolImage(id) {
        return this.http
            .get(this.apiUrl + this.TOOL_SUFFIX + '/' + id + '/image', HTTP_IMAGE_OPTIONS)
            .pipe(map((data) => {
            return data;
        }));
    }
    getToolImageBase64(id) {
        return this.http.get(this.apiUrl + this.TOOL_SUFFIX + '/' + id + '/image64', HTTP_TEXT_OPTIONS);
    }
    saveUserTool(contour) {
        return this.http
            .put(this.apiUrl + this.TOOL_SUFFIX, contour, HTTP_JSON_OPTIONS)
            .pipe(map(data => ModelMapper.jsonToContourModel(data)));
    }
    deleteTool(contour) {
        return this.http.delete(this.apiUrl + this.TOOL_SUFFIX + '/' + contour.id, HTTP_JSON_OPTIONS);
    }
    /**
     * initializes the server Url dependent on the client domain
     *
     * inlay-de => inlay-server.
     * inlay-client.mysortimo[de|fr|..] => inlay-server.mysortimo[de|fr]
     *
     */
    initializeServerUrl() {
        const clientDomain = window.document.domain; // inlay-de.sortimo-test.de or inlay....
        if (clientDomain.startsWith('inlay-de.')) {
            this.backendUrl = clientDomain.replace('inlay-de.', 'inlay-server.');
        }
        else if (clientDomain.startsWith('inlay-client.')) {
            this.backendUrl = clientDomain.replace('inlay-client.', 'inlay-server.');
        }
        //
    }
    editAllContourForCanvas(contours) {
        return forkJoin(contours.map(contour => {
            if (contour.ProducerImageContour) {
                return this.editProducerImageForCanvas(contour);
            }
            else if (contour.ImageContour) {
                return this.editImageForCanvas(contour);
            }
            return of(contour);
        }));
    }
    editProducerImageForCanvas(contour) {
        // delete unneccessary previewImage
        delete contour.ProducerImageContour.previewImage;
        if (!(contour.ProducerImageContour.image &&
            contour.ProducerImageContour.image.startsWith(BASE64_DATA_PREFIX))) {
            // replace link within image with base 64 encoding
            return this.getToolImageBase64(contour.ProducerImageContour.id).pipe(map((imagecontent) => {
                try {
                    contour.ProducerImageContour.image = imagecontent;
                    return contour;
                }
                catch (e) {
                    console.error(e);
                    throw e;
                }
            }));
        }
        return of(contour);
    }
    editImageForCanvas(contour) {
        // delete unnecessary previewImage
        delete contour.ImageContour.previewImage;
        if (!(contour.ImageContour.image &&
            contour.ImageContour.image.startsWith(BASE64_DATA_PREFIX))) {
            // replace link within image with base 64 encoding
            return this.getToolImageBase64(contour.ImageContour.id).pipe(map((imagecontent) => {
                try {
                    contour.ImageContour.image = imagecontent;
                    return contour;
                }
                catch (e) {
                    console.error(e);
                    throw e;
                }
            }));
        }
        return of(contour);
    }
}
BackendService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackendService_Factory() { return new BackendService(i0.ɵɵinject(i1.HttpClient)); }, token: BackendService, providedIn: "root" });
