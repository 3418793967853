import {
    CanvasContour,
    ContourChangeData,
    ContourCollisionVizData,
    ContourHandlesData,
    ContourStateData,
    ContourType,
    FoamInnerMarginDrawData,
    ItemProperties
} from './contour-items-interfaces';
import { EMPTY, Observable, Subject } from 'rxjs';
import { CanvasService } from '../canvas.service';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { PolygonSegment } from '../collision/collision-util';
import * as _ from 'lodash';
import { BBox } from 'snapsvg';
import { nextUniqueId } from '../../../utils/unique-id';
import { Handle } from '../../shared/handle/handle';
import { TextSelectionData } from './text-contour';
import { CanvasElementChangedEvent } from '../canvas.component';
import { Tool } from '../../shared/tool/tool';
import { FoamEditorService } from '../../foam-editor.service';
import { CLASS_NAME_CONTOUR_GROUP, CLASS_NAME_CONTOUR_PATH } from './constants';
import { pathString2Polygon } from './contour-helper';

export interface FoamInnerMarginContourArgs {
    contourId: string;
    svgPathDefinition: string;
    foamSvgPathDefinition: string;
    itemProps?: ItemProperties;
    width: number;
    height: number;
    marginPathString?: string;
    marginSize?: number;
    visible?: boolean;
    globalContourPathBBox?: BBox;
    localContourPathBBox?: BBox;
    globalContourPathMatrix?: Snap.Matrix;
    localContourPathMatrix?: Snap.Matrix;
}

export class FoamInnerMarginContour implements CanvasContour {
    boundingBox: Snap.BBox | undefined;
    contourId: string;

    isCollidable = true;
    isRemovable = false;
    isRotatable = false;
    isSelectable = false;
    isResizable = false;
    isTextElement = false;

    itemElement: Snap.Element | undefined;
    itemTransformation: Snap.TransformationDescriptor | undefined;
    marginBoundingBox: Snap.BBox | undefined;
    marginPath: Snap.Element | undefined;
    marginPathString: string | undefined;
    marginPolygonLines: Array<PolygonSegment> | undefined;
    pathSegments: Array<any> | undefined;
    svgPathDefinition: string;
    foamSvgPathDefinition: string;
    polygonLines: Array<PolygonSegment> | undefined;

    svgClipPathDefinition: string;

    readonly width: number;
    readonly height: number;
    readonly depth: number;

    localContourPathBBox: Rectangle2D;
    globalContourPathBBox: Rectangle2D;
    contourBoundsInParent: Rectangle2D;

    globalContourPathMatrix: Snap.Matrix;
    localContourPathMatrix: Snap.Matrix;

    canvasService: CanvasService;
    visible = true;

    /**
     * @deprecated
     */
    contourType: ContourType;
    readonly itemProps: ItemProperties;

    private contourChanged: Subject<ContourStateData> = new Subject();

    constructor(obj?: FoamInnerMarginContourArgs) {
        const setterFn = (value: any, def: any) => (value == null ? def : value);
        const setterOrErrorFn = (value: any) => {
            if (value == null) {
                throw new ReferenceError();
            }
            return value;
        };
        this.contourId = obj.contourId || nextUniqueId();
        this.svgPathDefinition = setterOrErrorFn(obj.svgPathDefinition);

        this.foamSvgPathDefinition = setterOrErrorFn(obj.foamSvgPathDefinition);

        this.width = setterOrErrorFn(obj.width);
        this.height = setterOrErrorFn(obj.height);

        this.svgClipPathDefinition = this.createClipPath();

        this.itemProps = setterFn(obj.itemProps, {
            itemPathId: CLASS_NAME_CONTOUR_PATH + this.contourId,
            classNamePath: CLASS_NAME_CONTOUR_PATH.slice(0, -1),
            classNameGroup: CLASS_NAME_CONTOUR_GROUP + this.contourId
        });

        this.visible = setterFn(obj.visible, true);
        this.globalContourPathBBox = setterFn(obj.globalContourPathBBox, undefined);
        this.localContourPathBBox = setterFn(obj.localContourPathBBox, undefined);
        this.globalContourPathMatrix = setterFn(obj.globalContourPathMatrix, undefined);
        this.localContourPathMatrix = setterFn(obj.localContourPathMatrix, undefined);
    }

    static create(foamContourItem: any): FoamInnerMarginContour {
        const contourItem = Object.assign(
            {} as FoamInnerMarginContour,
            _.cloneDeep(foamContourItem)
        );
        return new FoamInnerMarginContour(contourItem);
    }

    private createClipPath() {
        return `${this.foamSvgPathDefinition} ${this.svgPathDefinition}`;
    }

    contains(px: number, py: number): boolean {
        return false;
    }

    containsTarget(target: Element): boolean {
        return false;
    }

    createHandles(): Handle[] {
        return [];
    }

    getCanvas(): CanvasService {
        return this.canvasService;
    }

    getCollisionData(): ContourCollisionVizData {
        return new ContourCollisionVizData({
            contourId: this.contourId,
            pathString: this.svgPathDefinition,
            clipPathString: this.svgClipPathDefinition,
            // pathStringToBeClipped: this.clipPathString,
            transformString: this.localContourPathMatrix.toTransformString(),
            transformString2: this.localContourPathMatrix.toString(),
            innerCollisionPolygonLines: this.polygonLines,
            innerCollisionBBox: this.globalContourPathBBox,
            globalContourPathMatrix: this.globalContourPathMatrix,
            localContourPathMatrix: this.localContourPathMatrix,
            isCollidable: this.isCollidable
        });
    }

    getDrawData(): FoamInnerMarginDrawData {
        return new FoamInnerMarginDrawData({
            contourId: this.contourId,
            svgPathDefinition: this.svgPathDefinition,
            isCollidable: this.isCollidable,
            isSelectable: this.isSelectable,
            matrix: this.localContourPathMatrix
        });
    }

    getHandlesDrawData(): ContourHandlesData {
        return undefined;
    }

    getOnChanged(): Observable<ContourStateData> {
        return EMPTY;
    }

    getOnRemoved(): Observable<void> {
        return EMPTY;
    }

    getSelectionData(): TextSelectionData | undefined {
        return undefined;
    }

    getTool(target: Element, editor?: FoamEditorService): Tool | null {
        return undefined;
    }

    getVisibility(): boolean {
        return false;
    }

    onContourElementDrawn(drawnData: CanvasElementChangedEvent): void {
        if (drawnData && drawnData.contour) {
            this.polygonLines = pathString2Polygon(
                this.svgPathDefinition,
                drawnData.contour.globalMatrix
            );

            this.globalContourPathBBox = drawnData.contour.globalBBox;
            this.localContourPathBBox = drawnData.contour.localBBox;
            this.globalContourPathMatrix = drawnData.contour.globalMatrix;
            this.localContourPathMatrix = drawnData.contour.localMatrix;
        }
    }

    remove(): void {}

    rotate(
        angle: number,
        anchorX: number,
        anchorY: number,
        stopEvent?: boolean
    ): ContourChangeData {
        return undefined;
    }

    seVisibility(newValue: boolean): void {}

    setCanvas(canvas: CanvasService): void {
        this.canvasService = canvas;
    }

    transform(matrix: Snap.Matrix, stopEvent?: boolean): ContourChangeData {
        return undefined;
    }

    translate(dx: number, dy: number, stopEvent?: boolean): ContourChangeData {
        return undefined;
    }
}
