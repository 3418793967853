import { NgModule } from '@angular/core';
import { DialogModule } from '../dialog/dialog.module';
import { SharedModule } from '../shared/shared.module';
import { EditActionsToolbarComponent } from './edit-actions-toolbar/edit-actions-toolbar.component';
import { PriceDisplayComponent } from './price-display/price-display.component';
import { DisplayOptionsPanelComponent } from './display-options-panel/display-options-panel.component';
import { ContourPropertiesComponent } from './contour-properties/contour-properties.component';
import { ProjectValidityInfoComponent } from './project-validity-info/project-validity-info.component';
import { CompletionDialogComponent } from './dialogs/completion-dialog/completion-dialog.component';
import { SaveDialogComponent } from './dialogs/save-dialog/save-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { ProjectPropertyComponent } from './project-property/project-property.component';
import { FoamEditorComponent } from './foam-editor.component';
import { ImageContourEditDialogComponent } from './dialogs/image-contour-edit-dialog/image-contour-edit-dialog.component';
import { ImageContourDeleteDialogComponent } from './dialogs/image-contour-delete-dialog/image-contour-delete-dialog.component';
import { PartnerContourInfoDialogComponent } from './dialogs/partner-contour-info-dialog/partner-contour-info-dialog.component';
import { ContourDepthPromptDialogComponent } from './dialogs/contour-depth-prompt-dialog/contour-depth-prompt-dialog.component';
import { PartialCuttingWarningDialogComponent } from './dialogs/partial-cutting-warning-dialog/partial-cutting-warning-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadContourDialogModule } from './dialogs/upload-dialog/upload-contour-dialog.module';
import { TranslationSharedModule } from '../shared/translation-shared.module';
import { ScrollViewportModule } from './scroll-viewport/scroll-viewport.module';
import { RibbonModule } from './ribbon/ribbon.module';
import { DroppableModule } from '../droppable/droppable.module';
import { CanvasModule } from './canvas/canvas.module';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        TranslationSharedModule,
        // App modules
        SharedModule,
        DialogModule,
        RibbonModule,
        UploadContourDialogModule,
        ScrollViewportModule,
        DroppableModule,
        CanvasModule
    ],
    declarations: [
        EditActionsToolbarComponent,
        PriceDisplayComponent,
        DisplayOptionsPanelComponent,
        ContourPropertiesComponent,
        ProjectValidityInfoComponent,
        CompletionDialogComponent,
        SaveDialogComponent,
        ErrorDialogComponent,
        ProjectPropertyComponent,
        FoamEditorComponent,
        ImageContourEditDialogComponent,
        ImageContourDeleteDialogComponent,
        PartnerContourInfoDialogComponent,
        ContourDepthPromptDialogComponent,
        PartialCuttingWarningDialogComponent
    ],
    exports: [FoamEditorComponent],
    // dynamically loaded components
    entryComponents: [
        CompletionDialogComponent,
        SaveDialogComponent,
        ErrorDialogComponent,
        ImageContourEditDialogComponent,
        ImageContourDeleteDialogComponent,
        PartnerContourInfoDialogComponent,
        ContourDepthPromptDialogComponent,
        PartialCuttingWarningDialogComponent
    ]
})
export class FoamEditorModule {}
