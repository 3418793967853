<div class="main-toolbar__title">{{ panelIndex }}. {{ panelTitle }}</div>
<div class="overview-panel__content-container">
    <p class="action-info-text">{{ 'OVERVIEW_RIBBON.INFO_TEXT' | translate }}</p>

    <div class="display-functions">
        <div class="display-functions__row">
            <div class="display-functions__toggle-switch">
                <app-toggle-switch
                    #toggleMargin
                    [inputId]="'collision-margin__switch'"
                    [labelWidth]="'160px'"
                    [labelHeight]="'100%'"
                    [checked]="isContourSafeMarginShown"
                    (change)="showSafetyMargin(toggleMargin.checked)"
                >
                    {{ 'ON_OFF_SLIDER.COLLISION_MARGIN' | translate }}
                </app-toggle-switch>
            </div>
        </div>
        <div class="display-functions__row">
            <div class="display-functions__toggle-switch">
                <app-toggle-switch
                    #toggleImage
                    [inputId]="'tool-images__switch'"
                    [labelWidth]="'160px'"
                    [labelHeight]="'100%'"
                    [checked]="isContourImageShown"
                    (change)="showContourImage(toggleImage.checked)"
                >
                    {{ 'ON_OFF_SLIDER.SHOW_TOOLS' | translate }}
                </app-toggle-switch>
            </div>
        </div>
    </div>

    <p class="action-info-text">
        <b>{{ 'EDITOR.NOTE' | translate }}</b
        >{{ 'OVERVIEW_RIBBON.NOTE_TEXT' | translate }}
    </p>
</div>
