/**
 * Utilities for human friendly conversions
 **/

const KB = 1024;
const MB = 1048576; // 1024^2
const GB = 1073741824; // 1024^3;

/**
 * Convert bytes to next readable units (GB, MB , KB)
 * @param bytes
 */
export function humanReadableByte(bytes): string {
    const gbRounded = Math.round((bytes / GB) * 100000) / 100000;
    if (gbRounded >= 1) {
        return gbRounded.toFixed(2) + ' GB';
    }

    const mbRounded = Math.round((bytes / MB) * 100000) / 100000;
    if (mbRounded >= 1) {
        return mbRounded.toFixed(2) + ' MB';
    }

    const kbRounder = Math.round((bytes / KB) * 100000) / 100000;
    return kbRounder.toFixed(2) + ' KB';
}
