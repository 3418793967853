import { Injectable } from '@angular/core';

import { InlSVGTextInputElement, InlTextChangedEvent } from './inl-svg-text-input-element';
import { transformMatrix } from '../shared/geom/matrix';
import { Point2D } from '../shared/geom/point2D';
import { Rectangle2D } from '../shared/geom/rectangle2D';
import { transformBoundingBox } from './contour/contour-helper';
import { createGroup, createPath, sendToBack, sendToFront } from '../../utils/dom-utils';

import { CanvasElementChangedEvent } from './canvas.component';
import { CLASS_NAME_CONTOUR_SELECTED } from './contour/constants';
import {
    ContourChangeData,
    ContourSelectionData,
    CuttableContourDrawData,
    FoamDrawData,
    FoamInnerMarginDrawData,
    TextContourChangeData,
    TextCuttableContourDrawData
} from './contour/contour-items-interfaces';
import { TextContour, TextSelectionData } from './contour/text-contour';

declare var Snap: any;

const isPresent = (value: any): boolean => {
    return value !== undefined && value !== null;
};

/**
 * The ContourElementBuilder simplifies the creation of SVG elements to
 * represent contours.
 *
 */
@Injectable()
export class ContourElementBuilder {
    private contourContainerElement: Snap.Paper;
    private marginContainerElement: Snap.Paper;

    createContour(contourId: string): ContourElementHolder {
        return new ContourElementHolder(contourId);
    }

    createTextContour(contourId: string): TextContourElementHolder {
        return new TextContourElementHolder(contourId);
    }

    createGroupContour(contourId: string): GroupContourElementHolder {
        return new GroupContourElementHolder(contourId);
    }

    // TODO later
    setElementContainer(contourContainerElement: Snap.Paper, marginContainerElement: Snap.Paper) {
        this.contourContainerElement = contourContainerElement;
        this.marginContainerElement = marginContainerElement;
    }
}

export class FoamContourElementHolder {
    readonly contourId: string;
    pathElement: Snap.Element;

    /** Cache transformed data **/
    transformData: ContourTransformData;
    innerMarginTransformData: ContourTransformData;

    constructor(contourId: string) {
        this.contourId = contourId;
    }

    setPath(
        pathElement: Snap.Element,
        contourData: FoamDrawData | FoamInnerMarginDrawData
    ): FoamContourElementHolder {
        this.pathElement = pathElement;
        this.pathElement.node.setAttribute('d', contourData.svgPathDefinition);
        return this;
    }

    getDrawData(isNewContour?: boolean): CanvasElementChangedEvent | null {
        if (!this.pathElement) {
            return null;
        }

        const localBbox = Rectangle2D.fromObject((this.pathElement.node as any).getBBox());
        const { globalMatrix, localMatrix } = transformMatrix(
            this.pathElement.node as any,
            localBbox
        );

        this.transformData = {
            globalMatrix: globalMatrix,
            localMatrix: localMatrix,
            localBBox: localBbox,
            globalBBox: Rectangle2D.fromPointsObject(transformBoundingBox(localBbox, globalMatrix))
        };

        return {
            contourId: this.contourId,
            isNewContour: isNewContour || false,
            contour: this.transformData
        };
    }
}

export class ContourElementHolder {
    readonly contourId: string;
    contourElements: ContourElements;
    marginElements: MarginGroupNode;

    constructor(contourId: string) {
        this.contourId = contourId;
    }

    setContourElements(
        container: Snap.Paper,
        contourData: CuttableContourDrawData,
        attrs?: ContourOptions
    ): ContourElementHolder {
        const attrsGroup = attrs ? attrs.group : undefined;
        this.contourElements = new ContourElements(
            createGroup(container, contourData.itemProps.classNameGroup, attrsGroup),
            contourData
        );

        const attrsContour = attrs ? attrs.contour : undefined;
        this.contourElements.setContourPath(attrsContour);
        return this;
    }

    setMarginElements(
        container: Snap.Paper,
        contourData: CuttableContourDrawData,
        attrs?: MarginOptions
    ): ContourElementHolder {
        const attrsGroup = attrs ? attrs.group : undefined;
        this.marginElements = new MarginGroupNode(
            createGroup(container, contourData.itemProps.classNameMarginGroup, attrsGroup),
            contourData
        );

        this.marginElements.setMarginPath(attrs);
        return this;
    }

    update(newContourData: ContourChangeData): boolean {
        let contourShapeChanged = false;
        let marginShapeChanged = false;
        if (this.contourElements) {
            contourShapeChanged = this.contourElements.update(newContourData);
        }

        if (this.marginElements) {
            marginShapeChanged = this.marginElements.update(newContourData);
        }
        return contourShapeChanged || marginShapeChanged;
    }

    select(selectionData: ContourSelectionData) {
        // sent contour to front if is not explicitly send back (which is the case recessed-grips)
        const sendToFn = selectionData.sendTo === 'back' ? sendToBack : sendToFront;

        if (this.contourElements) {
            this.contourElements.pathElement.addClass(CLASS_NAME_CONTOUR_SELECTED);

            sendToFn(
                this.contourElements.groupElement.parent().node,
                this.contourElements.groupElement.node
            );
        }

        if (this.marginElements) {
            this.marginElements.pathElement.addClass(CLASS_NAME_CONTOUR_SELECTED);

            sendToFn(
                this.marginElements.groupElement.parent().node,
                this.marginElements.groupElement.node
            );
        }
    }

    deselect(selectionData: ContourSelectionData) {
        let sendToFn: (parent: HTMLElement, node: Node) => void;
        if (selectionData.sendTo === 'front') {
            sendToFn = sendToFront;
        } else if (selectionData.sendTo === 'back') {
            sendToFn = sendToBack;
        }

        if (this.contourElements) {
            this.contourElements.pathElement.removeClass(CLASS_NAME_CONTOUR_SELECTED);

            if (sendToFn) {
                sendToFn(
                    this.contourElements.groupElement.parent().node,
                    this.contourElements.groupElement.node
                );
            }
        }

        if (this.marginElements) {
            this.marginElements.pathElement.removeClass(CLASS_NAME_CONTOUR_SELECTED);

            if (sendToFn) {
                sendToFn(
                    this.marginElements.groupElement.parent().node,
                    this.marginElements.groupElement.node
                );
            }
        }
    }

    remove() {
        this.contourElements.removeAll();
        this.marginElements.removeAll();
    }

    getDrawData(isNewContour?: boolean): CanvasElementChangedEvent | null {
        let contourTransformData: ContourTransformData;
        let marginTransformData: ContourTransformData;
        if (this.contourElements) {
            this.contourElements.updateTransformationData();
            contourTransformData = this.contourElements.transformData;
        }

        if (this.marginElements) {
            this.marginElements.updateTransformationData();
            marginTransformData = this.marginElements.transformData;
        }

        if (contourTransformData || marginTransformData) {
            return {
                contourId: this.contourId,
                contour: contourTransformData,
                margin: marginTransformData,
                isNewContour: isNewContour || false
            };
        }

        return null;
    }
}

export class TextContourElementHolder {
    readonly contourId: string;
    contourElements: TextContourElements;
    marginElements: MarginGroupNode;
    textInput: InlSVGTextInputElement;

    constructor(contourId: string) {
        this.contourId = contourId;
    }

    setTextContourElements(
        container: Snap.Paper,
        contourData: TextCuttableContourDrawData,
        attrs?: TextContourOptions
    ) {
        const groupOpts = attrs ? attrs.group : undefined;
        this.contourElements = new TextContourElements(
            createGroup(container, contourData.itemProps.classNameGroup, groupOpts),
            contourData
        );

        this.contourElements.setTextNode(attrs);

        this.textInput = new InlSVGTextInputElement(
            contourData.contourId,
            this.contourElements.textElement,
            attrs.htmlTextInputElement,
            attrs.svgRoot
        );
        this.textInput.textChanges.subscribe(textChanged => {
            attrs.onTextChanged(textChanged);
        });

        // update path-string using methods of the InlSVGTextInputElement
        this.contourElements.svgPathDefinition = TextContour.createPathString(
            Rectangle2D.fromObject(this.textInput.getTextBBox(true))
        );

        return this;
    }

    setMarginElements(
        container: Snap.Paper,
        contourData: CuttableContourDrawData,
        attrs?: MarginOptions
    ) {
        const attrsGroup = attrs ? attrs.group : undefined;
        this.marginElements = new MarginGroupNode(
            createGroup(container, contourData.itemProps.classNameMarginGroup, attrsGroup),
            contourData
        );

        this.marginElements.setMarginPath(attrs);
        return this;
    }

    update(newContourData: TextContourChangeData): boolean {
        let contourShapeChanged = false;

        // Update the marginPathString before updating the MarginNode (see marginElements.update())
        if (newContourData.textContent) {
            this.textInput.setText(newContourData.textContent);
            const globalTextBounds = Rectangle2D.fromObject(this.textInput.getTextBBox(true));
            this.contourElements.svgPathDefinition = TextContour.createPathString(globalTextBounds);
            this.marginElements.marginPathString = TextContour.createTextMarginPathString(
                globalTextBounds,
                this.marginElements.marginSize
            );

            this.updateContourAndMarginPaths({
                contourId: newContourData.contourId,
                marginPathString: this.marginElements.marginPathString
            });
            contourShapeChanged = true;
        }

        contourShapeChanged =
            contourShapeChanged || this.updateContourAndMarginPaths(newContourData);

        if (newContourData.selectionData) {
            this.handleTextSelectionChanged(newContourData.selectionData);
        }

        return contourShapeChanged;
    }

    private updateContourAndMarginPaths<T extends TextContourChangeData>(
        newContourData: T
    ): boolean {
        let contourShapeChanged = false;
        if (this.contourElements) {
            this.contourElements.update(newContourData);
            contourShapeChanged = true;
        }

        if (this.marginElements) {
            this.marginElements.update(newContourData);
            contourShapeChanged = true;
        }
        return contourShapeChanged;
    }

    private handleTextSelectionChanged(textSelectionData: TextSelectionData) {
        this.textInput.editMode = !!textSelectionData.isInEditMode;

        if (textSelectionData.textSelection) {
            const { type, position } = textSelectionData.textSelection;
            if (type === 'ALL') {
                this.textInput.selectAll();
            } else if (type === 'SELECT') {
                this.textInput.select(position.x, position.y);
            } else if (type === 'EXTEND') {
                this.textInput.extendSelection(position.x, position.y);
            }
        }
    }

    select(selectionData: ContourSelectionData) {
        // sent contour to front if is not explicitly send back (which is the case recessed-grips)
        const sendToFn = selectionData.sendTo === 'back' ? sendToBack : sendToFront;
        if (this.contourElements) {
            sendToFn(
                this.contourElements.groupElement.parent().node,
                this.contourElements.groupElement.node
            );
        }

        if (this.marginElements) {
            this.marginElements.pathElement.addClass(CLASS_NAME_CONTOUR_SELECTED);

            sendToFn(
                this.marginElements.groupElement.parent().node,
                this.marginElements.groupElement.node
            );
        }

        if (selectionData.selectionData) {
            this.handleTextSelectionChanged(selectionData.selectionData);
        }
    }

    deselect(selectionData: ContourSelectionData) {
        let sendToFn: (parent: HTMLElement, node: Node) => void;
        if (selectionData.sendTo === 'front') {
            sendToFn = sendToFront;
        } else if (selectionData.sendTo === 'back') {
            sendToFn = sendToBack;
        }

        if (this.contourElements) {
            if (sendToFn) {
                sendToFn(
                    this.contourElements.groupElement.parent().node,
                    this.contourElements.groupElement.node
                );
            }
        }

        if (this.marginElements) {
            this.marginElements.pathElement.removeClass(CLASS_NAME_CONTOUR_SELECTED);

            if (sendToFn) {
                sendToFn(
                    this.marginElements.groupElement.parent().node,
                    this.marginElements.groupElement.node
                );
            }
        }

        if (selectionData.selectionData) {
            this.handleTextSelectionChanged(selectionData.selectionData);
        }
    }

    // FIXME duplicate
    getDrawData(isNewContour?: boolean): CanvasElementChangedEvent | null {
        let contourTransformData: ContourTransformData;
        let marginTransformData: ContourTransformData;
        if (this.contourElements) {
            this.contourElements.updateTransformationData();
            contourTransformData = this.contourElements.transformData;
        }

        if (this.marginElements) {
            this.marginElements.updateTransformationData();
            marginTransformData = this.marginElements.transformData;
        }

        if (contourTransformData || marginTransformData) {
            return {
                contourId: this.contourId,
                contour: {
                    svgPathDefinition: this.contourElements.svgPathDefinition,
                    ...contourTransformData
                },
                margin: {
                    marginPathString: this.marginElements.marginPathString,
                    ...marginTransformData
                },
                textContent: this.textInput.textInput.value,
                isNewContour: isNewContour || false
            };
        }

        return null;
    }

    remove() {
        this.contourElements.removeAll();
        this.marginElements.removeAll();
        this.textInput.destroy();
    }
}

export class ContourElements {
    readonly groupElement: Snap.Paper;
    pathElement: Snap.Element;
    imageElement: Snap.Element;

    /** Cache transformed data **/
    transformData: ContourTransformData;

    /**
     * Creates a new ContourGroupNode instance
     *
     * @param group
     * @param contourData
     */
    constructor(group: Snap.Paper, private contourData: CuttableContourDrawData) {
        this.groupElement = group;
        const matrix = contourData.matrix;
        if (matrix) {
            group.node.setAttribute('transform', matrix.toString());
        }
    }

    setContourPath(attrs?: { [name: string]: any }) {
        this.pathElement = createPath(this.contourData.svgPathDefinition, this.groupElement, {
            id: this.contourData.itemProps.itemPathId,
            fill: this.contourData.itemProps.contourFill
        });
        this.pathElement.attr(attrs);

        const imgSrc = this.contourData.image;
        if (imgSrc) {
            const { width, height } = this.pathElement.getBBox();
            this.imageElement = this.groupElement.image(imgSrc, 0, 0, width, height);
            this.imageElement.node.setAttribute('pointer-events', 'none');
            this.imageElement.node.id = this.contourData.itemProps.imagePathId;
        }

        return this;
    }

    update(contourData: ContourChangeData) {
        if (!this.pathElement || !this.groupElement) {
            return null;
        }

        let contourShapeChanged = false;
        if (contourData.svgPathDefinition) {
            this.pathElement.node.setAttribute('d', contourData.svgPathDefinition);
            contourShapeChanged = true;
        }

        if (contourData.matrix) {
            this.groupElement.node.setAttribute('transform', contourData.matrix.toString());
            contourShapeChanged = true;
        }

        if (contourData.visibility !== undefined && contourData.visibility !== null) {
            this.groupElement.node.style.visibility = contourData.visibility ? 'visible' : 'hidden';
            contourShapeChanged = true;
        }

        return contourShapeChanged;
    }

    updateTransformationData() {
        if (!this.groupElement) {
            return;
        }

        const groupNode = this.groupElement.node;
        const localBbox = Rectangle2D.fromObject((groupNode as any).getBBox());
        const { globalMatrix, localMatrix } = transformMatrix(groupNode as any, localBbox);

        this.transformData = {
            globalMatrix: globalMatrix,
            localMatrix: localMatrix,
            localBBox: localBbox,
            globalBBox: Rectangle2D.fromPointsObject(transformBoundingBox(localBbox, globalMatrix))
        };
    }

    removeAll() {
        if (this.groupElement) {
            this.groupElement.remove();
        } else {
            this.pathElement.remove();
            this.imageElement.remove();
        }
    }
}

export class TextContourElements {
    readonly groupElement: Snap.Paper;
    textElement: Snap.Element;

    svgPathDefinition: string;

    /** Cache transformed data **/
    transformData: ContourTransformData;

    readonly holderRef: TextContourElementHolder;

    constructor(group: Snap.Paper, private contourData: TextCuttableContourDrawData) {
        this.groupElement = group;
    }

    setTextNode(textContourOpts: TextContourOptions) {
        this.textElement = this.groupElement.text(0, 0, [this.contourData.textContent]);

        if (textContourOpts.contour) {
            this.textElement.attr(textContourOpts.contour);
        }
        this.textElement.node.classList.add(this.contourData.itemProps.classNamePath);

        this.textElement.node.setAttribute('fill', '#2880E6');
        this.textElement.node.setAttribute('font-size', '9');
        this.textElement.node.setAttributeNS(
            'http://www.w3.org/XML/1998/namespace',
            'xml:space',
            'preserve'
        );
        this.textElement.node.setAttribute('alignment-baseline', 'middle');

        if (this.contourData.matrix) {
            this.textElement.node.setAttribute('transform', this.contourData.matrix.toString());
        }

        // this.svgPathDefinition =
        // this.contourData.createPathString(this.getTextBounds());

        return this;
    }

    update(contourData: TextContourChangeData): boolean {
        if (!this.textElement || !this.groupElement) {
            return null;
        }

        let contourShapeChanged = false;
        if (contourData.matrix) {
            this.textElement.node.setAttribute('transform', contourData.matrix.toString());
            contourShapeChanged = true;
        }

        if (contourData.visibility !== undefined) {
            this.groupElement.node.style.visibility = contourData.visibility ? 'visible' : 'hidden';
            contourShapeChanged = true;
        }

        return contourShapeChanged;
    }

    updateTransformationData() {
        if (!this.textElement) {
            return;
        }
        const localBbox = Rectangle2D.fromObject((this.textElement.node as any).getBBox());
        const { globalMatrix, localMatrix } = transformMatrix(
            this.textElement.node as any,
            localBbox
        );

        this.transformData = {
            globalMatrix: globalMatrix,
            localMatrix: localMatrix,
            localBBox: localBbox,
            globalBBox: Rectangle2D.fromPointsObject(transformBoundingBox(localBbox, globalMatrix))
        };
    }

    removeAll() {
        if (this.groupElement) {
            this.groupElement.remove();
        } else {
            this.textElement.remove();
        }
    }
}

export class MarginGroupNode {
    readonly groupElement: Snap.Paper;
    pathElement: Snap.Element;

    /**
     * Cache the computed path string of the TextContour needed for the
     * collision detection *
     */
    marginPathString: string;

    /** Cache transformed data **/
    transformData: ContourTransformData;

    marginSize: number;

    /**
     * Creates a new MarginGroupNode instance
     *
     * @param group
     * @param contourData
     */
    constructor(group: Snap.Paper, private contourData: CuttableContourDrawData) {
        this.groupElement = group;

        if (this.contourData.marginSize) {
            this.marginSize = this.contourData.marginSize;
        }
    }

    setMarginPath(marginOpts?: MarginOptions) {
        let attrs: { [name: string]: any };

        this.marginPathString = this.contourData.marginPathString;
        if (marginOpts) {
            this.marginPathString = marginOpts.marginPathString || this.marginPathString;
            attrs = marginOpts.contour ? marginOpts.contour.htmlAttributes : undefined;
        }

        this.pathElement = createPath(
            this.marginPathString,
            this.groupElement,
            {
                id: this.contourData.itemProps.marginPathId,
                fill: this.contourData.itemProps.marginFill,
                opacity: this.contourData.itemProps.marginOpacity
            },
            this.contourData.itemProps.classNameMarginPath
        );

        this.pathElement.attr(attrs);

        const matrix = this.contourData.matrix;
        if (matrix) {
            this.pathElement.node.setAttribute('transform', matrix.toString());
        }

        return this;
    }

    /**
     * Updates the margin path based on the given contourData.
     *
     * @param contourData
     */
    update(contourData: ContourChangeData): boolean {
        let marginShapeChanged = false;
        if (!this.pathElement || !this.groupElement) {
            return null;
        }

        if (contourData.marginSize) {
            this.marginSize = contourData.marginSize;
        }

        if (contourData.marginPathString) {
            this.pathElement.node.setAttribute('d', contourData.marginPathString);
            marginShapeChanged = true;
        }
        if (contourData.matrix && contourData.matrix.toString() !== 'undefined') {
            this.pathElement.node.setAttribute('transform', contourData.matrix.toString());
            marginShapeChanged = true;
        }
        let visibility: boolean;
        if (isPresent(contourData.visibility)) {
            visibility = contourData.visibility;
        } else if (isPresent(contourData.marginVisibility)) {
            visibility = contourData.marginVisibility;
        }

        if (visibility !== undefined) {
            this.groupElement.node.style.visibility = visibility ? 'visible' : 'hidden';
        }

        return marginShapeChanged;
    }

    updateTransformationData() {
        if (!this.pathElement) {
            return;
        }
        const marginLocalBbox = Rectangle2D.fromObject((this.pathElement.node as any).getBBox());
        const marginTx = transformMatrix(this.pathElement.node as any, marginLocalBbox);

        this.transformData = {
            globalMatrix: marginTx.globalMatrix,
            localMatrix: marginTx.localMatrix,
            localBBox: marginLocalBbox,
            globalBBox: Rectangle2D.fromPointsObject(
                transformBoundingBox(marginLocalBbox, marginTx.globalMatrix)
            )
        };
    }

    removeAll() {
        if (this.groupElement) {
            this.groupElement.remove();
        } else {
            this.pathElement.remove();
        }
    }
}

export class GroupContourElementHolder {
    readonly elementHolders: {
        [contourId: string]: ContourElementHolder | TextContourElementHolder;
    };

    readonly contourId: string;
    private firstUpdate: boolean = true;

    /** Cache group transformed data **/
    transformData: ContourTransformData;

    constructor(contourId: string) {
        this.contourId = contourId;
        this.elementHolders = {};
        this.transformData = {
            globalMatrix: undefined,
            localMatrix: undefined,
            globalBBox: undefined,
            localBBox: undefined
        };
    }

    setContourHolder(
        contourHolder: ContourElementHolder | TextContourElementHolder
    ): GroupContourElementHolder {
        this.elementHolders[contourHolder.contourId] = contourHolder;
        return this;
    }

    update(contourData: ContourChangeData): boolean {
        let groupShapeChanged = false;

        if (contourData.children) {
            contourData.children.forEach(childContourData => {
                const childHolder = this.elementHolders[childContourData.contourId];
                if (childHolder) {
                    let contourShapeChanged = false;
                    if (childHolder instanceof ContourElementHolder) {
                        contourShapeChanged = childHolder.update(childContourData);
                    } else if (childHolder instanceof TextContourElementHolder) {
                        contourShapeChanged = childHolder.update(
                            childContourData as TextContourElementHolder
                        );
                    }

                    if (!groupShapeChanged && contourShapeChanged) {
                        groupShapeChanged = true;
                    }
                }
            });
        }

        return groupShapeChanged;
    }

    select(selectionData: ContourSelectionData) {
        Object.values(this.elementHolders).forEach(holder => holder.select(selectionData));
    }

    deselect(selectionData: ContourSelectionData) {
        Object.values(this.elementHolders).forEach(holder => holder.deselect(selectionData));
    }

    getDrawData(scaleFactor: number, itemsLayerOffset: Point2D): CanvasElementChangedEvent | null {
        const childElementHolders = Object.values(this.elementHolders);
        if (childElementHolders.length < 1) {
            return null;
        }

        const topLeft = new Point2D(Number.MAX_VALUE, Number.MAX_VALUE);
        const bottomRight = new Point2D(Number.MIN_VALUE, Number.MIN_VALUE);
        const minTransPt = new Point2D(Number.MAX_VALUE, Number.MAX_VALUE);

        const childrenTransformData: {
            [contourId: string]: CanvasElementChangedEvent;
        } = {};

        childElementHolders.forEach(childHolder => {
            // first update the transformation data (TODO should be cached)
            childrenTransformData[childHolder.contourId] = childHolder.getDrawData();

            const contourTransformData = childHolder.contourElements.transformData;
            bottomRight.x = Math.max(bottomRight.x, contourTransformData.globalBBox.x2);
            bottomRight.y = Math.max(bottomRight.y, contourTransformData.globalBBox.y2);

            topLeft.x = Math.min(topLeft.x, contourTransformData.globalBBox.x);
            topLeft.y = Math.min(topLeft.y, contourTransformData.globalBBox.y);

            // changedEvent.globalBBox.x removes the margin size from
            minTransPt.x = Math.min(
                minTransPt.x,
                (contourTransformData.globalMatrix as any).e +
                    contourTransformData.localBBox.x * scaleFactor
            );
            minTransPt.y = Math.min(
                minTransPt.y,
                (contourTransformData.globalMatrix as any).f +
                    contourTransformData.localBBox.y * scaleFactor
            );
        });

        const globalBounds = new Rectangle2D(
            topLeft.x,
            topLeft.y,
            bottomRight.x - topLeft.x,
            bottomRight.y - topLeft.y
        );

        if (this.firstUpdate) {
            const localBounds = new Rectangle2D(
                0,
                0,
                globalBounds.width / scaleFactor,
                globalBounds.height / scaleFactor
            );

            const localTx = (topLeft.x - itemsLayerOffset.x) / scaleFactor;
            const localTy = (topLeft.y - itemsLayerOffset.y) / scaleFactor;
            this.transformData.localMatrix = Snap.matrix(1, 0, 0, 1, localTx, localTy);
            this.transformData.localBBox = localBounds;
            this.firstUpdate = false;
        }

        const groupGlobalMatrix = Snap.matrix(
            scaleFactor,
            0,
            0,
            scaleFactor,
            minTransPt.x,
            minTransPt.y
        );

        this.transformData.globalMatrix = groupGlobalMatrix;
        this.transformData.globalBBox = globalBounds;

        return {
            contourId: this.contourId,
            contour: this.transformData,
            children: childrenTransformData,
            isNewContour: false
        };
    }

    remove() {
        Object.values(this.elementHolders).forEach(holder => holder.remove());
    }
}

export interface ContourOptions {
    group?: { htmlAttributes: { [name: string]: any } };
    contour?: { htmlAttributes: { [name: string]: any } };
}

export interface MarginOptions {
    marginPathString?: string;
    group?: { htmlAttributes: { [name: string]: any } };
    contour?: { htmlAttributes: { [name: string]: any } };
}

export interface TextContourOptions {
    svgRoot: SVGSVGElement;
    htmlTextInputElement: HTMLTextAreaElement;
    onTextChanged: (change: InlTextChangedEvent) => void;
    group?: { htmlAttributes: { [name: string]: any } };
    contour?: { htmlAttributes: { [name: string]: any } };
}

export interface ContourTransformData {
    globalMatrix: Snap.Matrix;
    localMatrix: Snap.Matrix;
    globalBBox: Rectangle2D;
    localBBox: Rectangle2D;
}

export interface ReadOnlyContourTransformData extends ContourTransformData {
    readonly globalMatrix: Snap.Matrix;
    readonly localMatrix: Snap.Matrix;
    readonly globalBBox: Rectangle2D;
    readonly localBBox: Rectangle2D;
}
