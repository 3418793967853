<div appAccordion>
    <app-accordion-toolbar [panelTitle]="panelTitle" [panelIndex]="panelIndex">
    </app-accordion-toolbar>

    <app-expansion-panel expanded="true">
        <app-expansion-panel-header>
            <div appExpansionPanelTitle>
                {{ 'TOOL_RIBBON.SUB_MENU_MY_TOOLS' | translate }}
            </div>
        </app-expansion-panel-header>

        <div class="main-toolbar__content">
            <app-button
                app-icon-button
                textPosition="after"
                iconAlign="horizontal"
                buttonSize="normal"
                class="stretch-button btn-capture-contour"
                (click)="openUploadDialog()"
            >
                <app-icon shape="icon-camera_enhance" iconSize="normal"></app-icon>
                {{ 'TOOL_RIBBON.CAPTURE_TOOL' | translate }}
            </app-button>
            <div class="item-search user-contour-search">
                <input
                    #userSearchField
                    type="search"
                    id="search-input-user-database"
                    class="item-search-field"
                    name="search-contour"
                    placeholder="{{ 'TOOL_RIBBON.SEARCH_TOOL' | translate }}"
                    value=""
                    (keyup)="onSearchUserItem($event)"
                />
                <span class="item-search-icon">
                    <app-icon shape="search" iconSize="small"></app-icon>
                </span>
            </div>
            <p class="info-text">{{ 'TOOL_RIBBON.TOOL_INFO_TEXT' | translate }}</p>
            <app-image-contours-browser #userContourBrowser [data]="userContourItems | async">
            </app-image-contours-browser>
        </div>
    </app-expansion-panel>

    <app-expansion-panel>
        <app-expansion-panel-header>
            <div appExpansionPanelTitle>{{ 'TOOL_RIBBON.SUB_MENU_TOOL_DATABASE' | translate }}</div>
        </app-expansion-panel-header>

        <div class="contour-filters-container">
            <label>{{ 'TOOL_RIBBON.DATABASE_INFO_TEXT' | translate }}</label>
            <select class="contour-browser-select" (change)="filterByProducer($event.target.value)">
                <option
                    label="{{ 'TOOL_RIBBON.DATABASE_DEFAULT_MANUFACTURER' | translate }}"
                ></option>
                <option *ngFor="let producer of filterProducer" [value]="producer.value">{{
                    producer.title
                }}</option>
            </select>
            {{ selectedProducer }}
            <select class="contour-browser-select" (change)="filterByToolType($event.target.value)">
                <option label="{{ 'TOOL_RIBBON.DATABASE_DEFAULT_TOOL_TYPE' | translate }}"></option>
                <option *ngFor="let toolType of filterToolType" [value]="toolType.value">{{
                    toolType.title
                }}</option>
            </select>
        </div>

        <div class="item-search-container">
            <label>{{ 'TOOL_RIBBON.DATABASE_INFO_SEARCH' | translate }}</label>

            <div class="item-search  partner-contour-search">
                <input
                    #partnerSearchField
                    type="search"
                    id="search-input-database"
                    class="item-search-field"
                    name="search-contour"
                    placeholder="{{ 'TOOL_RIBBON.SEARCH_TOOL' | translate }}"
                    value=""
                    (keyup)="onSearchPartnerItem($event)"
                />
                <span class="item-search-icon">
                    <app-icon shape="search" iconSize="small"></app-icon>
                </span>
            </div>
        </div>

        <app-image-contours-browser
            [data]="partnerContourItems | async"
            viewportSize="252"
            #partnerContourBrowser
        ></app-image-contours-browser>
    </app-expansion-panel>
</div>
