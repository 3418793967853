import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-error-messages',
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.scss']
})
export class ErrorMessagesComponent implements OnInit {
    @Input()
    group: FormGroup;

    @Input()
    controlName: string;

    private control: AbstractControl;

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.control = this.group.controls[this.controlName];
    }

    get errorMessage(): string {
        if (!this.control) {
            return null;
        }

        const isControlInvalid = this.control.touched && this.control.dirty && this.control.invalid;

        if (this.control.errors) {
            for (const key in this.control.errors) {
                if (this.control.errors[key] && isControlInvalid) {
                    return this.translateService.instant(
                        'FORM_ERRORS.' + key.toUpperCase(),
                        this.control.errors[key]
                    );
                }
            }
        }

        return null;
    }
}
