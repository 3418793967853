import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { IconComponent } from './icon/icon.component';
import { LinkTextComponent } from './link-text/link-text.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { ErrorMessagesComponent } from './form/error-messages/error-messages.component';
import { ZoomPanelComponent } from './zoom-panel/zoom-panel.component';
import { ObserveElementDirective } from './observer/observe-element.directive';

@NgModule({
    declarations: [
        ButtonComponent,
        IconComponent,
        LinkTextComponent,
        SpinnerComponent,
        ToggleSwitchComponent,
        TooltipDirective,
        ZoomPanelComponent,
        ErrorMessagesComponent,
        ObserveElementDirective
    ],
    imports: [CommonModule],
    exports: [
        ButtonComponent,
        IconComponent,
        LinkTextComponent,
        SpinnerComponent,
        ToggleSwitchComponent,
        TooltipDirective,
        ZoomPanelComponent,
        ErrorMessagesComponent,
        ObserveElementDirective
    ]
})
export class SharedModule {}
