import { AfterViewInit, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

const BUTTON_ATTRIBUTES = ['app-button', 'app-icon-button'];

const BUTTON_COLOR_TYPES = [
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'link',
    'primary-selected',
    'secondary-selected',
    'tertiary-selected',
    'quaternary-selected',
    'link-selected'
];

const DEFAULT_BUTTON_COLOR = 'primary';
const DEFAULT_BUTTON_ALIGN = 'horizontal';
const DEFAULT_BUTTON_SIZE = 'normal';

export type ColorType =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'link'
    | 'primary-selected'
    | 'secondary-selected'
    | 'tertiary-selected'
    | 'quaternary-selected'
    | 'link-selected'
    | undefined;
export type AlignType = 'vertical' | 'horizontal' | undefined;

export type TextPosition = 'before' | 'after' | 'before-after' | 'none';
export type ButtonSize = 'x-small' | 'small' | 'normal' | 'medium' | 'large' | 'xlarge';

@Component({
    selector: 'app-button, app-button[app-icon-button]',
    exportAs: 'appButton',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.text-before-icon]': 'isBefore()',
        '[class.text-after-icon]': 'isAfter()',
        '[class.text-before-after-icon]': 'isBeforeAndAfter()'
    }
})
export class ButtonComponent implements AfterViewInit {
    @Input()
    textPosition: TextPosition = 'none';

    private _buttonSize: ButtonSize;

    private _color: ColorType;
    private _align: AlignType;
    private _isDisabled = false;

    constructor(private _elementRef: ElementRef) {
        // Add classes
        for (const attr of BUTTON_ATTRIBUTES) {
            if (this.hasHostAttributes(attr)) {
                (this._elementRef.nativeElement as HTMLElement).classList.add(attr);
            }
        }

        // Set default color
        this.color = DEFAULT_BUTTON_COLOR;
        this.iconAlign = DEFAULT_BUTTON_ALIGN;
        this.buttonSize = DEFAULT_BUTTON_SIZE;
    }

    ngAfterViewInit(): void {
        // stop click if button is disabled
        const elem = this._elementRef.nativeElement as HTMLElement;
        elem.parentElement.addEventListener(
            'click',
            event => {
                if (event.target === elem && this._isDisabled) {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                }
            },
            true
        );
    }

    hasHostAttributes(...attributes: string[]) {
        return attributes.some(attribute => this._elementRef.nativeElement.hasAttribute(attribute));
    }

    @Input()
    set color(value: ColorType) {
        if (value) {
            if (BUTTON_COLOR_TYPES.indexOf(value) === -1) {
                console.error('Color type "' + value + '" not supported');
                return;
            }

            if (this._color) {
                this._elementRef.nativeElement.classList.remove(`app-${this._color}`);
            }

            this._elementRef.nativeElement.classList.add(`app-${value}`);
            this._color = value;
        }
    }

    get color(): ColorType {
        return this._color;
    }

    @Input()
    set iconAlign(value: AlignType) {
        if (value) {
            if (this._align) {
                this._elementRef.nativeElement.classList.remove(`app-${this._align}`);
            }

            this._elementRef.nativeElement.classList.add(`app-${value}`);
            this._align = value;
        }
    }

    @Input()
    set buttonSize(value: ButtonSize) {
        if (value) {
            if (this._buttonSize) {
                this._elementRef.nativeElement.classList.remove(`app-btn-size-${this._buttonSize}`);
            }

            this._elementRef.nativeElement.classList.add(`app-btn-size-${value}`);
            this._buttonSize = value;
        }
    }

    @Input()
    get disabled(): boolean {
        return this._isDisabled;
    }

    set disabled(value: boolean) {
        if (this._isDisabled !== value) {
            if (value) {
                this._elementRef.nativeElement.classList.add('app-btn-disabled');
                this._isDisabled = value;
            } else {
                this._elementRef.nativeElement.classList.remove('app-btn-disabled');
                this._isDisabled = value;
            }
        }
    }

    isBeforeAndAfter(): boolean {
        return this.textPosition === 'before-after';
    }

    isBefore(): boolean {
        return this.textPosition === 'before';
    }

    isAfter(): boolean {
        return this.textPosition === 'after';
    }
}
