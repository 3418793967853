import { Observable } from 'rxjs';

import { CanvasService } from '../../canvas/canvas.service';
import { CanvasContour } from '../../canvas/contour/contour-items-interfaces';
import { getScrollX, getScrollY } from '../../../utils/dom-utils';
import { CompoundMoveUndoableEditAction } from '../../undo/compound-move-undoable-edit-action';
import { MoveUndoableEditAction } from '../../undo/move-undoable-edit-action';
import { UndoableEditaAction } from '../../undo/undo-manager.service';
import { FoamEditorService } from '../../foam-editor.service';
import { SimpleDragEvent } from '../event-helpers';
import { Point2D } from '../geom/point2D';

import { Tool } from './tool';

export class DragTracker extends Tool {
    private anchorContourItem: CanvasContour;
    private canvasService: CanvasService;
    private cursorContourOffset: Point2D;
    private lastXY: Point2D;
    private lastInsidePos: Point2D = new Point2D(0, 0);
    private totalDelta: { [id: string]: Point2D } = {};
    private selectedContours: CanvasContour[];

    constructor(readonly foamEditorService: FoamEditorService) {
        super(foamEditorService);
        this.canvasService = this.foamEditorService.getCanvasService();
    }

    handleMouseDown(event: MouseEvent): void {
        event.preventDefault();
        this.canvasService.requestFocus();

        if (!this.anchorContourItem) {
            console.warn('no item to drag');
            return;
        }

        if (event.shiftKey || event.ctrlKey) {
            this.canvasService.toggleSelection(this.anchorContourItem);
        } else if (!this.canvasService.isContourItemSelected(this.anchorContourItem)) {
            this.canvasService.clearSelection();
            this.canvasService.addToSelection([this.anchorContourItem]);
        }

        this.lastXY = new Point2D(event.clientX, event.clientY);
        this.selectedContours = this.canvasService.getSelectedContourItems();
    }

    handleMouseDrag(event: SimpleDragEvent): void {
        event.originalEvent.preventDefault();

        if (!this.anchorContourItem) {
            console.warn('no item to drag');
            return;
        }

        const canvasCursorPt = new Point2D(event.x + getScrollX(), event.y + getScrollY());
        const canvasBounds = this.canvasService.getCanvasViewportBounds().outerBounds;
        const isInsideCanvas = canvasBounds.contains(canvasCursorPt.x, canvasCursorPt.y);
        // stop translation if the cursor is outside the canvas
        if (!isInsideCanvas) {
            return;
        }

        const scaleFactor = this.canvasService.getScaleFactor();
        const delta = new Point2D(event.deltaX / scaleFactor, event.deltaY / scaleFactor);

        // compute x/y-position of the previous mouse-move event
        const prevPt = new Point2D(
            event.x - event.deltaX + getScrollX(),
            event.y - event.deltaY + getScrollY()
        );
        const fromOutsideCanvas = isInsideCanvas && !canvasBounds.contains(prevPt.x, prevPt.y);
        if (fromOutsideCanvas) {
            // The first delta when entering the canvas again must be the cursor distance between
            // since leaving and entering the canvas. This is need to make the (dragged) contour to
            // appear at the canvas border when the mouse is moved inside the canvas
            delta.x = (event.x - this.lastInsidePos.x) / scaleFactor;
            delta.y = (event.y - this.lastInsidePos.y) / scaleFactor;
        }

        this.selectedContours.forEach(contourItem => {
            this.updateTotalDelta(contourItem.contourId, delta.x, delta.y);
            contourItem.translate(delta.x, delta.y);
        });

        this.lastInsidePos.x = event.x;
        this.lastInsidePos.y = event.y;
    }

    handleMouseMove(event: MouseEvent): void {
        event.preventDefault();
    }

    handleMouseUp(event: MouseEvent): void {
        if (this.selectedContours && this.selectedContours.length > 0) {
            this.clampToCanvasBoundaryAfterTransformation();

            if (Object.keys(this.totalDelta).length > 0) {
                this.canvasService
                    .getUndoManagerService()
                    .addEditAction(this.createMoveEditAction());
            }
        }

        // reset
        this.totalDelta = {};
        this.anchorContourItem = undefined;
        this.selectedContours = undefined;
    }

    private createMoveEditAction(): UndoableEditaAction {
        const selectionInfo = {
            addToSelection: (c: CanvasContour[]) => this.canvasService.addToSelection(c),
            selectedContours: this.selectedContours
        };

        if (this.selectedContours.length === 1) {
            return new MoveUndoableEditAction(
                this.selectedContours,
                this.totalDelta[this.selectedContours[0].contourId],
                selectionInfo
            );
        } else {
            const compoundEditAction = new CompoundMoveUndoableEditAction(
                'Move contours',
                selectionInfo
            );
            this.selectedContours.forEach(contour => {
                compoundEditAction.addEditAction(
                    new MoveUndoableEditAction([contour], this.totalDelta[contour.contourId])
                );
            });
            return compoundEditAction;
        }
    }

    private updateTotalDelta(contourId: string, deltaX: number, deltaY: number) {
        let totalDelta = this.totalDelta[contourId];

        if (!totalDelta) {
            totalDelta = new Point2D(0, 0);
            this.totalDelta[contourId] = totalDelta;
        }

        totalDelta.x += deltaX;
        totalDelta.y += deltaY;
    }

    /**
     * Used to limit the itemTransformation after it happens regarding only the left border of the
     * canvas
     *
     */
    private clampToCanvasBoundaryAfterTransformation() {
        const canvasBounds = this.canvasService.getCanvasDrawBounds();

        this.selectedContours.forEach(contourItem => {
            let deltaX = 0;
            let deltaY = 0;
            const contourBounds = contourItem.globalContourPathBBox;
            const adjustedDistance = 10;

            // top boundary
            if (contourBounds.cy < 0) {
                deltaY = -Math.round(contourBounds.cy) + adjustedDistance;
            }

            // right boundary
            if (contourBounds.cx > canvasBounds.width) {
                deltaX = Math.round(canvasBounds.width - contourBounds.cx) - adjustedDistance;
            }

            // bottom boundary
            if (contourBounds.cy > canvasBounds.height) {
                deltaY = Math.round(canvasBounds.height - contourBounds.cy) - adjustedDistance;
            }

            // left boundary
            if (contourBounds.cx < 0) {
                deltaX = -Math.round(contourBounds.cx) + adjustedDistance;
            }

            if (deltaX !== 0 || deltaY !== 0) {
                contourItem.translate(deltaX, deltaY);
                this.updateTotalDelta(contourItem.contourId, deltaX, deltaY);
            }
        });
    }

    setDraggedItem(item: CanvasContour): void {
        this.anchorContourItem = item;
    }

    getToolStarted(): Observable<any> {
        return undefined;
    }

    handleDbClick(event: MouseEvent): void {}
}
