import {
    CLASS_NAME_ROTATION_GRIP,
    ID_ROTATION_HANDLE_GROUP,
    RotationConnectorType
} from '../../canvas/handles/rotation-handles-drawer';
import { RotationHandleData } from '../../canvas/contour/contour-items-interfaces';
import { Handle } from './handle';
import { CuttableContour } from '../../canvas/contour/cuttable-contour';
import { GroupCuttableContour } from '../../canvas/contour/group-cuttable-contour';
import { getAngle } from '../geom/geometry';
import { Point2D } from '../geom/point2D';
import { transformPoint } from '../geom/matrix';
import { Rectangle2D } from '../geom/rectangle2D';
import { RotateUndoableEditAction } from '../../undo/rotate-undoable-edit.action';

export class RotationHandle<T extends CuttableContour | GroupCuttableContour> implements Handle {
    readonly owner: T;
    readonly contourId: string;
    visible: boolean = true;
    gripWidth = 10;
    gripHeight = 10;
    gripRadius = 10;
    distanceFromGripBottomToSelectionBox = 30;
    distanceFromGripCenterToSelectionBox = 40; // gripRadius + distanceFromGripCenterToSelectionBox
    initDistanceFromGripBottomToSelectionBox = 30;
    connectorType: RotationConnectorType = RotationConnectorType.START_ON_END_CENTER;

    private targetSelection: string;
    private containerId: string;

    private currentAngle: number;
    private center: Point2D;
    private startLocalBounds: Rectangle2D;
    private startAngle: number;

    constructor(owner: T) {
        this.owner = owner;
        this.targetSelection = '#' + CLASS_NAME_ROTATION_GRIP + '-' + owner.contourId;
        this.containerId = ID_ROTATION_HANDLE_GROUP + owner.contourId;
        this.contourId = owner.contourId;
    }

    contains(target: Element) {
        return target && target.matches(this.targetSelection);
    }

    trackStart(anchorPt: Point2D) {
        this.center = this.getCenter();
        this.currentAngle = this.owner.localContourPathMatrix.split().rotate;
        this.startAngle = this.currentAngle;
        this.startLocalBounds = this.owner.localContourPathBBox.clone();
    }

    trackStep(startAnchor: Point2D, anchorPt: Point2D) {
        const angle = getAngle(anchorPt.x, anchorPt.y, this.center.x, this.center.y);

        const deltaAngle = angle - this.currentAngle;
        this.owner
            .getCanvas()
            .getSelectedContourItems()
            .forEach(contour => {
                if (contour.isRotatable) {
                    contour.rotate(
                        deltaAngle,
                        contour.localContourPathBBox.cx,
                        contour.localContourPathBBox.cy
                    );
                }
            });

        this.currentAngle += deltaAngle;
    }

    trackEnd(startAnchor: Point2D, anchorPt: Point2D) {
        const totalAngle = this.currentAngle - this.startAngle;
        const contours = this.owner
            .getCanvas()
            .getSelectedContourItems()
            .filter(c => c.isRotatable);

        this.owner
            .getCanvas()
            .getUndoManagerService()
            .addEditAction(
                new RotateUndoableEditAction(
                    contours,
                    totalAngle,
                    this.startLocalBounds.cx,
                    this.startLocalBounds.cy
                )
            );
    }

    getCenter(): Point2D | undefined {
        if (this.owner) {
            // local and untransformed bounds
            const localBounds = this.owner.localContourPathBBox;
            const globalCenter = transformPoint(
                localBounds.cx,
                localBounds.cy,
                this.owner.localContourPathMatrix
            );

            return new Point2D(globalCenter.x, globalCenter.y);
        }
        return undefined;
    }

    getDrawData(): RotationHandleData {
        return new RotationHandleData({
            containerId: this.containerId,
            contourId: this.contourId,
            visible: this.visible,
            gripWidth: this.gripWidth,
            gripHeight: this.gripHeight,
            gripRadius: this.gripRadius,
            distanceFromGripBottomToSelectionBox: this.distanceFromGripBottomToSelectionBox,
            distanceFromGripCenterToSelectionBox: this.distanceFromGripCenterToSelectionBox, // gripRadius + distanceFromGripCenterToSelectionBox
            initDistanceFromGripBottomToSelectionBox: this.initDistanceFromGripBottomToSelectionBox,
            connectorType: this.connectorType
        });
    }
}
