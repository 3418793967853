declare var ClipperLib: any;
const PRECISION_FACTOR = 1000;

export function computeSafetyMarginPath(
    pointsArr: Array<[string, number, number]>,
    marginSize: number
): string | undefined {
    const points: { X: number; Y: number }[] = [];
    for (let i = 0; i < pointsArr.length - 1; i++) {
        const x = pointsArr[i][1] * PRECISION_FACTOR;
        const y = pointsArr[i][2] * PRECISION_FACTOR;
        points.push({ X: x, Y: y });

        if (x === undefined || y === undefined) {
            console.error('some points are undefined');
        }
    }
    const clipperOffset = new ClipperLib.ClipperOffset(2.0, 0.1 * PRECISION_FACTOR);
    clipperOffset.AddPath(points, ClipperLib.JoinType.jtRound, ClipperLib.EndType.etClosedPolygon);
    const solution = new ClipperLib.Path();

    let marginResult: { X: number; Y: number }[] = [];
    try {
        clipperOffset.Execute(solution, marginSize * PRECISION_FACTOR);
        marginResult = solution[0];
        if (!marginResult) {
            console.error('Failed to create margin path');
        }
    } catch (error) {
        console.error(error.message);
        return undefined;
    }

    let pathString = '';
    for (let i = 0; i < marginResult.length; i++) {
        if (i === 0) {
            pathString =
                'M' +
                marginResult[i].X / PRECISION_FACTOR +
                ',' +
                marginResult[i].Y / PRECISION_FACTOR;
        } else {
            pathString +=
                'L' +
                marginResult[i].X / PRECISION_FACTOR +
                ',' +
                marginResult[i].Y / PRECISION_FACTOR;
        }
    }
    // Ensure path is closed:
    const firstPoint = marginResult[0];
    const lastPoint = marginResult[marginResult.length - 1];
    if (firstPoint.X !== lastPoint.X || firstPoint.Y !== lastPoint.Y) {
        pathString += 'L' + firstPoint.X / PRECISION_FACTOR + ',' + firstPoint.Y / PRECISION_FACTOR;
    }
    return pathString;
}
