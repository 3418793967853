import { UndoableEditaAction } from './undo-manager.service';
import { ResizeableContour } from '../canvas/contour/resizeable-contour';

export class DepthEditAction implements UndoableEditaAction {
    readonly presentationName: string;

    constructor(
        private readonly contour: ResizeableContour,
        private readonly newDepth: number,
        private readonly oldDepth: number,
        private readonly isOldDepthUserDefined: boolean,
        private readonly isNewDepthUserDefined: boolean,
        presentationName?: string
    ) {
        if (!contour) {
            throw Error('contour cannot be undefined');
        }
        this.presentationName = presentationName || 'Change contour depth';
    }

    redo(): void {
        this.contour.userDefinedDepth = this.isNewDepthUserDefined;
        this.contour.resizeDepth(this.newDepth);
    }

    undo(): void {
        this.contour.userDefinedDepth = this.isOldDepthUserDefined;
        this.contour.resizeDepth(this.oldDepth);
    }
}
