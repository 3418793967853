import { ElementRef, NgZone } from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Dimension2D } from '../../shared/geom/dimension2d';
import { Point2D } from '../../shared/geom/point2D';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { getScrollX, getScrollY } from '../../../utils/dom-utils';

import {
    CanvasScrollViewportDirective,
    ScrollViewportBounds
} from './canvas-scroll-viewport.directive';

const CANVAS_MARGIN_TOP = 0;

export interface CanvasBoundsChange {
    canvasViewportBounds?: ScrollViewportBounds;
    canvasContentBounds?: Rectangle2D;
    scaleFactor?: number;

    /**
     * Translation amount that keeps the canvas background scrolled to the center.
     * The effect becomes more visible when background size is smaller than the viewport size.
     */
    centerXY?: Point2D;
}

/**
 * This class manages the computation of the canvas viewport and its content.
 */
export class CanvasBoundsManager {
    /** Cache the canvas viewport bounds **/
    private viewportBounds: ScrollViewportBounds;

    /** Cache the canvas content bounds, which usually equals the svg {@link DOMRect} **/
    private canvasContentBounds: Rectangle2D;

    /** Cache the current scale factor of the canvas content **/
    private scaleFactor = 1;

    /** Cache the current scale factor to make the canvas content to fit its viewport **/
    scaleFactorToFitViewport: number;

    private _canvasBoundsChange = new Subject<CanvasBoundsChange>();

    /** Observable of canvasBounds changes **/
    readonly canvasBoundsChange: Observable<CanvasBoundsChange>;

    /** Keeps track of the global `scroll` and `resize` subscriptions. */
    _scrollEventSubscription: Subscription | null = null;

    /** Marks cache of the scale factor used to center to the viewport as invalid **/
    private scaleFactorToFitViewportIsInvalid: boolean;

    private scrollViewport: CanvasScrollViewportDirective;
    private canvasContent: ElementRef<Element>;

    /**
     *
     * @param canvasComponent - canvas component that implements the ZoomAndScrollableCanvas interface
     * @param foamLayer
     * @param ngZone
     */
    constructor(private canvasComponent: ZoomAndScrollableCanvas, private ngZone: NgZone) {
        this.scrollViewport = this.canvasComponent.scrollViewport;
        this.canvasContent = this.canvasComponent.canvasContent;
        this.canvasBoundsChange = this._canvasBoundsChange.pipe(distinctUntilChanged());
        this.listenToScrollEvents();
    }

    listenToScrollEvents() {
        this._scrollEventSubscription = this.ngZone.runOutsideAngular(() => {
            return fromEvent(window.document, 'scroll').subscribe(() => {
                if (this.canvasContentBounds) {
                    this.updateCanvasContentBounds();
                    this._canvasBoundsChange.next({
                        canvasContentBounds: this.canvasContentBounds
                    });
                }
            });
        });
    }

    getScaleFactor(): number {
        return this.scaleFactor;
    }

    getCanvasContentBounds(): Rectangle2D {
        return this.canvasContentBounds;
    }

    getCanvasViewportBounds(): ScrollViewportBounds {
        return this.viewportBounds;
    }

    setViewport(boundsValue: Rectangle2D, scaleToViewPort?: boolean, scrollToCenter?: boolean) {
        if (this.checkBoundsEquality(boundsValue, this.viewportBounds)) {
            return;
        }

        const newViewportBounds = this.computeViewportBounds(
            new Dimension2D(boundsValue.width, boundsValue.height)
        );
        // update dimension if it has changed, e.g., when padding is added
        // it is enough to check if the outerBounds has change
        if (boundsValue && boundsValue.equals(newViewportBounds.outerBounds)) {
            return;
        }

        this.viewportBounds = newViewportBounds;
        let contentBoundsDidChange = false;
        if (!this.canvasContentBounds || scaleToViewPort) {
            // scale canvas content to match the viewport
            const resizeDim = new Dimension2D(
                this.viewportBounds.outerBounds.width,
                this.viewportBounds.outerBounds.height
            );
            this.scaleCanvasContent(resizeDim.width, resizeDim.height);
            contentBoundsDidChange = this.updateCanvasContentBounds();
        }

        if (scaleToViewPort) {
            this.scaleFactorToFitViewportIsInvalid = true;
            this.updateScaleFactorToFitViewport();
            if (this.scaleFactor !== this.scaleFactorToFitViewport) {
                this.scaleFactor = this.scaleFactorToFitViewport;
            }
        }

        let centerXY;
        if (scrollToCenter) {
            centerXY = this.getScaleCenterXY(this.scaleFactor);
            // this.setContentToFitCanvas();
        }

        this._canvasBoundsChange.next({
            canvasViewportBounds: this.viewportBounds,
            scaleFactor: scaleToViewPort ? this.scaleFactor : undefined,
            centerXY: centerXY,
            canvasContentBounds: contentBoundsDidChange ? this.canvasContentBounds : undefined
        });

        // scrollCanvasViewCenter
        // fire change event
    }

    scaleToFitViewport(): void {
        this.scaleFactorToFitViewportIsInvalid = true;
        this.updateScaleFactorToFitViewport();
        this.setScaleFactor(this.scaleFactorToFitViewport);
    }

    private updateScaleFactorToFitViewport() {
        if (!this.scaleFactorToFitViewportIsInvalid || this.viewportBounds == null) {
            return;
        }
        const { width, height } = this.canvasComponent.getCanvasBackgroundLocalBounds();
        const padding = this.canvasComponent.backgroundCenterPadding;

        const scaleFactor = this.computeInitialScaleFactor(
            width,
            height,
            this.viewportBounds.outerBounds.width,
            this.viewportBounds.outerBounds.height,
            padding
        );

        this.scaleFactorToFitViewport = Math.round(scaleFactor * 100) / 100;
        this.scaleFactorToFitViewportIsInvalid = false;
    }

    setScaleFactor(newScaleFactor: number) {
        if (newScaleFactor !== this.scaleFactor) {
            this.scaleFactor = newScaleFactor;

            const canvasBackgroundBounds = this.canvasComponent.getCanvasBackgroundLocalBounds();
            const newWidth = canvasBackgroundBounds.width * newScaleFactor;
            const newHeight = canvasBackgroundBounds.height * newScaleFactor;
            this.scaleCanvasContent(newWidth, newHeight);
            const boundsDidChange = this.updateCanvasContentBounds();

            const centerXY = this.getScaleCenterXY(newScaleFactor);

            this._canvasBoundsChange.next({
                scaleFactor: this.scaleFactor,
                centerXY: centerXY,
                canvasContentBounds: boundsDidChange ? this.canvasContentBounds : undefined
            });
        }
    }

    private getScaleCenterXY(scaleFactor: number): Point2D {
        const { width, height } = this.canvasComponent.getCanvasBackgroundLocalBounds();
        const newWidth = width * scaleFactor;
        const newHeight = height * scaleFactor;
        const centerX = (this.canvasContentBounds.width - newWidth) / 2;
        const centerY = (this.canvasContentBounds.height - newHeight) / 2;
        return new Point2D(centerX, centerY);
    }

    /**
     * Updates the size of the canvas content.
     * The size (width and height) of the canvas content is clamped to the viewport boundary.
     * This will disable the zoom-out if the background fits into the viewport
     *
     * @param newWidth
     * @param newHeight
     */
    private scaleCanvasContent(newWidth: number, newHeight: number): Dimension2D {
        newWidth = Math.max(this.viewportBounds.outerBounds.width, newWidth);
        newHeight = Math.max(this.viewportBounds.outerBounds.height, newHeight);
        // update the svg root element
        this.canvasContent.nativeElement.setAttribute('width', `${newWidth}px`);
        this.canvasContent.nativeElement.setAttribute('height', `${newHeight}px`);

        return new Dimension2D(newWidth, newHeight);
    }

    private setCanvasContentToViewportBounds(): boolean {
        const resizeDim = new Dimension2D(
            this.viewportBounds.outerBounds.width,
            this.viewportBounds.outerBounds.height
        );
        this.scaleCanvasContent(resizeDim.width, resizeDim.height);
        return this.updateCanvasContentBounds();
    }

    private checkBoundsEquality(a: any, b: any): boolean {
        if (!a || !b) {
            return false;
        }
        return a.equals(b);
    }

    private updateCanvasContentBounds(): boolean {
        const newCanvasContentBounds = this.measureCanvasContentBounds();
        if (!this.checkBoundsEquality(newCanvasContentBounds, this.canvasContentBounds)) {
            this.canvasContentBounds = newCanvasContentBounds;
            return true;
        }
        return false;
    }

    private measureCanvasContentBounds(): Rectangle2D {
        // TODO use clientTop/Left
        const drawAreaBounds = <DOMRect> this.canvasContent.nativeElement.getBoundingClientRect();
        return new Rectangle2D(
            drawAreaBounds.x,
            drawAreaBounds.y,
            drawAreaBounds.width,
            drawAreaBounds.height
        );
    }

    private computeViewportBounds(newViewportDimension: Dimension2D): ScrollViewportBounds {
        const width = Math.max(1, newViewportDimension.width);
        const height = Math.max(1, newViewportDimension.height - CANVAS_MARGIN_TOP);

        const viewportRects = this.scrollViewport.getScrollViewportBounds();

        const newCanvasOffset = new Point2D(
            viewportRects.outerBounds.x + getScrollX(),
            viewportRects.outerBounds.y + getScrollY()
        );

        const outerBounds = new Rectangle2D(newCanvasOffset.x, newCanvasOffset.y, width, height);
        const innerBounds = new Rectangle2D(
            newCanvasOffset.x,
            newCanvasOffset.y,
            viewportRects.innerBounds.width,
            viewportRects.innerBounds.height
        );
        return new ScrollViewportBounds(innerBounds, outerBounds);
    }

    private computeInitialScaleFactor(
        width: number,
        height: number,
        maxWidth: number,
        maxHeight: number,
        padding: [number, number, number, number]
    ): number {
        let scaleFactor = 1;
        const viewportRatio = maxWidth / maxHeight;
        const foamRatio = width / height;
        if (foamRatio <= viewportRatio) {
            const newHeight = maxHeight - (padding[0] + padding[2]);
            scaleFactor = newHeight / height;
        } else {
            const newWidth = maxWidth - (padding[1] + padding[3]);
            scaleFactor = newWidth / width;
        }

        return scaleFactor;
    }

    destroy() {
        this._removeGlobalListener();
    }

    private _removeGlobalListener() {
        if (this._scrollEventSubscription) {
            this._scrollEventSubscription.unsubscribe();
            this._scrollEventSubscription = null;
        }
    }
}

export interface ZoomAndScrollableCanvas {
    /** Viewport element (visible area) that is enhanced with the
     * {@code CanvasScrollViewportDirective} directive to perform the scroll **/
    scrollViewport: CanvasScrollViewportDirective;
    /** Content to be zoomed and scroll. This is typically the svg-root element **/
    canvasContent: ElementRef<Element>;
    /** Background that defines the drawing area (e.g. foam-layer) within editor items are placed **/
    // canvasBackground: ElementRef<Element>;
    /** Padding that should be applied to center and fit the background (e.g. foam) to the viewport **/
    backgroundCenterPadding: [number, number, number, number];

    /**
     * Gets the current bounds of the canvas background
     *
     * @return Rectangle2D
     */
    getCanvasBackgroundLocalBounds(): Rectangle2D | undefined;
}
