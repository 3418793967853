import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { CustomValidators } from '../../../shared/form/validators';
import { DialogRefs } from '../../../dialog/dialog-refs';

@Component({
    selector: 'app-contour-depth-prompt-dialog',
    templateUrl: './contour-depth-prompt-dialog.component.html',
    styleUrls: ['./contour-depth-prompt-dialog.component.scss']
})
export class ContourDepthPromptDialogComponent implements OnInit {
    private responseSubject = new Subject<DepthPromptResponse>();
    readonly response: Observable<DepthPromptResponse> = this.responseSubject.asObservable();
    buttonCancelText: string;
    form: FormGroup;
    depth: FormControl;

    minContourDepth: number = 0;
    maxContourDepth: number = Number.POSITIVE_INFINITY;

    constructor(
        public dialogRefs: DialogRefs<ContourDepthPromptDialogComponent>,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.translate.stream(['NAVIGATION_BUTTON']).subscribe(res => {
            this.buttonCancelText = res['NAVIGATION_BUTTON'].CANCEL;
            this.setForm();
        });
    }

    cancel() {
        this.form.reset();
        this.dialogRefs.close();
    }

    private setForm() {
        this.depth = new FormControl(this.minContourDepth, [
            Validators.required,
            CustomValidators.rangeNumber(this.minContourDepth, this.maxContourDepth),
            CustomValidators.positiveInteger
        ]);

        this.form = new FormGroup({ depth: this.depth });
    }

    processed() {
        if (this.depth.valid) {
            this.responseSubject.next({ depth: Number(this.depth.value), canceled: false });
            this.dialogRefs.close();
        }
    }
}

export interface DepthPromptResponse {
    depth: number;
    canceled: boolean;
}
