<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <div class="dialog-content__title howto-step-title">
                    {{ 'TOOL_CAPTURE_INSTRUCTION_DIALOG.UPLOAD_INFO_TEXT' | translate }}
                </div>
                <div class="howto-steps__container">
                    <div class="howto-steps__video">
                        <img src="assets/upload_dialog/howto-example.png" />
                    </div>
                    <div class="howto-steps">
                        <div class="howto-step-item">
                            <div class="howto-step-number">1</div>
                            <p>
                                {{ 'TOOL_CAPTURE_INSTRUCTION_DIALOG.UPLOAD_STEP_ONE' | translate }}
                            </p>
                        </div>
                        <div class="howto-step-item">
                            <div class="howto-step-number">2</div>
                            <p>
                                {{ 'TOOL_CAPTURE_INSTRUCTION_DIALOG.UPLOAD_STEP_TWO' | translate }}
                            </p>
                        </div>
                        <div class="howto-step-item">
                            <div class="howto-step-number">3</div>
                            <p
                                [innerHTML]="
                                    'TOOL_CAPTURE_INSTRUCTION_DIALOG.UPLOAD_STEP_THREE' | translate
                                "
                            ></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="capture-notes__container">
                <div class="dialog-content__inner-container">
                    <div class="dialog-content__title">
                        {{ 'TOOL_CAPTURE_INSTRUCTION_DIALOG.WARNING_INFO_TEXT' | translate }}
                    </div>
                    <div class="capture-notes-list">
                        <div class="capture-note">
                            <img src="assets/upload_dialog/howto-step-1.svg" />
                            <p
                                [innerHTML]="
                                    'TOOL_CAPTURE_INSTRUCTION_DIALOG.WARNING_DISTANCE' | translate
                                "
                            ></p>
                        </div>

                        <div class="capture-note">
                            <img src="assets/upload_dialog/howto-step-2.svg" />
                            <p
                                [innerHTML]="
                                    'TOOL_CAPTURE_INSTRUCTION_DIALOG.WARNING_LIGHT' | translate
                                "
                            ></p>
                        </div>

                        <div class="capture-note">
                            <img src="assets/upload_dialog/howto-step-3.svg" />
                            <p
                                [innerHTML]="
                                    'TOOL_CAPTURE_INSTRUCTION_DIALOG.WARNING_TOOLS' | translate
                                "
                            ></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-upload-dialog-footer></app-upload-dialog-footer>
