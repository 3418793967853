/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll-viewport.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scroll-viewport.component";
import * as i3 from "./default-scroll-strategy";
var styles_ScrollViewportComponent = [i0.styles];
var RenderType_ScrollViewportComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ScrollViewportComponent, data: {} });
export { RenderType_ScrollViewportComponent as RenderType_ScrollViewportComponent };
export function View_ScrollViewportComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { scrollContentContainer: 0 }), i1.ɵqud(671088640, 2, { innerScrollViewport: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["innerScrollViewport", 1]], null, 3, "div", [["class", "scroll-viewport-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["scrollContentContainer", 1]], null, 1, "div", [["class", "scroll-content-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "scroll-spacer"]], [[4, "transform", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalContentSizeTransform; _ck(_v, 5, 0, currVal_0); }); }
export function View_ScrollViewportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scroll-viewport", [], [[2, "scroll-viewport", null], [2, "scroll-viewport--horizontal", null], [2, "scroll-viewport--vertical", null]], null, null, View_ScrollViewportComponent_0, RenderType_ScrollViewportComponent)), i1.ɵdid(1, 4964352, null, 0, i2.ScrollViewportComponent, [i1.ElementRef, i3.INL_SCROLL_STRATEGY, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).scrollViewportClass; var currVal_1 = i1.ɵnov(_v, 1).isHorizontal; var currVal_2 = i1.ɵnov(_v, 1).isVertical; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var ScrollViewportComponentNgFactory = i1.ɵccf("app-scroll-viewport", i2.ScrollViewportComponent, View_ScrollViewportComponent_Host_0, { orientation: "orientation", viewportSize: "viewportSize", hideScrollBars: "hideScrollBars", itemCount: "itemCount" }, {}, ["*"]);
export { ScrollViewportComponentNgFactory as ScrollViewportComponentNgFactory };
