/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-validity-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/icon/icon.component.ngfactory";
import * as i3 from "../../shared/icon/icon.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./project-validity-info.component";
var styles_ProjectValidityInfoComponent = [i0.styles];
var RenderType_ProjectValidityInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectValidityInfoComponent, data: {} });
export { RenderType_ProjectValidityInfoComponent as RenderType_ProjectValidityInfoComponent };
function View_ProjectValidityInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "collision-warning-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "collision-warning-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(3, 114688, null, 0, i3.IconComponent, [i1.ElementRef], { shape: [0, "shape"], cssWidth: [1, "cssWidth"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "collision-warning-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "warning"; var currVal_1 = "28px"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("EDITOR.COLLISION_WARNING")); _ck(_v, 5, 0, currVal_2); }); }
function View_ProjectValidityInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "project-is-valid-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "project-is-valid-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(5, 114688, null, 0, i3.IconComponent, [i1.ElementRef], { shape: [0, "shape"], cssWidth: [1, "cssWidth"] }, null)], function (_ck, _v) { var currVal_1 = "check-circle"; var currVal_2 = "24px"; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("EDITOR.COLLISION_OK")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ProjectValidityInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectValidityInfoComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["isValidPanel", 2]], null, 0, null, View_ProjectValidityInfoComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasCollision; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProjectValidityInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-validity-info", [], null, null, null, View_ProjectValidityInfoComponent_0, RenderType_ProjectValidityInfoComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProjectValidityInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectValidityInfoComponentNgFactory = i1.ɵccf("app-project-validity-info", i6.ProjectValidityInfoComponent, View_ProjectValidityInfoComponent_Host_0, { hasCollision: "hasCollision" }, {}, []);
export { ProjectValidityInfoComponentNgFactory as ProjectValidityInfoComponentNgFactory };
