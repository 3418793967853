export enum ApiErrorCode {
    INTERNAL_ERROR = 6000,
    /**
     * Indicates an unknown error caused by a native library.
     */
    NATIVE_INTERNAL_ERROR = 5999,
    /**
     * Indicates that the paper detected (based on the markers) is not supported.
     */
    PAPER_DETECTION_FAILED = 6001,
    /**
     * Indicates that no markers were found.
     */
    MARKER_DETECTION_FAILED = 6002,
    CONTOUR_DETECTION_FAILED = 6003,
    JOB_ALREADY_EXISTS = 6004,
    JOB_NOT_FOUND = 6005,
    /**
     * Indicates an error while trying to read the image to be segmented.
     */
    INVALID_IMAGE = 6006,
    REFINEMENT_FAILED = 6007,
    IMAGE_SIZE_LIMIT_EXCEEDED = 6008,
    ASYNC_REQUEST_TIMEOUT = 6009
}

export interface ApiError {
    statusCode: number;
    errorCode: number;
    message: string;
    data?: any | null;
}

export interface FileApiError extends ApiError {
    filename: string;
}

export function instanceOfApiError(object: any): object is ApiError {
    return (
        'statusCode' in object && 'errorCode' in object && 'message' in object && 'data' in object
    );
}
