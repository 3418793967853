import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ProjectValidityInfoComponent } from '../project-validity-info/project-validity-info.component';
import { FoamLayerDirective } from '../canvas/layers/foam-layer.directive';

@Component({
    selector: 'app-project-property',
    templateUrl: './project-property.component.html',
    styleUrls: ['./project-property.component.scss']
})
export class ProjectPropertyComponent implements OnInit {
    @ViewChild(ProjectValidityInfoComponent, { static: false })
    projectValidityInfo: ProjectValidityInfoComponent;

    isTextInputDisabled: boolean = true;
    @ViewChild('configNameInput', { static: false })
    readonly configurationNameTextInput: ElementRef<HTMLInputElement>;

    @ViewChild('editButton', { static: false })
    readonly editButton: ElementRef;

    @Output()
    configurationNameChange = new EventEmitter<string>();

    @Input()
    hasCollision: boolean = false;

    private _configurationName: string = '';
    private _boxName: string = 'Unknown Box';

    ngOnInit() {}

    onChange() {
        this.configurationNameChange.emit(this.configurationNameTextInput.nativeElement.value);
    }

    @Input()
    get configurationName() {
        return this._configurationName;
    }

    set configurationName(value: string) {
        if (value != null && typeof value !== 'object') {
            this._configurationName = `${value}`.trim();
        }
    }

    @Input()
    get boxName() {
        return this._boxName;
    }

    set boxName(value: string) {
        if (value != null && typeof value !== 'object') {
            this._boxName = `${value}`.trim();
        }
    }

    onEditLayoutName() {
        this.isTextInputDisabled = !this.isTextInputDisabled;
        if (this.isTextInputDisabled) {
            this.configurationNameTextInput.nativeElement.setAttribute('disabled', '');
        } else {
            this.configurationNameTextInput.nativeElement.removeAttribute('disabled');
            this.configurationNameTextInput.nativeElement.focus();
        }
    }

    onEnter() {
        this.configurationNameTextInput.nativeElement.blur();
    }

    onBlur($event: FocusEvent) {
        if ($event.relatedTarget) {
            // skip blur events caused by the editButton as they are already handled in the
            // the click event handler #onEditLayoutName()
            const parentElem = ($event.relatedTarget as HTMLElement).parentElement;
            if (parentElem.classList.contains('project-title__btn')) {
                return;
            }
        }

        this.isTextInputDisabled = true;
        this.configurationNameTextInput.nativeElement.setAttribute('disabled', '');
    }
}
