<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'secondary'"
            iconAlign="horizontal"
            textPosition="after"
            buttonSize="normal"
            (click)="previousContent()"
            data-test-id="dialog-prev-btn"
        >
            <app-icon svgWidth="24" svgHeight="24" [shape]="backwardButtonIcon" iconSize="small">
            </app-icon>
            {{ backwardButtonText }}
        </app-button>
        <app-button
            app-icon-button
            [color]="'primary'"
            [disabled]="isNextButtonDisabled"
            buttonSize="normal"
            textPosition="before"
            (click)="nextContent()"
            data-test-id="dialog-next-btn"
        >
            <app-spinner *ngIf="showSpinnerOnNextButton" type="chase" color="grey"></app-spinner>
            {{ forwardButtonText }}
            <app-icon svgWidth="24" svgHeight="24" shape="arrow_right" iconSize="small"> </app-icon>
        </app-button>
    </div>
</app-dialog-footer>
