declare const Snap: any;

export class PositionData {
    localMatrix: Snap.Matrix;

    constructor(obj: any) {
        if (obj != null && obj.localMatrix != null) {
            this.localMatrix = Snap.matrix(
                obj.localMatrix.a,
                obj.localMatrix.b,
                obj.localMatrix.c,
                obj.localMatrix.d,
                obj.localMatrix.e,
                obj.localMatrix.f
            );
        }
    }
}
