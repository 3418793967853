import { Observable, Subject } from 'rxjs';

import { DomPortalSlot, ViewContainerPortalSlot } from '../shared/portal/portal';

import { DialogContainerComponent } from './dialog-container.component';

/**
 * The DialogRefs is used to manipulate the Dialog after its creation.
 */
export class DialogRefs<T> {
    dialogContentComponent: T;

    private readonly opened = new Subject<void>();
    private readonly closed = new Subject<boolean>();

    readonly afterOpened: Observable<void> = this.opened.asObservable();
    readonly afterClosed: Observable<boolean> = this.closed.asObservable();

    readonly dialogContainer: DialogContainerComponent;

    constructor(
        private portalSlot: DomPortalSlot | ViewContainerPortalSlot,
        dialogContainer: DialogContainerComponent
    ) {
        this.portalSlot = portalSlot;
        this.dialogContainer = dialogContainer;

        // this.dialogContent = dialogComponentRef.instance.modalDialogContent;
        // this.dialogComponentRef = dialogComponentRef;

        this.portalSlot.detached.subscribe(() => {
            this.closed.next();
            this.closed.complete();
            this.dialogContentComponent = null;
        });

        this.dialogContainer.attached.subscribe(compRef => {
            this.opened.next();
        });

        /*
        _overlayRef.detachments().subscribe(() => {
            this._afterClosed.next(this._result);
            this._afterClosed.complete();
            this.componentInstance = null!;
            this._overlayRef.dispose();
        }); */
    }

    /**
     * Close dialog.
     */
    close(b?: boolean) {
        this.closed.next(b);
        this.closed.complete();
        this.portalSlot.dispose();
    }
}
