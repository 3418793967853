/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expansion-panel-header.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./expansion-panel-header.component";
import * as i3 from "./expansion-panel.component";
var styles_ExpansionPanelHeaderComponent = [i0.styles];
var RenderType_ExpansionPanelHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ExpansionPanelHeaderComponent, data: {} });
export { RenderType_ExpansionPanelHeaderComponent as RenderType_ExpansionPanelHeaderComponent };
export function View_ExpansionPanelHeaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "app-panel-header-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "app-expansion-indicator"]], [[2, "indicator-expanded", null]], null, null, null, null)), i1.ɵncd(null, 0), i1.ɵncd(null, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._isExpanded(); _ck(_v, 1, 0, currVal_0); }); }
export function View_ExpansionPanelHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expansion-panel-header", [["class", "app-expansion-panel-header"], ["role", "button"]], [[1, "tabindex", 0], [2, "app-expanded", null], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ExpansionPanelHeaderComponent_0, RenderType_ExpansionPanelHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.ExpansionPanelHeaderComponent, [i3.ExpansionPanelComponent, i1.ElementRef, i1.ChangeDetectorRef], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : 0); var currVal_1 = i1.ɵnov(_v, 1)._isExpanded(); var currVal_2 = i1.ɵnov(_v, 1)._isExpanded(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var ExpansionPanelHeaderComponentNgFactory = i1.ɵccf("app-expansion-panel-header", i2.ExpansionPanelHeaderComponent, View_ExpansionPanelHeaderComponent_Host_0, {}, {}, ["appExpansionPanelTitle", "*"]);
export { ExpansionPanelHeaderComponentNgFactory as ExpansionPanelHeaderComponentNgFactory };
