<div class="price-display">
    <div class="price-display__title">
        <div class="price-display__title-text">
            {{ 'EDITOR.PRICE_DISPLAY' | translate }}
        </div>
        <svg height="75" width="20" xmlns="http://www.w3.org/2000/svg">
            <polygon points="0,0 0,75 20,36.5" />
        </svg>
    </div>

    <div class="price-display__price">
        <div class="price-display__price-text">{{ price }}</div>
        <div class="price-display__price-info">
            {{ vat }}
        </div>
    </div>
</div>
