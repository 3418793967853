import { Action, ActionEvent } from './action';
import { Injectable } from '@angular/core';
import { FoamEditorService } from '../foam-editor.service';
@Injectable()
export class ShowSafetyMarginAction implements Action {
    constructor(private readonly foamEditor: FoamEditorService) {}

    execute(event: ShowSafetyMarginActionEvent) {
        this.foamEditor.getCanvasService().setProperties({
            showContourSafeMargin: event.showContourSafeMargin
        });
    }
}
export class ShowSafetyMarginActionEvent extends ActionEvent {
    showContourSafeMargin: boolean;
}
