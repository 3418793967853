/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-text.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./link-text.component";
var styles_LinkTextComponent = [i0.styles];
var RenderType_LinkTextComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LinkTextComponent, data: {} });
export { RenderType_LinkTextComponent as RenderType_LinkTextComponent };
export function View_LinkTextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["tabindex", "0"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_LinkTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-text", [], [[2, "text-before-icon", null], [2, "text-after-icon", null], [2, "text-before-after-icon", null]], null, null, View_LinkTextComponent_0, RenderType_LinkTextComponent)), i1.ɵdid(1, 114688, null, 0, i2.LinkTextComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isBefore(); var currVal_1 = i1.ɵnov(_v, 1).isAfter(); var currVal_2 = i1.ɵnov(_v, 1).isBeforeAndAfter(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var LinkTextComponentNgFactory = i1.ɵccf("app-link-text, app-link-text[app-icon-link-text]", i2.LinkTextComponent, View_LinkTextComponent_Host_0, { isActive: "isActive", textPosition: "textPosition", disabled: "disabled" }, {}, ["*"]);
export { LinkTextComponentNgFactory as LinkTextComponentNgFactory };
