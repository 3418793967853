<app-dialog-header>
    <h1 class="app-dialog-title">{{ 'CONTOUR_DEPTH_DIALOG.TITLE' | translate }}</h1>
</app-dialog-header>
<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <p>{{ 'CONTOUR_DEPTH_DIALOG.DEPTH_INFO_TEXT' | translate }}</p>
                <form [formGroup]="form">
                    <div class="form-field" id="form-field__contour-depth">
                        <input
                            type="text"
                            formControlName="depth"
                            id="input-contour-depth"
                            class="input-element"
                            placeholder="0"
                            required
                        />
                        <label class="form-field__label" for="input-contour-depth">mm</label>
                        <div class="form-field__hint">
                            {{ 'CONTOUR_DEPTH_DIALOG.VALID_DEPTH' | translate }}
                            {{ minContourDepth }}-{{ maxContourDepth }} mm
                        </div>
                    </div>
                    <app-error-messages [group]="form" controlName="title"> </app-error-messages>
                </form>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'secondary'"
            iconAlign="horizontal"
            textPosition="after"
            buttonSize="normal"
            (click)="cancel()"
        >
            <app-icon svgWidth="24" svgHeight="24" shape="icon-close" iconSize="small"> </app-icon>
            {{ buttonCancelText }}
        </app-button>
        <app-button
            app-icon-button
            [disabled]="form.invalid"
            [color]="'primary'"
            buttonSize="normal"
            textPosition="before"
            (click)="processed()"
        >
            {{ 'CONTOUR_DEPTH_DIALOG.NEXT_BUTTON' | translate }}
            <app-icon svgWidth="24" svgHeight="24" shape="arrow_right" iconSize="small"> </app-icon>
        </app-button>
    </div>
</app-dialog-footer>
