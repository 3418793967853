import { CompoundMoveUndoableEditAction } from '../undo/compound-move-undoable-edit-action';
import { Point2D } from '../shared/geom/point2D';
import { MoveUndoableEditAction } from '../undo/move-undoable-edit-action';
export class AlignAction {
    constructor(foamEditor) {
        this.foamEditor = foamEditor;
        this.canvasService = this.foamEditor.getCanvasService();
    }
    execute(event) {
        const selectedContourItems = this.canvasService.getSelectedContourItems();
        const selectionInfo = {
            addToSelection: (c) => this.canvasService.addToSelection(c),
            selectedContours: selectedContourItems
        };
        const compoundEditAction = new CompoundMoveUndoableEditAction(this.undoPresentationName, selectionInfo);
        this.alignContour(selectedContourItems, this.getSelectionBounds(), compoundEditAction);
        // check if any translation was done
        if (compoundEditAction.editActions.length > 0) {
            this.canvasService.getUndoManagerService().addEditAction(compoundEditAction);
        }
    }
    getSelectionBounds() {
        let xMin = Number.POSITIVE_INFINITY;
        let yMin = Number.POSITIVE_INFINITY;
        let xMax = Number.NEGATIVE_INFINITY;
        let yMax = Number.NEGATIVE_INFINITY;
        const selectedItems = this.canvasService.getSelectedContourItems();
        const itemsCount = selectedItems.length;
        const xMins = [];
        const yMins = [];
        selectedItems
            .filter(c => c.contourBoundsInParent != null)
            .forEach((contour) => {
            const bbox = contour.contourBoundsInParent.clone();
            xMins.push({ contour: contour, cx: bbox.cx });
            yMins.push({ contour: contour, cy: bbox.cy });
            xMin = Math.min(xMin, bbox.x);
            yMin = Math.min(yMin, bbox.y);
            xMax = Math.max(xMax, bbox.x2);
            yMax = Math.max(yMax, bbox.y2);
        });
        xMins.sort((a, b) => {
            if (a.cx > b.cx) {
                return 1;
            }
            if (a.cx < b.cx) {
                return -1;
            }
            return 0;
        });
        yMins.sort((a, b) => {
            if (a.cy > b.cy) {
                return 1;
            }
            if (a.cy < b.cy) {
                return -1;
            }
            return 0;
        });
        const height = yMax - yMin;
        const width = xMax - xMin;
        const cx = xMin + width / 2;
        const cy = yMin + height / 2;
        let gapCenterX;
        let gapCenterY;
        if (itemsCount > 2) {
            const horizontalSpreadRegionWidth = xMins[itemsCount - 1].cx - xMins[0].cx;
            gapCenterX = horizontalSpreadRegionWidth / (itemsCount - 1);
            const verticalSpreadRegionHeight = yMins[itemsCount - 1].cy - yMins[0].cy;
            gapCenterY = verticalSpreadRegionHeight / (itemsCount - 1);
        }
        return {
            x: xMin,
            y: yMin,
            x2: xMax,
            y2: yMax,
            width: width,
            height: height,
            cx: cx,
            cy: cy,
            gapCenterX: gapCenterX,
            gapCenterY: gapCenterY,
            sortedCenterX: xMins,
            sortedCenterY: yMins
        };
    }
}
export class LeftAlignAction extends AlignAction {
    constructor() {
        super(...arguments);
        this.undoPresentationName = 'Left align contours';
    }
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        selectedContour.forEach(contour => {
            const contourBounds = contour.contourBoundsInParent.clone();
            const deltaX = selectionBounds.x - contourBounds.x;
            if (deltaX !== 0) {
                // TODO can we batch transformations
                contour.translate(deltaX, 0);
                compoundEditAction.addEditAction(new MoveUndoableEditAction([contour], new Point2D(deltaX, 0)));
            }
        });
    }
}
export class TopAlignAction extends AlignAction {
    constructor() {
        super(...arguments);
        this.undoPresentationName = 'Top align contours';
    }
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        selectedContour.forEach(contour => {
            const contourBounds = contour.contourBoundsInParent.clone();
            const deltaY = selectionBounds.y - contourBounds.y;
            if (deltaY !== 0) {
                // TODO can we batch transformations
                contour.translate(0, deltaY);
                compoundEditAction.addEditAction(new MoveUndoableEditAction([contour], new Point2D(0, deltaY)));
            }
        });
    }
}
export class RightAlignAction extends AlignAction {
    constructor() {
        super(...arguments);
        this.undoPresentationName = 'Right align contours';
    }
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        selectedContour.forEach(contour => {
            const contourBounds = contour.contourBoundsInParent.clone();
            const deltaX = selectionBounds.x2 - contourBounds.x2;
            if (deltaX !== 0) {
                // TODO can we batch transformations
                contour.translate(deltaX, 0);
                compoundEditAction.addEditAction(new MoveUndoableEditAction([contour], new Point2D(deltaX, 0)));
            }
        });
    }
}
export class BottomAlignAction extends AlignAction {
    constructor() {
        super(...arguments);
        this.undoPresentationName = 'Bottom align contours';
    }
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        selectedContour.forEach(contour => {
            const contourBounds = contour.contourBoundsInParent.clone();
            const deltaY = selectionBounds.y2 - contourBounds.y2;
            if (deltaY !== 0) {
                // TODO can we batch transformations
                contour.translate(0, deltaY);
                compoundEditAction.addEditAction(new MoveUndoableEditAction([contour], new Point2D(0, deltaY)));
            }
        });
    }
}
export class HorizontalCenterAlignAction extends AlignAction {
    constructor() {
        super(...arguments);
        this.undoPresentationName = 'Center contours horizontal';
    }
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        selectedContour.forEach(contour => {
            const contourBounds = contour.contourBoundsInParent.clone();
            const deltaX = selectionBounds.cx - contourBounds.cx;
            if (deltaX !== 0) {
                // TODO can we batch transformations
                contour.translate(deltaX, 0);
                compoundEditAction.addEditAction(new MoveUndoableEditAction([contour], new Point2D(deltaX, 0)));
            }
        });
    }
}
export class VerticalCenterAlignAction extends AlignAction {
    constructor() {
        super(...arguments);
        this.undoPresentationName = 'Center contours vertically';
    }
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        selectedContour.forEach(contour => {
            const contourBounds = contour.contourBoundsInParent.clone();
            const deltaY = selectionBounds.cy - contourBounds.cy;
            if (deltaY !== 0) {
                // TODO can we batch transformations
                contour.translate(0, deltaY);
                compoundEditAction.addEditAction(new MoveUndoableEditAction([contour], new Point2D(0, deltaY)));
            }
        });
    }
}
export class HorizontalSpreadAlignAction extends AlignAction {
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        if (selectedContour.length < 3) {
            return;
        }
        selectionBounds.sortedCenterX.forEach((newCenter, index) => {
            // skip first and last item
            if (index === 0 || index === selectionBounds.sortedCenterX.length - 1) {
                return;
            }
            if (newCenter.contour) {
                const itemCx = selectionBounds.sortedCenterX[0].cx + selectionBounds.gapCenterX * index;
                const deltaX = itemCx - newCenter.cx;
                if (deltaX !== 0) {
                    newCenter.contour.translate(deltaX, 0);
                    compoundEditAction.addEditAction(new MoveUndoableEditAction([newCenter.contour], new Point2D(deltaX, 0)));
                }
            }
        });
    }
}
export class VerticalSpreadAlignAction extends AlignAction {
    /**
     * @override
     * @inheritdoc
     */
    alignContour(selectedContour, selectionBounds, compoundEditAction) {
        if (selectedContour.length < 3) {
            return;
        }
        selectionBounds.sortedCenterY.forEach((newCenter, index) => {
            // skip first and last item
            if (index === 0 || index === selectionBounds.sortedCenterY.length - 1) {
                return;
            }
            if (newCenter.contour) {
                const itemCy = selectionBounds.sortedCenterY[0].cy + selectionBounds.gapCenterY * index;
                const deltaY = itemCy - newCenter.cy;
                if (deltaY !== 0) {
                    newCenter.contour.translate(0, deltaY);
                    compoundEditAction.addEditAction(new MoveUndoableEditAction([newCenter.contour], new Point2D(0, deltaY)));
                }
            }
        });
    }
}
