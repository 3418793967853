import { TranslateService } from '@ngx-translate/core';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';

import { FoamEditorService } from '../../../../foam-editor.service';
import {
    ImageContourModel,
    ImageContourType,
    ProducerImageContourModel
} from '../../../../../models/image-contour.model';
import { DialogService } from '../../../../../dialog/dialog.service';
import { ImageContourDeleteDialogComponent } from '../../../../dialogs/image-contour-delete-dialog/image-contour-delete-dialog.component';
import { ImageContourEditDialogComponent } from '../../../../dialogs/image-contour-edit-dialog/image-contour-edit-dialog.component';
import { PreviewContourService } from '../../grip-text-contour-panel/preview-contour.service';
import { ScrollViewportComponent } from '../../../../scroll-viewport/scroll-viewport.component';

import { CreationImageContourDndHandler } from './creation-image-contour-dnd-handler';
import { DndHostComponent } from '../../../../../droppable/default-svg-dnd-handler';
import {
    CanvasImageContourCreateActionEvent,
    ImageContourCreateAction
} from '../../../../actions/contour-create.action';
import { auditTime, take, takeUntil } from 'rxjs/operators';
import { animationFrameScheduler, merge, Subject } from 'rxjs';
import { PartnerContourInfoDialogComponent } from '../../../../dialogs/partner-contour-info-dialog/partner-contour-info-dialog.component';

@Component({
    selector: 'app-image-contours-browser',
    templateUrl: './image-contours-browser.component.html',
    styleUrls: ['./image-contours-browser.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageContoursBrowserComponent
implements DndHostComponent<ImageContourModel>, OnInit, AfterViewInit, OnDestroy {
    @Input()
    data: ImageContourModel[];

    dropZoneId = 'workspace-dropzone';

    @ViewChild('contourItemsList', { static: false })
    readonly contourItemsList: ElementRef;

    @ViewChild(ScrollViewportComponent, { static: false })
    scrollViewport: ScrollViewportComponent;

    private _viewportSize: number;

    public dndHandler: CreationImageContourDndHandler;

    canScrollUp = false;
    canScrollDown = false;
    private imageContourCreateAction: ImageContourCreateAction;
    private onDestroySubject = new Subject<void>();
    private invalidItemTooltip: string;
    private addItemInfoTooltip: string;

    constructor(
        private foamEditorService: FoamEditorService,
        private dialogService: DialogService,
        private previewContourService: PreviewContourService,
        private ngZone: NgZone,
        private readonly translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.translateService.stream(['MOUSEOVER']).subscribe(res => {
            this.invalidItemTooltip = res.MOUSEOVER.INVALID_ITEM_DEPTH;
            this.addItemInfoTooltip = res.MOUSEOVER.ITEM_ACTION_DESCRIPTION;
        });

        this.dndHandler = new CreationImageContourDndHandler(this, this.previewContourService);
        this.imageContourCreateAction = new ImageContourCreateAction(
            this.foamEditorService,
            this.dialogService
        );
    }

    ngAfterViewInit(): void {
        // renderedContentChange is need to update the scroll buttons the data changes,
        // i.e. when this component is initially loaded or whenever the a new image contour
        // is added by the user
        // TODO duplication see DetectionResultComponent
        merge(this.scrollViewport.renderedContentChange, this.scrollViewport.elementScrolled())
            .pipe(
                takeUntil(this.onDestroySubject),
                // Collect multiple events into one until the next animation frame
                auditTime(0, animationFrameScheduler)
            )
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.canScrollUp = this.scrollViewport.canScrollTo('top');
                    this.canScrollDown = this.scrollViewport.canScrollTo('bottom');
                    // We must manually mark this view for change detection,
                    // otherwise the changes to `canScrollUp` and `canScrollDown` will not be applied
                    // to their binding.
                    this.changeDetectorRef.markForCheck();
                });
            });
    }

    @Input()
    get viewportSize(): number {
        return this._viewportSize;
    }

    set viewportSize(value: number) {
        if (value) {
            this._viewportSize = Number(value);
        }
    }

    public getDeleteToolTipContent(item: ImageContourModel): string {
        if (item.isRemovable) {
            return this.translateService.instant('MOUSEOVER.DELETE');
        }
        return this.translateService.instant('MOUSEOVER.DELETE_DISABLED');
    }

    public getItemTooltipContent(item: ImageContourModel): string {
        return item.isValid ? this.addItemInfoTooltip : this.invalidItemTooltip;
    }

    scrollUp(event: Event) {
        this.scrollViewport.scrollUp();
    }

    scrollDown(event: Event) {
        this.scrollViewport.scrollDown();
    }

    editImageContour(contour: ImageContourModel) {
        if (contour.imageContourType === ImageContourType.USER_TOOL) {
            const dialogRef = this.dialogService.openDialog(ImageContourEditDialogComponent);
            dialogRef.dialogContentComponent.imageContour = new ImageContourModel(contour);
        }
    }

    deleteImageContour(contour: ImageContourModel) {
        if (contour.imageContourType === ImageContourType.USER_TOOL) {
            const dialogRef = this.dialogService.openDialog(ImageContourDeleteDialogComponent);
            dialogRef.dialogContentComponent.imageContour = new ImageContourModel(contour);
        }
    }

    showImageContourInfo(contour: ProducerImageContourModel) {
        if (contour.imageContourType === ImageContourType.PRODUCER_TOOL) {
            const dialogRef = this.dialogService.openDialog(PartnerContourInfoDialogComponent);
            dialogRef.dialogContentComponent.partnerImageContour = contour;
        }
    }

    getData(): ImageContourModel[] {
        return this.data;
    }

    getScaleFactor(): number {
        return this.foamEditorService.getCanvasService().getScaleFactor();
    }

    onItemDropped(event: CanvasImageContourCreateActionEvent) {
        this.imageContourCreateAction.execute(event);
    }

    ngOnDestroy(): void {
        this.onDestroySubject.next();
    }
}
