import { BackendService } from '../../../backend/backend.service';
import { ConfiguratorStoreService } from '../../../configurator-store.service';
import { ModelMapper } from '../../../models/model.mapper';
import { Injectable, OnDestroy } from '@angular/core';

import {
    UploadDialogComponent,
    UploadDialogContent,
    UploadDialogContentComponent
} from './upload-dialog.component';
import { BehaviorSubject, merge, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, distinctUntilChanged, map, takeWhile, tap } from 'rxjs/operators';
import { UploadCompleteEvent } from '../../../file-upload/file-upload.service';
import { DialogRefs } from '../../../dialog/dialog-refs';
import { ImageContourModel } from '../../../models/image-contour.model';
import { ImageContour } from '../../canvas/contour/image-contour';

@Injectable()
export class UploadDialogService implements OnDestroy {
    private contents: UploadDialogContent<any>[];
    private currentIndex = 0;
    readonly detectedPaperImages: DetectedPaperImage[] = [];
    readonly segmentationResults = new Map<string, SegmentationResult>();
    readonly detectedPaperImagesChanged = new BehaviorSubject<ReadonlyArray<DetectedPaperImage>>(
        null
    );

    contentChangeSubject = new BehaviorSubject<UploadDialogContent<any>>(null);
    contentValidityChange = new BehaviorSubject<boolean>(false);
    private isCurrentContentValid: boolean;

    private detectionCompleteSubject = new BehaviorSubject(false);

    contoursInfo: ContourInfo[];

    /**
     *  Segmentation results are `dirty` if the user has modified the contours.
     */
    isSegmentationResultsDirty = true;

    public isSaving = false;

    constructor(
        private http: HttpClient,
        private dialogRefs: DialogRefs<UploadDialogComponent>,
        private dataStoreService: ConfiguratorStoreService,
        private readonly backendService: BackendService
    ) {}

    public static createHttpOptions(): {
        headers: HttpHeaders;
        withCredentials: boolean;
        } {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            withCredentials: true
        };
    }

    get apiDetectionUrl(): string {
        return this.backendService.apiUrl + '/detection-jobs/';
    }

    setDialogContents(contents: UploadDialogContent<UploadDialogContentComponent>[]) {
        this.contents = contents;
        this.currentIndex = -1;
    }

    getDialogContents(): UploadDialogContent<UploadDialogContentComponent>[] | null {
        return this.contents ? Array.from(this.contents) : null;
    }

    private getNextContent(): UploadDialogContent<any> | null {
        const nextIndex = this.currentIndex + 1;
        if (this.contents && this.contents.length > 0 && nextIndex < this.contents.length) {
            const result = this.contents[nextIndex];
            this.currentIndex = nextIndex;
            return result;
        }

        return null;
    }

    private getPrevContent(): UploadDialogContent<any> | null {
        const prevIndex = this.currentIndex - 1;
        if (this.contents && this.contents.length > 0 && prevIndex > -1) {
            const result = this.contents[prevIndex];
            this.currentIndex = prevIndex;
            return result;
        }
        return null;
    }

    getContentChanged<T extends UploadDialogContentComponent>(): Observable<
        UploadDialogContent<T>
        > {
        return this.contentChangeSubject.asObservable();
    }

    loadPreviousContent() {
        if (this.getCurrentIndex() === 0) {
            this.dialogRefs.close();
        }

        const currentContent = this.getCurrentContent();
        if (currentContent) {
            currentContent.componentPortal.componentRef.instance.onLoadPrevContent();
        }

        const content = this.getPrevContent();
        this.contentChangeSubject.next(content);
    }

    resetContoursDetection() {
        // TODO cleaning up
        // TODO REMOVE all jobs ?
        this.loadPreviousContentByIndex(2);
        this.detectionCompleteSubject.next(false);
    }

    private loadPreviousContentByIndex(index: number) {
        if (this.contents[index]) {
            this.currentIndex = index;
            const content = this.contents[index];
            this.contentChangeSubject.next(content);
        }
    }

    loadNextContent() {
        const currentContent = this.getCurrentContent();
        if (currentContent) {
            if (!this.isCurrentContentValid) {
                return;
            }
            currentContent.componentPortal.componentRef.instance.onLoadNextContent();
        }
        const content = this.getNextContent();
        if (content) {
            this.contentChangeSubject.next(content);
        }
    }

    hasNextContent(): boolean {
        const nextIndex = this.currentIndex + 1;
        return this.contents && nextIndex < this.contents.length;
    }

    setContentValidity(isValid: boolean) {
        if (isValid !== this.isCurrentContentValid) {
            this.isCurrentContentValid = isValid;
            this.contentValidityChange.next(isValid);
        }
    }

    getContentValidityChange(): Observable<boolean> {
        return this.contentValidityChange.asObservable();
    }

    /**
     * Checks if the contours' data supplied by the user is valid and complete.
     */
    isDialogContentValid(): boolean {
        // TODO: should isCurrentContentValid not be enough
        const contentContainer = this.contents[this.currentIndex];
        if (contentContainer) {
            const compInstance = contentContainer.componentPortal.componentRef.instance;
            if (compInstance) {
                return compInstance.isValid();
            }
        }
        return false;
    }

    getCurrentContent(): UploadDialogContent<any> | null {
        return this.contents[this.currentIndex];
    }

    getCurrentIndex(): number {
        return this.currentIndex;
    }

    hasPreviousContent(): boolean {
        return this.contents && this.currentIndex - 1 > -1;
    }

    /**
     * Checks if the contours' data supplied by the user is valid and complete.
     */

    /* isDialogComplete(): boolean {
        return false;
    }
*/

    addUploadedImageFile(data: UploadCompleteEvent<DetectedPaperImage>) {
        this.detectedPaperImages.push({
            jobId: data.data.jobId,
            sourceImage: data.file.fileName,
            croppedPaperImage: 'data:image/png;base64,' + data.data.croppedPaperImage,
            croppedPaperWidth: data.data.croppedPaperWidth,
            croppedPaperHeight: data.data.croppedPaperHeight
        });
        this.detectedPaperImagesChanged.next(this.detectedPaperImages);
    }

    /**
     * Delete job
     * @param fileName
     */
    deleteUploadFile(fileName: string) {
        const detectedPaperImage = this.detectedPaperImages.find(
            img => img.sourceImage.toLowerCase() === fileName.toLowerCase()
        );

        if (detectedPaperImage) {
            this.deleteJob(detectedPaperImage.jobId);
        }
    }

    deleteJob(jobId: string, notifyServer: boolean = true) {
        const paperIndex = this.detectedPaperImages.findIndex(paper => paper.jobId === jobId);
        if (paperIndex > -1) {
            const detectedPaperImage = this.detectedPaperImages[paperIndex];
            this.segmentationResults.delete(detectedPaperImage.jobId);
            this.detectedPaperImages.splice(paperIndex, 1);
            this.detectedPaperImagesChanged.next(this.detectedPaperImages);

            if (notifyServer && detectedPaperImage && detectedPaperImage.jobId) {
                // TODO error handling
                const options = UploadDialogService.createHttpOptions();
                this.http
                    .delete(this.apiDetectionUrl + detectedPaperImage.jobId, options)
                    .subscribe();
            }
        }
    }

    private deleteAllJobs() {
        if (this.detectedPaperImages.length < 1) {
            return;
        }

        const jobIds = JSON.stringify(this.detectedPaperImages.map(img => img.jobId));
        const options = UploadDialogService.createHttpOptions();
        this.http.post(this.apiDetectionUrl + 'batchDelete', jobIds, options).subscribe();
    }

    startContourDetection(): Observable<SegmentationResult> {
        this.detectionCompleteSubject.next(false);
        this.isSegmentationResultsDirty = true;
        return new Observable<SegmentationResult>(observer => {
            const nextCallback = (imageInfo: SegmentationResult) => observer.next(imageInfo);
            const completeCallback = () => observer.complete();
            const errorCallback = error => observer.error(error);
            this._startContourDetectionJobs(nextCallback, completeCallback, errorCallback);
        });
    }

    isDetectionComplete(): Observable<boolean> {
        return this.detectionCompleteSubject.pipe(distinctUntilChanged());
    }

    private _startContourDetectionJobs(
        nextCallback: (result: SegmentationResult) => void,
        completeCallback: () => void,
        errorCallback: (error: any) => void
    ) {
        const jobIds = this.detectedPaperImages.map(x => x.jobId);
        const requests: Observable<SegmentationResult>[] = [];
        const options = UploadDialogService.createHttpOptions();
        for (let i = 0; i < jobIds.length; i++) {
            requests[i] = this.http
                .get<SegmentationResult>(this.apiDetectionUrl + jobIds[i] + '/contours', options)
                .pipe(
                    catchError(() => {
                        console.error('Failed to start detection job');
                        const resp: SegmentationResult = {
                            jobId: jobIds[i],
                            contours: undefined,
                            borderIndices: undefined,
                            regionPixels: undefined,
                            contoursMask: undefined,
                            failed: true
                        };
                        return of(resp);
                    })
                );
        }

        merge(...requests).subscribe(
            (resp: SegmentationResult) => {
                const paperImage = this.detectedPaperImages.find(img => img.jobId === resp.jobId);
                if (!paperImage) {
                    console.error(
                        'could not found paper image for the received segmentation result.'
                    );
                } else {
                    this.segmentationResults.set(resp.jobId, resp);
                    nextCallback(resp);
                }
            },
            error => errorCallback(error),
            () => {
                if (this.segmentationResults.size === this.detectedPaperImages.length) {
                    this.detectionCompleteSubject.next(true);
                }
                completeCallback();
            }
        );
    }

    /**
     * Get details on all detected contours.
     */
    getAllContoursInfo(): Observable<ContourInfo[]> {
        if (this.isSegmentationResultsDirty) {
            const jobIds = JSON.stringify(this.detectedPaperImages.map(img => img.jobId));

            const options = UploadDialogService.createHttpOptions();
            return this.http
                .post<ContourInfo[]>(this.apiDetectionUrl + 'batchContoursInfo', jobIds, options)
                .pipe(
                    tap(contours => (this.contoursInfo = contours.map(x => this.toContoursInfo(x))))
                );
        }

        return of<ContourInfo[]>(this.contoursInfo);
    }

    private toContoursInfo(obj: ContourInfo) {
        return {
            jobId: obj.jobId,
            contourIndex: obj.contourIndex,
            image: obj.image,
            previewImage: obj.previewImage,
            width: obj.width,
            height: obj.height,
            title: null,
            depth: 0,
            description: null,
            isValid: false
        };
    }

    refineContour(
        jobId: string,
        selectedRegions: number[],
        level: number,
        removeRegion: boolean
    ): Observable<SegmentationResult> | undefined {
        if (jobId == null) {
            throw Error('Failed to refine contour');
        }

        if (selectedRegions.length < 1) {
            return undefined;
        }

        this.isSegmentationResultsDirty = true;
        return new Observable<SegmentationResult>(observer => {
            const nextCallback = (imageInfo: SegmentationResult) => observer.next(imageInfo);
            const completeCallback = () => observer.complete();
            const errorCallback = (error: any) => observer.error(error);
            this._refineContour(
                jobId,
                selectedRegions,
                level,
                removeRegion,
                nextCallback,
                completeCallback,
                errorCallback
            );
        });
    }

    private _refineContour(
        jobId: string,
        selectedRegions: number[],
        level: number,
        removeRegion: boolean,
        nextCallback: (imageInfo: SegmentationResult) => void,
        completeCallback: () => void,
        errorCallback: (error: any) => void
    ) {
        const mode = removeRegion ? 1 : 0;
        const sendData = { regions: selectedRegions, level: level, mode: mode };

        // TODO move http requests to the DataStoreService and leave only the result handling here
        const options = UploadDialogService.createHttpOptions();
        this.http
            .patch<RefinementResult>(this.apiDetectionUrl + jobId + '/contours', sendData, options)
            .pipe(
                catchError(() => {
                    // TODO error message notification
                    console.error(`Failed to update  regions: ${JSON.stringify(selectedRegions)}`);
                    return of(null);
                })
            )
            .subscribe(
                (resp: RefinementResult) => {
                    if (!resp) {
                        throwError(`Failed to update  regions: ${JSON.stringify(selectedRegions)}`);
                    }

                    const newSegmentationResult = this.createRefinedSegmentationResult(resp);
                    if (newSegmentationResult) {
                        this.segmentationResults.set(resp.jobId, newSegmentationResult);
                        nextCallback(newSegmentationResult);
                    }
                },
                err => errorCallback(err),
                () => completeCallback()
            );
    }

    deleteContour(jobId: string, contourIdx: number): Observable<SegmentationResult> {
        const options = UploadDialogService.createHttpOptions();
        return this.http
            .delete<RefinementResult>(
                this.apiDetectionUrl + jobId + '/contour/' + contourIdx + '/delete',
                options
            )
            .pipe(
                map((resp: RefinementResult) => {
                    if (!resp) {
                        throw new Error(`Failed delete contour: ${JSON.stringify(contourIdx)}`);
                    }
                    const newSegmentationResult = this.createRefinedSegmentationResult(resp);
                    if (newSegmentationResult !== null) {
                        const idx = this.contoursInfo.findIndex(
                            c => c.jobId === resp.jobId && c.contourIndex === contourIdx
                        );
                        if (idx === -1) {
                            throw new Error(`Failed delete contour: ${JSON.stringify(contourIdx)}`);
                        }

                        this.segmentationResults.set(resp.jobId, newSegmentationResult);
                        return newSegmentationResult;
                    }
                    return null;
                }),
                takeWhile(v => v !== null)
            );
    }

    private createRefinedSegmentationResult(resp: RefinementResult): SegmentationResult | null {
        const paperImage = this.detectedPaperImages.find(img => img.jobId === resp.jobId);
        if (!paperImage) {
            console.error('could not found paper image for the received segmentation result.');
            return null;
        } else {
            const oldSegmentationResult = this.segmentationResults.get(resp.jobId);
            if (!oldSegmentationResult) {
                console.error('could not found previous segmentation result.');
                return null;
            } else {
                return {
                    jobId: resp.jobId,
                    contours: resp.contours,
                    contoursMask: resp.contoursMask,
                    regionPixels: oldSegmentationResult.regionPixels,
                    borderIndices: oldSegmentationResult.borderIndices
                };
            }
        }
    }

    saveContours(contours: ImageContourDetails[]) {
        // TODO test this
        if (!this.isDialogContentValid() && !this.isSaving) {
            return;
        }

        this.isSaving = true;

        const requests: Observable<{ ImageContour: ImageContour }>[] = [];
        const options = UploadDialogService.createHttpOptions();
        for (let i = 0; i < contours.length; i++) {
            const contourDetails = contours[i];
            const contourIndex = this.contoursInfo[i].contourIndex;
            requests[i] = this.http.post<{ ImageContour: ImageContour }>(
                this.apiDetectionUrl +
                    this.contoursInfo[i].jobId +
                    '/contour/' +
                    contourIndex +
                    '/save',
                contourDetails,
                options
            );
        }

        // TODO better handling of errors
        merge(...requests).subscribe(
            (resp: { ImageContour: ImageContour }) => {
                this.dataStoreService.addNewUserTool(new ImageContourModel(resp.ImageContour));
            },
            null,
            () => {
                this.isSaving = false;
                this.dialogRefs.close(true);
            }
        );
    }

    ngOnDestroy(): void {
        this.deleteAllJobs();
        // clear
        this.detectedPaperImages.splice(0, this.detectedPaperImages.length);
        this.detectedPaperImagesChanged.complete();
        this.segmentationResults.clear();
        this.contentChangeSubject.complete();
        this.contentValidityChange.complete();
    }
}

export interface DetectedPaperImage {
    readonly jobId: string;
    readonly croppedPaperWidth: number;
    readonly croppedPaperHeight: number;
    readonly croppedPaperImage: string;
    /** uploaded image **/
    readonly sourceImage: string;
}

/**
 * Segmentation DTO
 */
export interface SegmentationResult {
    readonly jobId: string;
    readonly contours: { x: number; y: number }[][];
    readonly contoursMask: number[];
    readonly regionPixels: number[][];
    readonly borderIndices: number[][][];
    readonly failed?: boolean;
}

export interface RefinementResult {
    jobId: string;
    contours: { x: number; y: number }[][];
    contoursMask: number[];
}

export interface ContourInfo {
    readonly jobId: string;
    readonly contourIndex: number;
    image: string;
    previewImage: string;
    width: number;
    height: number;
    title?: string;
    depth: number;
    description?: string | null;
    isValid: boolean;
}

export type ImageContourDetails = {
    title: string;
    description: string;
    depth: number;
};
