import { Observable } from 'rxjs';

import { FoamEditorService } from '../../foam-editor.service';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { Handle } from '../../shared/handle/handle';
import { Tool } from '../../shared/tool/tool';
import { PolygonSegment } from '../collision/collision-util';
import { CanvasElementChangedEvent } from '../canvas.component';
import { CanvasService } from '../canvas.service';
import { ResizeHandleCursorType, ResizeHandleSide } from '../handles/resize-handles-drawer';
import { RotationConnectorType } from '../handles/rotation-handles-drawer';

import { TextSelectionData } from './text-contour';

const setNumberFn = (val: number, defaultVal: any) => {
    return val === undefined || val === null ? defaultVal : val;
};

const setterFn = (value: any, def: any) => (value == null ? def : value);

export enum ContourType {
    TEXT,
    RECTANGLE,
    CIRCLE,
    RECESSED_GRIP,
    TOOL,
    FOAM
}

export interface ItemProperties {
    minWidth?: number;
    minHeight?: number;
    minRadius?: number;
    readonly itemPathId: string;
    readonly imagePathId: string;
    readonly marginPathId: string;
    /** TODO remove all class name. Should be provided by the UI */
    classNamePath: string;
    classNameGroup: string;
    classNameMarginPath: string;
    classNameMarginGroup: string;
    classNameItemSelected: string;
    contourFill?: string;
    marginFill?: string;
    marginOpacity?: number;
}

export interface CanvasContour {
    /** canvas contour id **/
    contourId: string; // TODO rename it to itemIndex
    contourType: ContourType;
    svgPathDefinition: string;

    globalContourPathBBox?: Rectangle2D;

    contourBoundsInParent?: Rectangle2D;
    localContourPathBBox?: Rectangle2D;
    localMarginPathBBox?: Rectangle2D;
    globalMarginPathBBox?: Rectangle2D;

    globalContourPathMatrix?: Snap.Matrix;
    globalMarginPathMatrix?: Snap.Matrix;
    localContourPathMatrix?: Snap.Matrix;
    localMarginPathMatrix?: Snap.Matrix;

    polygonLines: Array<PolygonSegment> | undefined;
    marginPolygonLines: Array<PolygonSegment> | undefined;
    itemTransformation: Snap.TransformationDescriptor | undefined;
    marginPathString: string | undefined;
    pathSegments?: Array<any> | undefined;

    /**
     * @Deprecated
     */
    marginPath: Snap.Element | undefined;

    /**
     * @Deprecated
     */
    itemElement: Snap.Element | undefined;

    isSelectable: boolean;
    isRemovable: boolean;
    /**
     * Whether the collision detection should be checked against all other contours or only the foam
     * contour
     */
    isCollidable: boolean;
    // TODO should a CuttableContour only property
    isRotatable: boolean;
    isResizable: boolean;

    depth: number;
    height: number;
    width: number;

    /* Properties */

    readonly itemProps: ItemProperties;

    /**
    attributeChanged: Observable<void>;

    //TODO add property collidable and used it instead of checking for ContourType=== GRIP
    /**
     * Checks if this contour contains the point ({@code px}, {@code py}).
     */
    contains(px: number, py: number): boolean;

    // TODO rename ot setVisible
    seVisibility(newValue: boolean): void;
    getVisibility(): boolean;

    /**
     * Creates selection handles for manipulating the figure.
     */
    createHandles(): Handle[];

    getHandlesDrawData(): ContourHandlesData;
    getSelectionData(): TextSelectionData | undefined;

    /**
     * Transforms the contour.
     *
     * @param matrix
     */
    transform?(matrix: Snap.Matrix, stopEvent?: boolean): ContourChangeData;

    translate?(dx: number, dy: number, stopEvent?: boolean): ContourChangeData;

    rotate?(
        angle: number,
        anchorX: number,
        anchorY: number,
        stopEvent?: boolean
    ): ContourChangeData;

    setCanvas(canvas: CanvasService): void;

    /**
     * Returns the specially built tool of the contour based on the mouse event target .
     *
     * @param target
     * @param editor
     * @return the specialized {@code Tool}, or null, if no tool is specified.
     */
    getTool(target: Element, editor?: FoamEditorService): Tool | null;

    containsTarget(target: Element): boolean;

    getCanvas(): CanvasService;

    /**
     * Removes the given contour item.
     * This method is invoked when a contour item has been removed.
     */

    remove(): void;

    getOnRemoved(): Observable<void>;

    /**
     * Fires that a ContourItem changed its visual representation
     * @param isTransformed true, if the visual change involves the transformation of the
     *     ContourItem
     */
    //  changed(isTransformed: boolean);

    /**
     * Emits when a ContourItem has changed its visual representation
     *
     * @return true, if the visual change involves a transformation
     */
    getOnChanged(): Observable<ContourStateData>;

    getDrawData(): ContourDrawData;

    onContourElementDrawn(drawnData: CanvasElementChangedEvent): void;

    getCollisionData(): ContourCollisionVizData | ContourCollisionVizData[];
}

export abstract class ContourDrawData implements ContourStateData {
    contourId: string;
    svgPathDefinition?: string;

    protected constructor(obj: ContourDrawData) {
        this.contourId = obj.contourId;
        this.svgPathDefinition = obj.svgPathDefinition || undefined;
    }
}

export class RotationHandleData extends ContourDrawData {
    readonly containerId: string;
    readonly visible: boolean;
    readonly gripWidth: number;
    readonly gripHeight: number;
    readonly gripRadius: number;
    readonly distanceFromGripBottomToSelectionBox: number;
    readonly distanceFromGripCenterToSelectionBox: number;
    readonly initDistanceFromGripBottomToSelectionBox: number;
    readonly connectorType: RotationConnectorType;

    constructor(obj: RotationHandleData) {
        super(obj);
        this.containerId = obj.containerId;
        this.visible = obj.visible;
        this.gripWidth = obj.gripWidth;
        this.gripHeight = obj.gripHeight;
        this.gripRadius = obj.gripRadius;
        this.distanceFromGripBottomToSelectionBox = obj.distanceFromGripBottomToSelectionBox;
        this.distanceFromGripCenterToSelectionBox = obj.distanceFromGripCenterToSelectionBox;
        this.initDistanceFromGripBottomToSelectionBox =
            obj.initDistanceFromGripBottomToSelectionBox;
        this.connectorType = obj.connectorType;
    }
}

export class ResizeHandleData extends ContourDrawData {
    readonly cursorType: ResizeHandleCursorType;
    readonly side: ResizeHandleSide;
    readonly visible: boolean;

    constructor(obj: ResizeHandleData) {
        super(obj);
        this.cursorType = obj.cursorType;
        this.side = obj.side;
        this.visible = obj.visible;
    }
}

export class ResizeHandleListData extends ContourDrawData {
    readonly containerId: string;
    readonly visible: boolean;
    readonly handles: ResizeHandleData[];

    constructor(obj: ResizeHandleListData) {
        super(obj);
        this.containerId = obj.containerId;
        this.visible = obj.visible;
        this.handles = obj.handles;
    }
}

export class ContourHandlesData {
    readonly containerId?: string;
    readonly contourId: string;
    // TODO convert it to Rectangle2D
    readonly transformBounds?: {
        readonly localContourBounds: Rectangle2D;
        readonly localMarginBounds: Rectangle2D;
        readonly selectionBoundsMatrix: Snap.Matrix;
    };

    readonly rotationHandles?: RotationHandleData;
    readonly resizeHandles?: ResizeHandleListData;

    constructor(obj: ContourHandlesData) {
        this.contourId = setterFn(obj.contourId, undefined);
        this.containerId = setterFn(obj.containerId, undefined);
        this.resizeHandles = setterFn(obj.resizeHandles, undefined);
        this.rotationHandles = setterFn(obj.rotationHandles, undefined);
        this.transformBounds = setterFn(obj.transformBounds, undefined);
        /*
        this.localContourPathBBox = setterFn(obj.localContourPathBBox, undefined);
        this.localContourPathMatrix = setterFn(obj.localContourPathMatrix, undefined);
        this.localMarginBounds = setterFn(obj.localMarginBounds, undefined);
        this.localMarginMatrix = setterFn(obj.localMarginMatrix, undefined); */
    }
}

export class ContourSelectionData {
    readonly contourId: string;
    readonly handles?: ContourHandlesData;
    readonly itemProps?: ItemProperties;
    readonly collision?: CanvasCollisionVizData;
    readonly isCollidable?: boolean;
    // TODO rename it to be different from -> ContourSelectionData
    readonly selectionData?: TextSelectionData | undefined;
    readonly visibility?: boolean;
    readonly sendTo?: 'front' | 'back';

    constructor(obj: ContourSelectionData) {
        this.contourId = obj.contourId;
        this.handles = obj.handles || undefined;
        this.itemProps = obj.itemProps || undefined;
        this.collision = obj.collision || undefined;
        this.isCollidable = obj.isCollidable;
        this.sendTo = obj.sendTo || undefined;
        this.selectionData = obj.selectionData || undefined;
        this.visibility = obj.visibility !== undefined ? obj.visibility : undefined;
    }
}

export interface CanvasCollisionVizData {
    readonly data: ContourCollisionData[];
    readonly foam?: ContourCollisionVizData;
    readonly foamInnerMargin?: ContourCollisionVizData;
}

export interface ContourCollisionData {
    readonly contourId: string;
    readonly selectedContour: MarginContourCollisionVizData;
    readonly otherContours: MarginContourCollisionVizData[];
}

export class ContourCollisionVizData {
    contourId: string;
    pathString: string;
    clipPathString: string; // used when the item is selected
    // outerClipPathString: string;
    transformString: string;
    transformString2: string;
    innerCollisionPolygonLines: Array<PolygonSegment>;
    innerCollisionBBox: Rectangle2D;
    globalContourPathMatrix: Snap.Matrix;
    localContourPathMatrix: Snap.Matrix;
    isCollidable: boolean;

    constructor(obj: ContourCollisionVizData) {
        this.contourId = obj.contourId;
        this.clipPathString = obj.clipPathString;
        // this.outerClipPathString = obj.outerClipPathString;
        this.pathString = obj.pathString;
        this.transformString = obj.transformString;
        this.transformString2 = obj.transformString2;
        this.innerCollisionPolygonLines = obj.innerCollisionPolygonLines;
        this.innerCollisionBBox = obj.innerCollisionBBox;

        this.globalContourPathMatrix = obj.globalContourPathMatrix;
        this.localContourPathMatrix = obj.localContourPathMatrix;

        this.isCollidable = obj.isCollidable;
    }
}

/**
 * For contours with an margin path
 */
export class MarginContourCollisionVizData extends ContourCollisionVizData {
    pathStringToBeClipped: string; // used by other items
    // innerPathStringToBeClipped: string;
    outerCollisionPolygonLines: Array<PolygonSegment>;
    outerCollisionBBox: Rectangle2D;
    globalMarginPathMatrix: Snap.Matrix;
    localMarginPathMatrix: Snap.Matrix;
    globalContourPathBBox: Rectangle2D;
    localContourPathBBox: Rectangle2D;

    constructor(obj: MarginContourCollisionVizData) {
        super(obj);
        this.pathStringToBeClipped = obj.pathStringToBeClipped;
        //   this.innerPathStringToBeClipped = obj.innerPathStringToBeClipped;
        this.outerCollisionPolygonLines = obj.outerCollisionPolygonLines;
        this.outerCollisionBBox = obj.outerCollisionBBox;
        this.globalMarginPathMatrix = obj.globalMarginPathMatrix;
        this.localMarginPathMatrix = obj.localMarginPathMatrix;
        this.globalContourPathBBox = obj.globalContourPathBBox;
        this.localContourPathBBox = obj.localContourPathBBox;
    }
}

export class ContourChangeData implements ContourStateData {
    contourId: string;
    transformString?: string;
    transformString2?: string;
    // TODO should we use only the matrix or leave the transformString (and other matrix related
    // value)? Pros: same value must no be derived multiple times by components (e.g. using split
    // etc) Cons: Verbose object with redudant properties
    matrix?: Snap.Matrix;
    svgPathDefinition?: string;
    marginPathString?: string;
    marginSize?: number;
    width?: number;
    height?: number;
    depth?: number;
    x?: number;
    y?: number;
    rotate?: { deltaAngle: number; anchorX: number; anchorY: number };
    translate?: { dx: number; dy: number };
    children?: ContourChangeData[];
    handles?: ContourHandlesData;
    selectionData?: TextSelectionData;
    visibility?: boolean;
    marginVisibility?: boolean;
    isDepthChanged?: boolean;

    constructor(obj: ContourChangeData) {
        // NOSONAR

        this.contourId = obj.contourId;
        this.width = obj.width;
        this.height = obj.height;
        this.depth = obj.depth;
        this.transformString = obj.transformString || undefined;
        this.transformString2 = obj.transformString2 || undefined;
        this.svgPathDefinition = obj.svgPathDefinition || undefined;
        this.marginPathString = obj.marginPathString || undefined;
        this.marginSize = obj.marginSize || undefined;
        this.matrix = obj.matrix || undefined;
        this.x = setNumberFn(obj.x, undefined);
        this.y = setNumberFn(obj.y, undefined);
        this.rotate = obj.rotate || undefined;
        this.translate = obj.translate || undefined;
        this.children = obj.children || undefined;
        this.handles = obj.handles || undefined;
        this.selectionData = obj.selectionData || undefined;
        this.visibility = obj.visibility !== undefined ? obj.visibility : undefined;
        this.isDepthChanged = obj.isDepthChanged || undefined;
    }
}

export class TextContourChangeData extends ContourChangeData {
    textContent?: string;

    constructor(obj: TextContourChangeData) {
        super(obj);
        this.textContent = obj.textContent || undefined;
    }
}

export class RubberBandDrawData {
    x: number;
    y: number;
    width: number;
    height: number;
    visible: boolean;
}

export class FoamDrawData extends ContourDrawData {
    svgPathDefinition: string;
    svgClipPathDefinition: string;
    innerMarginDrawData?: FoamInnerMarginDrawData;

    constructor(obj: FoamDrawData) {
        super(obj);
        this.svgClipPathDefinition = obj.svgClipPathDefinition;
        this.innerMarginDrawData = obj.innerMarginDrawData;
    }
}

export class FoamInnerMarginDrawData extends ContourDrawData {
    isCollidable: boolean;
    isSelectable: boolean;
    matrix?: Snap.Matrix;

    constructor(obj: FoamInnerMarginDrawData) {
        super(obj);
    }
}

export class CuttableContourDrawData extends ContourDrawData {
    image?: string;
    marginPathString: string;
    marginSize: number;
    itemProps: ItemProperties;
    isCollidable: boolean;
    isSelectable: boolean;
    matrix?: Snap.Matrix;
    sendTo?: 'back' | 'front';

    constructor(obj: CuttableContourDrawData) {
        super(obj);
        this.contourId = obj.contourId;
        this.svgPathDefinition = obj.svgPathDefinition;
        this.image = obj.image;
        this.marginPathString = obj.marginPathString;
        this.marginSize = obj.marginSize;
        this.itemProps = obj.itemProps;
        this.isCollidable = obj.isCollidable;
        this.isSelectable = obj.isSelectable;
        this.matrix = obj.matrix;
        this.sendTo = obj.sendTo || 'front';
    }
}

export class GroupContourDrawData extends ContourDrawData {
    children: CuttableContourDrawData[];

    constructor(obj: GroupContourDrawData) {
        super(obj);
        this.children = obj.children;
    }
}

export class TextCuttableContourDrawData extends CuttableContourDrawData {
    textContent: string;
    createPathString: (textBounds: Rectangle2D) => string;

    constructor(obj: TextCuttableContourDrawData) {
        super(obj);
        this.textContent = obj.textContent;
        this.createPathString = obj.createPathString;
    }
}

export interface ContourStateData {
    contourId: string;
}

export class ContourItemsRemoved {
    contourIds: string[];

    constructor(removedItemsId: string[]) {
        this.contourIds = removedItemsId;
    }
}
