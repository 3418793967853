import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ImageContourModel } from '../../../models/image-contour.model';
import { ConfiguratorStoreService } from '../../../configurator-store.service';
import { DialogRefs } from '../../../dialog/dialog-refs';

@Component({
    selector: 'app-image-contour-delete-dialog',
    templateUrl: './image-contour-delete-dialog.component.html',
    styleUrls: ['./image-contour-delete-dialog.component.scss']
})
export class ImageContourDeleteDialogComponent implements OnInit, OnDestroy {
    buttonCancelText: string;
    imageContour: ImageContourModel;
    private readonly onDestroy = new Subject<void>();

    constructor(
        public dialogRefs: DialogRefs<ImageContourDeleteDialogComponent>,
        private imageContourService: ConfiguratorStoreService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.translate.stream(['NAVIGATION_BUTTON']).subscribe(res => {
            this.buttonCancelText = res['NAVIGATION_BUTTON'].CANCEL;
        });
    }

    cancel() {
        this.dialogRefs.close();
    }

    delete() {
        this.imageContourService
            .delete(this.imageContour)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(
                () => this.dialogRefs.close(),
                err => console.error(err)
            );
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }
}
