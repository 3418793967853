/**
 * {Doc is derived from the JHotDraw}
 *
 * A Tool defines an editing mode of the foam editor {@link FoamEditorService}.
 *
 * When a tool is set on the foam editor using {@code setTool}, the foam editor
 * registers the tool as a listener for mouse and keyboard events on the {@link CanvasComponent}.
 * The tool processes these events and perform edit operations
 * on the figures of the drawing, on the drawing itself or even on the drawing
 * view.
 *
 * @author David Bimamisa
 *
 */
import { Observable, Subject } from 'rxjs';
import { FoamEditorService } from '../../foam-editor.service';
import { SelectionDragEvent, SelectionMousedownEvent } from './selection-tool';
import { SimpleDragEvent } from '../event-helpers';
import { CanvasService } from '../../canvas/canvas.service';
import { Action } from '../../actions/action';

export abstract class Tool {
    protected toolDoneSubject: Subject<void> = new Subject();
    isActive: boolean = false;

    protected inputMap: Map<string, Action>;

    constructor(protected readonly foamEditorService: FoamEditorService) {
        this.inputMap = this.createInputMap();
    }

    activate(): void {
        this.isActive = true;
    }
    deactivate(): void {
        this.isActive = false;
    }

    protected createInputMap(): Map<string, Action> {
        return undefined;
    }

    /**
     * Informs the listener that a tool has started interacting with the canvas.
     */
    abstract getToolStarted(): Observable<any>;

    /**
     * Informs the listener that a tool has done its interaction.
     * This method can be used to switch back to the default tool.
     */
    toolDone(): Observable<void> {
        return this.toolDoneSubject.asObservable();
    }

    abstract handleMouseDown(event: MouseEvent): void;
    abstract handleMouseMove(event: MouseEvent): void;
    abstract handleMouseDrag(event: SimpleDragEvent): void;
    abstract handleMouseUp(event: MouseEvent): void;
    abstract handleDbClick(event: MouseEvent): void;
    handleKeyDown(event: KeyboardEvent): void {}
    handleKeyUp(event: KeyboardEvent): void {}
}
