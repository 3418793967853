export class EventHelpers {
    public static toSelectionDragEvent(
        moveEvents: MouseEvent[],
        mouseDownEvent: MouseEvent
    ): SimpleDragEvent {
        const previousEvent = moveEvents[0];
        const currentEvent = moveEvents[1];

        const x = currentEvent.clientX;
        const y = currentEvent.clientY;

        const dx = x - previousEvent.x;
        const dy = y - previousEvent.y;
        const totalX = x - mouseDownEvent.clientX;
        const totalY = y - mouseDownEvent.clientY;

        return {
            x: x,
            y: y,
            deltaX: dx,
            deltaY: dy,
            startX: mouseDownEvent.clientX,
            startY: mouseDownEvent.clientY,
            totalDeltaX: totalX,
            totalDeltaY: totalY,
            originalEvent: currentEvent
        };
    }
}

export interface SimpleDragEvent {
    readonly x: number;
    readonly y: number;
    readonly deltaX: number;
    readonly deltaY: number;
    readonly startX: number;
    readonly startY: number;
    readonly totalDeltaX: number;
    readonly totalDeltaY: number;
    readonly originalEvent: MouseEvent;
}
