import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, OnChanges {
    @Input() type: SpinnerType = 'fadingCircle';

    @Input()
    public color: ColorType = 'blue';

    @ViewChild('inner', { static: true }) target: ElementRef<HTMLElement>;

    private isViewInitialized: boolean;

    constructor() {}

    ngOnInit() {
        this.isViewInitialized = true;
        this.updateView();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type) {
            this.updateView();
        }
    }

    updateView() {
        if (!this.isViewInitialized) {
            return;
        }

        // Select template to match type.
        let template;
        switch (this.type) {
        case 'chase':
            template = spinnerChaseTemplate;
            break;
        case 'foldingCube':
            template = spinnerFoldingCubeTemplate;
            break;
        case 'threeBounce':
            template = spinnerThreeBounceTemplate;
            break;
        case 'fadingCircleSmall':
            template = spinnerFadingCircleSmallTemplate;
            break;
        case 'fadingCircle':
        default:
            template = spinnerFadingCircleTemplate;
            break;
        }

        // Append template to view child.
        this.target.nativeElement.innerHTML = template;
        this.updateColor();
    }

    updateColor() {
        if (this.isViewInitialized && this.color) {
            const firstElem = this.target.nativeElement.firstElementChild as HTMLElement;
            if (firstElem) {
                for (const type of COLOR_TYPES) {
                    firstElem.classList.remove(`sk-spinner-${type}`);
                }
                firstElem.classList.add(`sk-spinner-${this.color}`);
            }
        }
    }
}

export type ColorType = 'blue' | 'grey';
const COLOR_TYPES = ['blue', 'grey'];

export type SpinnerType =
    | 'chase'
    | 'fadingCircle'
    | 'fadingCircleSmall'
    | 'foldingCube'
    | 'threeBounce';

export const spinnerThreeBounceTemplate = `
  <div class="sk-three-bounce">
    <div class="sk-child sk-bounce1"></div>
    <div class="sk-child sk-bounce2"></div>
    <div class="sk-child sk-bounce3"></div>
  </div>
`;

export const spinnerFadingCircleSmallTemplate = `
  <div class="sk-fading-circle sk-spinner-small">
    <div class="sk-circle1 sk-circle"></div>
    <div class="sk-circle2 sk-circle"></div>
    <div class="sk-circle3 sk-circle"></div>
    <div class="sk-circle4 sk-circle"></div>
    <div class="sk-circle5 sk-circle"></div>
    <div class="sk-circle6 sk-circle"></div>
  </div>
`;

export const spinnerFadingCircleTemplate = `
  <div class="sk-fading-circle">
    <div class="sk-circle1 sk-circle"></div>
    <div class="sk-circle2 sk-circle"></div>
    <div class="sk-circle3 sk-circle"></div>
    <div class="sk-circle4 sk-circle"></div>
    <div class="sk-circle5 sk-circle"></div>
    <div class="sk-circle6 sk-circle"></div>
    <div class="sk-circle7 sk-circle"></div>
    <div class="sk-circle8 sk-circle"></div>
    <div class="sk-circle9 sk-circle"></div>
    <div class="sk-circle10 sk-circle"></div>
    <div class="sk-circle11 sk-circle"></div>
    <div class="sk-circle12 sk-circle"></div>
  </div>
`;

export const spinnerFoldingCubeTemplate = `
  <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div>
`;

export const spinnerChaseTemplate = `
<div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>`;
