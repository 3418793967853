/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/button/button.component.ngfactory";
import * as i3 from "../../shared/button/button.component";
import * as i4 from "../../shared/icon/icon.component.ngfactory";
import * as i5 from "../../shared/icon/icon.component";
import * as i6 from "@angular/common";
import * as i7 from "./dialog-header.component";
import * as i8 from "../dialog-refs";
var styles_DialogHeaderComponent = [i0.styles];
var RenderType_DialogHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DialogHeaderComponent, data: {} });
export { RenderType_DialogHeaderComponent as RenderType_DialogHeaderComponent };
function View_DialogHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-button", [["app-icon-button", ""], ["class", "modal-close-btn btn-border-radiusless"]], [[2, "text-before-icon", null], [2, "text-after-icon", null], [2, "text-before-after-icon", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 4243456, null, 0, i3.ButtonComponent, [i1.ElementRef], { color: [0, "color"], buttonSize: [1, "buttonSize"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-icon", [["cssHeight", "16px"], ["cssWidth", "16px"], ["iconSize", "small"], ["shape", "icon-close"]], null, null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(3, 114688, null, 0, i5.IconComponent, [i1.ElementRef], { shape: [0, "shape"], iconSize: [1, "iconSize"], cssWidth: [2, "cssWidth"], cssHeight: [3, "cssHeight"] }, null)], function (_ck, _v) { var currVal_3 = "tertiary"; var currVal_4 = "x-small"; _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = "icon-close"; var currVal_6 = "small"; var currVal_7 = "16px"; var currVal_8 = "16px"; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isBefore(); var currVal_1 = i1.ɵnov(_v, 1).isAfter(); var currVal_2 = i1.ɵnov(_v, 1).isBeforeAndAfter(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_DialogHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-card-title"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.closable; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_DialogHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["class", "modal-card-head"]], null, [["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DialogHeaderComponent_0, RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i7.DialogHeaderComponent, [i8.DialogRefs], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogHeaderComponentNgFactory = i1.ɵccf("app-dialog-header", i7.DialogHeaderComponent, View_DialogHeaderComponent_Host_0, { closable: "closeable" }, {}, ["*"]);
export { DialogHeaderComponentNgFactory as DialogHeaderComponentNgFactory };
