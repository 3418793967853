import { Injectable } from '@angular/core';
import { Action, ActionEvent } from './action';
import { FoamEditorService } from '../foam-editor.service';
import { CanvasService } from '../canvas/canvas.service';

@Injectable()
export class ZoomAction implements Action {
    private readonly canvasService: CanvasService;

    constructor(private readonly foamEditor: FoamEditorService) {
        this.canvasService = this.foamEditor.getCanvasService();
    }

    execute(event: ZoomActionEvent) {
        const oldScaleFactor = this.canvasService.getScaleFactor();
        if (oldScaleFactor) {
            const newScaleFactor = Number((oldScaleFactor + event.zoomStep / 100).toFixed(2));
            this.canvasService.setScaleFactor(newScaleFactor);
        }
    }
}

export interface ZoomActionEvent extends ActionEvent {
    zoomStep: number;
}
