import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    animationFrameScheduler,
    BehaviorSubject,
    fromEvent,
    merge,
    Observable,
    Subject
} from 'rxjs';
import {
    auditTime,
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    take,
    takeUntil,
    tap,
    throttleTime
} from 'rxjs/operators';
import { ScrollViewportComponent } from '../../../../scroll-viewport/scroll-viewport.component';
import { UploadDialogContentComponent } from '../../upload-dialog.component';
import {
    DetectedPaperImage,
    SegmentationResult,
    UploadDialogService
} from '../../upload-dialog.service';
import {
    BackgroundImageInfo,
    ContourSegmentationResult,
    DetectionResultCanvasComponent,
    EditMode,
    RegionSelection
} from './detection-result-canvas/detection-result-canvas.component';
import { ScaleFactorState } from '../../../../canvas/shared/scalefactor-state';
import { ZoomActionEvent } from '../../../../actions/zoom.action';
import { SelectionMode } from './paint-selection/paint-selection';
import { SelectionCursorDrawer } from './selection-cursor-drawer';

@Component({
    selector: 'app-contour-detection-editor',
    templateUrl: './detection-result.component.html',
    styleUrls: ['./detection-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetectionResultComponent
implements UploadDialogContentComponent, OnInit, AfterViewInit, OnDestroy {
    private beforeAttachComponent: Subject<void> = new Subject();

    @ViewChild(DetectionResultCanvasComponent, { static: false })
    private detectionResultCanvasComponent: DetectionResultCanvasComponent;

    @ViewChild('imagesScrollViewport', { static: false })
    private readonly imagesScrollViewport: ScrollViewportComponent;

    @ViewChild('contourScrollViewport', { static: false })
    private readonly contoursScrollableDirective: ScrollViewportComponent;

    @ViewChild('follower', { static: false })
    private readonly follower: ElementRef<HTMLElement>;

    paperImages: ReadonlyArray<DetectedPaperImage>;
    selectedPaperImage: BackgroundImageInfo;
    private paperImageChangedSubject = new Subject<BackgroundImageInfo>();
    readonly paperImageChanged: Observable<BackgroundImageInfo>;

    paperImagesCount: number;
    selectedImageIndex: number = 0;
    // selectedSegmentationResult: ContourDetectionResult;
    private readonly onDestroy = new Subject<void>();
    private contourDetectionResultSubject = new Subject<ContourSegmentationResult>();
    readonly contourDetectionResultChanged: Observable<ContourSegmentationResult>;

    // selectedUploadImage: UploadedContour[];

    currentEditMode: EditMode;
    showZoomPanel: boolean;
    zoomValue: number;
    zoomStep: number = 10;

    isNextButtonDisabled: boolean = true;
    zoomMinValue: number;

    // isLoadingPaperImage: boolean;
    readonly isLoadingSegmentationResult = new Subject<boolean>();
    readonly isLoadingPaperImage = new Subject<boolean>();
    loadingIndicatorWidth: number = 0;
    loadingIndicatorHeight: number = 0;
    canScrollImagesUp = false;
    canScrollImagesDown = false;

    private selectionCursorDrawer: SelectionCursorDrawer;
    private selectedContoursContainer: HTMLElement;
    private selectionRadius: number = 15;
    private selectionRadiusChangedSubject = new BehaviorSubject<number>(15);
    readonly selectionRadiusChanged: Observable<number>;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private uploadDialogService: UploadDialogService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.contourDetectionResultChanged = this.contourDetectionResultSubject.pipe(
            takeUntil(this.onDestroy),
            distinctUntilChanged()
        );

        this.selectionRadiusChanged = this.selectionRadiusChangedSubject.pipe(
            takeUntil(this.onDestroy),
            distinctUntilChanged()
        );
        this.paperImageChanged = this.paperImageChangedSubject.pipe(
            takeUntil(this.onDestroy),
            distinctUntilChanged((a, b) => a.image === b.image)
        );

        this.paperImageChanged.subscribe(() => {
            // hide previous contour
            if (this.detectionResultCanvasComponent) {
                this.detectionResultCanvasComponent.hideSVGContours();
            }
        });
    }

    ngOnInit() {
        this.isLoadingPaperImage.next(true);
        this.showZoomPanel = false;
        this.currentEditMode = EditMode.MOVE;
        this.getUploadImageFiles();
        this.enableLoadingCursor();
        this.selectionCursorDrawer = new SelectionCursorDrawer();
    }

    ngAfterViewInit(): void {
        merge(
            this.imagesScrollViewport.renderedContentChange,
            this.imagesScrollViewport.elementScrolled()
        )
            .pipe(
                takeUntil(this.onDestroy),
                // Collect multiple events into one until the next animation frame
                auditTime(0, animationFrameScheduler)
            )
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.canScrollImagesUp = this.imagesScrollViewport.canScrollTo('top');
                    this.canScrollImagesDown = this.imagesScrollViewport.canScrollTo('bottom');
                    // We must call markForCheck since we are using the OnPush strategy
                    this.changeDetectorRef.markForCheck();
                });
            });

        this.selectedContoursContainer = this.elementRef.nativeElement.querySelector(
            '.selected-contours-image'
        );
        this.selectedContoursContainer.addEventListener('wheel', this.updateSelectionRadius);
        this.updateEditCursor();
    }

    scrollImagesUp($event: MouseEvent) {
        this.imagesScrollViewport.scrollUp();
    }

    scrollImagesDown(event: Event) {
        this.imagesScrollViewport.scrollDown();
    }

    private enableLoadingCursor() {
        this.isLoadingSegmentationResult.subscribe(isLoading => {
            if (isLoading) {
                if (this.selectedContoursContainer) {
                    this.selectedContoursContainer.style.cursor = 'progress';
                }
                window.document.body.style.cursor = 'progress';
            } else {
                this.updateEditCursor();
                document.body.style.cursor = 'auto';
            }
        });
    }

    changeEditMode(event: Event) {
        const inputElemVal = (event.target as HTMLInputElement).value;
        if (inputElemVal === 'add-regions') {
            this.currentEditMode = EditMode.ADD;
        } else if (inputElemVal === 'remove-regions') {
            this.currentEditMode = EditMode.REMOVE;
        } else if (inputElemVal === 'move-tool') {
            this.currentEditMode = EditMode.MOVE;
        }
        this.updateEditCursor();
    }

    private updateEditCursor() {
        this.selectionCursorDrawer.drawCursor(
            this.selectedContoursContainer,
            this.selectionRadius,
            this.currentEditMode
        );
    }

    scaleFactorChanged(state: ScaleFactorState) {
        this.zoomStep = 10 * state.scaleFactorToFitViewport;
        this.zoomValue = Math.round(state.scaleFactor * 100);
        this.zoomMinValue = Math.round(state.scaleFactorToFitViewport * 100);
        this.loadingIndicatorWidth = this.selectedPaperImage.width * state.scaleFactor;
        this.loadingIndicatorHeight = this.selectedPaperImage.height * state.scaleFactor;
    }

    zoom(event: ZoomActionEvent) {
        if (this.detectionResultCanvasComponent) {
            this.detectionResultCanvasComponent.zoom(event.zoomStep);
        }
    }

    isValid(): Observable<boolean> {
        return this.uploadDialogService.isDetectionComplete().pipe(
            map(() => {
                const it = this.uploadDialogService.segmentationResults.values();
                // check if alt-least one item is valid
                for (const item of it) {
                    if (!item.failed) {
                        return true;
                    }
                }
                return false;
            })
        );
    }

    private getUploadImageFiles() {
        // TODO add .pipe(takeUntil(this.destroySubject))
        this.uploadDialogService.detectedPaperImagesChanged
            .pipe(takeUntil(this.onDestroy))
            .subscribe((images: ReadonlyArray<DetectedPaperImage>) => {
                this.paperImages = images;
                this.paperImagesCount = images.length;
            });
    }

    selectRegions($event: RegionSelection) {
        const isRemoveRegion = $event.selectionMode === SelectionMode.REMOVE;
        const selectedPaper = this.paperImages[this.selectedImageIndex];
        this.isLoadingSegmentationResult.next(true);
        this.uploadDialogService
            .refineContour(selectedPaper.jobId, $event.regions, $event.level, isRemoveRegion)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(
                (segmentationResult: SegmentationResult) => {
                    this.contourDetectionResultSubject.next(segmentationResult);
                    this.isLoadingSegmentationResult.next(false);
                },
                () => {
                    this.isLoadingSegmentationResult.next(false);
                    console.error(`Failed to update regions: ${JSON.stringify($event.regions)}`);
                }
            );
    }

    onAttached(): void {
        this.beforeAttachComponent.next();
        this.isLoadingPaperImage.next(true);
        this.isLoadingSegmentationResult.next(true);
        this.showZoomPanel = false;

        this.selectPaperImage(0, false);

        this.uploadDialogService
            .startContourDetection()
            .pipe(takeUntil(this.onDestroy))
            .subscribe((segmentationResult: SegmentationResult) => {
                const selectedPaperImage = this.paperImages[this.selectedImageIndex];
                if (selectedPaperImage && selectedPaperImage.jobId === segmentationResult.jobId) {
                    if (!segmentationResult.failed) {
                        this.contourDetectionResultSubject.next(segmentationResult);
                    }
                    this.isLoadingSegmentationResult.next(false);
                }
            });
    }

    onLoadNextContent() {}

    onLoadPrevContent() {}

    selectPaperImage(imageIndex: number, loadSegmentationResult?: boolean) {
        // skip if the image is already selected expect when the dialog is attached for the first time,
        // .i.e. selectedPaperImage equals null
        if (
            loadSegmentationResult &&
            this.selectedImageIndex === imageIndex &&
            this.selectedPaperImage != null
        ) {
            this.isLoadingPaperImage.next(false);
            return;
        }

        this.selectedImageIndex = Number(imageIndex);
        if (this.paperImages[this.selectedImageIndex]) {
            const paperImage = this.paperImages[this.selectedImageIndex];
            if (paperImage) {
                // enable the DetectionResultCanvasComponent first, to ensure that
                // the observable-based update on the backgroundImage and segmentationResult properties
                // is received by the DetectionResultCanvasComponent component
                this.isLoadingPaperImage.next(false);

                this.selectedPaperImage = {
                    image: paperImage.croppedPaperImage,
                    width: paperImage.croppedPaperWidth,
                    height: paperImage.croppedPaperHeight
                };
                // Wait for isLoadingPaperImage to be applied such that it displays the
                // DetectionResultCanvas component, before setting its properties
                this.ngZone.onStable.pipe(take(1)).subscribe(() => {
                    // This ensures that we are in angular zone
                    this.ngZone.run(() => {
                        this.paperImageChangedSubject.next(this.selectedPaperImage);

                        if (loadSegmentationResult) {
                            const segmentationResult = this.uploadDialogService.segmentationResults.get(
                                paperImage.jobId
                            );

                            // this.selectedSegmentationResult = segmentationResult;
                            this.contourDetectionResultSubject.next(segmentationResult);
                        }
                    });
                });

                this.showZoomPanel = true;
            } else {
                this.isLoadingPaperImage.next(true);
            }
        }
    }

    ngOnDestroy(): void {
        if (this.beforeAttachComponent) {
            this.beforeAttachComponent.complete();
        }
        this.onDestroy.next();
        this.onDestroy.complete();
        document.body.style.cursor = 'auto';
    }

    /**
     * For the provisional modification of the radius
     * @param event
     */
    private updateSelectionRadius = (event: WheelEvent) => {
        if (event.ctrlKey === true) {
            event.preventDefault();

            const delta = Math.sign(event.deltaY);
            let newRadius = this.selectionRadius + delta;
            newRadius = Math.min(48, Math.max(5, newRadius));

            if (newRadius !== this.selectionRadius) {
                this.selectionRadius = newRadius;
                this.selectionRadiusChangedSubject.next(this.selectionRadius);
                this.ngZone.runOutsideAngular(() => {
                    this.selectionCursorDrawer.drawCursor(
                        this.selectedContoursContainer,
                        this.selectionRadius,
                        this.currentEditMode
                    );
                });
            }
        }
    };
}
