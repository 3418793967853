import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContourInfo, SegmentationResult, UploadDialogService } from '../../upload-dialog.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../shared/form/validators';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UploadDialogContentComponent } from '../../upload-dialog.component';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { ScrollViewportComponent } from '../../../../scroll-viewport/scroll-viewport.component';
import { CanvasService } from '../../../../canvas/canvas.service';

@Component({
    selector: 'app-contour-configuration',
    templateUrl: './contour-configuration.component.html',
    styleUrls: ['./contour-configuration.component.scss']
})
export class ContourConfigurationComponent
implements UploadDialogContentComponent, AfterViewInit, OnDestroy {
    private beforeAttachComponent: Subject<void> = new Subject();
    private isFormValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isFormValid: Observable<boolean> = this.isFormValidSubject.pipe(distinctUntilChanged());

    contoursForms: FormArray;
    currentContourFormGroup: FormGroup;

    canScrollContoursLeft = false;
    canScrollContoursRight = false;

    @ViewChild('contourScrollViewport', { static: false })
    contoursScrollViewport: ScrollViewportComponent;

    contourConnectorPath: string;

    selectedContour: ContourInfo;

    /* The index of the selected contour */
    selectedContourIndex = 0;
    contours: ContourInfo[];

    private onDestroySubject = new Subject<void>();

    constructor(
        private uploadDialogService: UploadDialogService,
        private canvasService: CanvasService,
        private ngZone: NgZone
    ) {}

    ngAfterViewInit() {
        merge(
            this.contoursScrollViewport.renderedContentChange,
            this.contoursScrollViewport.elementScrolled()
        )
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.canScrollContoursLeft = this.contoursScrollViewport.canScrollTo('left');
                    this.canScrollContoursRight = this.contoursScrollViewport.canScrollTo('right');
                });
            });
    }

    ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
        if (this.beforeAttachComponent) {
            this.beforeAttachComponent.complete();
        }
    }

    isValid(): Observable<boolean> {
        return this.isFormValid;
    }

    onAttached(): void {
        this.beforeAttachComponent.next();
        this.selectedContourIndex = 0;
        this.setContoursForm();
    }

    onLoadNextContent(): void {
        if (this.contoursForms && this.contoursForms.valid) {
            const values = this.contoursForms.getRawValue();
            this.uploadDialogService.saveContours(values);
            // Disable button while saving
            if (this.uploadDialogService.isSaving) {
                this.isFormValidSubject.next(false);
            }
        }
    }

    onLoadPrevContent(): void {}

    selectContour(contourIndex: number) {
        if (!this.contours || this.contours.length < 1) {
            return;
        }

        this.selectedContourIndex = contourIndex;
        this.selectedContour = this.contours[contourIndex];
        const contourForm = <FormGroup> this.contoursForms.at(contourIndex);
        if (contourForm) {
            this.currentContourFormGroup = contourForm;
        }
        this.updateContourConnector();
    }

    private setContoursForm() {
        this.uploadDialogService.getAllContoursInfo().subscribe((contours: ContourInfo[]) => {
            this.contours = contours;
            const contourGroupForms = this.contours.map(contour =>
                this.createContourInfoForm(contour)
            );
            this.contoursForms = new FormArray(contourGroupForms);
            this.contoursForms.statusChanges
                .pipe(takeUntil(this.beforeAttachComponent))
                .subscribe(status => this.isFormValidSubject.next(status === 'VALID'));

            this.selectContour(0);
            this.uploadDialogService.isSegmentationResultsDirty = false;
        });
    }

    private createContourInfoForm(contour: ContourInfo) {
        const groupForm = {
            title: new FormControl(contour.title, [Validators.required, Validators.maxLength(20)]),
            description: new FormControl(contour.description),
            depth: new FormControl(contour.depth, [
                Validators.required,
                CustomValidators.positiveInteger,
                Validators.min(this.canvasService.getPropertiesValue().minContourDepth)
            ])
        };
        const contourForm = new FormGroup(groupForm);
        contourForm.statusChanges
            .pipe(takeUntil(this.beforeAttachComponent))
            .subscribe(() => (contour.isValid = contourForm.valid));

        return contourForm;
    }

    scrollContoursUp(event: Event) {
        this.contoursScrollViewport.scrollUp();
        this.updateContourConnector();
    }

    scrollContoursDown(event: Event) {
        this.contoursScrollViewport.scrollDown();
        this.updateContourConnector();
    }

    private updateContourConnector() {
        if (!this.contoursScrollViewport) {
            return;
        }
        const scrollLeft = this.contoursScrollViewport.getScrollOffset();
        // 62 = itemSize + itemSpace (see <app-scroll-viewport> in HTML template)
        const imagePosX = this.selectedContourIndex * 62;

        if (imagePosX < scrollLeft) {
            this.selectContour(this.selectedContourIndex + 1);
        } else if (imagePosX > scrollLeft + this.contoursScrollViewport.getViewportLength()) {
            this.selectContour(this.selectedContourIndex - 1);
        } else {
            // 202 = first item
            const lineLength = 214 + imagePosX - scrollLeft;
            this.contourConnectorPath = `M0 30 H${lineLength} V-22`;
        }
    }

    deleteContour(selectedContourIndex: number) {
        const contourToDelete = this.contours[selectedContourIndex];
        if (!contourToDelete) {
            console.warn('Could not found contourToDelete');
            return;
        }
        this.uploadDialogService
            .deleteContour(contourToDelete.jobId, contourToDelete.contourIndex)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((resp: SegmentationResult) => {
                // FIXME SegmentationResultsDirty should be called by the service?
                this.uploadDialogService.isSegmentationResultsDirty = true;
                this.uploadDialogService
                    .getAllContoursInfo()
                    .subscribe((contours: ContourInfo[]) => {
                        this.contours = contours;
                        // this.contours.splice(selectedContourIndex, 1);
                        this.contoursForms.removeAt(selectedContourIndex);

                        if (contours && contours.length === 0) {
                            this.uploadDialogService.resetContoursDetection();
                            return;
                        }
                        const pevIndex = Math.max(0, selectedContourIndex - 1);
                        this.selectContour(pevIndex);
                    });
            });
    }
}
