import {
    Component,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { DialogRefs } from '../dialog-refs';

@Component({
    selector: 'app-dialog-content',
    templateUrl: './dialog-content.component.html',
    styleUrls: ['./dialog-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'modal-card-body'
    }
})
export class DialogContentComponent<T> implements OnInit {
    // @HostBinding('class.opened')
    // opened: boolean = false;

    @Input('closeable')
    closable: boolean = true;

    /*  @Output('openChanged')
     openChanged: EventEmitter<boolean> = new EventEmitter<boolean>(false);*/
    /** dialogRef of the parent component which is injected by the {@link DialogService}**/
    dialogRefs: DialogRefs<T>;

    constructor(dialogRefs: DialogRefs<T>) {
        this.dialogRefs = dialogRefs;
    }

    ngOnInit() {}

    @HostListener('body:keyup.escape')
    close() {
        this.dialogRefs.close();
    }
}
