import { Injectable, OnDestroy, QueryList } from '@angular/core';
import { ExpansionPanelComponent } from './expansion-panel.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { AccordionItemDirective } from './accordion-item.directive';
import { UniqueSelectionDispatcher } from '../../../shared/collections/unique-selection-dispatcher';

/**
 * This class coordinates the navigation between the expansion panels of an
 * {@link ExpansionPanelComponent}
 */
@Injectable()
export class AccordionNavigator implements OnDestroy {
    private accordionItems: Map<number, AccordionItemDirective> = new Map();

    private _expandedIndex: number = undefined;
    private expansionPanels: QueryList<ExpansionPanelComponent>;
    private readonly subscriptions: Subscription[] = [];

    private accordionExpandedEventSource: Subject<number> = new Subject();
    onAccordionItemExpanded: Observable<number> = this.accordionExpandedEventSource.asObservable();

    constructor(private _expansionDispatcher: UniqueSelectionDispatcher) {
        // QUICK workaround
        this._removeUniqueSelectionListener = _expansionDispatcher.listen(
            (id: string, accordionId: string) => {
                // TODO find out why this is null
                if (!this.expansionPanels) {
                    return;
                }
                const panelIndex = this.expansionPanels.toArray().findIndex(p => p.id === id);
                if (panelIndex > -1 && this.accordionItems.has(panelIndex)) {
                    this.accordionExpandedEventSource.next(panelIndex);
                }
            }
        );
    }

    private _removeUniqueSelectionListener: () => void = () => {};

    /**
     * Adds expansion panels through which users should be able to navigate
     * @param expansionPanels
     */
    setExpansionPanels(expansionPanels: QueryList<ExpansionPanelComponent>): void {
        this.expansionPanels = expansionPanels;
        // Add expansion items to navigate trough
        this.addAccordionItems(this.expansionPanels.toArray());

        // Update the accordion items when the state of one have changed
        this.expansionPanels.changes.subscribe(() => {
            this.addAccordionItems(this.expansionPanels.toArray());
        });
    }

    // TODO write test cases: adding same item twice
    private addAccordionItems(
        panels: ExpansionPanelComponent[] /*, index: number, state: AccordionItemState */
    ) {
        // clear observables
        this.accordionItems.clear();
        // unsubscribe
        this.subscriptions.forEach(sub => sub.unsubscribe());

        panels
            .filter(p => !p.disabled)
            .forEach((panel, index) => {
                // initialize expandedIndex to the initially expanded panel
                if (panel.expanded) {
                    this._expandedIndex = index;
                }

                if (!(this.accordionItems.get(index) === panel)) {
                    this.accordionItems.set(index, panel);

                    this.subscriptions.push(
                        panel.opened.subscribe(() => {
                            this.setExpandedIndex(index);
                        })
                    );
                }
            });
    }

    private setExpandedIndex(value: number) {
        if (value !== undefined && value !== this._expandedIndex) {
            this._expandedIndex = value;
        }
    }

    getExpandedIndex(): number {
        return this._expandedIndex;
    }

    getExpandedItemSize(): number {
        return this.accordionItems.size;
    }

    /**
     * Navigates (or expands) the previous expansion panel
     * @returns {NavigateResult} the navigation result, otherwise {undefined} if the
     * current the item was not found.
     */
    expandPreviousItem(): NavigateResult | undefined {
        let prevIndex: number;

        if (this._expandedIndex > 0) {
            prevIndex = this._expandedIndex - 1;
        } else {
            // we have reach the last accordion
            return { panelId: undefined, endReached: true };
        }

        if (this._expandedIndex === prevIndex) {
            return { panelId: undefined, alreadyOpened: true };
        }

        const prevExpansionItem = this.accordionItems.get(prevIndex);
        // expand prevAccordion
        if (prevExpansionItem) {
            prevExpansionItem.expanded = true;
            return { panelId: prevExpansionItem.id };
        }

        // TODO should we throw Error instead
        console.error('Could not find the expansion panel with index ' + prevIndex);
        return undefined;
    }

    /**
     * Navigates (or expands) the next expansion panel
     */
    expandNextItem(): NavigateResult | undefined {
        let nextIndex: number;
        const lastIndex = this.accordionItems.size - 1;

        if (this._expandedIndex < 0) {
            // clamp curIndex
            nextIndex = 0;
        } else {
            nextIndex = this._expandedIndex + 1;
        }

        if (this._expandedIndex === nextIndex) {
            return { panelId: undefined, alreadyOpened: true };
        }

        if (nextIndex > lastIndex) {
            return { panelId: undefined, endReached: true };
        }

        const nextExpansionItem = this.getNextActiveItem(nextIndex);

        if (nextExpansionItem) {
            nextExpansionItem.expanded = true;
            return { panelId: nextExpansionItem.id };
        }

        // TODO should we throw Error instead
        console.error('Could not find the expansion panel with index ' + nextIndex);
        return undefined;
    }

    expandLastItem() {
        const lastIndex = this.accordionItems.size - 1;
        const lastItem = this.getNextActiveItem(lastIndex);
        if (lastItem) {
            lastItem.expanded = true;
        }
    }

    private getNextActiveItem(soughNextIndex: number): AccordionItemDirective {
        let startIndex = soughNextIndex;
        const len = this.accordionItems.size;
        while (startIndex < len) {
            const nextExpansionItem = this.accordionItems.get(startIndex);
            if (nextExpansionItem) {
                return nextExpansionItem;
            }
            startIndex++;
        }

        // TODO unit test
        return undefined;
    }

    ngOnDestroy(): void {
        this.accordionItems.clear();
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions.length = 0;
        this._removeUniqueSelectionListener();
        this.accordionExpandedEventSource.complete();
    }
}

export interface NavigateResult {
    panelId: string | undefined;
    alreadyOpened?: boolean;
    endReached?: boolean;
}
