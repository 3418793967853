import { NgModule } from '@angular/core';
import { CanvasScrollViewportDirective } from './canvas-scroll-viewport.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [CanvasScrollViewportDirective],
    exports: [CanvasScrollViewportDirective]
})
export class CanvasSharedModule {}
