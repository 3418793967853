import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiError, ApiErrorCode } from './api-error';

const ERROR_MSG_MAX_FILE_SIZE = '10MB';

@Injectable({
    providedIn: 'root'
})
export class ApiErrorService {
    constructor(private translateService: TranslateService) {}

    getErrorMessage(err: ApiError): ClientErrorMessage {
        const clientCode = this.getClientErrorCode(err.errorCode);
        const key = `ERRORS.${clientCode}`;

        return {
            message: this.translateService.instant(key, this.getInterpolateParams(clientCode)),
            clientCode: clientCode
        };
    }

    private getClientErrorCode(errorCode: ApiErrorCode): ClientErrorCode {
        switch (errorCode) {
        case ApiErrorCode.PAPER_DETECTION_FAILED:
        case ApiErrorCode.MARKER_DETECTION_FAILED:
            return ClientErrorCode.PAPER_DETECTION_FAILED;
        case ApiErrorCode.CONTOUR_DETECTION_FAILED:
            return ClientErrorCode.CONTOUR_DETECTION_FAILED;
        case ApiErrorCode.INVALID_IMAGE:
            return ClientErrorCode.INVALID_IMAGE;
        case ApiErrorCode.IMAGE_SIZE_LIMIT_EXCEEDED:
            return ClientErrorCode.IMAGE_SIZE_LIMIT_EXCEEDED;
        case ApiErrorCode.INTERNAL_ERROR:
        case ApiErrorCode.JOB_ALREADY_EXISTS:
        case ApiErrorCode.JOB_NOT_FOUND:
        case ApiErrorCode.REFINEMENT_FAILED:
        case ApiErrorCode.NATIVE_INTERNAL_ERROR:
        default:
            return ClientErrorCode.GENERAL;
        }
    }

    private getInterpolateParams(clientCode: ClientErrorCode): Object | undefined {
        if (clientCode === ClientErrorCode.IMAGE_SIZE_LIMIT_EXCEEDED) {
            return { maxFileSize: ERROR_MSG_MAX_FILE_SIZE };
        }
        return undefined;
    }
}

export interface ClientErrorMessage {
    clientCode: ClientErrorCode;
    message: string;
}

export enum ClientErrorCode {
    GENERAL = 'GENERAL',
    PAPER_DETECTION_FAILED = 'PAPER_DETECTION_FAILED',
    INVALID_IMAGE = 'INVALID_IMAGE',
    CONTOUR_DETECTION_FAILED = 'CONTOUR_DETECTION_FAILED',
    IMAGE_SIZE_LIMIT_EXCEEDED = 'IMAGE_SIZE_LIMIT_EXCEEDED'
}
