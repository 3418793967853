<div class="main-toolbar__title">{{ panelIndex }}. {{ panelTitle }}</div>

<div class="dialog__title">
    <div class="dialog__title-text">{{ dialogTitle }}</div>
    <app-button
        app-icon-button
        [color]="'tertiary'"
        [buttonSize]="'x-small'"
        (click)="closeDialog()"
    >
        <app-icon shape="icon-close" iconSize="small" cssWidth="16px" cssHeight="16px"></app-icon>
    </app-button>
</div>

<div class="ribbon-dialog__content">
    <ul class="alignment-list">
        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                [iconAlign]="'stretched'"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('LEFT')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-left"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_LEFT' | translate }}
            </app-button>
        </li>

        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                iconAlign="stretched"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('RIGHT')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-right"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_RIGHT' | translate }}
            </app-button>
        </li>

        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                iconAlign="stretched"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('TOP')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-top"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_TOP' | translate }}
            </app-button>
        </li>

        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                iconAlign="stretched"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('BOTTOM')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-bottom"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_BOTTOM' | translate }}
            </app-button>
        </li>

        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                iconAlign="stretched"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('HORIZONTAL_CENTER')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-horizontal-center"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_HORIZONTAL_CENTER' | translate }}
            </app-button>
        </li>

        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                iconAlign="stretched"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('VERTICAL_CENTER')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-vertical-center"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_VERTICAL_CENTER' | translate }}
            </app-button>
        </li>

        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                iconAlign="stretched"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('HORIZONTAL_SPREAD')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-horizontal-spread"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_HORIZONTAL_SPREAD' | translate }}
            </app-button>
        </li>

        <li>
            <app-button
                app-icon-button
                class="btn-border-radiusless"
                [color]="'secondary'"
                iconAlign="stretched"
                textPosition="after"
                [buttonSize]="'normal'"
                (click)="alignItems('VERTICAL_SPREAD')"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    cssWidth="30px"
                    cssHeight="30px"
                    shape="icon-align-vertical-spread"
                    iconSize="small"
                ></app-icon>
                {{ 'ALIGNMENT_RIBBON.BUTTON_ALIGN_VERTICAL_SPREAD' | translate }}
            </app-button>
        </li>
    </ul>

    <p class="action-info-text">
        {{ 'ALIGNMENT_RIBBON.INFO_TEXT' | translate }}
    </p>
</div>
