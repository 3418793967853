import { PartialCuttingWarningDialogComponent } from '../dialogs/partial-cutting-warning-dialog/partial-cutting-warning-dialog.component';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';

import { CanvasService } from '../canvas/canvas.service';
import { CircleContour } from '../canvas/contour/circle-contour';
import { CuttableContour } from '../canvas/contour/cuttable-contour';
import { ImageContour } from '../canvas/contour/image-contour';
import { RecessedGripContour } from '../canvas/contour/recessed-grip-contour';
import { RectangleContour } from '../canvas/contour/rectangle-contour';
import { TextContour } from '../canvas/contour/text-contour';
import {
    ContourDepthPromptDialogComponent,
    DepthPromptResponse
} from '../dialogs/contour-depth-prompt-dialog/contour-depth-prompt-dialog.component';
import { ContourStartDimensions, FoamEditorService } from '../foam-editor.service';
import { TextEditingTool } from '../shared/tool/text-editing-tool';
import { ImageContourModel } from '../../models/image-contour.model';
import { DialogService } from '../../dialog/dialog.service';
import { CreationEditAction } from '../undo/creation-edit-action';

import { Action, ActionEvent } from './action';

declare var Snap: any;

export abstract class ContourCreateAction implements Action {
    protected canvasService: CanvasService;
    protected foamEditorService: FoamEditorService;

    constructor(foamEditorService: FoamEditorService) {
        this.foamEditorService = foamEditorService;
        this.canvasService = this.foamEditorService.getCanvasService();
    }

    abstract execute(event?: ActionEvent): void;

    protected abstract createContour(
        event: CanvasContourCreateActionEvent
    ): Observable<CuttableContour>;

    protected addContourToCanvas(createdItem: CuttableContour, x: number, y: number) {
        if (!createdItem) {
            console.error('Failed to add contour to the canvas');
            return;
        }

        const added = this.canvasService.addContourItem(createdItem);
        if (!added) {
            return;
        }

        const canvasXY = this.canvasService.clientXYToFoamLayer(x, y);
        const tx = Snap.matrix().translate(canvasXY.x, canvasXY.y);
        createdItem.transform(tx);

        if (createdItem instanceof TextContour) {
            (createdItem as TextContour).selectTextAll();
            const textTool = new TextEditingTool(createdItem, this.foamEditorService);
            this.foamEditorService.setTool(textTool);
            textTool
                .toolDone()
                .pipe(take(1))
                .subscribe(() => {
                    this.foamEditorService.setDefaultTool();
                });
        }

        this.canvasService.clearSelection();
        this.canvasService.addToSelection([createdItem]);

        this.canvasService
            .getUndoManagerService()
            .addEditAction(new CreationEditAction(createdItem));
    }
}

export class StandardContourCreateAction extends ContourCreateAction {
    private startDimensions: ContourStartDimensions;

    constructor(foamEditorService: FoamEditorService, private dialogService: DialogService) {
        super(foamEditorService);
        this.startDimensions = this.foamEditorService.getContoursStartDimensions();
    }

    execute(event: CanvasContourCreateActionEvent) {
        this.createContour(event).subscribe(contour =>
            this.addContourToCanvas(contour, event.x, event.y)
        );
    }

    protected createContour(event: CanvasContourCreateActionEvent): Observable<CuttableContour> {
        if (!this.startDimensions) {
            throw new ReferenceError('startDimensions is undefined.');
        }

        const userDepthInputRequired = event.type === 'RECTANGLE' || event.type === 'CIRCLE';
        if (userDepthInputRequired) {
            return this.askForContourDepth().pipe(
                first(),
                filter(r => !r.canceled),
                map(response => {
                    return this.createDepthRequiredContours(event.type, response.depth);
                })
            );
        } else if (event.type === 'TEXT') {
            return of(new TextContour({ defaultText: 'Sortimo' }));
        } else if (event.type === 'RECESSED_GRIP') {
            const { width, height, cornerRadius } = this.startDimensions.RECESSED_GRIP;
            const createdItem = new RecessedGripContour({
                width: width,
                height: height,
                cornerRadiusXY: cornerRadius,
                depth: this.canvasService.getPropertiesValue().minRecessedGripDepth
            });
            return of(createdItem);
        } else {
            console.error('Unknown droppableItemType ' + event.type);
            return EMPTY;
        }
    }

    private createDepthRequiredContours(type: CanvasContourType, depth: number): CuttableContour {
        if (type === 'RECTANGLE') {
            const { width, height, cornerRadius } = this.startDimensions.RECTANGLE;
            return new RectangleContour({
                width: width,
                height: height,
                cornerRadiusXY: cornerRadius,
                depth: depth
            });
        }
        if (type === 'CIRCLE') {
            return new CircleContour({
                radius: this.startDimensions.CIRCLE.radius,
                depth: depth
            });
        }

        return undefined;
    }

    private askForContourDepth(): Observable<DepthPromptResponse> {
        const dialogRef = this.dialogService.openDialog(ContourDepthPromptDialogComponent);
        const compInstance = dialogRef.dialogContentComponent;

        const {
            minContourDepth,
            maxContourDepth
        } = this.foamEditorService.getCanvasService().getPropertiesValue();
        compInstance.minContourDepth = minContourDepth;
        compInstance.maxContourDepth = maxContourDepth;
        return compInstance.response;
    }
}

export class ImageContourCreateAction extends ContourCreateAction {
    constructor(
        readonly foamEditorService: FoamEditorService,
        private readonly dialogService: DialogService
    ) {
        super(foamEditorService);
    }

    execute(event: CanvasImageContourCreateActionEvent) {
        this.createContour(event).subscribe(contour =>
            this.addContourToCanvas(contour, event.x, event.y)
        );
    }

    protected createContour(
        event: CanvasImageContourCreateActionEvent
    ): Observable<CuttableContour> {
        const contourDepth = event.imageContour.depth;
        const {
            maxContourDepth,
            maxCuttingDepth
        } = this.foamEditorService.getCanvasService().getPropertiesValue();
        const showWarning =
            this.foamEditorService.isPartialCuttingEnable() && contourDepth > maxContourDepth;
        if (showWarning) {
            if (contourDepth > maxCuttingDepth) {
                throw Error(
                    'Teilversenkung active and object deeper thatn' +
                        maxCuttingDepth +
                        ' mm! some check went wrong'
                );
            }
            // opens the dialog asynchonousy object creation does not wait
            return this.openWarningDialog().pipe(
                map(() => ImageContour.create(event.imageContour, event.imageContour.id))
            );
        }

        return of(ImageContour.create(event.imageContour, event.imageContour.id));
    }

    private openWarningDialog(): Observable<void> {
        const dialogRef = this.dialogService.openDialog(PartialCuttingWarningDialogComponent);
        const compInstance = dialogRef.dialogContentComponent;
        return compInstance.response;
    }
}

export interface CanvasContourCreateActionEvent extends ActionEvent {
    type: CanvasContourType;
    x: number;
    y: number;
}

export interface CanvasImageContourCreateActionEvent extends CanvasContourCreateActionEvent {
    imageContour: ImageContourModel;
}

export type CanvasContourType = 'IMAGE_CONTOUR' | 'RECTANGLE' | 'TEXT' | 'CIRCLE' | 'RECESSED_GRIP';
