<div class="app-icon app-baseline">
    <svg
        #svgElement
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        [attr.width]="svgWidth"
        [attr.height]="svgHeight"
        [style.width]="cssWidth"
        [style.height]="cssHeight"
    >
        <use [attr.xlink:href]="shapeUrl"></use>
    </svg>
    <ng-content></ng-content>
</div>
