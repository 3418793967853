import { CollisionDetectionResult } from './collision-handler.service';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AdjacencyList } from './collision-graph';
import { StaticCollisionResult } from './static-collision-graph';

@Injectable()
export class CollisionEventNotifier {
    private dynamicCollisionChangesSource = new Subject<CollisionChangedEvent>();
    private staticCollisionChangesSource = new Subject<StaticCollisionChangedEvent>();

    readonly onDynamicCollisionChanged: Observable<
        CollisionChangedEvent
    > = this.dynamicCollisionChangesSource.asObservable();

    readonly onStaticCollisionChanged: Observable<
        StaticCollisionChangedEvent
    > = this.staticCollisionChangesSource.asObservable();

    private dynamicCollisionResults: CollisionDetectionResult[] = [];
    private staticCollisionResults: StaticCollisionResult[] = [];

    /**
     * Based on the recent collected {@link CollisionDetectionResult} (static and dynamic)
     * this methods notifies which items now collide and which don't collide anymore
     * The collected CollisionDetectionResult
     * @return notify
     */
    public notify(): boolean {
        return this.notifyDynamicCollisionChanges() || this.notifyStaticCollisionChanges();
    }

    private notifyDynamicCollisionChanges(): boolean {
        let notified = false;
        const addedItems = new Set<string>();
        const removedItems = new Set<string>();
        let totalCollidingItems = 0;

        // FIXME quick and dirty
        this.dynamicCollisionResults.forEach(res => {
            res.newCollidedItems.forEach(x => addedItems.add(x));
            res.toRemoveItems.forEach(x => removedItems.add(x));
            totalCollidingItems = res.totalCollidingItems;
        });

        if (addedItems.size > 0 || removedItems.size > 0) {
            const changedEvent: CollisionChangedEvent = {
                addedItems: addedItems,
                removedItems: removedItems,
                totalCollidingItems: totalCollidingItems
            };

            this.dynamicCollisionChangesSource.next(changedEvent);
            notified = true;
        }

        this.dynamicCollisionResults = [];
        return notified;
    }

    private notifyStaticCollisionChanges(): boolean {
        let notified = false;
        const addedItems: AdjacencyList = {};
        const removedItems: AdjacencyList = {};

        // FIXME quick and dirty
        this.staticCollisionResults.forEach(res => {
            Object.keys(res.addedItems).forEach(node => {
                if (!addedItems[node]) {
                    addedItems[node] = res.addedItems[node];
                } else {
                    // merge addedItem results
                    res.addedItems[node].forEach(x => addedItems[node].add(x));
                }

                // addedItems[node] = res.addedItems[node];
            });

            Object.keys(res.removedItems).forEach(node => {
                if (!removedItems[node]) {
                    removedItems[node] = res.removedItems[node];
                } else {
                    // merge remove results
                    res.removedItems[node].forEach(x => removedItems[node].add(x));
                }
            });
        });

        if (Object.keys(addedItems).length > 0 || Object.keys(removedItems).length > 0) {
            const changedEvent: StaticCollisionChangedEvent = {
                addedItems: addedItems,
                removedItems: removedItems
            };

            this.staticCollisionChangesSource.next(changedEvent);
            notified = true;
        }

        this.staticCollisionResults = [];
        return notified;
    }

    /**
     * Record collision detection results for the dynamic collision layer. This is used to collect
     * collision state changes so that Observables can be notified of changes regarding multiple
     * contour items at once
     * @param result
     */
    recordDynamicCollisionResult(result: CollisionDetectionResult) {
        this.dynamicCollisionResults.push(result);
    }

    /**
     * Record collision detection results for the fixed (static) collision layer.
     * @param result
     */
    recordStaticCollisionResult(result: StaticCollisionResult) {
        this.staticCollisionResults.push(result);
    }

    clearRecords() {
        this.dynamicCollisionResults = [];
        this.staticCollisionResults = [];
    }
}

export interface CollisionChangedEvent {
    readonly addedItems: Set<string> | null;
    readonly removedItems: Set<string> | null;
    readonly totalCollidingItems?: number;
}

export interface StaticCollisionChangedEvent {
    readonly addedItems: AdjacencyList;
    readonly removedItems: AdjacencyList;
}
