import { ContourHandlesData } from '../contour/contour-items-interfaces';
import { RotationHandlesDrawer } from './rotation-handles-drawer';
import { ResizeHandlesDrawer } from './resize-handles-drawer';
import { ContourTransformationBounds } from './contour-transformation-bounds';

declare var Snap: any;

export class SelectionHandle3 {
    private selectionItemGroup: Snap.Paper;
    private isDebugTransformationEnabled: boolean = false;
    private debugSvgCanvas: any;
    private debugBBox: any;
    selectionBounds: ContourTransformationBounds;
    private selectionRectangle: any;
    private isSelected: boolean;

    private isInitialCreation = true;

    private resizeHandles: ResizeHandlesDrawer;
    private rotationHandles: RotationHandlesDrawer;
    private scaleFactor: number;
    private debugRotationCenter: Snap.Element;

    constructor(
        private container: Snap.Paper,
        private contourId: string,
        handlesData: ContourHandlesData
    ) {
        this.init(handlesData);

        // this.rectangleId = 'sel-rect-tool-' + handleId;

        if (this.isDebugTransformationEnabled) {
            this.debugSvgCanvas = Snap(container);
            this.debugRotationCenter = this.debugSvgCanvas.circle(0, 0, 1);
        }
    }

    private init(handlesData: ContourHandlesData) {
        this.selectionItemGroup = this.container.g();
        // this.selectionItemGroup.attr({ display: 'none' });

        // init

        const transformBounds = handlesData.transformBounds;
        this.selectionBounds = new ContourTransformationBounds(
            this.contourId,
            this.selectionItemGroup,
            transformBounds.localContourBounds,
            transformBounds.localMarginBounds,
            transformBounds.selectionBoundsMatrix,
            this.isDebugTransformationEnabled
        );

        if (handlesData.resizeHandles) {
            this.resizeHandles = this.createResizeHandles(handlesData);
        }

        if (handlesData.rotationHandles) {
            this.rotationHandles = this.createRotationHandles(handlesData);
        }
    }

    private createResizeHandles(handlesData: ContourHandlesData) {
        return new ResizeHandlesDrawer(
            this.contourId,
            this.selectionItemGroup,
            handlesData.resizeHandles,
            this.selectionBounds
        );
    }

    private createRotationHandles(handlesData: ContourHandlesData): RotationHandlesDrawer {
        return new RotationHandlesDrawer(
            this.contourId,
            this.selectionItemGroup,
            handlesData.rotationHandles,
            this.selectionBounds
        );
    }

    updateHandles(handlesData: ContourHandlesData) {
        // update the selection bounds e.g. after resizing or typing in the textfield
        if (handlesData.transformBounds) {
            this.selectionBounds.updateBounds(
                handlesData.transformBounds.localMarginBounds,
                handlesData.transformBounds.selectionBoundsMatrix,
                handlesData.transformBounds.localContourBounds
            );

            // transform handle
            if (this.rotationHandles) {
                this.rotationHandles.transform(handlesData.transformBounds.selectionBoundsMatrix);
                this.rotationHandles.updateHandlesPosition();
            }

            if (this.resizeHandles) {
                this.resizeHandles.updateResizeHandles();
            }
        }

        // Update handles' properties
        if (handlesData.rotationHandles) {
            if (!this.rotationHandles) {
                this.rotationHandles = this.createRotationHandles(handlesData);
            }

            this.rotationHandles.updateHandles(handlesData.rotationHandles);

            /*
            if (this.isDebugTransformationEnabled) {
                this.debugRotationCenter.attr({
                    cx: handlesData.transformBounds.localContourBounds.cx,
                    cy: handlesData.transformBounds.localContourBounds.cy,
                    fill: 'red',
                    r: 1,
                });
                this.debugRotationCenter.node.setAttribute(
                    'transform',
                    handlesData.transformBounds.selectionBoundsMatrix.toString()
                );
            } */
        }

        if (handlesData.resizeHandles) {
            if (!this.resizeHandles) {
                this.resizeHandles = this.createResizeHandles(handlesData);
            }
            this.resizeHandles.updateHandles(handlesData.resizeHandles);
        }
    }

    hide() {
        this.selectionItemGroup.attr({ display: 'none' });
    }

    show() {
        this.selectionItemGroup.attr({ display: 'block' });
    }

    select() {
        /*
        if (!this.isSelected) {
            this.isSelected = true;

            this.selectionItemGroup.attr({ display: 'block' });
            if (this.isInitialCreation) {
                /*
                if (this.selectionBounds) {
                    this.selectionBounds.updateSelectionBox();
                } */
        /*   if (this.rotationHandles) {
                    this.rotationHandles.updateHandlesPosition(
                        this.selectionBounds.getBBox(),
                        this.selectionBounds.getLocalOuterMatrix()
                    );
                } */
        this.isInitialCreation = false;
        /*   }
       } */
    }

    deselect() {
        this.isSelected = false;
        this.selectionItemGroup.attr({ display: 'none' });
    }

    remove() {
        this.selectionItemGroup.remove();
    }

    transform(matrix: Snap.Matrix) {
        this.selectionBounds.transform(matrix);

        if (this.rotationHandles) {
            this.rotationHandles.transform(matrix);
        }
        if (this.resizeHandles) {
            this.resizeHandles.updateResizeHandles();
        }
    }

    /*
    translateHandle(deltaX: number, deltaY: number, transformationMatrix: Snap.Matrix) {
        // Update selection box center used as rotation pivot

        this.selectionBounds.transform(transformationMatrix);
        if (this.rotationHandles) {
            this.rotationHandles.transform(transformationMatrix);
        }

        if (this.resizeHandles) {
            this.resizeHandles.updateResizeHandles();
            //this.resizeHandles.translate(deltaX, deltaY);
        }
    }

    rotateHandle(
        deltaAngleDegree: number,
        deltaAngleRad: number,
        matrix: Snap.Matrix,
        centerX: number,
        centerY: number
    ) {
        this.selectionBounds.transform(matrix);
        this.rotationHandles.transform(matrix);

        if (this.resizeHandles) {
            this.resizeHandles.updateResizeHandles();
            //this.resizeHandles.rotate(deltaAngleDegree, matrix);
        }

        if (this.isDebugTransformationEnabled) {
            this.debugRotationCenter.attr({
                cx: centerX,
                cy: centerY,
                fill: 'red',
                r: 1,
            });
            this.debugRotationCenter.node.setAttribute('transform', matrix.toString());
        }
    } */

    private debugTransformation(
        circlePos: { x: number; y: number },
        rectCenterX: number,
        rectCenterY: number,
        deltaX: number,
        deltaY: number
    ) {
        this.debugSvgCanvas.circle(circlePos.x, circlePos.y, 2).attr({ fill: 'red' });

        this.debugSvgCanvas
            .line(rectCenterX, rectCenterY, rectCenterX + deltaX, rectCenterY - deltaY)
            .attr({ stroke: 'white' });

        const rectBBox = this.selectionRectangle.node.getBoundingClientRect();
        const selectionRectangleBBox = this.selectionRectangle.getBBox();
        this.debugBBox.attr({
            x: selectionRectangleBBox.x,
            y: selectionRectangleBBox.y,
            width: rectBBox.width,
            height: rectBBox.height,
            stroke: 'red',
            fill: 'transparent',
            'pointer-events': 'none'
        });
    }
}
