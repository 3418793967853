import { Component, OnInit } from '@angular/core';
import { RibbonSelectionService } from '../../ribbon/ribbon-selection.service';
import { DialogRefs } from '../../../dialog/dialog-refs';

@Component({
    selector: 'app-save-dialog',
    templateUrl: './save-dialog.component.html',
    styleUrls: ['./save-dialog.component.scss']
})
export class SaveDialogComponent implements OnInit {
    constructor(
        public dialogRefs: DialogRefs<SaveDialogComponent>,
        private ribbonSelectionService: RibbonSelectionService
    ) {}

    ngOnInit() {}

    cancel() {
        this.dialogRefs.close(false);
    }

    exit() {
        this.dialogRefs.close(true);
        this.ribbonSelectionService.selectTab(1);
    }
}
