import { NgZone, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class BreakpointObserverService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        /**  A map of all media queries currently being listened for. */
        this._queries = new Map();
        this._destroySubject = new Subject();
    }
    ngOnDestroy() {
        this._destroySubject.next();
        this._destroySubject.complete();
    }
    /**
     * Whether one or more media queries match the current viewport size.
     * @param value One or more media queries to check.
     * @returns Whether any of the media queries match.
     */
    isMatched(value) {
        value = Array.isArray(value) ? value : [value];
        const queries = splitQueries(value);
        const _matchMedia = window.matchMedia.bind(window);
        return queries.some(mediaQuery => _matchMedia(mediaQuery).matches);
    }
    observe(value) {
        value = Array.isArray(value) ? value : [value];
        const queries = splitQueries(value);
        const observables = queries.map(query => this._registerQuery(query).observable);
        return combineLatest(observables).pipe(map((breakpointStates) => {
            return {
                matches: breakpointStates.some(state => state && state.matches)
            };
        }));
    }
    /** Registers a specific query to be listened for. */
    _registerQuery(query) {
        // Only set up a new MediaQueryList if it is not already being listened for.
        if (this._queries.has(query)) {
            return this._queries.get(query);
        }
        const _matchMedia = window.matchMedia.bind(window);
        const mql = _matchMedia(query);
        // Create callback for match changes and add it is as a listener.
        const queryObservable = new Observable((observer) => {
            // Listener callback methods are wrapped to be placed back in ngZone. Callbacks must be placed
            // back into the zone because matchMedia is only included in Zone.js by loading the
            // webapis-media-query.js file alongside the zone.js file.  Additionally, some browsers do not
            // have MediaQueryList inherit from EventTarget, which causes inconsistencies in how Zone.js
            // patches it.
            const handler = (e) => this.ngZone.run(() => observer.next(e));
            mql.addListener(handler);
            return () => {
                mql.removeListener(handler);
            };
        }).pipe(takeUntil(this._destroySubject), startWith(mql), map((nextMql) => ({ matches: nextMql.matches })));
        // Add the MediaQueryList to the set of queries.
        const output = { observable: queryObservable, mql: mql };
        this._queries.set(query, output);
        return output;
    }
}
BreakpointObserverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreakpointObserverService_Factory() { return new BreakpointObserverService(i0.ɵɵinject(i0.NgZone)); }, token: BreakpointObserverService, providedIn: "root" });
/**
 * Split each query string into separate query strings if two queries are provided as comma
 * separated.
 */
function splitQueries(queries) {
    return queries
        .map((query) => query.split(','))
        .reduce((a1, a2) => a1.concat(a2))
        .map(query => query.trim());
}
