import { Point2D } from './point2D';
import { transformPoint } from './matrix';
import { getScrollX, getScrollY } from '../../../utils/dom-utils';
import { Rectangle2D } from './rectangle2D';

declare var Snap: any;

export function getAngle(x: number, y: number, centerX: number, centerY: number): number {
    const deltaX = x - centerX;
    const deltaY = centerY - y;

    const targetLineRad = Math.atan2(deltaX, deltaY);

    // get current rotation angle
    // const deltaAngleRad = targetLineRad - Snap.rad(currentDegree);
    // const deltaAngleDegree = CollisionUtil.toDegrees(targetLineRad) - currentDegree;

    // return { deltaAngleDegree: deltaAngleDegree, deltaAngleRad: deltaAngleRad };

    return toDegrees(targetLineRad);
}

export function toDegrees(radians: number): number {
    let angle = (radians * 180) / Math.PI;
    if (angle < 0) {
        angle += 360;
    }
    return angle;
}

// https://github.com/svgdotjs/svg.js/blob/a180a4e4fd4d47f7a45db0d3fb66d3df4bfb6749/src/boxes.js
export function _getGlobalBBox(this: Rectangle2D, node: SVGElement, parentEl: SVGGraphicsElement) {
    const bbox = node.getBoundingClientRect() as DOMRect;

    let xMin = Infinity;
    let xMax = -Infinity;
    let yMin = Infinity;
    let yMax = -Infinity;

    const m = parentEl.getScreenCTM().inverse();
    const pts = [
        new Point2D(this.x, this.y),
        new Point2D(this.x2, this.y),
        new Point2D(this.x, this.y2),
        new Point2D(this.x2, this.y2)
    ];

    let p;

    pts.forEach(function(pt) {
        p = transformPoint(pt.x, pt.y, m);
        xMin = Math.min(xMin, p.x);
        xMax = Math.max(xMax, p.x);
        yMin = Math.min(yMin, p.y);
        yMax = Math.max(yMax, p.y);
    });

    bbox.x = xMin;
    bbox.width = xMax - xMin;
    bbox.y = yMin;
    bbox.height = yMax - yMin;

    // since getBoundingClientRect changes with the scroll-offset
    bbox.x += getScrollX();
    bbox.y += getScrollY();
    // .bbox = Snap.path.getBBox(Snap.path.map(el.realPath, m.add(el.matrix)));

    return bbox;
}

/**
 * Modification of Snap.getBBox (without timout)
 * @param path
 */
/*
function getGlobalBBox(path) {
    path = Snap.path.toCubic(path);
    let x = 0,
        y = 0,
        X = [],
        Y = [],
        p;
    for (let i = 0, ii = path.length; i < ii; i++) {
        p = path[i];
        if (p[0] === 'M') {
            x = p[1];
            y = p[2];
            X.push(x);
            Y.push(y);
        } else {
            const dim = curveDim(x, y, p[1], p[2], p[3], p[4], p[5], p[6]);
            X = X.concat(dim.min.x, dim.max.x);
            Y = Y.concat(dim.min.y, dim.max.y);
            x = p[5];
            y = p[6];
        }
    }
    const xmin = Math.min.apply(0, X),
        ymin = Math.min.apply(0, Y),
        xmax = Math.max.apply(0, X),
        ymax = Math.max.apply(0, Y),
        bb = Snap.box(xmin, ymin, xmax - xmin, ymax - ymin);
    //pth.bbox = Snap._.clone(bb);
    return bb;
}

// Returns bounding box of cubic bezier curve.
// Source: http://blog.hackers-cafe.net/2009/06/how-to-calculate-bezier-curves-bounding.html
// Original version: NISHIO Hirokazu
// Modifications: https://github.com/timo22345
function curveDim(x0, y0, x1, y1, x2, y2, x3, y3) {
    var tvalues = [],
        bounds = [[], []],
        a,
        b,
        c,
        t,
        t1,
        t2,
        b2ac,
        sqrtb2ac;
    for (var i = 0; i < 2; ++i) {
        if (i == 0) {
            b = 6 * x0 - 12 * x1 + 6 * x2;
            a = -3 * x0 + 9 * x1 - 9 * x2 + 3 * x3;
            c = 3 * x1 - 3 * x0;
        } else {
            b = 6 * y0 - 12 * y1 + 6 * y2;
            a = -3 * y0 + 9 * y1 - 9 * y2 + 3 * y3;
            c = 3 * y1 - 3 * y0;
        }
        if (abs(a) < 1e-12) {
            if (abs(b) < 1e-12) {
                continue;
            }
            t = -c / b;
            if (0 < t && t < 1) {
                tvalues.push(t);
            }
            continue;
        }
        b2ac = b * b - 4 * c * a;
        sqrtb2ac = math.sqrt(b2ac);
        if (b2ac < 0) {
            continue;
        }
        t1 = (-b + sqrtb2ac) / (2 * a);
        if (0 < t1 && t1 < 1) {
            tvalues.push(t1);
        }
        t2 = (-b - sqrtb2ac) / (2 * a);
        if (0 < t2 && t2 < 1) {
            tvalues.push(t2);
        }
    }

    var x,
        y,
        j = tvalues.length,
        jlen = j,
        mt;
    while (j--) {
        t = tvalues[j];
        mt = 1 - t;
        bounds[0][j] =
            mt * mt * mt * x0 + 3 * mt * mt * t * x1 + 3 * mt * t * t * x2 + t * t * t * x3;
        bounds[1][j] =
            mt * mt * mt * y0 + 3 * mt * mt * t * y1 + 3 * mt * t * t * y2 + t * t * t * y3;
    }

    bounds[0][jlen] = x0;
    bounds[1][jlen] = y0;
    bounds[0][jlen + 1] = x3;
    bounds[1][jlen + 1] = y3;
    bounds[0].length = bounds[1].length = jlen + 2;

    return {
        min: { x: mmin.apply(0, bounds[0]), y: mmin.apply(0, bounds[1]) },
        max: { x: mmax.apply(0, bounds[0]), y: mmax.apply(0, bounds[1]) },
    };
} */
export interface Bounds {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    width: number;
    height: number;
}

export const ZERO_BOUNDS: Bounds = {
    minX: 0,
    minY: 0,
    maxX: 0,
    maxY: 0,
    width: 0,
    height: 0
};
