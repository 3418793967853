import {
    ChangeDetectorRef,
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    SkipSelf
} from '@angular/core';
import { AccordionDirective } from './accordion.directive';
import { coerceBooleanProperty } from '../../../utils/coerce-boolean';
import { UniqueSelectionDispatcher } from '../../../shared/collections/unique-selection-dispatcher';

/** Used to generate unique ID for each accordion item. */
let nextId = 0;

export const ACCORDION_CHILD_ID_NAME = 'app-accordion-child-';

@Directive({
    selector: '[appAccordionItem]'
})
export class AccordionItemDirective implements OnDestroy {
    /** Event emitted every time the AccordionItem is closed. */
    @Output()
    closed: EventEmitter<void> = new EventEmitter<void>();

    /** Event emitted every time the AccordionItem is opened. */
    @Output()
    opened: EventEmitter<void> = new EventEmitter<void>();

    private _expanded = false;
    private _disabled: boolean = false;

    /**
     * Emits whenever the expanded state of the accordion changes.
     * Primarily used to facilitate two-way binding.
     */
    @Output()
    expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** The unique AccordionItem id. */
    readonly id: string = `${ACCORDION_CHILD_ID_NAME}${nextId++}`;

    constructor(
        public accordion: AccordionDirective,
        private _changeDetectorRef: ChangeDetectorRef,
        protected _expansionDispatcher: UniqueSelectionDispatcher
    ) {
        this._removeUniqueSelectionListener = _expansionDispatcher.listen(
            (id: string, accordionId: string) => {
                if (this.id === 'app-accordion-child-0') {
                }
                if (this.accordion && this.accordion.id === accordionId && this.id !== id) {
                    this.expanded = false;
                }
            }
        );
    }

    @Input()
    get expanded(): any {
        return this._expanded;
    }

    set expanded(expanded: any) {
        expanded = coerceBooleanProperty(expanded);

        // Only emit events and update the internal value if the value changes.
        if (this._expanded !== expanded) {
            this._expanded = expanded;
            this.expandedChange.emit(expanded);

            if (expanded) {
                this.opened.emit();
                /**
                 * In the unique selection dispatcher, the id parameter is the id of the CdkAccordionItem,
                 * the name value is the id of the accordion.
                 */
                const accordionId = this.accordion ? this.accordion.id : this.id;
                this._expansionDispatcher.notify(this.id, accordionId);
            } else {
                this.closed.emit();
            }

            // Ensures that the animation will run when the value is set outside of an `@Input`.
            // This includes cases like the open, close and toggle methods.
            this._changeDetectorRef.markForCheck();
        }
    }

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(disabled: any) {
        this._disabled = coerceBooleanProperty(disabled);
    }

    /** Toggles the expanded state of the accordion item. */
    toggle(): void {
        if (!this.disabled) {
            this.expanded = !this.expanded;
        }
    }

    /** Sets the expanded state of the accordion item to false. */
    close(): void {
        if (!this.disabled) {
            this.expanded = false;
        }
    }

    /** Sets the expanded state of the accordion item to true. */
    open(): void {
        if (!this.disabled) {
            this.expanded = true;
        }
    }

    ngOnDestroy() {
        this.opened.complete();
        this.closed.complete();
        this._removeUniqueSelectionListener();
    }

    /** Unregister function for _expansionDispatcher. */
    private _removeUniqueSelectionListener: () => void = () => {};
}
