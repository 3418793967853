import { ImageContour } from '../foam-editor/canvas/contour/image-contour';
import { catchError, concatMap, finalize, map, retryWhen, take, tap } from 'rxjs/operators';
import { ImageContourModel, ProducerImageContourModel } from './../models/image-contour.model';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { StartParameterModel } from '../models/start-parameter.model';
import {
    ContourJSON,
    ImageContourJSON,
    ModelMapper,
    ProducerImageContourJSON
} from '../models/model.mapper';
import { ApiErrorCode, instanceOfApiError } from './api-error';
import { ProducerFilterOptions } from '../models/producer-filter-options';

const BASE64_DATA_PREFIX = 'data:image';

const HTTP_TEXT_OPTIONS = {
    headers: new HttpHeaders({ 'Content-Type': 'text/plain' }),
    responseType: 'text' as 'text',
    withCredentials: true
};

const HTTP_JSON_OPTIONS = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

const HTTP_IMAGE_OPTIONS = {
    responseType: 'arraybuffer' as 'json',
    withCredentials: true
};

@Injectable({ providedIn: 'root' })
export class BackendService {
    private readonly TOOL_SUFFIX = '/Tool';
    private readonly TOOL_OPTIONS_SUFFIX = '/ToolOptions';
    private readonly SAVE_FOAM_CONFIG_URL = '/Config';
    private readonly STARTPARAMTER_URL = '/Config/startParameters';

    private _backendUrl: string;

    constructor(private readonly http: HttpClient) {}

    public static deleteImageAttributes(contour: ImageContourModel): ImageContourModel {
        // FIXME: move to server
        if (!(contour.previewImage && contour.previewImage.startsWith(BASE64_DATA_PREFIX))) {
            delete contour.previewImage;
        }
        if (!(contour.image && contour.image.startsWith(BASE64_DATA_PREFIX))) {
            delete contour.image;
        }
        return contour;
    }

    private static isAsyncRequestTimeoutError(error: any): boolean {
        return (
            error &&
            instanceOfApiError(error) &&
            error.errorCode === ApiErrorCode.ASYNC_REQUEST_TIMEOUT
        );
    }

    private get backendUrl(): string {
        return this._backendUrl;
    }

    private set backendUrl(url: string) {
        this._backendUrl = url;
    }

    public get apiUrl(): string {
        if (!this.backendUrl) {
            return environment.apiUrl;
        }

        return 'https://' + this.backendUrl;
    }

    public saveFoamConfig(bodyData: any): Observable<string> {
        return this.http
            .put(this.apiUrl + this.SAVE_FOAM_CONFIG_URL, bodyData, HTTP_JSON_OPTIONS)
            .pipe(
                catchError(err => this.handleError(err)),
                map(o => {
                    // return (o as any).configId;
                    const configId = (o as any).configId;
                    console.log('bodyData Saved, id: ' + configId);
                    return configId;
                })
            );
    }

    /**
     * Performs HTTP long polling for the given {@code requestStatusUrl},
     *  by responding to the ASYNC_REQUEST_TIMEOUT message from the server.
     *
     * @param requestStatusUrl
     * @param maxRetries - The number of replies when receiving a ASYNC_REQUEST_TIMEOUT message
     */
    private longPollingRequest(requestStatusUrl: string, maxRetries = 70): Observable<any> {
        const saveStatus = this.http.get(this.apiUrl + '/' + requestStatusUrl, HTTP_JSON_OPTIONS);

        let retryCnt = 0;
        return saveStatus.pipe(
            retryWhen(errors =>
                errors.pipe(
                    concatMap(error => {
                        if (error && BackendService.isAsyncRequestTimeoutError(error.error)) {
                            retryCnt++;
                            if (retryCnt > maxRetries) {
                                return throwError(
                                    `Failed to complete long polling after ${maxRetries} attempts`
                                );
                            }
                            return of(error);
                        }
                        return throwError(error);
                    })
                )
            )
        );
    }

    private handleError(err: HttpErrorResponse) {
        console.log('handing error: ' + err);
        if (err && err.error && BackendService.isAsyncRequestTimeoutError(err.error)) {
            const data = err.error.data;
            if (data && data.requestStatusUrl) {
                return this.longPollingRequest(data.requestStatusUrl);
            } else {
                return throwError('Failed to check status of the async request');
            }
        }
        return throwError(err);
    }

    public getStartParameters(id: string): Observable<StartParameterModel> {
        this.initializeServerUrl();
        let options = HTTP_JSON_OPTIONS;
        if (id) {
            options = Object.assign({ params: { id: id } }, options);
        }
        return this.http
            .get<StartParameterModel>(this.apiUrl + this.STARTPARAMTER_URL, options)
            .pipe(map((data: any) => ModelMapper.jsonToStartParameterModel(data)));
    }

    public getAllToolFilter(): Observable<ProducerFilterOptions> {
        return this.http.get<ProducerFilterOptions>(
            this.apiUrl + this.TOOL_OPTIONS_SUFFIX,
            HTTP_JSON_OPTIONS
        );
    }

    public getAllTool(): Observable<ImageContourModel[]> {
        const TOOL_CLASS_NAME = 'ImageContour';
        const PRODUCER_TYPE_CLASS_NAME = 'ProducerImageContour';

        return this.http.get<any[]>(this.apiUrl + this.TOOL_SUFFIX, HTTP_JSON_OPTIONS).pipe(
            map((responseArray: any[]) => {
                return responseArray.map(data => {
                    if (data[TOOL_CLASS_NAME]) {
                        return new ImageContourModel(data[TOOL_CLASS_NAME]);
                    } else if (data[PRODUCER_TYPE_CLASS_NAME]) {
                        return new ProducerImageContourModel(data[PRODUCER_TYPE_CLASS_NAME]);
                    }
                    throw Error('ImageContourModel data structure invalid');
                });
            })
        );
    }

    public getTool(id: string): Observable<ImageContour> {
        return this.http.get(this.apiUrl + this.TOOL_SUFFIX + '/' + id, HTTP_JSON_OPTIONS).pipe(
            map((data: any) => {
                // FIXME: use domain model
                return new ImageContour(data);
            })
        );
    }

    public getToolImage(id: string): Observable<ArrayBuffer> {
        return this.http
            .get(this.apiUrl + this.TOOL_SUFFIX + '/' + id + '/image', HTTP_IMAGE_OPTIONS)
            .pipe(
                map((data: any) => {
                    return data as ArrayBuffer;
                })
            );
    }

    public getToolImageBase64(id: string): Observable<string> {
        return this.http.get(
            this.apiUrl + this.TOOL_SUFFIX + '/' + id + '/image64',
            HTTP_TEXT_OPTIONS
        );
    }

    public saveUserTool(contour: ImageContourJSON): Observable<ImageContourModel> {
        return this.http
            .put(this.apiUrl + this.TOOL_SUFFIX, contour, HTTP_JSON_OPTIONS)
            .pipe(map(data => ModelMapper.jsonToContourModel(data)));
    }

    public deleteTool(contour: ImageContourModel): Observable<Object> {
        return this.http.delete(
            this.apiUrl + this.TOOL_SUFFIX + '/' + contour.id,
            HTTP_JSON_OPTIONS
        );
    }

    /**
     * initializes the server Url dependent on the client domain
     *
     * inlay-de => inlay-server.
     * inlay-client.mysortimo[de|fr|..] => inlay-server.mysortimo[de|fr]
     *
     */
    private initializeServerUrl() {
        const clientDomain = window.document.domain; // inlay-de.sortimo-test.de or inlay....
        if (clientDomain.startsWith('inlay-de.')) {
            this.backendUrl = clientDomain.replace('inlay-de.', 'inlay-server.');
        } else if (clientDomain.startsWith('inlay-client.')) {
            this.backendUrl = clientDomain.replace('inlay-client.', 'inlay-server.');
        }
        //
    }

    public editAllContourForCanvas(contours: ContourJSON[]): Observable<ContourJSON[]> {
        return forkJoin(
            contours.map(contour => {
                if ((contour as any).ProducerImageContour) {
                    return this.editProducerImageForCanvas(contour as ProducerImageContourJSON);
                } else if ((contour as any).ImageContour) {
                    return this.editImageForCanvas(contour as ImageContourJSON);
                }
                return of(contour);
            })
        );
    }

    private editProducerImageForCanvas(
        contour: ProducerImageContourJSON
    ): Observable<ProducerImageContourJSON> {
        // delete unneccessary previewImage
        delete contour.ProducerImageContour.previewImage;
        if (
            !(
                contour.ProducerImageContour.image &&
                contour.ProducerImageContour.image.startsWith(BASE64_DATA_PREFIX)
            )
        ) {
            // replace link within image with base 64 encoding
            return this.getToolImageBase64(contour.ProducerImageContour.id).pipe(
                map((imagecontent: string) => {
                    try {
                        contour.ProducerImageContour.image = imagecontent;
                        return contour;
                    } catch (e) {
                        console.error(e);
                        throw e;
                    }
                })
            );
        }
        return of(contour);
    }

    private editImageForCanvas(contour: ImageContourJSON): Observable<ImageContourJSON> {
        // delete unnecessary previewImage
        delete contour.ImageContour.previewImage;
        if (
            !(
                contour.ImageContour.image &&
                contour.ImageContour.image.startsWith(BASE64_DATA_PREFIX)
            )
        ) {
            // replace link within image with base 64 encoding
            return this.getToolImageBase64(contour.ImageContour.id).pipe(
                map((imagecontent: string) => {
                    try {
                        contour.ImageContour.image = imagecontent;
                        return contour;
                    } catch (e) {
                        console.error(e);
                        throw e;
                    }
                })
            );
        }
        return of(contour);
    }
}
