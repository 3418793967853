/**
 * This class describes only the width and height (2D-dimension) of an object.
 * That is, it does not specifying x,y location which would make it a {@link Rectangle2D}
 */
export class Dimension2D {
    width: number;
    height: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    equals(otherDim: Dimension2D): boolean {
        return this.width === otherDim.width && this.height === otherDim.height;
    }
}
