<div #innerScrollViewport class="scroll-viewport-inner">
    <div #scrollContentContainer class="scroll-content-container">
        <ng-content></ng-content>
    </div>
    <!--
  Spacer used to force the scrolling container to the correct size for the *total* number of items
  so that the scrollbar captures the size of the entire data set.
-->
    <div class="scroll-spacer" [style.transform]="totalContentSizeTransform"></div>
</div>
