import { ReplaySubject } from 'rxjs';
export function RibbonPanelContent(Base = class {
}) {
    return class extends Base {
    };
}
export function AccordionRibbonPanelContent(Base = class {
}) {
    return class extends Base {
        constructor() {
            super(...arguments);
            this._afterNavigatorInit = new ReplaySubject(1);
            this.afterNavigatorInit = this._afterNavigatorInit.asObservable();
        }
        ngOnDestroy() { }
        ngAfterViewInit() {
            this.accordionNavigator.setExpansionPanels(this.expansionPanels);
            this._afterNavigatorInit.next(true);
        }
        expandPreviousItem() {
            return this.accordionNavigator.expandPreviousItem();
        }
        expandNextItem() {
            return this.accordionNavigator.expandNextItem();
        }
        expandLastItem() {
            return this.accordionNavigator.expandLastItem();
        }
        getExpandedIndex() {
            return this.accordionNavigator.getExpandedIndex();
        }
        getExpandedItemSize() {
            return this.accordionNavigator.getExpandedItemSize();
        }
        onAccordionItemExpanded() {
            return this.accordionNavigator.onAccordionItemExpanded;
        }
    };
}
