/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ribbon-panel-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ribbon-toolbar-content.directive";
import * as i3 from "../ribbon-dialog-content.directive";
import * as i4 from "../grip-text-contour-panel/preview-contour.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../foam-editor.service";
import * as i7 from "./ribbon-panel-content.component";
import * as i8 from "../../ribbon-selection.service";
import * as i9 from "../../../canvas/canvas.service";
var styles_RibbonPanelContentComponent = [i0.styles];
var RenderType_RibbonPanelContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RibbonPanelContentComponent, data: {} });
export { RenderType_RibbonPanelContentComponent as RenderType_RibbonPanelContentComponent };
function View_RibbonPanelContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_RibbonPanelContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_RibbonPanelContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { panelHost: 0 }), i1.ɵqud(671088640, 2, { dialogHost: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RibbonPanelContentComponent_1)), i1.ɵdid(3, 16384, [[1, 4]], 0, i2.RibbonToolbarContentDirective, [i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RibbonPanelContentComponent_2)), i1.ɵdid(5, 16384, [[2, 4]], 0, i3.RibbonDialogContentDirective, [i1.ViewContainerRef], null, null)], null, null); }
export function View_RibbonPanelContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ribbon-panel-content", [], null, null, null, View_RibbonPanelContentComponent_0, RenderType_RibbonPanelContentComponent)), i1.ɵprd(512, null, i4.PreviewContourService, i4.PreviewContourService, [i5.DOCUMENT, i6.FoamEditorService]), i1.ɵdid(2, 1294336, null, 0, i7.RibbonPanelContentComponent, [i1.ComponentFactoryResolver, i8.RibbonSelectionService, i9.CanvasService, i4.PreviewContourService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RibbonPanelContentComponentNgFactory = i1.ɵccf("app-ribbon-panel-content", i7.RibbonPanelContentComponent, View_RibbonPanelContentComponent_Host_0, { panel: "panel" }, {}, []);
export { RibbonPanelContentComponentNgFactory as RibbonPanelContentComponentNgFactory };
