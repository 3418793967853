import { Injectable } from '@angular/core';
import { CollisionGraph, GraphData } from './collision-graph';
import { checkLinesIntersection, pathsIntersect } from './collision-util';
import { CollisionEventNotifier } from './collision-event-notifier';
import { StaticCollisionGraph } from './static-collision-graph';
import { isInside } from 'point-in-svg-polygon';
import {
    CanvasCollisionVizData,
    ContourCollisionData,
    ContourCollisionVizData,
    MarginContourCollisionVizData
} from '../contour/contour-items-interfaces';

declare var Snap: any;

@Injectable()
export class CollisionHandlerService {
    constructor(
        private collisionGraph: CollisionGraph,
        private staticCollisionGraph: StaticCollisionGraph,
        private collisionEventNotifier: CollisionEventNotifier
    ) {}

    getCollisionGraph(): GraphData {
        return this.collisionGraph.getCollisionNodesWthEdges();
    }

    /**
     * Adds a node to the {@link StaticCollisionGraph} if it is not already present.
     *
     * @param node
     */
    addStaticNode(node: string) {
        this.staticCollisionGraph.addNode(node);
    }

    /**
     * Removes a node to the {@link StaticCollisionGraph} if it is not already present
     *
     * @param removeNodes
     */
    removeNodes(removeNodes: string[]): Set<string> {
        if (!removeNodes || removeNodes.length === 0) {
            // TODO empty set vs null
            return new Set<string>();
        }
        const toRemove = new Set<string>();
        for (const node of removeNodes) {
            const collidedItems = this.collisionGraph.getAdjacentNodes(node);
            // remove all edges that involves the given node
            this.collisionGraph.removeAllEdges(node);
            this.collisionGraph.removeNode(node);

            toRemove.add(node);
            // now we have removed the node
            // add colliding items with no other edges to the remove list
            if (collidedItems) {
                collidedItems.forEach(x => {
                    if (!this.collisionGraph.hasEdge(x)) {
                        toRemove.add(x);
                    }
                });
            }
        }

        const result: CollisionDetectionResult = {
            newCollidedItems: new Set<string>(),
            toRemoveItems: toRemove,
            totalCollidingItems: this.collisionGraph.countEdges()
        };

        this.collisionEventNotifier.recordDynamicCollisionResult(result);
        this.collisionEventNotifier.notify();
        return new Set(toRemove);
        // this.staticCollisionGraph.removeNodes(node);
    }

    selectNode(selectedNodes: string[]) {
        if (!selectedNodes || selectedNodes.length === 0) {
            return;
        }
        // const edgesToRemove: { [id: string]: Set<string> } = {};
        for (const node of selectedNodes) {
            // remove nodes that collide with the selectedNodes

            // the selection removes edges to enforce the uni-directionality of the collision graph
            // this is needed to avoid duplicate clipping, one in the dynamic and fixed layer
            this.removeDuplicateEdges(node);
            this.staticCollisionGraph.removeNode(node);
        }
        this.notifyCollisionChanges();
    }

    /**
     *
     * @param nodes
     * @param selectedNodes
     */
    addNodeToStaticGraph(nodes: string[], selectedNodes?: string[]) {
        nodes.forEach(x => this.staticCollisionGraph.addNode(x));
        if (selectedNodes) {
            selectedNodes.forEach(node => {
                this.removeDuplicateEdges(node);
            });
        }
        this.notifyCollisionChanges();
    }

    /**
     * Removes edges of the given node from the static collision graph if they already exist in the
     * dynamic collision graph.
     *
     * This method is used to enforce the uni-directionality of the collision graph, which is
     * is needed to avoid duplicate clipping, one in the dynamic and fixed layer
     *
     * @param node - typically a currently selected node
     */
    private removeDuplicateEdges(node: string) {
        const invertedEdges = this.collisionGraph.selectNode(node);
        if (invertedEdges) {
            invertedEdges.forEach(key => {
                this.staticCollisionGraph.removeEdge(key, node);
            });
        }
    }

    /**
     * Detects and updates the collisions of the {@link selectedItem}. It also emits a
     * {@link CollisionChangedEvent} when a collision has been newly detected or removed
     *
     * This method is typically called when a contour item has been moved or rotated.
     * @param collisionData
     * @param notify
     */
    public detect(
        collisionData: CanvasCollisionVizData,
        notify?: boolean
    ): CollisionDetectionResult[] {
        const results = collisionData.data.map(x =>
            this.runDetection(x, collisionData.foam, collisionData.foamInnerMargin)
        );
        if (notify) {
            this.notifyCollisionChanges();
        }

        return results;
    }

    private runDetection(
        collisionData: ContourCollisionData,
        foam?: ContourCollisionVizData,
        foamInnerMargin?: ContourCollisionVizData
    ): CollisionDetectionResult {
        const { selectedContour } = collisionData;

        // removes edges directed to this selected contour in order to enforce the
        // uni-directionality of both collision graphes
        // FIXME duplicate code. See line 88
        const invertedEdges = this.collisionGraph.selectNode(selectedContour.contourId);
        if (invertedEdges) {
            invertedEdges.forEach(key => {
                this.staticCollisionGraph.removeEdge(key, selectedContour.contourId);
            });
        }
        this.staticCollisionGraph.removeNode(selectedContour.contourId);

        const priorCollidedItems = this.collisionGraph.getAdjacentNodes(selectedContour.contourId);
        const latestCollideItems = this.getCollideItems(collisionData, foam, foamInnerMargin);

        const newCollidedItems = this.addCollisionEdges(
            selectedContour.contourId,
            priorCollidedItems,
            latestCollideItems
        );

        const toRemoveItems = this.removedEdges(
            selectedContour.contourId,
            priorCollidedItems,
            latestCollideItems
        );

        const result: CollisionDetectionResult = {
            newCollidedItems: newCollidedItems,
            toRemoveItems: toRemoveItems,
            collideItems: latestCollideItems,
            totalCollidingItems: this.collisionGraph.countEdges()
        };

        this.collisionEventNotifier.recordDynamicCollisionResult(result);

        return result;
    }

    private addCollisionEdges(
        selectedContourId: string,
        priorCollidedItems,
        latestCollideItems
    ): Set<string> {
        const newCollidedItems = new Set<string>();

        // Update edges of the selected nodes
        if (latestCollideItems.size > 0) {
            // clear the edges so that we don't need to explicitly remove edges when a collision
            // has been removed
            // this.collisionGraph.removeAllEdges(selectedItem.contourId);

            if (!this.collisionGraph.hasEdge(selectedContourId)) {
                newCollidedItems.add(selectedContourId);
            }

            latestCollideItems.forEach(contourIndex => {
                if (!priorCollidedItems || !priorCollidedItems.has(contourIndex)) {
                    // check if the node specified by the contourIndex is new, i.e.
                    // it has no edges with other nodes
                    if (!this.collisionGraph.hasEdge(contourIndex)) {
                        newCollidedItems.add(contourIndex);
                    }
                    // add the new edge only after the newness check
                    this.collisionGraph.addEdge(selectedContourId, contourIndex);
                }
            });
        }

        return newCollidedItems;
    }

    /**
     * Remove collision edges by comparing the latest and prior colliding contour
     * @param selectedContourId
     * @param priorCollidedItems
     * @param latestCollideItems
     * @return removed edges
     */
    private removedEdges(
        selectedContourId: string,
        priorCollidedItems: Set<string>,
        latestCollideItems: Set<string>
    ): Set<string> {
        const toRemoveItems = new Set<string>();
        // get removed edges of the selected nodes
        if (priorCollidedItems) {
            priorCollidedItems.forEach(priorNode => {
                if (!latestCollideItems.has(priorNode)) {
                    this.collisionGraph.removeEdge(selectedContourId, priorNode);
                    // this priorNode doesn't collide with other nodes if it has no edges
                    if (!this.collisionGraph.hasEdge(priorNode)) {
                        toRemoveItems.add(priorNode);
                    }
                }
            });

            // check if the selected item also doesn't collide anymore
            // this occurs only when collisions were detected previously,
            // i.e. the priorCollidedItems is not empty
            if (!this.collisionGraph.hasEdge(selectedContourId)) {
                toRemoveItems.add(selectedContourId);
            }
        }

        return toRemoveItems;
    }

    /**
     * Triggers the @{CollisionEventNotifier} to notifies Observers of the recent collision
     * detection changes
     */
    notifyCollisionChanges() {
        this.collisionEventNotifier.notify();
    }

    private getCollideItems(
        collisionData: ContourCollisionData,
        foamCollisionData: ContourCollisionVizData,
        foamInnerMarginCollisionData?: ContourCollisionVizData
    ): Set<string> {
        const { otherContours } = collisionData;
        const selectedContourCollisionData = collisionData.selectedContour;

        const collidedObjects: Set<string> = new Set();

        if (selectedContourCollisionData.isCollidable) {
            const len = otherContours.length;
            for (let i = 0; i < len; i++) {
                const otherContour = otherContours[i];

                const isIntersecting =
                    pathsIntersect(
                        selectedContourCollisionData.innerCollisionBBox,
                        otherContour.outerCollisionBBox,
                        selectedContourCollisionData.innerCollisionPolygonLines,
                        otherContour.outerCollisionPolygonLines
                    ) ||
                    pathsIntersect(
                        selectedContourCollisionData.outerCollisionBBox,
                        otherContour.innerCollisionBBox,
                        selectedContourCollisionData.outerCollisionPolygonLines,
                        otherContour.innerCollisionPolygonLines
                    );

                if (isIntersecting) {
                    collidedObjects.add(otherContour.contourId);
                } else {
                    // check if the selected polygon is inside the other polygon
                    const transformedSelectedBBox =
                        selectedContourCollisionData.globalContourPathBBox;
                    const otherContourTransformedPathArray = Snap.path.map(
                        otherContour.pathStringToBeClipped,
                        otherContour.globalMarginPathMatrix
                    );
                    const isInsideAnother = isInside(
                        [transformedSelectedBBox.cx, transformedSelectedBBox.cy],
                        otherContourTransformedPathArray.toString()
                    );

                    if (isInsideAnother) {
                        collidedObjects.add(otherContour.contourId);
                    }
                }
            }
        }

        if (foamInnerMarginCollisionData) {
            if (
                this.isCollidingWithFoamInnerMargin(
                    foamInnerMarginCollisionData,
                    selectedContourCollisionData
                )
            ) {
                collidedObjects.add(foamInnerMarginCollisionData.contourId);
            }
        }

        // check outer collision with foam border
        if (foamCollisionData) {
            if (this.isCollidingWithFoam(foamCollisionData, selectedContourCollisionData)) {
                collidedObjects.add(foamCollisionData.contourId);
            }
        }

        return collidedObjects;
    }

    private isCollidingWithFoam(
        foamCollisionData: ContourCollisionVizData,
        selectedContour: MarginContourCollisionVizData
    ): boolean {
        if (
            checkLinesIntersection(
                selectedContour.outerCollisionPolygonLines,
                foamCollisionData.innerCollisionPolygonLines
            )
        ) {
            return true;
        } else if (!this.checkBoundingBoxInFoamPolygon2(selectedContour, foamCollisionData)) {
            // check if the item is completely outside the foam
            return true;
        }
        return false;
    }

    private isCollidingWithFoamInnerMargin(
        foamInnerMarginCollisionData: ContourCollisionVizData,
        selectedContour: MarginContourCollisionVizData
    ): boolean {
        if (
            checkLinesIntersection(
                selectedContour.innerCollisionPolygonLines,
                foamInnerMarginCollisionData.innerCollisionPolygonLines
            )
        ) {
            return true;
        } else if (
            !this.checkBoundingBoxInFoamPolygon2(selectedContour, foamInnerMarginCollisionData)
        ) {
            // check if the item is completely inside the innerfoam
            return true;
        }
        return false;
    }

    checkBoundingBoxInFoamPolygon2(
        selectedItem: MarginContourCollisionVizData,
        foamData: ContourCollisionVizData
    ) {
        // check if the item is completely outside the foam

        // We must check every end-points of the selected item since the bounding box is inaccurate
        // for none rectangular foams
        // Also checking corner is not enough

        const len = selectedItem.innerCollisionPolygonLines.length;
        for (let i = 0; i < len; i++) {
            const localPt = {
                x: selectedItem.innerCollisionPolygonLines[i].endX,
                y: selectedItem.innerCollisionPolygonLines[i].endY
            };
            if (
                this.checkIsInsidePath(
                    foamData.pathString,
                    localPt,
                    selectedItem.globalContourPathMatrix,
                    foamData.globalContourPathMatrix
                )
            ) {
                return true;
            }
        }

        return false;
    }

    checkIsInsidePath(
        svgPathDefinition: string,
        localItemPt: { x: number; y: number },
        pointElementMatrix: Snap.Matrix,
        pathMatrix: Snap.Matrix
    ): boolean {
        const transformedPathArray = Snap.path.map(svgPathDefinition, pathMatrix);

        const transPathString = transformedPathArray.toString();
        return isInside([localItemPt.x, localItemPt.y], transPathString);
    }
}

/**
 * This interface provides only information needed by the UI components to draw or remove
 * SVG clip-paths used to represent collisions between contours.
 *
 * Note: This interface does not store information about which objects are colliding with one
 * another because the client does not need it yet.
 *
 */
export interface CollisionDetectionResult {
    /**
     * New items added for the first time to the collision graph (i.e. they never collided with any
     * other items before) *
     */
    newCollidedItems: Set<string>;
    /** Items completely removed from the collision graph **/
    toRemoveItems: Set<string>;
    // TODO should be removed
    /** Items colliding with the selected item **/
    collideItems?: Set<string>;
    /** Total number of collisions **/
    totalCollidingItems: number;
}
