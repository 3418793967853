<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <div #messageError class="upload-message-error">
                    <div class="upload-message-error__inner" *ngIf="errorMessage">
                        {{ errorMessage }}
                        <ul>
                            <li *ngFor="let detailError of detailsErrorMessages">
                                {{ detailError }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div #dropZone class="contour-upload-dropzone-container">
                    <div class="dropzone-image">
                        <app-icon shape="upload-image" iconSize="medium"></app-icon>
                    </div>
                    <div
                        class="dropzone-text"
                        [innerHTML]="'IMAGE_UPLOAD_DIALOG.INFO_TEXT' | translate"
                    ></div>

                    <app-button
                        class="file-browser-button"
                        [color]="'secondary'"
                        buttonSize="normal"
                        (click)="openFileBrowser()"
                        [disabled]="isUploadDisabled"
                    >
                        {{ 'IMAGE_UPLOAD_DIALOG.CHOOSE_FILE_BUTTON' | translate }}
                    </app-button>
                    <form class="contour-upload-foam" enctype="multipart/form-data">
                        <input
                            #fileInput
                            type="file"
                            id="contour-file"
                            name="contour-file"
                            accept=".jpg, .jpeg, .png, .tif, .tiff"
                            multiple
                            (change)="uploadFiles($event.target.files)"
                            [disabled]="isUploadDisabled"
                        />
                    </form>
                    <div class="accepted-format">
                        {{ 'IMAGE_UPLOAD_DIALOG.FILE_FORMAT' | translate }}
                    </div>
                </div>

                <div class="upload-files__container">
                    <table *ngIf="showImagesToUpload" class="upload-files__table">
                        <thead>
                            <tr class="upload-files__table_header">
                                <th>
                                    {{ 'IMAGE_UPLOAD_DIALOG.FILE' | translate }} (
                                    {{ uploadedFiles.length }} / {{ maxUploadCount }} )
                                </th>
                                <th>{{ 'IMAGE_UPLOAD_DIALOG.FILE_SIZE' | translate }}</th>
                                <th>{{ 'IMAGE_UPLOAD_DIALOG.STATUS' | translate }}</th>
                                <th>{{ 'IMAGE_UPLOAD_DIALOG.DELETE' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let fileInfo of uploadedFiles"
                                [class.loaded]="fileInfo.isDone"
                            >
                                <td>
                                    <div class="image-name-n-preview-container">
                                        <img
                                            *ngIf="fileInfo.previewImageSrc != null"
                                            class="preview-image"
                                            [attr.src]="fileInfo.previewImageSrc"
                                        />
                                        <div class="file-name-text">{{ fileInfo.fileName }}</div>
                                    </div>
                                </td>
                                <td>{{ fileInfo.fileSize }}</td>
                                <td>
                                    {{ fileInfo.progressPercent }}%
                                    <app-icon
                                        *ngIf="fileInfo.isDone"
                                        class="upload-done-icon"
                                        shape="check-circle"
                                        iconSize="small"
                                    >
                                    </app-icon>
                                </td>
                                <td>
                                    <app-button
                                        app-icon-button
                                        buttonSize="xx-small"
                                        appTooltip
                                        appToolTipContent="{{ 'MOUSEOVER.DELETE' | translate }}"
                                        [appTooltipPlacement]="'right'"
                                        (click)="deleteUploadFile(fileInfo.fileName)"
                                    >
                                        <app-icon
                                            shape="bin"
                                            iconSize="small"
                                            cssWidth="18px"
                                            cssHeight="19px"
                                        ></app-icon>
                                    </app-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-upload-dialog-footer></app-upload-dialog-footer>
