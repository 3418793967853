import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Observer, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

// Taken and adapted from https://github.com/angular/components/src/cdk/layout/breakpoints-observer.ts

/** The current state of a layout breakpoint. */
export interface BreakpointState {
    /** Whether the breakpoint is currently matching. */
    matches: boolean;
}

interface Query {
    observable: Observable<BreakpointState>;
    mql: MediaQueryList;
}

@Injectable({
    providedIn: 'root'
})
export class BreakpointObserverService implements OnDestroy {
    /**  A map of all media queries currently being listened for. */
    private _queries: Map<string, Query> = new Map();
    private _destroySubject: Subject<{}> = new Subject();

    constructor(private ngZone: NgZone) {}

    ngOnDestroy() {
        this._destroySubject.next();
        this._destroySubject.complete();
    }

    /**
     * Whether one or more media queries match the current viewport size.
     * @param value One or more media queries to check.
     * @returns Whether any of the media queries match.
     */
    isMatched(value: string | string[]): boolean {
        value = Array.isArray(value) ? value : [value];
        const queries = splitQueries(value);
        const _matchMedia = window.matchMedia.bind(window);
        return queries.some(mediaQuery => (_matchMedia(mediaQuery) as MediaQueryList).matches);
    }

    observe(value: string | string[]): Observable<BreakpointState> {
        value = Array.isArray(value) ? value : [value];
        const queries = splitQueries(value);
        const observables = queries.map(query => this._registerQuery(query).observable);

        return combineLatest(observables).pipe(
            map((breakpointStates: BreakpointState[]) => {
                return {
                    matches: breakpointStates.some(state => state && state.matches)
                };
            })
        );
    }

    /** Registers a specific query to be listened for. */
    private _registerQuery(query: string): Query {
        // Only set up a new MediaQueryList if it is not already being listened for.
        if (this._queries.has(query)) {
            return this._queries.get(query)!;
        }

        const _matchMedia = window.matchMedia.bind(window);
        const mql: MediaQueryList = _matchMedia(query);
        // Create callback for match changes and add it is as a listener.
        const queryObservable = new Observable<MediaQueryList>(
            (observer: Observer<MediaQueryList>) => {
                // Listener callback methods are wrapped to be placed back in ngZone. Callbacks must be placed
                // back into the zone because matchMedia is only included in Zone.js by loading the
                // webapis-media-query.js file alongside the zone.js file.  Additionally, some browsers do not
                // have MediaQueryList inherit from EventTarget, which causes inconsistencies in how Zone.js
                // patches it.
                const handler = (e: any) => this.ngZone.run(() => observer.next(e));
                mql.addListener(handler);
                return () => {
                    mql.removeListener(handler);
                };
            }
        ).pipe(
            takeUntil(this._destroySubject),
            startWith(mql),
            map((nextMql: MediaQueryList) => ({ matches: nextMql.matches }))
        );

        // Add the MediaQueryList to the set of queries.
        const output = { observable: queryObservable, mql: mql };
        this._queries.set(query, output);
        return output;
    }
}

/**
 * Split each query string into separate query strings if two queries are provided as comma
 * separated.
 */
function splitQueries(queries: string[]): string[] {
    return queries
        .map((query: string) => query.split(','))
        .reduce((a1: string[], a2: string[]) => a1.concat(a2))
        .map(query => query.trim());
}
