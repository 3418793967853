import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UploadDialogService } from '../upload-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadDialogContent } from '../upload-dialog.component';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-upload-dialog-footer',
    templateUrl: './upload-dialog-footer.component.html',
    styleUrls: ['./upload-dialog-footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadDialogFooterComponent implements OnInit, OnDestroy {
    isNextButtonDisabled: boolean = true;
    forwardButtonText: string;
    backwardButtonText: string;
    backwardButtonIcon: string = 'icon-close';

    private buttonBackText: string;
    private buttonNextText: string;
    private buttonFinishText: string;
    private buttonCancelText: string;

    private onDestroyed: Subject<void> = new Subject();
    showSpinnerOnNextButton = false;

    constructor(
        private uploadDialogService: UploadDialogService,
        private translateService: TranslateService
    ) {
        this.translateService.stream(['NAVIGATION_BUTTON']).subscribe(res => {
            this.buttonBackText = res.NAVIGATION_BUTTON.BACK;
            this.buttonNextText = res.NAVIGATION_BUTTON.NEXT;
            this.buttonFinishText = res.NAVIGATION_BUTTON.FINISH;
            this.buttonCancelText = res.NAVIGATION_BUTTON.CANCEL;
        });
    }

    ngOnInit() {
        this.uploadDialogService
            .getContentChanged()
            .pipe(
                takeUntil(this.onDestroyed),
                filter(content => content !== null)
            )
            .subscribe((content: UploadDialogContent<any>) => {
                this.updateNavButtons();
            });

        this.uploadDialogService
            .getContentValidityChange()
            .pipe(takeUntil(this.onDestroyed))
            .subscribe(isValid => {
                this.isNextButtonDisabled = !isValid;
            });
    }

    ngOnDestroy(): void {
        this.onDestroyed.next();
    }

    previousContent() {
        this.uploadDialogService.loadPreviousContent();
    }

    nextContent() {
        this.uploadDialogService.loadNextContent();
        this.showSpinnerOnNextButton = this.uploadDialogService.isSaving;
    }

    private updateNavButtons() {
        if (this.uploadDialogService.hasPreviousContent()) {
            this.backwardButtonIcon = 'arrow_left';
            this.backwardButtonText = this.buttonBackText;
        } else {
            this.backwardButtonIcon = 'icon-close';
            this.backwardButtonText = this.buttonCancelText;
        }

        this.forwardButtonText = this.uploadDialogService.hasNextContent()
            ? this.buttonNextText
            : this.buttonFinishText;
    }
}
