import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationSharedModule } from '../../shared/translation-shared.module';
import { SharedModule } from '../../shared/shared.module';
import { DialogModule } from '../../dialog/dialog.module';
import { ScrollViewportModule } from '../scroll-viewport/scroll-viewport.module';
import { ToolsContourPanelComponent } from './ribbon-panels/tools-contour-panel/tools-contour-panel.component';
import { GripTextContourPanelComponent } from './ribbon-panels/grip-text-contour-panel/grip-text-contour-panel.component';
import { OverviewPanelComponent } from './ribbon-panels/overview-panel/overview-panel.component';
import { AlignmentRibbonDialogComponent } from './ribbon-panels/ribbon-dialog/alignment-dialog/alignment-ribbon-dialog.component';
import { RibbonComponent } from './ribbon.component';
import { RibbonTabsComponent } from './ribbon-tabs/ribbon-tabs.component';
import { RibbonTabItemComponent } from './ribbon-tabs/ribbon-tab-item/ribbon-tab-item.component';
import { RibbonToolbarContentDirective } from './ribbon-panels/ribbon-toolbar-content.directive';
import { RibbonPanelContentComponent } from './ribbon-panels/ribbon-panel-content/ribbon-panel-content.component';
import { RibbonDialogContentDirective } from './ribbon-panels/ribbon-dialog-content.directive';
import { AccordionTopbarComponent } from './ribbon-panels/ribbon-accordion-topbar/accordion-topbar.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { AccordionDirective } from './expansion-panel/accordion.directive';
import { AccordionItemDirective } from './expansion-panel/accordion-item.directive';
import {
    AppExpansionPanelTitleDirective,
    ExpansionPanelHeaderComponent
} from './expansion-panel/expansion-panel-header.component';
import { ImageContoursBrowserComponent } from './ribbon-panels/tools-contour-panel/contour-browser/image-contours-browser.component';
import { DroppableModule } from '../../droppable/droppable.module';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        TranslationSharedModule,
        // App modules
        SharedModule,
        DialogModule,
        ScrollViewportModule,
        DroppableModule
    ],
    declarations: [
        RibbonComponent,
        RibbonTabsComponent,
        RibbonTabItemComponent,
        RibbonToolbarContentDirective,
        RibbonPanelContentComponent,
        RibbonDialogContentDirective,
        AlignmentRibbonDialogComponent,
        ToolsContourPanelComponent,
        GripTextContourPanelComponent,
        OverviewPanelComponent,
        AccordionTopbarComponent,
        ExpansionPanelComponent,
        AccordionDirective,
        AccordionItemDirective,
        ExpansionPanelHeaderComponent,
        AppExpansionPanelTitleDirective,
        ImageContoursBrowserComponent
    ],
    exports: [RibbonComponent],
    entryComponents: [
        ToolsContourPanelComponent,
        GripTextContourPanelComponent,
        OverviewPanelComponent,
        AlignmentRibbonDialogComponent
    ]
})
export class RibbonModule {}
