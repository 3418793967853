import { Point2D } from '../../../../../shared/geom/point2D';

export function getPixelIndex(x: number, y: number, width: number, channels: number = 1) {
    return (Math.floor(x) + Math.floor(y) * width) * channels;
}

export function getPixelCoordinate(index: number, width: number): { x: number; y: number } {
    const x = index % width;
    const y = (index - x) / width;
    return { x: x, y: y };
}

export function create4NeighborPixels(
    pixelIndex: number,
    width: number,
    height: number,
    neighborsOnly: boolean = false
): number[] {
    const neighbors = [];
    // clamping left
    if (pixelIndex % width > 0) {
        neighbors.push(pixelIndex - 1);
    }
    // clamping right
    if (pixelIndex % width !== width - 1) {
        neighbors.push(pixelIndex + 1);
    }
    // clamping top
    if (pixelIndex > width - 1) {
        neighbors.push(pixelIndex - width);
    }

    // clamping bottom
    if (pixelIndex < height * (width - 1)) {
        neighbors.push(pixelIndex + width);
    }

    // pixelIndex - 1, pixelIndex + 1, pixelIndex - width, pixelIndex + width];
    return neighborsOnly ? neighbors : [pixelIndex, ...neighbors];
}

/**
 *
 * @param pixelIndex
 * @param width
 * @param height
 * @param neighborsOnly
 * @param result should have length of 8 if neighborsOnly or 9
 */
export function create8NeighborPixels(
    pixelIndex: number,
    width: number,
    height: number,
    neighborsOnly: boolean = false,
    result?: number[] | undefined
): number[] {
    const neighbors = Array.isArray(result) ? result : new Array(neighborsOnly ? 8 : 0);
    let i = -1;
    // clamping left
    let left = false;
    if (pixelIndex % width > 0) {
        neighbors[++i] = pixelIndex - 1;
        left = true;
    }
    // clamping right
    let right = false;
    if (pixelIndex % width !== width - 1) {
        neighbors[++i] = pixelIndex + 1;
        right = true;
    }
    // clamping top
    let top = false;
    if (pixelIndex > width - 1) {
        neighbors[++i] = pixelIndex - width;
        top = true;
    }

    // clamping bottom
    let bottom = false;
    if (pixelIndex < height * (width - 1)) {
        neighbors[++i] = pixelIndex + width;
        bottom = true;
    }

    if (left && top) {
        neighbors[++i] = pixelIndex - width - 1;
    }

    if (right && top) {
        neighbors[++i] = pixelIndex + 1 - width;
    }
    if (right && bottom) {
        neighbors[++i] = pixelIndex + 1 + width;
    }

    if (left && bottom) {
        neighbors[++i] = pixelIndex - 1 + width;
    }

    // pixelIndex - 1, pixelIndex + 1, pixelIndex - width, pixelIndex + width];
    if (Array.isArray(result)) {
        return undefined;
    }
    return neighborsOnly ? neighbors : [pixelIndex, ...neighbors];
}

export type RGBTuple = [number, number, number];
export type PixelInfo = {
    color: RGBTuple;
    summedColor: number;
    coordinate: { x: number; y: number };
};
