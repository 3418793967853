import { CanvasContour } from '../canvas/contour/contour-items-interfaces';
import { UndoableEditaAction } from './undo-manager.service';
import { CanvasService } from '../canvas/canvas.service';

export class DeleteUndoableEditAction implements UndoableEditaAction {
    readonly presentationName: string;
    private readonly localContourPathMatrices: { [id: string]: Snap.Matrix };
    private readonly canvasService: CanvasService;

    constructor(private readonly contours: CanvasContour[], presentationName?: string) {
        if (contours.length < 1 || !contours[0]) {
            throw Error('contours cannot be undefined');
        }

        this.presentationName = presentationName || 'Delete contour';
        this.localContourPathMatrices = {};
        this.contours.forEach(x => {
            this.localContourPathMatrices[x.contourId] = x.localContourPathMatrix.clone();
        });
        this.canvasService = this.contours[0].getCanvas();
    }

    redo(): void {
        this.canvasService.removeContours(this.contours);
    }

    undo(): void {
        this.contours.forEach(contour => {
            const added = this.canvasService.addContourItem(contour);
            if (added) {
                contour.transform(this.localContourPathMatrices[contour.contourId]);
            }
        });

        this.canvasService.clearSelection();
        this.canvasService.addToSelection(this.contours);
    }
}
