/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toggle-switch.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./toggle-switch.component";
var styles_ToggleSwitchComponent = [i0.styles];
var RenderType_ToggleSwitchComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ToggleSwitchComponent, data: {} });
export { RenderType_ToggleSwitchComponent as RenderType_ToggleSwitchComponent };
export function View_ToggleSwitchComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { labelElement: 0 }), i1.ɵqud(671088640, 2, { containerElement: 0 }), i1.ɵqud(671088640, 3, { inputElement: 0 }), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["container", 1]], null, 3, "div", [["class", "toggle-switch"]], [[4, "height", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[3, 0], ["inputElement", 1]], null, 0, "input", [["type", "checkbox"]], [[8, "id", 0], [8, "disabled", 0], [8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onInputClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["labelElement", 1]], null, 1, "label", [], [[8, "htmlFor", 0], [2, "toggle-switch__disabled", null], [4, "width", null], [4, "height", null]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelHeight; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.inputId; var currVal_2 = _co.isDisabled; var currVal_3 = _co.checked; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.inputId; var currVal_5 = _co.isDisabled; var currVal_6 = _co.labelWidth; var currVal_7 = _co.labelHeight; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ToggleSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toggle-switch", [], null, null, null, View_ToggleSwitchComponent_0, RenderType_ToggleSwitchComponent)), i1.ɵdid(1, 114688, null, 0, i2.ToggleSwitchComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToggleSwitchComponentNgFactory = i1.ɵccf("app-toggle-switch", i2.ToggleSwitchComponent, View_ToggleSwitchComponent_Host_0, { inputId: "inputId", isDisabled: "isDisabled", checked: "checked", labelWidth: "labelWidth", labelHeight: "labelHeight" }, {}, ["*"]);
export { ToggleSwitchComponentNgFactory as ToggleSwitchComponentNgFactory };
