<app-dialog-header>
    <h1 class="app-dialog-title">{{ 'PARTIAL_CUTTING_DIALOG.TITLE' | translate }}</h1>
</app-dialog-header>
<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <p>{{ 'PARTIAL_CUTTING_DIALOG.MESSAGE' | translate }}</p>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'primary'"
            buttonSize="normal"
            textPosition="before"
            (click)="processed()"
        >
            {{ 'PARTIAL_CUTTING_DIALOG.OK_BUTTON' | translate }}
            <app-icon svgWidth="24" svgHeight="24" shape="arrow_right" iconSize="small"> </app-icon>
        </app-button>
    </div>
</app-dialog-footer>
