<div appCanvasScrollViewport hasScrollbar="false" class="canvas-container canvas-viewport">
    <svg
        #canvasContent
        xmlns="http://www.w3.org/2000/svg"
        xlinks="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        id="svg-canvas"
    >
        <g #layerItems class="layer-items">
            <g #canvasBackground class="underlay-bg-layer">
                <image
                    *ngIf="backgroundImage"
                    x="0"
                    y="0"
                    [attr.width]="backgroundImage?.width"
                    [attr.height]="backgroundImage?.height"
                    [attr.xlink:href]="backgroundImage.image"
                ></image>
            </g>
            <g #layerContours class="layer-contours"></g>
            <g class="draw-paths"></g>
            <g class="debug-all-contours"></g>
        </g>
    </svg>

    <canvas
        #borderCanvas
        class="paint-canvas border-paint-canvas"
        [attr.width]="backgroundImage?.width"
        [attr.height]="backgroundImage?.height"
    ></canvas>
    <canvas
        #paintCanvas
        class="paint-canvas canvas-2"
        [attr.width]="backgroundImage?.width"
        [attr.height]="backgroundImage?.height"
    ></canvas>
    <canvas
        #brushCanvas
        class="brush-canvas canvas-3"
        [attr.width]="backgroundImage?.width"
        [attr.height]="backgroundImage?.height"
    >
    </canvas>
</div>
