import { Directive, ElementRef, OnInit } from '@angular/core';

import { Rectangle2D } from '../../shared/geom/rectangle2D';
import {
    CollisionChangedEvent,
    CollisionEventNotifier
} from '../collision/collision-event-notifier';
import { CanvasService, SelectionChangedData } from '../canvas.service';
import {
    ContourElementHolder,
    GroupContourElementHolder,
    TextContourElementHolder
} from '../contour-element-builder.service';
import {
    ContourChangeData,
    ContourItemsRemoved,
    CuttableContourDrawData
} from '../contour/contour-items-interfaces';
import { LayerElementStoreService } from '../layer-element-store.service';

import { CanvasContourLayer } from './canvas-contour-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';
import { CanvasLayerWithSubscriptionMixin } from './destroyable-layer-mixin';

declare var Snap: any;
const ICON_WIDTH = 24;
const ICON_HEIGHT = 24;

@Directive({
    selector: '[appCanvasOverlayLayer]'
})
export class CanvasOverlayLayerDirective
    extends CanvasLayerMixin(CanvasLayerWithSubscriptionMixin())
    implements CanvasContourLayer<Snap.Paper>, OnInit {
    /**
     * @deprecated use element
     */
    svgElement: Snap.Paper;
    element: Snap.Paper;
    private badges: { [contourIdex: string]: BadgesContainer } = {};
    private addedContainers: { [contourIdex: string]: Snap.Paper } = {};

    constructor(
        elementRef: ElementRef,
        private canvasService: CanvasService,
        private layerElementStore: LayerElementStoreService,
        private collisionEventNotifier: CollisionEventNotifier
    ) {
        super();
        this.svgElement = this.element = Snap(elementRef.nativeElement);
        /*
        this.addSubscription(
            this.workspaceItemService.onItemRemoved.subscribe(woItem => {
                if (!woItem) {
                    console.error('Failed to remove contour ');
                }
                this.removeItemBadges(woItem.contourId);
            })
        ); */
    }

    ngOnInit(): void {
        this.collisionEventNotifier.onDynamicCollisionChanged.subscribe(
            (collisionEvent: CollisionChangedEvent) => {
                if (collisionEvent.addedItems) {
                    collisionEvent.addedItems.forEach(contourId => {
                        this.addCollisionWarning(contourId);
                    });
                }
                if (collisionEvent.removedItems) {
                    collisionEvent.removedItems.forEach(contourId => {
                        this.setWarningBadgeVisibility(contourId, false);
                    });
                }
            }
        );
    }

    getBadgesContainerOrAddIfAbsent(contourIndex: string): BadgesContainer {
        let badgeContainer = this.badges[contourIndex];
        if (!badgeContainer) {
            const svgContainer = this.svgElement.g() as Snap.Paper;
            svgContainer.node.classList.add('badges-' + contourIndex);

            badgeContainer = { container: svgContainer };
            badgeContainer.warningBadge = svgContainer.svg(
                null,
                null,
                24,
                24,
                0,
                0,
                32,
                32
            ) as Snap.Paper;
            badgeContainer.warningBadge.addClass('badge-item');

            const use = badgeContainer.warningBadge.use(badgeContainer.warningBadge) as Snap.Paper;
            use.attr({
                class: 'badge-warning',
                href: '/assets/icons/icons-defs.svg#warning',
                'pointer-events': 'none'
            });

            badgeContainer.warningBadge = use;
            this.badges[contourIndex] = badgeContainer;
        }

        return badgeContainer;
    }

    addCollisionWarning(contourId: string) {
        const woItem = this.canvasService.getContourItem(contourId, true);
        if (!woItem) {
            if (this.canvasService.getFoamContourItem().contourId !== contourId) {
                const innerFoam = this.canvasService.getFoamContourItem().foamInnerMarginContour;
                if (innerFoam && innerFoam.contourId !== contourId) {
                    console.error('contour item not found');
                }
            }

            return;
        }

        const itemBadgeContainer = this.getBadgesContainerOrAddIfAbsent(contourId);

        const elementHolder = this.layerElementStore.getContourElements2(contourId, true);
        if (!(elementHolder instanceof GroupContourElementHolder)) {
            this.transformBadgeContainer(
                itemBadgeContainer.container,
                elementHolder.contourElements.transformData.globalBBox
            );
        } else {
            console.error('should be transformBadgeContainer');
        }

        // itemBadgeContainer.warningBadge.attr({ visibility: 'visible' });

        this.setWarningBadgeVisibility(contourId, woItem.getVisibility());
        this.addedContainers[contourId] = itemBadgeContainer.container;
    }

    removeCollisionWarning(contourIndex: string) {
        const container = this.badges[contourIndex];
        if (container && container.warningBadge) {
            container.warningBadge.attr({ visibility: 'hidden' });
            // container.warningBadge.remove();
            // container.warningBadge = undefined;
        }
    }

    setWarningBadgeVisibility(contourId: string, visible: boolean) {
        const container = this.badges[contourId];
        if (container && container.warningBadge) {
            container.warningBadge.node.style.visibility = visible ? 'visible' : 'hidden';
        }
    }

    /**
     * @Depreacted
     * @param contourIndex
     */
    removeItemBadges(contourIndex: string) {
        const itemBadgeContainer = this.badges[contourIndex];
        if (itemBadgeContainer) {
            itemBadgeContainer.container.remove();
            delete this.badges[contourIndex];
        }
    }

    private transformBadgeContainer(itemBadgesContainer: Snap.Paper, boundingBox: Rectangle2D) {
        const tx = boundingBox.cx - ICON_WIDTH / 2;
        const ty = boundingBox.cy - ICON_HEIGHT / 2;
        itemBadgesContainer.node.setAttribute('transform', `translate(${tx}, ${ty})`);
    }

    /**
     * @param contourId
     * @param boundingBox
     */
    updateBadgePosition(contourId: string, boundingBox: Rectangle2D) {
        const itemBadgesContainer = this.addedContainers[contourId];
        if (itemBadgesContainer) {
            this.transformBadgeContainer(itemBadgesContainer, boundingBox);
        }
    }

    addContourItem(contourData: CuttableContourDrawData) {
        return undefined;
    }

    setBadgeVisibility(
        holder: ContourElementHolder | TextContourElementHolder | GroupContourElementHolder,
        visibility: boolean
    ): void {
        if (holder instanceof GroupContourElementHolder) {
            const groupChildHolders = Object.values(holder.elementHolders);
            groupChildHolders.forEach(childHolder => {
                this.setWarningBadgeVisibility(childHolder.contourId, visibility);
            });
        } else {
            this.setWarningBadgeVisibility(holder.contourId, visibility);
        }
    }

    changeContourItem(contourData: ContourChangeData): void {
        /*  const holders = this.layerElementStore.getContourElements2(contourData.contourId);
          if (!holders) {
              return;
          }

          if (holders instanceof GroupContourElementHolder) {
              Object.values(holders.elementHolders).forEach(holder => {
                  if (contourData.visibility !== undefined) {
                      this.setWarningBadgeVisibility(holder.contourId, contourData.visibility);
                  }
              });
          } else {
              if (contourData.visibility !== undefined) {
                  this.setWarningBadgeVisibility(contourData.contourId, contourData.visibility);
              }
          } */
    }

    removeContourItem(contourData: ContourItemsRemoved) {
        if (!contourData.contourIds) {
            return;
        }

        contourData.contourIds.forEach(contourId => {
            const itemBadgeContainer = this.badges[contourId];
            if (itemBadgeContainer) {
                itemBadgeContainer.container.remove();
                delete this.badges[contourId];
            }
        });
    }

    selectContourItems(data: SelectionChangedData) {}
}

interface BadgesContainer {
    container: Snap.Paper | undefined;
    warningBadge?: Snap.Paper | undefined;
    infoBadge?: Snap.Paper | undefined;
}
