import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
    Type,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CanvasComponent } from './canvas/canvas.component';
import { CanvasService } from './canvas/canvas.service';
import {
    ContourProperties,
    ContourPropertiesComponent
} from './contour-properties/contour-properties.component';
import { CompletionDialogComponent } from './dialogs/completion-dialog/completion-dialog.component';
import { ProjectPropertyComponent } from './project-property/project-property.component';
import { OverviewPanelComponent } from './ribbon/ribbon-panels/overview-panel/overview-panel.component';
import { TabItemInfo } from './ribbon/ribbon.component';
import { CollisionEventNotifier } from './canvas/collision/collision-event-notifier';
import { DialogService } from '../dialog/dialog.service';

import { FoamEditorService } from './foam-editor.service';
import { Rectangle2D } from './shared/geom/rectangle2D';
import { computeRelativeScaleFactor, ScaleFactorState } from './canvas/shared/scalefactor-state';
import { ZoomAction, ZoomActionEvent } from './actions/zoom.action';
import { SaveDialogComponent } from './dialogs/save-dialog/save-dialog.component';
import { ConfiguratorStoreService } from '../configurator-store.service';
import { ModelMapper } from '../models/model.mapper';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { RibbonSelectionService } from './ribbon/ribbon-selection.service';
import { CollisionHandlerService } from './canvas/collision/collision-handler.service';
import { CollisionGraph } from './canvas/collision/collision-graph';
import { StaticCollisionGraph } from './canvas/collision/static-collision-graph';
import { CanvasDomService } from './canvas/canvas-dom.service';
import { DroppableStateStore } from '../droppable/droppable-state-store.service';
import { RecessedGripCollisionService } from './canvas/recessed-grip-collision-service';
import { CanvasDroppableHandler } from './canvas/canvas-droppable-handler.service';

/**
 * This component contains the foam editor's canvas, the ribbon menu and
 * It acts as mediator for coordinating tools and canvas
 */

@Component({
    selector: 'app-foam-editor',
    templateUrl: './foam-editor.component.html',
    styleUrls: ['./foam-editor.component.scss'],
    providers: [
        DialogService,
        FoamEditorService,
        CanvasService,
        CollisionEventNotifier,
        CollisionHandlerService,
        CollisionGraph,
        StaticCollisionGraph,
        CollisionEventNotifier,
        CanvasDomService,
        DroppableStateStore,
        RecessedGripCollisionService,
        CanvasDroppableHandler
    ]
})
export class FoamEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(ProjectPropertyComponent, { static: false })
    readonly projectProperty: ProjectPropertyComponent;

    @ViewChild(ContourPropertiesComponent, { static: false })
    private contourPropertiesComponent: ContourPropertiesComponent;

    @ViewChild(CanvasComponent, { static: false })
    readonly canvasComponent: CanvasComponent;

    @ViewChild('mainWindow', { static: false })
    readonly mainWindowRef: ElementRef;

    collisionChange: Observable<any>;

    contourProperties: Observable<ContourProperties>;

    showEditActions: boolean = true;
    showZoomPanel: boolean = true;
    displayFunctions: boolean = true;

    showContourProperties: boolean;
    showCompletionButtons = false;
    zoomValue: number;
    zoomStep: number = 10;
    boxName: Observable<string>;
    price: string;
    vat: string;

    private readonly canvasService: CanvasService;
    private onDestroy = new Subject<void>();

    readonly isSavingFoamConfiguration = new BehaviorSubject<boolean>(false);

    private zoomActionIn: ZoomAction;
    private zoomActionOut: ZoomAction;

    /** Emits once during the component's ngOnInit */
    readonly onInitialized = new ReplaySubject<boolean>(1);

    configurationName: string;
    saveToolTipContent: string;
    saveBasketToolTipContent: string;

    constructor(
        private foamEditorService: FoamEditorService,
        private dialogService: DialogService,
        private ribbonSelectionService: RibbonSelectionService,
        private configuratorStoreService: ConfiguratorStoreService,
        private collisionEventNotifier: CollisionEventNotifier,
        private translateService: TranslateService
    ) {
        this.contourProperties = this.foamEditorService.getSelectedContourProperties();
        this.canvasService = this.foamEditorService.getCanvasService();
        this.zoomActionIn = new ZoomAction(this.foamEditorService);
        this.zoomActionOut = new ZoomAction(this.foamEditorService);
    }

    ngOnInit() {
        // TODO duplicate code
        this.ribbonSelectionService.ongRibbonTabSelected.subscribe((tabInfo: TabItemInfo) => {
            this.showEditActions = !(tabInfo && tabInfo.panelComponent === OverviewPanelComponent);
        });
        this.foamEditorService.initialize();
    }

    ngAfterViewInit() {
        this.foamEditorService.foamConfigLoaded.subscribe(() => {
            const {
                languageKey,
                languageContent
            } = this.configuratorStoreService.getLanguageSettings();
            this.translateService.setTranslation(languageKey, languageContent);
            this.translateService.use(languageKey);

            const foamItem = this.foamEditorService.getFoam();
            this.boxName = this.translateService.stream('EDITOR.INLAY_THICKNESS', {
                boxName: foamItem.title
            });

            this.translateService
                .stream('EDITOR.PRICE_VAT', {
                    vat: this.foamEditorService.getVat()
                })
                .subscribe(translatedVat => {
                    this.vat = translatedVat;
                });

            this.price = this.foamEditorService.getPrice();

            const foamConfig = this.foamEditorService.getFoamConfiguration();
            this.configurationName = foamConfig.configurationName;

            this.foamEditorService.getFoamConfiguration().contours.forEach(item => {
                const contourItem = ModelMapper.toCanvasContour(
                    item,
                    this.foamEditorService.getContoursStartDimensions()
                );
                this.canvasService.addContourItem(contourItem);
                contourItem.transform(item.positionData.localMatrix);
            });

            this.foamEditorService.setInputEvents(this.canvasComponent.getInputEvents());
            this.foamEditorService.getInputEvents().subscribe(inputEvents => {
                if (inputEvents) {
                    inputEvents.mouseDowns.subscribe(() => {
                        //   this.contourPropertiesComponent.blurInputElements();
                        //  this.projectProperty.layoutNameTextInput.nativeElement.blur();
                    });
                }
            });

            this.collisionChange = this.foamEditorService.collisionChange;

            this.foamEditorService
                .canBeSaved()
                .pipe(filter(can => !can))
                .subscribe(() => this.updateSaveToolTipContent());

            this.calculateCanvasBounds();
            this.onInitialized.next(true);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }

    scaleFactorChanged(state: ScaleFactorState) {
        this.zoomStep = 10 * state.scaleFactorToFitViewport;
        this.zoomValue = computeRelativeScaleFactor(
            state.scaleFactor,
            state.scaleFactorToFitViewport
        );
    }

    zoomIn(event: ZoomActionEvent) {
        this.zoomActionIn.execute(event);
    }

    zoomOut(event: ZoomActionEvent) {
        this.zoomActionOut.execute(event);
    }

    calculateCanvasBounds() {
        // Calculate the height based on the current width in order to preserve the aspect ratio
        const infoPanelHeight = document.querySelector('.workspace-info-wrapper').clientHeight;
        const editorHeight = document.querySelector('.editor-wrapper').clientHeight;
        const projectPropertiesHeight = document.querySelector('.project-properties').clientHeight;

        const newHeight = editorHeight - projectPropertiesHeight - infoPanelHeight;
        const width = (this.canvasComponent.elementRef.nativeElement as HTMLElement).clientWidth;

        this.canvasComponent.setCanvasViewport(new Rectangle2D(0, 0, width, newHeight), true, true);
    }

    onRibbonPanelChanged(tab: TabItemInfo) {
        if (tab.panelComponent === OverviewPanelComponent) {
            if (this.canvasComponent) {
                this.canvasComponent.enabled = false;
            }
            this.showCompletionButtons = true;
            this.showZoomPanel = false;
            this.displayFunctions = false;
            this.showContourProperties = false;

            this.foamEditorService.getCanvasService().clearSelection();
            this.foamEditorService.getCanvasService().setProperties({
                showContourImage: false,
                showContourSafeMargin: false
            });
        } else {
            if (this.canvasComponent) {
                this.canvasComponent.enabled = true;
            }
            this.showCompletionButtons = false;
            this.showZoomPanel = true;
            this.displayFunctions = true;
            this.showContourProperties = true;
            this.foamEditorService.getCanvasService().setProperties({
                showContourImage: true,
                showContourSafeMargin: true
            });
        }
    }

    public canBeSaved(): Observable<boolean> {
        return this.foamEditorService.canBeSaved();
    }

    private updateSaveToolTipContent(): string | undefined {
        if (this.collisionChange) {
            this.saveToolTipContent = this.translateService.instant(
                'MOUSEOVER.COLLISION_IN_OVERVIEW'
            );
            this.saveBasketToolTipContent = this.translateService.instant(
                'MOUSEOVER.COLLISION_IN_OVERVIEW'
            );
        } else if (this.foamEditorService.getCanvasService().getAllContourItems().length === 0) {
            this.saveToolTipContent = this.translateService.instant(
                'MOUSEOVER.BUTTON_SAVE_DISABLED'
            );
            this.saveBasketToolTipContent = this.translateService.instant(
                'MOUSEOVER.BUTTON_BASKET_DISABLED'
            );
        }
        return undefined;
    }

    onConfigNameChange(newConfiguration: string) {
        this.foamEditorService.getFoamConfiguration().configurationName = newConfiguration;
    }

    public openSaveDialog() {
        const redirectUrl = this.getSaveRedirectUrl('afterSave');
        this.openCompletionDialog(SaveDialogComponent, redirectUrl);
    }

    public openSaveToBasketDialog() {
        const redirectUrl = this.getSaveRedirectUrl('afterBasket');
        this.openCompletionDialog(CompletionDialogComponent, redirectUrl);
    }

    private getSaveRedirectUrl(redirectUrlKey: string): string | undefined {
        return environment.redirectUrls ? environment!.redirectUrls[redirectUrlKey] : undefined;
    }

    private openCompletionDialog<T>(dialog: any, target: string) {
        if (!this.foamEditorService.hasCollision()) {
            const dialogRef = this.dialogService.openDialog(dialog);

            dialogRef.afterClosed.subscribe((save: boolean) => {
                if (save) {
                    this.isSavingFoamConfiguration.next(true);
                    this.foamEditorService.saveFoamConfig().subscribe(
                        nextValue => {
                            this.isSavingFoamConfiguration.next(false);

                            if (target !== undefined && window) {
                                const referrerUrl = this.getFullReferrerUrl(
                                    this.translateService.currentLang
                                );

                                const navigationTarget = `${referrerUrl}/${target}/?configId=${nextValue}&sessionId=${this.configuratorStoreService.getSessionRef()}`;
                                console.log('Success: redirecting to ' + navigationTarget);
                                window.location.href = navigationTarget;
                            }
                        },
                        // () => {
                        err => {
                            console.log('Error:' + err);
                            this.isSavingFoamConfiguration.next(false);
                            this.dialogService.openDialog(ErrorDialogComponent);
                        },
                        () => {
                            console.log('completed Server request');
                        }
                    );
                }
            });
        }
    }

    /**
     * Returns full version of the document.referrer without trailing slash
     *
     * @param language
     * @private
     */
    private getFullReferrerUrl(language: string): string {
        // remove trailing slash
        const referrerUrl = document.referrer.replace(/\/$/, '');

        // referrer just contains the domain
        if (referrerUrl !== '' && referrerUrl.indexOf('inlay/editor') < 0) {
            return referrerUrl + '/' + language + '/inlay/editor';
        }

        return referrerUrl;
    }
}
