declare var Snap: any;
/* eslint no-prototype-builtins: "warn" */

export class ModelDecoder {
    static number(value: any, defaultValue?: number): number {
        const numberValue = Number(value);
        if (!isNaN(numberValue)) {
            return numberValue;
        }

        if (defaultValue !== undefined) {
            return defaultValue;
        }

        throw new Error(`Invalid value type; expected Number, received ${JSON.stringify(value)}.`);
    }

    static string(value: any, predicate?: (value: string) => boolean): string {
        if (typeof value === 'string') {
            if (predicate && !predicate(value)) {
                throw new Error(
                    `Invalid value content; Failed predicate test, received ${JSON.stringify(
                        value
                    )}.`
                );
            }
            return value;
        }

        throw new Error(`Invalid value type; expected String, received ${JSON.stringify(value)}.`);
    }

    static boolean(value: any, defaultValue: boolean): boolean {
        if (typeof value === 'boolean') {
            return value;
        }

        if (defaultValue !== undefined) {
            return defaultValue;
        }

        throw new Error(`Invalid value type; expected Boolean, received ${JSON.stringify(value)}.`);
    }

    static object<T>(value: any, decoders?: Object): T {
        if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
            if (decoders) {
                for (const key in decoders) {
                    if (decoders.hasOwnProperty(key)) {
                        decoders[key](value[key]);
                    }
                }
            }
            return <T>value;
        }

        throw new Error(`Invalid value type; expected Object, received ${JSON.stringify(value)}.`);
    }

    static matrix(value: any): Snap.Matrix {
        try {
            const a = ModelDecoder.number(value.a);
            const b = ModelDecoder.number(value.b);
            const c = ModelDecoder.number(value.c);
            const d = ModelDecoder.number(value.d);
            const e = ModelDecoder.number(value.e);
            const f = ModelDecoder.number(value.f);
            return new Snap.Matrix(a, b, c, d, e, f);
        } catch (err) {
            throw new Error(`Invalid value type; expected Matrix: {a: number, b: number, c: number,
            d: number, e: number, f: number} object, received ${JSON.stringify(value)}.`);
        }
    }
}
