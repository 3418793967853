import { Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { RibbonComponent, TabItemInfo } from './ribbon.component';
import { RibbonDialog } from './ribbon-panels/ribbon-dialog/ribbon-dialog';

@Injectable({
    providedIn: 'root'
})
export class RibbonSelectionService {
    private selectedTabSubject: Subject<number> = new Subject();
    onTabRequest = this.selectedTabSubject.asObservable();

    private dialogChangeSubject: Subject<void> = new Subject();
    onDialogChange = this.dialogChangeSubject.asObservable();

    private ribbonTabSelectedSubject: Subject<TabItemInfo> = new Subject();
    readonly ongRibbonTabSelected = this.ribbonTabSelectedSubject.asObservable();

    _openedRibbonDialog: RibbonDialog;
    ribbonComponent: RibbonComponent;

    /*
    @Output()
    ribbonClosed: EventEmitter<boolean> = new EventEmitter<boolean>(true);
*/

    constructor() {}

    getCurrentTab(): TabItemInfo {
        return this.ribbonComponent.currentPanel;
    }

    updateSelectedRibbonTab(item: TabItemInfo) {
        if (item) {
            this.ribbonTabSelectedSubject.next(item);
        }
    }

    set openedRibbonDialog(value: RibbonDialog) {
        if (value !== this._openedRibbonDialog) {
            this._openedRibbonDialog = value;
            this.dialogChangeSubject.next();
        }
    }

    get openedRibbonDialog(): RibbonDialog {
        return this._openedRibbonDialog;
    }

    selectTab(tabIndex: number) {
        this.selectedTabSubject.next(tabIndex);
    }

    openRibbonDialog(ribbonDialog: Type<RibbonDialog>) {
        if (!this.ribbonComponent) {
            console.error('RibbonComponent is undefined');
            return;
        }
        this.ribbonComponent.panelContentComponent.loadRibbonDialog(ribbonDialog);
    }

    closeRibbonDialog() {
        if (!this.ribbonComponent) {
            console.error('RibbonComponent is undefined');
            return;
        }

        this.ribbonComponent.panelContentComponent.closeRibbonDialog();
    }
}
