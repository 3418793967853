import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnInit,
    Output,
    Type,
    ViewChild
} from '@angular/core';
import { RibbonTabItemComponent } from './ribbon-tabs/ribbon-tab-item/ribbon-tab-item.component';
import { GripTextContourPanelComponent } from './ribbon-panels/grip-text-contour-panel/grip-text-contour-panel.component';
import { ToolsContourPanelComponent } from './ribbon-panels/tools-contour-panel/tools-contour-panel.component';
import { OverviewPanelComponent } from './ribbon-panels/overview-panel/overview-panel.component';
import { RibbonTabsComponent } from './ribbon-tabs/ribbon-tabs.component';
import { RibbonPanelContentComponent } from './ribbon-panels/ribbon-panel-content/ribbon-panel-content.component';
import { RibbonPanelContent } from './ribbon-panels/ribbon-panel-content';
import { take } from 'rxjs/operators';
import { RibbonSelectionService } from './ribbon-selection.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-ribbon',
    templateUrl: './ribbon.component.html',
    styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent implements OnInit, AfterViewInit {
    // TODO converts it to an input
    tabItems: TabItemInfo[] = [
        { index: 1, id: 'tools', title: 'Werkzeuge', panelComponent: ToolsContourPanelComponent },
        {
            index: 2,
            id: 'grip-text',
            title: 'Text, Formen und Griffe',
            panelComponent: GripTextContourPanelComponent
        },
        { index: 3, id: 'overview', title: 'Übersicht', panelComponent: OverviewPanelComponent }
    ];

    private _currentPanel: TabItemInfo;

    @ViewChild(RibbonTabsComponent, { static: false })
    readonly ribbonTabsComponent: RibbonTabsComponent;

    @ViewChild(RibbonPanelContentComponent, { static: false })
    readonly panelContentComponent: RibbonPanelContentComponent;

    prevButtonDisabled: boolean;
    nextButtonDisabled: boolean;
    isOverviewPanel: boolean = true;

    @Output()
    panelChanged: EventEmitter<TabItemInfo> = new EventEmitter();

    constructor(
        private ribbonSelectionService: RibbonSelectionService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.translate
            .stream([
                'TOOL_RIBBON.MAIN_MENU',
                'SHAPE_HANDLE_TEXT_RIBBON.MAIN_MENU',
                'OVERVIEW_RIBBON.MAIN_MENU'
            ])
            .subscribe(res => {
                this.tabItems[0].title = res['TOOL_RIBBON.MAIN_MENU'];
                this.tabItems[1].title = res['SHAPE_HANDLE_TEXT_RIBBON.MAIN_MENU'];
                this.tabItems[2].title = res['OVERVIEW_RIBBON.MAIN_MENU'];
            });

        this.ribbonSelectionService.ribbonComponent = this;
    }

    ngAfterViewInit() {
        this.ribbonSelectionService.onTabRequest.subscribe(tabIndex => {
            this.selectTabByIndex(tabIndex);
        });
    }

    onTabSelected($event: RibbonTabItemComponent) {
        if (!this.currentPanel || $event.itemId !== this.currentPanel.id) {
            const tabItem = this.tabItems.find(x => x.id === $event.itemId);
            if (tabItem) {
                this.currentPanel = tabItem;
            } else {
                throw Error('TabItem "' + $event.itemId + '" not found.');
            }
        }
    }

    onTabClicked($event: RibbonTabItemComponent) {
        // Clicking on an already activated tab should also close the opened ribbon dialog
        if (this.panelContentComponent && $event.selected) {
            this.panelContentComponent.closeRibbonDialog();
        }
    }

    selectPreviousTab() {
        const prevIndex = this.currentPanel.index - 1;
        if (prevIndex >= 1) {
            // Wait until the current panelContentComponent has been updated
            // when calling the #selectPreviousTab() method below
            // FIXME: source of error -> this might not get called as expected if there is no
            // previous tab. Instead it will be called when another panel is loaded which
            // will lead to unexpected behaviours
            this.panelContentComponent.panelLoaded.pipe(take(1)).subscribe(x => {
                this.panelContentComponent.expandLastItem();
            });
            this.selectTabByIndex(prevIndex);
        }
    }

    expandPreviousAccordionOrPanel() {
        if (this.panelContentComponent.isAccordionPanel()) {
            const toggleResult = this.panelContentComponent.expandPreviousExpansionItem();
            if (toggleResult && toggleResult.endReached) {
                this.selectPreviousTab();
            }
        } else {
            this.selectPreviousTab();
        }
    }

    expandNextAccordionOrPanel() {
        if (this.panelContentComponent.isAccordionPanel()) {
            const toggleResult = this.panelContentComponent.expandNextExpansionItem();
            if (!toggleResult || toggleResult.endReached) {
                this.panelContentComponent.panelLoaded.pipe(take(1)).subscribe(x => {});
                this.selectNextTab();
            }
        } else {
            this.selectNextTab();
        }
    }

    selectNextTab() {
        const nextIndex = this.currentPanel.index + 1;
        if (nextIndex <= this.tabItems.length) {
            this.selectTabByIndex(nextIndex);
        }
    }

    private selectTabByIndex(tabIndex: number) {
        const item = this.tabItems.find(x => x.index === tabIndex);
        // Hack if this is only a panel switch or not.
        if (item) {
            // For now we don't need to update this.currentPanel;
            // because the ribbonTabsComponent will call #onTabSelected for us
            // using event emitter. However, this is hard to track and understand

            this.ribbonTabsComponent.selectTabById(item.id);
        } else {
            throw Error('TabItem with index"' + tabIndex + '" cannot be found.');
        }
    }

    set currentPanel(item: TabItemInfo) {
        if (!item || item === this._currentPanel) {
            return;
        }

        this.panelContentComponent.panelLoaded.pipe(take(1)).subscribe(x => {
            // update button when the panel is loaded
            this.updateNavButtons();

            if (this.panelContentComponent.isAccordionPanel()) {
                this.panelContentComponent
                    .onAccordionItemExpanded()
                    .subscribe((accordionIndex: number) => {
                        // update buttons when the accordion has changed
                        this.updateNavButtons();
                    });
            }
        });

        this._currentPanel = item;
        this.ribbonSelectionService.updateSelectedRibbonTab(item);
        this.panelChanged.emit(item);

        if (this.currentPanel && this.currentPanel.id === 'overview') {
            this.isOverviewPanel = true;
        } else {
            this.isOverviewPanel = false;
        }
    }

    updateNavButtons() {
        // default values. if the panel has no accordion, it acts like the where only one with
        // index zero which consequently would be also the last accordion
        let accordionIndex = 0;
        let isLastAccordion: boolean = true;

        if (this.panelContentComponent.isAccordionPanel()) {
            accordionIndex = this.panelContentComponent.getExpandedIndex();
            isLastAccordion =
                accordionIndex === this.panelContentComponent.getExpandedItemSize() - 1;
        }

        if (this._currentPanel.index === 1 && accordionIndex === 0) {
            this.prevButtonDisabled = true;
            this.nextButtonDisabled = false;
        } else if (this._currentPanel.index === this.tabItems.length && isLastAccordion) {
            this.prevButtonDisabled = false;
            this.nextButtonDisabled = true;
        } else {
            this.prevButtonDisabled = false;
            this.nextButtonDisabled = false;
        }
    }

    get currentPanel(): TabItemInfo {
        return this._currentPanel;
    }
}

export interface TabItemInfo {
    index: number;
    id: string;
    title: string;
    panelComponent: Type<RibbonPanelContent>;
}
