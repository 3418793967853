import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationSharedModule } from '../../shared/translation-shared.module';
import { SharedModule } from '../../shared/shared.module';
import { ScrollViewportModule } from '../scroll-viewport/scroll-viewport.module';
import { DroppableModule } from '../../droppable/droppable.module';
import { ItemsLayerDirective } from './layers/items-layer.directive';
import { FoamLayerDirective } from './layers/foam-layer.directive';
import { ContourItemsLayerDirective } from './layers/contour-items-layer.directive';
import { CollisionLayerDirective } from './layers/collision-layer.directive';
import { SafetyMarginLayerDirective } from './layers/safety-margin-layer.directive';
import { SelectionLayerDirective } from './layers/selection-layer.directive';
import { FixedCollisionLayerDirective } from './layers/fixed-collision-layer.directive';
import { CanvasOverlayLayerDirective } from './layers/canvas-overlay-layer.directive';
import { CanvasComponent } from './canvas.component';
import { CanvasSharedModule } from './shared/canvas-shared.module';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        TranslationSharedModule,
        // App modules
        SharedModule,
        // DialogModule,
        // RibbonModule,
        // UploadContourDialogModule,
        ScrollViewportModule,
        DroppableModule,
        CanvasSharedModule
    ],
    declarations: [
        ItemsLayerDirective,
        FoamLayerDirective,
        ContourItemsLayerDirective,
        CollisionLayerDirective,
        SafetyMarginLayerDirective,
        SelectionLayerDirective,
        FixedCollisionLayerDirective,
        CanvasOverlayLayerDirective,
        CanvasComponent
    ],
    exports: [CanvasComponent, CanvasSharedModule],
    // dynamically loaded components
    entryComponents: []
})
export class CanvasModule {}
