import { ImageContourType } from '../../../models/image-contour.model';
import * as _ from 'lodash';
import { BBox } from 'snapsvg';
import {
    calculateMargin,
    pathString2Polygon,
    transformBoundingBox
} from 'src/app/foam-editor/canvas/contour/contour-helper';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { Handle } from '../../shared/handle/handle';
import { RotationHandle } from '../../shared/handle/rotation-handle';
import { CanvasElementChangedEvent } from '../canvas.component';

import {
    CLASS_NAME_CONTOUR_GROUP,
    CLASS_NAME_CONTOUR_IMAGE,
    CLASS_NAME_CONTOUR_PATH,
    CLASS_NAME_CONTOUR_SELECTED,
    CLASS_NAME_MARGIN_GROUP,
    CLASS_NAME_MARGIN_PATH,
    COLOR_BG_ITEM_PATH,
    COLOR_BG_MARGIN_PATH,
    OPACITY_BG_MARGIN_PATH
} from './constants';
import {
    ContourChangeData,
    ContourHandlesData,
    CuttableContourDrawData,
    ItemProperties,
    MarginContourCollisionVizData
} from './contour-items-interfaces';
import { CuttableContour } from './cuttable-contour';
import { Tool } from '../../shared/tool/tool';
import { MarginUtils } from '../shared/margin-utils';

declare var Snap: any;

export interface ImageContourArgs {
    dataStoreId?: string;
    contourId?: string;
    svgPathDefinition: string;
    image?: string;
    previewImage?: string;
    itemProps?: ItemProperties;
    marginPathString?: string;
    isSelectable?: boolean;
    isRemovable?: boolean;
    isCollidable?: boolean;
    isResizable?: boolean;
    pathGlobalBBox?: BBox;
    pathLocalBBox?: BBox;
    marginPathLocalBBox?: BBox;
    marginPathGlobalBBox?: BBox;
    localContourPathMatrix?: Snap.Matrix;
    type: ImageContourType;
    width?: number;
    height?: number;
    depth: number;
    title: string;
    description?: string;
}

export const TOOL_DEFAULT_ITEM_PROPS = (contourId: string): ItemProperties => {
    return {
        itemPathId: CLASS_NAME_CONTOUR_PATH + contourId,
        imagePathId: CLASS_NAME_CONTOUR_IMAGE + contourId,
        marginPathId: CLASS_NAME_MARGIN_PATH + contourId,
        classNamePath: CLASS_NAME_CONTOUR_PATH.slice(0, -1),
        classNameGroup: CLASS_NAME_CONTOUR_GROUP + contourId,
        classNameMarginPath: CLASS_NAME_MARGIN_PATH.slice(0, -1),
        classNameMarginGroup: CLASS_NAME_MARGIN_GROUP + contourId,
        classNameItemSelected: CLASS_NAME_CONTOUR_SELECTED
    };
};

export class ImageContour extends CuttableContour {
    /** data store contour id **/
    dataStoreId: string;
    previewImage: string;
    title: string;
    description?: string;
    image: string;

    isRemovable: boolean = true;
    isSelectable: boolean = true;
    isCollidable: boolean = true;
    isResizable: boolean = true;
    type: ImageContourType;

    private rotationHandle: RotationHandle<CuttableContour>;

    constructor(obj?: ImageContourArgs) {
        super(obj);
        const setterFn = (value: any, def: any) => (value == null ? def : value);

        if (!obj.dataStoreId) {
            throw ReferenceError('dataStoreId is undefined');
        }
        this.dataStoreId = obj.dataStoreId;
        this.svgPathDefinition = setterFn(obj.svgPathDefinition, undefined);
        this.image = setterFn(obj.image, undefined);
        this.previewImage = setterFn(obj.previewImage, undefined);

        this.type = setterFn(obj.type, undefined);
        this.title = setterFn(obj.title, '');
        this.description = setterFn(obj.description, '');

        this.globalContourPathBBox = setterFn(obj.pathGlobalBBox, undefined);
        this.localContourPathBBox = setterFn(obj.pathLocalBBox, undefined);
        this.localMarginPathBBox = setterFn(obj.marginPathLocalBBox, undefined);
        this.globalMarginPathBBox = setterFn(obj.marginPathGlobalBBox, undefined);

        if (obj.localContourPathMatrix) {
            this.localContourPathMatrix = Snap.matrix().add(obj.localContourPathMatrix);
        }

        this.width = setterFn(obj.width, undefined);
        this.height = setterFn(obj.height, undefined);

        this.depth = setterFn(obj.depth, undefined);

        this.itemProps.contourFill = COLOR_BG_ITEM_PATH;
        this.itemProps.marginFill = COLOR_BG_MARGIN_PATH;
        this.itemProps.marginOpacity = OPACITY_BG_MARGIN_PATH;

        this.isSelectable = setterFn(obj.isSelectable, true);
        this.isRemovable = setterFn(obj.isRemovable, true);
        this.isCollidable = setterFn(obj.isCollidable, true);
        this.isResizable = setterFn(obj.isResizable, true);

        this.marginSize = this.depth ? calculateMargin(this.depth) : undefined;

        this.marginPathString = this.marginPathString = setterFn(
            obj.marginPathString,
            MarginUtils.createMarginPathString(this.svgPathDefinition, this.marginSize)
        );
    }

    static create(
        imageContourObjs: any,
        dataStoreIdKey?: string,
        type?: ImageContourType
    ): ImageContour {
        const contourItem = Object.assign(
            {
                dataStoreId: dataStoreIdKey,
                type: type
                // localContourPathMatrix: imageContourObjs.positionData ? imageContourObjs.positionData.localMatrix : undefined,
            } as ImageContourArgs,
            _.cloneDeep(imageContourObjs)
        );
        return new ImageContour(contourItem);
    }

    /**
     * method which needs to be implemented because of abstract class CutableContour ...
     */
    getTool(): Tool | null {
        // FIXME: see comment above (abstract inheritance )
        return null;
    }

    setDepth(newDepth: number) {
        if (newDepth !== undefined && this.depth !== newDepth) {
            this.depth = this.constraintResizeDepth(newDepth);
            this.marginSize = calculateMargin(this.depth);
            this.marginPathString = MarginUtils.createMarginPathString(
                this.svgPathDefinition,
                this.marginSize
            );

            const contourChangeData = new ContourChangeData({
                contourId: this.contourId,
                marginPathString: this.marginPathString,
                marginSize: this.marginSize,
                isDepthChanged: true
            });
            this.contourChanged.next(contourChangeData);
        }
    }

    contains(px: number, py: number): boolean {
        return false;
    }

    containsTarget(targetNode: Element): boolean {
        return (
            targetNode.id === this.itemProps.itemPathId ||
            targetNode.id === this.itemProps.imagePathId ||
            targetNode.id === this.itemProps.marginPathId
        );
    }

    constraintResizeDepth(newDepth: number): number {
        let newValue = Math.max(newDepth, this.canvasService.getPropertiesValue().minContourDepth);
        newValue = Math.min(newValue, this.canvasService.getPropertiesValue().maxContourDepth);
        return newValue;
    }

    createHandles(): Handle[] {
        if (!this.rotationHandle) {
            this.rotationHandle = new RotationHandle<CuttableContour>(this);
        }
        return [this.rotationHandle];
    }

    getHandlesDrawData(): ContourHandlesData {
        return new ContourHandlesData({
            containerId: 'handle-container-' + this.contourId,
            contourId: this.contourId,
            transformBounds: {
                localContourBounds: this.localContourPathBBox,
                localMarginBounds: this.localMarginPathBBox,
                selectionBoundsMatrix: this.localContourPathMatrix
            },
            rotationHandles: this.rotationHandle.getDrawData()
        });
    }

    onContourElementDrawn(drawnData: CanvasElementChangedEvent) {
        if (!drawnData) {
            return;
        }

        if (drawnData.contour) {
            this.polygonLines = pathString2Polygon(
                this.svgPathDefinition,
                drawnData.contour.globalMatrix
            );

            this.globalContourPathBBox = drawnData.contour.globalBBox;
            this.localContourPathBBox = drawnData.contour.localBBox;

            this.globalContourPathMatrix = drawnData.contour.globalMatrix;
            this.localContourPathMatrix = drawnData.contour.localMatrix;

            this.contourBoundsInParent = Rectangle2D.fromPointsObject(
                transformBoundingBox(drawnData.contour.localBBox, this.localContourPathMatrix)
            );
        }

        if (drawnData.margin) {
            this.globalMarginPathMatrix = drawnData.margin.globalMatrix;
            this.localMarginPathMatrix = drawnData.margin.localMatrix;
            this.globalMarginPathBBox = drawnData.margin.globalBBox;
            this.localMarginPathBBox = drawnData.margin.localBBox;
            this.marginPolygonLines = pathString2Polygon(
                this.marginPathString,
                drawnData.margin.globalMatrix
            );
        }
    }

    getDrawData(): CuttableContourDrawData {
        return new CuttableContourDrawData({
            contourId: this.contourId,
            svgPathDefinition: this.svgPathDefinition,
            image: this.image,
            marginPathString: this.marginPathString,
            marginSize: this.marginSize,
            itemProps: this.itemProps,
            isCollidable: this.isCollidable,
            isSelectable: this.isSelectable,
            matrix: this.localContourPathMatrix
        });
    }

    getCollisionData(): MarginContourCollisionVizData {
        if (!this.isCollidable) {
            return null;
        }

        return new MarginContourCollisionVizData({
            contourId: this.contourId,
            clipPathString: this.svgPathDefinition,
            //  outerClipPathString: this.marginPathString,
            pathString: this.svgPathDefinition,
            pathStringToBeClipped: this.marginPathString,
            // innerPathStringToBeClipped: this.pathString,
            transformString: this.localMarginPathMatrix
                ? this.localMarginPathMatrix.toTransformString()
                : undefined,
            transformString2: this.localMarginPathMatrix
                ? this.localMarginPathMatrix.toString()
                : undefined,

            innerCollisionPolygonLines: this.polygonLines,
            innerCollisionBBox: this.globalContourPathBBox,
            outerCollisionPolygonLines: this.marginPolygonLines,
            outerCollisionBBox: this.globalMarginPathBBox,

            globalContourPathBBox: this.globalContourPathBBox,
            localContourPathBBox: this.localContourPathBBox,

            globalMarginPathMatrix: this.globalMarginPathMatrix,
            localMarginPathMatrix: this.localMarginPathMatrix,
            globalContourPathMatrix: this.globalContourPathMatrix,
            localContourPathMatrix: this.localContourPathMatrix,
            isCollidable: this.isCollidable
        });
    }
}
