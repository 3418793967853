<ng-content select="app-expansion-panel-header"></ng-content>
<div
    class="app-expansion-panel-content"
    [@bodyExpansion]="_getExpandedState()"
    (@bodyExpansion.done)="_bodyAnimation($event)"
    (@bodyExpansion.start)="_bodyAnimation($event)"
    [id]="id"
    #body
>
    <div class="app-expansion-panel-body">
        <ng-content></ng-content>
    </div>
</div>
