import { TranslateService } from '@ngx-translate/core';
import { ApiErrorCode } from './api-error';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
const ERROR_MSG_MAX_FILE_SIZE = '10MB';
export class ApiErrorService {
    constructor(translateService) {
        this.translateService = translateService;
    }
    getErrorMessage(err) {
        const clientCode = this.getClientErrorCode(err.errorCode);
        const key = `ERRORS.${clientCode}`;
        return {
            message: this.translateService.instant(key, this.getInterpolateParams(clientCode)),
            clientCode: clientCode
        };
    }
    getClientErrorCode(errorCode) {
        switch (errorCode) {
            case ApiErrorCode.PAPER_DETECTION_FAILED:
            case ApiErrorCode.MARKER_DETECTION_FAILED:
                return ClientErrorCode.PAPER_DETECTION_FAILED;
            case ApiErrorCode.CONTOUR_DETECTION_FAILED:
                return ClientErrorCode.CONTOUR_DETECTION_FAILED;
            case ApiErrorCode.INVALID_IMAGE:
                return ClientErrorCode.INVALID_IMAGE;
            case ApiErrorCode.IMAGE_SIZE_LIMIT_EXCEEDED:
                return ClientErrorCode.IMAGE_SIZE_LIMIT_EXCEEDED;
            case ApiErrorCode.INTERNAL_ERROR:
            case ApiErrorCode.JOB_ALREADY_EXISTS:
            case ApiErrorCode.JOB_NOT_FOUND:
            case ApiErrorCode.REFINEMENT_FAILED:
            case ApiErrorCode.NATIVE_INTERNAL_ERROR:
            default:
                return ClientErrorCode.GENERAL;
        }
    }
    getInterpolateParams(clientCode) {
        if (clientCode === ClientErrorCode.IMAGE_SIZE_LIMIT_EXCEEDED) {
            return { maxFileSize: ERROR_MSG_MAX_FILE_SIZE };
        }
        return undefined;
    }
}
ApiErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiErrorService_Factory() { return new ApiErrorService(i0.ɵɵinject(i1.TranslateService)); }, token: ApiErrorService, providedIn: "root" });
export var ClientErrorCode;
(function (ClientErrorCode) {
    ClientErrorCode["GENERAL"] = "GENERAL";
    ClientErrorCode["PAPER_DETECTION_FAILED"] = "PAPER_DETECTION_FAILED";
    ClientErrorCode["INVALID_IMAGE"] = "INVALID_IMAGE";
    ClientErrorCode["CONTOUR_DETECTION_FAILED"] = "CONTOUR_DETECTION_FAILED";
    ClientErrorCode["IMAGE_SIZE_LIMIT_EXCEEDED"] = "IMAGE_SIZE_LIMIT_EXCEEDED";
})(ClientErrorCode || (ClientErrorCode = {}));
