<div class="contour-browser__scroll-button--up">
    <app-icon
        [disabled]="!canScrollUp"
        [shape]="'keyboard_arrow_up'"
        [cssHeight]="'18px'"
        [cssWidth]="'40px'"
        (click)="scrollUp($event)"
    ></app-icon>
</div>

<div class="contour-browser">
    <app-scroll-viewport
        itemSize="75"
        [itemCount]="data.length"
        [viewportSize]="viewportSize"
        class="contour-browser-scroll-viewport"
    >
        <div
            class="contour-browser__item-list"
            #contourItemsList
            appDroppableContainer
            [dropZoneId]="dropZoneId"
            [dndHandler]="dndHandler"
        >
            <div
                *ngIf="!data || data.length < 1"
                [innerHTML]="'TOOLS_CONTOUR_PANEL.DATA_EMPTY_TEXT' | translate"
            ></div>
            <div
                class="contour-browser-item draggable-item"
                *ngFor="let item of data"
                appDroppableItem
                droppableItemType="IMAGE_CONTOUR"
                attr.data-item-id="{{ item.id }}"
                [droppableItemDisabled]="!item.isValid"
                [class.droppable-is-dissabled]="!item.isValid"
            >
                <div
                    appTooltip
                    [appToolTipContent]="getItemTooltipContent(item)"
                    [appTooltipPlacement]="'bottom'"
                    class="contour-browser-item__row"
                >
                    <div class="contour-browser-item__image">
                        <img src="{{ item.previewImage }}" />
                    </div>
                    <div class="contour-browser-item__title">
                        <span class="selectable-text">{{ item.title }}</span>
                        <div class="contour-browser-item__info">
                            {{ 'TOOLS_CONTOUR_PANEL.HEIGHT' | translate }}: {{ item.depth }} mm
                        </div>
                    </div>
                </div>

                <div class="contour-browser-item__actions">
                    <app-button
                        *ngIf="item.isEditable"
                        app-icon-button
                        buttonSize="xx-small"
                        appTooltip
                        appToolTipContent="{{ 'MOUSEOVER.EDIT' | translate }}"
                        [appTooltipPlacement]="'right'"
                        (click)="editImageContour(item)"
                    >
                        <app-icon
                            shape="edit"
                            iconSize="small"
                            cssWidth="18px"
                            cssHeight="19px"
                        ></app-icon>
                    </app-button>
                    <span
                        appTooltip
                        [appToolTipContent]="getDeleteToolTipContent(item)"
                        [appTooltipPlacement]="'right'"
                    >
                        <app-button
                            *ngIf="item.isEditable"
                            app-icon-button
                            buttonSize="xx-small"
                            [disabled]="!item.isRemovable"
                            (click)="deleteImageContour(item)"
                        >
                            <app-icon
                                shape="bin"
                                iconSize="small"
                                cssWidth="18px"
                                cssHeight="19px"
                            ></app-icon>
                        </app-button>
                    </span>
                    <app-button
                        *ngIf="!item.isEditable"
                        app-icon-button
                        buttonSize="xx-small"
                        appTooltip
                        appToolTipContent="{{ 'MOUSEOVER.INFO' | translate }}"
                        [appTooltipPlacement]="'right'"
                        (click)="showImageContourInfo(item)"
                    >
                        <app-icon shape="info" iconSize="small" cssWidth="10px" cssHeight="28px">
                        </app-icon>
                    </app-button>
                </div>
            </div>
        </div>
    </app-scroll-viewport>
</div>

<div class="contour-browser__scroll-button--down">
    <app-icon
        [shape]="'keyboard_arrow_down'"
        [cssHeight]="'18px'"
        [cssWidth]="'40px'"
        (click)="scrollDown($event)"
        [disabled]="!canScrollDown"
    ></app-icon>
</div>
