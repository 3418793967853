import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { UploadDialogContentComponent } from '../../upload-dialog.component';

@Component({
    selector: 'app-how-to',
    templateUrl: './how-to-upload.component.html',
    styleUrls: ['./how-to-upload.component.scss']
})
export class HowToUploadComponent implements OnInit, UploadDialogContentComponent {
    constructor() {}

    ngOnInit() {}

    isValid(): Observable<boolean> {
        return Observable.create(o => o.next(true));
    }

    onLoadNextContent(): void {}

    onLoadPrevContent(): void {}

    onAttached(): void {}
}
