<div
    appCanvasScrollViewport
    appDropZone
    [ngClass]="{
        'canvas-viewport': showScrollBars,
        'canvas-viewport--no-scrollbars': !showScrollBars
    }"
    [dropZoneId]="dropZoneId"
>
    <div class="canvas-content">
        <svg
            #canvasContent
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            class="svg-frame"
        >
            <g
                appItemLayer
                class="layer-items"
                [showContourImage]="canvasProperties.showContourImage"
                [showContourSafeMargin]="canvasProperties.showContourSafeMargin"
                appDroppableContainer
                hasMoveableElements="true"
                dropZoneId="thrash-basket-dropzone"
                [dndHandler]="canvasDroppableHandler"
            >
                <g appFoamLayer class="layer-foam">
                    <path id="foam-path" fill="url(#foam-bg)"></path>
                    <path
                        id="foam-inner-margin-path"
                        stroke="white"
                        stroke-dasharray="10,5"
                        opacity="0.5"
                        stroke-width="2"
                        stroke-dashoffset="10"
                    ></path>
                </g>
                <g
                    appSafetyMarginLayer
                    [showContourSafetyMargin]="canvasProperties.showContourSafeMargin"
                    class="layer-safety-margins"
                ></g>
                <g appContourItemsLayer class="layer-contour-items"></g>

                <g appSelectionLayer class="selection-layer"></g>
                <g
                    appCollisionLayer
                    class="contour-collision-layer"
                    style="pointer-events: none"
                ></g>
                <g
                    appFixedCollisionLayer
                    class="fixed-collision-layer"
                    style="pointer-events: none"
                ></g>
            </g>

            <g appCanvasOverlayLayer class="canvas-overlay-layer"></g>

            <defs>
                <pattern
                    id="foam-bg"
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    patternContentUnits="objectBoundingBox"
                    class="layout-background"
                    preserveAspectRatio="xMidYMid slice"
                >
                    <image
                        x="0"
                        y="0"
                        width="1"
                        height="1"
                        xlink:href="assets/foam-lboxx.jpg"
                        preserveAspectRatio="xMidYMid slice"
                    />
                </pattern>
            </defs>
        </svg>
    </div>
</div>

<textarea
    #textContourInput
    id="text-contour-input"
    autocomplete="off"
    (input)="($event.target.value)"
></textarea>
