import { Injectable } from '@angular/core';
import { RecessedGripContour } from './contour/recessed-grip-contour';
import { share } from 'rxjs/operators';
import { CanvasContour } from './contour/contour-items-interfaces';
import { Observable, Subject } from 'rxjs';
import { pathsIntersect } from './collision/collision-util';
import { CuttableContour } from './contour/cuttable-contour';

@Injectable()
export class RecessedGripCollisionService {
    private allGripCollision: Map<string, Set<string>> = new Map();
    private readonly recessedGripCollisionChangeSource = new Subject<Map<string, Set<string>>>();

    constructor() {}

    getRecessedGripCollisionChange(): Observable<Map<string, Set<string>>> {
        return this.recessedGripCollisionChangeSource.pipe(share());
    }

    getRecessedGripOf(contour: CuttableContour): Map<string, Set<string>> {
        const gripToUpdate = new Map<string, Set<string>>();
        for (const [gripId, collidingContours] of this.allGripCollision.entries()) {
            if (collidingContours.has(contour.contourId)) {
                gripToUpdate.set(gripId, collidingContours);
            }
        }
        return gripToUpdate;
    }

    /**
     * Detects whether the changed contour (e.g., transformed) collide with
     * one or more recessed grips.
     *
     * This method fires an Observable when a collision has been detected or removed.
     *
     * By definition, the {@code otherContours} array SHOULD contain all contours expect the {@code changedContour}.
     * However, if it does contain the changedContour, it will NOT affect the detection algorithm.
     *
     * @param changedContour the contour that has changed (e.g., transformed)
     * @param otherContours all contours excluding the {@code changedContour}
     */
    detect(changedContour: CuttableContour, otherContours: CuttableContour[]) {
        let gripToUpdate: Map<string, Set<string>>;
        if (changedContour instanceof RecessedGripContour) {
            gripToUpdate = this.detectBasedOnRecessedGripContour(changedContour, otherContours);
        } else {
            gripToUpdate = this.detectBasedOnContour(changedContour, otherContours);
        }

        if (gripToUpdate && gripToUpdate.size > 0) {
            this.recessedGripCollisionChangeSource.next(gripToUpdate);
        }
    }

    private detectBasedOnRecessedGripContour(
        grip: RecessedGripContour,
        otherContours: CanvasContour[]
    ): Map<string, Set<string>> | null {
        let recessedGripCollisionItems = this.allGripCollision.get(grip.contourId);
        if (!recessedGripCollisionItems) {
            recessedGripCollisionItems = new Set<string>();
            this.allGripCollision.set(grip.contourId, recessedGripCollisionItems);
        }
        let collisionChanged = false;

        const excludeGrip = contour =>
            !(contour instanceof RecessedGripContour) && grip.contourId !== contour.contourId;
        otherContours.filter(excludeGrip).forEach(otherContour => {
            const isIntersecting = pathsIntersect(
                grip.globalContourPathBBox,
                otherContour.globalContourPathBBox,
                grip.polygonLines,
                otherContour.polygonLines
            );

            const wasIntersecting = recessedGripCollisionItems.has(otherContour.contourId);
            if (isIntersecting && !wasIntersecting) {
                recessedGripCollisionItems.add(otherContour.contourId);
                collisionChanged = true;
            } else if (!isIntersecting && wasIntersecting) {
                recessedGripCollisionItems.delete(otherContour.contourId);
                collisionChanged = true;
            }
        });

        // remove old intersections

        // no need to detect the data structure if the selected grip was not intersecting with any
        // contours prior to this detect
        if (collisionChanged) {
            this.allGripCollision.set(grip.contourId, recessedGripCollisionItems);
            return new Map<string, Set<string>>().set(
                grip.contourId,
                new Set(recessedGripCollisionItems)
            );
        }

        return null;
    }

    private detectBasedOnContour(
        changedContour: CuttableContour,
        otherContours: CuttableContour[]
    ): Map<string, Set<string>> | null {
        const changedRecessedGrips = new Map<string, Set<string>>();

        // filter out none recessed contours
        const recessedOnlyFilter = x =>
            x instanceof RecessedGripContour && changedContour.contourId !== x.contourId;
        const allRecessedGrips = otherContours.filter(recessedOnlyFilter);

        for (const recessedGrip of allRecessedGrips) {
            let recessedGripCollisionItems = this.allGripCollision.get(recessedGrip.contourId);
            if (!recessedGripCollisionItems) {
                recessedGripCollisionItems = new Set<string>();
                this.allGripCollision.set(recessedGrip.contourId, recessedGripCollisionItems);
            }

            const isIntersecting = pathsIntersect(
                changedContour.globalContourPathBBox,
                recessedGrip.globalContourPathBBox,
                changedContour.polygonLines,
                recessedGrip.polygonLines
            );
            const wasIntersecting = recessedGripCollisionItems.has(changedContour.contourId);
            const isNewIntersection = isIntersecting && !wasIntersecting;
            const isRemovedIntersection = !isIntersecting && wasIntersecting;

            if (isNewIntersection) {
                recessedGripCollisionItems.add(changedContour.contourId);
                changedRecessedGrips.set(recessedGrip.contourId, recessedGripCollisionItems);
            } else if (isRemovedIntersection) {
                // remove the contour if it was previously intersecting with the recessed-grip but
                // not anymore
                recessedGripCollisionItems.delete(changedContour.contourId);
                changedRecessedGrips.set(recessedGrip.contourId, recessedGripCollisionItems);
            }
        }

        return changedRecessedGrips;
    }

    removeContour(contourId: string) {
        const gripToUpdate = new Map<string, Set<string>>();
        const gripToRemove = this.allGripCollision.get(contourId);
        if (gripToRemove) {
            gripToUpdate.set(contourId, new Set<string>());
            this.allGripCollision.delete(contourId);
        }
        for (const [gripId, collidingContours] of this.allGripCollision.entries()) {
            if (collidingContours.has(contourId)) {
                collidingContours.delete(contourId);
                gripToUpdate.set(gripId, collidingContours);
                this.allGripCollision.set(gripId, collidingContours);
            }
        }
        if (gripToUpdate.size > 0) {
            this.recessedGripCollisionChangeSource.next(gripToUpdate);
        }
    }
}
