import { DroppableEvent, DroppableHandlerService } from './droppable-handler.service';
import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { DroppableState, DroppableStateStore, DropZone } from './droppable-state-store.service';
import { List } from 'immutable';
import { filter, map, take } from 'rxjs/operators';
import { DropZoneDirective } from './drop-zone.directive';
import {
    DroppableMirrorComponent,
    MirrorDropState
} from './droppable-mirror/droppable-mirror.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DroppableItem } from './droppable-item';
import { DroppableContainer } from './droppable-container';
import { DefaultSVGDndHandler, DndHandler } from './default-svg-dnd-handler';

// TDOO rename to  DroppableStartCOntainer
@Directive({
    selector: '[appDroppableContainer]'
})
export class DroppableContainerDirective implements OnInit, DroppableContainer, OnDestroy {
    @Input()
    dropZoneId: string;

    @Input()
    dndHandler: DndHandler;

    @Input()
    hasMoveableElements = false;

    /** Emits when the user starts dragging an dropabble of this container. */
    @Output()
    started = new EventEmitter<DroppableContainerEvent>();

    /** Emits when the user drops an item inside the dropZone . */
    @Output()
    moved = new EventEmitter<DroppableContainerEvent>();

    /** Emits when the user moves a droppable item ot this container */
    @Output()
    dropped = new EventEmitter<DroppableContainerEvent>();

    /** Emits when the user enters the dropZone with an item of this container. */
    @Output()
    entered = new EventEmitter<DroppableContainerEvent>();

    @Output()
    enteredStart = new EventEmitter<DroppableContainerEvent>();

    @Output()
    left = new EventEmitter<DroppableContainerEvent>();

    @Output()
    canceled = new EventEmitter<DroppableContainerEvent>();

    private dropZone: DropZoneDirective;
    mirrorComponent: DroppableMirrorComponent;
    private droppableHandlerService: DroppableHandlerService;
    private _dropState: MirrorDropState;
    private _dnDHandler: DndHandler;

    constructor(
        public elementRef: ElementRef,
        private droppableStateStore: DroppableStateStore,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        if (this.dropZoneId === null) {
            throw new Error('Attribute "dropZoneId" is required');
        }

        this.droppableHandlerService = new DroppableHandlerService(this.hasMoveableElements);
        this.droppableHandlerService.addContainer(this);

        this._dnDHandler = this.dndHandler ? this.dndHandler : new DefaultSVGDndHandler();
        this._dnDHandler.setMirrorComponent(this.mirrorComponent);

        this.droppableHandlerService
            .getOnServiceStart()
            .pipe(take(1))
            .subscribe(() => this.proxyDragEvents());

        this.droppableStateStore.changes
            .pipe(
                // ignore initial value
                filter(
                    (state: DroppableState) =>
                        !state.dropZones.isEmpty() && state.mirrorComponentRef !== null
                ),
                map((state: DroppableState) => this.toZoneById(state, this.dropZoneId))
            )
            .subscribe((refs: ZoneAndMirrorRefs) => {
                if (refs.dropZoneRef && refs.dropZoneRef !== this.dropZone) {
                    this.dropZone = refs.dropZoneRef;
                    this.droppableHandlerService.addDropZone(this.dropZone);
                }

                if (refs.mirrorComponentRef && refs.mirrorComponentRef !== this.mirrorComponent) {
                    this.mirrorComponent = refs.mirrorComponentRef;
                    this._dnDHandler.setMirrorComponent(this.mirrorComponent);
                }
            });
    }

    private toZoneById(state: DroppableState, soughtZoneId): ZoneAndMirrorRefs | null {
        const zones: List<DropZone> = state.dropZones;
        const index = zones.findIndex(zone => zone.dropZoneId === soughtZoneId);
        let componentRef;
        if (index > -1) {
            componentRef = zones.get(index).componentRef;
        }
        return { mirrorComponentRef: state.mirrorComponentRef, dropZoneRef: componentRef };
    }

    addItem(item: DroppableItem) {
        this.droppableHandlerService.addDroppableItem(item);
    }

    removeItem(nativeElement: Element) {
        this.droppableHandlerService.removeDroppableItem(nativeElement);
    }

    /**
     * Proxies the events from the {@linkg DroppableHandlerService} so that it matches the events
     * of this directive.
     */
    private proxyDragEvents() {
        this.droppableHandlerService.getDragStart().subscribe(evt => {
            if (
                !this.hasMoveableElements ||
                (this.hasMoveableElements && this.dropZone.dragPreviewAreaElement)
            ) {
                this.onDragStart(evt);
            }
        });

        this.droppableHandlerService.getDragEnter().subscribe(evt => {
            this.onDragEnter(evt);
            if (this.hasMoveableElements && !this.dropZone.dragPreviewAreaElement) {
                this.onDragStart(evt);
            }
        });

        this.droppableHandlerService.getDragMove().subscribe(evt => this.onDragMove(evt));

        this.droppableHandlerService.getDragLeave().subscribe(evt => this.onDragLeave(evt));

        if (this.droppableHandlerService.getDragReEnterPreviewArea()) {
            this.droppableHandlerService
                .getDragReEnterPreviewArea()
                .subscribe(evt => this.onDragReEnterPreviewArea(evt));
        }

        this.droppableHandlerService.getDragCancel().subscribe(x => this.onDragCancel(x));

        this.droppableHandlerService.getOnDrop().subscribe(x => {
            this.onDrop(x);
        });
    }

    // the order when the _dnDHandler's callbacks are called matter;
    // it gives the handler the possibility to override the above properties

    private onDragStart(evt: DroppableEvent) {
        // Moveable elements have no mirrored node. However, the mirrored element is injected in to
        // the svg-preview element. So there is no need to fetch data from the ToolContourService
        if (!this.hasMoveableElements && evt.mirrorElement) {
            this.mirrorComponent.mirrorDraggedItem = this.sanitizer.bypassSecurityTrustHtml(
                evt.mirrorElement.outerHTML
            );
        }

        this.dropState = 'outside';
        this._dnDHandler.onDragStart(evt);
        this.started.emit({ event: evt, container: this });
    }

    private onDragMove(evt: DroppableEvent) {
        this._dnDHandler.onDragMove(evt);
        // the order matter; it gives the handler the possibility to override the above properties
        this.moved.emit({ event: evt, container: this });
    }

    private onDrop(evt: DroppableEvent) {
        this._dnDHandler.onDragDrop(evt);
        this.dropped.emit({ event: evt, container: this });
        this.dropState = 'stop';
    }

    private onDragEnter(evt: DroppableEvent) {
        this._dnDHandler.onDragEnter(evt);
        this.dropState = 'enter';
        this.entered.emit({ event: evt, container: this });
    }

    private onDragReEnterPreviewArea(evt: DroppableEvent) {
        this._dnDHandler.onDragReEnterPreviewArea(evt);
        this.dropState = 'outside';
        this.enteredStart.emit({ event: evt, container: this });
    }

    private onDragLeave(evt: DroppableEvent) {
        this._dnDHandler.onDragLeave(evt);
        this.dropState = 'outside';
        this.left.emit({ event: evt, container: this });
    }

    private onDragCancel(evt: DroppableEvent) {
        this._dnDHandler.onDragCancel(evt);
        this.dropState = 'stop';
        this.canceled.emit({ event: evt, container: this });
    }

    set dropState(value: MirrorDropState) {
        if (value && value !== this._dropState) {
            document.body.classList.remove('app-mirror-enter');
            document.body.classList.remove('app-mirror-outside');
            document.body.classList.add(`app-mirror-${value}`);
            this._dropState = value;
        }
    }

    ngOnDestroy(): void {
        if (this.droppableHandlerService) {
            this.droppableHandlerService.ngOnDestroy();
        }
    }
}

interface ZoneAndMirrorRefs {
    mirrorComponentRef: DroppableMirrorComponent;
    dropZoneRef: DropZoneDirective | undefined;
}

export interface DroppableContainerEvent {
    event: DroppableEvent;
    /** container of the item on which the drag event is targeted to**/
    container: DroppableContainer;
}
