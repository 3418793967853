import { Rectangle2D } from '../../../../../shared/geom/rectangle2D';
import { CanvasBoundsChange } from '../../../../../canvas/shared/canvas-bounds.manager';
import { Dimension2D } from '../../../../../shared/geom/dimension2d';
import { CanvasScrollViewportDirective } from '../../../../../canvas/shared/canvas-scroll-viewport.directive';

declare var Snap: any;

export class UploadCanvasBoundsChangeHandler {
    constructor(private scrollViewport: CanvasScrollViewportDirective) {}

    handleBoundsChange(
        change: CanvasBoundsChange,
        canvasNativeElement: HTMLElement,
        oldScaleFactor: number,
        imageSize: Dimension2D,
        layersElement: { layerItems: HTMLElement | SVGGraphicsElement; others: HTMLElement[] }
    ) {
        if (change.canvasViewportBounds) {
            const pxWidth = change.canvasViewportBounds.outerBounds.width + 'px';
            const pxHeight = change.canvasViewportBounds.outerBounds.height + 'px';

            canvasNativeElement.style.maxWidth = pxWidth;
            canvasNativeElement.style.maxHeight = pxHeight;
        }

        if (change.scaleFactor) {
            const tx = Snap(layersElement.layerItems).transform().localMatrix;
            tx.a = change.scaleFactor;
            tx.d = change.scaleFactor;

            if (change.centerXY) {
                // tx.translate(change.centerXY.x / change.scaleFactor, change.centerXY.y / change.scaleFactor);
            }
            let dx, dy;
            if (oldScaleFactor != null) {
                dx = imageSize.width * oldScaleFactor - imageSize.width * change.scaleFactor;
                dy = imageSize.height * oldScaleFactor - imageSize.height * change.scaleFactor;
                tx.e = tx.e + dx / 2;
                tx.f = tx.f + dy / 2;

                const globalBBox = layersElement.layerItems.getBoundingClientRect();
                // transformed bbox
                const bbox = new Rectangle2D(
                    globalBBox.left + dx / 2,
                    globalBBox.top + dy / 2,
                    imageSize.width * change.scaleFactor,
                    imageSize.height * change.scaleFactor
                );

                this.snapToBorder(tx, bbox);
                this.centerBackground(tx, bbox);

                const transformString = tx.toString();
                layersElement.layerItems.setAttribute('transform', transformString);
                if (layersElement.others) {
                    // NOTE for canvas element we must use the style attribute
                    layersElement.others.forEach(l => (l.style.transform = transformString));
                }

                // this.borderDebugCanvasContext.setTransform(tx.a, tx.b, tx.c, tx.d, tx.e, tx.f);
                // redraw
                //  this.drawAllBorders(this.paintSelectionModel.borderIndices,
                //      this.imageSize.width, this.imageSize.height, this.borderDebugCanvasContext);
            } else {
                // initial --> translate to center
                // TODO can we reuse the centerBackground
                tx.translate(
                    change.centerXY.x / change.scaleFactor,
                    change.centerXY.y / change.scaleFactor
                );
                tx.e = change.centerXY.x;
                tx.f = change.centerXY.y;

                const transformString = tx.toString();
                layersElement.layerItems.setAttribute('transform', transformString);
                if (layersElement.others) {
                    // NOTE for canvas element we must use the style attribute
                    layersElement.others.forEach(l => (l.style.transform = transformString));
                }
                // this.borderDebugCanvasContext.setTransform(tx.a, tx.b, tx.c, tx.d, tx.e, tx.f);
            }

            // const currentElmTx = Snap(this.layerItems.nativeElement).transform().localMatrix;
            // tx.add(currentElmTx);
        }
    }

    private snapToBorder(tx: any, bbox: Rectangle2D) {
        const canvasBounds = this.scrollViewport.getScrollViewportBounds().outerBounds;
        if (bbox.width > canvasBounds.width) {
            const d2x = canvasBounds.x - bbox.x;
            const d2x2 = canvasBounds.x2 - bbox.x2;

            const leftIsInside = d2x < 0;
            const rightIsInside = d2x2 > 0;
            if (leftIsInside && !rightIsInside) {
                tx.e += d2x;
            } else if (!leftIsInside && rightIsInside) {
                tx.e += d2x2;
            }
        }

        if (bbox.height > canvasBounds.height) {
            const d2y = canvasBounds.y - bbox.y;
            const d2y2 = canvasBounds.y2 - bbox.y2;

            const topIsInside = d2y < 0;
            const bottomIsInside = d2y2 > 0;
            if (topIsInside && !bottomIsInside) {
                tx.f += d2y;
            } else if (!topIsInside && bottomIsInside) {
                tx.f += d2y2;
            }
        }
    }

    private centerBackground(tx: any, bbox: Rectangle2D) {
        const canvasBounds = this.scrollViewport.getScrollViewportBounds().outerBounds;
        if (bbox.height < canvasBounds.height) {
            const centerY = canvasBounds.cy - bbox.cy;
            tx.f += centerY;
        }
        if (bbox.width < canvasBounds.width) {
            const centerX = canvasBounds.cx - bbox.cx;
            tx.e += centerX;
        }
    }
}
