import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ProducerImageContourModel } from '../../../models/image-contour.model';
import { ConfiguratorStoreService } from '../../../configurator-store.service';
import { DialogRefs } from '../../../dialog/dialog-refs';

@Component({
    selector: 'app-partner-image-contour-info',
    templateUrl: './partner-contour-info-dialog.component.html',
    styleUrls: ['./partner-contour-info-dialog.component.scss']
})
export class PartnerContourInfoDialogComponent implements OnInit {
    buttonCloseText: string;
    partnerImageContour: ProducerImageContourModel;
    constructor(
        public dialogRefs: DialogRefs<PartnerContourInfoDialogComponent>,
        private imageContourService: ConfiguratorStoreService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.translate.stream(['NAVIGATION_BUTTON']).subscribe(res => {
            this.buttonCloseText = res['NAVIGATION_BUTTON'].CLOSE;
        });
    }

    close() {
        this.dialogRefs.close();
    }
}
