import { Action } from './action';
import { FoamEditorService } from '../foam-editor.service';
import { CanvasService } from '../canvas/canvas.service';
import { MoveUndoableEditAction } from '../undo/move-undoable-edit-action';

export abstract class AbstractMoveAction implements Action {
    moveStepValue: number = 1;
    private readonly canvasService: CanvasService;

    constructor(private readonly foamEditor: FoamEditorService, moveStepValue?: number) {
        if (moveStepValue !== undefined) {
            this.moveStepValue = moveStepValue;
        }
        this.canvasService = this.foamEditor.getCanvasService();
    }

    execute(event: KeyboardEvent) {
        const deltaXY = this.getDeltaXY(this.moveStepValue);
        if (!deltaXY) {
            return;
        }

        const contoursToMove = this.canvasService.getSelectedContourItems();
        if (contoursToMove.length > 0) {
            event.preventDefault();
            contoursToMove.forEach(x => {
                x.translate(deltaXY.x, deltaXY.y);
            });

            const selectionInfo = {
                addToSelection: this.canvasService.addToSelection,
                selectedContours: contoursToMove
            };

            this.canvasService
                .getUndoManagerService()
                .addEditAction(new MoveUndoableEditAction(contoursToMove, deltaXY, selectionInfo));
        }
    }

    abstract getDeltaXY(moveStepValue: number): { x: number; y: number };
}

export class UpMoveAction extends AbstractMoveAction {
    getDeltaXY(moveStepValue: number): { x: number; y: number } | undefined {
        if (!moveStepValue) {
            return undefined;
        }
        return { x: 0, y: -moveStepValue };
    }
}

export class DownMoveAction extends AbstractMoveAction {
    getDeltaXY(moveStepValue: number): { x: number; y: number } | undefined {
        if (!moveStepValue) {
            return undefined;
        }
        return { x: 0, y: moveStepValue };
    }
}

export class RightMoveAction extends AbstractMoveAction {
    getDeltaXY(moveStepValue: number): { x: number; y: number } | undefined {
        if (!moveStepValue) {
            return undefined;
        }
        return { x: moveStepValue, y: 0 };
    }
}

export class LeftMoveAction extends AbstractMoveAction {
    getDeltaXY(moveStepValue: number): { x: number; y: number } | undefined {
        if (!moveStepValue) {
            return undefined;
        }
        return { x: -moveStepValue, y: 0 };
    }
}
