import { ModelDecoder } from './model.decoder';
import { PositionData } from './position-data.model';

export class FoamContourModel {
    id: number;
    title: string;
    width: number;
    height: number;
    depth: number;
    innerMargin: number;
    svgPathDefinition: string;
    positionData: PositionData;
    teilVersenkung: boolean;

    constructor(obj: FoamContourModel) {
        this.id = ModelDecoder.number(obj.id);
        this.title = ModelDecoder.string(obj.title);
        this.width = ModelDecoder.number(obj.width);
        this.height = ModelDecoder.number(obj.height);
        this.depth = ModelDecoder.number(obj.depth);
        this.innerMargin = ModelDecoder.number(obj.innerMargin, 0);

        this.svgPathDefinition = ModelDecoder.string(obj.svgPathDefinition);
        this.teilVersenkung = ModelDecoder.boolean(obj.teilVersenkung, false);
        if (obj.positionData && obj.positionData.localMatrix) {
            this.positionData = ModelDecoder.object(obj.positionData);
        } else {
            this.positionData = new PositionData({
                localMatrix: ModelDecoder.matrix({ a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 })
            });
        }
        // TODO: activate this again
        /*, {  localMatrix: ModelDecoder.matrix } */
    }
}
