import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Stores and manages undoable edit actions
 */
@Injectable({
    providedIn: 'root'
})
export class UndoManagerService {
    private editActions: UndoableEditaAction[];
    /**
     * Either the index of the next edit action that was undone (if an undo has been performed), or
     * the index of the next edit action (which is the size of the list), if no undo has been performed
     */
    private nextActionIndex: number;

    private undoStateChangedSubject: Subject<UndoState> = new Subject();
    undoStateChanged: Observable<UndoState> = this.undoStateChangedSubject.asObservable();

    constructor() {
        this.editActions = [];
        this.nextActionIndex = 0;
    }

    /**
     * Adds an edit action to the history of undoable actions.
     *
     * @param editAction
     */
    addEditAction(editAction: UndoableEditaAction) {
        if (this.nextActionIndex < this.editActions.length && this.editActions.length > 0) {
            const deleteCount = this.editActions.length - this.nextActionIndex;
            this.editActions.splice(this.nextActionIndex, deleteCount);
        }
        this.editActions.push(editAction);
        this.nextActionIndex = this.editActions.length;
        this.fireStateChange();
    }

    /**
     * Performs an undo action
     */
    undo() {
        if (this.nextActionIndex > 0) {
            const action = this.editActions[--this.nextActionIndex];
            action.undo();
            this.fireStateChange();
        }
    }

    /**
     * Performs a redo action.
     *
     */
    redo() {
        if (this.nextActionIndex < this.editActions.length && this.editActions.length > 0) {
            const nextAction = this.editActions[this.nextActionIndex++];
            nextAction.redo();
            this.fireStateChange();
        }
    }

    private fireStateChange() {
        const undoEditAction = this.getActionToBeUndone();
        let nextUndoAction: { name: string };
        if (undoEditAction) {
            nextUndoAction = {
                name: undoEditAction.presentationName
            };
        }

        const redoEditAction = this.getActionToBeRedone();
        let nextRedoAction: { name: string };
        if (redoEditAction) {
            nextRedoAction = {
                name: redoEditAction.presentationName
            };
        }

        this.undoStateChangedSubject.next({
            nextUndoAction: nextUndoAction,
            nextRedoAction: nextRedoAction
        });
    }

    getActionToBeUndone(): UndoableEditaAction | null {
        if (this.nextActionIndex > 0 && this.editActions.length > 0) {
            const toBeUndoneIndex = this.nextActionIndex - 1;
            return this.editActions[toBeUndoneIndex];
        }
        return null;
    }

    getActionToBeRedone(): UndoableEditaAction | null {
        if (this.nextActionIndex < this.editActions.length && this.editActions.length > 0) {
            return this.editActions[this.nextActionIndex];
        }
        return null;
    }

    canUndo(): boolean {
        return this.getActionToBeUndone() !== null;
    }

    canRedo(): boolean {
        return this.getActionToBeRedone() !== null;
    }
}

export interface UndoableEditaAction {
    presentationName: string;

    undo(): void;
    redo(): void;
}

export interface UndoState {
    nextUndoAction?: {
        name: string;
    };
    nextRedoAction?: {
        name: string;
    };
}
