import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogRefs } from '../dialog-refs';

@Component({
    selector: 'app-dialog-footer',
    templateUrl: './dialog-footer.component.html',
    styleUrls: ['./dialog-footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'modal-card-foot'
    }
})
export class DialogFooterComponent<T> implements OnInit {
    dialogRefs: DialogRefs<T>;

    constructor(dialogRefs: DialogRefs<T>) {}

    ngOnInit() {}
}
