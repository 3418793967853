import { DOCUMENT } from '@angular/common';
import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';

import { InlComponentPortal, PortalSlot } from '../shared/portal/portal';

import { ModalDialogSlotDirective } from './modal-dialog-slot.directive';

export type DialogSize = 'small' | 'medium' | 'large';
export const DEFAULT_DIALOG_SIZE: DialogSize = 'medium';

/**
 * Wraps dialog content
 *
 */
@Component({
    selector: 'app-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: ['./dialog-container.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'app-dialog-container'
    }
})
export class DialogContainerComponent extends PortalSlot implements OnDestroy {
    /** Used this template as a slot for the InlComponentPortal **/
    @ViewChild(ModalDialogSlotDirective, { static: true })
    readonly dialogContainerSlot;

    /** Fires when a portal is attached to this container slot. */
    @Output()
    attached: EventEmitter<ComponentRef<any>> = new EventEmitter<ComponentRef<any>>();

    private attachedComponentRef: ComponentRef<any>;

    private _dialogSize: DialogSize;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        @Inject(DOCUMENT) private document: any
    ) {
        super();

        // set default size
        this.dialogSize = DEFAULT_DIALOG_SIZE;
    }

    attach<T>(portal: InlComponentPortal<T>): ComponentRef<T> {
        super.checkPortalArgs(portal);
        return this.attachComponentPortal(portal);
    }

    private attachComponentPortal<T>(portal: InlComponentPortal<T>): ComponentRef<T> {
        portal.attach(this, true);

        const resolver = portal.componentFactoryResolver || this.componentFactoryResolver;
        const componentFactory = resolver.resolveComponentFactory(portal.component);

        const viewContainerRef = this.dialogContainerSlot.viewContainerRef;
        viewContainerRef.clear();

        this.attachedComponentRef = viewContainerRef.createComponent(
            componentFactory,
            viewContainerRef.length,
            portal.injector || viewContainerRef.injector
        );

        this.attachedPortal = portal;
        this.attached.emit(viewContainerRef);

        this.elementRef.nativeElement.focus();
        this.document.body.classList.add('modal-is-open');

        return this.attachedComponentRef;
    }

    @Input()
    set dialogSize(value: DialogSize) {
        if (value && this._dialogSize !== value) {
            if (this._dialogSize) {
                this.elementRef.nativeElement.classList.remove(`dialog-size-${this._dialogSize}`);
            }

            this.elementRef.nativeElement.classList.add(`dialog-size-${value}`);
            this._dialogSize = value;
        }
    }

    detach(): any {
        super.detach();
        this.document.body.classList.remove('modal-is-open');
        this.disposeAttachedComponent();
    }

    hasAttached(): boolean {
        return !!this.attachedPortal;
    }

    ngOnDestroy() {
        this.dispose();
    }

    dispose(): void {
        super.dispose();
        this.disposeAttachedComponent();

        this.attachedPortal = null;
        this.attachedComponentRef = null;
    }

    private disposeAttachedComponent() {
        if (this.attachedComponentRef) {
            this.attachedComponentRef.destroy();
        }
    }
}
