<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <div class="dialog-content__title">{{ 'MAT_CHOICE_DIALOG.TITLE' | translate }}</div>

                <div class="mat-choice__container">
                    <a href="/assets/pdf/Sortimo_Inlay_Unterlage_A4.pdf" download>
                        <app-button
                            app-icon-button
                            color="quaternary"
                            [buttonSize]="'xlarge'"
                            iconAlign="vertical"
                        >
                            <app-icon
                                class="download-btn-label-icon"
                                shape="download-dina-4"
                                iconSize="large"
                            ></app-icon>
                            <div
                                class="download-btn-label__content"
                                [innerHTML]="'MAT_CHOICE_DIALOG.DIN_A4' | translate"
                            ></div>
                        </app-button>
                    </a>

                    <a href="/assets/pdf/Sortimo_Inlay_Unterlage_A3.pdf" download>
                        <app-button
                            app-icon-button
                            color="quaternary"
                            [buttonSize]="'xlarge'"
                            iconAlign="vertical"
                        >
                            <app-icon
                                class="download-btn-label-icon"
                                shape="download-dina-3"
                                iconSize="large"
                            ></app-icon>
                            <div
                                class="download-btn-label__content"
                                [innerHTML]="'MAT_CHOICE_DIALOG.DIN_A3' | translate"
                            ></div>
                        </app-button>
                    </a>
                </div>
            </div>
            <p class="print_info">
                {{ 'MAT_CHOICE_DIALOG.PRINT_INFO' | translate }}
            </p>
        </div>
    </div>
</app-dialog-content>
<app-upload-dialog-footer></app-upload-dialog-footer>
