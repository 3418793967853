/**
 * This directive centers the foam  within the workspace
 */
import { Directive, ElementRef, OnInit } from '@angular/core';
import { CanvasBoundsChange } from '../shared/canvas-bounds.manager';
import { FoamContourElementHolder } from '../contour-element-builder.service';
import { FoamDrawData, FoamInnerMarginDrawData } from '../contour/contour-items-interfaces';
import { LayerElementStoreService } from '../layer-element-store.service';
import { CanvasLayer } from './canvas-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';

declare var Snap: any;

@Directive({ selector: '[appFoamLayer]' })
export class FoamLayerDirective extends CanvasLayerMixin()
    implements OnInit, CanvasLayer<Snap.Paper> {
    element: Snap.Paper;
    /**
     * @Deprecated use element instead
     */
    svgElement: Snap.Paper;
    foamPath: Snap.Element;
    foamMarginPath: Snap.Element;
    foamHeight: number;
    foamWidth: number;

    private foamId: string;
    readonly pathCssSelector: string = '#foam-path';
    readonly pathMarginCssSelector: string = '#foam-inner-margin-path';
    private foamElementHolder: FoamContourElementHolder;
    private foamInnerMarginElementHolder: FoamContourElementHolder;

    constructor(
        private elementRef: ElementRef,
        private layerElementStore: LayerElementStoreService
    ) {
        super();
        this.svgElement = this.element = Snap(this.elementRef.nativeElement);
    }

    ngOnInit(): void {
        this.foamPath = this.element.select(this.pathCssSelector);
        this.foamMarginPath = this.element.select(this.pathMarginCssSelector);
    }

    protected onDimensionsChanged(boundsChangeEvent: CanvasBoundsChange) {
        /*  if (this.foamElementHolder) {
              const changedEvent = this.foamElementHolder.getDrawData();
              this.layerElementStore.notifyFoamContourChange(changedEvent);
          } */
    }

    setFoamItem(contourData: FoamDrawData) {
        this.foamId = contourData.contourId;

        if (!this.foamElementHolder) {
            this.foamElementHolder = new FoamContourElementHolder(this.foamId);
            this.layerElementStore.setFoamContour(this.foamElementHolder);
        }

        this.foamElementHolder.setPath(this.foamPath, contourData);

        const changedEvent = this.foamElementHolder.getDrawData(true);
        const localBBox = this.foamElementHolder.transformData.localBBox;
        this.foamWidth = localBBox.width;
        this.foamHeight = localBBox.height;

        this.layerElementStore.notifyFoamContourChange(changedEvent);

        if (contourData.innerMarginDrawData) {
            this.setFoamInnerMargin(contourData.innerMarginDrawData);
        }
    }

    private setFoamInnerMargin(contourData: FoamInnerMarginDrawData) {
        if (!this.foamInnerMarginElementHolder) {
            this.foamInnerMarginElementHolder = new FoamContourElementHolder(contourData.contourId);
            this.layerElementStore.setFoamInnerMarginContour(this.foamInnerMarginElementHolder);
        }

        this.foamInnerMarginElementHolder.setPath(this.foamMarginPath, contourData);

        const changedEvent = this.foamInnerMarginElementHolder.getDrawData(true);

        this.layerElementStore.notifyFoamInnerMarginContourChange(changedEvent);
    }
}
