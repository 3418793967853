<div class="display-functions-panel">
    <div class="display-functions-panel__row">
        <div class="display-functions-panel__toggle-switch">
            <app-toggle-switch
                [inputId]="'tool-images__switch'"
                [labelWidth]="'204px'"
                [labelHeight]="'24px'"
                [checked]="isShowContourImageEnabled | async"
                [isDisabled]="false"
                (change)="toggleShowContourImage($event.target.checked)"
            >
                {{ 'ON_OFF_SLIDER.TOOL_PICTURES' | translate }}
            </app-toggle-switch>
        </div>
    </div>
</div>
