import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * Stores and manages undoable edit actions
 */
export class UndoManagerService {
    constructor() {
        this.undoStateChangedSubject = new Subject();
        this.undoStateChanged = this.undoStateChangedSubject.asObservable();
        this.editActions = [];
        this.nextActionIndex = 0;
    }
    /**
     * Adds an edit action to the history of undoable actions.
     *
     * @param editAction
     */
    addEditAction(editAction) {
        if (this.nextActionIndex < this.editActions.length && this.editActions.length > 0) {
            const deleteCount = this.editActions.length - this.nextActionIndex;
            this.editActions.splice(this.nextActionIndex, deleteCount);
        }
        this.editActions.push(editAction);
        this.nextActionIndex = this.editActions.length;
        this.fireStateChange();
    }
    /**
     * Performs an undo action
     */
    undo() {
        if (this.nextActionIndex > 0) {
            const action = this.editActions[--this.nextActionIndex];
            action.undo();
            this.fireStateChange();
        }
    }
    /**
     * Performs a redo action.
     *
     */
    redo() {
        if (this.nextActionIndex < this.editActions.length && this.editActions.length > 0) {
            const nextAction = this.editActions[this.nextActionIndex++];
            nextAction.redo();
            this.fireStateChange();
        }
    }
    fireStateChange() {
        const undoEditAction = this.getActionToBeUndone();
        let nextUndoAction;
        if (undoEditAction) {
            nextUndoAction = {
                name: undoEditAction.presentationName
            };
        }
        const redoEditAction = this.getActionToBeRedone();
        let nextRedoAction;
        if (redoEditAction) {
            nextRedoAction = {
                name: redoEditAction.presentationName
            };
        }
        this.undoStateChangedSubject.next({
            nextUndoAction: nextUndoAction,
            nextRedoAction: nextRedoAction
        });
    }
    getActionToBeUndone() {
        if (this.nextActionIndex > 0 && this.editActions.length > 0) {
            const toBeUndoneIndex = this.nextActionIndex - 1;
            return this.editActions[toBeUndoneIndex];
        }
        return null;
    }
    getActionToBeRedone() {
        if (this.nextActionIndex < this.editActions.length && this.editActions.length > 0) {
            return this.editActions[this.nextActionIndex];
        }
        return null;
    }
    canUndo() {
        return this.getActionToBeUndone() !== null;
    }
    canRedo() {
        return this.getActionToBeRedone() !== null;
    }
}
UndoManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UndoManagerService_Factory() { return new UndoManagerService(); }, token: UndoManagerService, providedIn: "root" });
