import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ShowContourImageAction } from '../../../actions/show-contour-image.action';
import { ShowSafetyMarginAction } from '../../../actions/show-safety-margin.action';
import { CanvasProperties } from '../../../canvas/canvas.service';
import { FoamEditorService } from '../../../foam-editor.service';
import { RibbonPanelContent } from '../ribbon-panel-content';

@Component({
    selector: 'app-overview-panel',
    templateUrl: './overview-panel.component.html',
    styleUrls: ['./overview-panel.component.scss'],
    providers: [ShowContourImageAction, ShowSafetyMarginAction]
})
export class OverviewPanelComponent extends RibbonPanelContent() implements OnInit {
    panelTitle = 'Übersicht';
    panelIndex = 3;
    panelId = 'overview';
    isContourImageShown: boolean = false;
    isContourSafeMarginShown: boolean = false;

    constructor(
        private foamEditorService: FoamEditorService,
        private translate: TranslateService,
        private showContourImageAction: ShowContourImageAction,
        private showSafetyMarginAction: ShowSafetyMarginAction
    ) {
        super();
    }

    ngOnInit() {
        this.translate.stream(['OVERVIEW_RIBBON.MAIN_MENU']).subscribe(res => {
            this.panelTitle = res['OVERVIEW_RIBBON.MAIN_MENU'];
        });

        this.foamEditorService
            .getCanvasService()
            .getProperties()
            .subscribe((prop: CanvasProperties) => {
                this.isContourImageShown = prop.showContourImage;
                this.isContourSafeMarginShown = prop.showContourSafeMargin;
            });

        this.showContourImage(this.isContourImageShown);
        this.showSafetyMargin(this.isContourSafeMarginShown);
    }

    showContourImage(isVisible: boolean) {
        this.showContourImageAction.execute({
            showContourImage: isVisible
        });
    }

    showSafetyMargin(isVisible: boolean) {
        this.showSafetyMarginAction.execute({
            showContourSafeMargin: isVisible
        });
    }
}
