import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

export type IconSize = 'small' | 'normal' | 'medium' | 'large';
const DEFAULT_ICON_SIZE = 'normal';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnInit {
    private svgIconFileUrl = '/assets/icons/icons-defs.svg';
    private _shape: string;
    shapeUrl: string;

    @ViewChild('svgElement', { static: false })
    readonly svgElement;

    @Input()
    svgWidth = '100%';

    @Input()
    svgHeight = '100%';

    private _cssWidth: string;
    private _cssHeight: string;

    private _iconSize: IconSize;
    private _isDisabled = false;

    constructor(private _elementRef: ElementRef) {
        this.iconSize = DEFAULT_ICON_SIZE;
    }

    @Input()
    set shape(value: string) {
        if (value && value !== this._shape) {
            this._shape = value;
            this.shapeUrl = this.svgIconFileUrl + '#' + value;
        }
    }

    get shape() {
        return this._shape;
    }

    @Input()
    set iconSize(value: IconSize) {
        if (value) {
            if (this._iconSize) {
                this._elementRef.nativeElement.classList.remove(`app-icon-size-${this._iconSize}`);
            }

            this._elementRef.nativeElement.classList.add(`app-icon-size-${value}`);
            this._iconSize = value;
        }
    }

    get iconSize() {
        return this._iconSize;
    }

    @Input()
    set cssWidth(value: string) {
        if (value) {
            this._cssWidth = value;
        }
    }

    get cssWidth(): string {
        return this._cssWidth;
    }

    @Input()
    set cssHeight(value: string) {
        if (value) {
            this._cssHeight = value;
        }
    }

    get cssHeight(): string {
        return this._cssHeight;
    }

    // Use Disabled mixin
    @Input()
    get disabled(): boolean {
        return this._isDisabled;
    }

    set disabled(value: boolean) {
        if (this._isDisabled !== value) {
            if (value) {
                this._elementRef.nativeElement.classList.add('app-icon-disabled');
                this._isDisabled = value;
            } else {
                this._elementRef.nativeElement.classList.remove('app-icon-disabled');
                this._isDisabled = value;
            }
        }
    }

    ngOnInit() {}
}
