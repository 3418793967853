import { transformPoint } from '../../shared/geom/matrix';
import { RotationHandleData } from '../contour/contour-items-interfaces';
import { Point2D } from '../../shared/geom/point2D';
import { ContourTransformationBounds } from './contour-transformation-bounds';

declare var Snap: any;

export const ID_ROTATION_HANDLE_GROUP = 'rotation-handle-';

export const CLASS_NAME_ROTATION_GRIP = 'rotation-grip';
export const CLASS_NAME_ROTATION_GRIP_LINE = 'rotation-grip-line';

export const ID_ROTATION_GRIP_ITEM = CLASS_NAME_ROTATION_GRIP + '-';
export const ID_ROTATION_GRIP_LINE_ITEM = CLASS_NAME_ROTATION_GRIP_LINE + '-';

export const CLASS_NAME_ROTATION_HANDLE_GROUP = 'rotation-handle-group-';

export enum RotationConnectorType {
    /** the rotation line starts above the selection bbox and ends on the line**/
    START_ABOVE_END_ON,
    /** the rotation line starts on the top line of the selection bbox and ends on the bbox's center **/
    START_ON_END_CENTER,
    START_ABOVE_END_CENTER
}

export class RotationHandlesDrawer {
    private rotationHandleGroup: Snap.Paper;
    private rotationLine: Snap.Element;
    private rotationGrip: Snap.Element;
    private rotationHandleIconGroup: Snap.Paper;
    private rotationGripIcon: Snap.Element;

    private initDistanceFromGripBottomToSelectionBox = 17;

    private gripWidth = 11;
    private gripHeight = 11;
    private distanceFromGripBottomToSelectionBox = 17;
    private distanceFromGripCenterToSelectionBox: number;
    private distanceFromGripTopToSelectionBox: number;
    private gripRadius = 5.5;
    private visible = true;
    private connectorType: RotationConnectorType;
    private startLinePosition: Point2D;
    private endLinePosition: Point2D;
    private gripPosition: Point2D;

    constructor(
        public readonly id: string,
        private containerElement: Snap.Paper,
        handlesData: RotationHandleData,
        private selectionBounds: ContourTransformationBounds
    ) {
        this.init(handlesData);
    }

    private init(handlesData: RotationHandleData) {
        this.connectorType = handlesData.connectorType;

        this.distanceFromGripCenterToSelectionBox =
            this.initDistanceFromGripBottomToSelectionBox + this.gripRadius;

        this.distanceFromGripCenterToSelectionBox =
            this.distanceFromGripBottomToSelectionBox + this.gripRadius;

        // TODO should be removed
        this.distanceFromGripTopToSelectionBox =
            this.gripHeight + this.distanceFromGripBottomToSelectionBox;

        this.createRotationHandleGroup(handlesData);

        this.createRotationLine();
        this.createRotationGripIcon();
        this.createRotationGrip();

        this.updateHandlesPosition();
    }

    private createRotationHandleGroup(handlesData: RotationHandleData) {
        const groupId = ID_ROTATION_HANDLE_GROUP + this.id;
        this.rotationHandleGroup = this.containerElement.g();
        this.rotationHandleGroup.node.setAttribute('id', groupId);
        const displayValue = handlesData.visible ? 'inline' : 'none';
        this.rotationHandleGroup.node.setAttribute('display', displayValue);

        this.rotationHandleGroup.addClass(CLASS_NAME_ROTATION_HANDLE_GROUP + this.id);
    }

    updateHandles(handleData: RotationHandleData) {
        if (!handleData) {
            return;
        }

        if (this.visible !== handleData.visible) {
            this.visible = handleData.visible;
            const displayValue = this.visible ? 'inline' : 'none';
            this.rotationHandleGroup.attr({ display: displayValue });
        }
    }

    private createRotationLine() {
        const lineStartX = this.gripRadius;
        const lineStartY = this.gripHeight;
        const lineEndX = lineStartX;
        // se endY to 1 as the correct value will be set afterwards in the #updateHandlesPosition
        const lineEndY = 1;

        this.rotationLine = this.rotationHandleGroup.line(
            lineStartX,
            lineStartY,
            lineEndX,
            lineEndY
        );
        this.rotationLine.addClass(CLASS_NAME_ROTATION_GRIP_LINE);
        this.rotationLine.node.id = ID_ROTATION_GRIP_LINE_ITEM + this.id;
    }

    private createRotationGripIcon() {
        this.rotationGripIcon = Snap(document.createElementNS('http://www.w3.org/2000/svg', 'use'));
        // HACK: use ht <g> element is used for doing transformation on <use> element using Snap.svg
        this.rotationHandleIconGroup = this.rotationHandleGroup.g();
        this.rotationHandleIconGroup.add(this.rotationGripIcon);
        this.rotationGripIcon.attr({
            'xlink:href': '/assets/icons/icons-defs.svg#rotation-handle',
            x: 0,
            y: 0,
            width: this.gripWidth.toString(),
            height: this.gripHeight.toString()
        });
    }

    private createRotationGrip() {
        const circleXY = this.gripWidth / 2;
        this.rotationGrip = this.rotationHandleGroup.circle(
            circleXY,
            circleXY,
            this.gripRadius / 2
        );
        this.rotationGrip.node.id = ID_ROTATION_GRIP_ITEM + this.id;
        this.rotationGrip.addClass(CLASS_NAME_ROTATION_GRIP);
    }

    getLineLocalPosition(): { start: Point2D; end: Point2D } {
        const bounds = this.selectionBounds.getLocalOuterBounds();

        let yStart = bounds.y;
        let yEnd = bounds.y + bounds.height / 2;

        if (this.connectorType === RotationConnectorType.START_ABOVE_END_ON) {
            yStart -= this.distanceFromGripBottomToSelectionBox;
            yEnd = 0;
        } else if (this.connectorType === RotationConnectorType.START_ABOVE_END_CENTER) {
            yStart -= this.distanceFromGripBottomToSelectionBox / 2;
        }
        const xStart = bounds.cx;

        return {
            start: new Point2D(xStart, yStart),
            end: new Point2D(xStart, yEnd)
        };
    }

    /**
     * Updates handles position
     * This method should be called each time when an item is selected or resized
     *
     */
    updateHandlesPosition() {
        this.updateGripIconPosition();

        const { start, end } = this.getLineLocalPosition();
        this.startLinePosition = start;
        this.endLinePosition = end;

        const matrix = this.selectionBounds.getLocalOuterMatrix();

        const lineStartPt = transformPoint(start.x, start.y, matrix);
        const lineEndPt = transformPoint(end.x, end.y, matrix);

        this.gripPosition = new Point2D(start.x, start.y - this.gripRadius);
        const gripPt = transformPoint(this.gripPosition.x, this.gripPosition.y, matrix);

        this.rotationLine.attr({
            x1: lineStartPt.x.toFixed(4),
            y1: lineStartPt.y.toFixed(4),
            x2: lineEndPt.x.toFixed(4),
            y2: lineEndPt.y.toFixed(4)
        });

        this.rotationGrip.attr({
            cx: gripPt.x.toFixed(4),
            cy: gripPt.y.toFixed(4),
            r: this.gripRadius
        });
    }

    private updateGripIconPosition() {
        // Snap.getBbOX does not work for <use> element
        const gripIconBBox = (this.rotationHandleIconGroup as any).getBBox(true);
        // typically this happens when the <use> element is not fully loaded
        let width = gripIconBBox.width;
        let height = gripIconBBox.height;
        if (gripIconBBox.width === 0 && gripIconBBox.height === 0) {
            width = this.gripWidth;
            height = this.gripHeight;
        }

        const gripPos = this.getGripIconPosition();
        this.rotationGripIcon.attr({
            x: gripPos.x,
            y: gripPos.y,
            width: width.toFixed(4),
            height: height.toFixed(4)
        });

        this.rotationHandleIconGroup.node.setAttribute(
            'transform',
            this.selectionBounds.getLocalOuterMatrix().toString()
        );
    }

    private getGripIconPosition(): Point2D {
        const bounds = this.selectionBounds.getLocalOuterBounds();
        const ptX = bounds.x + bounds.width / 2 - this.gripRadius;
        let ptY = bounds.y - this.gripRadius * 2;

        if (this.connectorType === RotationConnectorType.START_ABOVE_END_ON) {
            ptY -= this.distanceFromGripBottomToSelectionBox;
        } else if (this.connectorType === RotationConnectorType.START_ABOVE_END_CENTER) {
            ptY -= this.distanceFromGripBottomToSelectionBox / 2;
        }

        return new Point2D(ptX, ptY);
    }

    /*
    private scaleSizes(scaleFactor: number) {
        this.gripRadius = this.initGripRadius / scaleFactor;
        this.distanceFromGripBottomToSelectionBox =
            this.initDistanceFromGripBottomToSelectionBox / scaleFactor;
        this.gripWidth = this.initGripWidth / scaleFactor;
        this.gripHeight = this.initGripHeight / scaleFactor;
    } */

    /*
    rotate(deltaDegree: number, matrix: Snap.Matrix) {
        const lineStartPt = transformPoint(
            this.startLinePosition.x,
            this.startLinePosition.y,
            matrix
        );
        const lineEndPt = transformPoint(this.endLinePosition.x, this.endLinePosition.y, matrix);

        const gripPt = transformPoint(this.gripPosition.x, this.gripPosition.y, matrix);

        this.rotationHandleIconGroup.node.setAttribute('transform', matrix.toString());
        this.rotateLine(lineStartPt, lineEndPt);
        this.rotationGrip.attr({
            cx: gripPt.x,
            cy: gripPt.y,
        });
    } */

    private rotateLine(
        newLinePos1: { x: number; y: number },
        newLinePos2: { x: number; y: number }
    ): void {
        this.rotationLine.attr({
            x1: newLinePos1.x,
            y1: newLinePos1.y,
            x2: newLinePos2.x,
            y2: newLinePos2.y
        });
    }

    transform(matrix: Snap.Matrix): void {
        const gripPt = transformPoint(this.gripPosition.x, this.gripPosition.y, matrix);

        const lineStartPt = transformPoint(
            this.startLinePosition.x,
            this.startLinePosition.y,
            matrix
        );
        const lineEndPt = transformPoint(this.endLinePosition.x, this.endLinePosition.y, matrix);
        this.rotateLine(lineStartPt, lineEndPt);

        this.rotationGrip.attr({
            cx: gripPt.x,
            cy: gripPt.y
        });
        this.rotationHandleIconGroup.node.setAttribute('transform', matrix.toString());
    }
}
