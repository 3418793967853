export enum AlignmentType {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    HORIZONTAL_CENTER = 'HORIZONTAL_CENTER',
    VERTICAL_CENTER = 'VERTICAL_CENTER',
    HORIZONTAL_SPREAD = 'HORIZONTAL_SPREAD',
    VERTICAL_SPREAD = 'VERTICAL_SPREAD'
}
