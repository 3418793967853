/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-container.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-dialog-slot.directive";
import * as i3 from "./dialog-container.component";
import * as i4 from "@angular/common";
var styles_DialogContainerComponent = [i0.styles];
var RenderType_DialogContainerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DialogContainerComponent, data: {} });
export { RenderType_DialogContainerComponent as RenderType_DialogContainerComponent };
function View_DialogContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_DialogContainerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dialogContainerSlot: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "app-dialog-container-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogContainerComponent_1)), i1.ɵdid(3, 16384, [[1, 4]], 0, i2.ModalDialogSlotDirective, [i1.ViewContainerRef], null, null)], null, null); }
export function View_DialogContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-dialog-container", [["class", "app-dialog-container"]], null, null, null, View_DialogContainerComponent_0, RenderType_DialogContainerComponent)), i1.ɵdid(1, 180224, null, 0, i3.DialogContainerComponent, [i1.ElementRef, i1.ComponentFactoryResolver, i1.ViewContainerRef, i4.DOCUMENT], null, null)], null, null); }
var DialogContainerComponentNgFactory = i1.ɵccf("app-dialog-container", i3.DialogContainerComponent, View_DialogContainerComponent_Host_0, { dialogSize: "dialogSize" }, { attached: "attached" }, []);
export { DialogContainerComponentNgFactory as DialogContainerComponentNgFactory };
