import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';

import { Dimension2D } from '../../shared/geom/dimension2d';
import { Point2D } from '../../shared/geom/point2D';
import { Rectangle2D } from '../../shared/geom/rectangle2D';

@Directive({
    selector: '[appCanvasScrollViewport]'
})
export class CanvasScrollViewportDirective implements OnDestroy {
    @Input() pannableNode: SVGElement;

    private invisibleContentWidth: number;
    private invisibleContentHeight: number;
    private oldInvisibleContentWidth: number;
    private oldInvisibleContentHeight: number;

    _scrollEventSubscription: Subscription | null = null;

    private scrollPositionChange = new Subject<Point2D>();
    readonly onScrolled = this.scrollPositionChange.asObservable();
    private _hasScrollbar: boolean;
    private lastPanXY: Point2D = new Point2D(0, 0);

    constructor(private elementRef: ElementRef<HTMLElement>, private ngZone: NgZone) {
        this._scrollEventSubscription = this.ngZone.runOutsideAngular(() => {
            return fromEvent(this.elementRef.nativeElement, 'scroll').subscribe(() =>
                this.scrollPositionChange.next(this.getScrollPosition())
            );
        });

        this.hasScrollbar = true;
    }

    getElement(): ElementRef<HTMLElement> {
        return this.elementRef;
    }

    getScrollViewportBounds(): ScrollViewportBounds {
        const bounds = this.elementRef.nativeElement.getBoundingClientRect() as DOMRect;

        const innerWidth = this.elementRef.nativeElement.clientWidth;
        const innerHeight = this.elementRef.nativeElement.clientHeight;
        return new ScrollViewportBounds(
            new Rectangle2D(bounds.x, bounds.y, innerWidth, innerHeight),
            new Rectangle2D(bounds.x, bounds.y, bounds.width, bounds.height)
        );
    }

    ngOnDestroy(): void {
        if (this._scrollEventSubscription) {
            this._scrollEventSubscription.unsubscribe();
            this._scrollEventSubscription = null;
        }
    }

    scrollContentToCenter(contentDimension: Dimension2D, scaleFactor?: number) {
        const canvasViewport = this.elementRef.nativeElement;

        this.oldInvisibleContentWidth = this.invisibleContentWidth;
        this.oldInvisibleContentHeight = this.invisibleContentHeight;

        // we use the clientWidth/clientHeight instead of the canvasViewportBounds.with/height
        // because clientWidth and clientHeight do not include the scrollbar size

        // use clientWidth/Height instead of canvasViewportBounds as it does not include the
        // scrollbar width/height

        this.invisibleContentWidth = contentDimension.width - canvasViewport.clientWidth;
        this.invisibleContentHeight = contentDimension.height - canvasViewport.clientHeight;

        if (this.oldInvisibleContentWidth == null) {
            this.oldInvisibleContentWidth = this.invisibleContentWidth;
            this.oldInvisibleContentHeight = this.invisibleContentHeight;
        }

        // divide scroll amount by two since uniform scaling involves enlarges the content both
        // directions of each axis
        const scrollX = (this.invisibleContentWidth - this.oldInvisibleContentWidth) / 2;
        const scrollY = (this.invisibleContentHeight - this.oldInvisibleContentHeight) / 2;

        if (this.hasScrollbar) {
            canvasViewport.scrollLeft += scrollX;
            canvasViewport.scrollTop += scrollY;
        } else if (this.pannableNode) {
            // cache the last scroll amount (similar to scrollLeft/scrollRight) since the scroll amount
            // might be resetted by other components, for example, when centering the canvas
            // background (and thus changing e and f)
            const tx = (<SVGGraphicsElement> this.pannableNode).getCTM();
            const dx = canvasViewport.clientWidth / 2 - contentDimension.width / 2;
            const dy = canvasViewport.clientHeight / 2 - contentDimension.height / 2;
            // const tansX = Math.round(scrollX / scaleFactor  * 100) / 100;
            // const tansY = Math.round(scrollY / scaleFactor  * 100) / 100;
            tx.e = this.lastPanXY.x - scrollX;
            tx.f = this.lastPanXY.y - scrollY;
            const txString = `matrix(${tx.a},${tx.b},${tx.c},${tx.d},${dx},${dy})`;
            this.pannableNode.setAttribute('transform', txString);

            this.lastPanXY.x = tx.e;
            this.lastPanXY.y = tx.f;
        }
    }

    @Input()
    set hasScrollbar(value: any) {
        if (!value) {
            return;
        }
        if (value === true || (typeof value === 'string' && value.toLowerCase() === 'true')) {
            this._hasScrollbar = value;
        } else {
            this._hasScrollbar = false;
        }
    }

    get hasScrollbar(): any {
        return this._hasScrollbar;
    }

    getScrollPosition(): Point2D {
        const el = this.elementRef.nativeElement;
        return new Point2D(el.scrollLeft, el.scrollTop);
    }
}

export class ScrollViewportBounds {
    /**
     * The viewport inner bounds which is the viewport bounds without scrollbars and
     * borders
     */
    readonly innerBounds: Rectangle2D;
    /**
     * The viewport outer bounds which is the viewport bounds including the
     * scrollbars
     */
    readonly outerBounds: Rectangle2D;

    constructor(innerBounds: Rectangle2D, outerBounds: Rectangle2D) {
        this.innerBounds = innerBounds;
        this.outerBounds = outerBounds;
    }

    clone(): ScrollViewportBounds {
        return new ScrollViewportBounds(this.innerBounds.clone(), this.outerBounds.clone());
    }

    equals(bounds: ScrollViewportBounds): boolean {
        return (
            bounds.outerBounds.equals(this.outerBounds) &&
            bounds.innerBounds.equals(this.innerBounds)
        );
    }
}
