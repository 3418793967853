import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import Tooltip from 'tooltip.js';

export type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left';

@Directive({
    selector: '[appTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
    @Input()
    appTooltipPlacement: TooltipPlacement;
    private _appToolTipContent: string = '';
    private _appTooltipDisabled: boolean;

    private tooltipInstance: Tooltip;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {
        this.appTooltipPlacement = 'top';
    }

    ngAfterViewInit() {
        if (this.appTooltipDisabled === undefined) {
            this.appTooltipDisabled = false;
        }
    }

    private createTooltipInstance() {
        this.tooltipInstance = new Tooltip(this.elementRef.nativeElement, {
            container: window.document.body,
            title: this._appToolTipContent,
            placement: this.appTooltipPlacement,
            delay: { show: 400, hide: 0 }
        });
    }

    @Input()
    set appToolTipContent(newValue: string) {
        if (newValue !== this._appToolTipContent) {
            this._appToolTipContent = newValue;
            if (this.tooltipInstance) {
                this.tooltipInstance.updateTitleContent(this.appToolTipContent);
            }
        }
    }

    get appToolTipContent(): string {
        return this._appToolTipContent;
    }

    @Input()
    set appTooltipDisabled(newValue: boolean) {
        if (newValue !== this._appTooltipDisabled) {
            this._appTooltipDisabled = newValue;
            if (this.appTooltipDisabled && this.tooltipInstance) {
                this.tooltipInstance.hide();
            } else if (!this.appTooltipDisabled && !this.tooltipInstance) {
                this.createTooltipInstance();
            }
        }
    }

    get appTooltipDisabled(): boolean {
        return this._appTooltipDisabled;
    }

    ngOnDestroy(): void {
        if (this.tooltipInstance) {
            this.tooltipInstance.dispose();
        }
    }
}
