import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { animationFrameScheduler, fromEvent, merge, Observable, Observer, Subject } from 'rxjs';

import { INL_SCROLL_STRATEGY, InlScrollStrategy } from './default-scroll-strategy';
import { auditTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-scroll-viewport',
    templateUrl: './scroll-viewport.component.html',
    styleUrls: ['./scroll-viewport.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ScrollViewportComponent
implements OnChanges, ScrollViewport, OnInit, AfterViewInit, OnDestroy {
    private _itemCount = 0;

    /** The direction the viewport scrolls. */
    @Input()
    orientation: 'horizontal' | 'vertical' = 'vertical';

    @Input()
    viewportSize: number;

    @Input()
    hideScrollBars: boolean = true;

    @ViewChild('scrollContentContainer', { static: false })
    private readonly scrollContentContainer: ElementRef<HTMLElement>;

    @ViewChild('innerScrollViewport', { static: false })
    private readonly innerScrollViewport: ElementRef<HTMLElement>;

    @HostBinding('class.scroll-viewport')
    scrollViewportClass = true;

    private totalContentSize: number;
    totalContentSizeTransform: string = '';
    private cachedScrollViewportSize: number;

    private beforeInit = true;
    private renderedContentChangeSubject = new Subject<boolean>();
    readonly renderedContentChange: Observable<
        boolean
    > = this.renderedContentChangeSubject.asObservable();

    private onDestroySubject = new Subject<void>();

    private _elementScrolled: Observable<Event> = new Observable((observer: Observer<Event>) =>
        this.ngZone.runOutsideAngular(() =>
            fromEvent(this.innerScrollViewport.nativeElement, 'scroll')
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe(observer)
        )
    );

    @HostBinding('class.scroll-viewport--horizontal')
    private get isHorizontal() {
        return this.orientation === 'horizontal';
    }

    @HostBinding('class.scroll-viewport--vertical')
    private get isVertical(): boolean {
        return this.orientation === 'vertical';
    }

    constructor(
        public readonly elementRef: ElementRef<HTMLElement>,
        @Inject(INL_SCROLL_STRATEGY) private scrollStrategy: InlScrollStrategy,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.scrollStrategy.setScrollViewport(this);
        this.beforeInit = false;
    }

    ngAfterViewInit(): void {
        this.updateViewportSize();
    }

    ngOnDestroy(): void {
        this.onDestroySubject.complete();
    }

    elementScrolled(): Observable<Event> {
        return this._elementScrolled;
    }

    private hideNativeScrollBar() {
        if (this.itemCount < 1) {
            return;
        }

        const sizeProperty = this.orientation === 'vertical' ? 'width' : 'height';
        const scrollViewportSize =
            this.innerScrollViewport.nativeElement.getBoundingClientRect()[sizeProperty] + 17;

        if (scrollViewportSize !== this.cachedScrollViewportSize) {
            this.cachedScrollViewportSize = scrollViewportSize;

            /*
            this.scrollViewportSizeSubject.next({
                [`${sizeProperty}.px`]: scrollViewportSize.toString(),
                [`min-${sizeProperty}.px`]: scrollViewportSize.toString(),
                [`max-${sizeProperty}.px`]: scrollViewportSize.toString(),
            }); */

            // this.elementRef.nativeElement.style[sizeProperty] = (scrollViewportSize - 18) + 'px';
        }
    }

    @Input()
    get itemCount(): number {
        return this._itemCount;
    }

    set itemCount(newItemCount: number) {
        const newCount = Number(newItemCount);
        if (newCount !== this._itemCount) {
            this._itemCount = newCount;
            if (!this.beforeInit) {
                this.scrollStrategy.onItemsCountsChanged();
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.viewportSize) {
            if (this.orientation === 'vertical') {
                this.elementRef.nativeElement.style.maxHeight = this.viewportSize + 'px';
                this.elementRef.nativeElement.style.height = this.viewportSize + 'px';
            } else {
                this.elementRef.nativeElement.style.maxWidth = this.viewportSize + 'px';
                this.elementRef.nativeElement.style.height = this.viewportSize + 'px';
            }
        }
    }

    scrollUp() {
        this.scrollStrategy.scrollUp();
    }

    scrollDown() {
        this.scrollStrategy.scrollDown();
    }

    scrollToTop() {
        this.innerScrollViewport.nativeElement.scrollTop = 0;
    }

    /**
     * Scrolls the viewport to the given offset
     *
     * Offset must be a positive number
     *
     * @param offset
     */
    scrollToOffset(offset: number) {
        const options: ScrollToOptions = {};
        const elem = this.innerScrollViewport.nativeElement;
        if (this.orientation === 'horizontal') {
            // TODO not implemented yet
            const newLeft = elem.scrollLeft + offset;
            const maxScrollLeft = elem.scrollWidth - elem.clientWidth;
            options.left = Math.min(newLeft, maxScrollLeft);
        } else {
            // ensure that negative value are converted to zero
            const newTop = elem.scrollTop + offset;
            const maxScrollTop = elem.scrollHeight - elem.clientHeight;
            // clamp scroll upwards (first max) and downward (second max)
            options.top = Math.max(0, Math.min(newTop, maxScrollTop));
        }

        this.scrollTo(options);
    }

    private scrollTo(options: ScrollToOptions) {
        const elem = this.innerScrollViewport.nativeElement;

        if (options.top != null) {
            elem.scrollTop = options.top;
            this.renderedContentChangeSubject.next(true);
        }

        if (options.left != null) {
            elem.scrollLeft = options.left;
            this.renderedContentChangeSubject.next(true);
        }
    }

    setTotalContentSize(contentSize: number) {
        if (this.totalContentSize !== contentSize) {
            this.totalContentSize = contentSize;
            const axis = this.orientation === 'horizontal' ? 'X' : 'Y';
            this.totalContentSizeTransform = `scale${axis}(${this.totalContentSize})`;
            this.renderedContentChangeSubject.next(true);
        }
    }

    canScrollUp(): boolean {
        const scrollToUp = this.orientation === 'vertical' ? 'top' : 'left';
        return this.canScrollTo(scrollToUp);
    }

    canScrollBottom(): boolean {
        const scrollToDown = this.orientation === 'vertical' ? 'bottom' : 'right';
        return this.canScrollTo(scrollToDown);
    }

    canScrollTo(scrollTo: 'top' | 'bottom' | 'left' | 'right'): boolean {
        // NOSONAR
        const elem = this.innerScrollViewport.nativeElement;
        const scrollTop = elem.scrollTop;
        const clientHeight = elem.clientHeight;
        if (scrollTo === 'top') {
            return elem.scrollTop > 0;
        }
        if (scrollTo === 'bottom') {
            return Math.round(elem.scrollTop) + elem.clientHeight < this.totalContentSize;
        }
        if (scrollTo === 'left') {
            return elem.scrollLeft > 0;
        }
        if (scrollTo === 'right') {
            return Math.round(elem.scrollLeft) + elem.clientWidth < this.totalContentSize;
        }
        return false;
    }

    getScrollOffset(): number {
        const elem = this.innerScrollViewport.nativeElement;
        if (this.orientation === 'horizontal') {
            return elem.scrollLeft;
        } else {
            return elem.scrollTop;
        }
    }

    getViewportLength(): number {
        const elem = this.innerScrollViewport.nativeElement;
        if (this.orientation === 'horizontal') {
            return elem.clientWidth;
        } else {
            return elem.clientHeight;
        }
    }

    updateViewportSize() {
        if (this.hideScrollBars) {
            this.hideNativeScrollBar();
        }
    }
}

export interface ScrollViewport {
    /** The length of the data bound to this viewport (in number of items). */
    itemCount: number;
    renderedContentChange: Observable<boolean>;

    setTotalContentSize(contentSize: number);
    updateViewportSize();

    /* Scroll to the top  or left (depending on the orientation of the ScrollViewport) */
    scrollUp(): void;

    /* Scroll downwards or to the right */
    scrollDown(): void;

    scrollToOffset(offset: number);

    getScrollOffset(): number;

    /**
     * Checks if the viewport can be scroll to the given direction
     * @param scrollTo - direction to be checked
     */
    canScrollTo(scrollTo: 'top' | 'bottom' | 'left' | 'right'): boolean;
}
