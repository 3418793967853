import { MoveUndoableEditAction, UndoSelectionInfo } from './move-undoable-edit-action';
import { UndoableEditaAction } from './undo-manager.service';

export class CompoundMoveUndoableEditAction implements UndoableEditaAction {
    readonly presentationName: string;

    readonly editActions: MoveUndoableEditAction[] = [];

    constructor(presentationName?: string, private selectionInfo?: UndoSelectionInfo) {
        this.presentationName = presentationName || 'Move contours';
    }

    addEditAction(action: MoveUndoableEditAction) {
        this.editActions.push(action);
    }

    redo(): void {
        if (this.selectionInfo) {
            this.selectionInfo.addToSelection(this.selectionInfo.selectedContours);
        }
        this.editActions.forEach(action => {
            action.redo();
        });
    }

    undo(): void {
        if (this.selectionInfo) {
            this.selectionInfo.addToSelection(this.selectionInfo.selectedContours);
        }
        this.editActions.forEach(action => {
            action.undo();
        });
    }
}
