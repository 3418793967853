import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injector,
    Type,
    ViewContainerRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Portal<T> {
    attach(host: PortalSlot, fromHost?: boolean): ComponentRef<T> | void;

    detach(fromHost?: boolean): void;
}

/**
 * A component that can be rendered in a PortalSlot
 *
 */

export class InlComponentPortal<T> implements Portal<T> {
    attachedHost: PortalSlot | null;
    component: Type<T>;
    injector: Injector;
    componentFactoryResolver: ComponentFactoryResolver;

    /** cache component viewRef **/
    componentRef: ComponentRef<T> | null;

    constructor(
        component: Type<T>,
        injector?: Injector | null,
        componentFactoryResolver?: ComponentFactoryResolver | null
    ) {
        this.component = component;
        this.injector = injector;
        this.componentFactoryResolver = componentFactoryResolver;
    }

    /**
     * Attach this portal to a host.
     *
     * @param host
     * @param fromHost
     */
    attach(host: PortalSlot, fromHost?: boolean): ComponentRef<T> | void {
        if (host == null) {
            throw Error('Attempting to attach a portal to a null PortalOutlet');
        }

        if (host.hasAttached()) {
            throw Error('Host already has a portal attached');
        }

        this.attachedHost = host;

        if (!fromHost) {
            return <ComponentRef<T>>host.attach(this);
        }
    }

    /**
     * Detach this portal from its host
     *
     * @param fromHost
     */
    detach(fromHost?: boolean): void {
        if (this.attachedHost == null) {
            throw Error('Attempting to detach a portal that is not attached to a host');
        } else {
            const host = this.attachedHost;
            this.attachedHost = null;
            if (!fromHost) {
                host.detach();
            }
        }
    }

    get isAttached(): boolean {
        return this.attachedHost != null;
    }
}

export abstract class PortalSlot {
    /** The portal currently attached to the host. */
    protected attachedPortal: InlComponentPortal<any> | null;
    private isDisposed: boolean = false;

    protected checkPortalArgs(portal: InlComponentPortal<any>) {
        if (!portal) {
            throw Error('No ComponentPortable provided to attach');
        }

        if (this.attachedPortal) {
            throw Error('Portal is already attached');
        }

        if (this.isDisposed) {
            throw Error('PortalSlot has been already removed');
        }
    }

    abstract attach<T>(portal: InlComponentPortal<T>): ComponentRef<T>;

    detach(): void {
        if (this.attachedPortal) {
            // FIRE _detachments event
            // this._attachedPortal.component
            this.attachedPortal.detach(true);
            this.attachedPortal = null;
        }
    }

    hasAttached(): boolean {
        return !!this.attachedPortal;
    }

    dispose(): void {
        if (this.attachedPortal) {
            this.detach();
        }

        this.isDisposed = true;
    }
}

export class ViewContainerPortalSlot extends PortalSlot {
    private slotElement: ViewContainerRef;
    private componentPortalRef: ComponentRef<any>;
    private detachments = new Subject<void>();
    readonly detached: Observable<void> = this.detachments.asObservable();
    constructor(
        slotElement: ViewContainerRef,
        private appRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector
    ) {
        super();
        this.slotElement = slotElement;
    }

    attach<T>(portal: InlComponentPortal<T>): ComponentRef<T> {
        super.checkPortalArgs(portal);
        return this.attachComponentPortal(portal);
    }

    private attachComponentPortal<T>(portal: InlComponentPortal<T>): ComponentRef<T> {
        const resolver = portal.componentFactoryResolver || this.componentFactoryResolver;
        const componentFactory = resolver.resolveComponentFactory(portal.component);

        this.slotElement.clear();

        this.componentPortalRef = this.slotElement.createComponent(
            componentFactory,
            this.slotElement.length,
            portal.injector || this.slotElement.injector
        );
        return this.componentPortalRef;
    }

    /**  Hides attached portal **/
    detach(): void {
        super.detach();
        if (this.componentPortalRef) {
            this.componentPortalRef.destroy();
        }
        this.detachments.next();
        // FIRE _detachments event
    }

    dispose(): void {
        super.dispose();
        if (this.componentPortalRef) {
            this.componentPortalRef.destroy();
        }
        this.detachments.complete();
    }
}

/**
 * The DOM placeholder where a PortalComponent can be inserted to.
 */
export class DomPortalSlot extends PortalSlot {
    /** Element into which the content is projected. */
    public slotElement: Element;
    private componentPortalRef: ComponentRef<any>;
    private detachments = new Subject<void>();
    /** Emits when this slot has been detached **/
    readonly detached: Observable<void> = this.detachments.asObservable();

    constructor(
        slotElement: Element,
        private appRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector
    ) {
        super();
        this.slotElement = slotElement;
    }

    attach<T>(portal: InlComponentPortal<T>): ComponentRef<T> {
        this.checkPortalArgs(portal);
        this.attachedPortal = portal;
        return this.attachComponentPortal(portal);
    }

    /**  Hides attached portal **/
    detach(): void {
        super.detach();
        this.disposeAttachedComponent();
        this.detachments.next();
        // FIRE _detachments event
        // this._attachedPortal.component
    }

    private attachComponentPortal<T>(portal: InlComponentPortal<T>): ComponentRef<T> {
        const resolver = portal.componentFactoryResolver || this.componentFactoryResolver;
        const componentFactory = resolver.resolveComponentFactory(portal.component);

        const componentRef = componentFactory.create(portal.injector || this.injector);
        this.appRef.attachView(componentRef.hostView);
        const componentRootElement = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        this.componentPortalRef = componentRef;

        portal.attachedHost = this;

        this.slotElement.appendChild(componentRootElement);
        return componentRef;
    }

    /**
     * Removes this portal permanently from the DOM  (before it is destroyed).
     */
    dispose(): void {
        super.dispose();
        this.disposeAttachedComponent();

        if (this.slotElement.parentNode != null) {
            this.slotElement.parentNode.removeChild(this.slotElement);
        }

        this.detachments.complete();
    }

    private disposeAttachedComponent() {
        if (this.componentPortalRef) {
            this.appRef.detachView(this.componentPortalRef.hostView);
            this.componentPortalRef.destroy();
        }
    }
}
