import { Action } from './action';
import { FoamEditorService } from '../foam-editor.service';
import { DeleteUndoableEditAction } from '../undo/delete-undoable-edit-action';

/**
 * Action that deletes selected contour
 */
export class DeleteAction implements Action {
    constructor(private readonly foamEditorService: FoamEditorService) {}

    execute(event?: KeyboardEvent) {
        const canvasService = this.foamEditorService.getCanvasService();
        const contoursToRemove = canvasService.getSelectedContourItems();

        if (contoursToRemove.length > 0) {
            canvasService.removeContours(contoursToRemove);
            this.foamEditorService
                .getCanvasService()
                .getUndoManagerService()
                .addEditAction(new DeleteUndoableEditAction(contoursToRemove));
        }
    }
}
