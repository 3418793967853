import { Type } from '@angular/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class RibbonSelectionService {
    /*
    @Output()
    ribbonClosed: EventEmitter<boolean> = new EventEmitter<boolean>(true);
*/
    constructor() {
        this.selectedTabSubject = new Subject();
        this.onTabRequest = this.selectedTabSubject.asObservable();
        this.dialogChangeSubject = new Subject();
        this.onDialogChange = this.dialogChangeSubject.asObservable();
        this.ribbonTabSelectedSubject = new Subject();
        this.ongRibbonTabSelected = this.ribbonTabSelectedSubject.asObservable();
    }
    getCurrentTab() {
        return this.ribbonComponent.currentPanel;
    }
    updateSelectedRibbonTab(item) {
        if (item) {
            this.ribbonTabSelectedSubject.next(item);
        }
    }
    set openedRibbonDialog(value) {
        if (value !== this._openedRibbonDialog) {
            this._openedRibbonDialog = value;
            this.dialogChangeSubject.next();
        }
    }
    get openedRibbonDialog() {
        return this._openedRibbonDialog;
    }
    selectTab(tabIndex) {
        this.selectedTabSubject.next(tabIndex);
    }
    openRibbonDialog(ribbonDialog) {
        if (!this.ribbonComponent) {
            console.error('RibbonComponent is undefined');
            return;
        }
        this.ribbonComponent.panelContentComponent.loadRibbonDialog(ribbonDialog);
    }
    closeRibbonDialog() {
        if (!this.ribbonComponent) {
            console.error('RibbonComponent is undefined');
            return;
        }
        this.ribbonComponent.panelContentComponent.closeRibbonDialog();
    }
}
RibbonSelectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RibbonSelectionService_Factory() { return new RibbonSelectionService(); }, token: RibbonSelectionService, providedIn: "root" });
