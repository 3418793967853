import { FoamConfigurationModel } from './models/foam-configuration.model';
import { ContourJSON, FoamConfigurationJSON, ModelMapper } from './models/model.mapper';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BackendService } from './backend/backend.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';

import {
    ImageContourModel,
    ImageContourType,
    ProducerImageContourModel
} from './models/image-contour.model';
import { StartParameterModel } from './models/start-parameter.model';

/**
 * This class stores all image contours available to the user.
 * All UI components should only interact with this class to fetch contours and
 * never use the server API directly (e.g. {@class BackendService}
 */
@Injectable({ providedIn: 'root' })
export class ConfiguratorStoreService {
    private readonly userContourItems = new BehaviorSubject<ImageContourModel[]>([]);
    private readonly producerContourItems = new BehaviorSubject<ProducerImageContourModel[]>([]);

    initialDataLoaded = new ReplaySubject<boolean>(1);
    private startParameters: StartParameterModel;
    private imageContours: ImageContourModel[];
    private languageSettings: LanguageSettings;
    private price: string;
    private vat: string;
    private sessionRef: string;
    public foamConfig: FoamConfigurationModel;
    private teilVersenkung = false;

    private filterProducerName = new BehaviorSubject<string[]>([]);
    private filterToolTypeName = new BehaviorSubject<string[]>([]);

    constructor(private backendService: BackendService) {}

    loadFoamConfiguration(sessionRef: string) {
        this.sessionRef = sessionRef;
        const startParamsRequest = this.backendService.getStartParameters(sessionRef);

        // requests need to be chained! because "getStartparameters"-Response sets the correct cookie value!
        startParamsRequest.subscribe(startParam => {
            this.startParameters = startParam;
            this.price = this.startParameters.price;
            this.vat = this.startParameters.vat;
            this.languageSettings = {
                languageKey: this.startParameters.languageKey,
                languageContent: this.startParameters.languageContent
            };
            this.foamConfig = this.startParameters.foamConfig;
            this.teilVersenkung = this.startParameters.foamConfig.foamContour.teilVersenkung;

            const filterOptionRequest = this.backendService.getAllToolFilter();
            filterOptionRequest.subscribe(data => {
                this.filterProducerName.next(data.producerName);
                this.filterToolTypeName.next(data.toolTypeName);
            });
            const toolsRequest = this.backendService.getAllTool();

            toolsRequest.subscribe(allTool => {
                this.imageContours = allTool;
                const userTools = this.imageContours.filter(
                    item => item.imageContourType === ImageContourType.USER_TOOL
                );
                const producerTools = this.imageContours.filter(
                    item => item.imageContourType === ImageContourType.PRODUCER_TOOL
                );

                this.initialDataLoaded.next(true);
                // Order matter here: for avoiding tools browser components to receive tools
                // and call foamService/canvasService methods (e.g. canvasService#isContourDepthValid)
                // is before these services were fully initialized
                this.userContourItems.next(userTools);
                this.producerContourItems.next(producerTools);
            });
        });
    }

    public isTeilversenkungActive(): boolean {
        return this.teilVersenkung;
    }

    public addNewUserTool(imageContourModel: ImageContourModel) {
        const value: ImageContourModel[] = this.userContourItems.value;
        value.push(imageContourModel);
        this.userContourItems.next(value);
    }

    getLanguageSettings(): LanguageSettings {
        return this.languageSettings;
    }

    getFoamConfiguration() {
        return this.foamConfig;
    }

    getPrice(): string {
        return this.price;
    }

    getVat(): string {
        return this.vat;
    }

    getToolFilterToolTypeName(): Observable<string[]> {
        return this.filterToolTypeName.asObservable();
    }

    getToolFilterProducerName(): Observable<string[]> {
        return this.filterProducerName.asObservable();
    }

    getUserItems(): Observable<ImageContourModel[]> {
        return this.userContourItems.asObservable();
    }

    getPartnerItems(): Observable<ProducerImageContourModel[]> {
        return this.producerContourItems.asObservable();
    }

    getSessionRef(): string {
        return this.sessionRef;
    }

    getImageContourById(id: string): Readonly<ImageContourModel> | undefined {
        return this.imageContours.find((c: ImageContourModel) => c.id === id);
    }

    edit(contour: ImageContourModel): Observable<any> {
        const contourForSave: ImageContourModel = BackendService.deleteImageAttributes(
            new ImageContourModel(contour)
        );
        return this.backendService
            .saveUserTool(ModelMapper.imageContourModelToJSON(contourForSave))
            .pipe(tap(this.updateImageContour));
    }

    private updateImageContour = (updatedContour: ImageContourModel) => {
        const contourIndex = this.userContourItems
            .getValue()
            .findIndex(value => value.id === updatedContour.id);
        if (contourIndex !== -1) {
            const allImage = this.userContourItems.getValue();
            allImage[contourIndex] = updatedContour;
            const userTools = allImage.filter(
                item => item.imageContourType === ImageContourType.USER_TOOL
            );
            this.userContourItems.next(userTools);
        }
    };

    delete(contour: ImageContourModel): Observable<boolean> {
        if (!contour.isRemovable) {
            return EMPTY;
        }

        return this.backendService.deleteTool(contour).pipe(
            map(result => {
                if (result === true) {
                    let index = -1;
                    this.userContourItems.getValue().forEach((value, innerIndex) => {
                        if (value.id != null && value.id === contour.id) {
                            index = innerIndex;
                        }
                    });
                    if (index >= 0) {
                        const newList: ImageContourModel[] = this.userContourItems.getValue();
                        newList.splice(index, 1);
                        this.userContourItems.next(newList);
                        return true;
                    }
                }
                return false;
            })
        );
    }

    saveFoamConfig(contours: ContourJSON[]): Observable<string> {
        return this.backendService.editAllContourForCanvas(contours).pipe(
            map(allUpdatedContour => {
                return ModelMapper.foamConfigurationToJSON(
                    this.foamConfig.id,
                    this.foamConfig.configurationName,
                    this.foamConfig.foamContour,
                    allUpdatedContour
                );
            }),
            mergeMap((jsonData: FoamConfigurationJSON) => {
                return this.backendService.saveFoamConfig(jsonData);
            })
        );
    }

    /**
     * Marks the specified user contours as not removable, while marking other contours as removable
     *
     * @param contoursIds
     */
    updateUserContoursRemovability(contoursIds: string[]) {
        const userTools = this.userContourItems.getValue().map((contour: ImageContourModel) => {
            contour.isRemovable = contoursIds.findIndex(c => c === contour.id) === -1;
            return contour;
        });
        this.userContourItems.next(userTools);
    }
}

export interface LanguageSettings {
    languageKey: string;
    languageContent: Object;
}
