import { Component, OnInit } from '@angular/core';
import { ShowContourImageAction } from '../actions/show-contour-image.action';
import { Observable } from 'rxjs';
import { CanvasService } from '../canvas/canvas.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-display-options-panel',
    templateUrl: './display-options-panel.component.html',
    styleUrls: ['./display-options-panel.component.scss'],
    providers: [ShowContourImageAction]
})
export class DisplayOptionsPanelComponent implements OnInit {
    isShowContourImageEnabled: Observable<boolean>;

    constructor(
        private canvasService: CanvasService,
        private showContourImageAction: ShowContourImageAction
    ) {}

    ngOnInit() {
        this.isShowContourImageEnabled = this.canvasService
            .getProperties()
            .pipe(map(props => props.showContourImage));
    }

    toggleShowContourImage(isChecked: boolean) {
        this.showContourImageAction.execute({
            showContourImage: isChecked
        });
    }
}
