/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./display-options-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/toggle-switch/toggle-switch.component.ngfactory";
import * as i3 from "../../shared/toggle-switch/toggle-switch.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../actions/show-contour-image.action";
import * as i7 from "../canvas/canvas.service";
import * as i8 from "./display-options-panel.component";
var styles_DisplayOptionsPanelComponent = [i0.styles];
var RenderType_DisplayOptionsPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DisplayOptionsPanelComponent, data: {} });
export { RenderType_DisplayOptionsPanelComponent as RenderType_DisplayOptionsPanelComponent };
export function View_DisplayOptionsPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "display-functions-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "display-functions-panel__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "display-functions-panel__toggle-switch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "app-toggle-switch", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.toggleShowContourImage($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToggleSwitchComponent_0, i2.RenderType_ToggleSwitchComponent)), i1.ɵdid(4, 114688, null, 0, i3.ToggleSwitchComponent, [i1.ElementRef], { inputId: [0, "inputId"], isDisabled: [1, "isDisabled"], checked: [2, "checked"], labelWidth: [3, "labelWidth"], labelHeight: [4, "labelHeight"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(6, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tool-images__switch"; var currVal_1 = false; var currVal_2 = i1.ɵunv(_v, 4, 2, i1.ɵnov(_v, 5).transform(_co.isShowContourImageEnabled)); var currVal_3 = "204px"; var currVal_4 = "24px"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("ON_OFF_SLIDER.TOOL_PICTURES")); _ck(_v, 6, 0, currVal_5); }); }
export function View_DisplayOptionsPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-display-options-panel", [], null, null, null, View_DisplayOptionsPanelComponent_0, RenderType_DisplayOptionsPanelComponent)), i1.ɵprd(512, null, i6.ShowContourImageAction, i6.ShowContourImageAction, [i7.CanvasService]), i1.ɵdid(2, 114688, null, 0, i8.DisplayOptionsPanelComponent, [i7.CanvasService, i6.ShowContourImageAction], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DisplayOptionsPanelComponentNgFactory = i1.ɵccf("app-display-options-panel", i8.DisplayOptionsPanelComponent, View_DisplayOptionsPanelComponent_Host_0, {}, {}, []);
export { DisplayOptionsPanelComponentNgFactory as DisplayOptionsPanelComponentNgFactory };
