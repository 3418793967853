import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { point2DToPathString } from '../../../../../canvas/contour/contour-helper';
import { NS } from '../../../../../../namespaces';
import { PaintSelectionModel } from '../paint-selection/paint-selection-model';
import {
    CanvasBoundsChange,
    CanvasBoundsManager,
    ZoomAndScrollableCanvas
} from '../../../../../canvas/shared/canvas-bounds.manager';
import { CanvasScrollViewportDirective } from '../../../../../canvas/shared/canvas-scroll-viewport.directive';
import { Rectangle2D } from '../../../../../shared/geom/rectangle2D';
import { Observable, Subject } from 'rxjs';
import { BreakpointObserverService } from '../../../../../../shared/layout/breakpoint-observer.service';
import { takeUntil } from 'rxjs/operators';
import { Breakpoints } from '../../../../../../shared/layout/breakpoints';
import { ScaleFactorState } from '../../../../../canvas/shared/scalefactor-state';
import { PanHandler } from '../paint-selection/pan-handler';
import { drawBorder, PaintSelection, SelectionMode } from '../paint-selection/paint-selection';
import { Point2D } from '../../../../../shared/geom/point2D';
import { UploadCanvasBoundsChangeHandler } from './upload-canvas-bounds-change-handler';
import { Dimension2D } from '../../../../../shared/geom/dimension2d';

export enum EditMode {
    ADD,
    REMOVE,
    MOVE
}

declare var Snap: any;

@Component({
    selector: 'app-detection-result-canvas',
    templateUrl: './detection-result-canvas.component.html',
    styleUrls: ['./detection-result-canvas.component.scss'],
    providers: [PaintSelection],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetectionResultCanvasComponent
implements ZoomAndScrollableCanvas, OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input()
    backgroundImage: BackgroundImageInfo;

    @Input()
    segmentationResult: Observable<ContourSegmentationResult>;

    /* Async EventEmitter as workaround for ExpressionChangedAfterItHasBeenCheckedError */
    @Output()
    scaleFactorChanged = new EventEmitter<ScaleFactorState>(true);

    @Output() readonly regionsSelected = new EventEmitter<RegionSelection>();

    @ViewChild('layerContours', { static: false })
    private layerContours: ElementRef<SVGGElement>;

    // TODO use { static: true } when updating to Angular 8
    @ViewChild('paintCanvas', { static: false })
    private paintCanvas: ElementRef<HTMLCanvasElement>;

    @ViewChild('brushCanvas', { static: false })
    private brushCanvas: ElementRef<HTMLCanvasElement>;

    // TODO remove it
    /* for debug only */
    @ViewChild('borderCanvas', { static: false })
    private borderCanvas: ElementRef<HTMLCanvasElement>;

    @ViewChild('layerItems', { static: false })
    layerItems: ElementRef<SVGGElement>;

    @Input()
    maxWidth: number = 854;

    @Input()
    maxHeight: number = 653;

    @Input()
    selectionRadius: number = 15;

    @ViewChild('canvasContent', { static: false })
    readonly canvasContent: ElementRef<SVGSVGElement>;

    @ViewChild('canvasBackground', { static: false })
    private canvasBackground: ElementRef<SVGGElement>;

    @ViewChild(CanvasScrollViewportDirective, { static: false })
    readonly scrollViewport: CanvasScrollViewportDirective;

    private isDrawing: boolean;
    private mouseDownPoint: { x: number; y: number };
    // rename to contourBordersCanvasContext
    private paintCanvasContext: CanvasRenderingContext2D;
    private brushCanvasContext: CanvasRenderingContext2D;
    private borderDebugCanvasContext: CanvasRenderingContext2D;

    private isInEditMode: boolean;

    private paintSelectionModel = new PaintSelectionModel();
    private canvasBoundsChangeHandler;
    private canvasBoundsManager: CanvasBoundsManager;

    readonly backgroundCenterPadding: [number, number, number, number] = [1, 1, 1, 1];

    private destroySubject = new Subject<void>();
    private panHandler: PanHandler;

    private _editMode: EditMode;
    private oldScaleFactor: number;

    // FIXME for debugging only
    private showDebugBorders = false;
    private backgroundImageData: ImageData;
    private brushPaintImageData: ImageData;
    private invalidateBrushPaintImageData: boolean = true;
    private cachedTargetBBox: ClientRect | DOMRect;

    private selectionMouseDownListener = (event: MouseEvent) => {
        requestAnimationFrame(() => {
            this.selectionMouseDown(event);
        });
    };

    private selectionMouseMoveListener = (event: MouseEvent) => {
        this._ngZone.runOutsideAngular(() => {
            requestAnimationFrame(() => {
                this.selectionMouseMove(event);
            });
        });
    };

    private selectionMouseUpListener = (event: MouseEvent) => this.selectionMouseUp(event);

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private paintSelection: PaintSelection,
        private breakpointObserver: BreakpointObserverService,
        private changeDetector: ChangeDetectorRef,
        private _ngZone: NgZone
    ) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        this.scrollViewport.pannableNode = this.layerItems.nativeElement;
        this.canvasBoundsManager = new CanvasBoundsManager(this, this._ngZone);
        this.canvasBoundsManager.canvasBoundsChange.subscribe(change =>
            this.handleCanvasBoundsChange(change)
        );

        this.panHandler = new PanHandler(
            this.canvasContent.nativeElement,
            this.layerItems.nativeElement,
            this.scrollViewport
        );

        this.canvasBoundsChangeHandler = new UploadCanvasBoundsChangeHandler(this.scrollViewport);

        this.panHandler.getOnNodeTranslated().subscribe((tx: SVGMatrix) => {
            //  const scalableContentSize = this.layerItems.nativeElement.getBoundingClientRect();

            const txString =
                `matrix(${tx.a.toFixed(2)},${tx.b.toFixed(2)},${tx.c.toFixed(2)},` +
                `${tx.d.toFixed(2)},${tx.e.toFixed(2)},${tx.f.toFixed(2)})`;
            this.borderCanvas.nativeElement.style.transform = txString;
            this.paintCanvas.nativeElement.style.transform = txString;
            this.brushCanvas.nativeElement.style.transform = txString;

            /* this.borderDebugCanvasContext.translate(tx.e, tx.f);
            this.drawAllBorders(this.paintSelectionModel.borderIndices,
                this.backgroundImage.width, this.backgroundImage.height, this.borderDebugCanvasContext); */
        });

        this.watchBreakpoints();
        this.maxWidth = this.elementRef.nativeElement.clientWidth;

        this.paintCanvasContext = this.paintCanvas.nativeElement.getContext('2d');
        this.brushCanvasContext = this.brushCanvas.nativeElement.getContext('2d');
        this.borderDebugCanvasContext = this.borderCanvas.nativeElement.getContext('2d');

        this.borderCanvas.nativeElement.style.visibility = 'hidden';
        this.paintCanvas.nativeElement.style.visibility = 'hidden';
        this.brushCanvas.nativeElement.style.visibility = 'hidden';

        // Note: calling updatePanHandler() in editMode is not enough because
        // any calls before AfterViewInit will have no effect as
        // the panHandler is null when the editMode property is initialized
        this.updatePanHandler();

        this._ngZone.runOutsideAngular(() => {
            this.paintCanvas.nativeElement.addEventListener(
                'mousedown',
                this.selectionMouseDownListener
            );
            this.paintCanvas.nativeElement.addEventListener(
                'mousemove',
                this.selectionMouseMoveListener
            );
            this.paintCanvas.nativeElement.addEventListener(
                'mouseup',
                this.selectionMouseUpListener
            );
        });

        this.updatePaintCanvasVisibility();

        this.segmentationResult.subscribe(segmentationResult =>
            this.handleContourDetectionResultChanges(segmentationResult)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        // update background image
        if (changes.backgroundImage && this.backgroundImage) {
            // this.hideSVGContours();
            if (this.paintCanvas) {
                this.paintCanvas.nativeElement.width = this.backgroundImage.width;
                this.paintCanvas.nativeElement.height = this.backgroundImage.height;

                this.borderCanvas.nativeElement.width = this.backgroundImage.width;
                this.borderCanvas.nativeElement.height = this.backgroundImage.height;
            }
            this.changeDetector.detectChanges();
            if (this.canvasBoundsManager) {
                this.canvasBoundsManager.scaleToFitViewport();
            }
            // HACK: see line 208
            this.isDrawing = false;

            if (!this.paintCanvasContext) {
                this.paintCanvasContext = this.paintCanvas.nativeElement.getContext('2d');
            }

            this.canvasBackground.nativeElement.querySelector('image').onload = () => {
                this.paintCanvasContext.drawImage(
                    this.canvasBackground.nativeElement.querySelector('image'),
                    0,
                    0
                );
                this.backgroundImageData = this.paintCanvasContext.getImageData(
                    0,
                    0,
                    this.backgroundImage.width,
                    this.backgroundImage.height
                );

                this.paintSelection.setImageData(
                    this.backgroundImageData.data,
                    this.backgroundImage.width,
                    this.backgroundImage.height,
                    4
                );

                // clear the paintCanvas otherwise it will occlude the svg path
                // that should be visible when the edit mode is entered for the first time
                this.paintCanvasContext.clearRect(
                    0,
                    0,
                    this.backgroundImage.width,
                    this.backgroundImage.height
                );
            };
        }
    }

    private handleContourDetectionResultChanges(result: ContourSegmentationResult) {
        // clear svg contours if the segmentationResult is explicitly set to null
        // this.clearSGVContours();
        // HACK: Prevent border drawing if the mouseUp (which set isDrawing to false ) was not call properly.
        // This may happen if the debugger breaks at the mouseDown handler
        this.isDrawing = false;

        if (result != null) {
            this.clearSGVContours();
            this.showSVGContours();
            this.drawSVGContours(result.contours);

            this.paintSelection.setContours(
                result,
                this.backgroundImage.width,
                this.backgroundImage.height
            );
            if (this.showDebugBorders) {
                this.drawAllBorders(
                    result.borderIndices[0],
                    this.backgroundImage.width,
                    this.backgroundImage.height,
                    this.borderDebugCanvasContext
                );
            }
        }
    }

    getCanvasBackgroundLocalBounds(): Rectangle2D | undefined {
        if (this.canvasBackground) {
            if (this.backgroundImage == null) {
                return new Rectangle2D(0, 0, this.maxWidth, this.maxHeight);
            }
            return new Rectangle2D(0, 0, this.backgroundImage.width, this.backgroundImage.height);
        }
        return undefined;
    }

    private handleCanvasBoundsChange(change: CanvasBoundsChange) {
        const dim = this.backgroundImage
            ? new Dimension2D(this.backgroundImage.width, this.backgroundImage.height)
            : new Dimension2D(this.maxWidth, this.maxHeight);

        this.canvasBoundsChangeHandler.handleBoundsChange(
            change,
            this.elementRef.nativeElement,
            this.oldScaleFactor,
            dim,
            {
                layerItems: this.layerItems.nativeElement,
                others: [
                    this.paintCanvas.nativeElement,
                    this.brushCanvas.nativeElement,
                    this.borderCanvas.nativeElement
                ]
            }
        );
        if (change.scaleFactor) {
            this.scaleFactorChanged.emit({
                scaleFactor: change.scaleFactor,
                scaleFactorToFitViewport: this.canvasBoundsManager.scaleFactorToFitViewport
            });
        }
    }

    zoom(zoomStep: number) {
        this.oldScaleFactor = this.canvasBoundsManager.getScaleFactor();
        const newScaleFactor = Number((this.oldScaleFactor + zoomStep / 100).toFixed(2));
        this.canvasBoundsManager.setScaleFactor(newScaleFactor);
    }

    private watchBreakpoints() {
        // see css class .dialog-content__inner-container
        this.breakpointObserver
            .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
            .pipe(takeUntil(this.destroySubject))
            .subscribe(() => {
                if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
                    this.maxHeight = 550;
                    this.maxWidth = this.elementRef.nativeElement.clientWidth;
                    this.canvasBoundsManager.setViewport(
                        new Rectangle2D(0, 0, this.maxWidth, this.maxHeight),
                        true,
                        true
                    );
                } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
                    this.maxWidth = this.elementRef.nativeElement.clientWidth;
                    this.maxHeight = 598; // +18
                    this.canvasBoundsManager.setViewport(
                        new Rectangle2D(0, 0, this.maxWidth, this.maxHeight),
                        true,
                        true
                    );
                } else if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
                    this.maxWidth = this.elementRef.nativeElement.clientWidth;
                    this.maxHeight = 676;
                    this.canvasBoundsManager.setViewport(
                        new Rectangle2D(0, 0, this.maxWidth, this.maxHeight),
                        true,
                        true
                    );
                }
            });
    }

    @Input()
    set editMode(value: EditMode) {
        if (value != null && value !== this._editMode) {
            this._editMode = value;

            this.isInEditMode = this.editMode === EditMode.ADD || this.editMode === EditMode.REMOVE;
            this.updatePanHandler();
            this.updatePaintCanvasVisibility();
            if (this.isInEditMode && this.backgroundImage) {
                // TODO use smaller bound
                this.paintCanvasContext.clearRect(
                    0,
                    0,
                    this.backgroundImage.width,
                    this.backgroundImage.height
                );
            }
        }
    }

    private updatePaintCanvasVisibility() {
        if (this.paintCanvas) {
            if (this.isInEditMode) {
                this.paintCanvas.nativeElement.style.visibility = 'visible';
                this.brushCanvas.nativeElement.style.visibility = 'visible';
                if (this.showDebugBorders) {
                    this.borderCanvas.nativeElement.style.visibility = 'visible';
                }
            } else {
                this.paintCanvas.nativeElement.style.visibility = 'hidden';
                this.brushCanvas.nativeElement.style.visibility = 'hidden';
                if (this.showDebugBorders) {
                    this.borderCanvas.nativeElement.style.visibility = 'hidden';
                }
            }
        }
    }

    get editMode(): EditMode {
        return this._editMode;
    }

    private updatePanHandler() {
        if (!this.panHandler) {
            return;
        }
        if (this.editMode === EditMode.MOVE) {
            this.panHandler.start();
        } else {
            this.panHandler.stop();
        }
    }

    private clearSGVContours() {
        if (this.layerContours) {
            const layerElem = this.layerContours.nativeElement;
            while (layerElem.firstChild) {
                layerElem.removeChild(layerElem.firstChild);
            }
        }
    }

    private drawSVGContours(contours: { x: number; y: number }[][]) {
        if (this.layerContours == null || !contours) {
            return;
        }

        const layerElem = this.layerContours.nativeElement;
        const len = contours.length;
        for (let i = 0; i < len; i++) {
            const dAttr = point2DToPathString(contours[i]);
            if (!dAttr) {
                continue;
            }

            const contourSvgPathWhite = document.createElementNS(NS.SVG, 'path');
            contourSvgPathWhite.setAttribute('stroke-width', '2');
            contourSvgPathWhite.setAttribute('stroke', 'white');
            contourSvgPathWhite.setAttribute('fill', 'none');
            contourSvgPathWhite.setAttribute('d', dAttr);

            const contourSvgPathBlack = document.createElementNS(NS.SVG, 'path');
            contourSvgPathBlack.setAttribute('stroke-width', '2');
            contourSvgPathBlack.setAttribute('stroke', 'black');
            contourSvgPathBlack.setAttribute('stroke-dasharray', '10');
            contourSvgPathBlack.setAttribute('fill', 'none');
            contourSvgPathBlack.setAttribute('d', dAttr);

            layerElem.appendChild(contourSvgPathWhite);
            layerElem.appendChild(contourSvgPathBlack);
        }
    }

    private geSelectionMode(): SelectionMode {
        if (this.editMode === EditMode.ADD) {
            return SelectionMode.ADD;
        } else if (this.editMode === EditMode.REMOVE) {
            return SelectionMode.REMOVE;
        }
        return undefined;
    }

    private drawBrush(selectedPixels: number[], width: number): void {
        const len = selectedPixels.length;
        this.brushCanvasContext.fillStyle = 'rgba(6,105,178,0.39)';

        for (let i = 0; i < len; i++) {
            const pixelIndex = selectedPixels[i];
            const x = pixelIndex % width; // calc x by index
            const y = (pixelIndex - x) / width; // calc y by index
            this.brushCanvasContext.fillRect(x, y, 1, 1);
        }
    }

    private selectionMouseDown(event: MouseEvent) {
        if (this.isInEditMode && event.button === 0) {
            this.isDrawing = true;
            this.hideSVGContours();

            this.paintSelectionModel.currentLevel = 0;

            // debug only
            if (this.showDebugBorders) {
                this.drawAllBorders(
                    this.paintSelectionModel.borderIndices[this.paintSelectionModel.currentLevel],
                    this.backgroundImage.width,
                    this.backgroundImage.height,
                    this.borderDebugCanvasContext
                );
            }

            const mousePt = this.getMousePosition(event, true);
            const selectionMode = this.geSelectionMode();
            const selectionResult = this.paintSelection.startSelection(
                mousePt,
                selectionMode,
                this.selectionRadius
            );
            const width = this.backgroundImage.width;
            const height = this.backgroundImage.height;
            if (selectionResult.paintIndices.length > 0) {
                this.drawBrush(selectionResult.newPaintIndices, width);
            }
            if (selectionResult.borderIndices != null && selectionResult.borderIndices.length > 0) {
                drawBorder(selectionResult.borderIndices, width, height, this.paintCanvasContext);
            }
        } else {
            this.isDrawing = false;
        }
    }

    private selectionMouseMove(event: MouseEvent) {
        // event.stopPropagation();
        if (this.isInEditMode && this.isDrawing) {
            const mousePt = this.getMousePosition(event);
            const selectionMode = this.geSelectionMode();
            const selectionResult = this.paintSelection.updateSelection(
                mousePt,
                selectionMode,
                this.selectionRadius
            );

            const width = this.backgroundImage.width;
            const height = this.backgroundImage.height;
            if (selectionResult.newPaintIndices.length > 0) {
                this.drawBrush(selectionResult.newPaintIndices, width);
            }
            if (selectionResult.borderIndices != null) {
                this.paintCanvasContext.clearRect(
                    selectionResult.bounds.minX,
                    selectionResult.bounds.minY,
                    selectionResult.bounds.width,
                    selectionResult.bounds.height
                );
                drawBorder(selectionResult.borderIndices, width, height, this.paintCanvasContext);
            }
        }
    }

    private selectionMouseUp(event: MouseEvent) {
        this.isDrawing = false;
        this.invalidateBrushPaintImageData = true;
        if (!this.isInEditMode) {
            return;
        }
        const selectedRegions = this.paintSelection.endSelection(this.geSelectionMode());

        if (selectedRegions.compoundBounds) {
            this.paintCanvasContext.clearRect(
                selectedRegions.compoundBounds.minX,
                selectedRegions.compoundBounds.minY,
                selectedRegions.compoundBounds.width,
                selectedRegions.compoundBounds.height
            );
            this.brushCanvasContext.clearRect(
                selectedRegions.compoundBounds.minX,
                selectedRegions.compoundBounds.minY,
                selectedRegions.compoundBounds.width,
                selectedRegions.compoundBounds.height
            );
        }

        if (selectedRegions.allSelectedRegions.length === 0) {
            this.showSVGContours();
        } else {
            // TODO REMOVE
            // const selectedRegions = Array.from(this.paintSelectionModel.selectedRegions);
            this.regionsSelected.next({
                regions: selectedRegions.allSelectedRegions,
                level: this.paintSelectionModel.currentLevel,
                selectionMode: this.geSelectionMode()
            });
        }
    }

    /*
    private hatchTick() {
        if (this.cachedBorderIndices.length > 0) {
            this.hatchOffset = (this.hatchOffset + 1) % (this.hatchLength * 2);
            this.drawBorder(this.cachedBorderIndices, this.backgroundImage.width,
                this.backgroundImage.height, this.paintCanvasContext);
        }
    } */

    // for debugging
    private drawAllBorders(
        borderIndices: number[][],
        width: number,
        height: number,
        ctx: CanvasRenderingContext2D
    ) {
        if (!borderIndices) {
            return;
        }

        /*
        const offScreenCanvas: HTMLCanvasElement = document.createElement('canvas');
        offScreenCanvas.width = width;
        offScreenCanvas.height = height;
        const offScreenContext = offScreenCanvas.getContext('2d'); */

        const imgData = ctx.createImageData(width, height);
        ctx.clearRect(0, 0, width, height);

        const res = imgData.data;
        // taken from http://jsfiddle.net/zmdga17w/1/ (https://github.com/Tamersoul/magic-wand-js)
        const blen = borderIndices.length;
        for (let b = 0; b < blen; b++) {
            const borders = borderIndices[b];
            const len = borders.length;
            for (let j = 0; j < len; j++) {
                const i = borders[j];
                const x = i % width; // calc x by index
                const y = (i - x) / width; // calc y by index

                const k = (y * width + x) * 4;

                res[k] = 255; // red
                res[k + 1] = 0;
                res[k + 2] = 0;
                res[k + 3] = 100;
            }
        }

        ctx.putImageData(imgData, 0, 0);

        // const scaleFactor = this.canvasBoundsManager.getScaleFactor();
        // ctx.scale(scaleFactor, scaleFactor);
        // TODO pass the current canvasSize as parameter
        /* const scalableContentSize = this.layerItems.nativeElement.getBoundingClientRect();
        ctx.clearRect(0, 0, scalableContentSize.width, scalableContentSize.height);
        ctx.drawImage(offScreenCanvas, 0, 0); */
    }

    hideSVGContours() {
        if (this.layerContours) {
            this.layerContours.nativeElement.style.visibility = 'hidden';
        }
    }

    showSVGContours() {
        if (this.layerContours) {
            this.layerContours.nativeElement.style.visibility = 'visible';
        }
    }

    private getMousePosition(
        event: MouseEvent,
        invalidBBox = false,
        scale: boolean = true
    ): Point2D {
        if (this.cachedTargetBBox == null || invalidBBox) {
            this.cachedTargetBBox = (event.target as HTMLElement).getBoundingClientRect();
        }
        const x = Math.round(event.clientX - this.cachedTargetBBox.left);
        const y = Math.round(event.clientY - this.cachedTargetBBox.top);
        const scaleFactor = scale ? this.canvasBoundsManager.getScaleFactor() : 1.0;
        return new Point2D(Math.round(x / scaleFactor), Math.round(y / scaleFactor));
    }

    ngOnDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();

        document.removeEventListener('mousedown', this.selectionMouseDownListener);
        document.removeEventListener('mousemove', this.selectionMouseMoveListener);
        document.removeEventListener('mouseup', this.selectionMouseUpListener);
    }
}

export interface BackgroundImageInfo {
    image: string;
    width: number;
    height: number;
}

export interface ContourSegmentationResult {
    readonly contours: { x: number; y: number }[][];
    /* contour border */
    // TODO rename to contourMasks
    readonly contoursMask: number[];
    /* borders of fine level segmentation (used for interactive post-segmentation only) */
    readonly regionPixels: number[][];
    /* borders of fine level segmentation regions (used for interactive post-segmentation only) */
    // TODO rename to borderRegionIndices
    readonly borderIndices: number[][][];
}

export interface RegionSelection {
    regions: number[];
    level: number;
    selectionMode: SelectionMode;
}
