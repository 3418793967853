import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DropZoneDirective } from './drop-zone.directive';
import { List } from 'immutable';
import { DroppableMirrorComponent } from './droppable-mirror/droppable-mirror.component';

@Injectable()
export class DroppableStateStore {
    private stateSubject: BehaviorSubject<DroppableState> = new BehaviorSubject(
        initialDroppableState
    );
    changes: Observable<DroppableState> = this.stateSubject.asObservable();

    addDropZone(newDropZone: DropZone) {
        const zones: List<DropZone> = this.state.dropZones;
        const index = zones.findIndex(zone => zone.dropZoneId === newDropZone.dropZoneId);
        if (index === -1) {
            this.state.dropZones = zones.push(newDropZone);
            this.stateSubject.next(this.state);
        } else if (index > -1) {
            zones.delete(index);
            this.state.dropZones = zones.push(newDropZone);
            this.stateSubject.next(this.state);
        }
    }

    removeDropZone(toDeleteDropZone: DropZone) {
        const zones: List<DropZone> = this.state.dropZones;
        const index = zones.findIndex(zone => zone.dropZoneId === toDeleteDropZone.dropZoneId);
        if (index > -1) {
            this.state.dropZones = zones.delete(index);
            this.stateSubject.next(this.state);
        }
    }

    updateDroppableMirror(newMirrorComponentRef: DroppableMirrorComponent) {
        this.state.mirrorComponentRef = newMirrorComponentRef;
        this.stateSubject.next(this.state);
    }

    get state() {
        return this.stateSubject.value;
    }
}

export const initialDroppableState = {
    mirrorComponentRef: undefined,
    dropZones: List<DropZone>([])
};

export interface DroppableState {
    mirrorComponentRef: DroppableMirrorComponent;
    dropZones: List<DropZone>;
}

export interface DropZone {
    dropZoneId: string;
    componentRef: DropZoneDirective;
}
