import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class RouterService {
    constructor(route, router) {
        this.route = route;
        this.router = router;
    }
    getRouteOnNavigationEnd() {
        return this.router.events.pipe(filter(event => event instanceof NavigationEnd), map(() => this.route), map(route => {
            while (route['firstChild']) {
                route = route['firstChild'];
            }
            return route;
        }), filter(route => route['outlet'] === 'primary'));
    }
}
RouterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouterService_Factory() { return new RouterService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router)); }, token: RouterService, providedIn: "root" });
