import { PositionData } from './position-data.model';
import { ModelDecoder } from './model.decoder';

export class ContourModel {
    width?: number;
    height?: number;
    depth: number;
    svgPathDefinition?: string;
    positionData?: PositionData;

    constructor(obj: ContourModel) {
        this.depth = obj.depth ? ModelDecoder.number(obj.depth) : null;
        this.width = obj.width ? ModelDecoder.number(obj.width) : null;
        this.height = obj.height ? ModelDecoder.number(obj.height) : null;
        this.svgPathDefinition = obj.svgPathDefinition
            ? ModelDecoder.string(obj.svgPathDefinition)
            : null;
        this.positionData = new PositionData(obj.positionData);
    }
}

/**
 * The domain object of a circle shape retrieved from the server.
 */
export class CircleContourModel extends ContourModel {
    radius: number;

    constructor(obj?: CircleContourModel) {
        super(obj);
        this.radius = ModelDecoder.number(obj.radius);
    }
}

/**
 * The domain object of a rectangle shape retrieved from the server.
 */
export class RectangleContourModel extends ContourModel {
    constructor(obj?: RectangleContourModel) {
        super(obj);
    }
}

/**
 * The domain object of a recessed-grip shape retrieved from the server.
 */
export class RecessedGripContourModel extends ContourModel {
    userDefinedDepth?: boolean;
    constructor(obj?: RecessedGripContourModel) {
        super(obj);
        this.userDefinedDepth = obj.userDefinedDepth || false;
    }
}

export class TextContourModel extends ContourModel {
    textContent: string;
    fontFamily: string;
    fontSize: number;

    constructor(obj?: TextContourModel) {
        super(obj);
        this.textContent = ModelDecoder.string(obj.textContent, s => s !== '');
        this.fontFamily = ModelDecoder.string(obj.fontFamily, s => s !== '');
        this.fontSize = ModelDecoder.number(obj.fontSize);
    }
}

/**
 * The domain object of an image-based contour retrieved from the server.
 */
export class ImageContourModel extends ContourModel {
    /**
     *  Id specified by the data store (Called dataStoreId in UI {@code ImageContour} classes)
     */
    id?: string | null;
    imageContourType?: ImageContourType;
    title?: string;
    description?: string;
    previewImage?: string;
    image?: string;
    // TODO remove it; will be computed depending on the object depth
    isValid?: boolean;
    isEditable?: boolean;
    isRemovable?: boolean;
    userDefinedDepth?: boolean;

    constructor(obj?: ImageContourModel) {
        super(obj);
        const setterFn = (value: any, def: any) => (value == null ? def : value);

        this.id = obj.id ? ModelDecoder.string(obj.id) : null;
        this.depth = ModelDecoder.number(obj.depth);
        this.title = obj.title ? ModelDecoder.string(obj.title, s => s !== '') : null;
        this.image = obj.image ? ModelDecoder.string(obj.image, s => s !== '') : null;
        this.previewImage = obj.previewImage
            ? ModelDecoder.string(obj.previewImage, s => s !== '')
            : null;

        this.description = obj.description ? ModelDecoder.string(obj.description) : null;
        this.imageContourType = setterFn(obj.imageContourType, ImageContourType.USER_TOOL);
        this.isEditable = this.imageContourType === ImageContourType.USER_TOOL;
        this.isRemovable = setterFn(obj.isRemovable, true);
        this.userDefinedDepth = setterFn(obj.userDefinedDepth, false);
    }
}

export class ProducerImageContourModel extends ImageContourModel {
    producerName?: string;
    toolTypeName?: string;
    url?: string;

    constructor(obj: any) {
        super(obj);

        const setterOrErrorFn = (value: any) => {
            if (value == null) {
                throw new ReferenceError(obj.id.toString());
            }
            return value;
        };

        this.imageContourType = setterOrErrorFn(ImageContourType.PRODUCER_TOOL);
        this.producerName = setterOrErrorFn(obj.producerName);
        this.toolTypeName = setterOrErrorFn(obj.toolTypeName);
        this.url = obj.url;
        this.isEditable = false;
    }
}

export enum ImageContourType {
    USER_TOOL = 'USER_TOOL',
    PRODUCER_TOOL = 'PRODUCER_TOOL'
}
