/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accordion-topbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./accordion-topbar.component";
var styles_AccordionTopbarComponent = [i0.styles];
var RenderType_AccordionTopbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccordionTopbarComponent, data: {} });
export { RenderType_AccordionTopbarComponent as RenderType_AccordionTopbarComponent };
export function View_AccordionTopbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "main-toolbar__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ". ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.panelIndex; var currVal_1 = _co.panelTitle; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_AccordionTopbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accordion-toolbar", [], null, null, null, View_AccordionTopbarComponent_0, RenderType_AccordionTopbarComponent)), i1.ɵdid(1, 49152, null, 0, i2.AccordionTopbarComponent, [], null, null)], null, null); }
var AccordionTopbarComponentNgFactory = i1.ɵccf("app-accordion-toolbar", i2.AccordionTopbarComponent, View_AccordionTopbarComponent_Host_0, { panelTitle: "panelTitle", panelIndex: "panelIndex" }, {}, []);
export { AccordionTopbarComponentNgFactory as AccordionTopbarComponentNgFactory };
