import { Action } from './action';
import { CanvasService } from '../canvas/canvas.service';
import { FoamEditorService } from '../foam-editor.service';
import { Inject } from '@angular/core';

@Inject({})
export class RotationAction implements Action {
    private readonly canvasService: CanvasService;

    constructor(private readonly foamEditor: FoamEditorService) {
        this.canvasService = foamEditor.getCanvasService();
    }

    execute(event: RotationActionEvent) {
        this.canvasService.getSelectedContourItems().forEach(contour => {
            if (contour.isRotatable) {
                const currentAngle = contour.localContourPathMatrix.split().rotate;
                const deltaAngle = event.angle - currentAngle;
                contour.rotate(
                    deltaAngle,
                    contour.localContourPathBBox.cx,
                    contour.localContourPathBBox.cy
                );
            }
        });
    }
}

export interface RotationActionEvent {
    angle: number;
}
