import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from '@angular/core';

import { InlSVGTextInputElement } from '../inl-svg-text-input-element';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { CanvasDomService } from '../canvas-dom.service';
import { sendToBack, sendToFront } from '../../../utils/dom-utils';
import { CanvasElementChangedEvent } from '../canvas.component';
import { SelectionChangedData } from '../canvas.service';
import { ContourElementRefs } from '../contour-element-refs';
import { CLASS_NAME_CONTOUR_SELECTED } from '../contour/constants';
import { ContourElementType } from '../contour/contour-element-type';
import {
    ContourChangeData,
    ContourDrawData,
    ContourItemsRemoved,
    ContourSelectionData,
    TextContourChangeData
} from '../contour/contour-items-interfaces';
import { TextContour, TextSelectionData } from '../contour/text-contour';
import { GroupContourElementRefs } from '../group-contour-element-refs';
import { LayerElementStoreService } from '../layer-element-store.service';

import { CanvasContourLayer } from './canvas-contour-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';
import { CanvasLayerWithSubscriptionMixin } from './destroyable-layer-mixin';
import { ItemsLayerDirective } from './items-layer.directive';
import { SafetyMarginLayerDirective } from './safety-margin-layer.directive';

declare var Snap: any;

@Directive({ selector: '[appContourItemsLayer]' })
export class ContourItemsLayerDirective extends CanvasLayerMixin(CanvasLayerWithSubscriptionMixin())
    implements CanvasContourLayer<Snap.Paper>, AfterViewInit, OnDestroy {
    @Output()
    contourDrawn: EventEmitter<CanvasElementChangedEvent> = new EventEmitter();

    /**
     * @deprecated use element
     */
    svgElement: Snap.Paper;
    private snapMarginLayer: Snap.Paper;

    element: Snap.Paper;
    marginLayer: SafetyMarginLayerDirective;
    parentLayer: ItemsLayerDirective;

    constructor(
        private elementRef: ElementRef,
        private workspaceDOMService: CanvasDomService,
        private layerElementStore: LayerElementStoreService
    ) {
        super();
        this.svgElement = this.element = Snap(elementRef.nativeElement);
    }

    ngAfterViewInit() {
        // FIXME refactor the component communication
        this.snapMarginLayer = Snap('.layer-safety-margins');
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    /**
     * @Deprecated use itemLayers.addContourItem . Will be removed after test migration
     */
    addContourItem(contourData: ContourDrawData): CanvasElementChangedEvent {
        return undefined;
    }

    private updateTextContour2(inlSvgTextInput: InlSVGTextInputElement): CanvasElementChangedEvent {
        const contourRefs = this.layerElementStore.getContourElements(
            inlSvgTextInput.contourId
        ) as ContourElementRefs;

        const textBBox = Rectangle2D.fromObject(inlSvgTextInput.getTextBBox(true));

        // textBBox

        // const svgPathDefinition = TextContour.createPathString(textBBox);
        const marginPathString = TextContour.createTextMarginPathString(
            textBBox,
            contourRefs.marginSize
        );

        contourRefs.margin.marginPathString = marginPathString;

        const marginPath = contourRefs[ContourElementType.MARGIN_PATH];
        if (marginPath) {
            marginPath.node.setAttribute('d', marginPathString);
            marginPath.node.setAttribute(
                'transform',
                inlSvgTextInput.textElement.transform().localMatrix.toString()
            );
        }

        contourRefs.updateTransformData();

        return {
            contourId: inlSvgTextInput.contourId,
            contour: contourRefs.contour,
            margin: contourRefs.margin,
            isNewContour: false
        };
    }

    changeContourItem(contourData: ContourChangeData): CanvasElementChangedEvent {
        const contourRefs = this.layerElementStore.getContourElements(contourData.contourId);
        if (!contourRefs) {
            return undefined;
        }

        if (contourRefs instanceof GroupContourElementRefs) {
            const groupShapeChanged = this.updateGroupElements(contourData, contourRefs);
            if (groupShapeChanged) {
                contourRefs.updateGroupTransformedData(
                    this.scaleFactor,
                    this.parentLayer.layerOffset
                );
                return contourRefs.getTransformedData();
            }
        } else {
            const contourShapeChanged = this.updateSingleElements(contourData, contourRefs);
            if (contourShapeChanged) {
                contourRefs.updateTransformData();
                return {
                    contourId: contourData.contourId,
                    contour: contourRefs.contour,
                    isNewContour: false
                };
            }
        }

        return undefined;
    }

    private updateGroupElements(
        contourData: ContourChangeData,
        elements: GroupContourElementRefs
    ): boolean {
        let groupShapeChanged = false;
        if (contourData.children && contourData.children.length > 0) {
            contourData.children.forEach(data => {
                const childElement = elements.children.get(data.contourId);

                const contourShapeChanged = this.updateSingleElements(data, childElement);
                if (!groupShapeChanged && contourShapeChanged) {
                    groupShapeChanged = true;
                }

                /*
                const groupElement = childElement[ContourElementType.ITEM_GROUP];
                if (groupElement) {
                    groupElement.node.setAttribute('transform', data.matrix.toString());
                } */
                if (groupShapeChanged) {
                    const marginPath = childElement[ContourElementType.MARGIN_PATH];
                    if (marginPath) {
                        marginPath.node.setAttribute('transform', data.matrix.toString());
                        marginPath.attr({
                            x: data.x,
                            y: data.y
                        });
                    }
                }
            });
        }

        return groupShapeChanged;
    }

    private updateSingleElements(
        contourData: ContourChangeData,
        elements: ContourElementRefs
    ): boolean {
        const { matrix } = contourData;

        const pathElement = elements[ContourElementType.ITEM_PATH];
        const groupElement = elements[ContourElementType.ITEM_GROUP];
        const marginElem = elements[ContourElementType.MARGIN_PATH];
        const textElement = elements[ContourElementType.ITEM_TEXT];

        let contourShapeChanged = false;

        if (contourData.marginSize) {
            elements.marginSize = contourData.marginSize;
        }

        if (contourData.svgPathDefinition && pathElement) {
            pathElement.node.setAttribute('d', contourData.svgPathDefinition);
            contourShapeChanged = true;
        }

        if (matrix) {
            if (textElement) {
                textElement.node.setAttribute('transform', contourData.matrix.toString());
                contourShapeChanged = true;
            } else if (groupElement) {
                groupElement.node.setAttribute('transform', contourData.matrix.toString());
                contourShapeChanged = true;
            }
        }

        if (contourData.visibility !== undefined) {
            groupElement.node.style.visibility = contourData.visibility ? 'visible' : 'hidden';
            marginElem.node.style.visibility = contourData.visibility ? 'visible' : 'hidden';
        }

        if (textElement) {
            contourShapeChanged = this.updateTextElement(contourData);
        }
        return contourShapeChanged;
    }

    private updateTextElement(contourData: ContourChangeData): boolean {
        let contourShapeChanged = false;
        if (contourData instanceof TextContourChangeData && contourData.textContent) {
            const inlInputText = this.layerElementStore.getSVGTextInput(contourData.contourId);
            inlInputText.setText(contourData.textContent);
            this.layerElementStore.notifyFoamContourChange(this.updateTextContour2(inlInputText));
            contourShapeChanged = true;
        }

        if (contourData.selectionData) {
            this.handleTextSelectionChanged(
                contourData.contourId,
                contourData.selectionData as TextSelectionData
            );
            contourShapeChanged = true;
        }
        return contourShapeChanged;
    }

    selectContourItems(selectionData: SelectionChangedData) {
        const { itemsToSelect, itemsToDeselect } = selectionData;

        if (itemsToSelect) {
            this.doSelection(itemsToSelect, true);
        }

        if (itemsToDeselect) {
            this.doSelection(itemsToDeselect, false);
        }
    }

    private doSelection(contours: Set<ContourSelectionData>, selected: boolean) {
        contours.forEach(itemSelData => {
            const contourRefs = this.layerElementStore.getContourElements(itemSelData.contourId);
            if (contourRefs instanceof GroupContourElementRefs && contourRefs.children) {
                contourRefs.children.forEach(child => {
                    this.changeSelection(child, selected, itemSelData.sendTo);
                });
            } else if (contourRefs instanceof ContourElementRefs) {
                this.changeSelection(contourRefs, selected, itemSelData.sendTo);
            }

            if (
                itemSelData.selectionData ||
                itemSelData.selectionData instanceof TextSelectionData
            ) {
                this.handleTextSelectionChanged(
                    itemSelData.contourId,
                    itemSelData.selectionData as TextSelectionData
                );
            }
        });
    }

    private handleTextSelectionChanged(contourId: string, textSelectionData: TextSelectionData) {
        const svgTextInput = this.layerElementStore.getSVGTextInput(contourId);

        svgTextInput.editMode = !!textSelectionData.isInEditMode;

        if (textSelectionData.textSelection) {
            const { type, position } = textSelectionData.textSelection;
            if (type === 'ALL') {
                svgTextInput.selectAll();
            } else if (type === 'SELECT') {
                svgTextInput.select(position.x, position.y);
            } else if (type === 'EXTEND') {
                svgTextInput.extendSelection(position.x, position.y);
            }
        }
    }

    // TODO duplicate code (see safety-margin)
    private changeSelection(
        pathRefs: ContourElementRefs,
        select: boolean,
        sendTo?: 'front' | 'back'
    ): void {
        if (!pathRefs) {
            return;
        }

        const itemPath = pathRefs[ContourElementType.ITEM_PATH];
        if (itemPath) {
            if (select) {
                itemPath.addClass(CLASS_NAME_CONTOUR_SELECTED);
            } else {
                itemPath.removeClass(CLASS_NAME_CONTOUR_SELECTED);
            }
        }

        const group = pathRefs[ContourElementType.ITEM_GROUP];
        // woItem.contourType !== ContourType.RECESSED_GRI
        if (group) {
            if (sendTo === 'front') {
                sendToFront(this.element.node, group.node);
            } else if (sendTo === 'back') {
                sendToBack(this.element.node, group.node);
            }
        }
    }

    removeContourItem(contourData: ContourItemsRemoved) {
        if (!contourData.contourIds) {
            return;
        }

        contourData.contourIds.forEach(contourId => {
            const elements = this.layerElementStore.getContourElements(contourId);
            // delete via dragNDrop is not supported for groups yet
            // TODO creation and deletion of DroppableItem should be located in the same file/class
            if (elements instanceof ContourElementRefs) {
                this.workspaceDOMService.droppableContainer.removeItem(
                    elements[ContourElementType.ITEM_GROUP].node
                );
            }
            this.layerElementStore.removeContourElements(contourId);
        });
    }

    private _showContourImage: boolean = true;

    @Input()
    get showContourImage(): boolean {
        return this._showContourImage;
    }

    set showContourImage(newValue: boolean) {
        /*    if (this._showContourImage !== newValue) {
                this._showContourImage = newValue;
                const displayValue = newValue ? 'inline' : 'none';
                Object.values(this.layerElementStore.contourElementsRefs).forEach(refs => {
                    if (refs instanceof ContourElementRefs) {
                        this.setContourImageDisplay(refs[ContourElementType.ITEM_IMAGE],
           displayValue); } else if (refs instanceof GroupContourElementRefs) {
                        refs.children.forEach(childRef => {
                            this.setContourImageDisplay(
                                childRef[ContourElementType.ITEM_IMAGE], displayValue);
                        });
                    }
                });
            } */
    }
}
