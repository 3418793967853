/**
 * Service to create and draw a rubber-band selection on a specified svg element
 */
import { RubberBandDrawData } from './contour/contour-items-interfaces';

const CLASS_NAME_RUBBER_BAND = 'rubber-band-rect';

export class RubberBandDrawer {
    private rubberBand: Snap.Element;

    constructor(private readonly container: Snap.Paper) {}

    private createRubberBand() {
        this.rubberBand = this.container.rect(0, 0, 0, 0) as Snap.Element;
        this.rubberBand.attr({ display: 'none', class: CLASS_NAME_RUBBER_BAND });
    }

    draw(drawData: RubberBandDrawData) {
        // remove the svg element so that we don't need to send it to the
        // front next time when it becomes visible
        if (!drawData.visible && this.rubberBand) {
            this.rubberBand.remove();
            this.rubberBand = null;
            return;
        }

        if (this.rubberBand == null) {
            this.createRubberBand();
        }

        this.rubberBand.attr({
            x: drawData.x,
            y: drawData.y,
            width: drawData.width,
            height: drawData.height,
            display: 'inline'
        });
    }
}
