import { CuttableContour, CuttableContourArgs } from './cuttable-contour';
import {
    ContourChangeData,
    ContourHandlesData,
    MarginContourCollisionVizData,
    TextContourChangeData,
    TextCuttableContourDrawData
} from './contour-items-interfaces';
import { Handle } from '../../shared/handle/handle';
import { CanvasElementChangedEvent } from '../canvas.component';
import { Tool } from '../../shared/tool/tool';
import {
    createTextContourMargin,
    createTextContourPath,
    pathString2Polygon,
    transformBoundingBox
} from './contour-helper';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import {
    CLASS_NAME_TEXT_CONTOUR_GROUP,
    COLOR_BG_ITEM_PATH,
    COLOR_BG_MARGIN_PATH,
    OPACITY_BG_MARGIN_PATH
} from './constants';
import { RotationHandle } from '../../shared/handle/rotation-handle';
import { RotationConnectorType } from '../handles/rotation-handles-drawer';
import { TextEditingTool } from '../../shared/tool/text-editing-tool';
import { FoamEditorService } from '../../foam-editor.service';
import { Point2D } from '../../shared/geom/point2D';

export interface TextSelectionInfo {
    type: 'ALL' | 'SELECT' | 'EXTEND';
    position?: Point2D;
}

export class TextSelectionData {
    readonly isInEditMode: boolean;
    readonly textSelection?: TextSelectionInfo;

    constructor(obj: TextSelectionData) {
        this.isInEditMode = obj.isInEditMode;
        this.textSelection = obj.textSelection || undefined;
        if (
            this.textSelection &&
            !this.textSelection.position &&
            this.textSelection.type !== 'ALL'
        ) {
            throw new ReferenceError('Property position is undefined in object textSelection ');
        }
    }
}

export class TextContour extends CuttableContour {
    isCollidable: boolean;
    isRemovable: boolean = true;
    isSelectable: boolean;
    isResizable: boolean = false;
    defaultText: string;
    textContent: string;
    fontFamily: string;
    fontSize: number;
    private rotationHandle: RotationHandle<CuttableContour>;
    isInEditMode: boolean = false;
    private textSelection: TextSelectionInfo;
    private textEditingTool: TextEditingTool;

    constructor(obj: TextContourArgs) {
        super(obj);

        const setterFn = (value: any, def: any) => (value == null ? def : value);
        this.defaultText = setterFn(obj.defaultText, 'Sortimo');
        this.textContent = setterFn(obj.textContent, this.defaultText);
        this.isSelectable = setterFn(obj.isSelectable, true);
        this.isCollidable = setterFn(obj.isCollidable, true);
        this.isResizable = setterFn(obj.isResizable, false);
        this.fontFamily = setterFn(obj.fontFamily, 'ISOCPEUR');
        this.fontSize = setterFn(obj.fontSize, 9);
        this.marginSize = 1;
        this.itemProps.classNameGroup = CLASS_NAME_TEXT_CONTOUR_GROUP + this.contourId;
        this.itemProps.classNamePath = 'text-contour-' + this.contourId;
        this.itemProps.contourFill = COLOR_BG_ITEM_PATH;
        this.itemProps.marginFill = COLOR_BG_MARGIN_PATH;
        this.itemProps.marginOpacity = OPACITY_BG_MARGIN_PATH;
    }

    static createTextMarginPathString(textBounds: Rectangle2D, marginSize: number): string {
        return createTextContourMargin(textBounds, marginSize);
    }

    static createPathString(textBounds: Rectangle2D): string {
        return createTextContourPath(textBounds);
    }

    selectTextAll(fireEvent?: boolean) {
        this.getRotationHandle().visible = false;
        this.isInEditMode = true;
        this.textSelection = { type: 'ALL' };

        if (fireEvent) {
            this.fireSelectionDataChanged();
        }
    }

    selectText(mousePos: Point2D, fireEvent?: boolean) {
        const roHandle = this.getRotationHandle();
        roHandle.visible = false;
        this.isInEditMode = true;
        this.textSelection = { type: 'SELECT', position: mousePos };

        if (fireEvent) {
            this.fireSelectionDataChanged();
        }
    }

    extendSelection(mouseToCanvasPt: Point2D) {
        this.textSelection = { type: 'EXTEND', position: mouseToCanvasPt };
        this.fireSelectionDataChanged();
    }

    deselectTextInput(fireEvent?: boolean) {
        this.rotationHandle.visible = true;
        this.isInEditMode = false;
        this.textSelection = undefined;
        if (fireEvent) {
            this.fireSelectionDataChanged();
        }
    }

    setText(newText: string) {
        if (newText && newText.localeCompare(this.textContent) !== 0) {
            this.textContent = newText;
            this.contourChanged.next(
                new TextContourChangeData({
                    contourId: this.contourId,
                    textContent: this.textContent
                })
            );
        }
    }

    private fireSelectionDataChanged() {
        const contourChangeData = new ContourChangeData({
            contourId: this.contourId,
            selectionData: this.getSelectionData()
        });
        this.contourChanged.next(contourChangeData);
    }

    getSelectionData(): TextSelectionData {
        return new TextSelectionData({
            isInEditMode: this.isInEditMode,
            textSelection: this.textSelection
        });
    }

    contains(px: number, py: number): boolean {
        return false;
    }

    containsTarget(target: Element): boolean {
        const textSelector = '.' + this.itemProps.classNamePath;
        const spanSelector = textSelector + ' tspan';
        const backgroundSelector = '.svg-text-background-' + this.contourId;
        return (
            target.matches(spanSelector) ||
            target.matches(textSelector) ||
            target.matches(backgroundSelector) ||
            target.id === this.itemProps.marginPathId
        );
    }

    createHandles(): Handle[] {
        return [this.getRotationHandle()];
    }

    private getRotationHandle() {
        if (!this.rotationHandle) {
            this.rotationHandle = new RotationHandle<CuttableContour>(this);
            this.rotationHandle.connectorType = RotationConnectorType.START_ABOVE_END_ON;
        }
        return this.rotationHandle;
    }

    getCollisionData(): MarginContourCollisionVizData {
        return new MarginContourCollisionVizData({
            contourId: this.contourId,
            clipPathString: this.marginPathString,
            pathString: this.svgPathDefinition,
            pathStringToBeClipped: this.svgPathDefinition,
            transformString: this.localMarginPathMatrix.toTransformString(),
            transformString2: this.localMarginPathMatrix.toString(),

            innerCollisionPolygonLines: this.marginPolygonLines,
            innerCollisionBBox: this.globalMarginPathBBox,
            outerCollisionPolygonLines: this.marginPolygonLines,
            outerCollisionBBox: this.globalMarginPathBBox,

            globalContourPathBBox: this.globalContourPathBBox,
            localContourPathBBox: this.localContourPathBBox,

            globalMarginPathMatrix: this.globalMarginPathMatrix,
            localMarginPathMatrix: this.localMarginPathMatrix,
            globalContourPathMatrix: this.globalContourPathMatrix,
            localContourPathMatrix: this.localContourPathMatrix,
            isCollidable: this.isCollidable
        });
    }

    getDrawData(): TextCuttableContourDrawData {
        return new TextCuttableContourDrawData({
            contourId: this.contourId,
            textContent: this.textContent,
            svgPathDefinition: this.svgPathDefinition,
            marginPathString: this.marginPathString,
            marginSize: this.marginSize,
            itemProps: this.itemProps,
            isCollidable: this.isCollidable,
            isSelectable: this.isSelectable,
            matrix: this.localContourPathMatrix,
            // TODO remove it
            createPathString: (textBounds: Rectangle2D) => TextContour.createPathString(textBounds)
        });
    }

    getHandlesDrawData(): ContourHandlesData {
        return new ContourHandlesData({
            containerId: 'handle-container-' + this.contourId,
            contourId: this.contourId,
            transformBounds: {
                localContourBounds: this.localContourPathBBox,
                localMarginBounds: this.localMarginPathBBox,
                selectionBoundsMatrix: this.localContourPathMatrix
            },
            rotationHandles: this.rotationHandle.getDrawData()
        });
    }

    getTool(target: Element, editor: FoamEditorService): Tool | null {
        if (this.canvasService.isContourItemSelected(this) && this.containsTarget(target)) {
            if (!this.textEditingTool) {
                this.textEditingTool = new TextEditingTool(this, editor);
            }
            return this.textEditingTool;
        }
        return null;
    }

    onContourElementDrawn(drawnData: CanvasElementChangedEvent): void {
        if (!drawnData) {
            return;
        }

        if (drawnData.textContent) {
            this.textContent = drawnData.textContent;
        }

        if (drawnData.contour) {
            this.svgPathDefinition = drawnData.contour.svgPathDefinition;
            if (this.svgPathDefinition) {
                this.polygonLines = pathString2Polygon(
                    this.svgPathDefinition,
                    drawnData.contour.globalMatrix
                );
            }

            this.globalContourPathBBox = drawnData.contour.globalBBox;
            this.localContourPathBBox = drawnData.contour.localBBox;
            this.globalContourPathMatrix = drawnData.contour.globalMatrix;
            this.localContourPathMatrix = drawnData.contour.localMatrix;

            this.contourBoundsInParent = Rectangle2D.fromPointsObject(
                transformBoundingBox(drawnData.contour.localBBox, this.localContourPathMatrix)
            );

            this.width = drawnData.contour.localBBox.width;
            this.height = drawnData.contour.localBBox.height;
        }

        if (drawnData.margin) {
            this.marginPathString = drawnData.margin.marginPathString;

            this.globalMarginPathMatrix = drawnData.margin.globalMatrix;
            this.localMarginPathMatrix = drawnData.margin.localMatrix;
            this.globalMarginPathBBox = drawnData.margin.globalBBox;
            this.localMarginPathBBox = drawnData.margin.localBBox;
            this.marginPolygonLines = pathString2Polygon(
                this.marginPathString,
                drawnData.margin.globalMatrix
            );
        }
    }
}

export interface TextContourArgs extends CuttableContourArgs {
    defaultText?: string;
    textContent?: string;
    fontFamily?: string;
    fontSize?: number;
}
