/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-content.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dialog-content.component";
import * as i3 from "../dialog-refs";
var styles_DialogContentComponent = [i0.styles];
var RenderType_DialogContentComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DialogContentComponent, data: {} });
export { RenderType_DialogContentComponent as RenderType_DialogContentComponent };
export function View_DialogContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_DialogContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-content", [["class", "modal-card-body"]], null, [["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DialogContentComponent_0, RenderType_DialogContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.DialogContentComponent, [i3.DialogRefs], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogContentComponentNgFactory = i1.ɵccf("app-dialog-content", i2.DialogContentComponent, View_DialogContentComponent_Host_0, { closable: "closeable" }, {}, ["*"]);
export { DialogContentComponentNgFactory as DialogContentComponentNgFactory };
