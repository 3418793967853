import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZoomActionEvent } from '../../foam-editor/actions/zoom.action';

@Component({
    selector: 'app-zoom-panel',
    templateUrl: './zoom-panel.component.html',
    styleUrls: ['./zoom-panel.component.scss']
})
export class ZoomPanelComponent implements OnInit {
    @Input()
    zoomStartValue: number;

    @Input()
    zoomStep: number = 10;

    @Input()
    minValue: number = 2;

    @Output()
    zoomedOut = new EventEmitter<ZoomActionEvent>();

    @Output()
    zoomedIn = new EventEmitter<ZoomActionEvent>();

    @Input()
    titleZoomOut: string;

    @Input()
    titleZoomIn: string;

    disabledZoomOutBtn: boolean;

    private _zoomValue: number;

    constructor() {}

    ngOnInit() {
        // is nil
        if (this.zoomStartValue != null) {
            this.zoomValue = this.zoomStartValue;
        }
    }

    @Input()
    set zoomValue(newZoomValue: number) {
        if (newZoomValue !== this.zoomValue) {
            this._zoomValue = newZoomValue;
            this.disabledZoomOutBtn = this._zoomValue > this.minValue;
        }
    }

    get zoomValue(): number {
        return this._zoomValue;
    }

    zoomIn() {
        this.zoomedIn.next({
            zoomStep: this.zoomStep
        });
    }

    zoomOut() {
        if (this.disabledZoomOutBtn) {
            this.zoomedOut.next({
                zoomStep: -this.zoomStep
            });
        }
    }
}
