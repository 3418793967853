import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Optional,
    ViewEncapsulation
} from '@angular/core';
import { AccordionDirective } from './accordion.directive';
import { AccordionItemDirective } from './accordion-item.directive';
import { coerceBooleanProperty } from '../../../utils/coerce-boolean';
import { matExpansionAnimations } from './expansion-animation';
import { AnimationEvent } from '@angular/animations';
import { UniqueSelectionDispatcher } from '../../../shared/collections/unique-selection-dispatcher';

export type AppExpansionPanelState = 'expanded' | 'collapsed';

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['disabled', 'expanded'],
    outputs: ['opened', 'closed', 'expandedChange'],
    animations: [matExpansionAnimations.bodyExpansion],
    host: {
        class: 'app-expansion-panel',
        '[class.app-expanded]': 'expanded'
    }
})
export class ExpansionPanelComponent extends AccordionItemDirective {
    private _hideToggle = false;

    constructor(
        @Optional() public accordion: AccordionDirective,
        _changeDetectorRef: ChangeDetectorRef,
        _uniqueSelectionDispatcher: UniqueSelectionDispatcher
    ) {
        super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
        this.accordion = accordion;
    }

    /** Whether the toggle indicator should be hidden. */
    @Input()
    get hideToggle(): boolean {
        return this._hideToggle;
    }

    set hideToggle(value: boolean) {
        this._hideToggle = coerceBooleanProperty(value);
    }

    /** Whether the expansion indicator should be hidden. */
    _getHideToggle(): boolean {
        if (this.accordion) {
            return this.accordion.hideToggle;
        }
        return this.hideToggle;
    }

    /** Gets the expanded state string. */
    _getExpandedState(): AppExpansionPanelState {
        return this.expanded ? 'expanded' : 'collapsed';
    }

    _bodyAnimation(event: AnimationEvent) {
        const classList = event.element.classList;
        const cssClass = 'app-expanded';
        const { phaseName, toState } = event;

        // Toggle the body's `overflow: hidden` class when closing starts or when expansion ends in
        // order to prevent the cases where switching too early would cause the animation to jump.
        // Note that we do it directly on the DOM element to avoid the slight delay that comes
        // with doing it via change detection.
        if (phaseName === 'done' && toState === 'expanded') {
            classList.add(cssClass);
        } else if (phaseName === 'start' && toState === 'collapsed') {
            classList.remove(cssClass);
        }
    }
}
