import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { TabItemInfo } from '../ribbon/ribbon.component';
import { OverviewPanelComponent } from '../ribbon/ribbon-panels/overview-panel/overview-panel.component';
import { FoamEditorService } from 'src/app/foam-editor/foam-editor.service';
import { ResizeContourAction } from 'src/app/foam-editor/actions/resize-contour-action';
import { RotationAction } from '../actions/rotation-action';
import { Observable } from 'rxjs';
import { RibbonSelectionService } from '../ribbon/ribbon-selection.service';

@Component({
    selector: 'app-contour-properties',
    templateUrl: './contour-properties.component.html',
    styleUrls: ['./contour-properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContourPropertiesComponent implements OnInit, AfterViewInit {
    showContourProperties: boolean;
    displayFunctions: boolean;

    @HostBinding('class.contour-properties--overview')
    isOverviewPanelActive = false;

    @Input()
    contourProperties: Observable<ContourProperties>;

    props: ContourProperties;

    @ViewChild('widthInput', { static: false })
    readonly widthInputElement;

    @ViewChild('heightInput', { static: false })
    readonly heightInputElement;

    @ViewChild('depthInput', { static: false })
    readonly depthInputElement;

    @ViewChild('rotationInput', { static: false })
    readonly rotationInputElement: ElementRef<HTMLInputElement>;

    private readonly resizeAction: ResizeContourAction;
    private rotationAction: RotationAction;

    constructor(
        private readonly foamEditorService: FoamEditorService,
        private readonly ribbonSelectionService: RibbonSelectionService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.resizeAction = new ResizeContourAction(foamEditorService);
        this.rotationAction = new RotationAction(foamEditorService);
    }

    ngOnInit() {
        this.contourProperties.subscribe(x => {
            this.props = x;
            this.changeDetectorRef.markForCheck();
        });

        this.ribbonSelectionService.ongRibbonTabSelected.subscribe((tabInfo: TabItemInfo) => {
            if (tabInfo && tabInfo.panelComponent === OverviewPanelComponent) {
                this.isOverviewPanelActive = true;
                this.displayFunctions = false;
                this.showContourProperties = false;
            } else {
                this.isOverviewPanelActive = false;
                this.displayFunctions = true;
                this.showContourProperties = true;
            }
        });
    }

    ngAfterViewInit(): void {
        // Since the new input is only submitted when pressing ENTER,
        // we reset un-submitted input values to the last submitted (current) value
        this.rotationInputElement.nativeElement.addEventListener('blur', () => {
            this.rotationInputElement.nativeElement.value = this.props.rotationDegree.value;
        });

        this.heightInputElement.nativeElement.addEventListener('blur', () => {
            this.heightInputElement.nativeElement.value = this.props.height.value;
        });

        this.widthInputElement.nativeElement.addEventListener('blur', () => {
            this.widthInputElement.nativeElement.value = this.props.width.value;
        });

        this.depthInputElement.nativeElement.addEventListener('blur', () => {
            this.depthInputElement.nativeElement.value = this.props.depth.value;
        });
    }

    changeRotateDegree($event: Event) {
        const newValue = parseFloat(($event.target as HTMLInputElement).value);
        if (!isNaN(newValue)) {
            this.rotationAction.execute({ angle: newValue });
        }
        this.rotationInputElement.nativeElement.value = this.props.rotationDegree.value;
        this.rotationInputElement.nativeElement.blur();
    }

    changeHeight($event: Event) {
        const newValue = parseFloat(($event.target as HTMLInputElement).value);
        if (!isNaN(newValue)) {
            this.resizeAction.execute({
                dimension: { height: newValue }
            });
        }

        this.heightInputElement.nativeElement.value = this.props.height.value;
        this.heightInputElement.nativeElement.blur();
    }

    changeWidth($event: Event) {
        const newValue = parseFloat(($event.target as HTMLInputElement).value);
        if (!isNaN(newValue)) {
            this.resizeAction.execute({
                dimension: { width: newValue }
            });
        }
        this.widthInputElement.nativeElement.value = this.props.width.value;
        this.widthInputElement.nativeElement.blur();
    }

    changeDepth($event: Event) {
        const newValue = parseFloat(($event.target as HTMLInputElement).value);
        if (!isNaN(newValue)) {
            this.resizeAction.execute({
                depth: newValue
            });
        }
        this.depthInputElement.nativeElement.value = this.props.depth.value;
        this.depthInputElement.nativeElement.blur();
    }

    blurInputElements() {
        this.rotationInputElement.nativeElement.blur();
        this.depthInputElement.nativeElement.blur();
        this.widthInputElement.nativeElement.blur();
        this.heightInputElement.nativeElement.blur();
    }
}

export interface ContourProperties {
    readonly width: { value: string; editable: boolean };
    readonly height: { value: string; editable: boolean };
    readonly rotationDegree: { value: string; editable: boolean };
    readonly depth: { value: string; editable: boolean };
}
