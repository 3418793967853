<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container uploaded-images_container">
                <!-- sidebar -->
                <div class="sidebar">
                    <div class="action-info-text">
                        <p>{{ 'DETECTION_RESULT_DIALOG.EDIT_INFO_TEXT' | translate }}</p>
                        <p>
                            {{
                                'DETECTION_RESULT_DIALOG.EDIT_GUIDE_TEXT_BEFORE_PLUS_ICON'
                                    | translate
                            }}
                            <app-icon shape="contour-add-text" iconSize="small"></app-icon>
                            {{
                                'DETECTION_RESULT_DIALOG.EDIT_GUIDE_TEXT_BETWEEN_ICONS' | translate
                            }}
                            <app-icon shape="contour-remove-text" iconSize="small"></app-icon>
                            {{
                                'DETECTION_RESULT_DIALOG.EDIT_GUIDE_TEXT_AFTER_MINUS_ICON'
                                    | translate
                            }}
                        </p>
                    </div>

                    <app-upload-dialog-footer></app-upload-dialog-footer>
                </div>

                <div class="middle-bar">
                    <div class="action-buttons">
                        <div class="action-buttons__row">
                            <!--   <div class="btn-container" hidden="true">
                                <app-button
                                    app-icon-button
                                    color="tertiary"
                                    buttonSize="small"
                                    iconAlign="horizontal"
                                    appTooltip
                                    appToolTipContent="{{
                                        'DETECTION_RESULT_DIALOG.TOOLTIP_UNDO_ACTION' | translate
                                    }}"
                                    [appTooltipPlacement]="'right'"
                                >
                                    <app-icon shape="icon-arrow-back"></app-icon>
                                </app-button>
                            </div>
                            <div class="btn-container" hidden="true">
                                <app-button
                                    app-icon-button
                                    color="tertiary"
                                    buttonSize="small"
                                    iconAlign="horizontal"
                                    appTooltip
                                    disabled="true"
                                    appTooltip
                                    appToolTipContent="{{
                                        'DETECTION_RESULT_DIALOG.TOOLTIP_REDO_ACTION' | translate
                                    }}"
                                    [appTooltipPlacement]="'right'"
                                >
                                    <app-icon shape="icon-arrow-forward"></app-icon>
                                </app-button>
                            </div> -->
                            <div class="radio-btn-container">
                                <input
                                    type="radio"
                                    id="add-regions"
                                    name="contour-edit-mode"
                                    class="radio-btn-input"
                                    value="add-regions"
                                    autocomplete="off"
                                    checked
                                    (change)="changeEditMode($event)"
                                    [disabled]="(contourDetectionResultChanged | async) == null"
                                />
                                <label
                                    for="add-regions"
                                    class="radio-btn-label"
                                    appTooltip
                                    appToolTipContent="{{
                                        'DETECTION_RESULT_DIALOG.TOOLTIP_REMOVE_REGIONS' | translate
                                    }}"
                                >
                                    <div class="radio-btn-label-icon">
                                        <app-icon
                                            shape="contour-add"
                                            [disabled]="
                                                (contourDetectionResultChanged | async) == null
                                            "
                                        ></app-icon>
                                    </div>
                                </label>
                            </div>

                            <div class="radio-btn-container">
                                <input
                                    type="radio"
                                    id="remove-regions"
                                    name="contour-edit-mode"
                                    class="radio-btn-input"
                                    value="remove-regions"
                                    autocomplete="off"
                                    checked
                                    (change)="changeEditMode($event)"
                                    [disabled]="(contourDetectionResultChanged | async) == null"
                                />
                                <label
                                    for="remove-regions"
                                    class="radio-btn-label"
                                    appTooltip
                                    appToolTipContent="{{
                                        'DETECTION_RESULT_DIALOG.TOOLTIP_REMOVE_REGIONS' | translate
                                    }}"
                                >
                                    <div class="radio-btn-label-icon">
                                        <app-icon
                                            shape="contour-remove"
                                            [disabled]="
                                                (contourDetectionResultChanged | async) == null
                                            "
                                        ></app-icon>
                                    </div>
                                </label>
                            </div>
                            <div class="radio-btn-container">
                                <input
                                    type="radio"
                                    id="move-tool"
                                    name="contour-edit-mode"
                                    class="radio-btn-input"
                                    value="move-tool"
                                    autocomplete="off"
                                    checked
                                    (change)="changeEditMode($event)"
                                    [disabled]="(contourDetectionResultChanged | async) == null"
                                />
                                <label
                                    for="move-tool"
                                    class="radio-btn-label"
                                    appTooltip
                                    appToolTipContent="{{
                                        'DETECTION_RESULT_DIALOG.TOOLTIP_MOVE_CANVAS' | translate
                                    }}"
                                >
                                    <div class="radio-btn-label-icon">
                                        <app-icon shape="contour-move"></app-icon>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="uploaded-images-browser">
                        <h3>{{ 'DETECTION_RESULT_DIALOG.UPLOADED_IMAGES' | translate }}</h3>
                        <div class="uploaded-images-browser__scroll-button-up">
                            <app-icon
                                [disabled]="!canScrollImagesUp"
                                [shape]="'keyboard_arrow_up'"
                                [cssHeight]="'18px'"
                                [cssWidth]="'40px'"
                                (click)="scrollImagesUp($event)"
                            ></app-icon>
                        </div>
                        <!-- newItemSize =>  84 -->
                        <app-scroll-viewport
                            #imagesScrollViewport
                            itemSize="80.14"
                            itemSpace="10"
                            [itemCount]="paperImagesCount"
                            class="uploaded-images-scroll-viewport"
                        >
                            <div
                                class="uploaded-images-group"
                                [class.uploaded-images-group--selected]="selectedImageIndex == i"
                                *ngFor="let group of paperImages; let i = index"
                            >
                                <a (click)="selectPaperImage(i, true)">
                                    <img
                                        *ngIf="group.croppedPaperImage != null"
                                        [attr.src]="group.croppedPaperImage"
                                        alt="cropped-image"
                                    />
                                </a>
                            </div>
                        </app-scroll-viewport>
                        <div class="uploaded-images-browser__scroll-button-down">
                            <app-icon
                                [shape]="'keyboard_arrow_down'"
                                [cssHeight]="'18px'"
                                [cssWidth]="'40px'"
                                (click)="scrollImagesDown($event)"
                                [disabled]="!canScrollImagesDown"
                            >
                            </app-icon>
                        </div>
                    </div>

                    <app-zoom-panel
                        [zoomValue]="zoomValue"
                        [minValue]="zoomMinValue"
                        [zoomStep]="zoomStep"
                        (zoomedIn)="zoom($event)"
                        (zoomedOut)="zoom($event)"
                        [titleZoomOut]="'MOUSEOVER.ZOOM_OUT' | translate"
                        [titleZoomIn]="'MOUSEOVER.ZOOM_IN' | translate"
                    >
                    </app-zoom-panel>
                </div>
                <div class="selected-contours-image">
                    <div *ngIf="isLoadingPaperImage | async" class="paper-image-loading-indicator">
                        <app-spinner
                            class="segmentation-result-spinner"
                            type="fadingCircle"
                            color="grey"
                        ></app-spinner>
                    </div>
                    <app-detection-result-canvas
                        *ngIf="(isLoadingPaperImage | async) === false"
                        [backgroundImage]="paperImageChanged | async"
                        [segmentationResult]="contourDetectionResultChanged"
                        [editMode]="currentEditMode"
                        [selectionRadius]="selectionRadiusChanged | async"
                        (scaleFactorChanged)="scaleFactorChanged($event)"
                        (regionsSelected)="selectRegions($event)"
                    >
                    </app-detection-result-canvas>
                </div>
            </div>
        </div>
    </div>
</app-dialog-content>
