import { Observable } from 'rxjs';

import { CanvasService } from '../../canvas/canvas.service';
import { CanvasContour } from '../../canvas/contour/contour-items-interfaces';
import { FoamEditorService } from '../../foam-editor.service';
import { SimpleDragEvent } from '../event-helpers';
import { Rectangle2D } from '../geom/rectangle2D';

import { Tool } from './tool';

export class SelectAreaTracker extends Tool {
    private rubberBandBound: Rectangle2D = new Rectangle2D(0, 0, 0, 0);
    private canvasService: CanvasService;
    private selectedContours: Set<CanvasContour> = new Set();

    constructor(foamEditorService: FoamEditorService) {
        super(foamEditorService);
        this.canvasService = this.foamEditorService.getCanvasService();
    }

    handleMouseDown(event: MouseEvent): void {
        // DO NOT call preventDefault here otherwise no children of the canvas will be able to
        // receive focus

        this.rubberBandBound.x = 0;
        this.rubberBandBound.y = 0;
        this.rubberBandBound.width = 0;
        this.rubberBandBound.height = 0;

        if (!event.shiftKey && !event.ctrlKey && !this.isScrollbarClicked(event)) {
            this.canvasService.clearSelection();
        }
        this.selectedContours.clear();
    }

    handleMouseMove(event: MouseEvent): void {}

    handleMouseUp(event: MouseEvent): void {
        this.canvasService.drawRubberBand({
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            visible: false
        });
        this.selectedContours.clear();
    }

    handleMouseDrag(event: SimpleDragEvent): void {
        this.rubberBandBound.width = Math.abs(event.startX - event.x);
        this.rubberBandBound.height = Math.abs(event.startY - event.y);

        const xyToCanvas = this.canvasService.clientXYToCanvas(
            Math.min(event.startX, event.x),
            Math.min(event.startY, event.y)
        );
        this.rubberBandBound.x = xyToCanvas.x;
        this.rubberBandBound.y = xyToCanvas.y;

        this.canvasService.drawRubberBand({
            x: this.rubberBandBound.x,
            y: this.rubberBandBound.y,
            width: this.rubberBandBound.width,
            height: this.rubberBandBound.height,
            visible: true
        });

        this.selectContours();
    }

    private selectContours() {
        const toRemoveContours: Set<CanvasContour> = new Set(this.selectedContours);
        const newSelectContours: CanvasContour[] = this.canvasService.findContourWithin(
            this.rubberBandBound.clone()
        );

        newSelectContours.forEach(contour => {
            toRemoveContours.delete(contour);
        });

        this.selectedContours = new Set(newSelectContours);
        this.canvasService.removeFromSelection(Array.from(toRemoveContours));
        this.canvasService.addToSelection(newSelectContours);
    }

    getToolStarted(): Observable<any> {
        return undefined;
    }

    handleDbClick(event: MouseEvent): void {}

    private isScrollbarClicked(event: MouseEvent): boolean {
        const { width, height } = this.canvasService.getCanvasViewportBounds().innerBounds;
        const xyToCanvasViewport = this.canvasService.clientXYToCanvasViewport(event.x, event.y);
        return xyToCanvasViewport.x > width || xyToCanvasViewport.y > height;
    }
}
