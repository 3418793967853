import { ModelMapper } from './models/model.mapper';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BackendService } from './backend/backend.service';
import { BehaviorSubject, EMPTY, ReplaySubject } from 'rxjs';
import { ImageContourModel, ImageContourType } from './models/image-contour.model';
import * as i0 from "@angular/core";
import * as i1 from "./backend/backend.service";
/**
 * This class stores all image contours available to the user.
 * All UI components should only interact with this class to fetch contours and
 * never use the server API directly (e.g. {@class BackendService}
 */
export class ConfiguratorStoreService {
    constructor(backendService) {
        this.backendService = backendService;
        this.userContourItems = new BehaviorSubject([]);
        this.producerContourItems = new BehaviorSubject([]);
        this.initialDataLoaded = new ReplaySubject(1);
        this.teilVersenkung = false;
        this.filterProducerName = new BehaviorSubject([]);
        this.filterToolTypeName = new BehaviorSubject([]);
        this.updateImageContour = (updatedContour) => {
            const contourIndex = this.userContourItems
                .getValue()
                .findIndex(value => value.id === updatedContour.id);
            if (contourIndex !== -1) {
                const allImage = this.userContourItems.getValue();
                allImage[contourIndex] = updatedContour;
                const userTools = allImage.filter(item => item.imageContourType === ImageContourType.USER_TOOL);
                this.userContourItems.next(userTools);
            }
        };
    }
    loadFoamConfiguration(sessionRef) {
        this.sessionRef = sessionRef;
        const startParamsRequest = this.backendService.getStartParameters(sessionRef);
        // requests need to be chained! because "getStartparameters"-Response sets the correct cookie value!
        startParamsRequest.subscribe(startParam => {
            this.startParameters = startParam;
            this.price = this.startParameters.price;
            this.vat = this.startParameters.vat;
            this.languageSettings = {
                languageKey: this.startParameters.languageKey,
                languageContent: this.startParameters.languageContent
            };
            this.foamConfig = this.startParameters.foamConfig;
            this.teilVersenkung = this.startParameters.foamConfig.foamContour.teilVersenkung;
            const filterOptionRequest = this.backendService.getAllToolFilter();
            filterOptionRequest.subscribe(data => {
                this.filterProducerName.next(data.producerName);
                this.filterToolTypeName.next(data.toolTypeName);
            });
            const toolsRequest = this.backendService.getAllTool();
            toolsRequest.subscribe(allTool => {
                this.imageContours = allTool;
                const userTools = this.imageContours.filter(item => item.imageContourType === ImageContourType.USER_TOOL);
                const producerTools = this.imageContours.filter(item => item.imageContourType === ImageContourType.PRODUCER_TOOL);
                this.initialDataLoaded.next(true);
                // Order matter here: for avoiding tools browser components to receive tools
                // and call foamService/canvasService methods (e.g. canvasService#isContourDepthValid)
                // is before these services were fully initialized
                this.userContourItems.next(userTools);
                this.producerContourItems.next(producerTools);
            });
        });
    }
    isTeilversenkungActive() {
        return this.teilVersenkung;
    }
    addNewUserTool(imageContourModel) {
        const value = this.userContourItems.value;
        value.push(imageContourModel);
        this.userContourItems.next(value);
    }
    getLanguageSettings() {
        return this.languageSettings;
    }
    getFoamConfiguration() {
        return this.foamConfig;
    }
    getPrice() {
        return this.price;
    }
    getVat() {
        return this.vat;
    }
    getToolFilterToolTypeName() {
        return this.filterToolTypeName.asObservable();
    }
    getToolFilterProducerName() {
        return this.filterProducerName.asObservable();
    }
    getUserItems() {
        return this.userContourItems.asObservable();
    }
    getPartnerItems() {
        return this.producerContourItems.asObservable();
    }
    getSessionRef() {
        return this.sessionRef;
    }
    getImageContourById(id) {
        return this.imageContours.find((c) => c.id === id);
    }
    edit(contour) {
        const contourForSave = BackendService.deleteImageAttributes(new ImageContourModel(contour));
        return this.backendService
            .saveUserTool(ModelMapper.imageContourModelToJSON(contourForSave))
            .pipe(tap(this.updateImageContour));
    }
    delete(contour) {
        if (!contour.isRemovable) {
            return EMPTY;
        }
        return this.backendService.deleteTool(contour).pipe(map(result => {
            if (result === true) {
                let index = -1;
                this.userContourItems.getValue().forEach((value, innerIndex) => {
                    if (value.id != null && value.id === contour.id) {
                        index = innerIndex;
                    }
                });
                if (index >= 0) {
                    const newList = this.userContourItems.getValue();
                    newList.splice(index, 1);
                    this.userContourItems.next(newList);
                    return true;
                }
            }
            return false;
        }));
    }
    saveFoamConfig(contours) {
        return this.backendService.editAllContourForCanvas(contours).pipe(map(allUpdatedContour => {
            return ModelMapper.foamConfigurationToJSON(this.foamConfig.id, this.foamConfig.configurationName, this.foamConfig.foamContour, allUpdatedContour);
        }), mergeMap((jsonData) => {
            return this.backendService.saveFoamConfig(jsonData);
        }));
    }
    /**
     * Marks the specified user contours as not removable, while marking other contours as removable
     *
     * @param contoursIds
     */
    updateUserContoursRemovability(contoursIds) {
        const userTools = this.userContourItems.getValue().map((contour) => {
            contour.isRemovable = contoursIds.findIndex(c => c === contour.id) === -1;
            return contour;
        });
        this.userContourItems.next(userTools);
    }
}
ConfiguratorStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfiguratorStoreService_Factory() { return new ConfiguratorStoreService(i0.ɵɵinject(i1.BackendService)); }, token: ConfiguratorStoreService, providedIn: "root" });
