import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { StandardContourCreateAction } from '../../../actions/contour-create.action';
import { CanvasService } from '../../../canvas/canvas.service';
import { DroppableContainerEvent } from '../../../../droppable/droppable-container.directive';
import { FoamEditorService } from '../../../foam-editor.service';
import { DialogService } from '../../../../dialog/dialog.service';
import { AccordionNavigator } from '../../expansion-panel/accordion-navigator.service';
import { AccordionDirective } from '../../expansion-panel/accordion.directive';
import { ExpansionPanelComponent } from '../../expansion-panel/expansion-panel.component';
import { AccordionRibbonPanelContent, RibbonPanelContent } from '../ribbon-panel-content';

import { PreviewContourInfo, PreviewContourService } from './preview-contour.service';
import { FoamLayerDirective } from '../../../canvas/layers/foam-layer.directive';

@Component({
    selector: 'app-grip-text-contour-panel',
    templateUrl: './grip-text-contour-panel.component.html',
    styleUrls: ['./grip-text-contour-panel.component.scss'],
    providers: [AccordionNavigator]
})
export class GripTextContourPanelComponent extends RibbonPanelContent(AccordionRibbonPanelContent())
    implements OnInit, OnDestroy, AfterViewInit {
    dropZoneId = 'workspace-dropzone';

    panelTitle = 'Text, Formen und Griffe';
    panelIndex = 2;
    private strokeWidth = 2;
    panelId = 'grip-text';

    @ViewChild(AccordionDirective, { static: false })
    readonly appAccordion;

    @ViewChildren(ExpansionPanelComponent)
    expansionPanels: QueryList<ExpansionPanelComponent>;

    private onDestroy = new Subject();
    private currentPreviewContour: PreviewContourInfo;
    private canvasContourCreateAction: StandardContourCreateAction;

    constructor(
        accordionNavigator: AccordionNavigator,
        private translate: TranslateService,
        private foamEditorService: FoamEditorService,
        private canvasService: CanvasService,
        private previewContourService: PreviewContourService,
        private dialogService: DialogService
    ) {
        super();
        this.accordionNavigator = accordionNavigator;
        this.canvasContourCreateAction = new StandardContourCreateAction(
            foamEditorService,
            dialogService
        );
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    ngOnInit() {
        const TRANSLATE_KEY = 'SHAPE_HANDLE_TEXT_RIBBON.MAIN_MENU';
        this.translate.onTranslationChange.subscribe(() => {
            this.translate.stream([TRANSLATE_KEY]).subscribe(res => {
                this.panelTitle = res[TRANSLATE_KEY];
            });
        });
        this.translate.stream([TRANSLATE_KEY]).subscribe(res => {
            this.panelTitle = res[TRANSLATE_KEY];
        });
    }

    onDragStart($containerEvent: DroppableContainerEvent) {
        const mirrorComponent = $containerEvent.container.mirrorComponent;
        const itemType = $containerEvent.event.draggableTarget.elementRef.droppableItemType;
        if (!itemType) {
            console.error('DroppableItemType is not specified');
            return;
        }

        this.currentPreviewContour = this.previewContourService.getPreviewContour(itemType);
        if (this.currentPreviewContour) {
            mirrorComponent.setPreviewContour(this.currentPreviewContour);
        }
    }

    onDragEnter($containerEvent: DroppableContainerEvent) {
        const mirrorComponent = $containerEvent.container.mirrorComponent;
        const itemType = $containerEvent.event.draggableTarget.elementRef.droppableItemType;
        if (!itemType) {
            console.error('DroppableItemType is not specified');
            return;
        }

        if (itemType === 'TEXT') {
            const { width, height } = (<SVGGraphicsElement>(
                this.currentPreviewContour.contourSvg
            )).getBBox();
            mirrorComponent.width = width;
            mirrorComponent.height = height;
        } else {
            mirrorComponent.width = this.currentPreviewContour.svgRootWidth;
            mirrorComponent.height = this.currentPreviewContour.svgRootHeight;
        }
    }

    onDragDrop($containerEvent: DroppableContainerEvent) {
        const itemType = $containerEvent.event.draggableTarget.elementRef.droppableItemType;
        if (!itemType) {
            console.error('DroppableItemType is not specified');
            return;
        }

        const mirrorComponent = $containerEvent.container.mirrorComponent;
        const x = $containerEvent.event.x - mirrorComponent.width / 2;
        const y = $containerEvent.event.y - mirrorComponent.height / 2;

        this.canvasContourCreateAction.execute({ type: itemType, x: x, y: y });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.onDestroy.next();
    }
}
