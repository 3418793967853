import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CanvasService } from 'src/app/foam-editor/canvas/canvas.service';
import { ImageContourModel, ProducerImageContourModel } from 'src/app/models/image-contour.model';

import { DialogService } from '../../../../dialog/dialog.service';
import { ImageContourSearchService, SearchItemType } from '../../../image-contour-search.service';
import { ImageContoursBrowserComponent } from './contour-browser/image-contours-browser.component';
import { AccordionNavigator } from '../../expansion-panel/accordion-navigator.service';
import { AccordionDirective } from '../../expansion-panel/accordion.directive';
import { ExpansionPanelComponent } from '../../expansion-panel/expansion-panel.component';
import { UploadDialogComponent } from '../../../dialogs/upload-dialog/upload-dialog.component';
import { AccordionRibbonPanelContent, RibbonPanelContent } from '../ribbon-panel-content';
import { FoamEditorService } from '../../../foam-editor.service';
import { SelectControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-tools-contour-panel',
    templateUrl: './tools-contour-panel.component.html',
    styleUrls: ['./tools-contour-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AccordionNavigator, ImageContourSearchService]
})
export class ToolsContourPanelComponent extends RibbonPanelContent(AccordionRibbonPanelContent())
    implements OnInit, OnDestroy, AfterViewInit {
    panelTitle = 'Werkzeuge';
    panelIndex = 1;
    panelId = 'tools';

    @ViewChild(AccordionDirective, { static: false })
    readonly appAccordion: AccordionDirective;

    @ViewChild('userContourBrowser', { static: false })
    readonly userContourBrowser: ImageContoursBrowserComponent;

    @ViewChild('partnerContourBrowser', { static: false })
    readonly partnerContourBrowser: ImageContoursBrowserComponent;

    @ViewChild('userSearchField', { static: true })
    private readonly userSearchField: ElementRef<HTMLInputElement>;

    @ViewChild('partnerSearchField', { static: true })
    private readonly partnerSearchField: ElementRef<HTMLInputElement>;

    @ViewChildren(ExpansionPanelComponent)
    readonly expansionPanels: QueryList<ExpansionPanelComponent>;

    private userContourItemsSubject: BehaviorSubject<ReadonlyArray<ImageContourModel>>;
    readonly userContourItems: Observable<ReadonlyArray<ImageContourModel>>;

    private partnerContourItemsSubject: BehaviorSubject<ReadonlyArray<ImageContourModel>>;
    readonly partnerContourItems: Observable<ReadonlyArray<ImageContourModel>>;

    private searchPartnerItemSubject: Subject<string> = new Subject();
    private searchUserItemSubject: Subject<string> = new Subject();
    private onDestroy = new Subject<void>();

    selectedProducer: string;

    filterProducer: FilterOption[];
    filterToolType: FilterOption[];

    private filterQuery: FilterQuery = {
        producerName: null,
        toolTypeName: null
    };

    private unfilteredPartnerItems: ReadonlyArray<ProducerImageContourModel>;

    constructor(
        accordionNavigator: AccordionNavigator,
        private canvasService: CanvasService,
        private foamEditorService: FoamEditorService,
        private searchService: ImageContourSearchService,
        private translate: TranslateService,
        private dialogService: DialogService
    ) {
        super();

        this.userContourItemsSubject = new BehaviorSubject<ReadonlyArray<ImageContourModel>>([]);
        this.userContourItems = this.userContourItemsSubject.asObservable();

        this.partnerContourItemsSubject = new BehaviorSubject<ReadonlyArray<ImageContourModel>>([]);
        this.partnerContourItems = this.partnerContourItemsSubject.asObservable();

        this.accordionNavigator = accordionNavigator;

        this.filterProducer = [];
        this.filterToolType = [];
    }

    private getSearchPartnerItemQuery(): Observable<string> {
        return this.searchPartnerItemSubject.asObservable();
    }

    private getSearchUserItemQuery(): Observable<string> {
        return this.searchUserItemSubject.asObservable();
    }

    ngOnInit() {
        this.translate.stream('TOOL_RIBBON.MAIN_MENU').subscribe((res: string) => {
            this.panelTitle = res;
        });
        this.foamEditorService.getToolFilterProducerName().subscribe(items => {
            this.filterProducer = items.map(item => new FilterOption(item));
        });

        this.foamEditorService.getToolFilterToolTypeName().subscribe(items => {
            this.filterToolType = items.map(item => new FilterOption(item));
        });
        this.foamEditorService
            .getPartnerItems()
            .subscribe((items: ReadonlyArray<ProducerImageContourModel>) => {
                // we don't need to apply search since partner cannot be deleted or added
                // without refreshing the entire page
                this.updatePartnerTools(items);
            });
        this.foamEditorService.getUserItems().subscribe((userItems: ImageContourModel[]) => {
            // re-apply search
            if (this.userSearchField.nativeElement.value.length > 0) {
                this.searchService
                    .oneShotSearch(
                        this.userSearchField.nativeElement.value,
                        SearchItemType.USER_CONTOURS
                    )
                    .subscribe(items => {
                        this.userContourItemsSubject.next(items);
                    });
            } else {
                this.userContourItemsSubject.next(userItems.sort(sortByTitle));
            }
        });

        this.foamEditorService
            .getPartnerItems()
            .subscribe((items: ReadonlyArray<ProducerImageContourModel>) => {
                // we don't need to apply search since partner cannot be deleted or added
                // without refreshing the entire page
                this.updatePartnerTools(items);
            });
    }

    // FIXME: must be call explicitly when build for production. Maybe we should
    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        // search changes should happen when the DOM is ready
        this.searchService
            .search(this.getSearchPartnerItemQuery(), SearchItemType.PARTNER_CONTOURS)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(items => {
                this.updatePartnerTools(items);
                this.partnerContourBrowser.scrollViewport.scrollToTop();
            });

        this.searchService
            .search(this.getSearchUserItemQuery(), SearchItemType.USER_CONTOURS)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(items => {
                this.userContourItemsSubject.next(items);
            });
    }

    private updatePartnerTools(items: ReadonlyArray<ProducerImageContourModel>) {
        this.partnerContourItemsSubject.next(items);
        this.unfilteredPartnerItems = items;
        this.applyFilters();
    }

    private applyFilters() {
        const newPartnerContourItems = this.unfilteredPartnerItems
            .filter(x => {
                let filterIn = true;
                if (this.filterQuery.producerName) {
                    filterIn =
                        x.producerName.toUpperCase() ===
                        this.filterQuery.producerName.toUpperCase();
                }

                if (this.filterQuery.toolTypeName) {
                    filterIn =
                        filterIn &&
                        x.toolTypeName.toUpperCase() ===
                            this.filterQuery.toolTypeName.toUpperCase();
                }
                return filterIn;
            })
            .sort((a, b) => {
                return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
            });
        this.partnerContourItemsSubject.next(newPartnerContourItems);
    }

    filterByProducer(value: string) {
        this.filterQuery.producerName = value && value.length > 0 ? value : null;
        this.applyFilters();
    }

    filterByToolType(value: string) {
        this.filterQuery.toolTypeName = value && value.length > 0 ? value : null;
        this.applyFilters();
    }

    // selectDetectedPaperImage
    ngOnDestroy() {
        this.onDestroy.next();
    }

    onSearchPartnerItem(eventOrQuery: string | KeyboardEvent): void {
        if (eventOrQuery instanceof KeyboardEvent) {
            this.searchPartnerItemSubject.next((event.target as HTMLInputElement).value);
        } else if (typeof eventOrQuery === 'string' || typeof eventOrQuery === 'number') {
            this.searchPartnerItemSubject.next(eventOrQuery);
        }
    }

    onSearchUserItem(eventOrQuery: string | KeyboardEvent) {
        if (eventOrQuery instanceof KeyboardEvent) {
            this.searchUserItemSubject.next((event.target as HTMLInputElement).value);
        } else if (typeof eventOrQuery === 'string' || typeof eventOrQuery === 'number') {
            this.searchUserItemSubject.next(eventOrQuery);
        }
    }

    openUploadDialog() {
        this.dialogService.openDialog(UploadDialogComponent).afterClosed.subscribe(() => {});
    }
}

const sortByTitle = (a, b) => {
    return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
};

export class FilterOption {
    value: string;
    title: string;
    constructor(stringKey: string) {
        this.value = stringKey;
        this.title = stringKey;
    }
}

export interface FilterQuery {
    producerName: string | null;
    toolTypeName: string | null;
}
