<app-dialog-header>
    <h1 class="app-dialog-title">{{ 'CONTOUR_INFO_DIALOG.TITLE' | translate }}</h1>
</app-dialog-header>
<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <div class="dialog-row">
                    <span>{{ 'CONTOUR_INFO_DIALOG.MANUFACTURER' | translate }}</span>
                    {{ partnerImageContour.producerName }}
                </div>
                <div class="dialog-row">
                    <span>{{ 'CONTOUR_INFO_DIALOG.TYPE' | translate }}</span>
                    {{ partnerImageContour.toolTypeName }}
                </div>
                <div class="dialog-row">
                    <span>{{ 'CONTOUR_INFO_DIALOG.NAME' | translate }}</span>
                    {{ partnerImageContour.title }}
                </div>
                <div class="dialog-row">
                    <span>{{ 'CONTOUR_INFO_DIALOG.DIMENSION' | translate }}</span>
                    {{ partnerImageContour.width }}x{{ partnerImageContour.height }} x{{
                        partnerImageContour.depth
                    }}
                    {{ 'CONTOUR_INFO_DIALOG.DIMENSION_SUFFIX' | translate }}
                </div>
                <div *ngIf="partnerImageContour.url" class="dialog-row">
                    <span>{{ 'CONTOUR_INFO_DIALOG.PRODUCT_DETAILS' | translate }}</span>
                    <a [attr.href]="partnerImageContour.url" target="_blank">{{
                        'CONTOUR_INFO_DIALOG.LINK_TITLE' | translate
                    }}</a>
                </div>

                <div class="contour-image-container">
                    <img
                        *ngIf="partnerImageContour.image != null"
                        [attr.src]="partnerImageContour.image"
                    />
                </div>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'secondary'"
            iconAlign="horizontal"
            textPosition="after"
            buttonSize="normal"
            (click)="close()"
        >
            <app-icon svgWidth="24" svgHeight="24" shape="icon-close" iconSize="small"> </app-icon>
            {{ buttonCloseText }}
        </app-button>
    </div>
</app-dialog-footer>
