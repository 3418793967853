import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { createGroup, createPath } from '../../../utils/dom-utils';
import { CanvasElementChangedEvent } from '../canvas.component';
import { SelectionChangedData } from '../canvas.service';
import { ContourElementRefs } from '../contour-element-refs';
import { ContourElementType } from '../contour/contour-element-type';
import {
    ContourChangeData,
    ContourItemsRemoved,
    ContourSelectionData,
    CuttableContourDrawData
} from '../contour/contour-items-interfaces';
import { GroupContourElementRefs } from '../group-contour-element-refs';
import { LayerElementStoreService } from '../layer-element-store.service';

import { CanvasContourLayer } from './canvas-contour-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';
import { CanvasLayerWithSubscriptionMixin } from './destroyable-layer-mixin';
import { ItemsLayerDirective } from './items-layer.directive';

declare var Snap: any;

export const CLASS_NAME_ITEM_SELECTED = 'item-selected';

@Directive({ selector: '[appSafetyMarginLayer]' })
export class SafetyMarginLayerDirective extends CanvasLayerMixin(CanvasLayerWithSubscriptionMixin())
    implements CanvasContourLayer<Snap.Paper> {
    @Output()
    contourDrawn: EventEmitter<CanvasElementChangedEvent> = new EventEmitter();

    element: Snap.Paper;

    /**
     * @deprecated use element instead
     */
    svgElement: any;

    parentLayer: ItemsLayerDirective;

    constructor(
        private elementRef: ElementRef,
        private layerElementStore: LayerElementStoreService
    ) {
        super();
        // TODO remove this.svgElement
        this.svgElement = this.element = Snap(elementRef.nativeElement);
    }

    /**
     * @deprecated
     * @param contourData
     */
    addContourItem(contourData: CuttableContourDrawData): CanvasElementChangedEvent {
        return undefined;
    }

    addSingleContourItem(contourData: CuttableContourDrawData) {
        /*  const marginPath = this.createContourElements(contourData);

          const contourRefs =
          this.layerElementStore.addSingleContourElements(contourData.contourId, {
          marginPath: marginPath,
          });

          contourRefs.updateMarginTransformedData();
          if (!this.showContourSafetyMargin) {
              marginPath.node.style.visibility = 'hidden';
          }

          return {
              contourId: contourData.contourId,
              margin: contourRefs.margin,
          }; */
    }

    createContourElements(contourData: CuttableContourDrawData) {
        const marginGroup = createGroup(
            this.element,
            contourData.itemProps.classNameMarginGroup + '-' + contourData.contourId.toString()
        );

        let transformString: string;
        if (contourData.matrix) {
            transformString = contourData.matrix.toTransformString();
        }

        return createPath(
            contourData.marginPathString,
            marginGroup,
            {
                fill: contourData.itemProps.marginFill,
                opacity: contourData.itemProps.marginOpacity,
                id: contourData.itemProps.marginPathId,
                transform: transformString
            },
            contourData.itemProps.classNameMarginPath
        );
    }

    /*
    addGroupContourItem(groupContourData: GroupContourDrawData):
    CanvasElementChangedEvent { const groupContourRefs =
            (this.layerElementStore.getContourElements(
                groupContourData.contourId
            ) as GroupContourElementRefs) ||
            new GroupContourElementRefs(groupContourData.contourId);

        groupContourRefs.children = new Map();
        groupContourData.children.forEach(contourData => {
            if (contourData instanceof CuttableContourDrawData) {
                const marginPath = this.createContourElements(contourData);
                groupContourRefs.addChild({
                    contourId: contourData.contourId,
                    elements: {
                        marginPath: marginPath,
                    },
                });
            }
        });

        if (groupContourRefs.children.size === 0) {
            groupContourRefs.children = undefined;
        }

        this.layerElementStore.addContourElements(groupContourData.contourId,
    groupContourRefs); groupContourRefs.updateTransformData(this.scaleFactor,
    this.parentLayer.layerOffset); return groupContourRefs.getTransformedData();

        //return this.createGroupChangedEvent(groupContourData.contourId,
    groupContourRefs);
    } */
    /*
    private createGroupChangedEvent(
        contourId: string,
        contourRefs: GroupContourElementRefs
    ): CanvasElementChangedEvent {
        const changedEvents: { [contourId: number]: CanvasElementChangedEvent }
    = {};

        const minPt = new Point2D(Number.MAX_VALUE, Number.MAX_VALUE);
        const maxPt = new Point2D(Number.MIN_VALUE, Number.MIN_VALUE);
        const minTransPt = new Point2D(Number.MAX_VALUE, Number.MAX_VALUE);
        if (contourRefs.children && contourRefs.children.size > 0) {
            contourRefs.children.forEach((childElementRef, childId) => {
                childElementRef.updateTransformData();

                maxPt.x = Math.max(maxPt.x,
    childElementRef.margin.globalBBox.x2); maxPt.y = Math.max(maxPt.y,
    childElementRef.margin.globalBBox.y2);

                minPt.x = Math.min(minPt.x,
    childElementRef.margin.globalBBox.x); minPt.y = Math.min(minPt.y,
    childElementRef.margin.globalBBox.y);

                // changedEvent.globalBBox.x removes the margin size from
                minTransPt.x = Math.min(
                    minTransPt.x,
                    (childElementRef.margin.globalMatrix as any).e +
                        childElementRef.margin.localBBox.x * this.scaleFactor
                );
                minTransPt.y = Math.min(
                    minTransPt.y,
                    (childElementRef.margin.globalMatrix as any).f +
                        childElementRef.margin.localBBox.y * this.scaleFactor
                );
            });

            const globalBounds = new Rectangle2D(
                minPt.x,
                minPt.y,
                maxPt.x - minPt.x,
                maxPt.y - minPt.y
            );

            const localBounds = new Rectangle2D(0, 0, globalBounds.width,
    globalBounds.height);

            const localMatrix = Snap.matrix(1, 0, 0, 1, minPt.x, minPt.y);

            const globalMatrix = Snap.matrix(
                this.scaleFactor,
                0,
                0,
                this.scaleFactor,
                minTransPt.x,
                minTransPt.y
            );

            return {
                contourId: contourId,
                margin: {
                    globalMatrix: globalMatrix,
                    localMatrix: localMatrix,
                    localBBox: localBounds,
                    globalBBox: globalBounds,
                },
                children: changedEvents,
            };
        }
    } */
    /*
    private createChangedEvent(
        contourId: string,
        elementPath: Snap.Element
    ): CanvasElementChangedEvent {
        const localBbox = Rectangle2D.fromObject((elementPath.node as
    any).getBBox()); const { globalMatrix, localMatrix } =
    transformMatrix(elementPath.node as any, localBbox); return { contourId:
    contourId, margin: { globalMatrix: globalMatrix, localMatrix: localMatrix,
                localBBox: localBbox,
                //TODO use getGlobalBBox(); Snap getBBox is too slow
                globalBBox: Rectangle2D.fromObject(
                    elementPath.getBBox()
                ) ,
            },
        };
    } */

    changeContourItem(contourData: ContourChangeData): CanvasElementChangedEvent | void {
        /*   const contourRefs =
           this.layerElementStore.getContourElements(contourData.contourId); if
           (!contourRefs) { return;
           }

           if (contourRefs instanceof GroupContourElementRefs) {
               // Already move to contour-items-layer
               // this.updateGroupElements(contourData, contourRefs);
               // return this.createGroupChangedEvent(contourData.contourId,
           contourRefs); } else { const contourShapeChanged =
           this.updateSingleElements(contourData, contourRefs); if
           (contourShapeChanged) { contourRefs.updateMarginTransformedData();
                   return {
                       contourId : contourData.contourId,
                       margin : contourRefs.margin,
                   };
               }
           } */
    }

    private updateGroupElements(contourData: ContourChangeData, elements: GroupContourElementRefs) {
        if (contourData.children && contourData.children.length > 0) {
            contourData.children.forEach(data => {
                const childElement = elements.children.get(data.contourId);
                const marginPath = childElement[ContourElementType.MARGIN_PATH];
                if (marginPath) {
                    marginPath.node.setAttribute('transform', data.matrix.toString());
                    marginPath.attr({
                        x: data.x,
                        y: data.y
                    });
                }
            });
        }
    }
    /*
    private updateSingleElements(contourData: ContourChangeData, elements: ContourElementRefs) {
        const { matrix } = contourData;

        const marginPath = elements[ContourElementType.MARGIN_PATH];
        let contourShapeChanged = false;
        if (marginPath) {
            if (contourData.marginPathString) {
                marginPath.node.setAttribute('d', contourData.marginPathString);
                contourShapeChanged = true;
            }
            if (matrix) {
                marginPath.node.setAttribute('transform', contourData.matrix.toString());
                contourShapeChanged = true;
            }
        }

        return contourShapeChanged;
    }

    private setVisible(marginPath, visible: {}) {
        if (marginPath) {
            marginPath.node.style.display =
                visible[ContourElementType.MARGIN_PATH] ? 'inline' : 'none';
        }
    } */

    selectContourItems(selectionData: SelectionChangedData) {
        const { itemsToSelect, itemsToDeselect } = selectionData;

        if (itemsToSelect) {
            itemsToSelect.forEach((itemData: ContourSelectionData) => {
                const contourRefs = this.layerElementStore.getContourElements(itemData.contourId);
                if (contourRefs instanceof GroupContourElementRefs && contourRefs.children) {
                    contourRefs.children.forEach(child => {
                        this.changeSelection(child, true);
                    });
                } else if (contourRefs instanceof ContourElementRefs) {
                    this.changeSelection(contourRefs, true);
                }
            });
        }

        if (itemsToDeselect) {
            itemsToDeselect.forEach(itemData => {
                const contourRefs = this.layerElementStore.getContourElements(itemData.contourId);
                if (contourRefs instanceof GroupContourElementRefs && contourRefs.children) {
                    contourRefs.children.forEach(child => {
                        this.changeSelection(child, false);
                    });
                } else if (contourRefs instanceof ContourElementRefs) {
                    this.changeSelection(contourRefs, false);
                }
            });
        }
    }

    private changeSelection(pathRefs: ContourElementRefs, select: boolean): void {
        if (!pathRefs) {
            return;
        }

        const itemPath = pathRefs[ContourElementType.MARGIN_PATH];
        if (itemPath) {
            if (select) {
                itemPath.addClass(CLASS_NAME_ITEM_SELECTED);
            } else {
                itemPath.removeClass(CLASS_NAME_ITEM_SELECTED);
            }
        }
    }

    removeContourItem(contourData: ContourItemsRemoved) {
        /*  contourData.contourIds.forEach(contourId => {
            this.layerElementStore.removeContourElements(contourId);
        }); */
    }

    private _showContourSafetyMargin: boolean = true;

    @Input()
    get showContourSafetyMargin(): boolean {
        return this._showContourSafetyMargin;
    }

    set showContourSafetyMargin(newValue: boolean) {
        if (this._showContourSafetyMargin !== newValue) {
            this._showContourSafetyMargin = newValue;
            // const displayValue = newValue ? 'visible' : 'hidden';

            const elementHolders = Object.values(this.layerElementStore.contourElementHolders);
            if (elementHolders) {
                elementHolders.forEach(holder => {
                    const newContourData = {
                        contourId: holder.contourId,
                        marginVisibility: newValue
                    };
                    holder.update(newContourData);
                });
            } else {
                console.log('element holder undefined');
            }
        }
    }

    /*
    private setMarginDisplay(marginElem: Snap.Element, displayValue: string) {
        if (marginElem) {
            marginElem.node.style.visibility = displayValue;
        }
    } */
}
