<div class="main">
    <app-foam-editor></app-foam-editor>
</div>

<div *ngIf="isLoading" class="page-loading">
    <div class="page-loading--center-middle">
        <p class="page-loading-message">Loading</p>
        <app-spinner type="threeBounce" color="blue"></app-spinner>
    </div>
</div>
