import { Rectangle2D } from '../../shared/geom/rectangle2D';

declare var Snap: any;

/**
 * from http://stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect
 * @param x1
 * @param y1
 * @param x2
 * @param y2
 * @param x3
 * @param y3
 * @param x4
 * @param y4
 * @returns {boolean}
 */
export function intersectLineLine(x1, y1, x2, y2, x3, y3, x4, y4) {
    const s1_x = x2 - x1;
    const s1_y = y2 - y1;

    const s2_x = x4 - x3;
    const s2_y = y4 - y3;

    const s = (-s1_y * (x1 - x3) + s1_x * (y1 - y3)) / (-s2_x * s1_y + s1_x * s2_y);
    const t = (s2_x * (y1 - y3) - s2_y * (x1 - x3)) / (-s2_x * s1_y + s1_x * s2_y);
    return s >= 0 && s <= 1 && t >= 0 && t <= 1;
}

function getDebugCanvas(): Snap.Paper {
    let debugElem: Snap.Paper;
    const debugNode = window.document.querySelector('.debug-col');
    if (!debugNode) {
        debugElem = Snap(window.document.querySelector('.selection-layer')).g() as Snap.Paper;
        debugElem.addClass('debug-col');
    } else {
        debugElem = Snap(debugNode) as Snap.Paper;
    }
    return debugElem;
}

export function checkLinesIntersection(
    polygonLines: Array<PolygonSegment>,
    polygonLines2: Array<PolygonSegment>
): boolean {
    const plLength1 = polygonLines.length;
    const plLength2 = polygonLines2.length;

    for (let i = 0; i < plLength1; i++) {
        const line1: PolygonSegment = polygonLines[i];
        for (let j = 0; j < plLength2; j++) {
            const line2: PolygonSegment = polygonLines2[j];
            const intersect = intersectLineLine(
                line1.startX,
                line1.startY,
                line1.endX,
                line1.endY,
                line2.startX,
                line2.startY,
                line2.endX,
                line2.endY
            );
            if (intersect) {
                return true;
            }
        }
    }
    return false;
}
export function pathsIntersect(
    pathBounds: Rectangle2D,
    otherPathBounds: Rectangle2D,
    pathPolygonLines: Array<PolygonSegment>,
    otherPathPolygonLines: Array<PolygonSegment>
): boolean {
    // check if one BBox contains another box or if their lines intersect
    if (!Snap.path.isBBoxIntersect(pathBounds, otherPathBounds)) {
        return false;
    }

    if (checkLinesIntersection(pathPolygonLines, otherPathPolygonLines)) {
        return true;
    }

    return false;
}

export function rotatePoint(
    angleRad: number,
    pointX: number,
    pointY: number,
    centerX: number,
    centerY: number
): { x: number; y: number } {
    // Translate to origin
    pointX -= centerX;
    pointY -= centerY;

    // centerX =  centerY = 0;

    const sin = parseFloat(Math.sin(angleRad).toFixed(9));
    const cos = parseFloat(Math.cos(angleRad).toFixed(9));

    const xRotate = pointX * cos - pointY * sin;
    const yRotate = pointX * sin + pointY * cos;

    pointX = xRotate + centerX;
    pointY = yRotate + centerY;

    return {
        x: pointX,
        y: pointY
    };
}

export interface PolygonSegment {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
}
