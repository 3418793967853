import { CanvasScrollViewportDirective } from '../../../../../canvas/shared/canvas-scroll-viewport.directive';
import { Rectangle2D } from '../../../../../shared/geom/rectangle2D';
import { transformBoundingBox } from '../../../../../canvas/contour/contour-helper';
import { Observable, Subject } from 'rxjs';

declare var Snap: any;

export class PanHandler {
    private snapTransformElem: Snap.Element;
    private lastMousePos: { x: number; y: number };
    private started: boolean;
    private canvasBounds: Rectangle2D;
    private mouseMoveSubject = new Subject<SVGMatrix>();

    private mouseDownListener = (event: MouseEvent) => this.onMouseDown(event);
    private mouseMoveListener = (event: MouseEvent) => this.onMouseMove(event);
    private mouseUpListener = (event: MouseEvent) => this.onMouseUp(event);

    constructor(
        private canvas: SVGSVGElement,
        private transformSvgNode: SVGElement,
        private scrollViewport: CanvasScrollViewportDirective
    ) {
        this.snapTransformElem = Snap(transformSvgNode);
    }

    start() {
        if (this.canvas && !this.started) {
            this.started = true;
            this.canvas.addEventListener('mousedown', this.mouseDownListener);
        }
    }

    /**
     * Informs that the given transformSvgNode has been transformed.
     */
    getOnNodeTranslated(): Observable<SVGMatrix> {
        return this.mouseMoveSubject.asObservable();
    }

    private onMouseDown(event: MouseEvent): void {
        if (event.button !== 0) {
            return;
        }

        event.preventDefault();

        this.canvasBounds = this.scrollViewport.getScrollViewportBounds().outerBounds;
        this.lastMousePos = { x: event.clientX, y: event.clientY };

        // use document as mouse can go outside of the document
        document.addEventListener('mousemove', this.mouseMoveListener);
        document.addEventListener('mouseup', this.mouseUpListener);
    }

    private onMouseMove(event: MouseEvent) {
        event.preventDefault();

        const globalTx = (this.transformSvgNode as SVGGraphicsElement).getScreenCTM();
        let bbox = Rectangle2D.fromObject((this.transformSvgNode as SVGGraphicsElement).getBBox());
        bbox = Rectangle2D.fromPointsObject(transformBoundingBox(bbox, globalTx));

        const dx = event.clientX - this.lastMousePos.x;
        const dy = event.clientY - this.lastMousePos.y;

        this.lastMousePos.x = event.clientX;
        this.lastMousePos.y = event.clientY;

        if (this.canvasBounds.containsRect(bbox)) {
            return;
        }

        const tx = (this.transformSvgNode as SVGGraphicsElement).getCTM();
        // the canvasBounds contains the top and bottom border
        const isTopLeftBorderInside =
            bbox.y > this.canvasBounds.y && bbox.y2 < this.canvasBounds.y2;
        if (!isTopLeftBorderInside) {
            if (Math.round(bbox.y2 + dy) <= this.canvasBounds.y2) {
                // clamp when moving upward
                tx.f = -Math.round(bbox.height - this.canvasBounds.height);
            } else if (bbox.y2 > this.canvasBounds.y2 && bbox.y + dy >= this.canvasBounds.y) {
                // clamp when moving down
                tx.f = 0;
            } else {
                tx.f += dy;
            }
        }

        // the canvasBounds contains the left and right border
        const isLefRightBorderInside =
            bbox.x > this.canvasBounds.x && bbox.x2 < this.canvasBounds.x2;
        if (!isLefRightBorderInside) {
            // clamp when moving from left to right
            if (Math.round(bbox.x + dx) >= this.canvasBounds.x) {
                tx.e = 0;
            } else if (Math.round(bbox.x2 + dx) <= this.canvasBounds.x2) {
                // clamp when moving from right to left
                tx.e = -Math.round(bbox.width - this.canvasBounds.width);
            } else {
                tx.e += dx;
            }
        }

        const txString =
            `matrix(${tx.a.toFixed(2)},${tx.b.toFixed(2)},${tx.c.toFixed(2)},` +
            `${tx.d.toFixed(2)},${tx.e.toFixed(2)},${tx.f.toFixed(2)})`;
        this.transformSvgNode.setAttribute('transform', txString);
        this.mouseMoveSubject.next(tx);
    }

    private onMouseUp(event: MouseEvent) {
        // release document mouse event
        document.removeEventListener('mousemove', this.mouseMoveListener);
        document.removeEventListener('mouseup', this.mouseUpListener);
    }

    stop() {
        this.started = false;
        this.canvas.removeEventListener('mousedown', this.mouseDownListener);
        document.removeEventListener('mousemove', this.mouseMoveListener);
        document.removeEventListener('mouseup', this.mouseUpListener);
    }

    private clampTranslation(
        bbox: Rectangle2D,
        dx: number,
        dy: number
    ): { dx: number; dy: number } {
        if (bbox.y < 0 && bbox.y2 <= this.canvasBounds.y2) {
            dy = -Math.round(this.canvasBounds.y2 - bbox.y2);
        }

        return { dx: dx, dy: dy };
    }
}
