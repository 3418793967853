/**
 * Adopted from angular material2
 * A non-nested accordion. That is, we don't need to prevent nested expansion panels from registering
 * to the same accordion because panels are not supported. Hence, the injected AccordionDirective is declared as private instance and the
 * @Optional decorator is removed
 */

import { Directive, Input } from '@angular/core';
import { coerceBooleanProperty } from '../../../utils/coerce-boolean';

/** Used to generate unique ID for each accordion. */
let nextId = 0;

@Directive({
    selector: '[appAccordion]',
    host: {
        class: 'app-accordion'
    }
})
export class AccordionDirective {
    /** A readonly id value to use for unique selection coordination. */
    readonly id = `app-accordion-${nextId++}`;
    private _hideToggle: boolean = false;

    constructor() {}

    /** Whether the expansion indicator should be hidden. */
    @Input()
    get hideToggle(): boolean {
        return this._hideToggle;
    }

    set hideToggle(show: boolean) {
        this._hideToggle = coerceBooleanProperty(show);
    }
}
