<div class="collision-warning-panel" *ngIf="hasCollision; else isValidPanel">
    <div class="collision-warning-icon">
        <app-icon [shape]="'warning'" [cssWidth]="'28px'" [cssWidth]="'23px'"></app-icon>
    </div>
    <div class="collision-warning-text">{{ 'EDITOR.COLLISION_WARNING' | translate }}</div>
</div>

<ng-template #isValidPanel>
    <div class="project-is-valid-panel">
        <div class="project-is-valid-text">{{ 'EDITOR.COLLISION_OK' | translate }}</div>
        <app-icon [shape]="'check-circle'" [cssWidth]="'24px'" [cssWidth]="'24px'"></app-icon>
    </div>
</ng-template>
