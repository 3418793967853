import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogRefs } from '../../../dialog/dialog-refs';

@Component({
    templateUrl: './partial-cutting-warning-dialog.component.html',
    styleUrls: ['./partial-cutting-warning-dialog.component.scss']
})
export class PartialCuttingWarningDialogComponent implements OnInit {
    private responseSubject = new Subject<void>();
    readonly response: Observable<void> = this.responseSubject.asObservable();

    constructor(public dialogRefs: DialogRefs<PartialCuttingWarningDialogComponent>) {}

    ngOnInit() {}

    processed() {
        this.responseSubject.next();
        this.dialogRefs.close();
    }
}
