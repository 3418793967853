import { BackendService } from '../../../../../backend/backend.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UploadDialogContentComponent } from '../../upload-dialog.component';
import { DetectedPaperImage, UploadDialogService } from '../../upload-dialog.service';
import {
    FileUploadService,
    UploadCompleteEvent,
    UploadFileInfo
} from '../../../../../file-upload/file-upload.service';
import { groupBy } from '../../../../../utils/group-by';
import { ApiErrorService, ClientErrorCode } from '../../../../../backend/api-error.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    providers: [FileUploadService]
})
export class ImageUploadComponent
implements OnInit, AfterViewInit, UploadDialogContentComponent, OnDestroy {
    @ViewChild('fileInput', { static: false })
    fileInputElement: ElementRef<HTMLInputElement>;

    @ViewChild('messageError', { static: false })
    messageErrorElement: ElementRef<HTMLInputElement>;

    @ViewChild('dropZone', { static: false })
    dropZone: ElementRef;

    showImagesToUpload: boolean = false;

    errorMessage: string;
    detailsErrorMessages: string[] = [];
    uploadedFiles: UploadFileInfo[] = [];

    private isDialogValid: boolean = false;
    private isDialogValidSubject = new BehaviorSubject<boolean>(this.isDialogValid);
    form = new FormControl();

    constructor(
        private readonly uploadDialogService: UploadDialogService,
        private readonly fileUploadService: FileUploadService,
        private readonly translate: TranslateService,
        private readonly apiErrorService: ApiErrorService,
        private readonly backendService: BackendService
    ) {}

    ngOnInit() {
        this.fileUploadService
            .getOnUploadFilesStateChange()
            .subscribe((fileInfos: UploadFileInfo[]) => {
                this.uploadedFiles = fileInfos;
                this.showImagesToUpload = fileInfos.length > 0;
                const isDialogValid = this.showImagesToUpload && this.isFilesStateChangeValid();
                this.isDialogValidSubject.next(isDialogValid);
                this.updateErrorMessage();
            });

        this.fileUploadService
            .getUploadComplete<DetectedPaperImage>()
            .subscribe((evt: UploadCompleteEvent<DetectedPaperImage>) => {
                // since at least one file is complete this dialog is valid if there are no some
                // files in progress
                const isDialogValid = this.uploadedFiles.findIndex(file => file.inProgress) === -1;
                this.isDialogValidSubject.next(isDialogValid);
                this.uploadDialogService.addUploadedImageFile(evt);
            });
    }

    ngAfterViewInit(): void {
        const preventDefaults = (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const dropZoneElement = this.dropZone.nativeElement as HTMLElement;
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropZoneElement.addEventListener(eventName, preventDefaults, false);
        });

        const dragEnterOverListener = (e: Event) =>
            this.dropZone.nativeElement.classList.add('highlight');
        const dragLeaveDropListener = (e: Event) =>
            this.dropZone.nativeElement.classList.remove('highlight');

        ['dragenter', 'dragover'].forEach(eventName => {
            dropZoneElement.addEventListener(eventName, dragEnterOverListener, false);
        });

        ['dragleave', 'drop'].forEach(eventName => {
            dropZoneElement.addEventListener(eventName, dragLeaveDropListener, false);
        });

        dropZoneElement.addEventListener('drop', e => this.handleDrop(e), false);
    }

    ngOnDestroy(): void {
        this.isDialogValidSubject.complete();
    }

    get maxUploadCount(): number {
        return this.fileUploadService.maxUploadCount;
    }

    get isUploadDisabled(): boolean {
        return this.uploadedFiles.length >= this.fileUploadService.maxUploadCount;
    }

    private updateErrorMessage() {
        const failedFiles = this.uploadedFiles.filter(fileInf => fileInf.error != null);
        if (failedFiles.length > 0) {
            this.errorMessage =
                failedFiles.length === 1
                    ? this.translate.instant('ERRORS.GENERAL')
                    : this.translate.instant('ERRORS.GENERAL_PLURAL');

            const groupErrors = groupBy(
                failedFiles.map(x => x.error),
                err => err.errorCode
            );
            this.detailsErrorMessages = [];
            for (const errorCode of Object.keys(groupErrors)) {
                const fileNames = groupErrors[errorCode].map(x => x.filename).join(', ');
                const _errorMessage = this.apiErrorService.getErrorMessage(
                    groupErrors[errorCode][0]
                );
                this.detailsErrorMessages.push(_errorMessage.message + ' ' + fileNames);
            }
        } else {
            this.errorMessage = null;
        }
    }

    private isFilesStateChangeValid(): boolean {
        // dialog is valid is there is no more files in progress and if at least file is done
        let isDoneFound = false;
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i].inProgress) {
                return false;
            }
            if (!isDoneFound && this.uploadedFiles[i].isDone) {
                isDoneFound = true;
            }
        }
        return isDoneFound;
    }

    openFileBrowser() {
        if (this.fileInputElement) {
            const elem = this.fileInputElement.nativeElement;
            // reset value to allow users to override existing image and segmentation result
            elem.value = '';
            elem.click();
        }
    }

    private handleDrop(event: DragEvent) {
        this.uploadFiles(event.dataTransfer.files);
    }

    uploadFiles(files: FileList) {
        this.deleteAlreadyUploadedImages(files);
        this.fileUploadService.uploadFiles(files, this.backendService.apiUrl + '/detection-jobs');
    }

    private deleteAlreadyUploadedImages(files: FileList | File[]) {
        // filter/get already uploaded images
        const existingImages: DetectedPaperImage[] = this.uploadDialogService.detectedPaperImages.filter(
            (paper: DetectedPaperImage) => {
                for (let i = 0; i < files.length; i++) {
                    if (paper.sourceImage === files[i].name) {
                        return true;
                    }
                }

                return false;
            }
        );

        existingImages.forEach(img => {
            this.deleteUploadFile(img.sourceImage);
        });
    }

    deleteUploadFile(fileName: string) {
        this.fileUploadService.deleteUploadFile(fileName);
        this.uploadDialogService.deleteUploadFile(fileName);
    }

    isValid(): Observable<boolean> {
        return this.isDialogValidSubject.asObservable().pipe(distinctUntilChanged());
    }

    onLoadNextContent(): void {}

    onLoadPrevContent(): void {}

    onAttached(): void {}
}

export interface FileDetailErrorMessage {
    [ClientErrorCode.PAPER_DETECTION_FAILED]?: string;
    [ClientErrorCode.INVALID_IMAGE]?: string;
}
