import { CuttableContour, CuttableContourArgs } from './cuttable-contour';
import { COLOR_BG_ITEM_PATH, COLOR_BG_MARGIN_PATH, OPACITY_BG_MARGIN_PATH } from './constants';
import {
    ContourChangeData,
    ContourHandlesData,
    CuttableContourDrawData,
    MarginContourCollisionVizData,
    ResizeHandleListData
} from './contour-items-interfaces';
import { CanvasElementChangedEvent } from '../canvas.component';
import { Handle } from '../../shared/handle/handle';
import { Tool } from '../../shared/tool/tool';
import {
    EastHandle,
    NorthEastHandle,
    NorthHandle,
    NorthWestHandle,
    ResizeHandle,
    SouthEastHandle,
    SouthHandle,
    SouthWestHandle,
    WestHandle
} from '../../shared/handle/resize-handle';
import { RotationHandle } from '../../shared/handle/rotation-handle';
import {
    calculateMargin,
    createRectanglePath,
    pathString2Polygon,
    transformBoundingBox
} from './contour-helper';
import { FoamEditorService } from '../../foam-editor.service';
import { ID_RESIZE_HANDLES_GROUP } from '../handles/resize-handles-drawer';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { ResizeableContour } from './resizeable-contour';
import { MarginUtils } from '../shared/margin-utils';

export class RectangleContour extends ResizeableContour {
    isCollidable: boolean = true;
    isRemovable: boolean;
    isSelectable: boolean = true;
    isResizable: boolean = true;
    cornerRadiusXY: number;

    private resizeHandles: ResizeHandle[];
    private rotationHandle: RotationHandle<CuttableContour>;
    private showResizeHandles: boolean = true;

    contourBoundsInParent: Rectangle2D;

    constructor(obj: RectangleContourArgs) {
        super(obj);

        const setterFn = (value: any, def: any) => (value == null ? def : value);

        this.width = setterFn(obj.width, 25);
        this.height = setterFn(obj.height, 25);
        this.depth = setterFn(obj.depth, 13);
        this.cornerRadiusXY = setterFn(obj.cornerRadiusXY, 25);

        this.isSelectable = setterFn(obj.isSelectable, true);
        this.isCollidable = setterFn(obj.isCollidable, true);
        this.isResizable = setterFn(obj.isResizable, true);

        this.itemProps.minWidth = 5;
        this.itemProps.minHeight = 5;
        this.itemProps.contourFill = COLOR_BG_ITEM_PATH;
        this.itemProps.marginFill = COLOR_BG_MARGIN_PATH;
        this.itemProps.marginOpacity = OPACITY_BG_MARGIN_PATH;

        this.svgPathDefinition = setterFn(
            obj.svgPathDefinition,
            createRectanglePath(
                0,
                0,
                this.width,
                this.height,
                this.cornerRadiusXY,
                this.cornerRadiusXY
            )
        );

        this.marginSize = this.depth ? calculateMargin(this.depth) : undefined;
        this.marginPathString = setterFn(
            obj.marginPathString,
            MarginUtils.createMarginPathString(this.svgPathDefinition, this.marginSize)
        );
    }

    isValidResizeStep(newBounds: Rectangle2D, oldBounds: Rectangle2D): boolean {
        if (newBounds.width < oldBounds.width && this.width <= this.itemProps.minWidth) {
            return false;
        } else if (newBounds.height < oldBounds.height && this.height <= this.itemProps.minHeight) {
            return false;
        }
        return true;
    }

    constraintResizeStep(newBounds: Rectangle2D): Rectangle2D {
        newBounds.width = Math.max(newBounds.width, this.itemProps.minWidth);
        newBounds.height = Math.max(newBounds.height, this.itemProps.minHeight);
        newBounds.recomputeCenter();
        return newBounds;
    }

    constraintResizeDepth(newDepth: number): number {
        if (!this.canvasService) {
            return newDepth;
        }
        let newValue = Math.max(newDepth, this.canvasService.getPropertiesValue().minContourDepth);
        newValue = Math.min(newValue, this.canvasService.getPropertiesValue().maxContourDepth);
        return newValue;
    }

    resizeDepth(depth: number) {
        if (depth !== undefined && this.depth !== depth) {
            const newDepth = this.constraintResizeDepth(depth);
            this.depth = newDepth;
            this.marginSize = calculateMargin(newDepth);

            this.marginPathString = MarginUtils.createMarginPathString(
                this.svgPathDefinition,
                this.marginSize
            );

            const contourChangeData = new ContourChangeData({
                contourId: this.contourId,
                marginPathString: this.marginPathString,
                isDepthChanged: true
            });
            this.contourChanged.next(contourChangeData);
        }
    }

    resizeDimension(newBounds: Rectangle2D, oldBounds?: Rectangle2D) {
        newBounds = this.constraintResizeStep(newBounds);

        if (!oldBounds) {
            oldBounds = this.localContourPathBBox.clone();
        }

        this.width = newBounds.width;
        this.height = newBounds.height;

        this.svgPathDefinition = createRectanglePath(
            0,
            0,
            this.width,
            this.height,
            this.cornerRadiusXY,
            this.cornerRadiusXY
        );

        this.marginPathString = MarginUtils.createMarginPathString(
            this.svgPathDefinition,
            this.marginSize
        );

        const dx = newBounds.x - oldBounds.x;
        const dy = newBounds.y - oldBounds.y;
        const matrix = this.localContourPathMatrix.clone();
        matrix.translate(dx, dy);

        const oldMarginBounds = this.localMarginPathBBox;

        const dWidth = newBounds.width - oldBounds.width;
        const dHeight = newBounds.height - oldBounds.height;

        const newMarginBounds = new Rectangle2D(
            oldMarginBounds.x,
            oldMarginBounds.y,
            oldMarginBounds.width + dWidth,
            oldMarginBounds.height + dHeight
        );

        const oldContourBounds = this.localContourPathBBox;
        const newContourBounds = new Rectangle2D(
            oldContourBounds.x,
            oldContourBounds.y,
            newBounds.width,
            newBounds.height
        );

        const contourChangeData = new ContourChangeData({
            contourId: this.contourId,
            svgPathDefinition: this.svgPathDefinition,
            marginPathString: this.marginPathString,
            matrix: matrix,
            width: this.width,
            height: this.height,
            handles: new ContourHandlesData({
                contourId: this.contourId,
                transformBounds: {
                    localContourBounds: newContourBounds,
                    localMarginBounds: newMarginBounds,
                    selectionBoundsMatrix: matrix
                }
            })
        });
        this.contourChanged.next(contourChangeData);
    }

    contains(px: number, py: number): boolean {
        return false;
    }

    containsTarget(target: Element): boolean {
        return target.id === this.itemProps.itemPathId || target.id === this.itemProps.marginPathId;
    }

    createHandles(): Handle[] {
        if (!this.resizeHandles) {
            this.resizeHandles = [
                new NorthEastHandle(this),
                new EastHandle(this),
                new SouthEastHandle(this),
                new SouthHandle(this),
                new SouthWestHandle(this),
                new WestHandle(this),
                new NorthWestHandle(this),
                new NorthHandle(this)
            ];
        }

        if (!this.rotationHandle) {
            this.rotationHandle = new RotationHandle<CuttableContour>(this);
        }

        return [this.rotationHandle, ...this.resizeHandles];
    }

    getCollisionData(): MarginContourCollisionVizData {
        return new MarginContourCollisionVizData({
            contourId: this.contourId,
            clipPathString: this.svgPathDefinition,
            pathString: this.svgPathDefinition,
            pathStringToBeClipped: this.marginPathString,
            transformString: this.localMarginPathMatrix
                ? this.localMarginPathMatrix.toTransformString()
                : undefined,
            transformString2: this.localMarginPathMatrix
                ? this.localMarginPathMatrix.toString()
                : undefined,

            innerCollisionPolygonLines: this.polygonLines,
            outerCollisionPolygonLines: this.marginPolygonLines,
            innerCollisionBBox: this.globalContourPathBBox,
            outerCollisionBBox: this.globalMarginPathBBox,

            globalContourPathBBox: this.globalContourPathBBox,
            localContourPathBBox: this.localContourPathBBox,

            globalMarginPathMatrix: this.globalMarginPathMatrix,
            localMarginPathMatrix: this.localMarginPathMatrix,
            globalContourPathMatrix: this.globalContourPathMatrix,
            localContourPathMatrix: this.localContourPathMatrix,
            isCollidable: this.isCollidable
        });
    }

    getDrawData(): CuttableContourDrawData {
        return new CuttableContourDrawData({
            contourId: this.contourId,
            svgPathDefinition: this.svgPathDefinition,
            marginPathString: this.marginPathString,
            marginSize: this.marginSize,
            itemProps: this.itemProps,
            isCollidable: this.isCollidable,
            isSelectable: this.isSelectable,
            matrix: this.localContourPathMatrix
        });
    }

    getHandlesDrawData(): ContourHandlesData {
        return new ContourHandlesData({
            containerId: 'handle-container-' + this.contourId,
            contourId: this.contourId,
            transformBounds: {
                localContourBounds: this.localContourPathBBox,
                localMarginBounds: this.localMarginPathBBox,
                selectionBoundsMatrix: this.localContourPathMatrix
            },
            resizeHandles: new ResizeHandleListData({
                contourId: this.contourId,
                containerId: ID_RESIZE_HANDLES_GROUP + this.contourId,
                handles: this.resizeHandles.map(x => x.getDrawData()),
                visible: this.showResizeHandles
            }),
            rotationHandles: this.rotationHandle.getDrawData()
        });
    }

    getTool(target: Element, editor?: FoamEditorService): Tool | null {
        return undefined;
    }

    onContourElementDrawn(drawnData: CanvasElementChangedEvent): void {
        if (!drawnData) {
            return;
        }
        if (drawnData.contour) {
            this.polygonLines = pathString2Polygon(
                this.svgPathDefinition,
                drawnData.contour.globalMatrix
            );

            this.globalContourPathBBox = drawnData.contour.globalBBox;
            this.localContourPathBBox = drawnData.contour.localBBox;
            this.globalContourPathMatrix = drawnData.contour.globalMatrix;
            this.localContourPathMatrix = drawnData.contour.localMatrix;

            this.contourBoundsInParent = Rectangle2D.fromPointsObject(
                transformBoundingBox(drawnData.contour.localBBox, this.localContourPathMatrix)
            );
        }

        if (drawnData.margin) {
            this.globalMarginPathMatrix = drawnData.margin.globalMatrix;
            this.localMarginPathMatrix = drawnData.margin.localMatrix;
            this.globalMarginPathBBox = drawnData.margin.globalBBox;
            this.localMarginPathBBox = drawnData.margin.localBBox;
            this.marginPolygonLines = pathString2Polygon(
                this.marginPathString,
                drawnData.margin.globalMatrix
            );
        }
    }
}

export interface RectangleContourArgs extends CuttableContourArgs {
    width: number;
    height: number;
    cornerRadiusXY: number;
    userDefinedDepth?: boolean;
    depth: number;
}
