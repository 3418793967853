export interface GroupedArray<T> {
    [key: string]: T[];
    [key: number]: T[];
}

export function groupBy<T>(
    array: T[],
    keySelector: (value: T) => number | string
): GroupedArray<T> {
    return array.reduce(function(accArr: GroupedArray<T>, currArr: T) {
        const key = keySelector(currArr);
        if (key) {
            accArr[key] = accArr[key] || [];
            accArr[key].push(currArr);
        }
        return accArr;
    }, {});
}
