import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-project-validity-info',
    templateUrl: './project-validity-info.component.html',
    styleUrls: ['./project-validity-info.component.scss']
})
export class ProjectValidityInfoComponent implements OnInit {
    @Input()
    hasCollision: boolean;

    constructor() {}

    ngOnInit() {}
}
