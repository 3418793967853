import { UndoableEditaAction } from './undo-manager.service';
import { CanvasContour } from '../canvas/contour/contour-items-interfaces';

export class MoveUndoableEditAction implements UndoableEditaAction {
    readonly presentationName: string;

    constructor(
        readonly contours: CanvasContour[],
        private readonly delta: { x: number; y: number },
        private selectionInfo?: UndoSelectionInfo,
        presentationName?: string
    ) {
        if (!this.contours) {
            throw Error('contours cannot be undefined');
        }

        this.presentationName = presentationName || 'Move contour';
    }

    redo(): void {
        if (this.selectionInfo) {
            this.selectionInfo.addToSelection(this.selectionInfo.selectedContours);
        }
        this.contours.forEach(contour => {
            contour.translate(this.delta.x, this.delta.y);
        });
    }

    undo(): void {
        if (this.selectionInfo) {
            this.selectionInfo.addToSelection(this.selectionInfo.selectedContours);
        }
        this.contours.forEach(contour => {
            contour.translate(-this.delta.x, -this.delta.y);
        });
    }
}

export interface UndoSelectionInfo {
    addToSelection: (contours: CanvasContour[]) => void;
    selectedContours: CanvasContour[];
}
