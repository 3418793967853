import { Action, ActionEvent } from './action';
import { Injectable } from '@angular/core';
import { CanvasService } from '../canvas/canvas.service';

@Injectable()
export class ShowContourImageAction implements Action {
    constructor(private readonly canvasService: CanvasService) {}

    execute(event: ShowContourImageActionEvent) {
        this.canvasService.setProperties({
            showContourImage: event.showContourImage
        });
    }
}

export class ShowContourImageActionEvent extends ActionEvent {
    showContourImage: boolean;
}
