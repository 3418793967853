import { CanvasLayer } from './canvas-layer';
import { Point2D } from '../../shared/geom/point2D';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export function CanvasLayerMixin<T extends Constructor<{}>>(
    Base = class {} as T
): Constructor<CanvasLayer<Snap.Paper>> & T {
    // FIXME replace Snap.Paper with a generic expression
    return class extends Base implements CanvasLayer<Snap.Paper> {
        element: Snap.Paper;
        scaleFactor = 1.0;

        scaleLayers(scaleFactor: number, centerXY?: Point2D) {
            this.scaleFactor = scaleFactor;
        }

        // eslint-disable-next-line @typescript-eslint/no-useless-constructor
        constructor(...args: any[]) {
            super(...args);
        }
    };
}

export interface WithSubscriptionMixin extends OnDestroy {
    addSubscription: (...subs: Subscription[]) => void;
}

export interface CanvasOffset {
    readonly x: number;
    readonly y: number;
}
