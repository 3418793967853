import { Injectable } from '@angular/core';

import { CanvasService } from './canvas.service';
import { CLASS_NAME_CONTOUR_GROUP, CLASS_NAME_TEXT_CONTOUR_GROUP } from './contour/constants';
import { getContourIdFromString } from './contour/contour-utils';
import { ImageContour } from './contour/image-contour';
import { TextContour } from './contour/text-contour';
import { DefaultSVGDndHandler, DndHandler } from '../../droppable/default-svg-dnd-handler';
import { DroppableEvent } from '../../droppable/droppable-handler.service';
import {
    ALIGN_MODE_CENTER,
    DroppableMirrorComponent
} from '../../droppable/droppable-mirror/droppable-mirror.component';

declare var Snap: any;

@Injectable()
export class CanvasDroppableHandler implements DndHandler {
    private currentDraggedContourId: string;
    private cachedMirrorWidth: number;
    private cachedMirrorHeight: number;

    private mirrorComponent: DroppableMirrorComponent;
    private defaultDnDHandler: DefaultSVGDndHandler;

    constructor(private canvasService: CanvasService) {
        this.defaultDnDHandler = new DefaultSVGDndHandler();
    }

    setMirrorComponent(mirror: DroppableMirrorComponent) {
        this.mirrorComponent = mirror;
        this.defaultDnDHandler.setMirrorComponent(mirror);
    }

    onDragStart(event: DroppableEvent) {
        this.defaultDnDHandler.onDragStart(event);
        // Hide draggable item
        this.currentDraggedContourId = this.getContourId(event);

        if (!this.currentDraggedContourId) {
            console.error('Unable to determine the contour index of the dragged item');
            return;
        }

        const woItem = this.canvasService.getContourItem(this.currentDraggedContourId);
        this.canvasService.getSelectedContourItems().forEach(x => {
            // TODO find a way to batch this changes
            x.seVisibility(false);
        });

        const mirrorComponent = this.mirrorComponent;
        const svgPreviewCanvas = mirrorComponent.svgPreviewCanvas;
        svgPreviewCanvas.clear();

        mirrorComponent.alignMode = 'center';

        const shrinkFactor = this.getShrinkFactor(mirrorComponent.width, mirrorComponent.height);
        const newWidth = mirrorComponent.width * shrinkFactor;
        const newHeight = mirrorComponent.height * shrinkFactor;
        this.cachedMirrorWidth = newWidth;
        this.cachedMirrorHeight = newHeight;

        mirrorComponent.width = newWidth;
        mirrorComponent.height = newHeight;

        const group = event.mirrorElement as SVGGElement;
        // The mirrorElement might be hidden when cloned after it enters the drop-zone preview area
        group.style.visibility = 'visible';

        mirrorComponent.setPreviewContour({
            svgRootWidth: newWidth,
            svgRootHeight: newHeight,
            contourSvg: group
        });

        const mirrorChildren: Node[] = Array.from(group.childNodes);
        // svgPreviewCanvas.add(group);

        const scaleFactor = this.canvasService.getScaleFactor();

        let path: SVGPathElement | SVGTextElement;
        if (!(woItem instanceof TextContour)) {
            path = mirrorChildren[0] as SVGPathElement;

            group.setAttributeNS(null, 'transform', `scale(${scaleFactor}, ${scaleFactor})`);
            path.setAttributeNS(null, 'transform', `scale(${shrinkFactor}, ${shrinkFactor})`);
        } else {
            path = mirrorChildren.find(
                el => el.nodeName.toUpperCase() === 'TEXT'
            ) as SVGTextElement;
            const textBackground = mirrorChildren.find(
                el => el.nodeName.toUpperCase() === 'RECT'
            ) as SVGRectElement;

            group.setAttribute('transform', `scale(${scaleFactor}, ${scaleFactor})`);
            path.setAttributeNS(null, 'x', '2');
            path.setAttributeNS(null, 'y', '0');
            path.setAttributeNS(null, 'font-size', '8px');
            path.setAttributeNS(null, 'fill', '#fff');
            path.setAttributeNS(null, 'transform', 'translate(2, -1.5)');

            textBackground.setAttributeNS(null, 'transform', '');
            textBackground.setAttributeNS(null, 'class', '');
            textBackground.setAttributeNS(null, 'fill', '#0669b2');
            textBackground.setAttributeNS(null, 'style', 'display: inline');

            group.setAttributeNS(null, 'transform', `scale(${scaleFactor}, ${scaleFactor})`);
        }

        if (woItem instanceof ImageContour) {
            const image = mirrorChildren[1] as SVGImageElement;
            image.setAttributeNS(null, 'transform', `scale(${shrinkFactor})`);
        }

        mirrorComponent.isMirroredNodeVisible = false;
        mirrorComponent.isPreviewNodeVisible = false;

        //
        this.updateOtherContainersPointerEvents('none');
    }

    private getContourId(event: DroppableEvent): string {
        const itemClassName = event.draggableTarget.htmlElement.getAttribute('class');
        let contourId = getContourIdFromString(itemClassName, CLASS_NAME_CONTOUR_GROUP);
        if (!contourId) {
            contourId = getContourIdFromString(itemClassName, CLASS_NAME_TEXT_CONTOUR_GROUP);
        }

        return contourId;
    }

    private updateOtherContainersPointerEvents(value: string) {
        ['.ribbon-container', '.project-properties', '.edit-actions-top'].forEach(x => {
            document.querySelector(x).setAttribute('style', 'pointer-events:' + value);
        });
    }

    onDragMove(evt: DroppableEvent) {
        this.defaultDnDHandler.onDragMove(evt);
    }

    onDragEnter($containerEvent: DroppableEvent) {
        const mirrorComponent = this.mirrorComponent;

        mirrorComponent.isPreviewNodeVisible = true;
        mirrorComponent.isMirroredNodeVisible = false;
        mirrorComponent.alignMode = 'center';
        mirrorComponent.dropState = 'enter';

        mirrorComponent.width = this.cachedMirrorWidth;
        mirrorComponent.height = this.cachedMirrorHeight;
    }

    onDragReEnterPreviewArea(event: DroppableEvent) {
        this.hideAll();
    }

    onDragDrop(event: DroppableEvent) {
        this.defaultDnDHandler.onDragDrop(event);
        this.canvasService.removeContours(this.canvasService.getSelectedContourItems());
        //TODO remove item
        this.updateOtherContainersPointerEvents('all');
    }

    onDragLeave(event: DroppableEvent) {
        // Override default behaviour of DroppableContainerDirective#onDragLeave()
        this.hideAll();
    }

    onDragCancel(event: DroppableEvent) {
        this.defaultDnDHandler.onDragCancel(event);
        this.canvasService.getSelectedContourItems().forEach(x => {
            // TODO find a way to batch this changes
            x.seVisibility(true);
        });

        this.updateOtherContainersPointerEvents('all');
    }

    private hideAll() {
        const mirrorComponent = this.mirrorComponent;
        mirrorComponent.isPreviewNodeVisible = false;
        mirrorComponent.isMirroredNodeVisible = false;
        mirrorComponent.alignMode = ALIGN_MODE_CENTER;

        mirrorComponent.width = this.cachedMirrorWidth;
        mirrorComponent.height = this.cachedMirrorHeight;
    }

    private getShrinkFactor(mirrorWidth: number, mirrorHeight: number): number {
        const buttonWidth = 75;
        const buttonHeight = 75;
        const padding = 20;

        let shrinkFactor;

        // ensure that the preview element is shrinked such that it is fully located inside the
        // dropZone button
        if (mirrorHeight >= mirrorWidth) {
            shrinkFactor = (buttonHeight - padding) / mirrorHeight;
        } else {
            shrinkFactor = (buttonWidth - padding) / mirrorWidth;
        }
        return shrinkFactor;
    }
}
