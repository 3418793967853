import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NS } from 'src/app/namespaces';

import { ContourStartDimensions, FoamEditorService } from '../../../foam-editor.service';

declare var Snap: any;

@Injectable()
export class PreviewContourService {
    private readonly scaledDimensions: ContourStartDimensions;
    readonly unscaledDimensions: ContourStartDimensions;

    private strokeWidth = 2;
    private scaleFactor: number;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private foamEditorService: FoamEditorService
    ) {
        this.unscaledDimensions = this.foamEditorService.getContoursStartDimensions();
        this.scaledDimensions = this.foamEditorService.getContoursStartDimensions();
        this.scaleDimensions(1.0);
    }

    scaleDimensions(scaleFactor: number) {
        this.scaleFactor = scaleFactor;
        if (this.unscaledDimensions) {
            for (const key of Object.keys(this.unscaledDimensions)) {
                if (this.unscaledDimensions[key]) {
                    for (const key2 of Object.keys(this.unscaledDimensions[key])) {
                        this.scaledDimensions[key][key2] =
                            this.unscaledDimensions[key][key2] * scaleFactor;
                    }
                }
            }
        }
    }

    getPreviewContour(type: PreviewContourType): PreviewContourInfo | undefined {
        if (type === 'TEXT') {
            return this.getPreviewTextContour();
        } else if (type === 'RECTANGLE') {
            return this.getPreviewRectangleContour();
        } else if (type === 'CIRCLE') {
            return this.getPreviewCircleContour();
        } else if (type === 'RECESSED_GRIP') {
            return this.getPreviewRecessedContour();
        } else {
            // TODO return placehholder object
            console.error('Unknown preview contour ' + type);
            return undefined;
        }
    }

    getPreviewRectangleContour(): PreviewContourInfo {
        const { width, height, cornerRadius } = this.scaledDimensions.RECTANGLE;

        const rect: SVGRectElement = this.document.createElementNS(
            NS.SVG,
            'rect'
        ) as SVGRectElement;
        rect.setAttributeNS(null, 'x', this.strokeWidth.toString());
        rect.setAttributeNS(null, 'y', this.strokeWidth.toString());
        rect.setAttributeNS(null, 'width', width.toString());
        rect.setAttributeNS(null, 'height', height.toString());
        rect.setAttributeNS(null, 'rx', cornerRadius.toString());
        rect.setAttributeNS(null, 'ry', cornerRadius.toString());
        rect.setAttributeNS(null, 'fill-opacity', '0'); // NOSONAR
        rect.setAttributeNS(null, 'stroke', '#2880E6');
        rect.setAttributeNS(null, 'stroke-width', this.strokeWidth.toString()); // NOSONAR

        return {
            svgRootWidth: width + this.strokeWidth * 2,
            svgRootHeight: height + this.strokeWidth * 2,
            contourSvg: rect
        };
    }

    getPreviewRecessedContour(): PreviewContourInfo {
        const { width, height, cornerRadius } = this.scaledDimensions.RECESSED_GRIP;

        const rect: SVGRectElement = this.document.createElementNS(
            NS.SVG,
            'rect'
        ) as SVGRectElement;
        rect.setAttributeNS(null, 'x', this.strokeWidth.toString());
        rect.setAttributeNS(null, 'y', this.strokeWidth.toString());
        rect.setAttributeNS(null, 'width', width.toString());
        rect.setAttributeNS(null, 'height', height.toString());
        rect.setAttributeNS(null, 'rx', cornerRadius.toString());
        rect.setAttributeNS(null, 'ry', cornerRadius.toString());
        rect.setAttributeNS(null, 'fill-opacity', '0');
        rect.setAttributeNS(null, 'stroke', '#2880E6');
        rect.setAttributeNS(null, 'stroke-width', this.strokeWidth.toString());
        return {
            svgRootWidth: width + this.strokeWidth * 2,
            svgRootHeight: height + this.strokeWidth * 2,
            contourSvg: rect
        };
    }

    getPreviewTextContour(): PreviewContourInfo {
        const textElem: SVGTextElement = this.document.createElementNS(
            NS.SVG,
            'text'
        ) as SVGTextElement;
        textElem.setAttributeNS(null, 'x', '0');
        textElem.setAttributeNS(null, 'y', '15');
        textElem.setAttributeNS(null, 'fill', '#ffffff');
        textElem.setAttributeNS(null, 'font-size', '18');
        textElem.textContent = 'Sortimo';

        textElem.setAttributeNS(NS.XML, 'xml:space', 'preserve');
        textElem.setAttribute('alignment-baseline', 'middle');

        return {
            svgRootWidth: this.scaledDimensions.TEXT.width,
            svgRootHeight: this.scaledDimensions.TEXT.height,
            contourSvg: textElem
        };
    }

    getPreviewCircleContour(): PreviewContourInfo {
        const diameter = this.scaledDimensions.CIRCLE.radius * 2;
        const radiusToString = this.scaledDimensions.CIRCLE.radius.toString();
        const cxy = (this.scaledDimensions.CIRCLE.radius + this.strokeWidth).toString();

        const circle: SVGCircleElement = this.document.createElementNS(
            NS.SVG,
            'circle'
        ) as SVGCircleElement;
        circle.setAttributeNS(null, 'cx', cxy);
        circle.setAttributeNS(null, 'cy', cxy);
        circle.setAttributeNS(null, 'r', radiusToString);
        circle.setAttributeNS(null, 'fill-opacity', '0');
        circle.setAttributeNS(null, 'stroke', '#2880E6');
        circle.setAttributeNS(null, 'stroke-width', this.strokeWidth.toString());
        return {
            svgRootWidth: diameter + this.strokeWidth * 2,
            svgRootHeight: diameter + this.strokeWidth * 2,
            contourSvg: circle
        };
    }

    getPreviewImageContour(contourInfo: PreviewImageContourInfo): PreviewContourInfo {
        const path: SVGPathElement = this.document.createElementNS(
            NS.SVG,
            'path'
        ) as SVGPathElement;
        path.setAttributeNS(null, 'd', contourInfo.svgPathDefinition);
        path.setAttributeNS(null, 'fill-opacity', '0');
        path.setAttributeNS(null, 'stroke', '#2880E6');
        path.setAttributeNS(null, 'stroke-width', '1px');
        path.setAttributeNS(null, 'stroke-linecap', 'round');
        path.setAttributeNS(null, 'transform', `scale(${this.scaleFactor}, ${this.scaleFactor})`);

        // Flexible height wight
        // Set the width/height to fit the contourSv
        const { width, height } = Snap(path).getBBox();
        const svgRootWidth = width + this.strokeWidth * 2;
        const svgRootHeight = height + this.strokeWidth * 2;

        return {
            svgRootWidth: svgRootWidth,
            svgRootHeight: svgRootHeight,
            contourSvg: path
        };
    }
}

export interface PreviewContourInfo {
    svgRootWidth: number;
    svgRootHeight: number;
    contourSvg?: SVGElement;
}

export type PreviewContourType =
    | 'IMAGE_CONTOUR'
    | 'RECTANGLE'
    | 'TEXT'
    | 'CIRCLE'
    | 'RECESSED_GRIP';

export interface PreviewImageContourInfo {
    svgPathDefinition: string;
}
