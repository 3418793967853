<div
    appAccordion
    appDroppableContainer
    [dropZoneId]="dropZoneId"
    (started)="onDragStart($event)"
    (entered)="onDragEnter($event)"
    (dropped)="onDragDrop($event)"
>
    <app-accordion-toolbar
        [panelTitle]="panelTitle"
        [panelIndex]="panelIndex"
    ></app-accordion-toolbar>
    <app-expansion-panel expanded="true">
        <app-expansion-panel-header>
            <div appExpansionPanelTitle>
                {{ 'SHAPE_HANDLE_TEXT_RIBBON.SUB_MENU_SHAPES' | translate }}
            </div>
        </app-expansion-panel-header>
        <p class="action-info-text">{{ 'SHAPE_HANDLE_TEXT_RIBBON.INFO_TEXT' | translate }}</p>

        <div class="contour-shape-list">
            <div
                class="contour-shape-item draggable-item"
                appDroppableItem
                droppableItemType="RECTANGLE"
            >
                <div class="contour-shape-icon"><app-icon shape="icon-rectangle"></app-icon></div>
                <div class="contour-shape-title">
                    {{ 'SHAPE_HANDLE_TEXT_RIBBON.ITEM_RECTANGLE' | translate }}
                </div>
            </div>
            <div
                class="contour-shape-item draggable-item"
                appDroppableItem
                droppableItemType="CIRCLE"
            >
                <div class="contour-shape-icon">
                    <app-icon shape="icon-circle"></app-icon>
                </div>
                <div class="contour-shape-title">
                    {{ 'SHAPE_HANDLE_TEXT_RIBBON.ITEM_CIRCLE' | translate }}
                </div>
            </div>
        </div>
    </app-expansion-panel>

    <app-expansion-panel>
        <app-expansion-panel-header>
            <div appExpansionPanelTitle>
                {{ 'SHAPE_HANDLE_TEXT_RIBBON.SUB_MENU_HANDLE_RECESSES' | translate }}
            </div>
        </app-expansion-panel-header>

        <p class="action-info-text">
            {{ 'SHAPE_HANDLE_TEXT_RIBBON.HANDLE_INFO_TEXT' | translate }}
        </p>

        <div class="contour-shape-list">
            <div
                class="contour-shape-item"
                appDroppableItem
                droppableItemType="RECESSED_GRIP"
                tabindex="0"
            >
                <div class="contour-shape-icon">
                    <app-icon shape="recessed_grip"></app-icon>
                </div>
                <div class="contour-shape-title">
                    {{ 'SHAPE_HANDLE_TEXT_RIBBON.HANDLE_RECESS' | translate }}
                </div>
            </div>
        </div>

        <p class="action-info-text">
            {{ 'SHAPE_HANDLE_TEXT_RIBBON.HANDLE_INFO_DEPTH_ONE' | translate }}
            <img src="/assets/help/item-depth.png" class="depth-input-image" />
        </p>

        <div class="grip-usage-desc">
            <p class="action-info-text">
                {{ 'SHAPE_HANDLE_TEXT_RIBBON.HANDLE_EXAMPLE_TEXT' | translate }}
            </p>
            <img
                src="/assets/help/grip-usage2.png"
                title="{{ 'SHAPE_HANDLE_TEXT_RIBBON.IMG_GRIP_TITLE' | translate }}"
            />
        </div>
    </app-expansion-panel>

    <app-expansion-panel>
        <app-expansion-panel-header>
            <div appExpansionPanelTitle>
                {{ 'SHAPE_HANDLE_TEXT_RIBBON.SUB_MENU_TEXT_BOXES' | translate }}
            </div>
        </app-expansion-panel-header>

        <p class="action-info-text">
            {{ 'SHAPE_HANDLE_TEXT_RIBBON.TEXT_BOX_INFO_TEXT' | translate }}
        </p>

        <div class="contour-shape-list">
            <div
                class="contour-shape-item draggable-item"
                appDroppableItem
                droppableItemType="TEXT"
                tabindex="0"
            >
                <div class="contour-shape-icon">
                    <app-icon shape="icon-text_web" svgWidth="28" svgHeight="28"></app-icon>
                </div>
                <div class="contour-shape-title">
                    {{ 'SHAPE_HANDLE_TEXT_RIBBON.TEXT_BOX' | translate }}
                </div>
            </div>
        </div>

        <div class="grip-usage-desc">
            <p class="action-info-text">
                {{ 'SHAPE_HANDLE_TEXT_RIBBON.TEXT_BOX_EXAMPLE_TEXT' | translate }}
            </p>
            <img
                src="/assets/help/text-usage.png"
                title="{{ 'SHAPE_HANDLE_TEXT_RIBBON.IMG_TEXT_BOX_TITLE' | translate }}"
            />
        </div>
    </app-expansion-panel>
</div>
