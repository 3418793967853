/**
 * Returns the contour id from the given string value, after the specified prefix
 *
 * @param stringValue
 * @param prefix
 */

export function getContourIdFromString(stringValue: string, prefix: string): string | null {
    const regx = new RegExp(`(${prefix})(\\d+)`);
    const matchesArr = stringValue.match(regx);
    if (matchesArr && matchesArr.length === 3) {
        return matchesArr[2];
    }

    return null;
}
