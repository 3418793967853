import { EditMode } from './detection-result-canvas/detection-result-canvas.component';

export class SelectionCursorDrawer {
    /**
     * Gets cursor for the given radius and selection mode.
     * @param element
     * @param radius
     * @param selectionMode
     */
    public drawCursor(element: HTMLElement, radius: number, selectionMode: EditMode) {
        let svg = '';
        if (selectionMode === EditMode.ADD || selectionMode === EditMode.REMOVE) {
            svg = this.drawCircleCursor(radius, selectionMode);
        } else if (selectionMode === EditMode.MOVE) {
            svg = this.drawMoveCursor();
        }

        element.style.cursor =
            "url('data:image/svg+xml;utf8," + svg + " ')" + radius + ' ' + radius + ' , auto';
    }

    private drawMoveCursor() {
        const pathString =
            'M13 15.86H7.79v-5.17L0 18.49l7.79 7.82v-5.24H13v-5.21zM15.93 12.99h5.21v-5.2h5.17L18.51 0l-7.82 7.79h5.24v5.2zM21.14 23.99h-5.21v5.22h-5.24L18.5 37l7.81-7.79h-5.17v-5.22zM37 18.5l-7.79-7.81v5.17H24v5.21h5.21v5.24L37 18.5zM16 16h5v5h-5z';
        return (
            '<svg xmlns="http://www.w3.org/2000/svg" width="37" viewBox="0 0" height="37"> <path d="' +
            pathString +
            '"/></svg>'
        );
    }

    private drawCircleCursor(radius: number, selectionMode: EditMode) {
        const strokeWidth = radius < 15 ? 0.3 : 0.7;
        let path = '';
        if (selectionMode === EditMode.ADD) {
            path = this.createSvgPlusSign(radius, strokeWidth);
        } else if (selectionMode === EditMode.REMOVE) {
            path = this.createSvgMinusSign(radius, strokeWidth);
        }
        const diameter = radius * 2;
        const circleSize = this.computeCircleSize(radius, strokeWidth);
        const viewBox = ` viewBox="0 0 ${diameter} ${diameter}"`;
        return (
            '<svg xmlns="http://www.w3.org/2000/svg"' +
            viewBox +
            ' width="' +
            diameter +
            '" height="' +
            diameter +
            '" > ' +
            this.createSvgCircle(circleSize, strokeWidth) +
            path +
            '  </svg>'
        );
    }

    private computeCircleSize(radius: number, strokeWidth: number): CircleSize {
        return {
            // remove 1 px to make the border to appear inside
            r: radius - strokeWidth * 2,
            cx: radius,
            cy: radius
        };
    }

    private createSvgCircle(size: CircleSize, strokeWidth: number) {
        // bright size
        let circleHtml =
            '<circle r="' +
            (size.r + 0.5) +
            '" cx="' +
            size.cx +
            '" cy="' +
            size.cy +
            '" fill="transparent" stroke="rgb(255,255,255)" stroke-width="1" />';
        circleHtml +=
            '<circle r="' +
            size.r +
            '" cx="' +
            size.cx +
            '" cy="' +
            size.cy +
            '" fill="transparent" stroke="rgb(0,0,0)" stroke-width="' +
            strokeWidth +
            '" />';
        return circleHtml;
    }

    private createSvgPlusSign(radius: number, strokeWidth: number): string {
        const p = radius - radius * 0.4;
        const q = radius + radius * 0.4;
        let path =
            '<line x1="' +
            p +
            '" y1="' +
            radius +
            '" x2="' +
            q +
            '" y2="' +
            radius +
            '" stroke="black" stroke-width="' +
            strokeWidth +
            '" />';
        path +=
            ' <line x1="' +
            radius +
            '" y1="' +
            p +
            '" x2="' +
            radius +
            '" y2="' +
            q +
            '" stroke="black" stroke-width="' +
            strokeWidth +
            '" />';
        return path;
    }

    private createSvgMinusSign(radius: number, strokeWidth: number): string {
        const p = radius - radius * 0.4;
        const q = radius + radius * 0.4;
        return (
            '<line x1="' +
            p +
            '" y1="' +
            radius +
            '" x2="' +
            q +
            '" y2="' +
            radius +
            '" stroke="black" stroke-width="' +
            strokeWidth +
            '"  />'
        );
    }
}

type CircleSize = {
    r: number;
    cx: number;
    cy: number;
};
