import { Point2D } from '../shared/geom/point2D';
import { Rectangle2D } from '../shared/geom/rectangle2D';

import { ContourElementRefs } from './contour-element-refs';
import { ContourElementType } from './contour/contour-element-type';
import { LayerElementStoreService } from './layer-element-store.service';

declare var Snap: any;

export class GroupContourElementRefs {
    readonly contourId: string;
    children?: Map<string, ContourElementRefs> = new Map();
    transformData: {
        globalMatrix: Snap.Matrix;
        localMatrix: Snap.Matrix;
        globalBBox: Rectangle2D;
        localBBox: Rectangle2D;
    };
    private firstUpdate: boolean = true;

    constructor(contourId: string, private layerElementStore: LayerElementStoreService) {
        this.contourId = contourId;
        this.transformData = {
            globalMatrix: Snap.matrix(),
            localMatrix: Snap.matrix(),
            globalBBox: undefined,
            localBBox: undefined
        };
    }

    deleteAll() {
        if (this.children) {
            this.children.forEach(child => child.deleteAll());
        }
    }

    addChild(obj: {
        contourId: string;
        elements: {
            itemGroup?: Snap.Element;
            itemPath?: Snap.Element;
            itemImage?: Snap.Element;
            marginPath?: Snap.Element;
            itemText?: Snap.Element;
        };
    }): ContourElementRefs {
        const childElement = new ContourElementRefs(obj.contourId, this.layerElementStore);
        childElement[ContourElementType.ITEM_GROUP] = obj.elements.itemGroup;
        childElement[ContourElementType.ITEM_PATH] = obj.elements.itemPath;
        childElement[ContourElementType.ITEM_IMAGE] = obj.elements.itemImage;
        childElement[ContourElementType.MARGIN_PATH] = obj.elements.marginPath;
        childElement[ContourElementType.ITEM_TEXT] = obj.elements.itemText;

        this.children.set(obj.contourId, childElement);
        return childElement;
    }

    updateTransformData(scaleFactor: number, itemsLayerOffset: Point2D) {
        this.updateGroupTransformedData(scaleFactor, itemsLayerOffset);
    }

    updateGroupTransformedData(scaleFactor: number, itemsLayerOffset: Point2D) {
        if (!this.children || this.children.size < 1) {
            return;
        }

        const topLeft = new Point2D(Number.MAX_VALUE, Number.MAX_VALUE);
        const bottomRight = new Point2D(Number.MIN_VALUE, Number.MIN_VALUE);
        const minTransPt = new Point2D(Number.MAX_VALUE, Number.MAX_VALUE);

        this.children.forEach((childElementRef, childId) => {
            childElementRef.updateTransformData();

            bottomRight.x = Math.max(bottomRight.x, childElementRef.contour.globalBBox.x2);
            bottomRight.y = Math.max(bottomRight.y, childElementRef.contour.globalBBox.y2);

            topLeft.x = Math.min(topLeft.x, childElementRef.contour.globalBBox.x);
            topLeft.y = Math.min(topLeft.y, childElementRef.contour.globalBBox.y);

            // changedEvent.globalBBox.x removes the margin size from
            minTransPt.x = Math.min(
                minTransPt.x,
                (childElementRef.contour.globalMatrix as any).e +
                    childElementRef.contour.localBBox.x * scaleFactor
            );
            minTransPt.y = Math.min(
                minTransPt.y,
                (childElementRef.contour.globalMatrix as any).f +
                    childElementRef.contour.localBBox.y * scaleFactor
            );
        });

        const globalBounds = new Rectangle2D(
            topLeft.x,
            topLeft.y,
            bottomRight.x - topLeft.x,
            bottomRight.y - topLeft.y
        );

        if (this.firstUpdate) {
            const localBounds = new Rectangle2D(
                0,
                0,
                globalBounds.width / scaleFactor,
                globalBounds.height / scaleFactor
            );

            const localTx = (topLeft.x - itemsLayerOffset.x) / scaleFactor;
            const localTy = (topLeft.y - itemsLayerOffset.y) / scaleFactor;
            const groupLocalMatrix = Snap.matrix(1, 0, 0, 1, localTx, localTy);
            this.transformData.localMatrix = groupLocalMatrix;
            this.transformData.localBBox = localBounds;
            this.firstUpdate = false;
        }

        const groupGlobalMatrix = Snap.matrix(
            scaleFactor,
            0,
            0,
            scaleFactor,
            minTransPt.x,
            minTransPt.y
        );

        this.transformData.globalMatrix = groupGlobalMatrix;
        this.transformData.globalBBox = globalBounds;
    }

    getTransformedData(
        isNewContour?: boolean
    ): {
        contourId: string;
        contour: {
            globalMatrix: Snap.Matrix;
            localMatrix: Snap.Matrix;
            globalBBox: Rectangle2D;
            localBBox: Rectangle2D;
        };
        children: { [contourId: string]: TransformedDataDesc };
        isNewContour: boolean;
    } {
        const childrenTransformData: { [contourId: string]: TransformedDataDesc } = {};
        // TODO cache this
        this.children.forEach((childRef, childCid) => {
            childrenTransformData[childCid] = {
                contourId: childCid,
                contour: childRef.contour,
                margin: childRef.margin,
                isNewContour: isNewContour || false
            };
        });

        return {
            contourId: this.contourId,
            contour: this.transformData,
            children: childrenTransformData,
            isNewContour: isNewContour || false
        };
    }
}

export interface TransformedDataDesc {
    contourId: string;
    margin?: {
        marginPathString?: string;
        globalMatrix: Snap.Matrix;
        localMatrix: Snap.Matrix;
        globalBBox: Rectangle2D;
        localBBox: Rectangle2D;
    };
    contour?: {
        svgPathDefinition?: string;
        globalMatrix: Snap.Matrix;
        localMatrix: Snap.Matrix;
        globalBBox: Rectangle2D;
        localBBox: Rectangle2D;
    };
    isNewContour: boolean;
}
