import { Rectangle2D } from './rectangle2D';

declare var Snap: any;

// Taken from https://github.com/SVG-Edit/svgedit/blob/a3f0b8e50196a55780271ecdab6e1c8a0938783a/editor/math.js
export function transformPoint(x, y, m) {
    return { x: m.a * x + m.c * y + m.e, y: m.b * x + m.d * y + m.f };
}

/**
 * create a new matrix to prepend the translation about deltaX/deltaX before the rotation
 * @param x
 * @param y
 * @param element
 */
export function prependTranslation(x: number, y: number, element: Snap.Element) {
    const newMatrix = Snap.matrix(1, 0, 0, 1, x, y);
    newMatrix.add(element.transform().localMatrix);
    element.node.setAttribute('transform', newMatrix.toString());
    //element.transform(newMatrix.toString());
}

export function getLocalMatrix(node: SVGElement, bbox: Rectangle2D): Snap.Matrix {
    let tstr = node.getAttribute('transform');
    if (!tstr) {
        return new Snap.Matrix();
    }
    tstr = Snap._.svgTransform2string(tstr);
    return Snap._.transform2matrix(tstr, bbox);
}

export function transformMatrix(
    node: SVGElement,
    localBBox: Rectangle2D
): { globalMatrix: Snap.Matrix; localMatrix: Snap.Matrix } {
    return {
        globalMatrix: Snap.matrix((node as any).getCTM()),
        localMatrix: getLocalMatrix(node as any, localBBox)
    };
}
