export class Point2D {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    static fromObject(obj: { x: number; y: number }): Point2D {
        return new Point2D(obj.x, obj.y);
    }

    static distance(x1: number, y1: number, x2: number, y2: number): number {
        x2 -= x1;
        y2 -= y1;
        return Math.sqrt(x2 * x2 + y2 * y2);
        /*  const num = x2 * x2 + y2 * y2;
        if (num === 0 || num === 1) {
            return num;
        }
        let res = cacheSqrt[num];
        if (res == null) {
            res = Math.sqrt(num);
            cacheSqrt[num] = res;
        }
        return res; */
    }

    static squareDistance(x1: number, y1: number, x2: number, y2: number): number {
        x2 -= x1;
        y2 -= y1;
        return x2 * x2 + y2 * y2;
    }

    setLocation(p: Point2D) {
        this.x = p.x;
        this.y = p.y;
    }

    distanceToPoint(pt: Point2D) {
        const x1 = pt.x - this.x;
        const y1 = pt.y - this.y;
        return Math.sqrt(x1 * x1 + y1 * y1);
    }

    clone(): Point2D {
        return new Point2D(this.x, this.y);
    }

    isEqual(otherOffset: Point2D) {
        return this.x === otherOffset.x && this.y === otherOffset.y;
    }
}
const cacheSqrt: { [key: number]: number } = [];
