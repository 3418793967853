import {
    AfterContentInit,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Type,
    ViewChild
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CanvasService } from '../../../canvas/canvas.service';
import { RibbonSelectionService } from '../../ribbon-selection.service';
import { NavigateResult } from '../../expansion-panel/accordion-navigator.service';
import { TabItemInfo } from '../../ribbon.component';
import { PreviewContourService } from '../grip-text-contour-panel/preview-contour.service';
import { RibbonDialogContentDirective } from '../ribbon-dialog-content.directive';
import { AlignmentRibbonDialogComponent } from '../ribbon-dialog/alignment-dialog/alignment-ribbon-dialog.component';
import { RibbonDialog } from '../ribbon-dialog/ribbon-dialog';
import { AccordionRibbonPanelContent, RibbonPanelContent } from '../ribbon-panel-content';
import { RibbonToolbarContentDirective } from '../ribbon-toolbar-content.directive';
import { FoamLayerDirective } from '../../../canvas/layers/foam-layer.directive';

@Component({
    selector: 'app-ribbon-panel-content',
    templateUrl: './ribbon-panel-content.component.html',
    styleUrls: ['./ribbon-panel-content.component.scss'],
    providers: [PreviewContourService]
})
export class RibbonPanelContentComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild(RibbonToolbarContentDirective, { static: false })
    readonly panelHost: RibbonToolbarContentDirective;

    panelTitle: string;
    panelIndex: number;

    @ViewChild(RibbonDialogContentDirective, { static: false })
    readonly dialogHost: RibbonDialogContentDirective;

    private cache = new Map<string, ComponentFactory<any>>();
    private _currentTabInfo: TabItemInfo;
    private currentComponentRef: ComponentRef<RibbonPanelContent>;

    /* Async EventEmitter as workaround for ExpressionChangedAfterItHasBeenCheckedError */
    panelLoaded: EventEmitter<void> = new EventEmitter<void>(true);
    private afterNavigatorInitSub: Subscription;
    private currentDialogComponentRef: ComponentRef<AlignmentRibbonDialogComponent>;

    private onDestroy = new Subject();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private ribbonSelectionService: RibbonSelectionService,
        private canvasService: CanvasService,
        private previewContourService: PreviewContourService
    ) {}

    ngOnInit() {
        this.canvasService
            .getOnScaleFactorChanged()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(scaleVal => {
                this.previewContourService.scaleDimensions(scaleVal);
            });
        this.previewContourService.scaleDimensions(this.canvasService.getScaleFactor());
    }

    ngAfterContentInit(): void {}

    @Input()
    set panel(value: TabItemInfo) {
        if (value) {
            if (this._currentTabInfo !== value) {
                this._currentTabInfo = value;
                this.panelTitle = value.title;
                this.panelIndex = value.index;

                // TODO lazy loading: detach and insert the dialog instead of destroying it
                if (this.currentDialogComponentRef) {
                    this.currentDialogComponentRef.destroy();
                }
                this.loadPanel(value.id, value.panelComponent);
            }
        }
    }

    get panel() {
        return this._currentTabInfo;
    }

    private loadPanel(name: string, component: Type<RibbonPanelContent>) {
        // TODO use rxjs to resolve the factory??

        let componentFactory = this.cache.get(name);
        if (!componentFactory) {
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            this.cache.set(name, componentFactory);
        }

        const viewContainerRef = this.panelHost.viewContainerRef;
        viewContainerRef.clear();
        this.currentComponentRef = viewContainerRef.createComponent(componentFactory);

        if (this.isAccordionPanel()) {
            const accordionPanel = this.currentComponentRef.instance as AccordionRibbonPanelContent;
            accordionPanel.afterNavigatorInit.pipe(take(1)).subscribe(() => {
                this.panelLoaded.emit();
            });

            // accordionPanel.appAccordion.id
        } else {
            this.panelLoaded.emit();
        }

        // Close and destroy the ribbon dialog without changing the panel viewContainer
        this.closeRibbonDialog(true);

        // TODO destroy ComponentRef
    }

    loadRibbonDialog(component: Type<RibbonDialog>) {
        let componentFactory = this.cache.get(name);
        if (!componentFactory) {
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            this.cache.set(name, componentFactory);
        }

        /* TODO lazy loading: detach and insert the dialog instead of destroying it */
        /**
        if (
            this.currentDialogComponentRef &&
            this.currentDialogComponentRef.componentType === component
        ) {
            this.dialogHost.viewContainerRef.insert(this.currentDialogComponentRef.hostView); } */
        const viewContainerRef = this.dialogHost.viewContainerRef;

        viewContainerRef.clear();
        this.currentDialogComponentRef = viewContainerRef.createComponent(componentFactory);
        const dialogPanel = this.currentDialogComponentRef.instance as RibbonDialog;
        dialogPanel.panelIndex = this._currentTabInfo.index;
        dialogPanel.panelTitle = this._currentTabInfo.title;

        this.ribbonSelectionService.openedRibbonDialog = dialogPanel;
        dialogPanel.closed.subscribe(() => {
            this.closeRibbonDialog();
        });

        // Hide panel content
        if (this.currentComponentRef) {
            this.panelHost.viewContainerRef.detach();
        }
    }

    public closeRibbonDialog(withoutInsertion?: boolean) {
        if (this.currentDialogComponentRef) {
            this.currentDialogComponentRef.destroy();
            if (!withoutInsertion) {
                this.panelHost.viewContainerRef.insert(this.currentComponentRef.hostView);
            }
            this.currentDialogComponentRef = null;
            this.ribbonSelectionService.openedRibbonDialog = null;
        }
    }

    expandNextExpansionItem(): NavigateResult | undefined {
        if (this.isAccordionPanel()) {
            const accordionPanel = this.currentComponentRef.instance as AccordionRibbonPanelContent;
            return accordionPanel.expandNextItem();
        }
        return undefined;
    }

    expandPreviousExpansionItem(): NavigateResult | undefined {
        if (this.isAccordionPanel()) {
            const accordionPanel = this.currentComponentRef.instance as AccordionRibbonPanelContent;
            return accordionPanel.expandPreviousItem();
        }
        return undefined;
    }

    expandLastItem() {
        if (this.isAccordionPanel()) {
            const accordionPanel = this.currentComponentRef.instance as AccordionRibbonPanelContent;
            accordionPanel.expandLastItem();
        }
    }

    getExpandedIndex(): number | undefined {
        if (this.isAccordionPanel()) {
            const accordionPanel = this.currentComponentRef.instance as AccordionRibbonPanelContent;
            return accordionPanel.getExpandedIndex();
        }
        return undefined;
    }

    getExpandedItemSize(): number | undefined {
        if (this.isAccordionPanel()) {
            const accordionPanel = this.currentComponentRef.instance as AccordionRibbonPanelContent;
            return accordionPanel.getExpandedItemSize();
        }
        return undefined;
    }

    onAccordionItemExpanded(): Observable<number> | undefined {
        if (!this.isAccordionPanel()) {
            return undefined;
        }

        const accordionPanel = this.currentComponentRef.instance as AccordionRibbonPanelContent;
        return accordionPanel.onAccordionItemExpanded();
    }

    isAccordionPanel(): boolean {
        const componentRef = this.currentComponentRef;
        return (
            componentRef &&
            (componentRef.instance as AccordionRibbonPanelContent).appAccordion !== undefined
        );
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }
}
