import { ElementRef, Injectable } from '@angular/core';
import { FixedCollisionLayerDirective } from './layers/fixed-collision-layer.directive';
import { Observable, Subject } from 'rxjs';
import { SelectionLayerDirective } from './layers/selection-layer.directive';
import { CanvasOverlayLayerDirective } from './layers/canvas-overlay-layer.directive';
import { CollisionLayerDirective } from './layers/collision-layer.directive';
import { ContourItemsLayerDirective } from './layers/contour-items-layer.directive';
import { DroppableContainerDirective } from '../../droppable/droppable-container.directive';
import { FoamLayerDirective } from './layers/foam-layer.directive';

@Injectable()
export class CanvasDomService {
    // private domElements: { [elment: string]: HTMLElement } = {};

    private fixedCollisionLayerDirectiveSource = new Subject<FixedCollisionLayerDirective>();
    onFixedCollisionLayerChanged: Observable<
        FixedCollisionLayerDirective
    > = this.fixedCollisionLayerDirectiveSource.asObservable();

    private _collisionLayer: CollisionLayerDirective;
    private _fixedCollisionLayer: FixedCollisionLayerDirective;
    private _selectionLayer: SelectionLayerDirective;
    private _canvasOverlayLayer: CanvasOverlayLayerDirective;
    private _contourItemsLayer: ContourItemsLayerDirective;
    private _droppableContainer: DroppableContainerDirective;
    private _foamLayer: FoamLayerDirective;
    private _textContourInputElement: ElementRef;
    svgCanvas: ElementRef;

    get textContourInputElement(): ElementRef {
        return this._textContourInputElement;
    }

    set textContourInputElement(value: ElementRef) {
        this._textContourInputElement = value;
    }

    set foamLayer(value: FoamLayerDirective) {
        if (value) {
            this._foamLayer = value;
        }
    }

    get foamLayer() {
        return this._foamLayer;
    }

    set contourItemsLayer(value: ContourItemsLayerDirective) {
        if (value) {
            this._contourItemsLayer = value;
        }
    }

    get contourItemsLayer() {
        return this._contourItemsLayer;
    }

    set collisionLayer(value: CollisionLayerDirective) {
        if (value) {
            this._collisionLayer = value;
        }
    }

    get collisionLayer() {
        return this._collisionLayer;
    }

    set fixedCollisionLayer(value: FixedCollisionLayerDirective) {
        if (value) {
            this._fixedCollisionLayer = value;
            this.fixedCollisionLayerDirectiveSource.next(value);
        }
    }

    get fixedCollisionLayer() {
        return this._fixedCollisionLayer;
    }

    set selectionLayer(value: SelectionLayerDirective) {
        this._selectionLayer = value;
    }

    get selectionLayer() {
        return this._selectionLayer;
    }

    set canvasOverlayLayer(value: CanvasOverlayLayerDirective) {
        this._canvasOverlayLayer = value;
    }

    get canvasOverlayLayer() {
        return this._canvasOverlayLayer;
    }

    set droppableContainer(value: DroppableContainerDirective) {
        if (value) {
            this._droppableContainer = value;
        }
    }

    get droppableContainer() {
        return this._droppableContainer;
    }
}
