import {
    AfterContentChecked,
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    OnDestroy,
    Output,
    QueryList,
    ViewEncapsulation
} from '@angular/core';
import { RibbonTabItemComponent } from './ribbon-tab-item/ribbon-tab-item.component';
import { merge, Subscription } from 'rxjs';

@Component({
    selector: 'app-ribbon-tabs',
    templateUrl: './ribbon-tabs.component.html',
    host: {
        class: 'ribbon__tabs'
    },
    styleUrls: ['./ribbon-tabs.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RibbonTabsComponent implements AfterContentInit, OnDestroy, AfterContentChecked {
    selectedTab: RibbonTabItemComponent;

    @Output()
    readonly selectChanged: EventEmitter<RibbonTabItemComponent> = new EventEmitter(true);

    @Output()
    readonly tabClicked: EventEmitter<RibbonTabItemComponent> = new EventEmitter();

    @ContentChildren(RibbonTabItemComponent)
    _tabItems: QueryList<RibbonTabItemComponent>;
    private tabSelectedSubscription: Subscription;

    constructor() {
        // this.activeId = this.tabItems[0].id;
    }

    ngAfterContentChecked() {}

    ngAfterContentInit() {
        const selectedTabs = this._tabItems.filter(tab => tab.selected);
        const tabToSelect = selectedTabs.length > 0 ? selectedTabs[0] : this._tabItems.first;
        // Run in promise as workaround for ExpressionChangedAfterItHasBeenCheckedError
        this.selectTab(tabToSelect);

        this.tabSelectedSubscription = merge(
            ...this._tabItems.map(tab => tab.tabSelectedChange)
        ).subscribe((tabItem: RibbonTabItemComponent) => {
            this.selectTab(tabItem);
        });
    }

    selectTabById(itemId: string) {
        const itemToSelect = this._tabItems.find(x => x.itemId === itemId);
        this.selectTab(itemToSelect);
    }

    selectTab(tabItem: RibbonTabItemComponent) {
        if (tabItem !== this.selectedTab) {
            if (this.selectedTab) {
                this.selectedTab.selected = false;
            }
            this.selectedTab = tabItem;
            this.selectedTab.selected = true;
            this.selectChanged.emit(tabItem);
        }

        this.tabClicked.emit(tabItem);
    }

    isTabSelected(tabItem: RibbonTabItemComponent) {
        return this.selectedTab && this.selectedTab === tabItem;
    }

    ngOnDestroy(): void {
        this.selectChanged.complete();
        this.tabSelectedSubscription.unsubscribe();
    }
}
