import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleSwitchComponent implements OnInit {
    @Input()
    inputId: string;

    @Input()
    isDisabled: boolean = false;

    @ViewChild('labelElement', { static: false })
    readonly labelElement: ElementRef;

    @ViewChild('container', { static: false })
    readonly containerElement: ElementRef;

    @ViewChild('inputElement', { static: false })
    readonly inputElement: ElementRef;

    private _labelWidth: string;
    private _labelHeight: string;

    private _isChecked: boolean = false;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        if (this.inputId === null || this.inputId === '') {
            throw new Error('Attribute "inputId" is required');
        }
    }

    @Input()
    set checked(newValue: boolean) {
        if (this._isChecked !== newValue) {
            this._isChecked = newValue;
        }
    }

    get checked(): boolean {
        return this._isChecked;
    }

    @Input()
    set labelWidth(value: string) {
        if (value && this._labelWidth !== value) {
            this._labelWidth = value;
        }
    }

    get labelWidth(): string {
        return this._labelWidth;
    }

    onInputClick(event: Event) {
        event.stopPropagation();
        this.toggle();
        this.inputElement.nativeElement.checked = this.checked;
    }

    private toggle() {
        this.checked = !this.checked;
    }

    @Input()
    set labelHeight(value: string) {
        if (value && this._labelHeight !== value) {
            this._labelHeight = value;
        }
    }

    get labelHeight(): string {
        return this._labelHeight;
    }
}
