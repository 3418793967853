import { Observable } from 'rxjs';

import { CanvasService } from '../../canvas/canvas.service';
import { TextContour } from '../../canvas/contour/text-contour';
import { ESCAPE_VALUE } from '../../keycodes';
import { TextUndoableEditAction } from '../../undo/text-undoable-edit-action';
import { FoamEditorService } from '../../foam-editor.service';
import { SimpleDragEvent } from '../event-helpers';

import { Tool } from './tool';

export class TextEditingTool extends Tool {
    private readonly canvasService: CanvasService;
    private oldTextContent: string;
    constructor(private readonly textContour: TextContour, foamEditorService: FoamEditorService) {
        super(foamEditorService);
        this.canvasService = this.foamEditorService.getCanvasService();
    }

    deactivate(): void {
        super.deactivate();
        this.endEdit();
    }

    handleMouseDown(event: MouseEvent): void {
        event.preventDefault();
        if (this.textContour.containsTarget(event.target as Element)) {
            this.beginEdit(event);
        } else {
            // deactivate tool since another contour has been clicked
            this.endEdit();
            // forward the mouse down event to the newly activated tool
            // NOTE: this is needed to make the SelectionTool selecting the newly clicked contour
            this.foamEditorService.getTool().handleMouseDown(event);
        }
    }

    private beginEdit(event: MouseEvent) {
        this.canvasService.addToSelection([this.textContour]);

        const canvasXY = this.canvasService.clientXYToFoamLayer(event.clientX, event.clientY);
        this.textContour.selectText(canvasXY, true);
        this.oldTextContent = this.textContour.textContent;
    }

    private endEdit() {
        this.textContour.deselectTextInput(true);
        this.canvasService.removeFromSelection([this.textContour]);

        this.toolDoneSubject.next();
        this.canvasService
            .getUndoManagerService()
            .addEditAction(
                new TextUndoableEditAction(
                    this.textContour,
                    this.textContour.textContent,
                    this.oldTextContent
                )
            );
    }

    handleMouseDrag(event: SimpleDragEvent): void {
        event.originalEvent.preventDefault();
        if (this.textContour.isInEditMode) {
            const canvasXY = this.canvasService.clientXYToFoamLayer(event.x, event.y);
            this.textContour.extendSelection(canvasXY);
        }
    }

    handleMouseMove(event: MouseEvent): void {}

    handleMouseUp(event: MouseEvent): void {
        event.preventDefault();
    }

    getToolStarted(): Observable<any> {
        return undefined;
    }

    handleDbClick(event: MouseEvent): void {}

    handleKeyUp(event: KeyboardEvent) {
        if (event.key === ESCAPE_VALUE) {
            this.endEdit();
        }
    }
}
