import { UndoableEditaAction } from './undo-manager.service';
import { CanvasContour } from '../canvas/contour/contour-items-interfaces';

export class RotateUndoableEditAction implements UndoableEditaAction {
    presentationName: string;

    constructor(
        private readonly contours: CanvasContour[],
        private readonly angle: number,
        private readonly anchorX: number,
        private readonly anchorY: number,
        presentationName?: string
    ) {
        this.presentationName = presentationName || 'Rotate contour';
    }

    redo(): void {
        this.contours.forEach(contour => {
            contour.rotate(this.angle, this.anchorX, this.anchorY);
        });
    }

    undo(): void {
        this.contours.forEach(contour => {
            contour.rotate(-this.angle, this.anchorX, this.anchorY);
        });
    }
}
