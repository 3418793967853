import { ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { GroupContourElementHolder } from './contour-element-builder.service';
import { GroupContourElementRefs } from './group-contour-element-refs';
import * as i0 from "@angular/core";
export class LayerElementStoreService {
    constructor() {
        /**
         * @deprecated
         */
        this.contourElementsRefs = {};
        /**
         * @deprecated
         */
        this.svgTextInputs = {};
        this.foamContourChanged = new Subject();
        this.foamInnerMarginContourChanged = new Subject();
        this.elementChanges = [];
        this.elementChanged = new Subject();
        /* contourElementHolders: (
            |ContourElementHolder|TextContourElementHolder|GroupContourElementHolder)[] = []; */
        this.contourElementHolders = {};
    }
    /**
     * @deprecated
     * @param contourId
     */
    getContourElements(contourId) {
        return this.contourElementsRefs[contourId];
    }
    addGroupContourElements(contourId) {
        const groupContourRefs = this.getContourElements(contourId) ||
            new GroupContourElementRefs(contourId, this);
        this.contourElementsRefs[contourId] = groupContourRefs;
        return groupContourRefs;
    }
    setContourElements(contourId, elementHolder) {
        this.contourElementHolders[contourId] = elementHolder;
    }
    getContourElements2(contourId, withGroupChildren) {
        const result = this.contourElementHolders[contourId];
        if (!result && withGroupChildren) {
            for (const holder of Object.values(this.contourElementHolders)) {
                if (holder instanceof GroupContourElementHolder) {
                    const soughtHolder = holder.elementHolders[contourId];
                    if (soughtHolder) {
                        return soughtHolder;
                    }
                }
            }
        }
        return result;
    }
    /**
     * @param contourId
     */
    removeContourElements(contourId) {
        delete this.contourElementHolders[contourId];
    }
    setFoamContour(elementHolder) {
        this.foamElementHolder = elementHolder;
    }
    setFoamInnerMarginContour(elementHolder) {
        this.foamInnerMarginElementHolder = elementHolder;
    }
    notifyOnContourChanges() {
        if (this.elementChanges.length > 0) {
            this.elementChanged.next(Array.from(this.elementChanges));
            this.elementChanges = [];
        }
    }
    addContourElementChanges(change) {
        this.elementChanges.push(change);
    }
    notifyFoamContourChange(changedEvent) {
        this.foamContourChanged.next(changedEvent);
    }
    notifyFoamInnerMarginContourChange(changedEvent) {
        this.foamInnerMarginContourChanged.next(changedEvent);
    }
    getFoamContourChange() {
        return this.foamContourChanged.asObservable();
    }
    getFoamInnerMarginContourChange() {
        return this.foamInnerMarginContourChanged.asObservable();
    }
    getSVGTextInput(contourId) {
        return this.svgTextInputs[contourId];
    }
    /**
     * @deprecated
     * @param contourId
     */
    removeSVGTextInput(contourId) {
        const inlInput = this.svgTextInputs[contourId];
        if (inlInput) {
            inlInput.destroy();
            delete this.svgTextInputs[contourId];
        }
    }
}
LayerElementStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayerElementStoreService_Factory() { return new LayerElementStoreService(); }, token: LayerElementStoreService, providedIn: "root" });
