import * as ItemPathUtil from './item-path-util';
import { pathString2Polygon } from '../contour/contour-helper';
declare var Snap: any;

export class MarginUtils {
    static createMarginPathString(
        svgPathDefinition: string,
        marginSize: number
    ): string | undefined {
        if (!svgPathDefinition || marginSize === undefined || marginSize === null) {
            return undefined;
        }
        const pathSegments = Snap.parsePathString(svgPathDefinition);

        return ItemPathUtil.computeSafetyMarginPath(pathSegments, marginSize);
    }

    /**
     * returns marginedPath for d attribute of path
     * @param svgPathDefinition
     * @param absoluteMarginDistanceMM
     */
    public static createInnerMarginFromSvgPath(
        svgPathDefinition: string,
        absoluteMarginDistanceMM: number
    ) {
        const path = pathString2Polygon(svgPathDefinition, Snap.matrix(1, 0, 0, 1, 0, 0));

        const pathDef: string[] = [];
        path.forEach((value, index) => {
            if (index === 0) {
                pathDef.push(`M${value.startX},${value.startY}`);
                pathDef.push(`L${value.endX},${value.endY}`);
            } else {
                pathDef.push(`L${value.startX},${value.startY}`);
                pathDef.push(`L${value.endX},${value.endY}`);
            }
        });

        const strPath = pathDef.join(';');

        const innerMarginedPath = MarginUtils.createMarginPathString(
            strPath,
            -absoluteMarginDistanceMM
        );
        return innerMarginedPath;
    }
}
