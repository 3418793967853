import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-accordion-toolbar',
    templateUrl: './accordion-topbar.component.html',
    styleUrls: ['./accordion-topbar.component.scss']
})
export class AccordionTopbarComponent {
    @Input()
    panelTitle: string;

    @Input()
    panelIndex: number;

    constructor() {}
}
