import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { BBox } from 'snapsvg';

import { FoamEditorService } from '../../foam-editor.service';
import { Rectangle2D } from '../../shared/geom/rectangle2D';
import { Handle } from '../../shared/handle/handle';
import { Tool } from '../../shared/tool/tool';
import { PolygonSegment } from '../collision/collision-util';
import { nextUniqueId } from '../../../utils/unique-id';
import { CanvasElementChangedEvent } from '../canvas.component';
import { CanvasService } from '../canvas.service';

import {
    CLASS_NAME_CONTOUR_GROUP,
    CLASS_NAME_CONTOUR_IMAGE,
    CLASS_NAME_CONTOUR_PATH,
    CLASS_NAME_CONTOUR_SELECTED,
    CLASS_NAME_MARGIN_GROUP,
    CLASS_NAME_MARGIN_PATH
} from './constants';
import {
    ContourChangeData,
    ContourHandlesData,
    ContourStateData,
    ContourType,
    CuttableContourDrawData,
    ItemProperties,
    MarginContourCollisionVizData,
    CanvasContour
} from './contour-items-interfaces';
import { GroupCuttableContour } from './group-cuttable-contour';
import { TextSelectionData } from './text-contour';

declare var Snap: any;
export abstract class CuttableContour implements CanvasContour {
    contourId: string;
    /**
     * @deprecated
     */
    contourType: ContourType;

    /**
     * @Deprecated
     */
    itemElement: Snap.Element | undefined;

    /**
     * @Deprecated
     */
    marginPath: Snap.Element | undefined;
    // TODO REMOVE ME

    marginPathString: string | undefined;
    // TODO REMOVE ME
    /**
     * @deprecated
     */
    marginPolygonLines: Array<PolygonSegment> | undefined;
    // TODO REMOVE ME
    /**
     * @deprecated
     */
    itemTransformation: Snap.TransformationDescriptor | undefined;
    readonly itemProps: ItemProperties;

    contourBoundsInParent: Rectangle2D;
    localContourPathBBox: Rectangle2D;
    localMarginPathBBox: Rectangle2D;
    globalContourPathBBox: Rectangle2D;
    globalMarginPathBBox: Rectangle2D;
    globalContourPathMatrix: Snap.Matrix;
    localContourPathMatrix: Snap.Matrix;
    localMarginPathMatrix: Snap.Matrix;
    globalMarginPathMatrix: Snap.Matrix;

    pathSegments: Array<any> | undefined;
    svgPathDefinition: string;
    polygonLines: Array<PolygonSegment> | undefined;

    /** Prevent contour from being rotated **/
    isRotatable: boolean = true;
    abstract isCollidable: boolean;
    abstract isRemovable: boolean;
    abstract isSelectable: boolean;
    abstract isResizable: boolean;
    depth: number;
    height: number;
    width: number;

    marginSize: number;

    protected contourChanged: Subject<ContourStateData> = new Subject();
    private contourRemoved: Subject<void> = new Subject();
    protected canvasService: CanvasService;
    protected visible: boolean = true;

    parentContour: GroupCuttableContour | null;

    // TODO add constructor with CuttableContourArgs

    protected constructor(obj: CuttableContourArgs) {
        const setterFn = (value: any, def: any) => (value == null ? def : value);

        this.contourId = obj.contourId || nextUniqueId();

        this.marginSize = setterFn(obj.marginSize, 9);

        this.itemProps = setterFn(obj.itemProps, {
            itemPathId: CLASS_NAME_CONTOUR_PATH + this.contourId,
            imagePathId: CLASS_NAME_CONTOUR_IMAGE + this.contourId,
            marginPathId: CLASS_NAME_MARGIN_PATH + this.contourId,
            classNamePath: CLASS_NAME_CONTOUR_PATH.slice(0, -1),
            classNameGroup: CLASS_NAME_CONTOUR_GROUP + this.contourId,
            classNameMarginPath: CLASS_NAME_MARGIN_PATH.slice(0, -1),
            classNameMarginGroup: CLASS_NAME_MARGIN_GROUP + this.contourId,
            classNameItemSelected: CLASS_NAME_CONTOUR_SELECTED
        });
    }

    abstract contains(px: number, py: number): boolean;

    abstract containsTarget(target: Element): boolean;

    abstract createHandles(): Handle[];

    abstract getHandlesDrawData(): ContourHandlesData;

    abstract getCollisionData(): MarginContourCollisionVizData;

    abstract getDrawData(): CuttableContourDrawData;

    getOnChanged(): Observable<ContourStateData> {
        return this.contourChanged.pipe(share());
    }

    getOnRemoved(): Observable<void> {
        return this.contourRemoved.pipe(share());
    }

    abstract getTool(target: Element, editor?: FoamEditorService): Tool | null;

    abstract onContourElementDrawn(drawnData: CanvasElementChangedEvent): void;

    remove() {
        this.contourRemoved.next();
    }

    setCanvas(canvas: CanvasService) {
        this.canvasService = canvas;
    }

    getCanvas(): CanvasService {
        return this.canvasService;
    }

    transform(matrix: Snap.Matrix, stopEvent?: boolean): ContourChangeData {
        const txString = matrix.toTransformString();

        const txSplitted = matrix.split();
        const contourChangeData = new ContourChangeData({
            contourId: this.contourId,
            transformString: txString,
            matrix: matrix,
            x: txSplitted.dx,
            y: txSplitted.dy,
            handles: new ContourHandlesData({
                contourId: this.contourId,
                transformBounds: {
                    localContourBounds: this.localContourPathBBox,
                    localMarginBounds: this.localMarginPathBBox,
                    selectionBoundsMatrix: matrix
                }
            })
        });
        if (!stopEvent) {
            this.contourChanged.next(contourChangeData);
        }

        return contourChangeData;
    }

    rotate(
        angle: number,
        anchorX: number,
        anchorY: number,
        stopEvent?: boolean
    ): ContourChangeData {
        let matrix: Snap.Matrix;
        if (this.localContourPathMatrix) {
            matrix = this.localContourPathMatrix.clone().rotate(angle, anchorX, anchorY);
        } else {
            matrix = Snap.matrix().rotate(angle, anchorX, anchorY);
        }

        const contourChangeData = new ContourChangeData({
            contourId: this.contourId,
            transformString: matrix.toTransformString(),
            transformString2: matrix.toString(),
            matrix: matrix,
            x: (matrix as any).e,
            y: (matrix as any).f,
            rotate: {
                deltaAngle: angle,
                anchorX: anchorX,
                anchorY: anchorY
            },
            handles: new ContourHandlesData({
                contourId: this.contourId,
                transformBounds: {
                    localContourBounds: this.localContourPathBBox,
                    localMarginBounds: this.localMarginPathBBox,
                    selectionBoundsMatrix: matrix
                }
            })
        });

        if (!stopEvent) {
            this.contourChanged.next(contourChangeData);
        }
        return contourChangeData;
    }

    translate(dx: number, dy: number, stopEvent?: boolean): ContourChangeData {
        const matrix: Snap.Matrix = Snap.matrix();
        if (this.localContourPathMatrix) {
            // prepend the translation before the rotation
            matrix.translate(dx, dy).add(this.localContourPathMatrix.clone());
        } else {
            matrix.translate(dx, dy);
        }

        const contourChangeData = new ContourChangeData({
            contourId: this.contourId,
            transformString: matrix.toTransformString(),
            matrix: matrix,
            x: (matrix as any).e,
            y: (matrix as any).f,
            translate: {
                dx: dx,
                dy: dy
            },
            handles: new ContourHandlesData({
                contourId: this.contourId,
                transformBounds: {
                    localContourBounds: this.localContourPathBBox,
                    localMarginBounds: this.localMarginPathBBox,
                    selectionBoundsMatrix: matrix
                }
            })
        });

        // this.localContourPathMatrix = matrix;

        if (!stopEvent) {
            this.contourChanged.next(contourChangeData);
        }

        return contourChangeData;
    }

    getSelectionData(): TextSelectionData | undefined {
        return undefined;
    }

    seVisibility(newValue: boolean) {
        if (this.visible !== newValue) {
            this.visible = newValue;
            this.contourChanged.next(
                new ContourChangeData({
                    contourId: this.contourId,
                    visibility: this.visible
                })
            );
        }
    }

    getVisibility(): boolean {
        return this.visible;
    }
}

export interface CuttableContourArgs {
    contourId?: string;
    svgPathDefinition?: string;
    itemProps?: ItemProperties;
    marginPathString?: string;
    marginSize?: number;
    isSelectable?: boolean;
    isCollidable?: boolean;
    isResizable?: boolean;
    pathGlobalBBox?: BBox;
    pathLocalBBox?: BBox;
    marginPathLocalBBox?: BBox;
    marginPathGlobalBBox?: BBox;
}
