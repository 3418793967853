import { AfterViewInit, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { AccordionNavigator, NavigateResult } from '../expansion-panel/accordion-navigator.service';
import { AccordionDirective } from '../expansion-panel/accordion.directive';
import { ExpansionPanelComponent } from '../expansion-panel/expansion-panel.component';

export interface RibbonPanelContent {
    panelTitle: string;
    panelIndex: number;
    panelId: string;
}

export function RibbonPanelContent<T extends Constructor>(Base = class {} as T) {
    return class extends Base {
        panelTitle: string;
        panelIndex: number;
        panelId: string;
    };
}

// TODO can we get rid of this interface when ssuing mxinxs?
export interface AccordionRibbonPanelContent extends RibbonPanelContent, OnDestroy, AfterViewInit {
    appAccordion: AccordionDirective;
    expansionPanels: QueryList<ExpansionPanelComponent>;
    afterNavigatorInit: Observable<false>;
    expandPreviousItem(): NavigateResult | undefined;
    expandNextItem(): NavigateResult | undefined;
    expandLastItem();
    getExpandedIndex(): number;
    getExpandedItemSize(): number;
    onAccordionItemExpanded(): Observable<number>;
    ngAfterViewInit(): void;
    ngOnDestroy(): void;
}

export function AccordionRibbonPanelContent<T extends Constructor>(Base = class {} as T) {
    return class extends Base implements OnDestroy, AfterViewInit {
        protected accordionNavigator: AccordionNavigator;
        appAccordion: AccordionDirective;
        expansionPanels: QueryList<ExpansionPanelComponent>;
        private _afterNavigatorInit: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
        afterNavigatorInit: Observable<boolean> = this._afterNavigatorInit.asObservable();

        ngOnDestroy(): void {}

        ngAfterViewInit(): void {
            this.accordionNavigator.setExpansionPanels(this.expansionPanels);
            this._afterNavigatorInit.next(true);
        }

        expandPreviousItem(): NavigateResult | undefined {
            return this.accordionNavigator.expandPreviousItem();
        }

        expandNextItem(): NavigateResult | undefined {
            return this.accordionNavigator.expandNextItem();
        }

        expandLastItem() {
            return this.accordionNavigator.expandLastItem();
        }

        getExpandedIndex(): number {
            return this.accordionNavigator.getExpandedIndex();
        }

        getExpandedItemSize(): number {
            return this.accordionNavigator.getExpandedItemSize();
        }

        onAccordionItemExpanded(): Observable<number> {
            return this.accordionNavigator.onAccordionItemExpanded;
        }
    };
}
