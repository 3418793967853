import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Directive,
    ElementRef,
    Host,
    HostBinding,
    ViewEncapsulation
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ExpansionPanelComponent } from './expansion-panel.component';

@Component({
    selector: 'app-expansion-panel-header',
    styleUrls: ['./expansion-panel-header.scss'],
    templateUrl: './expansion-panel-header.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'app-expansion-panel-header',
        role: 'button',
        '[attr.tabindex]': 'disabled ? -1 : 0',
        '[class.app-expanded]': '_isExpanded()',
        '[attr.aria-expanded]': '_isExpanded()',
        '(click)': 'onToggle()'
    }
})
export class ExpansionPanelHeaderComponent {
    private _parentChangeSubscription = Subscription.EMPTY;

    constructor(
        @Host() public panel: ExpansionPanelComponent,
        private _element: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        // Since the toggle state depends on an @Input on the panel, we
        // need to  subscribe and trigger change detection manually.
        this._parentChangeSubscription = merge(
            panel.opened,
            panel.closed
            // panel._inputChanges.pipe(filter(changes => !!(changes.hideToggle ||
            // changes.disabled)))
        ).subscribe(() => this._changeDetectorRef.markForCheck());

        // _focusMonitor.monitor(_element.nativeElement);
    }

    // @HostListener('click')
    onToggle(): void {
        this.panel.toggle();
    }

    /** Gets whether the expand indicator should be shown. */
    _showToggle(): boolean {
        return !this.panel.hideToggle;
    }

    _getPanelId(): string {
        return this.panel.id;
    }

    /** Gets whether the panel is expanded. */
    _isExpanded(): boolean {
        return this.panel.expanded;
    }

    /** Gets the expanded state string of the panel. */
    _getExpandedState(): string {
        return this.panel._getExpandedState();
    }

    get disabled() {
        return this.panel.disabled;
    }
}

/**
 *
 * This directive is to be used inside of the MatExpansionPanelHeader component.
 */
@Directive({
    selector: '[appExpansionPanelTitle]',
    host: {
        class: 'app-expansion-panel-header-title'
    }
})
export class AppExpansionPanelTitleDirective {}
