import {
    Directive,
    forwardRef,
    InjectionToken,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';

import { ScrollViewport } from './scroll-viewport.component';

export const INL_SCROLL_STRATEGY = new InjectionToken<InlScrollStrategy>('INL_SCROLL_STRATEGY ');

export interface InlScrollStrategy {
    onContentScrolled(): void;
    onItemsCountsChanged(): void;
    scrollUp(): void;
    scrollDown(): void;
    scrollToIndex(index: number): void;
    setScrollViewport(viewport: ScrollViewport);
    setItemSize(itemSize: number, spaceAmount: number);
}

export class DefaultScrollStrategy implements InlScrollStrategy {
    private viewport: ScrollViewport;
    private itemSize: number;
    private itemSpace: number;

    constructor(itemSize: number, itemSpace: number) {
        if (itemSpace < 0) {
            throw Error('item space cannot be negative');
        }

        this.itemSpace = itemSpace;
        this.itemSize = itemSize + itemSpace;
    }

    /** Called when the length of the data changes. */
    onItemsCountsChanged(): void {
        if (this.viewport && this.viewport.itemCount) {
            this.viewport.setTotalContentSize(
                this.viewport.itemCount * this.itemSize - this.itemSpace
            );
            this.viewport.updateViewportSize();
        }
    }

    /** Called when the viewport is scrolled */
    onContentScrolled(): void {}

    scrollUp(): void {
        if (this.viewport != null) {
            this.viewport.scrollToOffset(-this.itemSize);
        }
    }

    /* Scroll downwards or to the right */
    scrollDown(): void {
        if (this.viewport != null) {
            this.viewport.scrollToOffset(this.itemSize);
        }
    }

    /** Scroll to the offset for the given index. **/
    scrollToIndex(index: number): void {
        if (this.viewport && index > -1) {
            this.viewport.scrollToOffset(index * this.itemSize);
        }
    }

    setScrollViewport(viewport: ScrollViewport) {
        this.viewport = viewport;

        // fixed scroll strategy
        if (this.itemSize) {
            this.viewport.setTotalContentSize(
                this.viewport.itemCount * this.itemSize - this.itemSpace
            );
        }
    }

    setItemSize(itemSize: number, itemSpace: number) {
        if (itemSpace < 0) {
            throw Error('item space cannot be negative');
        }
        this.itemSize = itemSize + itemSpace;
        this.itemSpace = itemSpace;
    }
}

export function defaultScrollStrategyFactory(
    defaultScrollStrategyDir: DefaultScrollStrategyDirective
) {
    return defaultScrollStrategyDir.scrollStrategy;
}

@Directive({
    selector: 'app-scroll-viewport[itemSize]',
    providers: [
        {
            provide: INL_SCROLL_STRATEGY,
            useFactory: defaultScrollStrategyFactory,
            deps: [forwardRef(() => DefaultScrollStrategyDirective)]
        }
    ]
})
export class DefaultScrollStrategyDirective implements OnChanges {
    private _itemSize = 20;
    private _itemSpace = 0;
    scrollStrategy: DefaultScrollStrategy;

    constructor() {
        this.scrollStrategy = new DefaultScrollStrategy(this.itemSize, this.itemSpace);
    }

    /** The size of the items in the list (in pixels). */
    @Input()
    get itemSize(): number {
        return this._itemSize;
    }

    set itemSize(value: number) {
        this._itemSize = Number(value);
    }

    @Input()
    get itemSpace(): number {
        return this._itemSpace;
    }

    set itemSpace(value: number) {
        this._itemSpace = Number(value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.scrollStrategy.setItemSize(this.itemSize, this.itemSpace);
    }
}
