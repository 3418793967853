<div #actionToolbar class="ribbon__sub-toolbar">
    <div class="edit-actions-top">
        <ul class="history-actions">
            <li>
                <app-button
                    app-icon-button
                    [color]="'tertiary'"
                    buttonSize="x-small"
                    iconAlign="horizontal"
                    appTooltip
                    appToolTipContent="{{ 'MOUSEOVER.UNDO' | translate }}{{ undoActionName }}"
                    [disabled]="!canUndo"
                    [appTooltipPlacement]="'top'"
                    (click)="undo()"
                >
                    <app-icon shape="icon-arrow-back" cssWidth="27px" cssHeight="22px"></app-icon>
                </app-button>
            </li>
            <li>
                <app-button
                    app-icon-button
                    color="tertiary"
                    buttonSize="x-small"
                    iconAlign="horizontal"
                    appTooltip
                    appToolTipContent="{{ 'MOUSEOVER.REDO' | translate }}{{ redoActionName }}"
                    [appTooltipPlacement]="'top'"
                    [disabled]="!canRedo"
                    (click)="redo()"
                >
                    <app-icon
                        shape="icon-arrow-forward"
                        cssWidth="27px"
                        cssHeight="22px"
                    ></app-icon>
                </app-button>
            </li>
        </ul>

        <ul class="position-edit-actions">
            <li>
                <app-button
                    app-icon-button
                    color="tertiary"
                    buttonSize="normal"
                    iconAlign="horizontal"
                    class="stretch-button"
                    appTooltip
                    appToolTipContent="{{ 'MOUSEOVER.GROUP_OBJECTS' | translate }}"
                    [class.app-tertiary-selected]="isSelectedGrouped"
                    (click)="groupItems()"
                    [appTooltipPlacement]="'right'"
                >
                    <app-icon shape="icon-object-group" cssWidth="43px" cssHeight="40px"></app-icon>
                </app-button>
            </li>
            <li>
                <app-button
                    app-icon-button
                    [class.app-tertiary-selected]="isAlignmentDialogOpened"
                    color="tertiary"
                    buttonSize="normal"
                    iconAlign="horizontal"
                    class="stretch-button"
                    appTooltip
                    appToolTipContent="{{ 'MOUSEOVER.ALIGN_OBJECTS' | translate }}"
                    [appTooltipPlacement]="'right'"
                    (click)="toggleAlignmentDialog()"
                >
                    <app-icon
                        shape="icon-align-horizontal-middle"
                        cssWidth="43px"
                        cssHeight="40px"
                    ></app-icon>
                </app-button>
            </li>
        </ul>
    </div>
    <ul class="misc-edit-actions">
        <li>
            <div appDropZone [dropZoneId]="dropZoneId" [dragPreviewAreaElement]="actionToolbar">
                <app-button
                    app-icon-button
                    color="tertiary"
                    buttonSize="medium"
                    iconAlign="horizontal"
                    class="stretch-button"
                    (click)="deleteItem()"
                    appTooltip
                    appToolTipContent="{{ 'MOUSEOVER.DELETE' | translate }}"
                    [appTooltipPlacement]="'right'"
                >
                    <app-icon shape="bin" cssWidth="33px" cssHeight="45px"> </app-icon>
                </app-button>
            </div>
        </li>
    </ul>
</div>
