/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ribbon-tabs.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ribbon-tabs.component";
var styles_RibbonTabsComponent = [i0.styles];
var RenderType_RibbonTabsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RibbonTabsComponent, data: {} });
export { RenderType_RibbonTabsComponent as RenderType_RibbonTabsComponent };
export function View_RibbonTabsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_RibbonTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ribbon-tabs", [["class", "ribbon__tabs"]], null, null, null, View_RibbonTabsComponent_0, RenderType_RibbonTabsComponent)), i1.ɵdid(1, 3325952, null, 1, i2.RibbonTabsComponent, [], null, null), i1.ɵqud(603979776, 1, { _tabItems: 1 })], null, null); }
var RibbonTabsComponentNgFactory = i1.ɵccf("app-ribbon-tabs", i2.RibbonTabsComponent, View_RibbonTabsComponent_Host_0, {}, { selectChanged: "selectChanged", tabClicked: "tabClicked" }, ["app-ribbon-tab-item"]);
export { RibbonTabsComponentNgFactory as RibbonTabsComponentNgFactory };
