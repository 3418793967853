/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./droppable-mirror.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./droppable-mirror.component";
import * as i4 from "../droppable-state-store.service";
var styles_DroppableMirrorComponent = [i0.styles];
var RenderType_DroppableMirrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DroppableMirrorComponent, data: {} });
export { RenderType_DroppableMirrorComponent as RenderType_DroppableMirrorComponent };
export function View_DroppableMirrorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { svgPreviewContainer: 0 }), i1.ɵqud(671088640, 2, { container: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["container", 1]], null, 5, "div", [["class", "draggable-mirror-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "draggable-mirror-items"]], [[4, "display", null], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "svg-preview-item-container"]], [[4, "visibility", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["svgPreviewContainer", 1]], null, 0, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["id", "svg-preview-item"], ["version", "1.1"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.containerStyle(); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isMirroredNodeVisible ? "block" : "none"); var currVal_2 = _co.mirrorDraggedItem; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = (_co.isPreviewNodeVisible ? "visible" : "hidden"); _ck(_v, 6, 0, currVal_3); }); }
export function View_DroppableMirrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-droppable-mirror", [], null, null, null, View_DroppableMirrorComponent_0, RenderType_DroppableMirrorComponent)), i1.ɵdid(1, 4243456, null, 0, i3.DroppableMirrorComponent, [i1.ElementRef, i4.DroppableStateStore], null, null)], null, null); }
var DroppableMirrorComponentNgFactory = i1.ɵccf("app-droppable-mirror", i3.DroppableMirrorComponent, View_DroppableMirrorComponent_Host_0, { mirrorDraggedItem: "mirrorDraggedItem" }, {}, []);
export { DroppableMirrorComponentNgFactory as DroppableMirrorComponentNgFactory };
