import { ContourJSON, ModelMapper } from '../models/model.mapper';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { Action } from './actions/action';
import { DeleteAction } from './actions/delete.action';
import {
    DownMoveAction,
    LeftMoveAction,
    RightMoveAction,
    UpMoveAction
} from './actions/move-action';
import { CanvasService } from './canvas/canvas.service';
import { CircleContour } from './canvas/contour/circle-contour';
import { GroupCuttableContour } from './canvas/contour/group-cuttable-contour';
import { ImageContour } from './canvas/contour/image-contour';
import { RecessedGripContour } from './canvas/contour/recessed-grip-contour';
import { TextContour } from './canvas/contour/text-contour';
import { ContourProperties } from './contour-properties/contour-properties.component';
import { ImageContourModel, ProducerImageContourModel } from '../models/image-contour.model';
import {
    BACKSPACE_VALUE,
    DELETE_VALUE,
    DOWN_ARROW_VALUE,
    LEFT_ARROW_VALUE,
    RIGHT_ARROW_VALUE,
    UP_ARROW_VALUE
} from './keycodes';

import { SimpleDragEvent } from './shared/event-helpers';
import { SelectionTool } from './shared/tool/selection-tool';
import { Tool } from './shared/tool/tool';
import { RectangleContour } from './canvas/contour/rectangle-contour';
import { ConfiguratorStoreService } from '../configurator-store.service';
import { FoamContour } from './canvas/contour/foam-contour';
import { FoamContourModel } from '../models/foam-contour.model';
import { FoamConfigurationModel } from '../models/foam-configuration.model';
import {
    CollisionChangedEvent,
    StaticCollisionChangedEvent
} from './canvas/collision/collision-event-notifier';
import { CanvasContour } from './canvas/contour/contour-items-interfaces';
import { concat } from 'lodash';

/**
 * {Doc is derived from the JHotDraw}
 *
 * TODO add doc
 *
 */
@Injectable()
export class FoamEditorService implements OnDestroy {
    private defaultTool: Tool;
    private tool: Tool;
    private inputEvents: BehaviorSubject<EditorInputEvents> = new BehaviorSubject(null);
    private inputMap: Map<string, Action>;
    readonly foamConfigLoaded = new ReplaySubject<boolean>(1);
    private canBeSavedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _hasCollision: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private foamContour: FoamContourModel;
    private collidingItemsCount: number = 0;

    readonly collisionChange: Observable<boolean> = this._hasCollision.asObservable();

    constructor(
        private readonly canvasService: CanvasService,
        private readonly storeService: ConfiguratorStoreService,
        private ngZone: NgZone
    ) {
        this.setDefaultTool();
        this.inputMap = this.createInputMap();
    }

    public initialize() {
        this.storeService.initialDataLoaded.subscribe(() => {
            const foamConfig = this.storeService.getFoamConfiguration();
            this.setFoam(foamConfig.foamContour);
            this.foamConfigLoaded.next(true);
        });

        this.canvasService
            .getOnContourChanged()
            .pipe(debounceTime(300))
            .subscribe(() => {
                this.updateContourRemovability();
            });

        this.getCanvasService()
            .getOnContourAdded()
            .pipe(filter(() => this.canvasService.getAllContourItems().length === 1))
            .subscribe(c => this.updateCanBeSave());

        this.canvasService.getContourItemRemoved().subscribe(() => {
            // update canBeSave property only if the last one removed
            if (this.canvasService.getAllContourItems().length === 0) {
                this.updateCanBeSave();
            }
            this.updateContourRemovability();
        });

        this.canvasService
            .onStaticCollisionChanged()
            .subscribe((collisionEvt: StaticCollisionChangedEvent) => {
                if (collisionEvt.addedItems) {
                    this.collidingItemsCount = Object.keys(collisionEvt.addedItems).length;
                    this.ngZone.run(() => {
                        this._hasCollision.next(this.collidingItemsCount > 0);
                        this.updateCanBeSave();
                    });
                }
            });

        this.canvasService
            .onDynamicCollisionChanged()
            .subscribe((collisionEvt: CollisionChangedEvent) => {
                if (this.collidingItemsCount !== collisionEvt.totalCollidingItems) {
                    this.collidingItemsCount = collisionEvt.totalCollidingItems;
                    this.ngZone.run(() => {
                        this._hasCollision.next(this.collidingItemsCount > 0);
                        this.updateCanBeSave();
                    });
                }
            });
    }

    private updateCanBeSave() {
        const canBeSaved =
            this.collidingItemsCount === 0 && this.canvasService.getAllContourItems().length > 0;
        this.canBeSavedSubject.next(canBeSaved);
    }

    private setFoam(foamItem: FoamContourModel) {
        this.foamContour = foamItem;
        const canvasFoamContour = new FoamContour({
            contourId: 'foam-c-' + foamItem.id.toString(),
            svgPathDefinition: foamItem.svgPathDefinition,
            width: foamItem.width,
            height: foamItem.height,
            depth: foamItem.depth,
            innerMargin: foamItem.innerMargin
        });

        this.canvasService.setFoamContourItem(canvasFoamContour);
        this.canvasService.setProperties({
            isPartialCuttingEnable: this.isPartialCuttingEnable()
        });
    }

    getFoam(): Readonly<FoamContourModel> {
        return this.foamContour;
    }

    setDefaultTool() {
        this.setTool(this.getDefaultTool());
    }

    private getDefaultTool(): Tool {
        if (!this.defaultTool) {
            this.defaultTool = new SelectionTool(this);
        }
        return this.defaultTool;
    }

    /**
     * {Doc is derived from the JHotDraw}
     *
     * Calls deactivate on the previously active tool of this drawing editor.
     * Calls activate on the provided tool.
     * Forwards all mouse, mouse motion and keyboard events that occur on the DrawingView to the
     * provided tool.
     *
     * @param newValue
     */
    setTool(newValue: Tool) {
        if (newValue === this.tool) {
            return;
        }

        const oldValue = this.tool;
        if (oldValue) {
            oldValue.deactivate();
        }

        if (newValue !== null) {
            this.tool = newValue;
            this.tool.activate();
        }
    }

    getTool(): Readonly<Tool> {
        return this.tool;
    }

    setInputEvents(inputEvents: EditorInputEvents) {
        this.setEventHandlers(inputEvents);
        this.inputEvents.next(inputEvents);
    }

    getInputEvents(): Observable<EditorInputEvents> {
        return this.inputEvents.asObservable();
    }

    private createInputMap(): Map<string, Action> {
        const inMap = new Map<string, Action>();

        const deleteAction = new DeleteAction(this);

        inMap.set(BACKSPACE_VALUE, deleteAction);
        inMap.set(DELETE_VALUE, deleteAction);
        inMap.set(UP_ARROW_VALUE, new UpMoveAction(this, 1));
        inMap.set(DOWN_ARROW_VALUE, new DownMoveAction(this, 1));
        inMap.set(RIGHT_ARROW_VALUE, new RightMoveAction(this, 1));
        inMap.set(LEFT_ARROW_VALUE, new LeftMoveAction(this, 1));

        return inMap;
    }

    getInputMap(): Map<string, Action> | undefined {
        return this.inputMap;
    }

    // NOTE: we could have injected the CanvasService in the entry component, which would make this
    // getter obsolete. However, it feels more right to me to restrict the access to the
    // CanvasService in order to maintain the architecture clean. That is, only few components
    // should have access to the CanvasService. This will force us to always think about it
    getCanvasService(): CanvasService {
        return this.canvasService;
    }

    hasCollision(): boolean {
        return this._hasCollision.value;
    }

    /**
     * Emits when the one of the {@code ContourProperties} of the selected contour has changed.
     */
    getSelectedContourProperties(): Observable<ContourProperties> {
        const canvasService = this.getCanvasService();

        const changedItem = canvasService.getOnContourChanged().pipe(
            map(x => {
                if (canvasService.getSelectedContourItems().length === 1) {
                    return this.createContourProperties(x.contourId);
                } else {
                    return this.createEmptyContourProperties();
                }
            })
        );

        const selectedItem = canvasService.getOnSelectionChanged().pipe(
            map(data => {
                if (data.itemsToSelect && canvasService.getSelectedContourItems().length === 1) {
                    const contourId = data.itemsToSelect.values().next().value.contourId;
                    return this.createContourProperties(contourId);
                } else {
                    return this.createEmptyContourProperties();
                }
            })
        );

        return merge(changedItem, selectedItem).pipe(
            startWith(this.createEmptyContourProperties()),
            distinctUntilChanged()
        );
    }

    private createEmptyContourProperties(): ContourProperties {
        return {
            width: { value: '0', editable: false },
            height: { value: '0', editable: false },
            rotationDegree: { value: '0', editable: false },
            depth: { value: '0', editable: false }
        };
    }

    private createContourProperties(contourId: string): ContourProperties {
        const selectedContour = this.canvasService.getContourItem(contourId);

        let rotationDegree = selectedContour.localContourPathMatrix.split().rotate;
        if (rotationDegree < 0) {
            rotationDegree += 360;
        }

        if (selectedContour instanceof GroupCuttableContour) {
            return {
                width: { value: '', editable: false },
                height: { value: '', editable: false },
                rotationDegree: { value: rotationDegree.toFixed(0), editable: true },
                depth: { value: '', editable: false }
            };
        }

        const isWidthEditable = !(
            selectedContour instanceof ImageContour ||
            selectedContour instanceof RecessedGripContour ||
            selectedContour instanceof TextContour
        );
        const isHeightEditable = !(
            selectedContour instanceof ImageContour || selectedContour instanceof TextContour
        );
        const isRotationEditable = !(selectedContour instanceof CircleContour);
        const isDepthEditable = !(
            selectedContour instanceof ImageContour || selectedContour instanceof TextContour
        );

        const depth = selectedContour.depth ? selectedContour.depth.toFixed(0) : '0';
        return {
            width: {
                value: selectedContour.width.toFixed(0),
                editable: isWidthEditable
            },
            height: {
                value: selectedContour.height.toFixed(0),
                editable: isHeightEditable
            },
            rotationDegree: {
                value: rotationDegree.toFixed(0),
                editable: isRotationEditable
            },
            depth: {
                value: depth,
                editable: isDepthEditable
            }
        };
    }

    getFoamConfiguration(): FoamConfigurationModel {
        return this.storeService.getFoamConfiguration();
    }

    getPrice(): string {
        return this.storeService.getPrice();
    }

    getVat(): string {
        return this.storeService.getVat();
    }

    getUserItems(): Observable<ImageContourModel[]> {
        return this.storeService
            .getUserItems()
            .pipe(map(contours => this.setContourValidityFlag(contours)));
    }

    getToolFilterProducerName(): Observable<ReadonlyArray<string>> {
        return this.storeService.getToolFilterProducerName();
    }

    getToolFilterToolTypeName(): Observable<ReadonlyArray<string>> {
        return this.storeService.getToolFilterToolTypeName();
    }

    getPartnerItems(): Observable<ReadonlyArray<ProducerImageContourModel>> {
        return this.storeService
            .getPartnerItems()
            .pipe(map(contours => this.setContourValidityFlag(contours)));
    }

    private setContourValidityFlag(items: ReadonlyArray<ImageContourModel>): ImageContourModel[] {
        return items.map(item => {
            item.isValid = this.canvasService.isContourDepthValid(item.depth);
            return item;
        });
    }

    edit(contour: ImageContourModel): Observable<any> {
        return this.storeService.edit(contour);
    }

    delete(contour: ImageContourModel): Observable<boolean> {
        return this.storeService.delete(contour);
    }

    public isPartialCuttingEnable(): boolean {
        return this.storeService.isTeilversenkungActive();
    }

    public updateContourRemovability() {
        const dataStoreIds: string[] = this.canvasService
            .getAllContourItems()
            .filter(x => x instanceof ImageContour)
            .map(x => (<ImageContour>x).dataStoreId);
        this.storeService.updateUserContoursRemovability(dataStoreIds);
    }

    public canBeSaved(): Observable<boolean> {
        return this.canBeSavedSubject.pipe(distinctUntilChanged());
    }

    /**
     * Updates the given canvas image contour according to the datastore update.
     * @param canvasImageContour
     * @param datastoreContour
     */
    private updateCanvasContourFromDatastore(
        canvasImageContour: ImageContour,
        datastoreContour: ImageContourModel
    ) {
        if (canvasImageContour.depth !== datastoreContour.depth) {
            canvasImageContour.setDepth(datastoreContour.depth);
        }
        if (canvasImageContour.title !== datastoreContour.title) {
            canvasImageContour.title = datastoreContour.title;
        }
    }

    saveFoamConfig(): Observable<string> {
        return this.storeService.saveFoamConfig(this.getAllContour());
    }

    private contourToJson(item: CanvasContour): ContourJSON[] {
        if (item instanceof ImageContour) {
            const modelObj = this.storeService.getImageContourById(item.dataStoreId);
            return [ModelMapper.imageContourToJSON(item, modelObj)];
        } else if (item instanceof RectangleContour || item instanceof RecessedGripContour) {
            return [ModelMapper.rectangularContourToJSON(item)];
        } else if (item instanceof CircleContour) {
            return [ModelMapper.circleContourToJSON(item)];
        } else if (item instanceof TextContour) {
            return [ModelMapper.textContourToJSON(item)];
        } else if (item instanceof GroupCuttableContour) {
            // return ModelMapper.groupContourToJSON(item)?
            let data: ContourJSON[] = [];
            item.children.forEach(item => {
                data = data.concat(this.contourToJson(item));
            });
            return data;
        }
        throw Error('getAllContour Map Data error Type not found: \n' + JSON.stringify(item));
    }

    private getAllContour(): ContourJSON[] {
        let data: ContourJSON[] = [];
        this.canvasService.getAllContourItems().forEach(item => {
            data = data.concat(this.contourToJson(item));
        });
        console.log(data);
        return data;
    }

    ngOnDestroy(): void {}

    private setEventHandlers(inputEvents: EditorInputEvents) {
        this.ngZone.runOutsideAngular(() => {
            inputEvents.mouseDowns.subscribe(evt => this.getTool().handleMouseDown(evt));
            inputEvents.mouseMoves.subscribe(evt => this.getTool().handleMouseMove(evt));
            inputEvents.mouseDrag.subscribe(evt => this.getTool().handleMouseDrag(evt));
            inputEvents.mouseUps.subscribe(x => this.getTool().handleMouseUp(x));
            inputEvents.dblClicks.subscribe(evt => this.getTool().handleDbClick(evt));
            inputEvents.keyDown.subscribe(evt => this.getTool().handleKeyDown(evt));
            inputEvents.keyUp.subscribe(evt => this.getTool().handleKeyUp(evt));
        });
    }

    // TODO should be a constant
    getContoursStartDimensions(): Readonly<ContourStartDimensions> {
        // return an new object to prevent modification outside or deep-copy/cloe
        return {
            RECTANGLE: {
                width: 40,
                height: 25,
                cornerRadius: 2.5
            },
            CIRCLE: {
                radius: 40
            },
            RECESSED_GRIP: {
                width: 25,
                height: 40,
                cornerRadius: 12.5
            },
            TEXT: {
                width: 80,
                height: 30
            }
        };
    }
}

export interface CanvasExportData {
    foamData: ContourData;
    allContourData: ContourData[];
}

export interface ContourData {
    contourId: string;
    width: number;
    height: number;
    depth: number;
    svgPathDefinition: string;
    polygonLines: any;
    localBBox: any; // don' t care ATM (should be  Rectangle2d )
    localMatrix: any;
    textContent?: string;
    isTextElement: boolean;
    fontName?: string;
    fontSize?: number;
}

export interface EditorInputEvents {
    mouseDowns: Observable<MouseEvent>;
    mouseMoves: Observable<MouseEvent>;
    mouseUps: Observable<MouseEvent>;
    dblClicks?: Observable<MouseEvent>;
    mouseDrag?: Observable<SimpleDragEvent>;
    keyDown?: Observable<KeyboardEvent>;
    keyUp?: Observable<KeyboardEvent>;
}

// TODO specify cornerRadius as constant
export interface ContourStartDimensions {
    RECTANGLE: {
        width: number;
        height: number;
        cornerRadius: number;
    };
    CIRCLE: {
        radius: number;
    };
    RECESSED_GRIP: {
        width: number;
        height: number;
        cornerRadius: number;
    };
    TEXT: {
        width: number;
        height: number;
    };
}
