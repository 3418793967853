import { fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import {
    concatMap,
    filter,
    first,
    map,
    pairwise,
    share,
    switchMap,
    take,
    takeUntil,
    takeWhile,
    tap
} from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { DroppableItem } from './droppable-item';
import { DroppableContainer } from './droppable-container';
import { DropZoneDirective } from './drop-zone.directive';

export class DroppableHandlerService implements OnDestroy {
    private mouseUps: Observable<MouseEvent>;
    private mouseDowns: Observable<MouseEvent>;
    private mouseMoves: Observable<MouseEvent>;
    private mouseEnters: Observable<MouseEvent>;
    private mouseLeaves: Observable<MouseEvent>;
    private mouseEntersPreviewAreaElement: Observable<MouseEvent>;
    private mouseLeavesPreviewAreaElement: Observable<MouseEvent>;
    private dragReEntersPreviewArea: Observable<DroppableEvent>;

    private dragStarts: Observable<DroppableEvent>;
    private dragMoves: Observable<DroppableEvent>;
    private dragCancels: Observable<DroppableEvent>;
    private dragLeavesOnPreviewArea: Observable<DroppableEvent>;
    private dragEnters: Observable<DroppableEvent>;
    private dragLeaves: Observable<DroppableEvent>;
    private drops: Observable<any>;

    private draggableContainer: DroppableContainer;
    private droppableItems: DroppableItem[] = [];
    private dropZone: DropZoneDirective;
    private dropZoneElement: Element;

    private mirrorClass = 'contour-item-dragging';
    private mirrorElem: Element;

    private subscriptions: Subscription[] = [];
    private hasStarted = false;

    private serviceStarted: Subject<void> = new Subject();
    private isMouseDown: boolean = false;

    constructor(private readonly hasMoveableElements = false) {}

    private isReadyToStart() {
        return this.draggableContainer && this.dropZone && this.droppableItems.length > 0;
    }

    getDragStart(): Observable<DroppableEvent> {
        return this.dragStarts;
    }

    getDragEnter(): Observable<DroppableEvent> {
        return this.dragEnters;
    }

    getDragMove(): Observable<DroppableEvent> {
        return this.dragMoves;
    }

    getDragLeave(): Observable<DroppableEvent> {
        if (this.dragLeavesOnPreviewArea) {
            return merge(this.dragLeavesOnPreviewArea, this.dragLeaves);
        }
        return this.dragLeaves;
    }

    getDragReEnterPreviewArea(): Observable<DroppableEvent> {
        return this.dragReEntersPreviewArea;
    }

    getDragCancel(): Observable<DroppableEvent> {
        return this.dragCancels;
    }

    getOnDrop(): Observable<DroppableEvent> {
        return this.drops;
    }

    private start() {
        // no need to start listening for mouse events if the container or the dropZone is undefined
        if (this.hasStarted || !this.isReadyToStart()) {
            return;
        }

        this.hasStarted = true;
        const containerElem = this.draggableContainer.elementRef.nativeElement as HTMLElement;

        // DO NOT use preventDefault on mouseDown
        this.mouseDowns = fromEvent<MouseEvent>(containerElem, 'mousedown', {
            capture: true,
            passive: false
        }).pipe(
            tap((event: MouseEvent) => {
                // Stop propagation for mousedown only to prevent from prevent it
                // to start another drag event on a parent element
                event.stopPropagation();
                // avoid conflicts with the native HTML drag&drop
                if (event.target && (event.target as HTMLElement).draggable) {
                    event.preventDefault();
                }
                this.isMouseDown = true;
            })
        ) as Observable<MouseEvent>;

        // explicitly  set passive to false because newer browsers will default to true.
        // `preventDefault` is used to prevent the page from scrolling while the user is dragging.
        this.mouseMoves = fromEvent<MouseEvent>(window.document, 'mousemove', {
            capture: true,
            passive: false
        }).pipe(
            // As we're listening to the document root we should ensure that
            // preventDefault() is called only when the user clicks on the containerElem
            takeWhile(event => this.isMouseDown),
            tap((event: MouseEvent) => {
                // prevent native drag behaviour
                event.preventDefault();
            })
        ) as Observable<MouseEvent>;

        // Use window or documentElement (which refer to the root element <html>, otherwise mouseup might not be detected
        this.mouseUps = fromEvent<MouseEvent>(window, 'mouseup').pipe(
            tap(event => (this.isMouseDown = false))
        ) as Observable<MouseEvent>;

        // Prevent native drag behavior, especially for <img> elements
        containerElem.addEventListener('dragstart', evt => evt.preventDefault(), { capture: true });

        // disable text selection while dragging on FF and Safari
        window.document.addEventListener(
            'selectstart',
            evt => {
                if (this.isMouseDown) {
                    evt.preventDefault();
                }
            },
            { capture: true, passive: false }
        );

        this.createEventObservables();

        // All observables depend on the dragStart observable
        // The dependency chain: dragLeaves --> dragEnters --> dragMoves --> dragStart
        // IMPORTANT: when subscribing to the dragMoves Observables, the dragStart Observable will be subscribed again since the dragMoves
        // depends on it. Hence the dragMoves Observable will receive any dragStarts event twice
        // Therefore, we make all Observables shareable

        this.serviceStarted.next();
    }

    getOnServiceStart(): Observable<void> {
        return this.serviceStarted.pipe(share());
    }

    addContainer(container: DroppableContainer) {
        this.draggableContainer = container;
        this.start();
    }

    addDroppableItem(droppableItem: DroppableItem) {
        if (this.droppableItems.indexOf(droppableItem) > -1) {
            throw Error('droppableItem already exists');
        }
        this.droppableItems.push(droppableItem);
        this.start();
    }

    removeDroppableItem(element: Element) {
        const index = this.droppableItems.findIndex(x => x.nativeElement === element);
        if (index > -1) {
            this.droppableItems.splice(index, 1);
        }
    }

    addDropZone(dropZone: DropZoneDirective) {
        if (this.dropZone) {
            console.warn('drop zone already exists');
            return;
        }

        this.dropZone = dropZone;
        this.dropZoneElement = this.dropZone.elementRef.nativeElement;
        const previewAreaElement = this.dropZone.dragPreviewAreaElement;

        this.mouseEnters = fromEvent(this.dropZoneElement, 'mouseenter') as Observable<MouseEvent>;
        if (this.dropZone.dragPreviewAreaElement) {
            this.mouseEntersPreviewAreaElement = fromEvent(previewAreaElement, 'mouseenter', {
                capture: true
            }) as Observable<MouseEvent>;
            this.mouseLeavesPreviewAreaElement = fromEvent(previewAreaElement, 'mouseleave', {
                capture: true
            }) as Observable<MouseEvent>;
        }
        this.mouseLeaves = fromEvent(this.dropZoneElement, 'mouseleave') as Observable<MouseEvent>;

        // no need to start listening for mouse events if no container or no droppable elements are defined
        this.start();
    }

    private createEventObservables() {
        // NOSONAR
        if (this.hasMoveableElements) {
            this.initEventsForMoveableElements();
        } else {
            this.initEventsForFixedElements();
        }

        this.dragLeaves = this.dragEnters.pipe(
            switchMap(enterEvent => {
                return this.mouseLeaves.pipe(
                    takeUntil(this.mouseUps),
                    filter((leaveEvent: MouseEvent) => {
                        return leaveEvent.target === this.dropZone.elementRef.nativeElement;
                    }),
                    map((x: MouseEvent) => this.toDroppableEvent(x, enterEvent)),
                    take(1)
                );
            }),
            share()
        );

        this.drops = this.dragEnters.pipe(
            switchMap(enterEvent => {
                return this.mouseUps.pipe(
                    takeUntil(this.dragLeaves),
                    // paranoia check whether the mouse event's target is a child of the dropZone
                    /* filter((x: MouseEvent) => {
                        return (
                            this.dropZone.elementRef.nativeElement ===
                            this.findDropZoneTargetElement(x.target as Element)
                        );
                    }), */
                    map((x: MouseEvent) => this.toDroppableEvent(x, enterEvent)),
                    take(1)
                );
            }),
            share()
        );

        if (this.hasMoveableElements && this.dropZone.dragPreviewAreaElement) {
            this.dragCancels = this.dragStarts.pipe(
                // use switchMap as it unsubscribes the inner observable after its first emission
                // NOTE: we use this.mouseMoves to detect when the user enters the workspace from
                // the left-side, i.e. he leaves the previewAreaElement, enters another then enter
                // the workspace the mouseLeavesPreviewAreaElement can only detect when the user
                // enters directly the workspaceN
                switchMap(dragStartEvent => {
                    return merge(this.mouseUps, this.mouseMoves).pipe(
                        filter((event: MouseEvent | DroppableEvent) => {
                            if (event instanceof MouseEvent) {
                                if (event.type === 'mouseup') {
                                    const target = document.elementFromPoint(
                                        event.clientX,
                                        event.clientY
                                    );
                                    return (
                                        this.findDropZoneTargetElement(target) !==
                                        this.dropZoneElement
                                    );
                                } else if (event.type === 'mousemove') {
                                    // don't emit cancel event of we're still within the start
                                    // element
                                    const newTarget = event.target as Element;
                                    // TODO introduce a cancelElements/Regions property for
                                    // appDroppableContainer
                                    return newTarget.classList.contains('svg-frame');
                                }
                            }
                            return true;
                        }),
                        map((x: MouseEvent | DroppableEvent) => {
                            if (x instanceof MouseEvent) {
                                return this.toDroppableEvent(x, dragStartEvent);
                            }
                            return x;
                        }),
                        first(),
                        takeUntil(this.drops)
                    );
                }),
                share()
            );

            // Emit event when the user leaves the droppable previewAreaElement but does not enter
            // the canceled region/cancelElements
            this.dragLeavesOnPreviewArea = this.dragStarts.pipe(
                switchMap(dragStartEvent => {
                    return this.mouseLeavesPreviewAreaElement.pipe(
                        // filter out any mouseleave events that are not leaving the dropZone start
                        // element and where the left button is not depressed
                        filter((x: MouseEvent) => {
                            // TODO: why we need this?
                            if (x.buttons !== 1) {
                                return false;
                            }
                            const newTarget = x.relatedTarget as Element;
                            return !newTarget.classList.contains('svg-frame');
                        }),
                        map((x: MouseEvent) =>
                            this.toDroppableLeavePreviewAreaEvent(
                                x,
                                this.dropZone.dragPreviewAreaElement
                            )
                        ),
                        takeUntil(this.dragCancels)
                    );
                }),
                share()
            );
        } else {
            // FIXME duplicate code
            // NOTE: we don't use the dragMoves observable since it will not emit any event
            // when only on mousemove-event is fired due to the pairwise-operator
            this.dragCancels = this.dragStarts.pipe(
                switchMap(moveEvent => {
                    return this.mouseUps.pipe(
                        filter((upEvent: MouseEvent) => {
                            const target = document.elementFromPoint(
                                upEvent.clientX,
                                upEvent.clientY
                            );
                            return this.findDropZoneTargetElement(target) !== this.dropZoneElement;
                        }),
                        map((x: MouseEvent) => this.toDroppableEvent(x, moveEvent)),
                        take(1),
                        takeUntil(this.drops)
                    );
                }),
                share()
            );
        }
    }

    private initEventsForFixedElements() {
        // DO NOT use preventDefault on mouseDown
        this.dragStarts = this.mouseDowns.pipe(
            /*   tap((event: MouseEvent) => {
                   // Stop propagation for mousedown only to prevent from prevent it to start
                   // another drag event on a parent element
                   event.stopPropagation();
                   // avoid conflicts with the native HTML drag&drop
                   if (event.target && (event.target as HTMLElement).draggable) {
                       event.preventDefault();
                   }
               }), */
            // FIXME: switchMap vs flatMap vs concatMap or something like wait until
            concatMap((mouseDown: MouseEvent) => {
                return this.mouseMoves.pipe(
                    // create a potential drag start event
                    map((x: MouseEvent) =>
                        this.toDraggableStartEvent(mouseDown, this.getDroppableTarget(mouseDown))
                    ),
                    // filter out undefined and null values
                    // AND terminate this observable sequence if the mousemove target is not
                    // draggable
                    takeWhile((x: DroppableEvent | null) => x !== null),
                    // invoke actions with side effect behavior using the tap operator
                    tap((x: DroppableEvent) => {
                        x.mirrorElement.classList.add(this.mirrorClass);
                    }),
                    // get and emit the starting drag start event
                    take(1),

                    // terminate this observable sequence if the mouseUps observable emits an event
                    // takeUntil should be the last operator in the sequence to avoid leaks
                    takeUntil(this.mouseUps)
                );
            }),
            share()
        );

        this.dragMoves = this.dragStarts.pipe(
            switchMap(start => {
                return this.mouseMoves.pipe(
                    takeUntil(this.mouseUps),
                    pairwise(),
                    map((x: MouseEvent[]) => this.toDraggableEvent(x, start))
                );
            }),
            share()
        );

        // We don't use switchMap or concat here as the inner Observables (i.e. mouseEnters) might not be emitted when moving the
        // mouse pointer too fast
        // Note: SwitchMap would unsubscribe from each previously-emitted mouseEnters (inner Observable) whenever a new dragMoves
        // (outer Observable) is emitted
        // Hence, SwitchMap seems to unsubscribe too early, while concatMap somehow takes to long, miss the mouseEnters
        this.dragEnters = this.dragMoves.pipe(
            // terminate if the mouse is over the dropZone
            filter((x: DroppableEvent) => {
                // We dont use target because of the FF-bug
                // https://bugzilla.mozilla.org/show_bug.cgi?id=1259357
                const target = document.elementFromPoint(
                    x.originalEvent.clientX,
                    x.originalEvent.clientY
                );
                return (
                    this.findDropZoneTargetElement(target) ===
                    this.dropZone.elementRef.nativeElement
                );
            }),
            share()
        );
    }

    private initEventsForMoveableElements() {
        if (this.dropZone.dragPreviewAreaElement) {
            this.handleDropZoneWithPreviewArea();
        } else {
            // TODO should be removed or tested because as we have always a preview-area we don't no
            // if this code is working
            /*      this.dragEnters = this.mouseDowns.pipe(
                      map(mouseDown => this.getDroppableTarget(mouseDown)),
                      concatMap((droppableTarget: DroppableTarget) => {
                          return this.mouseEnters.pipe(
                              takeUntil(this.mouseUps),
                              // terminate if the mouse is over the dropZone
                              filter((x: MouseEvent) => {
                                  return (
                                      this.findDropZoneTargetElement(x.target as Element) ===
                                      this.dropZoneElement
                                  );
                              }),
                              // create a potential drag start event
                              map((x: MouseEvent) => {
                                  return this.toDraggableStartEvent(x, droppableTarget);
                              }),
                              // terminate this observable sequence if the mousemove target is not
               draggable takeWhile((x: DroppableEvent | null) => x !== null)
                          );
                      }),
                      share()
                  );

                  this.dragMoves = this.dragEnters.pipe(
                      switchMap((start: DroppableEvent) => {
                          return this.mouseMoves.pipe(
                              takeUntil(this.mouseUps),
                              // skip(1),
                              pairwise(),
                              map((x: MouseEvent[]) => this.toDraggableEvent(x, start))
                          );
                      }),
                      share()
                  ); */
        }
    }

    /**
     * Creates drag events for a DropZone that has an up-front preview area
     */
    private handleDropZoneWithPreviewArea() {
        this.dragStarts = this.mouseDowns.pipe(
            map(mouseDown => this.getDroppableTarget(mouseDown)),
            switchMap((droppableTarget: DroppableTarget) => {
                return this.mouseEntersPreviewAreaElement.pipe(
                    // terminate if the mouse is over the dropZone
                    filter((enterEvent: MouseEvent) => {
                        // FIXME: quick fix to detect that the mouse was leaving the svg-workspace
                        // note the problem with the mouseEnter is that is not reliable since
                        // the dragged item might be behind the cursor and thus the relatedTarget
                        // will be the <svg> element and the dragged contour item
                        const newTarget = enterEvent.relatedTarget as Element;
                        const isEnteringFromDroppableTarget =
                            this.findDroppableTargetElement(newTarget) ||
                            newTarget.classList.contains('svg-frame');

                        const target = document.elementFromPoint(
                            enterEvent.clientX,
                            enterEvent.clientY
                        );
                        return (
                            isEnteringFromDroppableTarget &&
                            this.findDropZoneTargetElement(
                                target,
                                this.dropZone.dragPreviewAreaElement
                            ) !== null
                        );
                    }),
                    // create a potential drag start event
                    map((enterEvent: MouseEvent) => {
                        return this.toDraggableStartEvent(enterEvent, droppableTarget);
                    }),
                    // filter out mouse enter event that cannot be converted into a DroppableEvent
                    filter((x: DroppableEvent | null) => x !== null),
                    // invoke actions with side effect behavior using the tap operator
                    tap((x: DroppableEvent) => {
                        // prevent native drag behaviour
                        // x.originalEvent.preventDefault();
                        x.mirrorElement.classList.add(this.mirrorClass);
                    }),
                    // take(1),
                    takeUntil(this.mouseUps)
                );
            }),
            share()
        );

        // get subsequent enters events
        this.dragReEntersPreviewArea = this.dragStarts.pipe(
            switchMap(dragStartEvent => {
                return this.mouseEntersPreviewAreaElement.pipe(
                    /* filter((x: MouseEvent) => {
                         const newTarget = x.relatedTarget as Element;
                         return !newTarget.classList.contains('svg-frame');
                     }), */
                    map((x: MouseEvent) => {
                        return this.toDroppableLeavePreviewAreaEvent(
                            x,
                            this.dropZone.dragPreviewAreaElement
                        );
                    }),
                    filter((x: DroppableEvent | undefined) => x !== null),
                    takeUntil(this.dragCancels),
                    takeUntil(this.drops)
                );
            }),
            share()
        );

        this.dragMoves = this.dragStarts.pipe(
            switchMap((startEvent: DroppableEvent) => {
                return this.mouseMoves.pipe(
                    tap((x: MouseEvent) => {
                        x.preventDefault();
                    }),
                    pairwise(),
                    map((x: MouseEvent[]) => this.toDraggableEvent(x, startEvent)),
                    takeUntil(this.mouseUps),
                    takeUntil(this.dragCancels)
                );
            }),
            share()
        );

        this.dragEnters = this.dragMoves.pipe(
            // terminate if the mouse is over the dropZone
            filter((x: DroppableEvent) => {
                const target = document.elementFromPoint(
                    x.originalEvent.clientX,
                    x.originalEvent.clientY
                );
                return this.findDropZoneTargetElement(target) === this.dropZoneElement;
            }),
            share()
        );
    }

    /**
     * Gets the closest parent element that is defined as draggable, dropZone or container (via [appDraggable] directive)
     *
     * @param {Event} startEvent
     * @returns {Element | null}
     */
    private findDroppableTargetElement(target: Element): DroppableTarget {
        let soughtTarget: Element = target;

        const droppableItem = this.getDroppableTargetNode(soughtTarget);
        if (droppableItem) {
            return {
                elementRef: droppableItem,
                htmlElement: droppableItem.nativeElement
            };
        }

        const containerElem = this.draggableContainer.elementRef.nativeElement;
        if (containerElem === soughtTarget) {
            return null;
        }

        while (
            soughtTarget != null &&
            containerElem !== soughtTarget &&
            !(soughtTarget instanceof HTMLBodyElement) &&
            !(containerElem instanceof HTMLHtmlElement)
        ) {
            soughtTarget = soughtTarget.parentNode as Element;
            const _droppableItem = this.getDroppableTargetNode(soughtTarget);
            if (_droppableItem) {
                return {
                    elementRef: _droppableItem,
                    htmlElement: soughtTarget
                };
            }
        }

        return null;
    }

    private getDroppableTargetNode(element: Element): DroppableItem {
        const index = this.droppableItems.findIndex(
            (x: DroppableItem) =>
                x.nativeElement &&
                (x.nativeElement === element ||
                    (x.relatedNativeElements && x.relatedNativeElements.indexOf(element) > -1))
        );
        return index > -1 ? this.droppableItems[index] : null;
    }

    private findDropZoneTargetElement(soughtTarget: Element, dropZoneElement?: Element): Element {
        if (!soughtTarget) {
            return null;
        }

        if (!dropZoneElement) {
            dropZoneElement = this.dropZoneElement;
        }

        if (dropZoneElement === soughtTarget) {
            return soughtTarget;
        }

        while (
            soughtTarget != null &&
            window.document.body !== soughtTarget &&
            window.document.documentElement !== soughtTarget
        ) {
            soughtTarget = soughtTarget.parentNode as Element;
            if (dropZoneElement === soughtTarget) {
                return soughtTarget;
            }
        }

        return null;
    }

    /**
     *
     * @param startEvent that triggers the drag action
     */
    private getDroppableTarget(startEvent: MouseEvent): DroppableTarget {
        const target = document.elementFromPoint(startEvent.clientX, startEvent.clientY);
        return this.findDroppableTargetElement(target as HTMLElement);
    }

    /**
     *
     * @param {MouseEvent} startEvent
     * @param draggableTarget
     * @returns {DroppableEvent | null}
     */
    private toDraggableStartEvent(
        startEvent: MouseEvent,
        draggableTarget: DroppableTarget
    ): DroppableEvent | null {
        const x = startEvent.clientX;
        const y = startEvent.clientY;
        const dx = 0;
        const dy = 0;
        const totalX = dx;
        const totalY = dy;

        if (!draggableTarget) {
            console.error('failed to find droppable start element');
            return null;
        }

        const mirrorElem = draggableTarget.htmlElement.cloneNode(true) as Element;
        const mirrorBBox = draggableTarget.htmlElement.getBoundingClientRect();

        const mirrorOffsetX = startEvent.clientX - mirrorBBox.left;
        const mirrorOffsetY = startEvent.clientY - mirrorBBox.top;

        return {
            x: x,
            y: y,
            mirrorOffsetX: mirrorOffsetX,
            mirrorOffsetY: mirrorOffsetY,
            deltaX: dx,
            deltaY: dy,
            totalDeltaX: totalX,
            totalDeltaY: totalY,
            originalEvent: startEvent,
            draggableTarget: draggableTarget,
            mirrorElement: mirrorElem
        } as DroppableEvent;
    }

    // FIXME
    private toDroppableLeavePreviewAreaEvent(
        currentEvent: MouseEvent,
        dragPreviewAreaElement: Element
    ): DroppableEvent | undefined {
        if (!dragPreviewAreaElement) {
            console.error('dragPreviewAreaElement is undefined');
            return undefined;
        }

        const x = currentEvent.clientX;
        const y = currentEvent.clientY;

        return {
            x: x,
            y: y,
            draggableTarget: undefined,
            dragPreviewAreaElement: dragPreviewAreaElement,
            mirrorElement: undefined,
            mirrorOffsetX: undefined,
            mirrorOffsetY: undefined,
            originalEvent: currentEvent
        };
    }

    private toDroppableEvent(
        currentEvent: MouseEvent,
        previousDragEvent: DroppableEvent
    ): DroppableEvent {
        const x = currentEvent.clientX;
        const y = currentEvent.clientY;
        return {
            x: x,
            y: y,
            draggableTarget: previousDragEvent.draggableTarget,
            mirrorElement: previousDragEvent.mirrorElement,
            mirrorOffsetX: previousDragEvent.mirrorOffsetX,
            mirrorOffsetY: previousDragEvent.mirrorOffsetY,
            originalEvent: currentEvent
        };
    }

    private toDraggableEvent(moveEvents: MouseEvent[], startEvent: DroppableEvent): DroppableEvent {
        const previousEvent = moveEvents[0];
        const currentEvent = moveEvents[1];

        const x = currentEvent.clientX;
        const y = currentEvent.clientY;
        const dx = x - previousEvent.x;
        const dy = y - previousEvent.y;
        const totalX = x - startEvent.x;
        const totalY = y - startEvent.y;

        return {
            x: x,
            y: y,
            mirrorOffsetX: startEvent.mirrorOffsetX,
            mirrorOffsetY: startEvent.mirrorOffsetY,
            deltaX: dx,
            deltaY: dy,
            totalDeltaX: totalX,
            totalDeltaY: totalY,
            originalEvent: currentEvent,
            draggableTarget: startEvent.draggableTarget,
            mirrorElement: startEvent.mirrorElement
        };
    }

    ngOnDestroy(): void {
        // prevent memory leak when component destroyed
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }
}

export interface DroppableTarget {
    readonly htmlElement: Element;
    readonly elementRef: DroppableItem;
}

export interface DroppableEvent {
    readonly x: number;
    readonly y: number;
    readonly mirrorOffsetX: number;
    readonly mirrorOffsetY: number;
    readonly deltaX?: number;
    readonly deltaY?: number;
    readonly totalDeltaX?: number;
    readonly totalDeltaY?: number;
    draggableTarget: DroppableTarget;
    readonly originalEvent: MouseEvent;
    mirrorElement: Element;
    readonly dragPreviewAreaElement?: Element;
    // readonly state: DragState;
}
