import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { WithSubscriptionMixin } from './canvas-layer-mixin';

export function CanvasLayerWithSubscriptionMixin<T extends Constructor<{}>>(): Constructor<
    WithSubscriptionMixin
    > {
    return class CanvasLayerWithSubscriptionMixin implements OnDestroy {
        private readonly subscriptions: Subscription[] = [];

        addSubscription(...subs: Subscription[]) {
            subs.forEach(x => this.subscriptions.push(x));
        }

        ngOnDestroy() {
            this.subscriptions.forEach(sub => sub.unsubscribe());
        }
    };
}
