import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { UploadDialogContentComponent } from '../../upload-dialog.component';

@Component({
    selector: 'app-mat-choice',
    templateUrl: './mat-choice.component.html',
    styleUrls: ['./mat-choice.component.scss']
})
export class MatChoiceComponent implements OnInit, UploadDialogContentComponent {
    constructor() {}

    ngOnInit() {}

    isValid(): Observable<boolean> {
        return Observable.create(o => o.next(true));
    }

    onLoadNextContent(): void {}

    onLoadPrevContent(): void {}

    onAttached(): void {}
}
