<app-dialog-header>
    <h1 class="app-dialog-title">{{ 'DELETE_CONTOUR_DIALOG.TITLE' | translate }}</h1>
</app-dialog-header>
<app-dialog-content>
    <div class="app-dialog-body">
        <div class="dialog-content__container">
            <div class="dialog-content__inner-container">
                <div class="contour-image-container">
                    <img *ngIf="imageContour.image != null" [attr.src]="imageContour.image" />
                </div>
                <div
                    class="delete-confirmation-message"
                    [innerHTML]="
                        'DELETE_CONTOUR_DIALOG.INFO_TEXT' | translate: { title: imageContour.title }
                    "
                ></div>
            </div>
        </div>
    </div>
</app-dialog-content>
<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'secondary'"
            iconAlign="horizontal"
            textPosition="after"
            buttonSize="normal"
            (click)="cancel()"
        >
            <app-icon svgWidth="24" svgHeight="24" shape="icon-close" iconSize="small"> </app-icon>
            {{ buttonCancelText }}
        </app-button>
        <app-button
            app-icon-button
            [color]="'primary'"
            buttonSize="normal"
            textPosition="before-after"
            (click)="delete()"
        >
            <app-icon
                svgWidth="24"
                svgHeight="24"
                cssWidth="21px"
                cssHeight="19px"
                shape="bin"
                iconSize="small"
            >
            </app-icon>
            {{ 'DELETE_CONTOUR_DIALOG.DELETE_BUTTON' | translate }}
            <app-icon svgWidth="24" svgHeight="24" shape="arrow_right" iconSize="small"> </app-icon>
        </app-button>
    </div>
</app-dialog-footer>
