import { Directive, ElementRef } from '@angular/core';

import {
    CollisionEventNotifier,
    StaticCollisionChangedEvent
} from '../collision/collision-event-notifier';
import { CanvasService, SelectionChangedData } from '../canvas.service';
import {
    ContourChangeData,
    ContourItemsRemoved,
    CuttableContourDrawData,
    MarginContourCollisionVizData,
    CanvasContour,
    FoamInnerMarginDrawData
} from '../contour/contour-items-interfaces';
import { FoamContour } from '../contour/foam-contour';

import { CanvasContourLayer } from './canvas-contour-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';
import { SVG_NAMESPACE_URI } from './collision-layer.directive';
import { CanvasLayerWithSubscriptionMixin } from './destroyable-layer-mixin';

declare var Snap: any;

export const FIXED_OUTER_PATH_TO_CLIP_ID = 'fixed-outer-contour-collision-';
export const FIXED_INNER_PATH_TO_CLIP_ID = 'fixed-inner-contour-collision-';

export const FIXED_OUTER_CLIP_PATHS_ID = 'fixed-outer-clip-contour-';
export const FIXED_INNER_CLIP_PATHS_ID = 'fixed-inner-clip-contour-';

export const CLASS_FIXED_CLIP_PATHS_ELEM = 'fixed-clip-contour-elem-';

/**
 * Displays "static" or fixed collisions
 *
 * in this context static / fixed means that there is no object selected and no object in movement
 */
@Directive({
    selector: '[appFixedCollisionLayer]'
})
export class FixedCollisionLayerDirective
    extends CanvasLayerMixin(CanvasLayerWithSubscriptionMixin())
    implements CanvasContourLayer<Snap.Paper> {
    svgElement: Snap.Paper;
    private readonly clipPaths: { [id: string]: CollisionClipPath } = {};
    element: Snap.Paper;

    constructor(
        private elementRef: ElementRef,
        private canvasService: CanvasService,
        private collisionEventNotifier: CollisionEventNotifier
    ) {
        super();
        this.svgElement = this.element = Snap(elementRef.nativeElement);

        this.addSubscription(
            this.collisionEventNotifier.onStaticCollisionChanged.subscribe(
                (collisionEvt: StaticCollisionChangedEvent) => {
                    // Add
                    for (const cIndex of Object.keys(collisionEvt.addedItems)) {
                        // Get previously selected items, but skip all selected items as they
                        // are already clipped by the collision-layer directive
                        const contourItem = this.canvasService.getContourItem(cIndex, true);
                        const clipPath = this.getOrCreateClipPath(cIndex, contourItem);

                        // create clip-paths for (previously) selected items
                        collisionEvt.addedItems[cIndex].forEach(collideItemIndex => {
                            this.createContourClipPaths(collideItemIndex, clipPath, contourItem);
                        });
                    }

                    for (const cIndex of Object.keys(collisionEvt.removedItems)) {
                        this.removeContourClipPaths(collisionEvt, cIndex);
                    }
                }
            )
        );

        /*
        this.workspaceItemService.onItemRemoved.subscribe((contourItem: WorkspaceContour) => {
            this.removePathToClip(contourItem);
        }); */
    }

    private createContourClipPaths(
        collideItemId: string,
        clipPath: CollisionClipPath,
        contourItem: CanvasContour
    ) {
        let collideItem: CanvasContour;

        const foamContour = this.canvasService.getFoamContourItem();
        const foamInnerMarginContour = foamContour.foamInnerMarginContour;
        if (foamContour.contourId === collideItemId) {
            collideItem = foamContour;
        } else if (foamInnerMarginContour && foamInnerMarginContour.contourId === collideItemId) {
            collideItem = foamInnerMarginContour;
        } else {
            collideItem = this.canvasService.getContourItem(collideItemId, true);
        }

        if (!clipPath.outerClipPathContent[collideItemId]) {
            this.createOuterClipPaths(contourItem.contourId, collideItem, clipPath);
        }

        // NOTE: there is no need to create an innerClipPath for foam contour because always the
        // margin of the given contour collide with the foam
        if (
            !(
                collideItem instanceof FoamContour || collideItem instanceof FoamInnerMarginDrawData
            ) &&
            !clipPath.innerClipPathContent[collideItemId]
        ) {
            this.createInnerClipPaths(contourItem.contourId, collideItem, clipPath);
        }
    }

    private removeContourClipPaths(collisionEvt: StaticCollisionChangedEvent, cIndex) {
        if (collisionEvt.removedItems[cIndex].size === 0) {
            return;
        }

        const clipPathItems = this.clipPaths[cIndex];
        if (clipPathItems) {
            const contourClipPaths = collisionEvt.removedItems[cIndex];

            contourClipPaths.forEach(collideItemIndex => {
                const outerElem = clipPathItems.outerClipPathContent[collideItemIndex];
                if (outerElem) {
                    outerElem.parentNode.removeChild(outerElem);
                    delete clipPathItems.outerClipPathContent[collideItemIndex];
                }

                const innerElem = clipPathItems.innerClipPathContent[collideItemIndex];
                if (innerElem) {
                    innerElem.parentNode.removeChild(innerElem);
                    delete clipPathItems.innerClipPathContent[collideItemIndex];
                }
            });

            if (Object.keys(clipPathItems.outerClipPathContent).length === 0) {
                clipPathItems.selectedOuterPathGroup.remove();
                clipPathItems.selectedInnerPathGroup.remove();

                clipPathItems.outerClipPathElement.parentNode.removeChild(
                    clipPathItems.outerClipPathElement
                );
                clipPathItems.innerClipPathElement.parentNode.removeChild(
                    clipPathItems.innerClipPathElement
                );

                delete this.clipPaths[cIndex];
            }
        }
    }

    private getOrCreateClipPath(cIndex, contourItem) {
        let clipPath = this.clipPaths[cIndex];
        if (!clipPath) {
            const collisionData = contourItem.getCollisionData() as MarginContourCollisionVizData;
            clipPath = this.createPathToClip(contourItem, collisionData);
        }
        return clipPath;
    }

    /**
     * @Deprecated
     * @param contourItem
     */
    /* private removePathToClip(contourItem: WorkspaceContour) {
        const clipPathItems = this.clipPaths[FIXED_OUTER_CLIP_PATHS_ID + contourItem.contourId];

        if (clipPathItems) {
            clipPathItems.outerClipPathElement.remove();
            clipPathItems.selectedOuterPathGroup.remove();
            delete this.clipPaths[FIXED_OUTER_CLIP_PATHS_ID + contourItem.contourId];
        }
    } */

    private createPathToClip(
        contourItem: CanvasContour,
        collisionData: MarginContourCollisionVizData
    ): CollisionClipPath {
        const outerCollisionGroup: Snap.Paper = this.svgElement.g();
        outerCollisionGroup.attr({
            'clip-path': `url(#${FIXED_OUTER_CLIP_PATHS_ID}${contourItem.contourId})`
        });

        const outerCollisionPath = outerCollisionGroup.path(collisionData.pathStringToBeClipped);
        outerCollisionPath.node.id = FIXED_OUTER_PATH_TO_CLIP_ID + contourItem.contourId;
        outerCollisionPath.node.setAttribute(
            'transform',
            collisionData.localMarginPathMatrix.toString()
        );

        const outerClipPathElem: SVGClipPathElement = document.createElementNS(
            SVG_NAMESPACE_URI,
            'clipPath'
        );
        outerClipPathElem.id = FIXED_OUTER_CLIP_PATHS_ID + contourItem.contourId;
        outerClipPathElem.setAttribute('clip-rule', 'evenodd');
        this.svgElement.node.appendChild(outerClipPathElem);

        // Add inner collision paths

        const innerCollisionGroup: Snap.Paper = this.svgElement.g();
        innerCollisionGroup.attr({
            'clip-path': `url(#${FIXED_INNER_CLIP_PATHS_ID}${contourItem.contourId})`
        });

        const innerCollisionPath = innerCollisionGroup.path(collisionData.clipPathString);
        innerCollisionPath.node.id = FIXED_INNER_PATH_TO_CLIP_ID + contourItem.contourId;
        innerCollisionPath.node.setAttribute(
            'transform',
            collisionData.localContourPathMatrix.toString()
        );

        const innerClipPathElem: SVGClipPathElement = document.createElementNS(
            SVG_NAMESPACE_URI,
            'clipPath'
        );
        innerClipPathElem.id = FIXED_INNER_CLIP_PATHS_ID + contourItem.contourId;
        innerClipPathElem.setAttribute('clip-rule', 'evenodd');
        this.svgElement.node.appendChild(innerClipPathElem);

        return (this.clipPaths[contourItem.contourId] = {
            contourId: contourItem.contourId,
            selectedOuterPathGroup: outerCollisionGroup,
            selectedInnerPathGroup: innerCollisionGroup,
            outerClipPathElement: outerClipPathElem,
            innerClipPathElement: innerClipPathElem,
            outerClipPathContent: {},
            innerClipPathContent: {}
        });
    }

    private createOuterClipPaths(
        selectedContourId: string,
        collideItem: CanvasContour,
        clipPathHolder: CollisionClipPath
    ) {
        const otherItemCollisionData = collideItem.getCollisionData() as MarginContourCollisionVizData;
        let outerMatrix: Snap.Matrix;
        if (collideItem instanceof FoamContour) {
            outerMatrix = otherItemCollisionData.localContourPathMatrix;
        } else {
            outerMatrix = (otherItemCollisionData as MarginContourCollisionVizData)
                .localContourPathMatrix;
        }

        /*
            otherItemCollisionData.clipPathString, otherItemCollisionData.pathStringToBeClipped */

        const outerClipPathChild: SVGPathElement = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
        );

        clipPathHolder.outerClipPathElement.appendChild(outerClipPathChild);
        outerClipPathChild.setAttribute('d', otherItemCollisionData.clipPathString);
        outerClipPathChild.setAttribute('transform', outerMatrix.toString());
        outerClipPathChild.setAttribute('class', CLASS_FIXED_CLIP_PATHS_ELEM + selectedContourId);
        clipPathHolder.outerClipPathContent[otherItemCollisionData.contourId] = outerClipPathChild;
    }

    private createInnerClipPaths(
        selectedContourId: string,
        collideItem: CanvasContour,
        clipPathHolder: CollisionClipPath
    ) {
        const otherItemCollisionData = collideItem.getCollisionData() as MarginContourCollisionVizData;
        const innerMatrix = (otherItemCollisionData as MarginContourCollisionVizData)
            .localMarginPathMatrix;
        if (otherItemCollisionData.pathStringToBeClipped) {
            const innerClipPathChild: SVGPathElement = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'path'
            );
            clipPathHolder.innerClipPathElement.appendChild(innerClipPathChild);

            innerClipPathChild.setAttribute('d', otherItemCollisionData.pathStringToBeClipped);
            innerClipPathChild.setAttribute('transform', innerMatrix ? innerMatrix.toString() : '');
            innerClipPathChild.setAttribute(
                'class',
                CLASS_FIXED_CLIP_PATHS_ELEM + selectedContourId
            );
            if (otherItemCollisionData) {
                clipPathHolder.innerClipPathContent[
                    otherItemCollisionData.contourId
                ] = innerClipPathChild;
            }
        }
    }

    /**
     * @deprecated Use transformContourItem
     * @param contourItem
     */
    /* updateItemPosition(contourItem: WorkspaceContour) {
        const _clipPaths = this.clipPaths[contourItem.contourId];
        if (!_clipPaths) {
            return;
        }
        const clipP = _clipPaths.selectedOuterPathGroup.children()[0];
        if (clipP) {
            clipP.attr({ transform: contourItem.itemElement.transform().localMatrix.toString() });
        }
    } */

    addContourItem(contourData: CuttableContourDrawData) {}

    // TODO to be removed
    changeContourItem(contourData: ContourChangeData) {
        /*   const _clipPaths = this.clipPaths[contourData.contourId];
           if (!_clipPaths) {
               return;
           }
           const clipP = _clipPaths.selectedOuterPathGroup.children()[0];
           if (clipP) {
               const txString = contourData.matrix.toString();
               clipP.node.setAttribute('transform', txString);
           } */
    }

    removeContourItem(contourData: ContourItemsRemoved) {
        if (!contourData || !contourData.contourIds) {
            return;
        }

        contourData.contourIds.forEach(contourId => {
            const clipPathItems = this.clipPaths[contourId];

            if (clipPathItems) {
                clipPathItems.outerClipPathElement.remove();
                clipPathItems.innerClipPathElement.remove();
                clipPathItems.selectedOuterPathGroup.remove();
                clipPathItems.selectedInnerPathGroup.remove();
                delete this.clipPaths[contourId];
            }
        });
    }

    selectContourItems(data: SelectionChangedData) {}
}

export interface CollisionClipPath {
    /**
     * The group of the path to be clipped in one of the collision layers.
     * This is typically the group of the selected contour path
     */
    contourId: string;
    selectedOuterPathGroup: Snap.Element;

    selectedInnerPathGroup: Snap.Element;
    /**
     * The <clipPath> SVG element
     */
    outerClipPathElement: SVGClipPathElement;

    innerClipPathElement: SVGClipPathElement;

    /**
     * This contains all the paths of the {@code outerClipPathElement}
     */
    outerClipPathContent: { [id: string]: SVGPathElement };

    innerClipPathContent: { [id: string]: SVGPathElement };
}
