/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./partial-cutting-warning-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../dialog/dialog-header/dialog-header.component";
import * as i4 from "../../../dialog/dialog-refs";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../dialog/dialog-content/dialog-content.component.ngfactory";
import * as i7 from "../../../dialog/dialog-content/dialog-content.component";
import * as i8 from "../../../dialog/dialog-footer/dialog-footer.component.ngfactory";
import * as i9 from "../../../dialog/dialog-footer/dialog-footer.component";
import * as i10 from "../../../shared/button/button.component.ngfactory";
import * as i11 from "../../../shared/button/button.component";
import * as i12 from "../../../shared/icon/icon.component.ngfactory";
import * as i13 from "../../../shared/icon/icon.component";
import * as i14 from "./partial-cutting-warning-dialog.component";
var styles_PartialCuttingWarningDialogComponent = [i0.styles];
var RenderType_PartialCuttingWarningDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PartialCuttingWarningDialogComponent, data: {} });
export { RenderType_PartialCuttingWarningDialogComponent as RenderType_PartialCuttingWarningDialogComponent };
export function View_PartialCuttingWarningDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-dialog-header", [["class", "modal-card-head"]], null, [["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [i4.DialogRefs], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "h1", [["class", "app-dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 7, "app-dialog-content", [["class", "modal-card-body"]], null, [["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DialogContentComponent_0, i6.RenderType_DialogContentComponent)), i1.ɵdid(6, 114688, null, 0, i7.DialogContentComponent, [i4.DialogRefs], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 5, "div", [["class", "app-dialog-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "dialog-content__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "dialog-content__inner-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 8, "app-dialog-footer", [["class", "modal-card-foot"]], null, null, null, i8.View_DialogFooterComponent_0, i8.RenderType_DialogFooterComponent)), i1.ɵdid(14, 114688, null, 0, i9.DialogFooterComponent, [i4.DialogRefs], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 6, "div", [["class", "app-dialog-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "app-button", [["app-icon-button", ""], ["buttonSize", "normal"], ["textPosition", "before"]], [[2, "text-before-icon", null], [2, "text-after-icon", null], [2, "text-before-after-icon", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.processed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ButtonComponent_0, i10.RenderType_ButtonComponent)), i1.ɵdid(17, 4243456, null, 0, i11.ButtonComponent, [i1.ElementRef], { textPosition: [0, "textPosition"], color: [1, "color"], buttonSize: [2, "buttonSize"] }, null), (_l()(), i1.ɵted(18, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, 0, 1, "app-icon", [["iconSize", "small"], ["shape", "arrow_right"], ["svgHeight", "24"], ["svgWidth", "24"]], null, null, null, i12.View_IconComponent_0, i12.RenderType_IconComponent)), i1.ɵdid(21, 114688, null, 0, i13.IconComponent, [i1.ElementRef], { svgWidth: [0, "svgWidth"], svgHeight: [1, "svgHeight"], shape: [2, "shape"], iconSize: [3, "iconSize"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 6, 0); _ck(_v, 14, 0); var currVal_5 = "before"; var currVal_6 = "primary"; var currVal_7 = "normal"; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = "24"; var currVal_10 = "24"; var currVal_11 = "arrow_right"; var currVal_12 = "small"; _ck(_v, 21, 0, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("PARTIAL_CUTTING_DIALOG.TITLE")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("PARTIAL_CUTTING_DIALOG.MESSAGE")); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 17).isBefore(); var currVal_3 = i1.ɵnov(_v, 17).isAfter(); var currVal_4 = i1.ɵnov(_v, 17).isBeforeAndAfter(); _ck(_v, 16, 0, currVal_2, currVal_3, currVal_4); var currVal_8 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("PARTIAL_CUTTING_DIALOG.OK_BUTTON")); _ck(_v, 18, 0, currVal_8); }); }
export function View_PartialCuttingWarningDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PartialCuttingWarningDialogComponent_0, RenderType_PartialCuttingWarningDialogComponent)), i1.ɵdid(1, 114688, null, 0, i14.PartialCuttingWarningDialogComponent, [i4.DialogRefs], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartialCuttingWarningDialogComponentNgFactory = i1.ɵccf("ng-component", i14.PartialCuttingWarningDialogComponent, View_PartialCuttingWarningDialogComponent_Host_0, {}, {}, []);
export { PartialCuttingWarningDialogComponentNgFactory as PartialCuttingWarningDialogComponentNgFactory };
