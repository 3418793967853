/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./icon.component";
var styles_IconComponent = [i0.styles];
var RenderType_IconComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
export function View_IconComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { svgElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "app-icon app-baseline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["svgElement", 1]], null, 1, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["xmlns", "http://www.w3.org/2000/svg"]], [[1, "width", 0], [1, "height", 0], [4, "width", null], [4, "height", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.svgWidth; var currVal_1 = _co.svgHeight; var currVal_2 = _co.cssWidth; var currVal_3 = _co.cssHeight; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.shapeUrl; _ck(_v, 3, 0, currVal_4); }); }
export function View_IconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i1.ɵdid(1, 114688, null, 0, i2.IconComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconComponentNgFactory = i1.ɵccf("app-icon", i2.IconComponent, View_IconComponent_Host_0, { svgWidth: "svgWidth", svgHeight: "svgHeight", shape: "shape", iconSize: "iconSize", cssWidth: "cssWidth", cssHeight: "cssHeight", disabled: "disabled" }, {}, ["*"]);
export { IconComponentNgFactory as IconComponentNgFactory };
