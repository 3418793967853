<div class="zoom-panel">
    <div class="zoom-panel-icon-minus">
        <app-button
            app-icon-button
            color="primary"
            buttonSize="x-small"
            class="btn-border-radiusless"
            [disabled]="!disabledZoomOutBtn"
            appTooltip
            appToolTipContent="{{ titleZoomOut }}"
            [appTooltipPlacement]="'bottom'"
            [appTooltipDisabled]="!disabledZoomOutBtn"
            (click)="zoomOut()"
        >
            <app-icon shape="minus" iconSize="small" cssWidth="15px" cssHeight="15px"></app-icon>
        </app-button>
    </div>
    <div class="zoom-panel-text">{{ zoomValue }}%</div>
    <div class="zoom-panel-icon-plus">
        <app-button
            app-icon-button
            color="primary"
            buttonSize="x-small"
            class="btn-border-radiusless"
            appTooltip
            appToolTipContent="{{ titleZoomIn }}"
            [appTooltipPlacement]="'bottom'"
            (click)="zoomIn()"
        >
            <app-icon shape="plus" iconSize="small" cssWidth="15px" cssHeight="15px"></app-icon>
        </app-button>
    </div>
</div>
