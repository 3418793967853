<!-- <header class="modal-card-head">
    <div class="modal-card-title">
        <ng-content select=".app-dialog-title"></ng-content>
    </div>
    <app-button app-icon-button class="modal-close-btn btn-border-radiusless"
                *ngIf="closable"
                (click)="close()"
                [color]="'tertiary'"
                [buttonSize]="'x-small'">
        <app-icon cssWidth="16px" cssHeight="16px" shape="icon-close" iconSize="small"></app-icon>
    </app-button>
</header> -->
<ng-content></ng-content>

<!--
<footer class="modal-card-foot">
    <ng-content select=".app-dialog-footer"></ng-content>
</footer>
-->
