import { Rectangle2D } from '../../shared/geom/rectangle2D';

export const CLASS_NAME_SELECTION_HANDLE_GROUP = 'selection-item-group-';

export class ContourTransformationBounds {
    private selectionRectangle: Snap.Element;

    /**
     * Inner element bounds => used by the resizeHanldes
     */

    localInnerBounds: Rectangle2D;

    /**
     * Outer (margin) element bounds (used for rotation)
     */
    localOuterBounds: Rectangle2D;
    /**
     * Outer (margin) element transformation matrix (used for rotation)
     */
    localOuterMatrix: Snap.Matrix;

    /**
     *
     * @param id
     * @param containerElement
     * @param localInnerBounds
     * @param localOuterBounds
     * @param localOuterMatrix
     * @param debug
     */
    constructor(
        public readonly id: string,
        private containerElement: Snap.Paper,
        localInnerBounds: Rectangle2D,
        localOuterBounds: Rectangle2D,
        localOuterMatrix: Snap.Matrix,
        private debug: boolean
    ) {
        this.localInnerBounds = localInnerBounds;
        this.localOuterBounds = localOuterBounds;
        this.localOuterMatrix = localOuterMatrix;

        this.init();
    }

    private init() {
        if (this.debug) {
            this.createSelectionRectangle();
        }
    }

    /**
     * Returns the matrix
     */
    getLocalOuterMatrix(): Snap.Matrix {
        return this.localOuterMatrix;
    }

    /**
     * Gets the untransformed selection bounds
     */
    getLocalOuterBounds(): Rectangle2D {
        return this.localOuterBounds;
    }

    private createSelectionRectangle() {
        this.containerElement.addClass(CLASS_NAME_SELECTION_HANDLE_GROUP + this.id);
        this.selectionRectangle = this.containerElement.rect(0, 0, 0, 0);

        this.selectionRectangle.attr({
            id: 'sel-rect-tool-' + this.id,
            'fill-opacity': 0,
            stroke: '#2880E6',
            'stroke-width': '1px',
            // 'cursor': 'move',
            'pointer-events': 'none',
            x: this.localOuterBounds.x,
            y: this.localOuterBounds.y,
            width: this.localOuterBounds.width,
            height: this.localOuterBounds.height,
            display: 'inline'
        });

        this.selectionRectangle.node.setAttribute('transform', this.localOuterMatrix.toString());
    }

    updateBounds(
        newLocalBBox: Rectangle2D,
        newLocalBBoxMatrix: Snap.Matrix,
        newLocalInnerBounds: Rectangle2D
    ) {
        if (newLocalBBox && !this.localOuterBounds.equals(newLocalBBox)) {
            this.localOuterBounds = newLocalBBox;
            if (this.selectionRectangle) {
                this.selectionRectangle.node.setAttribute('x', this.localOuterBounds.x.toString());
                this.selectionRectangle.node.setAttribute('y', this.localOuterBounds.y.toString());
                this.selectionRectangle.node.setAttribute(
                    'width',
                    this.localOuterBounds.width.toString()
                );
                this.selectionRectangle.node.setAttribute(
                    'height',
                    this.localOuterBounds.height.toString()
                );
            }
        }

        if (newLocalInnerBounds && !this.localInnerBounds.equals(newLocalInnerBounds)) {
            this.localInnerBounds = newLocalInnerBounds;
        }

        if (newLocalBBoxMatrix) {
            this.transform(newLocalBBoxMatrix);
        }
    }
    /*
    translate(deltaX: number, deltaY: number, transformationMatrix: Snap.Matrix) {

        this.localOuterMatrix = transformationMatrix;
        if (this.selectionRectangle) {
            this.selectionRectangle.node.setAttribute('transform', transformationMatrix.toString());
            //this.selectionRectangle.attr({ transform: transformationMatrix.toString() });
            // prependTranslation(deltaX, deltaY, this.selectionRectangle);
        }
    }

    rotate(angle: number, transformationMatrix: Snap.Matrix) {
        this.localOuterMatrix = transformationMatrix;
        //this.localOuterMatrix.rotate(angle, this.localOuterBounds.cx, this.localOuterBounds.cy);

        if (this.selectionRectangle) {
            this.selectionRectangle.node.setAttribute(
                'transform',
                this.localOuterMatrix.toString()
            );
        }
    }*/

    transform(matrix: Snap.Matrix) {
        this.localOuterMatrix = matrix.clone();

        if (this.selectionRectangle) {
            this.selectionRectangle.node.setAttribute(
                'transform',
                this.localOuterMatrix.toString()
            );
        }
    }
}
