<div class="draggable-mirror-container" [ngStyle]="containerStyle()" #container>
    <div
        class="draggable-mirror-items"
        [style.display]="isMirroredNodeVisible ? 'block' : 'none'"
        [innerHtml]="mirrorDraggedItem"
    ></div>
    <div
        class="svg-preview-item-container"
        [style.visibility]="isPreviewNodeVisible ? 'visible' : 'hidden'"
    >
        <svg
            #svgPreviewContainer
            id="svg-preview-item"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        ></svg>
    </div>
</div>
