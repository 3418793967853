import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ImageContourModel } from '../../../models/image-contour.model';
import { ConfiguratorStoreService } from '../../../configurator-store.service';
import { CustomValidators } from '../../../shared/form/validators';
import { DialogRefs } from '../../../dialog/dialog-refs';
import { FoamEditorService } from '../../foam-editor.service';

@Component({
    selector: 'app-image-contour-edit-dialog',
    templateUrl: './image-contour-edit-dialog.component.html',
    styleUrls: ['./image-contour-edit-dialog.component.scss']
})
export class ImageContourEditDialogComponent implements OnInit, OnDestroy {
    buttonCancelText: string;
    form: FormGroup;
    imageContour: ImageContourModel;
    private onDestroy = new Subject();

    constructor(
        public dialogRefs: DialogRefs<ImageContourEditDialogComponent>,
        private storeService: ConfiguratorStoreService,
        private foamEditorService: FoamEditorService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.translate.stream(['NAVIGATION_BUTTON']).subscribe(res => {
            this.buttonCancelText = res.NAVIGATION_BUTTON.CANCEL;
        });
        this.setForm();
    }

    cancel() {
        this.form.reset(this.imageContour);
        this.dialogRefs.close();
    }

    private setForm() {
        this.form = new FormGroup({
            title: new FormControl(this.imageContour.title, [
                Validators.required,
                Validators.maxLength(20)
            ]),
            description: new FormControl(this.imageContour.description),
            depth: new FormControl(this.imageContour.depth, [
                Validators.required,
                Validators.min(1),
                CustomValidators.positiveInteger
            ])
        });
    }

    /**
     * Saves the image contour update.
     */
    save() {
        if (this.form.invalid) {
            return;
        }

        const contour: ImageContourModel = {
            id: this.imageContour.id,
            imageContourType: this.imageContour.imageContourType,
            title: this.form.get('title').value,
            description: this.form.get('description').value,
            depth: Number(this.form.get('depth').value),
            width: this.imageContour.width,
            height: this.imageContour.height,
            previewImage: this.imageContour.previewImage,
            image: this.imageContour.image,
            svgPathDefinition: this.imageContour.svgPathDefinition
        };

        this.foamEditorService
            .edit(contour)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(
                () => {
                    this.dialogRefs.close();
                },
                err => {
                    console.error(err);
                }
            );
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
    }
}
