import { NgModule } from '@angular/core';
import { DroppableItemDirective } from './droppable-item.directive';
import { DroppableContainerDirective } from './droppable-container.directive';
import { DropZoneDirective } from './drop-zone.directive';
import { DroppableMirrorComponent } from './droppable-mirror/droppable-mirror.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule
        // App modules
    ],
    declarations: [
        DroppableItemDirective,
        DroppableContainerDirective,
        DropZoneDirective,
        DroppableMirrorComponent
    ],
    exports: [
        DroppableItemDirective,
        DroppableContainerDirective,
        DropZoneDirective,
        DroppableMirrorComponent
    ],
    entryComponents: []
})
export class DroppableModule {}
