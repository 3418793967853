import { Action, ActionEvent } from './action';
import { FoamEditorService } from '../foam-editor.service';
import { CanvasService } from '../canvas/canvas.service';
import { GroupCuttableContour } from '../canvas/contour/group-cuttable-contour';
import { CuttableContour } from '../canvas/contour/cuttable-contour';
import { CanvasContour } from '../canvas/contour/contour-items-interfaces';
import { UngroupUndoableEditAction } from '../undo/ungroup-undoable-edit-action';
import { GroupUndoableEditAction } from '../undo/group-undoable-edit-action';
import { RecessedGripContour } from '../canvas/contour/recessed-grip-contour';

export class GroupAction implements Action {
    private readonly canvasService: CanvasService;

    constructor(private readonly foamEditor: FoamEditorService) {
        this.canvasService = this.foamEditor.getCanvasService();
    }

    private compareToGroupContours(a: CuttableContour, b: CuttableContour): number {
        const aIsGrip = a instanceof RecessedGripContour;
        const bIsGrip = b instanceof RecessedGripContour;

        if (aIsGrip && !bIsGrip) {
            return -1;
        }
        if (!aIsGrip && bIsGrip) {
            return 1;
        }

        // (!aIsGrip && !bIsGrip) || (aIsGrip && bIsGrip)
        return 0;
    }

    canGroup(): boolean {
        return this.canvasService.getSelectedContourItems().length > 1;
    }

    canUngroup(): boolean {
        const selectedItems = this.canvasService.getSelectedContourItems();
        return selectedItems.length === 1 && selectedItems[0] instanceof GroupCuttableContour;
    }

    execute(event: GroupActionEvent) {
        if (event.requestGrouping) {
            if (this.canGroup()) {
                const toRemoveContours: CanvasContour[] = this.canvasService
                    .getSelectedContourItems()
                    .filter(x => x instanceof CuttableContour || x instanceof GroupCuttableContour);

                const toGroupContours: CuttableContour[] = [];
                toRemoveContours.forEach(x => {
                    if (x instanceof GroupCuttableContour) {
                        toGroupContours.push(...x.children);
                    } else {
                        toGroupContours.push(x as CuttableContour);
                    }
                });

                toGroupContours.sort(this.compareToGroupContours);
                const newGroupItem = new GroupCuttableContour(toGroupContours);

                this.canvasService.clearSelection();
                this.canvasService.removeContours(toRemoveContours);
                this.canvasService.addContourItem(newGroupItem);
                this.canvasService.addToSelection([newGroupItem]);

                this.canvasService
                    .getUndoManagerService()
                    .addEditAction(new GroupUndoableEditAction(newGroupItem));
            }
        } else if (this.canUngroup()) {
            const groupContour = this.canvasService.getSelectedContourItems()[0] as GroupCuttableContour;

            const undoAction = new UngroupUndoableEditAction(groupContour);

            const toUngroupContours = groupContour.children;
            this.canvasService.removeContours([groupContour]);

            toUngroupContours.forEach(contour => {
                this.canvasService.addContourItem(contour);
            });
            this.canvasService.addToSelection(toUngroupContours);

            // add undo action at the end to make sure that it is only added if none of the
            // methods calls above don't fail
            this.canvasService.getUndoManagerService().addEditAction(undoAction);
        }
    }
}

export interface GroupActionEvent extends ActionEvent {
    requestGrouping: boolean;
}
