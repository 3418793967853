import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { auditTime, distinctUntilChanged, mergeAll, switchMap, take } from 'rxjs/operators';

import { ImageContourModel } from '../models/image-contour.model';

import { ConfiguratorStoreService } from '../configurator-store.service';

@Injectable()
export class ImageContourSearchService {
    constructor(private readonly toolContoursDataStore: ConfiguratorStoreService) {}

    private doFakeSearch(query: string, type: SearchItemType): Observable<ImageContourModel[]> {
        let getItemFunctionName: string;
        if (type === SearchItemType.PARTNER_CONTOURS) {
            getItemFunctionName = 'getPartnerItems';
        } else if (type === SearchItemType.USER_CONTOURS) {
            getItemFunctionName = 'getUserItems';
        } else {
            console.error('Unknown database search');
            return type;
        }

        const searchResult: ImageContourModel[] = [];
        const reqExpQuery = new RegExp('.*' + this.escapeRegExp(query) + '.*', 'i');

        this.toolContoursDataStore[getItemFunctionName]()
            .pipe(take(1), mergeAll())
            .subscribe((contourItem: ImageContourModel) => {
                if (contourItem.title.search(reqExpQuery) > -1) {
                    searchResult.push(contourItem);
                }
            });

        return of(searchResult);
    }

    // TODO move it to utils
    private escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    /**
     * Search the tool contour item data store using the given query
     * @param query
     */
    search(query: Observable<string>, type: SearchItemType): Observable<ImageContourModel[]> {
        return query
            .pipe(auditTime(300))
            .pipe(distinctUntilChanged())
            .pipe(switchMap(term => this.doFakeSearch(term, type)));
    }

    /**
     * Fire and forget search
     * @param query
     * @param type
     */
    oneShotSearch(query: string, type: SearchItemType): Observable<ImageContourModel[]> {
        return this.doFakeSearch(query, type);
    }
}

export enum SearchItemType {
    PARTNER_CONTOURS,
    USER_CONTOURS
}
