/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-footer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dialog-footer.component";
import * as i3 from "../dialog-refs";
var styles_DialogFooterComponent = [i0.styles];
var RenderType_DialogFooterComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DialogFooterComponent, data: {} });
export { RenderType_DialogFooterComponent as RenderType_DialogFooterComponent };
export function View_DialogFooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_DialogFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-footer", [["class", "modal-card-foot"]], null, null, null, View_DialogFooterComponent_0, RenderType_DialogFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.DialogFooterComponent, [i3.DialogRefs], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogFooterComponentNgFactory = i1.ɵccf("app-dialog-footer", i2.DialogFooterComponent, View_DialogFooterComponent_Host_0, {}, {}, [".app-dialog-footer"]);
export { DialogFooterComponentNgFactory as DialogFooterComponentNgFactory };
