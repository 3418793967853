<div class="project-properties">
    <div class="project-dimensions">{{ boxName }}</div>
    <div class="project-title">
        <input
            #configNameInput
            type="text"
            disabled
            [class.project-title__text]="!isTextInputDisabled"
            [class.project-title__text--disabled]="isTextInputDisabled"
            placeholder="{{ 'EDITOR.CHOOSE_LAYOUT_NAME' | translate }}"
            maxlength="25"
            (keydown.enter)="onEnter()"
            [value]="configurationName"
            (change)="onChange()"
            (blur)="onBlur($event)"
        />

        <div class="project-title__btn-container">
            <app-button
                #editButton
                tabindex="0"
                app-icon-button
                class="project-title__btn"
                iconAlign="horizontal"
                buttonSize="small"
                appTooltip
                appToolTipContent="{{ 'EDITOR.EDIT' | translate }}"
                [appTooltipPlacement]="'bottom'"
                (click)="onEditLayoutName()"
            >
                <app-icon
                    shape="pencil"
                    width="24"
                    height="24"
                    iconSize="small"
                    cssWidth="23px"
                    cssHeight="23px"
                ></app-icon>
            </app-button>
        </div>
    </div>
    <app-project-validity-info [hasCollision]="hasCollision"></app-project-validity-info>
</div>
