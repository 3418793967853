import { UndoableEditaAction } from './undo-manager.service';
import { GroupCuttableContour } from '../canvas/contour/group-cuttable-contour';
import { GroupUndoableEditAction } from './group-undoable-edit-action';

export class UngroupUndoableEditAction implements UndoableEditaAction {
    readonly presentationName = 'Ungroup contour';
    readonly redoPresentationName: string = 'Ungroup contour';
    readonly undoPresentationName: string = 'Group contour';
    private readonly groupUndoableEditAction: GroupUndoableEditAction;
    constructor(
        private readonly groupContour: GroupCuttableContour,
        undoPresentationName?: string,
        redoPresentationName?: string
    ) {
        this.undoPresentationName = undoPresentationName || this.undoPresentationName;
        this.redoPresentationName = redoPresentationName || this.redoPresentationName;
        this.groupUndoableEditAction = new GroupUndoableEditAction(
            groupContour,
            this.undoPresentationName,
            this.redoPresentationName
        );
    }

    redo(): void {
        this.groupUndoableEditAction.undo();
    }

    undo(): void {
        this.groupUndoableEditAction.redo();
    }
}
