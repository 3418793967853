import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { FoamEditorModule } from './foam-editor/foam-editor.module';

@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forRoot([], { enableTracing: false }),
        // App modules
        SharedModule,
        FoamEditorModule
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {}
