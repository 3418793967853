import { Directive, ElementRef, Input, OnInit, Optional } from '@angular/core';
import { DroppableContainerDirective } from './droppable-container.directive';
import { DroppableItem } from './droppable-item';

export type DroppableItemType = 'IMAGE_CONTOUR' | 'TEXT' | 'CIRCLE' | 'RECTANGLE' | 'RECESSED_GRIP';

@Directive({
    selector: '[appDroppableItem]'
})
export class DroppableItemDirective implements OnInit, DroppableItem {
    @Input()
    public dropZone: string;
    @Input()
    public droppableItemType: DroppableItemType;
    @Input()
    public droppableItemDisabled: boolean;
    nativeElement: Element = undefined;
    // Other native elements not using the [appDroppableItem] but that should be treat like this DroppableItem
    relatedNativeElements: Element[] = undefined;

    constructor(
        @Optional() public droppableContainer: DroppableContainerDirective,
        @Optional() public elementRef: ElementRef
    ) {
        if (elementRef) {
            this.nativeElement = elementRef.nativeElement;
            this.nativeElement.classList.add('draggable-item');
        }
    }

    ngOnInit(): void {
        if (this.droppableContainer) {
            if (!this.droppableItemDisabled) {
                this.droppableContainer.addItem(this);
            }
        } else {
            //TODO implement use case  when this item is its own container
        }
    }
}
