<app-dialog-header>
    <h1 class="app-dialog-title">{{ 'SAVE_ERROR_DIALOG.TITLE' | translate }}</h1>
</app-dialog-header>
<app-dialog-content>
    <div class="app-dialog-body">
        <p class="error-container">{{ 'SAVE_ERROR_DIALOG.MESSAGE' | translate }}</p>
    </div>
</app-dialog-content>
<app-dialog-footer>
    <div class="app-dialog-footer">
        <app-button
            app-icon-button
            [color]="'secondary'"
            iconAlign="horizontal"
            textPosition="after"
            buttonSize="normal"
            (click)="cancel()"
        >
            <app-icon svgWidth="24" svgHeight="24" shape="icon-close" iconSize="small"> </app-icon>
            {{ 'SAVE_ERROR_DIALOG.BUTTON_LABEL' | translate }}
        </app-button>
    </div>
</app-dialog-footer>
