import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const DIGIT_REGEX = /^[0-9]*$/;

const hasNoErrors = (value: any): boolean => {
    return value !== undefined && value !== null;
};

export class CustomValidators {
    /**
     * Extends the  Validators.pattern with a description property.
     *
     */
    static positiveInteger(control: AbstractControl): ValidationErrors | null {
        const patternValidator: ValidatorFn = Validators.pattern(DIGIT_REGEX);
        const errors: ValidationErrors = patternValidator(control);
        return errors && errors['pattern'] ? { positiveInteger: errors['pattern'] } : null;
    }

    static rangeNumber(start: number, end: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (hasNoErrors(Validators.required(control))) {
                return null;
            }

            const value: number = control.value;
            return value >= start && value <= end
                ? null
                : {
                      rangeNumber: {
                          requiredMin: start,
                          requiredMax: end
                      }
                  };
        };
    }
}
