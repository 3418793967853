import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { PreviewContourInfo } from '../../foam-editor/ribbon/ribbon-panels/grip-text-contour-panel/preview-contour.service';
import { DroppableStateStore } from '../droppable-state-store.service';

declare var Snap: any;

export const ALIGN_MODE_CURSOR_OFFSET = 'cursor-offset';
export const ALIGN_MODE_CENTER = 'center';

export type DroppableMirrorAlignMode = 'center' | 'cursor-offset';
export type MirrorDropState = 'outside' | 'enter' | 'stop';

@Component({
    selector: 'app-droppable-mirror',
    templateUrl: './droppable-mirror.component.html',
    styleUrls: ['./droppable-mirror.component.scss']
})
export class DroppableMirrorComponent implements AfterViewInit {
    @ViewChild('svgPreviewContainer', { static: false })
    readonly svgPreviewContainer: ElementRef;

    @ViewChild('container', { static: false })
    readonly container: ElementRef;

    @Input()
    mirrorDraggedItem: SafeHtml;

    isMirroredNodeVisible: boolean;
    isPreviewNodeVisible: boolean;
    private position = 'absolute';
    top: number;
    left: number;
    width: number;
    height: number;
    alignMode: DroppableMirrorAlignMode = ALIGN_MODE_CURSOR_OFFSET;
    svgPreviewCanvas: Snap.Paper;
    cursorOffsetX: number;
    cursorOffsetY: number;
    private _dropState: MirrorDropState;
    svgPreviewRoot: SVGSVGElement;

    constructor(public elementRef: ElementRef, private droppableStateStore: DroppableStateStore) {
        this.droppableStateStore.updateDroppableMirror(this);
    }

    containerStyle() {
        let actualLeft;
        let actualTop;
        if (this.alignMode === ALIGN_MODE_CURSOR_OFFSET) {
            actualLeft = this.left - this.cursorOffsetX;
            actualTop = this.top - this.cursorOffsetY;
        } else if (this.alignMode === ALIGN_MODE_CENTER) {
            actualLeft = this.left - this.width / 2;
            actualTop = this.top - this.height / 2;
        }

        return {
            position: this.position,
            top: `${actualTop}px`,
            left: `${actualLeft}px`,
            width: `${this.width}px`,
            height: `${this.height}px`
        };
    }

    ngAfterViewInit() {
        this.dropState = 'outside';
        this.svgPreviewCanvas = Snap(this.svgPreviewContainer.nativeElement);
        this.svgPreviewRoot = this.svgPreviewContainer.nativeElement as SVGSVGElement;
    }

    set dropState(value: MirrorDropState) {
        if (value && value !== this._dropState) {
            if (this._dropState) {
                this.container.nativeElement.classList.remove(`app-mirror-${this._dropState}`);
            }
            this.container.nativeElement.classList.add(`app-mirror-${value}`);
            this._dropState = value;
        }
    }

    get dropState(): MirrorDropState {
        return this._dropState;
    }

    setPreviewContour(previewContour: PreviewContourInfo) {
        this.svgPreviewRoot.setAttribute('width', previewContour.svgRootWidth.toString());
        this.svgPreviewRoot.setAttribute('height', previewContour.svgRootHeight.toString());
        if (previewContour.contourSvg) {
            this.svgPreviewRoot.appendChild(previewContour.contourSvg);
        }
    }
}
