import { UndoableEditaAction } from './undo-manager.service';
import { GroupCuttableContour } from '../canvas/contour/group-cuttable-contour';
import { CuttableContour } from '../canvas/contour/cuttable-contour';

export class GroupUndoableEditAction implements UndoableEditaAction {
    readonly presentationName = 'Group contour';
    readonly redoPresentationName: string = 'Group contour';
    readonly undoPresentationName: string = 'Ungroup contour';
    private readonly children: CuttableContour[];

    constructor(
        private readonly groupContour: GroupCuttableContour,
        undoPresentationName?: string,
        redoPresentationName?: string
    ) {
        this.undoPresentationName = undoPresentationName || this.undoPresentationName;
        this.redoPresentationName = redoPresentationName || this.redoPresentationName;
        // clone and cache array since executing undo() or ungroup() action will remove all children
        // Therefore we make this.groupContour.children undefined
        this.children = this.groupContour.children.slice(0);
    }

    redo(): void {
        const canvasService = this.groupContour.getCanvas();
        canvasService.clearSelection();

        this.groupContour.children = this.children;
        canvasService.removeContours(this.groupContour.children);
        canvasService.addContourItem(this.groupContour);
        canvasService.addToSelection([this.groupContour]);
    }

    undo(): void {
        const canvasService = this.groupContour.getCanvas();

        // Note: this will also remove all children
        canvasService.removeContours([this.groupContour]);

        this.children.forEach(contour => {
            canvasService.addContourItem(contour);
        });
        canvasService.addToSelection(this.children);
        // add cached contours
        this.groupContour.children = this.children;
    }
}
