import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FoamEditorComponent } from './foam-editor/foam-editor.component';
import { Subject } from 'rxjs';
import { RouterService } from './route.services';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ConfiguratorStoreService } from './configurator-store.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild(FoamEditorComponent, { static: false })
    private readonly foamEditorComponent: FoamEditorComponent;

    private onDestroy = new Subject();

    isLoading = false;
    isError = false;

    constructor(
        private readonly storeService: ConfiguratorStoreService,
        private routerService: RouterService
    ) {}

    ngOnInit(): void {
        this.watchNavigationEnd();
        this.isLoading = true;
    }

    ngAfterViewInit(): void {
        this.foamEditorComponent.onInitialized.subscribe(() => {
            this.isLoading = false;
        });
    }

    watchNavigationEnd() {
        this.routerService
            .getRouteOnNavigationEnd()
            .pipe(
                // get route snapshot
                map(route => route.snapshot),
                takeUntil(this.onDestroy)
            )
            .subscribe(
                route => {
                    let sessionRef = null;
                    if (environment.allowUrlSession) {
                        sessionRef = (route as any).queryParamMap.get('sessionRef');
                    }
                    this.storeService.loadFoamConfiguration(sessionRef);
                },
                err => {
                    console.error(err);
                    this.isError = true;
                }
            );
    }
}
