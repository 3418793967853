/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ribbon-tab-item.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ribbon-tab-item.component";
var styles_RibbonTabItemComponent = [i0.styles];
var RenderType_RibbonTabItemComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RibbonTabItemComponent, data: {} });
export { RenderType_RibbonTabItemComponent as RenderType_RibbonTabItemComponent };
export function View_RibbonTabItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-tab-item-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_RibbonTabItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ribbon-tab-item", [], [[8, "id", 0], [2, "app-tab-item-selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onTabClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RibbonTabItemComponent_0, RenderType_RibbonTabItemComponent)), i1.ɵdid(1, 114688, null, 1, i2.RibbonTabItemComponent, [], null, null), i1.ɵqud(603979776, 1, { tabItemElement: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).tabItemId; var currVal_1 = i1.ɵnov(_v, 1)._selected; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var RibbonTabItemComponentNgFactory = i1.ɵccf("app-ribbon-tab-item", i2.RibbonTabItemComponent, View_RibbonTabItemComponent_Host_0, { itemId: "itemId", selected: "selected" }, {}, ["*"]);
export { RibbonTabItemComponentNgFactory as RibbonTabItemComponentNgFactory };
