/**
 * A simple wrapper for contour SVG elements
 */
import { transformMatrix } from '../shared/geom/matrix';
import { Rectangle2D } from '../shared/geom/rectangle2D';
import { transformBoundingBox } from './contour/contour-helper';

import { ContourElementType } from './contour/contour-element-type';
import { LayerElementStoreService } from './layer-element-store.service';

export class ContourElementRefs {
    contourId: string;
    [ContourElementType.ITEM_GROUP]: Snap.Element;
    [ContourElementType.ITEM_PATH]: Snap.Element;
    [ContourElementType.ITEM_IMAGE]: Snap.Element;
    [ContourElementType.MARGIN_PATH]: Snap.Element;
    [ContourElementType.ITEM_TEXT]: Snap.Element;

    marginSize?: number;
    margin: {
        marginPathString?: string;
        globalMatrix: Snap.Matrix;
        localMatrix: Snap.Matrix;
        globalBBox: Rectangle2D;
        localBBox: Rectangle2D;
    };

    contour: {
        svgPathDefinition?: string;
        globalMatrix: Snap.Matrix;
        localMatrix: Snap.Matrix;
        globalBBox: Rectangle2D;
        localBBox: Rectangle2D;
    };
    // Note for grouping (also multiple grouping) one level is always enough
    // children?: Map<string, ContourElementRefs<T>> = new Map();

    constructor(contourId: string, private layerElementStore: LayerElementStoreService) {
        this.contour = {
            svgPathDefinition: undefined,
            globalMatrix: undefined,
            localMatrix: undefined,
            globalBBox: undefined,
            localBBox: undefined
        };

        this.margin = {
            marginPathString: undefined,
            globalMatrix: undefined,
            localMatrix: undefined,
            globalBBox: undefined,
            localBBox: undefined
        };
    }

    updateTransformData() {
        // TODO cleaning up
        this.updateContourTransformData(true);
        this.updateMarginTransformedData(true);
        /*
        this.layerElementStore.addContourElementChanges({
            contourId: this.contourId,
            contour: this.contour,
            margin: this.margin,
        }); */
    }

    updateMarginTransformedData(asCombinedChange?: boolean) {
        // Update margin transformed data
        const marginNode = this[ContourElementType.MARGIN_PATH];
        if (marginNode) {
            const marginLocalBbox = Rectangle2D.fromObject((marginNode.node as any).getBBox());
            const marginTx = transformMatrix(marginNode.node as any, marginLocalBbox);
            this.margin.globalMatrix = marginTx.globalMatrix;
            this.margin.localMatrix = marginTx.localMatrix;
            this.margin.localBBox = marginLocalBbox;
            this.margin.globalBBox = Rectangle2D.fromPointsObject(
                transformBoundingBox(marginLocalBbox, marginTx.globalMatrix)
            );

            /*
            if (!asCombinedChange) {
                this.layerElementStore.addContourElementChanges({
                    contourId: this.contourId,
                    margin: this.margin,
                });
            } */
        }
    }

    updateContourTransformData(asCombinedChange?: boolean) {
        // let changed = false;
        if (this[ContourElementType.ITEM_TEXT]) {
            const textElem = this[ContourElementType.ITEM_TEXT];
            const localBbox = Rectangle2D.fromObject((textElem.node as any).getBBox());
            const { globalMatrix, localMatrix } = transformMatrix(textElem.node as any, localBbox);

            this.contour.globalMatrix = globalMatrix;
            this.contour.localMatrix = localMatrix;
            this.contour.localBBox = localBbox;
            // this.contour.globalBBox = Rectangle2D.fromObject(textElem.getBBox());
            this.contour.globalBBox = Rectangle2D.fromPointsObject(
                transformBoundingBox(localBbox, globalMatrix)
            );
            // changed = true;
        } else if (this[ContourElementType.ITEM_GROUP]) {
            const groupNode = this[ContourElementType.ITEM_GROUP];
            const localBbox = Rectangle2D.fromObject((groupNode.node as any).getBBox());
            const { globalMatrix, localMatrix } = transformMatrix(groupNode.node as any, localBbox);

            this.contour.globalMatrix = globalMatrix;
            this.contour.localMatrix = localMatrix;
            this.contour.localBBox = localBbox;
            this.contour.globalBBox = Rectangle2D.fromPointsObject(
                transformBoundingBox(localBbox, globalMatrix)
            );
            // changed = true;
        } else if (this[ContourElementType.ITEM_PATH]) {
            const itemPath = this[ContourElementType.ITEM_PATH];
            // for contours wto a group like foams
            const localBbox = Rectangle2D.fromObject((itemPath.node as any).getBBox());
            const { globalMatrix, localMatrix } = transformMatrix(itemPath.node as any, localBbox);

            this.contour.globalMatrix = globalMatrix;
            this.contour.localMatrix = localMatrix;
            this.contour.localBBox = localBbox;
            this.contour.globalBBox = Rectangle2D.fromPointsObject(
                transformBoundingBox(localBbox, globalMatrix)
            );
            // changed = true;
        }
        /*
                if (changed && !asCombinedChange) {
                    this.layerElementStore.addContourElementChanges({
                        contourId: this.contourId,
                        contour: this.contour,
                    });
        } */
    }

    /**
     * @deprecated
     */
    deleteAll() {
        if (this[ContourElementType.ITEM_PATH]) {
            this[ContourElementType.ITEM_PATH].remove();
            this[ContourElementType.ITEM_PATH] = undefined;
        }

        if (this[ContourElementType.MARGIN_PATH]) {
            this[ContourElementType.MARGIN_PATH].remove();
            this[ContourElementType.MARGIN_PATH] = undefined;
        }

        if (this[ContourElementType.ITEM_TEXT]) {
            this[ContourElementType.ITEM_TEXT].remove();
            this[ContourElementType.ITEM_TEXT] = undefined;
        }

        if (this[ContourElementType.ITEM_GROUP]) {
            this[ContourElementType.ITEM_GROUP].remove();
            this[ContourElementType.ITEM_GROUP] = undefined;
        }

        if (this[ContourElementType.ITEM_IMAGE]) {
            this[ContourElementType.ITEM_IMAGE].remove();
            this[ContourElementType.ITEM_IMAGE] = undefined;
        }
    }
}
