/**
 * A directed and unidirectional graph for representing collisions among contour items
 */
import { Injectable } from '@angular/core';

@Injectable()
export class CollisionGraph {
    private readonly nodes: AdjacencyList = {};

    /**
     * Adds an edge (collision) from the vertex nodeV to vertex nodeU. To enforce the
     * uni-directionality of edges, it also remove the edge from nodeU to nodeV, if present
     * @param {number} nodeV
     * @param {number} nodeU
     */
    addEdge(nodeV: string, nodeU: string) {
        if (nodeV === nodeU) {
            console.error('CollisionGraph does not allow self-loop edges');
            return;
        }
        const adjacentNodes = this.getOrCreateVertex(nodeV);
        if (adjacentNodes.has(nodeU)) {
            return;
        }
        adjacentNodes.add(nodeU);

        // enforce uni-directionality
        const adjacentNodesU = this.nodes[nodeU];
        if (adjacentNodesU) {
            adjacentNodesU.delete(nodeV);
        }
    }

    private getOrCreateVertex(vertex: string): Set<string> {
        let adjacentNodes = this.nodes[vertex];
        if (!adjacentNodes) {
            adjacentNodes = new Set<string>();
            this.nodes[vertex] = adjacentNodes;
        }

        return adjacentNodes;
    }

    /**
     * Gets all nodes connected <b>from</b> the given node
     *
     * @param {number} node
     * @returns {Set<number> | null} a set of adjacent nodes, or undefined if the given node is not
     * present in the graph
     */
    getAdjacentNodes(node: string): Set<string> | undefined {
        const adjacentNodes = this.nodes[node];
        if (adjacentNodes) {
            return new Set(adjacentNodes);
        }
        return undefined;
    }

    /**
     * Tests if there is an edge from nodeV to nodeU
     *
     * @param {number} nodeV
     * @param {number} nodeU
     */
    isAdjacentNode(nodeV: string, nodeU: string): boolean {
        const adjacentNodes = this.nodes[nodeV];
        if (adjacentNodes) {
            return adjacentNodes.has(nodeU);
        }
        return false;
    }

    /**
     * Tests if the given {@link node} has an edge with any other nodes
     * This is used for checking the newness of a node in the CollisionGraph since checking the
     * newness also involves searching at the edge level since a node that collides with others but
     * has not been selected are only stored as an edge
     *
     * @param node
     */
    hasEdge(node: string) {
        const adjacentNodes = this.nodes[node];
        if (adjacentNodes && adjacentNodes.size > 0) {
            return true;
        }
        const result = Object.values(this.nodes).find((s: Set<string>) => s.has(node));
        // coerce the result to boolean
        return !!result;
    }

    countEdges(): number {
        const found = Object.values(this.nodes).filter(s => s.size > 0);
        return found.length;
    }

    /**
     * Marks a node as selected by reversing the direction of all edges that end in the
     * given {@code node}. As result, the given {@code node} will act as the start node of these edges.
     *
     * Returns nodes that previously acted as starting node of to the given {@code node}
     * @param node
     */
    selectNode(node: string): Set<string> {
        const result = new Set<string>();
        let found = false;
        // find edges that end in node
        for (const [key, value] of Object.entries(this.nodes)) {
            if (value.has(node)) {
                result.add(key);
                found = true;
            }
        }

        if (found) {
            const adjacentWithSelectedNodes = this.getOrCreateVertex(node);
            result.forEach(key => {
                this.nodes[key].delete(node);
                adjacentWithSelectedNodes.add(key);
            });

            return result;
        }

        return null;
    }

    /**
     * Removes the edge from the vertex {@linkcode nodeV} to  the vertex {@linkcode nodeU}
     * @param {number} nodeV
     * @param {number} nodeU
     */
    removeEdge(nodeV: string, nodeU: string) {
        const adjacentNodes = this.nodes[nodeV];
        if (adjacentNodes) {
            adjacentNodes.delete(nodeU);
        }
    }

    /**
     * Removes all edges in which the given node is a starting as well as a ending node
     *
     * @param node
     */
    removeAllEdges(node: string) {
        const adjacentNodes = this.nodes[node];
        if (adjacentNodes) {
            adjacentNodes.clear();
        }
    }

    /**
     * Adds a node if it is not already present
     * @param {number} nodeV
     */
    addNode(nodeV: string) {
        this.getOrCreateVertex(nodeV);
    }

    /**
     * Tests if the collision graph contains the given node
     *
     * @param node
     */
    hasNode(node: string): boolean {
        return !!this.nodes[node];
    }

    /**
     * Returns the node if present in the graph or null if not
     *
     * @param node
     */
    getNode(node: string): Set<string> | null {
        return this.nodes[node] ? new Set(this.nodes[node]) : null;
    }

    /**
     * Gets all nodes from which a connection has been (now or previously) established to other nodes
     *
     * @returns {Set<string> | null}
     */
    getNodes(): Set<string> | null {
        return new Set(Object.keys(this.nodes));
    }

    /**
     * Removes a node and all the edges directed to or from it
     * @param {number} node
     * @returns true if the node was removed from the graph, or false if not
     */
    removeNode(node: string): boolean {
        if (this.nodes[node]) {
            delete this.nodes[node];
            for (const value of Object.values(this.nodes)) {
                value.delete(node);
            }
            return this.nodes[node] === undefined;
        }
        return false;
    }

    /**
     * Gets nodes
     *
     * @returns {GraphData}
     */
    getCollisionNodesWthEdges(): GraphData {
        const collisionNodes: AdjacencyList = {};
        let _size = 0;
        for (const nodeIndex of Object.keys(this.nodes)) {
            const node: Set<string> = this.nodes[nodeIndex];
            if (node && node.size > 0) {
                collisionNodes[nodeIndex] = new Set<string>(node.values());
                _size++;
            }
        }
        const collisionGraph: GraphData = { nodes: collisionNodes, size: _size };
        return _size > 0 ? collisionGraph : null;
    }
}

export interface GraphData {
    nodes: AdjacencyList;
    readonly size: number;
}

export interface AdjacencyList {
    [vertex: string]: Set<string>;
}
