import { Bounds } from '../../../../../shared/geom/geometry';
import { ContourSegmentationResult } from '../detection-result-canvas/detection-result-canvas.component';
import { getContourBounds } from './selection-utils';
import { deprecate } from 'util';

export class PaintSelectionModel {
    // first index = segmentation level
    regionPixels: number[][];
    /* Contains borders of all detected labels. This array is computed and set only once when
    the segmentation has started */
    // first index = segmentation level
    borderIndices: number[][][];

    maxSelectedRegionWidth: number = 5;
    maxSelectedRegionHeight: number = 4;

    currentLevel: number = 0;
    allSelectedRegions: Set<number>;
    currentSelectedRegions: Set<number>;
    noSupportedRegionIds: number[];
    width: number;
    height: number;
    // typically newSelectionMask + server-generated contourMask
    compoundSelectionMask: Uint8Array;
    // used for traceContour
    newSelectionMask: Uint8Array;
    /**
     *  @deprecate */
    smoothedSelectionMask: Uint8Array;
    innerContoursMask: Uint8Array;

    lastMaskFillIndices: Set<number>;

    /* total bounds including all contours */
    contours?: { x: number; y: number }[][];
    contoursBounds?: Bounds;
    contoursMask: number[];

    static checkBorderIndices(borderIndices: number[][][]) {
        borderIndices.forEach(arr => {
            if (arr.find(arr2 => !Array.isArray(arr2))) {
                throw new Error(
                    'BorderIndices must be a multi-dimensional array of type: number[][][]'
                );
            }
        });
    }

    static checkRegionPixels(regionPixels: number[][], imageArrSize: number) {
        if (regionPixels.length > 0) {
            const correctCount = regionPixels.filter(
                arr => Array.isArray(arr) && arr.length === imageArrSize
            ).length;
            if (correctCount !== regionPixels.length) {
                throw new Error(
                    'regionPixels must be a multi-dimensional array of type: number[][]'
                );
            }
        }
    }

    /**
     * This method is called when the segmentation result of the selected image contour is received
     * from the server or when or contour image get selected.
     * @param obj
     * @param width
     * @param height
     */
    init(obj: ContourSegmentationResult, width: number, height: number) {
        this.width = width;
        this.height = height;

        this.compoundSelectionMask = new Uint8Array(this.width * this.height);
        this.newSelectionMask = new Uint8Array(this.width * this.height);
        this.smoothedSelectionMask = new Uint8Array(this.width * this.height);
        this.innerContoursMask = new Uint8Array(this.width * this.height);
        this.contours = obj.contours;
        this.contoursMask = obj.contoursMask;
        this.contoursBounds = getContourBounds(this.contours, this.width, this.height);

        PaintSelectionModel.checkRegionPixels(obj.regionPixels, width * height);
        this.regionPixels = obj.regionPixels;

        PaintSelectionModel.checkBorderIndices(obj.borderIndices);
        this.borderIndices = obj.borderIndices;

        this.allSelectedRegions = new Set<number>();
        this.currentSelectedRegions = new Set<number>();

        this.lastMaskFillIndices = new Set<number>();
        this.noSupportedRegionIds = [];
    }

    update(obj: { contours: { x: number; y: number }[][]; contoursMask: number[] }) {
        this.contours = obj.contours;

        this.checkContoursMask(obj.contoursMask);
        this.contoursMask = obj.contoursMask;

        this.contoursBounds = getContourBounds(this.contours, this.width, this.height);
    }

    private checkContoursMask(contoursMask: number[]): void {
        if (
            Array.isArray(contoursMask) &&
            contoursMask.length !== this.compoundSelectionMask.length
        ) {
            throw new Error('contoursMask length does not match with the imageMask length');
        }
    }
}
