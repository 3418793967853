import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { DialogService } from './dialog.service';
import { DialogContainerComponent } from './dialog-container.component';
import { ModalDialogSlotDirective } from './modal-dialog-slot.directive';
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component';
import { ButtonComponent } from '../shared/button/button.component';
import { IconComponent } from '../shared/icon/icon.component';
import { TooltipDirective } from '../shared/tooltip/tooltip.directive';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [CommonModule, SharedModule],
    exports: [
        DialogHeaderComponent,
        DialogContentComponent,
        DialogFooterComponent,
        DialogContainerComponent,
        ButtonComponent,
        IconComponent,
        TooltipDirective
    ],
    declarations: [
        DialogHeaderComponent,
        DialogContentComponent,
        DialogFooterComponent,
        DialogContainerComponent,
        ModalDialogSlotDirective
    ],
    entryComponents: [DialogContainerComponent, DialogContentComponent]
})
export class DialogModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DialogModule,
            providers: [DialogService]
        };
    }
}
