import { DOCUMENT } from '@angular/common';
import {
    ApplicationRef,
    ComponentFactoryResolver,
    Inject,
    Injectable,
    Injector,
    OnDestroy,
    Type,
    ViewContainerRef
} from '@angular/core';
import { DialogContainerComponent } from './dialog-container.component';
import { DialogRefs } from './dialog-refs';
import {
    DomPortalSlot,
    InlComponentPortal,
    ViewContainerPortalSlot
} from '../shared/portal/portal';

@Injectable()
export class DialogService implements OnDestroy {
    private containerElement: HTMLElement;
    private dialogBackground: HTMLElement;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {}

    openDialog<T>(
        component: Type<T> | ComponentType<T>,
        testViewContainerRef?: ViewContainerRef
    ): DialogRefs<T> {
        let portalSlot: DomPortalSlot | ViewContainerPortalSlot;
        if (testViewContainerRef) {
            portalSlot = new ViewContainerPortalSlot(
                testViewContainerRef,
                this.appRef,
                this.componentFactoryResolver,
                this.injector
            );
        } else {
            portalSlot = new DomPortalSlot(
                this.createDialogPortalSlot(),
                this.appRef,
                this.componentFactoryResolver,
                this.injector
            );
        }
        const dialogContainer = this.attachDialogContainer(portalSlot);
        const dialogRef = this.attachDialogContent(component, dialogContainer, portalSlot);

        dialogRef.afterClosed.subscribe(() => this.removeDialogBackground());
        return dialogRef;
    }

    private attachDialogContainer(
        portalSlot: DomPortalSlot | ViewContainerPortalSlot
    ): DialogContainerComponent {
        const containerPortal = new InlComponentPortal(DialogContainerComponent, this.injector);
        const containerRef = portalSlot.attach<DialogContainerComponent>(containerPortal);

        return containerRef.instance;
    }

    private attachDialogContent<T>(
        component: Type<T>,
        dialogContainer: DialogContainerComponent,
        portalSlot: DomPortalSlot | ViewContainerPortalSlot
    ): DialogRefs<T> | undefined {
        // Add references that gives the user a handle to modify and close it.
        const dialogRef = new DialogRefs<T>(portalSlot, dialogContainer);

        const injector = this.createInjector<T>(dialogRef, dialogContainer);
        const contentRef = dialogContainer.attach(
            new InlComponentPortal(component, injector, null)
        );
        dialogRef.dialogContentComponent = contentRef.instance;

        return dialogRef;
    }

    private createInjector<T>(
        dialogRef: DialogRefs<T>,
        dialogContainer: DialogContainerComponent
    ): Injector {
        // The DialogContainerComponent is injected in the portal as the DialogContainerComponent
        // and the dialog's content are created out of the same ViewContainerRef (of the
        // DialogContainerComponent) and as such, are siblings for injector purposes. To allow the
        // hierarchy that is expected, the DialogContainerComponent is explicitly added to the
        // injection tokens.
        const injectionTokens = [
            { provide: DialogContainerComponent, useValue: dialogContainer },
            { provide: DialogRefs, useValue: dialogRef }
        ];

        return Injector.create({
            parent: this.injector,
            providers: injectionTokens
        });
    }

    private createDialogPortalSlot(): HTMLElement {
        this.createDialogBackground();
        const dialogSlot = this.document.createElement('div');
        dialogSlot.classList.add('dialog-wrapper');
        this.getDialogContainer().appendChild(dialogSlot);
        return dialogSlot;
    }

    private createDialogBackground(): HTMLElement {
        if (!this.dialogBackground) {
            this.dialogBackground = this.document.createElement('div');
            this.dialogBackground.classList.add('dialog-overlay-background');

            this.getDialogContainer().appendChild(this.dialogBackground);
        }

        return this.dialogBackground;
    }

    private removeDialogBackground() {
        if (this.dialogBackground && this.dialogBackground.parentNode) {
            this.dialogBackground.parentNode.removeChild(this.dialogBackground);
            this.dialogBackground = null;
        }
    }

    private getDialogContainer(): HTMLElement {
        if (!this.containerElement) {
            this.createDialogContainer();
        }
        return this.containerElement;
    }

    private createDialogContainer() {
        const container = this.document.createElement('div');
        container.classList.add('dialog-overlay');
        this.document.body.appendChild(container);

        this.containerElement = container;
    }

    ngOnDestroy() {
        if (this.containerElement && this.containerElement.parentNode) {
            this.containerElement.parentNode.removeChild(this.containerElement);
        }
    }
}

/** Interface that can be used to generically type a class. */
export interface ComponentType<T> {
    new (...args: any[]): T;
}
