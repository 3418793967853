<div #container class="toggle-switch" [style.height]="labelHeight">
    <input
        #inputElement
        type="checkbox"
        [id]="inputId"
        [disabled]="isDisabled"
        [checked]="checked"
        (click)="onInputClick($event)"
    />
    <label
        #labelElement
        [for]="inputId"
        [class.toggle-switch__disabled]="isDisabled"
        [style.width]="labelWidth"
        [style.height]="labelHeight"
    >
        <ng-content></ng-content>
    </label>
</div>
