import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { Paper } from 'snapsvg';

import { transformPoint } from '../shared/geom/matrix';

/* Specifies how much time elapses between each blink of the selection cursor. */
const CURSOR_BLINK_RATE = 530;

const POINTER_EVENT_CSS_ATTRIBUTE = 'pointer-events';
const DISABLE_NEWLINE_AND_LIMIT_TEXTINPUT = true;
const TEXT_INPUT_MAX_LENGTH = 20;

export class InlSVGTextInputElement {
    private svgPoint: SVGPoint;
    private cursor: Snap.Element;
    private charBBoxes: TextCharBBox[];
    private svgTextElement: SVGTextElement;
    private textBBox: Snap.BBox;
    private rect: Snap.Element[] = [];

    private textInputChangedSource = new Subject<InlTextChangedEvent>();
    private _editMode = false;
    private cursorPos: {
        startX: number;
        startY: number;
        endX: number;
        endY: number;
    } = { startX: 0, startY: 0, endX: 0, endY: 0 };

    textChanges: Observable<InlTextChangedEvent> = this.textInputChangedSource.asObservable();

    /**
     * The character positions of the last character of each line
     */
    private lineEndingsCharIndices: number[];
    /**
     * The selection positions of the last character of each line
     */
    private lineEndingsSelectionPos: number[];
    private previousLineEndingsSelPos: number[];
    private textBackground: Snap.Element;
    private cursorBlinker: number;
    private selectionBackground: Snap.Paper;
    private backgroundPadding: number = 2;
    private debugMode = false;
    private selectionPaths: SelectionPathInfo[] = [];
    isEmpty: boolean = false;
    private _keyUpSubscription: Subscription;
    private currentSelectionStart: number;
    contourId: string;
    textElement: Snap.Element;

    constructor(
        contourId: string,
        textElement: Snap.Element,
        // private workspaceContourItem: WorkspaceTextContourItem,
        public textInput: HTMLTextAreaElement,
        private svgRoot: SVGSVGElement //  private contourItemsLayer: ContourItemsLayerDirective
    ) {
        this.contourId = contourId;
        this.textElement = textElement;
        this.svgPoint = this.svgRoot.createSVGPoint();
        this.svgTextElement = (this.textElement.node as any) as SVGTextElement;

        this.init();
        this.setSVGText();
    }

    private init() {
        // By consuming keydown events, we prevent the execution of the DeleteAction the user is
        // deleting some text by
        this.textInput.addEventListener('keydown', event => event.stopPropagation(), true);
        this.textInput.setAttribute('maxlength', '' + TEXT_INPUT_MAX_LENGTH);

        const keyUps = fromEvent(this.textInput, 'keyup', { capture: true });
        this._keyUpSubscription = keyUps.subscribe(() => this.handleKeyUps());

        fromEvent<MouseEvent>(this.svgTextElement, 'dblclick', {
            capture: false
        }).subscribe(() => {
            if (this.editMode) {
                // this.selectWord(x);
            }
        });
    }

    private handleKeyUps() {
        if (!this.editMode) {
            return;
        }
        this.setTextFromHTMLTextInput();

        this.getCursor().node.style.display = 'block';
        this.enableCursorBlinker();
        this.drawBackground();

        this.textInputChangedSource.next({
            contourId: this.contourId,
            textContent: this.textInput.value,
            inlTextInput: this
        });
    }

    private wrapText() {
        const extentFirstChar = this.svgTextElement.getExtentOfChar(0);
        this.textElement.children().forEach((x: Snap.Element) => {
            x.attr({
                x: this.textElement.attr('x'),
                dy: extentFirstChar.height
            });
        });

        // update textBBox
        //   this.textBBox = this.snapTextElement.getBBox();
    }

    private setSVGText() {
        this.wrapText();
        this.setTextEndings();
        // eslint-disable-next-line
        let textInputContent = '';
        const textSpans = this.textElement.children();
        const spanLength = textSpans.length;

        for (let i = 0; i < spanLength; i++) {
            const lineBreak = i < spanLength - 1 ? '\n' : '';
            textInputContent += textSpans[i].node.textContent + lineBreak;
        }

        this.displayCharBorders();
    }

    select(mouseX: number, mouseY: number) {
        if (!this.editMode) {
            return;
        }

        this.hideSelectionBackground();
        this.enableCursorBlinker();
        this.textInput.focus();

        if (this.isEmpty) {
            return;
        }

        const cursorCharInfo = this.getCharIndexFromPoint(mouseX, mouseY);
        if (!cursorCharInfo) {
            console.error('cursorCharInfo is undefined');
            return;
        }

        const charIndex = cursorCharInfo.charIndex;
        let selectionEnd = charIndex;

        if (cursorCharInfo.isLineEnd || cursorCharInfo.isEndOfText) {
            selectionEnd = charIndex + 1;
        }

        const charLine = this.getCharLine(charIndex);
        if (charLine > 0) {
            selectionEnd += charLine - 1;
        }

        this.textInput.setSelectionRange(selectionEnd, selectionEnd);
        this.setCursorPosition(
            cursorCharInfo.cursorStartX,
            cursorCharInfo.cursorStartY,
            cursorCharInfo.cursorEndX,
            cursorCharInfo.cursorEndY
        );
    }

    private setCursorPosition(startX: number, startY: number, endX: number, endY: number) {
        const startPt = transformPoint(startX, startY, this.textElement.transform().localMatrix);

        this.cursorPos = {
            startX: startX,
            startY: startY,
            endX: endX,
            endY: endY
        };

        const endPt = transformPoint(endX, endY, this.textElement.transform().localMatrix);

        this.getCursor().attr({
            x1: startPt.x.toFixed(2),
            y1: startPt.y.toFixed(2),
            x2: endPt.x.toFixed(2),
            y2: endPt.y.toFixed(2)
        });
    }

    selectAll() {
        this.setSelection(0, this.lineEndingsSelectionPos[this.lineEndingsSelectionPos.length - 1]);
    }

    /*
    selectWord(mEvent: MouseEvent) {
        const mouseX = mEvent.x + getScrollX() - this.contourItemsLayer.canvasViewportBounds.x;
        const mouseY = mEvent.y + getScrollY() - this.contourItemsLayer.canvasViewportBounds.y;
        const charIndexInfo = this.getCharIndexFromPoint(mouseX, mouseY, false);

        if (charIndexInfo === undefined) {
            return;
        }

        const textString = this.svgTextElement.textContent;

        // borrowed from SVG.Edit
        const first = textString.substr(0, charIndexInfo.charIndex).replace(/[a-z0-9]+$/i, '')
            .length;
        const m = textString.substr(charIndexInfo.charIndex).match(/^[a-z0-9]+/i);
        const last = (m ? m[0].length : 0) + charIndexInfo.charIndex;
    } */

    extendSelection(mouseX: number, mouseY: number) {
        let selStart = this.textInput.selectionStart;

        const charIndexInfo = this.getCharIndexFromPoint(mouseX, mouseY, true, true);
        if (charIndexInfo === undefined) {
            return;
        }

        const newEnd = this.getSelectionPosFromCharIndex(charIndexInfo);

        // set first selection to the current selection's end position
        if (selStart > newEnd || (selStart < newEnd && this.textInput.selectionEnd > newEnd)) {
            selStart = this.textInput.selectionEnd;
        }

        const start = Math.min(selStart, newEnd);
        const end = Math.max(selStart, newEnd);

        if (start === end) {
            return;
        }

        this.currentSelectionStart = start;
        this.setSelection(start, end);
    }

    private setSelection(start: number, end: number, fromTextInput?: boolean) {
        if (start === end) {
            return;
        }

        if (!fromTextInput) {
            this.textInput.setSelectionRange(start, end);
        }

        const selectionStart = this.getCharInfoFromSelectionPosition(start);
        const selectionEnd = this.getCharInfoFromSelectionPosition(end);
        this.updateSelectionPath(selectionStart, selectionEnd);

        if (selectionStart !== selectionEnd) {
            this.disableCursorBlinker();
            this.getCursor().attr({ display: 'none' });
        }
        this.drawSelectionBackground();
    }

    private updateSelectionPath(selectionStart: CursorCharInfo, selectionEnd: CursorCharInfo) {
        this.selectionPaths.length = 0;
        this.selectionPaths = [];

        const len = this.lineEndingsCharIndices.length;
        let lineFirstCharIndex = 0;

        for (let i = 0; i < len; i++) {
            const endingCharIndex = this.lineEndingsCharIndices[i];
            //       selectionStart.charIndex <= endingCharIndex &&
            const isSelectedLine =
                lineFirstCharIndex <= selectionEnd.charIndex &&
                endingCharIndex >= selectionStart.charIndex;
            if (isSelectedLine) {
                // get char index of the current ending's line
                const charSelIndices = this.getSelectionCharIndicesOfEnding(
                    endingCharIndex,
                    selectionStart.charIndex,
                    selectionEnd.charIndex
                );

                const start = this.getCursorPositionInfo(charSelIndices.start, false);
                const isEndOfLine = charSelIndices.end === endingCharIndex;
                const end = this.getCursorPositionInfo(charSelIndices.end, isEndOfLine);
                this.selectionPaths.push({ start: start, end: end });
            } else if (this.selectionPaths.length > 0) {
                // if the current line is not selected but the previous one was so will the next
                // lines not be selected
                break;
            }

            lineFirstCharIndex = endingCharIndex + 1;
        }
    }

    private getSelectionCharIndicesOfEnding(
        endingCharIndex: number,
        selectionStartCharIndex: number,
        selectionEndCharIndex: number
    ): SelectionIndices | undefined {
        const endingIndex = this.lineEndingsCharIndices.indexOf(endingCharIndex);
        if (endingIndex === -1) {
            // TODO throw Exception
            console.error('Cannot find line ending of index ' + endingCharIndex);
            return undefined;
        }
        let charIndex = this.getFirstCharIndexOfEnding(endingIndex);
        let startIndex: number;
        let endIndex: number;
        while (charIndex <= endingCharIndex) {
            // needed when selection is done backwards
            if (charIndex >= selectionStartCharIndex && charIndex <= selectionEndCharIndex) {
                if (startIndex === undefined) {
                    startIndex = charIndex;
                }
                endIndex = charIndex;
            }
            charIndex++;

            if (charIndex > selectionEndCharIndex) {
                break;
            }
        }

        return { start: startIndex, end: endIndex };
    }

    private getFirstCharIndexOfEnding(endingIndex: number): number {
        // charIndex for endings of the first line is always zero
        if (endingIndex === 0) {
            return 0;
        } else {
            return this.lineEndingsCharIndices[endingIndex - 1] + 1;
        }
    }

    private drawSelectionOfLine(start: CursorCharInfo, end: CursorCharInfo): string {
        let selectionPath: string;
        const width = 4;

        if (start.charIndex === end.charIndex) {
            selectionPath = `M${start.cursorStartX},${start.cursorStartY}
                    L${start.cursorStartX + width},${start.cursorStartY}
                    L${start.cursorStartX + width},${start.cursorEndY}
                    L${start.cursorStartX},${start.cursorEndY}
                    L${start.cursorStartX},${start.cursorStartY}`;
        } else {
            selectionPath = `M${start.cursorStartX},${start.cursorStartY}
        L${end.cursorStartX},${end.cursorStartY}
        L${end.cursorEndX},${end.cursorEndY}
        L${start.cursorEndX},${end.cursorEndY}
        L${start.cursorStartX},${start.cursorStartY}`;
        }

        return selectionPath;
    }

    private drawSelectionBackground() {
        if (!this.selectionBackground) {
            this.selectionBackground = (this.textElement.parent() as Snap.Paper).g();
            this.selectionBackground.attr({
                class: 'svg-text-selections-group',
                display: 'inline',
                visibility: 'visible'
            });

            this.selectionBackground.node.setAttribute(POINTER_EVENT_CSS_ATTRIBUTE, 'none');
            this.selectionBackground.insertAfter(this.textElement);
        }

        this.selectionBackground.clear();
        this.selectionBackground.attr({ visibility: 'visible' });
        this.selectionPaths.forEach(x => {
            const selectionPathString = this.drawSelectionOfLine(x.start, x.end);
            const selectionPath = this.selectionBackground.path(selectionPathString);
            selectionPath.addClass('svg-text-selection-bg');

            selectionPath.transform(this.textElement.transform().localMatrix.toTransformString());
        });
    }

    private hideSelectionBackground() {
        if (this.selectionBackground) {
            this.selectionBackground.attr({ display: 'none' });
            this.selectionBackground.clear();
        }
    }

    get editMode(): boolean {
        return this._editMode;
    }

    // TODO: reduce strong coupling workspaceContourItem as editMode can only be called after
    // after the marginPath and transform properties of the WorkspaceContourItems are set
    // (see the #addTextContour() method)
    set editMode(value: boolean) {
        if (value !== this._editMode) {
            this._editMode = value;
            if (value) {
                this.textInput.value = '';
                this.textElement.node.classList.add('is-edit-mode');
                // used to prevent translation when the MouseMoved event is fired on the margin path
                // also it improve the text selection at text bounding border

                /*
                if (this.marginPath) {
                    this.marginPath.addClass('text-item-margin');
                    // leave the cursor to text when users reach a non-text element within the text
                    this.marginPath.node.style.cursor = 'text';
                } */

                // Set content
                this.textInput.value = this.svgTextElement.textContent;
                this.isEmpty = !this.svgTextElement.textContent.length;

                this.textInput.focus();
                this.textBBox = this.getTextBBox();
                this.getCursor().node.style.display = 'block';
                this.drawBackground();
                this.enableCursorBlinker();
                this.displayCharBorders();
            } else {
                this.textInput.blur();
                this.textInput.value = '';
                this.textElement.node.classList.remove('is-edit-mode');
                /* if (this.marginPath) {
                    this.marginPath.removeClass('text-item-margin');
                    this.marginPath.node.style.cursor = 'default';
                } */
                this.getCursor().node.style.display = 'none';
                this.disableCursorBlinker();
                this.hideSelectionBackground();
                this.hideBackground();
            }
        }
    }

    public redraw() {
        if (this._editMode) {
            this.drawBackground();
            this.setCursorPosition(
                this.cursorPos.startX,
                this.cursorPos.startY,
                this.cursorPos.endX,
                this.cursorPos.endY
            );
        }
    }

    private enableCursorBlinker() {
        if (!this.cursorBlinker) {
            this.cursorBlinker = window.setInterval(() => {
                const show = this.getCursor().attr('display') === 'none';
                this.getCursor().attr({ display: show ? 'inline' : 'none' });
            }, CURSOR_BLINK_RATE);
        }
    }

    private disableCursorBlinker() {
        window.clearInterval(this.cursorBlinker);
        this.cursorBlinker = null;
    }

    /**
     * Gets the index of the character under the given screen coordinates
     *
     * If outerRegions is set to true, the index detection will proceed
     *  even though users click outside the text region (eg., text-background or white-space)
     *
     * @param mouseX
     * @param mouseY
     * @param outerRegions
     */
    private getCharIndexFromPoint(
        mouseX: number,
        mouseY: number,
        outerRegions: boolean = true,
        onSelection?: boolean
    ): CursorCharInfo | undefined {
        // get the point of the mouse relative to the text local coordinate
        const pt = transformPoint(
            mouseX,
            mouseY,
            this.textElement.transform().localMatrix.invert()
        );
        const posX = pt.x;
        const posY = pt.y;

        this.svgPoint.x = posX;
        this.svgPoint.y = posY;

        let charIndex = this.svgTextElement.getCharNumAtPosition(this.svgPoint);

        if (charIndex < 0 && outerRegions) {
            charIndex = this.getCharIndexOfOuterPoint(this.svgPoint);
        }

        if (charIndex < 0 && onSelection) {
            return this.getClosestChar(this.svgPoint);
        }

        if (charIndex < 0) {
            return undefined;
        }

        const start = this.svgTextElement.getStartPositionOfChar(charIndex);
        const end = this.svgTextElement.getEndPositionOfChar(charIndex);

        // Determine if cursor should be moved one position to the left
        let cursorCharIndex = charIndex;
        let isLineEnd = false;

        const width = end.x - start.x;
        const switchingPos = start.x + width * 0.3;
        // Move the cursor to the beginning the next char if the click position exceed the
        // switchingPos. Note, aligned with most text-input widgets, the cursor is not moved when
        // the character clicked is the last character of a line
        if (posX > switchingPos) {
            // const lastCharIndex = this.svgTextElement.textContent.length - 1;
            isLineEnd = this.lineEndingsCharIndices.indexOf(charIndex) > -1;
            if (!isLineEnd) {
                cursorCharIndex++;
            }
        }

        return this.getCursorPositionInfo(cursorCharIndex, isLineEnd);
    }

    private getCharIndexOfOuterPoint(svgPoint: SVGPoint): number {
        const cachedSvgPointX = svgPoint.x;

        // used to get the char width
        const charWidth = this.svgTextElement.getExtentOfChar(0).width;
        // check if the user clicks on the left  side of the background rectangle
        svgPoint.x = cachedSvgPointX + this.backgroundPadding;
        let charIndex = this.svgTextElement.getCharNumAtPosition(svgPoint);

        // check if the user clicks on the left side of the background rectangle
        if (charIndex < 0) {
            svgPoint.x = cachedSvgPointX - this.backgroundPadding - charWidth;
            charIndex = this.svgTextElement.getCharNumAtPosition(svgPoint);

            // check if the user clicks on right side of the background rectangle
            if (charIndex < 0) {
                // if the user clicks on a white space (non-text element) of a text line that is
                // rendered because of the previous or the next line being longer than the clicked
                // line, then the user expects the char before the white space to be selected
                charIndex = this.getLineEndCharOfPointY(svgPoint);
            }
        }

        if (charIndex < 0) {
            svgPoint.x = cachedSvgPointX - charWidth;
            charIndex = this.svgTextElement.getCharNumAtPosition(svgPoint);
        }

        return charIndex;
    }

    private getClosestChar(svgPoint: SVGPoint): CursorCharInfo | undefined {
        if (this.pointIsOuterRight(svgPoint)) {
            const charIndex = this.getLineEndCharOfPointY(svgPoint);
            if (charIndex) {
                return this.getCursorPositionInfo(charIndex, true);
            }
        } else if (this.pointIsOuterLeft(svgPoint)) {
            const charIndex = this.getFistLineCharOfPointY(svgPoint);
            if (charIndex) {
                return this.getCursorPositionInfo(charIndex, false);
            }
        }
        return undefined;
    }

    /**
     * Gets charInfo of the line ending character whose glyph's bounding box contains the
     * specified y-point
     *
     * @param svgPoint
     */
    private getLineEndCharOfPointY(svgPoint: SVGPoint): number | undefined {
        return this.lineEndingsCharIndices.find(charIndex => {
            return this.hasPointY(charIndex, svgPoint);
        });
    }

    private getFistLineCharOfPointY(svgPoint: SVGPoint): number | undefined {
        if (this.hasPointY(0, svgPoint)) {
            return 0;
        }
        return this.lineEndingsCharIndices.find(charIndex => {
            const nextFirstCharIndex = charIndex + 1;
            return this.hasPointY(nextFirstCharIndex, svgPoint);
        });
    }

    private hasPointY(charIndex: number, svgPoint: SVGPoint): boolean {
        const extentChar = this.svgTextElement.getExtentOfChar(charIndex);
        const y2 = extentChar.y + extentChar.height;
        return extentChar.y <= svgPoint.y && svgPoint.y <= y2;
    }

    private pointIsOuterRight(svgPoint: SVGPoint): boolean {
        // svgPoint.x is an outer pointer if no ending points is smaller
        // so we look for greater ending pointer
        const pointIsRight = (charIndex: number) => {
            const lineEndCharPos = this.svgTextElement.getEndPositionOfChar(charIndex);
            return lineEndCharPos.x > svgPoint.x;
        };

        return this.lineEndingsCharIndices.find(pointIsRight) === undefined;
    }

    private pointIsOuterLeft(svgPoint: SVGPoint): boolean {
        const pointIsLeft = (charIndex: number) => {
            const lineStartCharPos = this.svgTextElement.getEndPositionOfChar(charIndex);
            return svgPoint.x > lineStartCharPos.x;
        };

        return this.lineEndingsCharIndices.filter(pointIsLeft) === undefined;
    }

    private getCursorPositionInfo(
        charIndex: number,
        isLineEndWithNoControlChar: boolean,
        charLine?: number
    ): CursorCharInfo {
        const textLength = this.svgTextElement.textContent.length;
        let isEndOfText = false;
        if (charIndex >= textLength) {
            isEndOfText = true;
            // clamp cursor position
            charIndex = textLength - 1;
        }

        const extentChar: SVGRect = this.svgTextElement.getExtentOfChar(charIndex);

        let cursorStartX = extentChar.x;
        const cursorStartY = extentChar.y;

        // TODO isEndOfText is not need anymore?
        if (isLineEndWithNoControlChar || isEndOfText) {
            cursorStartX = extentChar.x + extentChar.width;
        }

        const cursorHeight = extentChar.height;

        return {
            charIndex: charIndex,
            cursorStartX: cursorStartX,
            cursorStartY: cursorStartY,
            cursorEndX: cursorStartX,
            cursorEndY: cursorStartY + cursorHeight,
            isEndOfText: isEndOfText, // TODO isEndOfText is not need anymore?
            isLineEnd: isLineEndWithNoControlChar
        };
    }

    private getCursor() {
        if (!this.cursor) {
            const parentGNode = this.textElement.parent() as Snap.Paper;

            this.cursor = parentGNode.select('#cursor-text');
            if (!this.cursor) {
                this.cursor = parentGNode.line(0, 0, 1, 1).attr({
                    id: 'cursor-text',
                    class: 'cursor-text',
                    'stroke-width': 0.5
                });
            }
        }

        return this.cursor;
    }

    private setTextFromHTMLTextInput() {
        if (!this.textInput) {
            console.error('Could not found a textInput');
            return;
        }

        if (!this.textElement) {
            console.error('Could not found a svg text element');
            return;
        }

        this.hideSelectionBackground();
        // DISABLE MULTILINE TEXT
        let selectionStartPreOverride = this.textInput.selectionStart;
        let selectionEndPreOverride = this.textInput.selectionEnd;
        if (DISABLE_NEWLINE_AND_LIMIT_TEXTINPUT) {
            const index = this.textInput.value.indexOf('\n');
            this.textInput.value = this.textInput.value.replace(/(\n)*/g, '');
            if (index >= 0) {
                // if a '\n' was replaced move the cursor
                selectionStartPreOverride--;
                selectionEndPreOverride--;
            }
        }
        this.setText(this.textInput.value);
        this.textInput.selectionStart = selectionStartPreOverride;
        this.textInput.selectionEnd = selectionEndPreOverride;
        this.setSelection(this.textInput.selectionStart, this.textInput.selectionEnd, true);
    }

    /**
     * Updates svg text
     *
     * @param textContent
     */
    setText(textContent: string) {
        let contents = textContent.split('\n');

        // TODO escape control characters \n and \0 as they have a specific meaning for this widget
        // add line breaks
        if (contents.length > 1) {
            const lastIndex = contents.length - 1;
            contents = contents.map((txt, index: number) => {
                if (index !== lastIndex) {
                    txt += '\n';
                } else if (txt === '') {
                    // mark an empty line with a null character
                    txt += '\0';
                }

                return txt;
            });
        }

        this.textElement.attr({
            text: contents
        });

        this.isEmpty = !textContent.length;
        if (!this.isEmpty) {
            this.wrapText();
        }

        this.setTextEndings();

        if (this.isEmpty) {
            const bbox = this.getTextBBox(true);
            this.setCursorPosition(bbox.cx, bbox.y, bbox.cx, bbox.y2);
            this.displayCharBorders();
            return;
        }

        // update cursor position if there is no selection
        if (this.textInput.selectionStart === this.textInput.selectionEnd) {
            const cursorPosInfo = this.getCharInfoFromSelectionPosition(
                this.textInput.selectionEnd
            );

            this.setCursorPosition(
                cursorPosInfo.cursorStartX,
                cursorPosInfo.cursorStartY,
                cursorPosInfo.cursorEndX,
                cursorPosInfo.cursorEndY
            );
        }
        // for debugging purpose
        this.displayCharBorders();
    }

    /**
     * Gets {@link CursorCharInfo} for the given selection position
     *
     * @param selectionPos
     * @param textAction whether the selection position results from textAction
     */
    private getCharInfoFromSelectionPosition(selectionPos: number): CursorCharInfo | undefined {
        if (selectionPos > this.lineEndingsSelectionPos[this.lineEndingsSelectionPos.length - 1]) {
            console.error('Selection position ' + selectionPos + ' is out of range');
            return undefined;
        }

        let charIndex = selectionPos;
        const charLine = this.getSelectionLine(selectionPos);
        let isLineEndWithNoControlChar = false;

        // we must reduce the charIndex, if the end of the first line of a single line text or
        // the last line of a multi-line text is selected because these lines don't contain a
        // control character (line feed or null character) by design
        if (
            charLine === this.lineEndingsSelectionPos.length &&
            this.lineEndingsSelectionPos.indexOf(selectionPos) > -1
        ) {
            isLineEndWithNoControlChar = true;
            charIndex--;
        }

        return this.getCursorPositionInfo(charIndex, isLineEndWithNoControlChar, charLine);
    }

    private getSelectionPosFromCharIndex(charInfo: CursorCharInfo): number {
        let selectionPos = charInfo.charIndex;

        if (charInfo.isLineEnd || charInfo.isEndOfText) {
            selectionPos++;
        }

        return selectionPos;
    }

    /**
     * Gets the line number the character at the given index
     *
     * @param charIndex
     */
    private getCharLine(charIndex: number): number {
        const cIndex = this.lineEndingsCharIndices.findIndex(x => x >= charIndex);
        return cIndex + 1;
    }

    private getSelectionLine(selectionIndex: number): number {
        const cIndex = this.lineEndingsSelectionPos.findIndex(x => x >= selectionIndex);
        return cIndex + 1;
    }

    displayCharBorders() {
        if (!this.debugMode) {
            return;
        }
        const textContent = this.svgTextElement.textContent;
        // this.snapTextElement.attr('text');

        this.charBBoxes = [];
        this.charBBoxes.length = textContent.length;

        this.rect.forEach(r => r.remove());
        this.rect.length = 0;
        this.rect.length = textContent.length + 1;

        this.textBBox = this.getTextBBox();
        // Show textBBox
        this.rect[textContent.length + 1] = (this.textElement.parent() as Paper)
            .rect(this.textBBox.x, this.textBBox.y, this.textBBox.width, this.textBBox.height)
            .attr({
                'fill-opacity': 0,
                stroke: 'yellow'
            });
        this.rect[textContent.length + 1].node.setAttribute(POINTER_EVENT_CSS_ATTRIBUTE, 'none');

        // For debugging only
        for (let i = 0; i < textContent.length; i++) {
            const start = this.svgTextElement.getStartPositionOfChar(i);
            // const end = this.svgTextElement.getEndPositionOfChar(i);

            /* const endPt = transformPoint(
                start.x,
                this.textBBox.y + this.textBBox.height,
                this.snapTextElement.transform().globalMatrix
            ); */

            const extentChar: SVGRect = this.svgTextElement.getExtentOfChar(i);

            this.charBBoxes[i] = {
                x: start.x,
                y: extentChar.y,
                width: extentChar.width,
                height: extentChar.height
            };

            this.rect[i] = (this.textElement.parent() as Paper).rect(
                this.charBBoxes[i].x,
                this.charBBoxes[i].y,
                this.charBBoxes[i].width,
                this.charBBoxes[i].height
            );

            this.rect[i].attr({
                'fill-opacity': 0,
                stroke: 'red'
            });
            this.rect[i].node.setAttribute(POINTER_EVENT_CSS_ATTRIBUTE, 'none');

            this.rect[i].transform(this.textElement.transform().localMatrix.toTransformString());
        }
    }

    private setTextEndings() {
        const tspans = this.svgTextElement.childNodes;

        const len = tspans.length;
        this.previousLineEndingsSelPos = this.lineEndingsSelectionPos;
        this.lineEndingsCharIndices = [];
        this.lineEndingsSelectionPos = [];

        for (let i = 0; i < len; i++) {
            const tspanElem = tspans[i] as SVGTSpanElement;
            // set the first row to -1 because the positions of the <tspan>'s characters range
            // from 0 and to the text length minus one
            const prevPos = this.lineEndingsCharIndices[i - 1] || -1;

            const spanLength = tspanElem.textContent.length;
            const lastCharIndex = prevPos + spanLength;

            this.lineEndingsCharIndices[i] = lastCharIndex;

            // The selection position of the last character of a line (<tspan>) is the sum of
            // characters plus one, where one is the cursor/selection position at the end of the
            // line. However, this is not need if the last or first character of this line is an
            // line feed or null character
            let incrementSelPos = 0;
            const lastChar = tspanElem.textContent.charAt(spanLength - 1);
            const firstChar = tspanElem.textContent.charAt(0);
            if (firstChar === '\0') {
                incrementSelPos = 1;
            } else if (lastChar === '\n') {
                incrementSelPos = 0;
            } else {
                // the text field has only one line with no line feed
                incrementSelPos = 1;
            }
            this.lineEndingsSelectionPos[i] = lastCharIndex + incrementSelPos;
        }

        // previous endings should refer to the current one when text endings are
        // computed for the first time
        if (!this.previousLineEndingsSelPos) {
            this.previousLineEndingsSelPos = this.lineEndingsSelectionPos;
        }
    }

    // TODO cache value
    getTextBBox(untransformed?: boolean): Snap.BBox {
        const textAtt = Array.from(this.textElement.node.childNodes);
        if (this.svgTextElement.textContent.length === 0) {
            // add a temporary char to compute the bbox of an empty text field.
            this.textElement.attr({
                text: ['s']
            });
            this.wrapText();
            const bbox = (this.textElement as any).getBBox(untransformed);
            this.svgTextElement.textContent = '';
            return bbox;
        } else if (textAtt && textAtt.constructor === Array) {
            const emptyTexts = (textAtt as Array<SVGTSpanElement>).filter(
                txt => txt.textContent === '\0'
            );
            const bbox = (this.textElement as any).getBBox(untransformed);
            const extend = this.svgTextElement.getExtentOfChar(0);
            bbox.height += emptyTexts.length * extend.height;

            return bbox;
        } else {
            return (this.textElement as any).getBBox(untransformed);
        }
    }

    private drawBackground() {
        if (!this.textBackground) {
            this.textBackground = (this.textElement.parent() as Snap.Paper).rect(0, 0, 0, 0);
            this.textBackground.insertBefore(this.textElement);
        }

        // the textBackground is set to (0,0) and hence it will be transformed from the origin (0,0)
        // of its local coordinate
        // However, the text bounding box might not start at (0,0), the transformation will not
        // match the visual bound of the text element. To fix it simply add an additional offset to
        // the itemTransformation matrix.

        // use the native #getBBox() to get the offset relative to the element's local coordinate
        // system with Snap.Svg we must call #getBBox(true) to get the element's local coordinate
        // system. However, the snap.svg type definition has not added the boolean argument
        const nativeTexBBox = this.getTextBBox(true);

        this.textBackground.attr({
            x: 0,
            y: 0,
            width: nativeTexBBox.width,
            height: nativeTexBBox.height,
            //    class: 'svg-text-background, svg-text-background-' + this.contourId,
            display: 'inline'
        });

        this.textBackground.node.classList.add(
            'svg-text-background',
            'svg-text-background-' + this.contourId
        );

        // use itemElement as there are lay out in the same group
        const matrix = this.textElement
            .transform()
            .localMatrix.translate(nativeTexBBox.x, nativeTexBBox.y);
        this.textBackground.transform(matrix.toTransformString());

        // Add backgroundPadding
        const newWidth = parseFloat(this.textBackground.attr('width')) + this.backgroundPadding * 2;
        const newHeight =
            parseFloat(this.textBackground.attr('height')) + this.backgroundPadding * 2;
        this.textBackground.attr({
            x: -this.backgroundPadding,
            y: -this.backgroundPadding,
            width: newWidth.toFixed(2),
            height: newHeight.toFixed(2)
        });
    }

    private hideBackground() {
        if (this.textBackground) {
            this.textBackground.attr({
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                display: 'none'
            });
        }
    }

    destroy() {
        this.textInputChangedSource.complete();
        this.svgTextElement = null;
        this.textElement = null;
        this._keyUpSubscription.unsubscribe();
    }
}

interface TextCharBBox {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface CursorCharInfo {
    charIndex: number;
    cursorStartX: number;
    cursorStartY: number;
    cursorEndX: number;
    cursorEndY: number;
    isLineEnd: boolean;
    isEndOfText: boolean;
}

interface SelectionPathInfo {
    start: CursorCharInfo;
    end: CursorCharInfo;
}

interface SelectionIndices {
    start: number;
    end: number;
}

export interface InlTextChangedEvent {
    contourId: string;
    textContent: string;
    // textElement: Snap.Element;
    inlTextInput: InlSVGTextInputElement;
}
