import { Component, OnInit } from '@angular/core';
import { RibbonSelectionService } from '../ribbon/ribbon-selection.service';
import { AlignmentRibbonDialogComponent } from '../ribbon/ribbon-panels/ribbon-dialog/alignment-dialog/alignment-ribbon-dialog.component';
import { GroupAction } from '../actions/group.action';
import { FoamEditorService } from '../foam-editor.service';
import { SelectionChangedData } from '../canvas/canvas.service';
import { UndoState } from '../undo/undo-manager.service';
import { DeleteAction } from '../actions/delete.action';

@Component({
    selector: 'app-edit-actions-toolbar',
    templateUrl: './edit-actions-toolbar.component.html',
    styleUrls: ['./edit-actions-toolbar.component.scss']
})
export class EditActionsToolbarComponent implements OnInit {
    dropZoneId = 'thrash-basket-dropzone';
    isAlignmentDialogOpened: boolean = false;
    isSelectedGrouped: boolean = false;
    canUndo: boolean = false;
    canRedo: boolean = false;

    private readonly deleteAction: DeleteAction;
    private readonly groupAction: GroupAction;
    undoActionName: string = '';
    redoActionName: string = '';

    constructor(
        private readonly foamEditorService: FoamEditorService,
        private readonly ribbonSelectionService: RibbonSelectionService
    ) {
        this.groupAction = new GroupAction(foamEditorService);
        this.deleteAction = new DeleteAction(foamEditorService);
    }

    ngOnInit(): void {
        this.ribbonSelectionService.onDialogChange.subscribe((x: void) => {
            this.isAlignmentDialogOpened = this._isAlignmentDialogOpen();
        });

        const canvasService = this.foamEditorService.getCanvasService();
        canvasService.getOnSelectionChanged().subscribe((data: SelectionChangedData) => {
            this.isSelectedGrouped = this.groupAction.canUngroup();
        });

        this.foamEditorService
            .getCanvasService()
            .getUndoManagerService()
            .undoStateChanged.subscribe((state: UndoState) => {
                this.canUndo = !!state.nextUndoAction;
                this.canRedo = !!state.nextRedoAction;
                this.undoActionName = this.canUndo ? ': ' + state.nextUndoAction.name : '';
                this.redoActionName = this.canRedo ? ': ' + state.nextRedoAction.name : '';
            });
    }

    toggleAlignmentDialog() {
        if (!this.isAlignmentDialogOpened) {
            this.ribbonSelectionService.openRibbonDialog(AlignmentRibbonDialogComponent);
        } else {
            this.ribbonSelectionService.closeRibbonDialog();
        }
    }

    private _isAlignmentDialogOpen(): boolean {
        return (
            this.ribbonSelectionService.openedRibbonDialog &&
            this.ribbonSelectionService.openedRibbonDialog instanceof AlignmentRibbonDialogComponent
        );
    }

    deleteItem() {
        this.deleteAction.execute();
    }

    groupItems() {
        this.groupAction.execute({
            requestGrouping: !this.isSelectedGrouped
        });
    }

    redo() {
        this.foamEditorService
            .getCanvasService()
            .getUndoManagerService()
            .redo();
    }

    undo() {
        this.foamEditorService
            .getCanvasService()
            .getUndoManagerService()
            .undo();
    }
}
