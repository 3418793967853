import { Handle } from './handle';
import { ResizeHandleData } from '../../canvas/contour/contour-items-interfaces';
import {
    CLASS_NAME_RESIZE_HANDLE,
    ResizeHandleCursorType,
    ResizeHandleSide
} from '../../canvas/handles/resize-handles-drawer';
import { Point2D } from '../geom/point2D';
import { transformPoint } from '../geom/matrix';
import { Rectangle2D } from '../geom/rectangle2D';
import { CircleContour } from '../../canvas/contour/circle-contour';
import { ResizeableContour } from '../../canvas/contour/resizeable-contour';
import { ResizeEditAction } from '../../undo/resize-edit-action';

declare var Snap: any;

export class ResizeHandle implements Handle {
    readonly owner: ResizeableContour;
    readonly contourId: string;
    visible: boolean = true;
    private targetSelection: string;
    private containerId: string;
    side: ResizeHandleSide;
    cursorType: ResizeHandleCursorType;
    protected startLocalPathBounds: Rectangle2D;
    private startAnchorPt: Point2D;
    private startLocalContourPathMatrix: Snap.Matrix;
    protected oldBounds: Rectangle2D;

    constructor(owner: ResizeableContour, side: ResizeHandleSide) {
        this.owner = owner;
        this.targetSelection = '.' + CLASS_NAME_RESIZE_HANDLE + '-' + side + '-' + owner.contourId;
        this.contourId = owner.contourId;
        this.side = side;
    }

    contains(target: Element) {
        return target && target.matches(this.targetSelection);
    }

    getDrawData(): ResizeHandleData {
        return new ResizeHandleData({
            // containerId: this.containerId,
            contourId: this.contourId,
            visible: this.visible,
            side: this.side,
            cursorType: this.cursorType
        });
    }

    trackEnd(startAnchor: Point2D, currentAnchorPt: Point2D) {
        this.owner
            .getCanvas()
            .getUndoManagerService()
            .addEditAction(
                new ResizeEditAction(this.owner, this.oldBounds, this.startLocalPathBounds)
            );
    }

    trackStart(anchorPt: Point2D) {
        this.startLocalPathBounds = this.owner.localContourPathBBox.clone();
        this.oldBounds = this.startLocalPathBounds;
        this.startLocalContourPathMatrix = this.owner.localContourPathMatrix.clone();
        this.startAnchorPt = anchorPt;
    }

    trackStep(startAnchor: Point2D, currentAnchorPt: Point2D) {
        // const rotationAngle = this.owner.localContourPathMatrix.split().rotate;
        //  const rMatrix = Snap.matrix().rotate(-rotationAngle, 0, 0);

        const uAnchorPt = transformPoint(
            currentAnchorPt.x,
            currentAnchorPt.y,
            this.startLocalContourPathMatrix.invert()
        );

        this.resizeStep(Point2D.fromObject(uAnchorPt));
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {}
}

export class NorthEastHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.RIGHT_TOP);
        this.cursorType = ResizeHandleCursorType.NORTH_EAST_SOUTH_WEST;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        // fixed points: x and y2

        const newY = Math.min(uAnchorPt.y, this.startLocalPathBounds.y2 - 1);
        const newX2 = Math.max(uAnchorPt.x, this.startLocalPathBounds.x + 1);
        const newWidth = newX2 - this.startLocalPathBounds.x;
        const newHeight = this.startLocalPathBounds.y2 - newY;
        const newBounds = new Rectangle2D(this.startLocalPathBounds.x, newY, newWidth, newHeight);

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class EastHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.RIGHT);
        this.cursorType = ResizeHandleCursorType.EAST_WEST;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newX2 = Math.max(uAnchorPt.x, this.startLocalPathBounds.x + 1);
        const newWidth = newX2 - this.startLocalPathBounds.x;
        const newBounds = new Rectangle2D(
            this.startLocalPathBounds.x,
            this.startLocalPathBounds.y,
            newWidth,
            this.startLocalPathBounds.height
        );

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class NorthHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.TOP);
        this.cursorType = ResizeHandleCursorType.NORTH_SOUTH;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newY = Math.min(uAnchorPt.y, this.startLocalPathBounds.y2 - 1);
        const newHeight = this.startLocalPathBounds.y2 - newY;
        const newBounds = new Rectangle2D(
            this.startLocalPathBounds.x,
            newY,
            this.startLocalPathBounds.width,
            newHeight
        );

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class NorthWestHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.LEFT_TOP);
        this.cursorType = ResizeHandleCursorType.NORTH_WEST_SOUTH_EAST;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newX = Math.min(uAnchorPt.x, this.startLocalPathBounds.x2 - 1);
        const newY = Math.min(uAnchorPt.y, this.startLocalPathBounds.y2 - 1);
        const newWidth = this.startLocalPathBounds.x2 - newX;
        const newHeight = this.startLocalPathBounds.y2 - newY;
        const newBounds = new Rectangle2D(newX, newY, newWidth, newHeight);

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class SouthEastHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.RIGHT_BOTTOM);
        this.cursorType = ResizeHandleCursorType.NORTH_WEST_SOUTH_EAST;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newX = Math.max(uAnchorPt.x, this.startLocalPathBounds.x + 1);
        const newY = Math.max(uAnchorPt.y, this.startLocalPathBounds.y + 1);
        const newWidth = newX - this.startLocalPathBounds.x;
        const newHeight = newY - this.startLocalPathBounds.y;
        const newBounds = new Rectangle2D(
            this.startLocalPathBounds.x,
            this.startLocalPathBounds.y,
            newWidth,
            newHeight
        );

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class SouthHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.BOTTOM);
        this.cursorType = ResizeHandleCursorType.NORTH_SOUTH;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newY2 = Math.max(uAnchorPt.y, this.startLocalPathBounds.y + 1);
        const newHeight = newY2 - this.startLocalPathBounds.y;
        const newBounds = new Rectangle2D(
            this.startLocalPathBounds.x,
            this.startLocalPathBounds.y,
            this.startLocalPathBounds.width,
            newHeight
        );

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class SouthWestHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.LEFT_BOTTOM);
        this.cursorType = ResizeHandleCursorType.NORTH_EAST_SOUTH_WEST;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newX = Math.min(uAnchorPt.x, this.startLocalPathBounds.x2 - 1);
        const newY2 = Math.max(uAnchorPt.y, this.startLocalPathBounds.y + 1);
        const newWidth = this.startLocalPathBounds.x2 - newX;
        const newHeight = newY2 - this.startLocalPathBounds.y;
        const newBounds = new Rectangle2D(newX, this.startLocalPathBounds.y, newWidth, newHeight);

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class WestHandle extends ResizeHandle {
    constructor(owner: ResizeableContour) {
        super(owner, ResizeHandleSide.LEFT);
        this.cursorType = ResizeHandleCursorType.EAST_WEST;
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newX = Math.min(uAnchorPt.x, this.startLocalPathBounds.x2 - 1);
        const newWidth = this.startLocalPathBounds.x2 - newX;
        const newBounds = new Rectangle2D(
            newX,
            this.startLocalPathBounds.y,
            newWidth,
            this.startLocalPathBounds.height
        );

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class CircleWestHandle extends WestHandle {
    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newX = Math.min(uAnchorPt.x, this.startLocalPathBounds.x2 - 1);
        const newWidth = this.startLocalPathBounds.x2 - newX;

        const deltaRadius = (newWidth - this.oldBounds.width) / 2;
        const newBounds = new Rectangle2D(-deltaRadius * 2, -deltaRadius, newWidth, newWidth);

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class CircleEastHandle extends EastHandle {
    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newX2 = Math.max(uAnchorPt.x, this.startLocalPathBounds.x + 1);
        const newWidth = newX2 - this.startLocalPathBounds.x;

        const deltaRadius = (newWidth - this.oldBounds.width) / 2;

        const newBounds = new Rectangle2D(0, -deltaRadius, newWidth, newWidth);

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class CircleNorthHandle extends NorthHandle {
    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newY = Math.min(uAnchorPt.y, this.startLocalPathBounds.y2 - 1);
        const newHeight = this.startLocalPathBounds.y2 - newY;

        const deltaRadius = newHeight / 2 - (this.owner as CircleContour).radius;

        const newBounds = new Rectangle2D(-deltaRadius, -deltaRadius * 2, newHeight, newHeight);

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}

export class CircleSouthHandle extends SouthHandle {
    constructor(owner: CircleContour) {
        super(owner);
    }

    resizeStep(uAnchorPt: Point2D, keepAspect?: boolean): void {
        const newY2 = Math.max(uAnchorPt.y, this.startLocalPathBounds.y + 1);
        const newHeight = newY2 - this.startLocalPathBounds.y;

        const deltaRadius = newHeight / 2 - (this.owner as CircleContour).radius;

        const newBounds = new Rectangle2D(
            this.startLocalPathBounds.x - deltaRadius,
            this.startLocalPathBounds.y,
            newHeight,
            newHeight
        );

        if (this.owner.isValidResizeStep(newBounds, this.oldBounds)) {
            this.owner.resizeDimension(newBounds, this.oldBounds);
            this.oldBounds = newBounds;
        }
    }
}
