import adaptiveBezier from 'adaptive-bezier-curve';
import { CubicBezier2D } from 'kld-contours';
import { Point2D } from 'kld-intersections';
import { transformPoint } from '../../shared/geom/matrix';
import { Rectangle2D } from '../../shared/geom/rectangle2D';

import { PolygonSegment } from '../collision/collision-util';

declare var Snap: any;

export function createTextContourPath(textBBox: Rectangle2D): string {
    const x2 = textBBox.x + textBBox.width;
    const y2 = textBBox.y + textBBox.height;
    return `M${textBBox.x},${textBBox.y} \
    L${x2}, ${textBBox.y} L${x2},${y2} L${textBBox.x},${y2} L${textBBox.x},${textBBox.y}`;
}

export function createTextContourMargin(textBBox: Rectangle2D, textMarginSize: number): string {
    if (textMarginSize === undefined || textMarginSize === null) {
        throw ReferenceError('marginSize is undefined');
    }

    const xTopLeft = textBBox.x + textBBox.width;
    return `M${textBBox.x - textMarginSize},${textBBox.y - textMarginSize} \
        L${xTopLeft + textMarginSize}, ${textBBox.y - textMarginSize} \
        L${textBBox.x2 + textMarginSize},${textBBox.y2 + textMarginSize} \
        L${textBBox.x - textMarginSize},${textBBox.y2 + textMarginSize} \
        L${textBBox.x - textMarginSize},${textBBox.y - textMarginSize}`;
}

export function getCircularShapeSegments(
    radius: number,
    withStartAndEnd: boolean,
    transX: number = 0,
    transY: number = 0,
    sectorStart?: number,
    sectorEnd?: number,
    invertY?: boolean
): Array<[string, number, number]> {
    const steps = radius > 115 ? 2 : 10;
    const start = sectorStart || 0;
    const end = sectorEnd || 360;

    const lines: [string, number, number][] = [];
    // i < end as i=0 and i=360 have the same x/y-point
    let i = start;
    while (i < end) {
        const theta = (i * Math.PI) / 180;
        const x = transX + radius * Math.cos(theta);
        let y = transY + radius * Math.sin(theta);

        y = invertY ? y * -1 : y;

        if (i > start) {
            lines.push(['L', x, y]);
        } else if (withStartAndEnd) {
            lines.push(['M', x, y]);
        }

        i += steps;
    }

    if (withStartAndEnd) {
        const yEnd = invertY ? transY * -1 : transY;
        lines.push(['L', radius + transX, yEnd]);
    }

    return lines;
}

export function pathArrayToString(
    pathSegments: Array<[string, number, number]>,
    invert?: boolean
): string | null {
    let pathString = '';
    const len = pathSegments.length;

    if (!pathSegments || len === 0) {
        return null;
    }

    if (!invert) {
        for (let i = 0; i < len; i++) {
            const seg = pathSegments[i];
            pathString += `${seg[0]}${seg[1]},${seg[2]}`;
        }
    } else {
        // switch M with the last line
        const firstCommand = pathSegments[0][0];
        if (firstCommand === 'M') {
            pathSegments[len - 1][0] = 'M';
            pathSegments[0][0] = 'L';
        }

        for (let i = len - 1; i >= 0; i--) {
            const seg = pathSegments[i];
            pathString += `${seg[0]}${seg[1]},${seg[2]}`;
        }
    }

    return pathString === '' ? null : pathString;
}

export function createCirclePath(radius: number) {
    const halfRadius = radius / 2;
    const diameter = radius * 2;
    const scale = 1;

    // 1. quadrant
    const curveQ1 = adaptiveBezier(
        [diameter, radius],
        [diameter, halfRadius],
        [radius + halfRadius, 0],
        [radius, 0],
        scale
    );
    const curveQ1Lines = bezierToLineString2(curveQ1);

    // 2. quadrant
    const curveQ2 = adaptiveBezier(
        [radius, 0],
        [halfRadius, 0],
        [0, halfRadius],
        [0, radius],
        scale
    );
    const curveQ2Lines = bezierToLineString2(curveQ2);

    // 3. quadrant
    const curveQ3 = adaptiveBezier(
        [0, radius],
        [0, radius + halfRadius],
        [halfRadius, diameter],
        [radius, diameter],
        scale
    );
    const curveQ3Lines = bezierToLineString2(curveQ3);

    // 4. quadrant
    const curveQ4 = adaptiveBezier(
        [radius, diameter],
        [radius + halfRadius, diameter],
        [diameter, radius + halfRadius],
        [diameter, radius],
        scale
    );
    const curveQ4Lines = bezierToLineString2(curveQ4);

    return (
        `M${diameter},${radius}${curveQ1Lines}L${radius},0${curveQ2Lines}` +
        `L0,${radius}${curveQ3Lines}L${radius},${diameter}${curveQ4Lines}` +
        `L${diameter},${radius}`
    );
}

export function createRectanglePath(
    x,
    y,
    width: number,
    height: number,
    rx: number,
    ry: number
): string {
    const x2 = x + width;
    const y2 = y + height;

    let path: string;
    if (rx === 0 && ry === 0) {
        path = `M${x},${y}L${x2},${y}L${x2},${y2}L${x},${y2}L${x},${y}`;
    } else {
        const xRight = x2 - rx;
        const ctrl1XRight = x2 - rx / 2;
        const ctrlYRightTop = y + ry / 2;

        let curveRightTopLines: string;
        if (rx === ry) {
            curveRightTopLines = pathArrayToString(
                getCircularShapeSegments(rx, false, xRight, -ry, 0, 90, true),
                true
            );
        } else {
            const curveRightTop = new CubicBezier2D(
                new Point2D(xRight, y),
                new Point2D(ctrl1XRight, y),
                new Point2D(x2, ctrlYRightTop),
                new Point2D(x2, ry)
            );
            curveRightTopLines = bezierToLineString(curveRightTop);
        }

        const startYBottom = y2 - ry;
        const ctrlYBottom = y2 - ry / 2;

        let curveRightBottomLines;
        if (rx === ry) {
            curveRightBottomLines = pathArrayToString(
                getCircularShapeSegments(rx, false, xRight, -startYBottom, 270, 360, true),
                true
            );
        } else {
            const curveRightBottom = new CubicBezier2D(
                new Point2D(x2, startYBottom),
                new Point2D(x2, ctrlYBottom),
                new Point2D(ctrl1XRight, y2),
                new Point2D(xRight, y2)
            );
            curveRightBottomLines = bezierToLineString(curveRightBottom);
        }

        const startXLeft = x + rx;
        const ctrlXLeft = x + rx / 2;

        let curveLeftBottomLines;
        if (rx === ry) {
            curveLeftBottomLines = pathArrayToString(
                getCircularShapeSegments(rx, false, startXLeft, -startYBottom, 180, 270, true),
                true
            );
        } else {
            const curveLeftBottom = new CubicBezier2D(
                new Point2D(startXLeft, y2),
                new Point2D(ctrlXLeft, y2),
                new Point2D(x, ctrlYBottom),
                new Point2D(x, startYBottom)
            );
            curveLeftBottomLines = bezierToLineString(curveLeftBottom);
        }

        let curveLeftTopLines;
        if (rx === ry) {
            curveLeftTopLines = pathArrayToString(
                getCircularShapeSegments(rx, false, startXLeft, -ry, 90, 180, true),
                true
            );
        } else {
            const curveLeftTop = new CubicBezier2D(
                new Point2D(x, ry),
                new Point2D(x, ctrlYRightTop),
                new Point2D(ctrlXLeft, y),
                new Point2D(startXLeft, y)
            );
            curveLeftTopLines = bezierToLineString(curveLeftTop);
        }

        // make sure that that overlapping segment is not added to the result
        // overlapping occurs when the rectangle width equals the corner radius rx*2
        const lineTop = xRight === startXLeft ? '' : `L${xRight},${y}`;

        path =
            `M${startXLeft},${y}${lineTop}${curveRightTopLines}` +
            `L${x2},${y2 - ry}${curveRightBottomLines}` +
            `L${startXLeft},${y2}${curveLeftBottomLines}L${x},${ry}` +
            `${curveLeftTopLines}L${startXLeft},${y}`;
    }

    return path;
}

export function bezierToLineString2(
    bezier: number[][],
    withStartAndEnd?: boolean
): string | undefined {
    const bpLen = withStartAndEnd ? bezier.length : bezier.length - 1;
    const bpStart = withStartAndEnd ? 0 : 1;
    let linesPath: string = '';
    for (let pi = bpStart; pi < bpLen; pi++) {
        linesPath += `L${bezier[pi][0]},${bezier[pi][1]}`;
    }

    return linesPath === '' ? undefined : linesPath;
}

// TODO move it to utils
export function bezierToLineString(
    bezier: CubicBezier2D,
    withStartAndEnd?: boolean
): string | undefined {
    const bPoint: Point2D[] = bezier.toPolygon2D().points;
    const bpLen = withStartAndEnd ? bPoint.length : bPoint.length - 1;
    const bpStart = withStartAndEnd ? 0 : 1;
    let linesPath: string = '';
    for (let pi = bpStart; pi < bpLen; pi++) {
        linesPath += `L${bPoint[pi].x},${bPoint[pi].y}`;
    }

    return linesPath === '' ? undefined : linesPath;
}

export function transformBoundingBox(
    bbox: Snap.BBox | Rectangle2D,
    matrix: Snap.Matrix | { a: number; b: number; c: number; d: number; e: number; f: number }
): { x: number; y: number; x2: number; y2: number; cx: number; cy: number } {
    const topLeft = transformPoint(bbox.x, bbox.y, matrix);
    const topRight = transformPoint(bbox.x2, bbox.y, matrix);
    const bottomRight = transformPoint(bbox.x2, bbox.y2, matrix);
    const bottomRLeft = transformPoint(bbox.x, bbox.y2, matrix);
    const cxy = transformPoint(bbox.cx, bbox.cy, matrix);

    let x = Infinity;
    let x2 = -Infinity;
    let y = Infinity;
    let y2 = -Infinity;

    [topLeft, topRight, bottomRight, bottomRLeft].forEach(p => {
        x = Math.min(x, p.x);
        x2 = Math.max(x2, p.x);
        y = Math.min(y, p.y);
        y2 = Math.max(y2, p.y);
    });

    return {
        x: x,
        y: y,
        x2: x2,
        y2: y2,
        cx: cxy.x,
        cy: cxy.y
    };
}

export function calculateMargin(itemDepth: number): number | undefined {
    if (itemDepth < 5) {
        console.error('The contour depth is lower then the 5mm minimum depth.');
        return undefined;
    }

    if (itemDepth >= 5 && itemDepth <= 25) {
        return 8 + Math.ceil((itemDepth - 7) / 3);
    } else if (itemDepth >= 26 && itemDepth <= 30) {
        return 15;
    } else if (itemDepth >= 31 && itemDepth <= 54) {
        return 16 + Math.ceil((itemDepth - 38) / 8);
    } else if (itemDepth >= 55 && itemDepth <= 65) {
        return 19;
    } else {
        return 20;
    }
}

export function point2DToPathString(points: { x: number; y: number }[]): string | null {
    const len = points.length;
    let path = '';
    for (let i = 0; i < len; i++) {
        if (i === 0) {
            path += 'M' + points[i].x + ',' + points[i].y;
        } else {
            path += 'L' + points[i].x + ',' + points[i].y;
        }
    }

    return path;
}

export function point2DToPathData(points: { x: number; y: number }[]): SVGPathData[] {
    return points.map((point, index) => {
        const _values: [number, number] = [point.x, point.y];
        if (index === 0) {
            return { type: 'M', values: _values };
        } else {
            return { type: 'L', values: _values };
        }
    });
}

export interface SVGPathData {
    type: string;
    values: [number, number];
}

export function text2Polygon(textElement: Snap.Element): Array<PolygonSegment> {
    const linePoints: PolygonSegment[] = [];

    const textBBox = (textElement as any).getBBox(true);

    const endTopX = textBBox.x + textBBox.width;

    linePoints.push({ startX: textBBox.x, startY: textBBox.y, endX: endTopX, endY: textBBox.y });

    linePoints.push({ startX: endTopX, startY: textBBox.y, endX: textBBox.x2, endY: textBBox.y2 });

    linePoints.push({
        startX: textBBox.x2,
        startY: textBBox.y2,
        endX: textBBox.x,
        endY: textBBox.y2
    });

    linePoints.push({
        startX: textBBox.x,
        startY: textBBox.y2,
        endX: textBBox.x,
        endY: textBBox.y
    });

    // const debugElem = getDebugCanvas();
    // debugElem.clear();

    const ptsLen = linePoints.length;
    for (let n = 0; n < ptsLen; n++) {
        transformLine(linePoints[n], textElement.transform().globalMatrix);
        /* --- DEBUG ---- */
        //   const _line = linePoints[n];
        //    debugElem.line(_line.startX, _line.startY, _line.endX, _line.endY).attr({ stroke: 'red' });
    }

    return linePoints;
}

export function pathString2Polygon(
    svgPathDefinition: string,
    transformMatrix: Snap.Matrix
): Array<PolygonSegment> {
    const linePoints: PolygonSegment[] = [];
    const pathData = Snap.parsePathString(Snap.path.toAbsolute(svgPathDefinition));

    const pLength = pathData.length;
    let latestMoveIndex = 0;
    let currentPosX = pathData[0][1];
    let currentPosY = pathData[0][2];
    for (let i = 1; i < pLength; i++) {
        const segType: string = pathData[i][0];

        let startPosX: number;
        let startPosY: number;
        let endPosX: number;
        let endPosY: number;

        if (segType === 'M') {
            currentPosX = pathData[i][1];
            currentPosY = pathData[i][2];
            // store latest move command used as initial point for the close command
            latestMoveIndex = linePoints.length;
        } else if (segType === 'L') {
            startPosX = currentPosX;
            startPosY = currentPosY;
            endPosX = pathData[i][1];
            endPosY = pathData[i][2];

            linePoints.push({ startX: startPosX, startY: startPosY, endX: endPosX, endY: endPosY });
            currentPosX = endPosX;
            currentPosY = endPosY;
        } else if (segType === 'H' || segType === 'V') {
            // TODO duplicate code
            endPosX = segType === 'H' ? pathData[i][1] : currentPosX;
            endPosY = segType === 'V' ? pathData[i][1] : currentPosY;

            linePoints.push({
                startX: currentPosX,
                startY: currentPosY,
                endX: endPosX,
                endY: endPosY
            });

            currentPosX = endPosX;
            currentPosY = endPosY;
        } else if (segType === 'C') {
            let bezierStartPtX;
            let bezierStartPtY;

            if (linePoints.length > 0) {
                const prevEndPt = linePoints[linePoints.length - 1];
                bezierStartPtX = prevEndPt.endX;
                bezierStartPtY = prevEndPt.endY;
            } else {
                // linePoints.length  == 0 then
                const prevSeg = pathData[i - 1];
                bezierStartPtX = prevSeg[1];
                bezierStartPtY = prevSeg[2];
            }

            const bezierC = new CubicBezier2D(
                new Point2D(bezierStartPtX, bezierStartPtY),
                new Point2D(pathData[i][1], pathData[i][2]),
                new Point2D(pathData[i][3], pathData[i][4]),
                new Point2D(pathData[i][5], pathData[i][6])
            );

            const bPoint: Point2D[] = bezierC.toPolygon2D().points;
            const bpLen = bPoint.length;
            for (let pi = 1; pi < bpLen; pi++) {
                startPosX = bPoint[pi - 1].x;
                startPosY = bPoint[pi - 1].y;
                endPosX = bPoint[pi].x;
                endPosY = bPoint[pi].y;
                linePoints.push({
                    startX: startPosX,
                    startY: startPosY,
                    endX: endPosX,
                    endY: endPosY
                });

                currentPosX = endPosX;
                currentPosY = endPosY;
            }
        } else if (segType === 'Z') {
            startPosX = currentPosX;
            startPosY = currentPosY;
            endPosX = linePoints[latestMoveIndex].startX;
            endPosY = linePoints[latestMoveIndex].startY;
            // check if the path is already closed and Z is redundant
            if (!(startPosX === endPosX && startPosY === endPosY)) {
                linePoints.push({
                    startX: startPosX,
                    startY: startPosY,
                    endX: endPosX,
                    endY: endPosY
                });
            }
            currentPosX = endPosX;
            currentPosY = endPosY;
        } else {
            throw Error('Segment type ' + segType + ' is not allowed');
        }
    }

    // ** DEBUG /
    // const debugElem = getDebugCanvas();
    // debugElem.clear();

    const ptsLen = linePoints.length;
    for (let n = 0; n < ptsLen; n++) {
        transformLine(linePoints[n], transformMatrix);

        /* --- DEBUG ---- */
        // const _line = linePoints[n];
        // debugElem.line(_line.startX, _line.startY, _line.endX, _line.endY).attr({ stroke: 'red' });
    }
    return linePoints;
}

function transformLine(line: PolygonSegment, transformMatrix: Snap.Matrix) {
    /* return {
        startX: transformMatrix.x(line.startX, line.startY);
    } */
    const startPt = transformPoint(line.startX, line.startY, transformMatrix);
    const endPt = transformPoint(line.endX, line.endY, transformMatrix);
    line.startX = startPt.x;
    line.startY = startPt.y;
    line.endX = endPt.x;
    line.endY = endPt.y;
}
