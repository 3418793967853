import {
    DefaultSVGDndHandler,
    DndHandler,
    DndHostComponent
} from '../../../../../droppable/default-svg-dnd-handler';
import { DroppableEvent } from '../../../../../droppable/droppable-handler.service';
import { DroppableMirrorComponent } from '../../../../../droppable/droppable-mirror/droppable-mirror.component';
import { ImageContourModel } from '../../../../../models/image-contour.model';
import {
    PreviewContourInfo,
    PreviewContourService
} from '../../grip-text-contour-panel/preview-contour.service';

export class CreationImageContourDndHandler implements DndHandler {
    private mirrorComponent: DroppableMirrorComponent;
    private defaultDnDHandler: DefaultSVGDndHandler;
    private draggingImageContourItem: ImageContourModel;
    private currentPreviewContour: PreviewContourInfo;

    constructor(
        private hostComponent: DndHostComponent<ImageContourModel>,
        private previewContourService: PreviewContourService
    ) {
        this.defaultDnDHandler = new DefaultSVGDndHandler();
    }

    setMirrorComponent(mirror: DroppableMirrorComponent) {
        this.mirrorComponent = mirror;
        this.defaultDnDHandler.setMirrorComponent(mirror);
    }

    onDragStart(evt: DroppableEvent) {
        this.defaultDnDHandler.onDragStart(evt);

        const toolItemId = evt.draggableTarget.htmlElement.getAttribute('data-item-id');

        this.draggingImageContourItem = this.hostComponent
            .getData()
            .find((x: ImageContourModel) => x.id === toolItemId);

        if (!this.draggingImageContourItem) {
            console.error('Unable to load item ' + toolItemId);
            return;
        }

        this.currentPreviewContour = this.previewContourService.getPreviewImageContour({
            svgPathDefinition: this.draggingImageContourItem.svgPathDefinition
        });

        if (this.currentPreviewContour) {
            this.mirrorComponent.setPreviewContour(this.currentPreviewContour);
        }
    }

    onDragMove(evt: DroppableEvent) {
        this.defaultDnDHandler.onDragMove(evt);
    }

    onDragEnter(evt: DroppableEvent) {
        if (!this.draggingImageContourItem) {
            console.error('current contourItem not found');
            return;
        }

        this.defaultDnDHandler.onDragEnter(evt);

        this.mirrorComponent.width = this.currentPreviewContour.svgRootWidth;
        this.mirrorComponent.height = this.currentPreviewContour.svgRootHeight;
    }

    onDragLeave(evt: DroppableEvent) {
        this.defaultDnDHandler.onDragLeave(evt);
    }

    onDragCancel(evt: DroppableEvent) {
        this.defaultDnDHandler.onDragCancel(evt);
        this.draggingImageContourItem = null;
    }

    onDragDrop(event: DroppableEvent) {
        this.defaultDnDHandler.onDragDrop(event);

        const x = event.x - this.mirrorComponent.width / 2;
        const y = event.y - this.mirrorComponent.height / 2;

        if (!this.draggingImageContourItem) {
            console.error('Failed to add contour');
            return;
        }

        this.hostComponent.onItemDropped({
            type: 'IMAGE_CONTOUR',
            x: x,
            y: y,
            imageContour: this.draggingImageContourItem
        });
        this.draggingImageContourItem = null;
    }

    onDragReEnterPreviewArea(evt: DroppableEvent) {
        this.defaultDnDHandler.onDragReEnterPreviewArea(evt);
    }
}
