import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    constructor(private route: ActivatedRoute, private router: Router) {}

    getRouteOnNavigationEnd(): Observable<any> {
        return this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.route),
            map(route => {
                while (route['firstChild']) {
                    route = route['firstChild'];
                }
                return route;
            }),
            filter(route => route['outlet'] === 'primary')
        );
    }
}
