import { Injectable } from '@angular/core';
import { AdjacencyList, CollisionGraph } from './collision-graph';
import { CollisionEventNotifier, StaticCollisionChangedEvent } from './collision-event-notifier';

import * as _ from 'lodash';
@Injectable()
export class StaticCollisionGraph {
    private staticGraph: AdjacencyList = {};

    constructor(
        private collisionGraph: CollisionGraph,
        private collisionEventNotifier: CollisionEventNotifier
    ) {
        // TODO this is ugly, fix the GraphData inteface
        // we should be able to call collisionGraph.copy or collisionGraph.getGraph
        this.staticGraph = {};
    }

    update() {
        this.staticGraph = this.collisionGraph.getCollisionNodesWthEdges().nodes;
    }

    /**
     * Adds a node to the static collision graph if not already present.
     * A node is only added if it is present in the {@link CollisionGraph}
     *
     * This is used when a contour is de-selected in order to store and display collisions of
     * that contour
     *
     * NOTE: a contour is a node in terms of the collision graph
     * @param node
     */
    addNode(node: string) {
        const newNode = this.collisionGraph.getNode(node);

        // a node must already exist and colliding with one ore more nodes
        if (!newNode || newNode.size === 0) {
            return;
        }

        if (_.isEqual(this.staticGraph[node], newNode)) {
            return;
        }

        this.staticGraph[node] = newNode;

        const addedItems: AdjacencyList = {};
        addedItems[node] = new Set(newNode);

        const result: StaticCollisionResult = {
            addedItems: addedItems,
            removedItems: {}
        };
        this.collisionEventNotifier.recordStaticCollisionResult(result);
    }

    /**
     * @param node
     */
    removeNode(node: string) {
        const toRemovedNode = this.staticGraph[node];
        if (toRemovedNode) {
            const removedItems: AdjacencyList = {};

            if (toRemovedNode.size > 0) {
                removedItems[node] = new Set(toRemovedNode);
                const result: StaticCollisionResult = {
                    addedItems: {},
                    removedItems: removedItems
                };
                this.collisionEventNotifier.recordStaticCollisionResult(result);
            }
            delete this.staticGraph[node];
            // this.staticGraph[node] = new Set<string>();
        }
    }

    removeEdge(startNode: string, endNode: string) {
        const toRemovedNode = this.staticGraph[startNode];
        if (toRemovedNode) {
            toRemovedNode.delete(endNode);
            const removedItems: AdjacencyList = {};
            removedItems[startNode] = new Set<string>().add(endNode);
            const result: StaticCollisionResult = {
                addedItems: {},
                removedItems: removedItems
            };
            this.collisionEventNotifier.recordStaticCollisionResult(result);
        }
    }

    getGraph(): AdjacencyList {
        // TODO maybe we should not leak this reference
        // add a copy method already in the GraphData similar togetCollisionNodesWthEdges
        return this.staticGraph;
    }
}

export type StaticCollisionResult = StaticCollisionChangedEvent;
