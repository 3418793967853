import { transformPoint } from '../../shared/geom/matrix';
import { ResizeHandleData, ResizeHandleListData } from '../contour/contour-items-interfaces';
import { ContourTransformationBounds } from './contour-transformation-bounds';

export const CLASS_NAME_RESIZE_HANDLE = 'resize-handle';
export const ID_RESIZE_HANDLES_GROUP = 'resize-handles-';

export class ResizeHandlesDrawer {
    private resizeHandlesGroup: Snap.Paper;
    private resizeHandles: Map<string, Snap.Element>;
    private resizeHandleSize: number = 2.5;
    private scaleFactor: number = 1;
    private visible: boolean = true;

    constructor(
        public readonly contourId: string,
        private containerElement: Snap.Paper,
        private handlesData: ResizeHandleListData,
        private selectionBounds: ContourTransformationBounds
    ) {
        this.init();
    }

    private init() {
        this.resizeHandles = new Map();
        this.createResizeHandlesGroup();
        this.handlesData.handles.forEach((data: ResizeHandleData) => {
            this.resizeHandles.set(data.side, this.createResizeHandle(data));
        });

        this.updateResizeHandles();
    }

    private createResizeHandlesGroup() {
        const groupId = ID_RESIZE_HANDLES_GROUP + this.contourId;
        this.resizeHandlesGroup = this.containerElement.g();
        this.resizeHandlesGroup.node.setAttribute('id', groupId);
    }

    private createResizeHandle(data: ResizeHandleData): Snap.Element {
        const resizeHandle = this.resizeHandlesGroup.circle(0, 0, this.resizeHandleSize);

        // 2,2222 24

        const displayValue = data.visible ? 'inline' : 'none';
        resizeHandle.attr({
            display: displayValue,
            fill: '#ffcc0a',
            cursor: data.cursorType
        });

        resizeHandle.node.classList.add(CLASS_NAME_RESIZE_HANDLE);
        resizeHandle.node.classList.add(CLASS_NAME_RESIZE_HANDLE + '-' + this.contourId);
        resizeHandle.node.classList.add(
            CLASS_NAME_RESIZE_HANDLE + '-' + data.side + '-' + this.contourId
        );
        return resizeHandle;
    }

    updateHandles(handlesData: ResizeHandleListData) {
        handlesData.handles.forEach(data => {
            if (data.visible != null) {
                const handle = this.resizeHandles.get(data.side);
                if (handle) {
                    const displayValue = data.visible ? 'inline' : 'none';
                    handle.attr({ display: displayValue });
                }
            }
        });

        // nil check
        if (handlesData.visible != null && this.visible !== handlesData.visible) {
            const displayValue = handlesData.visible ? 'inline' : 'none';
            this.resizeHandlesGroup.attr({ display: displayValue });
            this.visible = handlesData.visible;
        }
    }

    updateResizeHandles() {
        const itemBBox = this.selectionBounds.localInnerBounds;
        const cx = itemBBox.x + itemBBox.width / 2;
        const cy = itemBBox.y + itemBBox.height / 2;

        const matrix = this.selectionBounds.getLocalOuterMatrix();
        const center = transformPoint(cx, cy, matrix);
        const centerTop = transformPoint(cx, itemBBox.y, matrix);
        const leftTop = transformPoint(itemBBox.x, itemBBox.y, matrix);
        const rightTop = transformPoint(itemBBox.x2, itemBBox.y, matrix);
        const rightMid = transformPoint(itemBBox.x2, cy, matrix);
        const rightBottom = transformPoint(itemBBox.x2, itemBBox.y2, matrix);
        const centerBottom = transformPoint(cx, itemBBox.y2, matrix);
        const leftBottom = transformPoint(itemBBox.x, itemBBox.y2, matrix);
        const leftMid = transformPoint(itemBBox.x, cy, matrix);

        const handlePts: { handle: Snap.Element; cx: number; cy: number }[] = [];
        this.resizeHandles.forEach((handle, key) => {
            if (key === ResizeHandleSide.LEFT_TOP) {
                handlePts.push({ handle: handle, cx: leftTop.x, cy: leftTop.y });
            } else if (key === ResizeHandleSide.TOP) {
                handlePts.push({ handle: handle, cx: centerTop.x, cy: centerTop.y });
            } else if (key === ResizeHandleSide.RIGHT_TOP) {
                handlePts.push({ handle: handle, cx: rightTop.x, cy: rightTop.y });
            } else if (key === ResizeHandleSide.RIGHT) {
                handlePts.push({ handle: handle, cx: rightMid.x, cy: rightMid.y });
            } else if (key === ResizeHandleSide.RIGHT_BOTTOM) {
                handlePts.push({ handle: handle, cx: rightBottom.x, cy: rightBottom.y });
            } else if (key === ResizeHandleSide.BOTTOM) {
                handlePts.push({ handle: handle, cx: centerBottom.x, cy: centerBottom.y });
            } else if (key === ResizeHandleSide.LEFT_BOTTOM) {
                handlePts.push({ handle: handle, cx: leftBottom.x, cy: leftBottom.y });
            } else if (key === ResizeHandleSide.LEFT) {
                handlePts.push({ handle: handle, cx: leftMid.x, cy: leftMid.y });
            }
        });

        handlePts.forEach(hPt => {
            hPt.handle.node.setAttribute('cx', hPt.cx.toFixed(4));
            hPt.handle.node.setAttribute('cy', hPt.cy.toFixed(4));
        });
    }

    rotate(angle: number, matrix: Snap.Matrix) {
        if (!this.resizeHandles) {
            return;
        }
        this.updateResizeHandles();
    }

    translate(deltaX: number, deltaY: number) {
        this.updateResizeHandles();
    }
}

export enum ResizeHandleCursorType {
    EAST_WEST = 'ew-resize',
    NORTH_SOUTH = 'ns-resize',
    NORTH_EAST_SOUTH_WEST = 'nesw-resize',
    NORTH_WEST_SOUTH_EAST = 'nwse-resize'
}

export enum ResizeHandleSide {
    TOP = 'top-handle',
    RIGHT_TOP = 'right-top-handle',
    RIGHT = 'right-handle',
    RIGHT_BOTTOM = 'right-bottom-handle',
    BOTTOM = 'bottom-handle',
    LEFT_BOTTOM = 'left-bottom-handle',
    LEFT = 'left-handle',
    LEFT_TOP = 'left-top-handle'
}
