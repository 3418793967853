<div class="modal-card-title">
    <ng-content></ng-content>
</div>
<app-button
    app-icon-button
    class="modal-close-btn btn-border-radiusless"
    *ngIf="closable"
    (click)="close()"
    [color]="'tertiary'"
    [buttonSize]="'x-small'"
>
    <app-icon cssWidth="16px" cssHeight="16px" shape="icon-close" iconSize="small"></app-icon>
</app-button>
