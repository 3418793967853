import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';
import { humanReadableByte } from '../utils/human';
import { FileApiError } from '../backend/api-error';

@Injectable()
export class FileUploadService {
    public maxUploadCount = 5;
    private fileUploadState: FileUploadState = {};
    private filesUploadStateSubject = new Subject<UploadFileInfo[]>();
    readonly uploadCompleted = new Subject<UploadCompleteEvent<any>>();

    constructor(private http: HttpClient) {}

    getOnUploadFilesStateChange(): Observable<UploadFileInfo[]> {
        return this.filesUploadStateSubject.asObservable().pipe(distinctUntilChanged());
    }

    getUploadComplete<T>(): Observable<UploadCompleteEvent<T>> {
        return this.uploadCompleted.asObservable();
    }

    /**
     * Upload files to the server.
     *
     * @param files
     * @param uploadUrl
     */
    uploadFiles(files: FileList | File[], uploadUrl: string) {
        const maxFilesCount = this.remainingUploads();
        for (let i = 0; i < files.length && i < maxFilesCount; i++) {
            const file = files[i];
            this.uploadFile(file, uploadUrl);
        }
    }

    uploadFile(file: File, uploadUrl: string) {
        if (!file || !uploadUrl || this.remainingUploads() <= 0) {
            return;
        }

        this.abortOngoingRequest(file);

        const fileInfo = this.createFileInfo(file);
        this.fileUploadState[fileInfo.fileName] = fileInfo;
        this.filesUploadStateSubject.next(Object.values(this.fileUploadState));

        const formData = new FormData();
        formData.append('file', file);

        const req = new HttpRequest('POST', uploadUrl, formData, {
            reportProgress: true,
            withCredentials: true
        });
        // use HttpClient.request (instead of HttpClient) to get a raw event stream
        const uploadSubscription = this.http
            .request(req)
            .pipe(
                // this event is uninteresting for us.
                // can change however in the future
                filter(evt => evt.type !== HttpEventType.Sent),
                catchError(err => this.handleError(file, err))
            )
            .subscribe((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.updateProgress(file, event);
                } else if (event.type === HttpEventType.Response) {
                    this.markAsComplete(file, event);
                }
            });

        fileInfo.abort = uploadSubscription.unsubscribe;
    }

    deleteUploadFile(fileName: string) {
        delete this.fileUploadState[fileName];
        this.filesUploadStateSubject.next(Object.values(this.fileUploadState));
    }

    private remainingUploads(): number {
        return Math.max(0, this.maxUploadCount - Object.keys(this.fileUploadState).length);
    }

    private updateProgress(file: File, event: HttpProgressEvent) {
        const fileInfo = this.fileUploadState[file.name];
        fileInfo.inProgress = true;
        fileInfo.progressPercent = Math.round((event.loaded * 100.0) / event.total);
        this.filesUploadStateSubject.next(Object.values(this.fileUploadState));
    }

    private markAsComplete(file: File, event: HttpResponse<any>) {
        const fileInfo = this.fileUploadState[file.name];
        fileInfo.isDone = true;
        fileInfo.inProgress = false;
        fileInfo.progressPercent = 100;
        this.uploadCompleted.next({ file: fileInfo, data: event.body });
    }

    private handleError(file: File, err: HttpErrorResponse): Observable<never> {
        this.fileUploadState[file.name].inProgress = false;
        this.fileUploadState[file.name].error = {
            statusCode: err.error.statusCode,
            errorCode: err.error.errorCode,
            message: err.error.message,
            filename: file.name
        };
        this.filesUploadStateSubject.next(Object.values(this.fileUploadState));
        // this.deleteUploadFile(file.name);
        // this.failedFilesChange.next(Array.from(this.failedFiles));
        return EMPTY;
    }

    /**
     * Aborts any ongoing upload request for the file with the file name (i.e. full-path) equals to
     * the specified file.
     *
     * @param file
     */
    private abortOngoingRequest(file: File) {
        const existingFileInfo = this.fileUploadState[file.name];
        if (existingFileInfo && !existingFileInfo.isDone) {
            existingFileInfo.abort();
        }
    }

    public createFileInfo(file: File): UploadFileInfo {
        const fileInfo = {
            fileName: file.name,
            fileSize: humanReadableByte(file.size),
            previewImageSrc: null,
            progressPercent: 0,
            isDone: false,
            inProgress: true,
            error: null,
            abort: null
        };
        this.getFileImageSrc(file, fileInfo);
        return fileInfo;
    }

    private getFileImageSrc(file: File, fileInfo: UploadFileInfo) {
        const reader = new FileReader();
        reader.onloadend = function() {
            if (typeof reader.result === 'string') {
                fileInfo.previewImageSrc = reader.result;
            }
        };
        reader.readAsDataURL(file);
    }
}

export interface FileUploadState {
    [fileName: string]: UploadFileInfo;
}

export interface UploadFileInfo {
    fileName: string;
    previewImageSrc: string | null;
    fileSize: string | number;
    progressPercent: number;
    isDone: boolean;
    inProgress: boolean;
    abort: () => void | null;
    error: FileApiError | null;
}

export interface UploadCompleteEvent<T> {
    file: UploadFileInfo;
    data: T;
}

export interface FileStateChangeUpload {
    changeType: 'PROGRESS' | 'ERROR';
    fileInfo: UploadFileInfo[];
}
