import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';

// TODO duplicate code
export type TextPosition = 'before' | 'after' | 'before-after' | 'none';
const LINK_TEXT_ATTRIBUTES = ['app-icon-link-text'];

@Component({
    selector: 'app-link-text, app-link-text[app-icon-link-text]',
    templateUrl: './link-text.component.html',
    styleUrls: ['./link-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.text-before-icon]': 'isBefore()',
        '[class.text-after-icon]': 'isAfter()',
        '[class.text-before-after-icon]': 'isBeforeAndAfter()'
    }
})
export class LinkTextComponent implements OnInit {
    @Input()
    isActive: boolean;

    @Input()
    textPosition: TextPosition = 'none';

    private _isDisabled = false;

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    ngOnInit() {
        // Add classes
        for (const attr of LINK_TEXT_ATTRIBUTES) {
            if (this.hasHostAttributes(attr)) {
                (this.elementRef.nativeElement as HTMLElement).classList.add(attr);
            }
        }
    }

    // TODO duplicate code (see button.component)
    private hasHostAttributes(...attributes: string[]) {
        return attributes.some(attribute => this.elementRef.nativeElement.hasAttribute(attribute));
    }

    @Input()
    get disabled(): boolean {
        return this._isDisabled;
    }

    set disabled(value: boolean) {
        if (this._isDisabled !== value) {
            if (value) {
                this.elementRef.nativeElement.classList.add(`app-link-text-disabled`);
                this._isDisabled = value;
            } else {
                this.elementRef.nativeElement.classList.remove(`app-link-text-disabled`);
                this._isDisabled = value;
            }
        }
    }

    isBeforeAndAfter(): boolean {
        return this.textPosition === 'before-after';
    }

    isBefore(): boolean {
        return this.textPosition === 'before';
    }

    isAfter(): boolean {
        return this.textPosition === 'after';
    }
}
