import { Directive, ElementRef, OnInit } from '@angular/core';
import { CanvasBoundsChange } from '../shared/canvas-bounds.manager';
import { SelectionChangedData } from '../canvas.service';
import {
    ContourChangeData,
    ContourItemsRemoved,
    CuttableContourDrawData
} from '../contour/contour-items-interfaces';
import { SelectionHandle3 } from '../handles/selection-handle3';

import { CanvasContourLayer } from './canvas-contour-layer';
import { CanvasLayerMixin } from './canvas-layer-mixin';
import { CanvasLayerWithSubscriptionMixin } from './destroyable-layer-mixin';

declare var Snap: any;

@Directive({ selector: '[appSelectionLayer]' })
export class SelectionLayerDirective extends CanvasLayerMixin(CanvasLayerWithSubscriptionMixin())
    implements CanvasContourLayer<Snap.Paper>, OnInit {
    element: Snap.Paper;

    /**
     * @deprecated use element instead
     */
    svgElement: Snap.Paper;

    private readonly selectionHandles: {
        [contourId: number]: SelectionHandle3;
    } = {};

    constructor(private readonly elementRef: ElementRef) {
        super();

        this.svgElement = this.element = Snap(elementRef.nativeElement);
        // this.initEventHandler(elementRef.nativeElement);
    }

    ngOnInit(): void {
        /*
        this.layerElementStore.getOnContourDrawn()
            .pipe(
                // FIXME this is how filter the foam element out
                // this is an indication for a poor abstraction

                filter(x => this.layerElementStore.getContourElements(x.contourId) != null))
            .subscribe(data => {
                this.changeContourItem(new ContourChangeData({
                    contourId: data.contourId,
                    handles: new ContourHandlesData({
                        contourId: data.contourId,
                        transformBounds: {
                            localContourBounds: data.contour.localBBox,
                            localMarginBounds: data.margin.localBBox,
                            selectionBoundsMatrix: data.contour.localMatrix,
                        },
                    }),
                }));
            }); */
    }
    /*
    private initEventHandler(eventTarget: SVGElement) {
        const mouseDowns = fromEvent(eventTarget, 'mousedown', { capture: true }) as Observable<
            MouseEvent
        >;
        const mouseMoves = fromEvent(document.documentElement, 'mousemove', {
            capture: false,
        }) as Observable<MouseEvent>;
        const mouseUps = fromEvent(window, 'mouseup', { capture: false }) as Observable<MouseEvent>;

        const rotationHandleSelected = mouseDowns.pipe(
            map(evt =>
                this.workspaceService.toItemSelectedInfo(
                    evt,
                    this.workspaceService.getRotationHandleMouseTarget(evt.target as Element)
                )
            ),
            filter(x => x !== null),
            share()
        );

        const rotateHandleMoved = rotationHandleSelected.pipe(
            switchMap(downEvt => {
                return mouseMoves.pipe(
                    takeUntil(mouseUps),
                    // combine subsequent mouse move events to create a drag event
                    pairwise(),
                    // create drag event
                    map((x: MouseEvent[]) => this.dragEventService.toDragEvent(x, downEvt))
                );
            }),
            share()
        );

        // TODO duplicate code
        const resizeHandleSelected = mouseDowns.pipe(
            map(evt =>
                this.workspaceService.toItemSelectedInfo(
                    evt,
                    this.workspaceService.getResizeHandleMouseTarget(evt.target as Element)
                )
            ),
            filter(x => x !== null),
            share()
        );

        const resizeHandleMoved = resizeHandleSelected.pipe(
            switchMap(downEvt => {
                return mouseMoves.pipe(
                    takeUntil(mouseUps),
                    // combine subsequent mouse move events to create a drag event
                    pairwise(),
                    // create drag event
                    map((x: MouseEvent[]) => this.dragEventService.toDragEvent(x, downEvt))
                );
            }),
            share()
        );

        resizeHandleMoved.subscribe(dEvt => {
            dEvt.originalEvent.preventDefault();
            this.workspaceService.resizeSelectedItemsFromMouseInput(dEvt);
        });

        rotateHandleMoved.subscribe((dEvt: AppDragEvent) => {
            dEvt.originalEvent.preventDefault();
            this.workspaceService.rotateSelectedItemsWithMouse(
                dEvt.x,
                dEvt.y,
                dEvt.targetInfo.contourIndex
            );
        });

        rotationHandleSelected.subscribe(x => {
            x.originalEvent.preventDefault();
        });
    } */

    addContourItem(contourData: CuttableContourDrawData) {}

    changeContourItem(contourData: ContourChangeData): void {
        const handlesData = contourData.handles;
        const handle: SelectionHandle3 = this.selectionHandles[contourData.contourId];

        if (handle) {
            if (handlesData) {
                handle.updateHandles(handlesData);
            }

            if (contourData.visibility !== undefined) {
                if (contourData.visibility) {
                    handle.show();
                } else {
                    handle.hide();
                }
            }
        }
    }

    removeContourItem(contourData: ContourItemsRemoved) {
        if (!contourData) {
            return;
        }
        contourData.contourIds.forEach(contourId => {
            const handle = this.selectionHandles[contourId];
            if (handle) {
                handle.remove();
                delete this.selectionHandles[contourId];
            }
        });
    }

    selectContourItems(data: SelectionChangedData) {
        const { itemsToSelect, itemsToDeselect } = data;

        if (itemsToSelect) {
            itemsToSelect.forEach(itemData => {
                if (!itemData.handles) {
                    return;
                }
                const handle = this.getOrCreateHandleDrawers(itemData);
                handle.updateHandles(itemData.handles);
                this.updateHandleVisibility(itemData.visibility, handle);
            });
        }

        if (itemsToDeselect) {
            itemsToDeselect.forEach(itemData => {
                const handle = this.selectionHandles[itemData.contourId];
                if (handle) {
                    handle.hide();
                }
            });
        }
    }

    private updateHandleVisibility(visibility: boolean, handle) {
        if (visibility !== undefined) {
            if (visibility) {
                handle.show();
            } else {
                handle.hide();
            }
        }
    }

    private getOrCreateHandleDrawers(itemData) {
        let handle = this.selectionHandles[itemData.contourId];
        if (!handle) {
            handle = new SelectionHandle3(this.element, itemData.contourId, itemData.handles);
            this.selectionHandles[itemData.contourId] = handle;
        }
        return handle;
    }

    protected onDimensionsChanged(changedEvent: CanvasBoundsChange) {}
}
