import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { RibbonDialog } from '../ribbon-dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlignmentType } from './alignment-type';
import { FoamEditorService } from '../../../../foam-editor.service';
import {
    BottomAlignAction,
    HorizontalCenterAlignAction,
    HorizontalSpreadAlignAction,
    LeftAlignAction,
    RightAlignAction,
    TopAlignAction,
    VerticalCenterAlignAction,
    VerticalSpreadAlignAction
} from '../../../../actions/align.action';

@Component({
    selector: 'app-alignment-dialog',
    templateUrl: './alignment-ribbon-dialog.component.html',
    styleUrls: ['./alignment-ribbon-dialog.component.scss']
})
export class AlignmentRibbonDialogComponent implements RibbonDialog, OnDestroy {
    panelTitle: string;
    panelIndex: number;
    dialogTitle = 'Ausrichten';

    @Output()
    closed: EventEmitter<void> = new EventEmitter();
    private leftAlignAction: LeftAlignAction;
    private topAlignAction: TopAlignAction;
    private rightAlignAction: RightAlignAction;
    private bottomAlignAction: BottomAlignAction;
    private horizontalCenterAlignAction: HorizontalCenterAlignAction;
    private verticalCenterAlignAction: VerticalCenterAlignAction;
    private horizontalSpreadAlignAction: HorizontalSpreadAlignAction;
    private verticalSpreadAlignAction: VerticalSpreadAlignAction;

    constructor(private readonly foamEditor: FoamEditorService, translate: TranslateService) {
        translate.stream(['ALIGNMENT_RIBBON.MAIN_MENU']).subscribe(res => {
            this.dialogTitle = res['ALIGNMENT_RIBBON.MAIN_MENU'];
        });
    }

    closeDialog() {
        this.closed.emit();
    }

    alignItems(type: string) {
        const enumType: AlignmentType = AlignmentType[type] as AlignmentType;
        switch (enumType) {
            case AlignmentType.LEFT:
                this.getLeftAlignAction().execute();
                break;
            case AlignmentType.RIGHT:
                this.getRightAlignAction().execute();
                break;
            case AlignmentType.TOP:
                this.getTopAlignAction().execute();
                break;
            case AlignmentType.BOTTOM:
                this.getBottomAlignAction().execute();
                break;
            case AlignmentType.HORIZONTAL_CENTER:
                this.geHorizontalCenterAlignAction().execute();
                break;
            case AlignmentType.VERTICAL_CENTER:
                this.getVerticalCenterAlignAction().execute();
                break;
            case AlignmentType.HORIZONTAL_SPREAD:
                this.getHorizontalSpreadAlignAction().execute();
                break;
            case AlignmentType.VERTICAL_SPREAD:
                this.getVerticalSpreadAlignAction().execute();
                break;
        }
    }

    getLeftAlignAction() {
        if (!this.leftAlignAction) {
            this.leftAlignAction = new LeftAlignAction(this.foamEditor);
        }

        return this.leftAlignAction;
    }

    getTopAlignAction() {
        if (!this.topAlignAction) {
            this.topAlignAction = new TopAlignAction(this.foamEditor);
        }

        return this.topAlignAction;
    }

    getRightAlignAction() {
        if (!this.rightAlignAction) {
            this.rightAlignAction = new RightAlignAction(this.foamEditor);
        }

        return this.rightAlignAction;
    }

    getBottomAlignAction() {
        if (!this.bottomAlignAction) {
            this.bottomAlignAction = new BottomAlignAction(this.foamEditor);
        }

        return this.bottomAlignAction;
    }

    geHorizontalCenterAlignAction() {
        if (!this.horizontalCenterAlignAction) {
            this.horizontalCenterAlignAction = new HorizontalCenterAlignAction(this.foamEditor);
        }

        return this.horizontalCenterAlignAction;
    }

    getVerticalCenterAlignAction() {
        if (!this.verticalCenterAlignAction) {
            this.verticalCenterAlignAction = new VerticalCenterAlignAction(this.foamEditor);
        }

        return this.verticalCenterAlignAction;
    }

    getHorizontalSpreadAlignAction() {
        if (!this.horizontalSpreadAlignAction) {
            this.horizontalSpreadAlignAction = new HorizontalSpreadAlignAction(this.foamEditor);
        }

        return this.horizontalSpreadAlignAction;
    }

    getVerticalSpreadAlignAction() {
        if (!this.verticalSpreadAlignAction) {
            this.verticalSpreadAlignAction = new VerticalSpreadAlignAction(this.foamEditor);
        }

        return this.verticalSpreadAlignAction;
    }

    //

    ngOnDestroy(): void {
        // remove all Observers
        this.closed.complete();
    }
}
