import { Point2D } from '../../../../../shared/geom/point2D';
import FastPriorityQueue from 'fastpriorityqueue';
import { create8NeighborPixels, getPixelIndex, PixelInfo, RGBTuple } from './pixel-math';
import { PriorityQueue } from '../../../../../../shared/structs/priority-queue';

export interface ImageInfo {
    data: Uint8ClampedArray;
    width: number;
    height: number;
    channels: number;
    pixelMap: PixelInfo[];
}

enum SelectState {
    SELECTED,
    NOT_SELECTED
}

export type PixelState = {
    pixelIndex: number;
    state: SelectState | undefined;
    lastSourceState: SelectState;
    cost: number;
};
type PixelQueueData = {
    cost: number;
    pixelInfo: PixelState;
    sourceState: SelectState;
};
type PixelStateMap = {
    [key: number]: PixelState;
};

const priorityQueueComparator = (a, b) => a.cost < b.cost;
// 8 for neighbors only excluding the selected pixel
const mooreNeighbor = new Array<number>(8);
const MAX_NEIGHBORS = mooreNeighbor.length;
export function competingFill(
    imageInfo: ImageInfo,
    mousePt: Point2D,
    radius: number = 4
): number[] {
    const pixelMap = imageInfo.pixelMap;
    const pixelStates: PixelStateMap = {};

    // Reuse queue to avoid GC
    const queue: FastPriorityQueue<PixelQueueData> = getPriorityQueue();
    addStartingPixels(queue, pixelStates, mousePt, radius, imageInfo);

    if (queue.isEmpty()) {
        return [];
    }

    const visited: { [key: number]: boolean } = {};
    const selectedPixels: number[] = [];

    //  const distancesToMouse: { [key: number]: number } = {};

    while (queue.size > 0) {
        // next least cost pixel
        const currentPixel = queue.poll();

        // skip if already updated/visited
        if (currentPixel.pixelInfo.state !== undefined) {
            releasePixelQueueData(currentPixel);
            continue;
        }

        const currentPixelIndex = currentPixel.pixelInfo.pixelIndex;
        // take the state of the source pixel
        currentPixel.pixelInfo.state = currentPixel.sourceState;
        if (currentPixel.pixelInfo.state === SelectState.SELECTED) {
            selectedPixels.push(currentPixelIndex);
        }

        // Note to reduce need for GC we use a predefined array to obtain the result
        // Hence, some entries might be undefined if a neighbor pixel is not found
        create8NeighborPixels(
            currentPixelIndex,
            imageInfo.width,
            imageInfo.height,
            true,
            mooreNeighbor
        );
        updateNeighborPixels(queue, currentPixel, pixelStates, pixelMap, mousePt, radius, visited);
        visited[currentPixelIndex] = true;
        releasePixelQueueData(currentPixel);
    }

    releasePriorityQueue(queue);
    return selectedPixels;
}

function addStartingPixels(
    queue: FastPriorityQueue<PixelQueueData>,
    pixelStates: PixelStateMap,
    startingPt,
    radius: number,
    imageInfo: ImageInfo
): void {
    // add start pixel
    // pixel index under the mouse
    const startIndex = getPixelIndex(startingPt.x, startingPt.y, imageInfo.width);
    if (startIndex < 0 || startIndex > imageInfo.data.length) {
        console.error(
            `starting pixel index ${startIndex} should be equal or less than the image length ${imageInfo.data.length}`
        );
        return;
    }
    pixelStates[startIndex] = {
        pixelIndex: startIndex,
        state: undefined,
        lastSourceState: SelectState.NOT_SELECTED,
        cost: 0
    } as PixelState;
    queue.add(getPixelQueueData(0, pixelStates[startIndex], SelectState.SELECTED));

    const circumPixels = getSquareOutline(
        startingPt.x,
        startingPt.y,
        radius,
        imageInfo.width,
        imageInfo.height
    );

    for (let i = 0, l = circumPixels.length; i < l; i++) {
        const pi = circumPixels[i];
        const circumPixelInfo: PixelState = {
            pixelIndex: pi,
            state: undefined,
            lastSourceState: SelectState.NOT_SELECTED,
            cost: 0.5
        };
        pixelStates[pi] = circumPixelInfo;

        // initial cost of 0.5 ensures that any path from a border-pixel to an inner-pixel
        // has a cost greater of 0.5 than a path between inner-pixels of the same length
        // Hence, it ensures that borders are not selected
        queue.add(getPixelQueueData(0.5, circumPixelInfo, SelectState.NOT_SELECTED));
    }
}

function updateNeighborPixels(
    queue: FastPriorityQueue<PixelQueueData>,
    currentPixel,
    pixelStates: PixelStateMap,
    pixelMap: PixelInfo[],
    startingPt,
    radius,
    visited
) {
    const currentPixelIndex = currentPixel.pixelInfo.pixelIndex;
    const currentPixelXY = pixelMap[currentPixelIndex].coordinate;
    const currentPixelSummedColor = pixelMap[currentPixelIndex].summedColor;
    for (let i = 0; i < MAX_NEIGHBORS; i++) {
        const nIndex = mooreNeighbor[i];
        if (nIndex === undefined || visited[nIndex]) {
            continue;
        }

        const nCoord = pixelMap[nIndex].coordinate;
        const distanceToMouse = Point2D.distance(startingPt.x, startingPt.y, nCoord.x, nCoord.y);
        /* let distanceToMouse = distancesToMouse[nIndex];
        if (distanceToMouse == null) {
            distanceToMouse = Point2D.distance(startingPt.x, startingPt.y, nCoord.x, nCoord.y);
            distancesToMouse[nIndex] = distanceToMouse;
        } */

        // const distanceToMouse = Point2D.distance(mousePt.x, mousePt.y, nCoord.x, nCoord.y);

        if (distanceToMouse <= radius) {
            const distanceToCurrPixel = Point2D.distance(
                currentPixelXY.x,
                currentPixelXY.y,
                nCoord.x,
                nCoord.y
            );

            const colorDiff = Math.abs(pixelMap[nIndex].summedColor - currentPixelSummedColor);
            const nCost = currentPixel.cost + colorDiff + distanceToCurrPixel;

            let pixelState = pixelStates[nIndex];
            if (!pixelState) {
                pixelState = {
                    pixelIndex: nIndex,
                    state: undefined,
                    cost: nCost,
                    lastSourceState: currentPixel.sourceState
                };
                pixelStates[nIndex] = pixelState;
                queue.add(getPixelQueueData(nCost, pixelState, currentPixel.sourceState));
            } else if (
                nCost < pixelState.cost &&
                pixelState.lastSourceState !== currentPixel.sourceState
            ) {
                pixelState.lastSourceState = currentPixel.sourceState;
                queue.add(getPixelQueueData(nCost, pixelState, currentPixel.sourceState));
            }
        }
    }
}

export function optimizeFunctionOnNextCall() {
    // eval('%OptimizeFunctionOnNextCall(competingFill)');
    // const status = eval('%GetOptimizationStatus(competingFill)');
    // printStatus(status);
}

function printStatus(status) {
    console.log(status.toString(2).padStart(12, '0'));
    if (status & (1 << 0)) {
        console.log('is function');
    }

    if (status & (1 << 1)) {
        console.log('is never optimized');
    }

    if (status & (1 << 2)) {
        console.log('is always optimized');
    }

    if (status & (1 << 3)) {
        console.log('is maybe deoptimized');
    }

    if (status & (1 << 4)) {
        console.log('is optimized');
    }

    if (status & (1 << 5)) {
        console.log('is optimized by TurboFan');
    }

    if (status & (1 << 6)) {
        console.log('is interpreted');
    }

    if (status & (1 << 7)) {
        console.log('is marked for optimization');
    }

    if (status & (1 << 8)) {
        console.log('is marked for concurrent optimization');
    }

    if (status & (1 << 9)) {
        console.log('is optimizing concurrently');
    }

    if (status & (1 << 10)) {
        console.log('is executing');
    }

    if (status & (1 << 11)) {
        console.log('topmost frame is turbo fanned');
    }
}

/**
 * Gets the pixels of a square's outline specified by the given radius.
 * @param x
 * @param y
 * @param radius
 * @param width
 * @param height
 */
export function getSquareOutline(
    x: number,
    y: number,
    radius: number,
    width: number,
    height: number
): number[] {
    if (radius < 1) {
        return [];
    }

    // top-left pixel on the circumference
    /**
     *       d1
     *  p1+------+p2
     *    +      +
     *  d2|      |
     *    +      +
     *  p3+------+p4
     *
     */
    const rx = x - radius;
    const ry = y - radius;
    const rx2 = x + radius;
    const ry2 = y + radius;
    const x1 = Math.max(0, rx);
    const y1 = Math.max(0, ry);
    const x2 = Math.min(width - 1, rx2);
    const y2 = Math.min(height - 1, ry2);

    const p1 = getPixelIndex(x1, y1, width);
    const p2 = getPixelIndex(x2, y1, width);
    const p3 = getPixelIndex(x1, y2, width);

    const d1 = p2 - p1;
    const d2 = y2 - y1;

    const result: number[] = [];
    const l = d1 + 1;
    for (let i = 0; i < l; i++) {
        result.push(p1 + i);
        // add bottom pixels on the circumference
        result.push(p3 + i);
    }
    /*
     // add bottom pixels on the circumference
    for (let i = 0; i < l; i++) {
        result.push(p3 + i);
    } */
    // add right pixels on the circumference
    for (let i = 1; i < d2; i++) {
        result.push(p1 + width * i);
        // add left pixels on the circumference
        result.push(p2 + width * i);
    }
    // add left pixels on the circumference
    /* for (let i = 1; i < d2; i++) {
        result.push(p2 + width * i);
    } */

    return result;
}

/**
 * Note: transparent pixels are interpreted as black pixels [0, 0, 0]
 * @param oneDimIndex
 * @param data
 * @param dimension
 */
export function getRGBColor(
    oneDimIndex: number,
    data: Uint8ClampedArray,
    dimension: number
): RGBTuple {
    const realIndex = oneDimIndex * dimension;
    return getCachedRgbColor(data[realIndex], data[realIndex + 1], data[realIndex + 2]);
    // return [data[realIndex], data[realIndex + 1], data[realIndex + 2]] as RGBTuple;
}

function diffRGB(a: [number, number, number], b: [number, number, number]): number {
    return Math.abs(a[0] - b[0]) + Math.abs(a[1] - b[1]) + Math.abs(a[2] - b[2]);
}

/**
 * Caching RGB ColorMap
 */

let cachedRgbColorMap: { [key: string]: RGBTuple } = {};

export function getCachedRgbColor(r: number, g: number, b: number): RGBTuple {
    const key = r + ',' + g + ',' + b;
    let color = cachedRgbColorMap[key];
    if (!color) {
        color = [b, g, b] as RGBTuple;
        cachedRgbColorMap[key] = color;
    }
    return color;
}

export function freeCachedRgbColors() {
    cachedRgbColorMap = null;
    cachedRgbColorMap = {};
}

/**
 *  Pool for FastPriorityQueue
 */

const priorityQueuePool: FastPriorityQueue<PixelQueueData>[] = [];
expandPriorityQueue(500);

function expandPriorityQueue(size: number): void {
    for (let i = 0; i < size; i++) {
        priorityQueuePool.push(new FastPriorityQueue<PixelQueueData>(priorityQueueComparator));
    }
}
function getPriorityQueue(): FastPriorityQueue<PixelQueueData> {
    let newObject: FastPriorityQueue<PixelQueueData>;
    // check to see if there is a spare one
    if (priorityQueuePool.length > 0) {
        newObject = priorityQueuePool.pop();
    } else {
        // none left, construct a new
        expandPriorityQueue(500 * 2);
        newObject = priorityQueuePool.pop();
    }
    return newObject;
}

function releasePriorityQueue(obj: FastPriorityQueue<PixelQueueData>): void {
    priorityQueuePool.push(obj);
    obj.trim();
}

/**
 * Pool for PixelQueueData objects
 */
const pixelQueuePool: PixelQueueData[] = [];
expandPixelQueueData(500);

function expandPixelQueueData(size: number): void {
    for (let i = 0; i < size; i++) {
        pixelQueuePool.push({
            cost: undefined,
            pixelInfo: undefined,
            sourceState: undefined
        });
    }
}

function getPixelQueueData(
    cost: number,
    pixelInfo: PixelState,
    sourceState: SelectState
): PixelQueueData {
    let newObject: PixelQueueData;
    // check to see if there is a spare one
    if (pixelQueuePool.length > 0) {
        newObject = pixelQueuePool.pop();
    } else {
        // none left, construct a new
        expandPixelQueueData(500 * 2);
        newObject = pixelQueuePool.pop();
    }

    newObject.cost = cost;
    newObject.pixelInfo = pixelInfo;
    newObject.sourceState = sourceState;
    return newObject;
}

function releasePixelQueueData(obj: PixelQueueData): void {
    pixelQueuePool.push(obj);
}
