<div class="ribbon-container">
    <app-ribbon-tabs (selectChanged)="onTabSelected($event)" (tabClicked)="onTabClicked($event)">
        <app-ribbon-tab-item selected="true" [itemId]="tabItems[0].id">
            <app-button app-icon-button color="secondary" buttonSize="large" iconAlign="vertical">
                <app-icon shape="tools" svgWidth="32" svgHeight="32" iconSize="large"></app-icon>
                <span>1.</span>
                {{ tabItems[0].title }}
            </app-button>
        </app-ribbon-tab-item>

        <app-ribbon-tab-item [itemId]="tabItems[1].id">
            <app-button
                app-icon-button
                color="secondary"
                [buttonSize]="'large'"
                iconAlign="vertical"
            >
                <app-icon shape="icon-text_fields" iconSize="large"></app-icon>
                <span>2.</span>
                {{ tabItems[1].title }}
            </app-button>
        </app-ribbon-tab-item>

        <app-ribbon-tab-item [itemId]="tabItems[2].id">
            <app-button app-icon-button color="secondary" buttonSize="large" iconAlign="vertical">
                <app-icon shape="icon-visibility" iconSize="large"></app-icon>
                <span>3.</span>
                {{ tabItems[2].title }}
            </app-button>
        </app-ribbon-tab-item>
    </app-ribbon-tabs>

    <div class="ribbon__main-toolbar">
        <div id="ribbon-toolbar-panel-container">
            <app-ribbon-panel-content [panel]="currentPanel"></app-ribbon-panel-content>
        </div>

        <div class="ribbon__panel-nav" [class.ribbon__panel-nav--overview]="isOverviewPanel">
            <app-button
                app-icon-button
                [color]="'secondary'"
                [disabled]="prevButtonDisabled"
                iconAlign="horizontal"
                textPosition="after"
                buttonSize="normal"
                (click)="expandPreviousAccordionOrPanel()"
            >
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    shape="arrow_left"
                    iconSize="small"
                ></app-icon>
                {{ 'NAVIGATION_BUTTON.BACK' | translate }}
            </app-button>

            <app-button
                *ngIf="!isOverviewPanel"
                app-icon-button
                [color]="'primary'"
                [attr.disabled]="nextButtonDisabled || null"
                iconAlign="horizontal"
                textPosition="before"
                buttonSize="normal"
                (click)="expandNextAccordionOrPanel()"
            >
                {{ 'NAVIGATION_BUTTON.NEXT' | translate }}
                <app-icon
                    svgWidth="24"
                    svgHeight="24"
                    shape="arrow_right"
                    iconSize="small"
                ></app-icon>
            </app-button>
        </div>
    </div>
</div>
