import { Tool } from './tool';
import { FoamEditorService } from '../../foam-editor.service';
import { take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { SelectAreaTracker } from './select-area-tracker';
import { SimpleDragEvent } from '../event-helpers';
import { DragTracker } from './drag-tracker';
import { CanvasContour } from '../../canvas/contour/contour-items-interfaces';
import { HandleTracker } from './handle-tracker';
import { CanvasService } from '../../canvas/canvas.service';
import { Action } from '../../actions/action';

/**
 * {Doc is derived from the JHotDraw}
 *
 * Tool to select and manipulate workspace items.
 *
 * A selection tool is in one of three states: 1) area
 * selection, 2) figure dragging, 3) handle manipulation. The different
 * states are handled by different tracker objects: the
 * {@link SelectAreaTracker}, the {@link DragTracker} and the
 * {@link HandleTracker}.
 *
 **/
export class SelectionTool extends Tool {
    private inputEventsSubscription: Subscription;
    private selectAreaTracker: SelectAreaTracker;
    private dragTracker: DragTracker;
    private handleTracker: HandleTracker;
    private tracker: Tool;
    private canvasService: CanvasService;

    constructor(foamEditorService: FoamEditorService) {
        super(foamEditorService);
        this.canvasService = this.foamEditorService.getCanvasService();
        this.init();
    }

    private init() {
        // for the case that deactivate was not called
        if (this.inputEventsSubscription) {
            this.inputEventsSubscription.unsubscribe();
        }

        // set the default tracker for MouseMoved double click events
        this.tracker = this.getSelectAreaTracker();
        this.tracker.toolDone().subscribe(() => this.deactivateHandler());
    }

    activate(): void {
        super.activate();
        this.tracker.activate();
    }

    deactivate(): void {
        super.deactivate();
        this.tracker.deactivate();
    }

    /**
     * Determines the tracker to be used for the given MouseEvent
     *
     * @param evt
     */
    private getSelectionTracker(evt: MouseEvent): Tool {
        const canvasService = this.foamEditorService.getCanvasService();
        let newTracker: Tool;
        let item: CanvasContour;

        const handle = canvasService.findHandle(evt.target as Element);
        if (handle) {
            newTracker = this.getHandleTracker();
            (newTracker as HandleTracker).setHandle(handle);
        } else {
            item = canvasService.findContourItem(evt);
            if (item) {
                newTracker = this.getDragTracker();
                if (newTracker) {
                    (<DragTracker>newTracker).setDraggedItem(item);
                }
            } else {
                newTracker = this.getSelectAreaTracker();
            }
        }

        return newTracker;
    }

    handleDbClick(evt: MouseEvent) {
        const canvasService = this.foamEditorService.getCanvasService();
        const item = canvasService.findContourItem(evt);
        let contourTrackerFound = false;
        if (item) {
            const newTracker = item.getTool(evt.target as Element, this.foamEditorService);
            if (newTracker && newTracker !== this.tracker) {
                this.foamEditorService.setTool(newTracker);
                newTracker
                    .toolDone()
                    .pipe(take(1))
                    .subscribe(() => this.foamEditorService.setTool(this));
                newTracker.handleMouseDown(evt);

                contourTrackerFound = true;
            }
        }

        if (!contourTrackerFound) {
            this.tracker.handleDbClick(evt);
        }
    }

    private setTracker(newTracker: Tool) {
        if (this.tracker != null) {
            this.tracker.deactivate();
        }

        this.tracker = newTracker;
        if (this.tracker != null) {
            this.tracker.activate();
            this.tracker
                .toolDone()
                .pipe(take(1))
                .subscribe(() => this.deactivateHandler());
        }
    }

    handleMouseDown(mouseEvent: MouseEvent) {
        const newTracker = this.getSelectionTracker(mouseEvent);
        if (newTracker !== this.tracker) {
            this.setTracker(newTracker);
        }
        if (this.tracker) {
            this.tracker.handleMouseDown(mouseEvent);
        }
    }

    handleMouseDrag(event: SimpleDragEvent): void {
        if (this.tracker) {
            this.tracker.handleMouseDrag(event);
        }
    }

    /**
     * Handles mouse move events
     * Note, all MouseMove events are delegated to the SelectAreaTracker
     *
     * @param event
     */
    handleMouseMove(event: MouseEvent) {
        if (this.tracker) {
            this.tracker.handleMouseMove(event);
        }
    }

    handleMouseUp(event: MouseEvent) {
        if (this.tracker) {
            this.tracker.handleMouseUp(event);
        }
    }

    handleKeyDown(event: KeyboardEvent): void {
        if (this.tracker) {
            this.tracker.handleKeyDown(event);

            let action: Action;

            // look up for at tool inputMap first
            if (this.inputMap) {
                action = this.inputMap.get(event.key);
            }

            if (!action) {
                const editorMap = this.foamEditorService.getInputMap();
                action = editorMap.get(event.key);
            }

            if (action) {
                action.execute(event);
            }
        }
    }

    handleKeyUp(event: KeyboardEvent): void {
        if (this.tracker) {
            this.tracker.handleKeyUp(event);
        }
    }

    private getHandleTracker(): HandleTracker {
        if (!this.handleTracker) {
            this.handleTracker = new HandleTracker(this.foamEditorService);
        }
        return this.handleTracker;
    }

    private getSelectAreaTracker(): SelectAreaTracker {
        if (!this.selectAreaTracker) {
            this.selectAreaTracker = new SelectAreaTracker(this.foamEditorService);
        }
        return this.selectAreaTracker;
    }

    private getDragTracker(): DragTracker {
        if (!this.dragTracker) {
            this.dragTracker = new DragTracker(this.foamEditorService);
        }
        return this.dragTracker;
    }

    getToolStarted(): Observable<any> {
        return undefined;
    }

    toolDone(): Observable<void> {
        return undefined;
    }

    private deactivateHandler() {
        if (this.tracker !== this.getSelectAreaTracker()) {
            this.tracker = this.getSelectAreaTracker();
            this.tracker.activate();
        }
    }
}

export interface SelectionMousedownEvent {
    tracker: Tool;
    contourItem?: CanvasContour;
    mouseEvent: MouseEvent;
}

export interface SelectionDragEvent {
    tracker: Tool;
    dragEvent: SimpleDragEvent;
}
