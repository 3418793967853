<div class="contour-properties">
    <div class="contour-properties__row">
        <div
            class="contour-property-width"
            [class.contour-property-panel--disabled]="!props.width.editable"
        >
            <div
                class="contour-property-icon"
                appTooltip
                appToolTipContent="{{ 'MOUSEOVER.WIDTH' | translate }}"
            >
                <app-icon
                    shape="resize-width"
                    svgWidth="32"
                    svgHeight="32"
                    iconSize="small"
                    cssWidth="21px"
                    cssHeight="11px"
                >
                </app-icon>
            </div>
            <input
                #widthInput
                type="text"
                class="prop-input--small"
                [disabled]="!props.width.editable"
                [value]="props.width.value"
                (keyup.enter)="changeWidth($event)"
            />
            <div class="contour-property-abbr">
                <abbr title="{{ 'MOUSEOVER.MILLIMETER' | translate }}">mm</abbr>
            </div>
        </div>
        <div
            class="contour-property-height"
            [class.contour-property-panel--disabled]="!props.height.editable"
        >
            <div
                class="contour-property-icon"
                appTooltip
                appToolTipContent="{{ 'MOUSEOVER.HEIGHT' | translate }}"
            >
                <app-icon
                    shape="resize-height"
                    svgWidth="100%"
                    svgHeight="100%"
                    iconSize="small"
                    cssWidth="12px"
                    cssHeight="21px"
                ></app-icon>
            </div>
            <input
                #heightInput
                type="text"
                (keyup.enter)="changeHeight($event)"
                [value]="props.height.value"
                [disabled]="!props.height.editable"
                class="prop-input--small"
            />
            <div class="contour-property-abbr">
                <abbr title="{{ 'MOUSEOVER.MILLIMETER' | translate }}">mm</abbr>
            </div>
        </div>
        <div
            class="contour-property-bag-depth"
            [class.contour-property-panel--disabled]="!props.depth.editable"
        >
            <span
                class="contour-property-icon"
                appTooltip
                appToolTipContent="{{ 'MOUSEOVER.MILLING_DEPTH' | translate }}"
                [appTooltipPlacement]="'bottom'"
            >
                <app-icon
                    shape="bag-depth"
                    iconSize="small"
                    cssWidth="23px"
                    cssHeight="23px"
                ></app-icon>
            </span>
            <input
                #depthInput
                type="text"
                class="prop-input--small"
                [disabled]="!props.depth.editable"
                [value]="props.depth.value"
                (keyup.enter)="changeDepth($event)"
            />
            <span class="contour-property-abbr"
                ><abbr title="{{ 'MOUSEOVER.MILLIMETER' | translate }}">mm</abbr></span
            >
        </div>
        <div
            class="contour-property-rotation"
            [class.contour-property-panel--disabled]="!props.rotationDegree.editable"
        >
            <span
                class="contour-property-icon"
                appTooltip
                appToolTipContent="{{ 'MOUSEOVER.ROTATION_ANGLE' | translate }}"
                [appTooltipPlacement]="'bottom'"
            >
                <app-icon
                    shape="repeat"
                    iconSize="small"
                    cssWidth="18px"
                    cssHeight="21px"
                ></app-icon>
            </span>
            <input
                #rotationInput
                type="text"
                class="prop-input--small"
                [disabled]="!props.rotationDegree.editable"
                [value]="props.rotationDegree.value"
                (keyup.enter)="changeRotateDegree($event)"
            />
            <span class="contour-property-abbr"
                ><abbr title="{{ 'MOUSEOVER.DEGREE' | translate }}">°</abbr></span
            >
        </div>
    </div>
</div>
