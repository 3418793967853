import {
    Component,
    ContentChild,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { ButtonComponent, ColorType } from '../../../../shared/button/button.component';
import { Observable, Subject } from 'rxjs';
import { FoamLayerDirective } from '../../../canvas/layers/foam-layer.directive';

let nextId = 0;

@Component({
    selector: 'app-ribbon-tab-item',
    templateUrl: './ribbon-tab-item.component.html',
    styleUrls: ['./ribbon-tab-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RibbonTabItemComponent implements OnInit {
    @ContentChild(ButtonComponent, { static: false })
    tabItemElement: ButtonComponent;

    @HostBinding('id')
    readonly tabItemId: string = `app-tab-item-${nextId++}`;

    @HostBinding('class.app-tab-item-selected')
    private _selected = false;

    @Input()
    itemId: string;

    private tabSelectedSource: Subject<RibbonTabItemComponent> = new Subject<
        RibbonTabItemComponent
    >();

    tabSelectedChange: Observable<RibbonTabItemComponent> = this.tabSelectedSource.asObservable();

    // FIXME more generic approach needed that support not only buttons

    constructor() {}

    ngOnInit() {}

    @HostListener('click', ['$event'])
    onTabClick(event: Event) {
        // event.stopPropagation();
        this.tabSelectedSource.next(this);
    }

    @Input()
    get selected(): boolean {
        return this._selected;
    }

    set selected(value: boolean) {
        if (value !== this._selected) {
            this._selected = value;

            if (this.tabItemElement) {
                if (value) {
                    this.tabItemElement.color = (this.tabItemElement.color +
                        '-selected') as ColorType;
                } else {
                    this.tabItemElement.color = this.tabItemElement.color.replace(
                        '-selected',
                        ''
                    ) as ColorType;
                }
            }
        }
    }
}
