let idCounter = 0;

/**
 * Generates a unique id.
 * @param prefix The prefix to be appended to the generated unique ID
 */
export function nextUniqueId(prefix?: string): string {
    idCounter++;
    return prefix == null ? idCounter.toString() : prefix + idCounter.toString();
}

export function resetUniqueId(): void {
    idCounter = 0;
}
