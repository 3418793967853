export const BACKSPACE_VALUE = 'Backspace';
export const BACKSPACE_CODE = 8;
export const DELETE_VALUE = 'Delete';
export const ESCAPE_VALUE = 'Escape';
export const DELETE_CODE = 46;
export const LEFT_ARROW_VALUE = 'ArrowLeft';
export const LEFT_ARROW_CODE = 37;
export const UP_ARROW_VALUE = 'ArrowUp';
export const UP_ARROW_CODE = 38;
export const RIGHT_ARROW_VALUE = 'ArrowRight';
export const RIGHT_ARROW_CODE = 39;
export const DOWN_ARROW_VALUE = 'ArrowDown';
export const DOWN_ARROW_CODE = 40;
export const CONTROL_VALUE = 'Control';
export const CONTROL_CODE = 17;
export const ALT_VALUE = 'Alt';
export const ALT_CODE = 18;
