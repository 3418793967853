/**
 * Computes the bounds of a contour using the specified xy-coordinates and size (width and height).
 *
 * @param contours xy-coordinates of the contour for which the bounds is computed
 * @param width width the contour for which the bounds  is computed
 * @param height height for which the bounds is computed
 */
import { Bounds, ZERO_BOUNDS } from '../../../../../shared/geom/geometry';

export function getContourBounds(
    contours: { x: number; y: number }[][],
    width: number,
    height: number
): Bounds {
    if (contours.length === 0) {
        return ZERO_BOUNDS;
    }
    // const result = [];

    let maxX = -1;
    let minX = width + 1;
    let maxY = -1;
    let minY = height + 1;

    for (let i = 0; i < contours.length; i++) {
        const len = contours[i].length;
        const contour = contours[i];
        //  result.push([]);

        for (let j = 0; j < len; j++) {
            const x = contour[j].x;
            const y = contour[j].y;
            // const k = x * width + y;
            //  result[i].push(k);

            // check minmax for X
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;

            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
        }
    }

    minX = roundToPixelSize(minX);
    minY = roundToPixelSize(minY);
    maxX = roundToPixelSize(maxX);
    maxY = roundToPixelSize(maxY);

    return {
        // borders: result,
        //   bounds: {
        minX: minX,
        minY: minY,
        maxX: maxX,
        maxY: maxY,
        width: maxX - minX,
        height: maxY - minY
        //   }
    };
}

function roundToPixelSize(p: number): number {
    if (p % 1 === 0) {
        return p;
    }
    const pixelEpsilon = 0.005;
    return Math.round(p + pixelEpsilon);
}
